/* DealTable.tsx */
import React, { useState } from "react";
import { ColDef, CellValueChangedEvent } from "ag-grid-community";
import { ICellRendererParams } from "ag-grid-community";
import {
  AddCalculationsMenu,
  CName,
  DataTable,
  DealData,
} from "../../../components";
import { CustomHeaderComponent } from "../../../common";
import DealStatusDropdownTable from "../../../components/DealStageStatus/DealStageStatus";

const dealColumnDefs: ColDef<DealData>[] = [
  {
    headerName: "Deal",
    field: "deal",
    headerClass: "my-header-class",
    sortable: true,
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    pinned: "left",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      imageUrl:
        "https://vignette.wikia.nocookie.net/es.pokemon/images/f/f4/Charizard_PLB.png/revision/latest?cb=20170426010226", // URL of the image
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <CName targetOpen={true} targetLocation={'deal'} value={params.value} />;
    },
  },
  {
    headerName: "Deal Stage",
    field: "dealStage",
    headerClass: "my-header-class",
    sortable: true,
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      imageUrl:
        "https://vignette.wikia.nocookie.net/es.pokemon/images/f/f4/Charizard_PLB.png/revision/latest?cb=20170426010226", // URL of the image
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <DealStatusDropdownTable params={params} width="150px" />;
    },
  },
  {
    headerName: "Deal Owner",
    field: "dealOwner",
    headerClass: "my-header-class",
    sortable: true,
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      imageUrl:
        "https://vignette.wikia.nocookie.net/es.pokemon/images/f/f4/Charizard_PLB.png/revision/latest?cb=20170426010226", // URL of the image
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <CName value={params.value.dealOwnerName} />;
    },
  },
  {
    headerName: "Deal Value",
    field: "dealValue",
    headerClass: "my-header-class",
    sortable: true,
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      imageUrl:
        "https://vignette.wikia.nocookie.net/es.pokemon/images/f/f4/Charizard_PLB.png/revision/latest?cb=20170426010226", // URL of the image
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <CName value={params.value} />;
    },
  },
  {
    headerName: "Description",
    field: "description",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      imageUrl:
        "https://vignette.wikia.nocookie.net/es.pokemon/images/f/f4/Charizard_PLB.png/revision/latest?cb=20170426010226", // URL of the image
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <CName value={params.value} />;
    },
  },
  {
    headerName: "Company",
    field: "company",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      imageUrl:
        "https://vignette.wikia.nocookie.net/es.pokemon/images/f/f4/Charizard_PLB.png/revision/latest?cb=20170426010226", // URL of the image
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <CName value={params.value.companyName} />;
    },
  },
];

const dealRowData: DealData[] = [
  {
    deal: "Webex deal",
    description:
      "The relationship strength of the company that owns the company.",
    dealStage: {
      dealStageName: "Negotiation",
      dealStageColor: "green",
      _id: "deal-5q",
    },
    dealOwner: {
      dealOwnerName: "John Doe",
      dealOwnerAvatarUrl: "https://i.pravatar.cc/150?img=51",
      _id: "owner-1a",
    },
    dealValue: "$500,000.00",
    company: {
      companyName: "Tech Corp",
      companyLogoUrl: "https://i.pravatar.cc/150?img=51",
    },
  },
  {
    deal: "Skype deal",
    description:
      "The relationship strength of the company that owns the company.",
    dealStage: {
      dealStageName: "Negotiation",
      dealStageColor: "green",
      _id: "deal-5q",
    },
    dealOwner: {
      dealOwnerName: "John Doe",
      dealOwnerAvatarUrl: "https://i.pravatar.cc/150?img=51",
      _id: "owner-1a",
    },
    dealValue: "$500,000.00",
    company: {
      companyName: "Tech Corp",
      companyLogoUrl: "https://i.pravatar.cc/150?img=51",
    },
  },
  {
    deal: "Google deal",
    description:
      "The relationship strength of the company that owns the company.",
    dealStage: {
      dealStageName: "Negotiation",
      dealStageColor: "green",
      _id: "deal-5q",
    },
    dealOwner: {
      dealOwnerName: "John Doe",
      dealOwnerAvatarUrl: "https://i.pravatar.cc/150?img=51",
      _id: "owner-1a",
    },
    dealValue: "$500,000.00",
    company: {
      companyName: "Tech Corp",
      companyLogoUrl: "https://i.pravatar.cc/150?img=51",
    },
  },
];

const pinnedBottomRowData = [
  {
    deal: "Tech Corp",
    description:
      "The relationship strength of the company that owns the company.",
    dealStage: {
      dealStageName: "Negotiation",
      dealStageColor: "green",
      _id: "deal-5q",
    },
    actions: <AddCalculationsMenu />, // Rendering your component
    dealOwner: {
      dealOwnerName: "John Doe",
      dealOwnerAvatarUrl: "https://i.pravatar.cc/150?img=51",
      _id: "owner-1a",
    },
    dealValue: "$1M",
    company: {
      companyName: "Tech Corp",
      companyLogoUrl: "https://i.pravatar.cc/150?img=51",
    },
  },
];

const updateDealData = async (event: CellValueChangedEvent<DealData>) => {
  const updatedData = event.data;
  // Make an API call to update the data
  try {
    console.log("Data updated successfully:", updatedData);
  } catch (error) {
    console.error("Error updating data:", error);
  }
};

const DealTable = () => {
  const [rowData] = useState(dealRowData);
  const [columnDefs] = useState(dealColumnDefs);

  return (
    <DataTable<DealData>
      columnDefs={columnDefs}
      rowData={rowData}
      pinnedBottomRowData={pinnedBottomRowData}
      onCellValueChanged={updateDealData}
    />
  );
};

export default DealTable;
