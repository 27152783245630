import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { XCircleIcon } from "../../../images";
import {
  AiSummaryView,
  DetailsPoster,
  ItemInfoView,
  ItemInsightsView,
  ItemPropertiesView,
  VersionHistoryView,
} from "./view";
import styles from "./DetailsCloudStorage.module.css";

interface DetailsCloudStorageProps {
  closeDetails?: () => void;
}

const DetailsCloudStorage: React.FC<DetailsCloudStorageProps> = ({closeDetails}) => {
  return (
    <Stack className={styles["details-sidebar"]}>
      
      {/* ----  header and close button ---- */}
      <Stack className={styles["details-sidebar__header"]}>
        <Typography className={styles["details-sidebar__title"]}>
          Details
        </Typography>
        <IconButton
        onClick={ () => closeDetails && closeDetails() }
          className={styles["details-sidebar__close-button"]}
          disableRipple
        >
          <XCircleIcon w={15} color="#000" />
        </IconButton>
      </Stack>

      {/* ------------------*/}

      {/* ----- all view are here ----- */}
      <Stack className={styles["details-sidebar__body"]}>
        <DetailsPoster />
        <ItemInsightsView linkCount={5} shareCount={2} viewCount={10} />
        <ItemPropertiesView />
        <ItemInfoView
          ownerName="Jenifer Jane Jare"
          uploader="John"
          createdTimestamp="December 25th, 2023, 04:22 PM"
          lastModifyTimestamp="March 22th, 2024, 07:28 AM"
          size="12 MB"
        />
        <AiSummaryView />
        <VersionHistoryView />
      </Stack>
      {/* ------------------*/}
    </Stack>
  );
};

export default DetailsCloudStorage;
