import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CloudStorageState } from "./CloudStorageType";

const initialState: CloudStorageState = {
    error: null,
    data: {
        folders: [
            { id: "1", name: "Folder 1", parentFolderId: null, subFolders: [], files: [] },
            { id: "2", name: "Folder 2", parentFolderId: null, subFolders: [], files: [] },
        ],
        files: [
            { id: "101", name: "File1.pdf", type: "pdf", size: 1024, parentFolderId: "1" },
            { id: "102", name: "Image1.png", type: "png", size: 2048, parentFolderId: "2" },
        ],
    },
    selectedDrive: localStorage.getItem("selectedDrive") || "BearishOS", // Default to 'BearishOS' if no saved selection
    availableDrives: [
        { id: "bearishos-001", name: "Bearish OS", type: "BearishOS", connected: true },
        { id: "gdrive-001", name: "Google Drive", type: "GoogleDrive", connected: true },
        { id: "dropbox-001", name: "Dropbox", type: "Dropbox", connected: false },
        { id: "OneDrive-001", name: "One Drive", type: "OneDrive", connected: false },
        { id: "Box-001", name: "Box", type: "Box", connected: false },
    ],
};


const cloudStorage = createSlice({
    name: "cloudStorage",
    initialState,
    reducers: {
        setSelectedDrive: (state, { payload }: PayloadAction<string>) => {
            if (state.availableDrives.some((drive) => drive.name === payload)) {
                state.selectedDrive = payload;
                localStorage.setItem("selectedDrive", payload); // Save to localStorage
            } else {
                state.error = `Drive "${payload}" is not connected.`;
            }
        },
    },
});

export const { setSelectedDrive } = cloudStorage.actions;

export default cloudStorage.reducer;
