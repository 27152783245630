import React from "react";
import style from "./ThreePageMarker.module.css";
import { Box, IconButton } from "@mui/material";

interface ThreePageMarkerProps {
  totalPage: number;
  activePage: number;
  handleMoveStep?: (step: number) => void; // Specify the argument type
}

const ThreePageMarker: React.FC<ThreePageMarkerProps> = ({
  activePage,
  totalPage,
  handleMoveStep,
}) => {
  const pagesArray = Array.from({ length: totalPage });

  return (
    <Box className={`${style["threeDotMarker-container"]}`}>
      {pagesArray.map((_, index) => (
        <IconButton
          key={index}
          onClick={() => handleMoveStep && handleMoveStep(index + 1)}
          className={`${style["threeDotMarker"]}
           ${activePage === index + 1 ? style["threeDotMarker--active"] : style["threeDotMarker--inactive"]}`}
        />
      ))}
    </Box>
  );
};

export default ThreePageMarker;
