import { Box, Paper, Popper, Stack } from "@mui/material";
import React, { useState } from "react";
import { AvatarNormal } from "../AvatarNormal";
import { CrossIcon } from "../../../images";
import { SmallContactCardHover } from "../SmallContactCardHover";

interface AvatarWithHoverDetailsProps {
  popoverPlace: "bottom" | "right" | "up";
  userName: string;
  userEmail: string;
  userIconSrc?: string;
  userAvatarColor?: string;
}

const AvatarWithHoverDetails: React.FC<AvatarWithHoverDetailsProps> = ({
  popoverPlace,
  userAvatarColor,
  userEmail,
  userIconSrc,
  userName,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleChipLeave = () => {
    setAnchorEl(null);
  };

  const handleChipHover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const getPopperPlacement = () => {
    switch (popoverPlace) {
      case "bottom":
        return "bottom-start";
      case "right":
        return "right-start";
      case "up":
        return "top-start";
      default:
        return "bottom-start"; // Default value if none of the cases match
    }
  };

  return (
    <Stack
      onMouseEnter={handleChipHover}
      onMouseLeave={handleChipLeave}
      sx={{
        position: "relative",
        width: `35px`,
        height: `35px`,
        boxSizing: "border-box",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <AvatarNormal
        size={35}
        username={userName} // Corrected to use the passed prop instead of hardcoded value
        avatarColor={userAvatarColor}
        imgSrc={userIconSrc}
      />
      <Box
        sx={{
          bgcolor: "#BCBCBC",
          height: "10px",
          width: "10px",
          borderRadius: "50%", // Changed to '50%' for perfect circle
          position: "absolute",
          top: 0,
          right: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid #BCBCBC",
          boxSizing: "border-box",
          cursor: "pointer",
        }}
      >
        <CrossIcon w={10} />
      </Box>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement={getPopperPlacement()}
        onMouseLeave={handleChipLeave}
        sx={{
          zIndex: 10,
          border: "0px",
          borderRadius: "5px",
          padding: "5px",
        }}
      >
        <Paper
          sx={{
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
          }}
        >
          <SmallContactCardHover
            userEmail={userEmail}
            userName={userName}
            userIconSrc={userIconSrc}
            userAvatarColor={userAvatarColor}
          />
        </Paper>
      </Popper>
    </Stack>
  );
};

export default AvatarWithHoverDetails;
