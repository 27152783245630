/**
 * This file contains action creators for authentication related actions.
 */

// Importing action creators and types from authSlice
import { createApiAction } from "../constantApi_config";
import {
  loginRequestAction,
  loginSuccessAction,
  loginFailureAction,
  signUpRequestAction,
  signUpSuccessAction,
  signUpFailureAction,
  googleAuthRequestAction,
  googleAuthSuccessAction,
  googleAuthFailureAction,
  microsoftAuthRequestAction,
  microsoftAuthSuccessAction,
  microsoftAuthFailureAction,
  optSendRequestAction,
  optSendSuccessAction,
  optSendFailureAction,
  optVerificationRequestAction,
  optVerificationSuccessAction,
  optVerificationFailureAction,
  companyAvailibityRequestAction,
  companyAvailibitySuccessAction,
  companyAvailibityFailureAction,
  getAllDepartmentsRequestAction,
  getAllDepartmentsSuccessAction,
  getAllDepartmentsFailureAction,
  fetchCompanyListRequestAction,
  fetchCompanyListSuccessAction,
  fetchCompanyListFailureAction,
  getAllUsersFailureAction,
  getAllUsersSuccessAction,
  getAllUsersRequestAction,
} from "./authSlice";
// Importing types for login and signup payloads
import {
  ExternalAuthType,
  LoginPayload,
  SignUpPayload,
  companyAvailibityPayload,
  sendOptPayload,
  verifyOptPayload,
} from "./authTypes";

/**
 * Interface for defining the payload structure required for API requests.
 */

/**
 * Action creator for logging in a user.
 * @param userData - The login payload containing user credentials.
 * @returns Action object with type and payload.
 */
export const loginUser = (userData: LoginPayload) =>
  createApiAction({
    onStart: loginRequestAction.type,
    onSuccess: loginSuccessAction.type,
    onError: loginFailureAction.type,
    method: "POST",
    url: `/user/auth/login`,
    data: userData,
    headers: {
      language: "en",
    },
    axiosRequired: true, // Explicitly setting axiosRequired
  });

/**
 * Action creator for signing up a new user.
 * @param userData - The signup payload containing user details.
 * @returns Action object with type and payload.
 */
export const signupUser = (userData: SignUpPayload | FormData) =>
  createApiAction({
    onStart: signUpRequestAction.type,
    onSuccess: signUpSuccessAction.type,
    onError: signUpFailureAction.type,
    method: "POST",
    url: `/user/auth/sign-up`,
    data: userData,
    headers: {
      "Content-Type": "multipart/form-data",
      language: "en",
    },
    axiosRequired: true, // Explicitly setting axiosRequired
  });

/**
 * Action creator for sending OTP to a user via email.
 * @param eOptUser - The payload containing email and other details for OTP sending.
 * @returns Action object with type and payload.
 */
export const sendOptToUser = (eOptUser: sendOptPayload) =>
  createApiAction({
    onStart: optSendRequestAction.type,
    onSuccess: optSendSuccessAction.type,
    onError: optSendFailureAction.type,
    method: "POST",
    url: `/user/send-otp-email`,
    data: eOptUser,
    axiosRequired: true, // Explicitly setting axiosRequired
  });

/**
 * Action creator for checking the availability of a company.
 * @param caCompany - The payload containing the company domain for availability check.
 * @returns Action object with type and payload.
 */
export const companyRegistryCheck = (caCompany: companyAvailibityPayload) =>
  createApiAction({
    onStart: companyAvailibityRequestAction.type,
    onSuccess: companyAvailibitySuccessAction.type,
    onError: companyAvailibityFailureAction.type,
    method: "GET",
    url: `/company/check-company-availability?domain=${caCompany?.companyName}`,
    axiosRequired: true, // Explicitly setting axiosRequired
  });

/**
 * Action creator for fetching a list of companies.
 * @param caCompany - The payload containing the company name for fetching the list.
 * @returns Action object with type and payload.
 */
export const fetchCompanyLists = (caCompany: companyAvailibityPayload) =>
  createApiAction({
    onStart: fetchCompanyListRequestAction.type,
    onSuccess: fetchCompanyListSuccessAction.type,
    onError: fetchCompanyListFailureAction.type,
    method: "GET",
    url: `/company/fetch-company-list?companyName=${caCompany?.companyName}`,
    axiosRequired: true, // Explicitly setting axiosRequired
  });

/**
 * Action creator for fetching all departments.
 * @returns Action object with type and payload.
 */
export const getAllDepartment = () =>
  createApiAction({
    onStart: getAllDepartmentsRequestAction.type,
    onSuccess: getAllDepartmentsSuccessAction.type,
    onError: getAllDepartmentsFailureAction.type,
    method: "GET",
    url: `/department/get-all-department`,
    axiosRequired: true, // Explicitly setting axiosRequired
  });

/**
 * Action creator for verifying the OTP sent to a user.
 * @param vUser - The payload containing OTP and user details for verification.
 * @returns Action object with type and payload.
 */
export const verifyOptOfUser = (vUser: verifyOptPayload) =>
  createApiAction({
    onStart: optVerificationRequestAction.type,
    onSuccess: optVerificationSuccessAction.type,
    onError: optVerificationFailureAction.type,
    method: "POST",
    url: `/user/verify-otp-email`,
    data: vUser,
    axiosRequired: true, // Explicitly setting axiosRequired
  });

/**
 * Action creator for handling external authentication options.
 * @param eUserData - The payload containing external auth data and option type.
 * @returns Action object with type and payload.
 */
export const externalEntitiesOptions = (eUserData: ExternalAuthType) =>
  createApiAction({
    onStart:
      eUserData.optionAuth === "GoogleAuth"
        ? googleAuthRequestAction.type
        : microsoftAuthRequestAction.type,
    onSuccess:
      eUserData.optionAuth === "GoogleAuth"
        ? googleAuthSuccessAction.type
        : microsoftAuthSuccessAction.type,
    onError:
      eUserData.optionAuth === "GoogleAuth"
        ? googleAuthFailureAction.type
        : microsoftAuthFailureAction.type,
    method: "POST",
    url: `/user/auth/sign-up?code=${eUserData.optionAuth}`,
    data: eUserData,
    axiosRequired: true, // Explicitly setting axiosRequired
  });


    /**
 * Action creator for fetching all users.
 * @returns Action object with type and payload.
 */
export const getAllUsers = () =>
  createApiAction({
    onStart: getAllUsersRequestAction.type,
    onSuccess: getAllUsersSuccessAction.type,
    onError: getAllUsersFailureAction.type,
    method: "GET",
    url: `/user/?limit=10&page=1&sort=desc`,
    axiosRequired: true,  // Explicitly setting axiosRequired
  });

