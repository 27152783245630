import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LayoutState } from "./layoutTypes";

/**
 * ==============================================
 * Define the initial state for the Layout slice
 * ==============================================
 * The initial state includes boolean flags for
 * different sections of the layout. These flags
 * control the visibility of drawers in the application.
 * ==============================================
 */
const initialState: LayoutState = {
    isDrawer_Dashboard: true,
    isDrawer_Call: true,
    isDrawer_Email: true,
    isDrawer_Calendar: true,
    isDrawer_Workspace: true,
    isDrawer_Messages: true,
    isDrawer_CRM: true,
    isDrawer_Trax: true,
    isDrawer_Settings: true,
    isDrawer_Project : true,
    isDrawer_CloudStorage: true,
};

/**
 * ==============================================
 * Create a slice for Layout logic
 * ==============================================
 * The layoutSlice handles the layout-related state
 * and actions. It contains a reducer to manage
 * the state changes based on dispatched actions.
 * ==============================================
 */
const layoutSlice = createSlice({
    name: "layout",
    initialState,
    reducers: {
        /**
         * ----------------------------------------------
         * Action to handle layout changes
         * ----------------------------------------------
         * This reducer function updates the state based
         * on the type of drawer and its boolean value
         * provided in the action payload.
         *
         * @param {LayoutState} state - Current state of the layout
         * @param {PayloadAction<{ type: keyof LayoutState; value: boolean }>} action - Action with payload containing the type of drawer and the new value
         * ----------------------------------------------
         */
        changeLayoutState(
            state: LayoutState,
            action: PayloadAction<{ type: keyof LayoutState; value: boolean }>
        ) {
            const { type, value } = action.payload;
            if (type in state) {
                state[type] = value;
            }
        },
    },
});

/**
 * ==============================================
 * Export actions and reducer
 * ==============================================
 * The changeLayoutState action is exported for
 * dispatching changes to the layout state.
 * The reducer is exported to be used in the store
 * configuration.
 * ==============================================
 */
export const { changeLayoutState } = layoutSlice.actions;

export default layoutSlice.reducer;
