import { Box, Stack, Link as MuiLink } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import React from "react";
import { BearishOSIcon, SearchIcon } from "../../../images";
import { IconTextComponent } from "../Buttons";
import styles from "./DrawerNavbar.module.css";
import { RouteConfig } from "../../../Router/RouteTypes";

type DrawerNavbarProps = {
  ROUTES: RouteConfig[];
};

const DrawerNavbar: React.FC<DrawerNavbarProps> = ({ ROUTES }) => {
  const location = useLocation();
  return (
    <Stack
      direction="row"
      flexGrow={1}
      zIndex={1200}
      className={styles.drawerNavbar__container}
    >
      {/* icons list and these are fixed in page */}
      <Stack alignItems="center" className={styles.drawerNavbar__iconList}>
        <BearishOSIcon w={30} />
        <Box className={styles.drawerNavbar__iconDivider} />
        <Stack className={styles.drawerNavbar__icons}>
          {/* iconButton one- search icon */}
          <IconTextComponent isActive={false} label="Search">
            <SearchIcon color="black" />
          </IconTextComponent>

          {ROUTES.filter(
            (route) =>
              route.isPrivate && route.useCommonLayout && route.isDrawerTab
          ).map((route, index) => {
            const IconComponent = route.icon;
            return (
              <MuiLink
                component={Link}
                key={index}
                to={route.path}
                sx={{ color: "black", textDecoration: "none" }}
              >
                {" "}
                <IconTextComponent
                  isActive={location.pathname.startsWith(route.path)}
                  label={route.name}
                >
                  {route.icon && IconComponent && <IconComponent />}
                </IconTextComponent>
              </MuiLink>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DrawerNavbar;
