import { Stack, SxProps } from "@mui/material";
import React, { ReactNode } from "react";

interface AttechmentViewContainerProps {
  children: ReactNode;
  sx?: SxProps;
}

const AttechmentViewContainer: React.FC<AttechmentViewContainerProps> = ({
  children,
  sx,
}) => {
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        overflowX: "auto",
        padding: "0px 0px 5px 0px",
        overflowY: "hidden", // Hide vertical scrollbar
        gap: "20px",
        "&::-webkit-scrollbar": {
          height: "5px", // Adjust horizontal scrollbar height
          width: "10px", // Adjust vertical scrollbar width
        },

        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent", // Scrollbar track (background) color
        },

        "&:hover::-webkit-scrollbar-track": {
          backgroundColor: "#f1f1f1", // Scrollbar track (background) color
        },

        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "transparent", // Make the scrollbar thumb transparent
          borderRadius: "10px", // Round scrollbar thumb
        },
        "&:hover::-webkit-scrollbar-thumb": {
          backgroundColor: "#888", // Scrollbar thumb color on hover
        },
        "&:hover::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#555", // Scrollbar thumb color on hover
        },
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
};

export default AttechmentViewContainer;
