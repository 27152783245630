import {
  AddColumnIcon,
  AudioIcon,
  BoardIcon,
  BookmarkIcon,
  BulletpointsIcon,
  ButtonIcon,
  // CalendarIcon,
  CalendarViewIcon,
  ChecklistIcon,
  CodeIcon,
  DiagramIcon,
  DocumentIcon,
  EquationIcon,
  FileIcon,
  GanttIcon,
  InlineIcon,
  // LockIcon,
  NotificationIcon,
  NumberedIcon,
  QuoteIcon,
  StrongestrelationshipIcon,
  SyncIcon,
  TableBoldIcon,
  TableIcon,
  TimelineIcon,
  TreeIcon,
  VideoIcon,
  WhiteboardIcon,
  ConnectionIcon,
  DividerIcon,
  documentCommandsMenuPng,
  FroalaIcon  
} from "../../../images";

// Define the base type for each item in the items array
interface BaseDocumentCommandItem {
  title: string;
  description: string;
  onClick: () => void;
  componentType: string;
  isBuild?: boolean;
}

// Define the type for items with an icon
interface IconDocumentCommandItem extends BaseDocumentCommandItem {
  icon: React.ElementType | string;
  text?: never;
}

// Define the type for items with text
interface TextDocumentCommandItem extends BaseDocumentCommandItem {
  text: string;
  icon?: never;
}

// Union type for DocumentCommandItem
type DocumentCommandItem = IconDocumentCommandItem | TextDocumentCommandItem;

// basic element
const BASIC_ELEMENTS: DocumentCommandItem[] = [
  {
    title: "Text",
    description: "Start writing with just plain text",
    text: "Aa",
    isBuild: true,
    componentType: "text",
    onClick: () => console.log("Lock page clicked"),
  },
  {
    title: "Checklist",
    description: "Track tasks with a simple checklist",
    icon: ChecklistIcon, // Use a placeholder component for now
    isBuild: true,
    componentType: "checklist",
    onClick: () => console.log("Image item clicked"),
  },
  {
    title: "Heading 1",
    description: "Large section heading",
    text: "H1",
    isBuild: true,
    componentType: "headinglarge",
    onClick: () => console.log("Image item clicked"),
  },
  {
    title: "Heading 2",
    description: "Medium section heading",
    text: "H2",
    isBuild: true,
    componentType: "headingmedium",
    onClick: () => console.log("Image item clicked"),
  },
  {
    title: "Heading 3",
    description: "Small section heading",
    text: "H3",
    isBuild: true,
    componentType: "headingsmall",
    onClick: () => console.log("Image item clicked"),
  },
  {
    title: "Table",
    description: "Add A simple table to your page",
    icon: TableIcon, // Use a placeholder component for now
    isBuild: true,
    componentType: "table",
    onClick: () => console.log("Image item clicked"),
  },
  {
    title: "WhiteBoard",
    description: "Add a simple whiteboard",
    icon: WhiteboardIcon,
    isBuild: false,
    componentType: "whiteboard",
    onClick: () => console.log("Image item clicked"),
  },
  {
    title: "Calendar",
    description: "Add a calender to view events",
    icon: CalendarViewIcon, // Use a placeholder component for now
    isBuild: false,
    componentType: "calendar",
    onClick: () => console.log("Image item clicked"),
  },
  {
    title: "Board",
    description: "Add a board view with tasks",
    icon: BoardIcon,
    isBuild: false,
    componentType: "board",
    onClick: () => console.log("Image item clicked"),
  },
  {
    title: "Gantt",
    description: "Add a gantt view and tasks",
    icon: GanttIcon,
    isBuild: false,
    componentType: "gantt",
    onClick: () => console.log("Image item clicked"),
  },
  {
    title: "Timeline",
    description: "Add a timeline view with tasks",
    icon: TimelineIcon,
    isBuild: false,
    componentType: "timeline",
    onClick: () => console.log("Image item clicked"),
  },
  {
    title: "List",
    description: "Create a basic list of items",
    icon: TimelineIcon,
    isBuild: false,
    componentType: "list",
    onClick: () => console.log("Image item clicked"),
  },
  {
    title: "Bulleted list",
    description: "Create a simplified bulleted list",
    icon: BulletpointsIcon, // Use a placeholder component for now
    isBuild: false,
    componentType: "textbulletedlist",
    onClick: () => console.log("Image item clicked"),
  },
  {
    title: "Numbered list",
    description: "Create a sampled numbered list",
    icon: NumberedIcon, // Use a placeholder component for now
    isBuild: false,
    componentType: "textnumberedlist",
    onClick: () => console.log("Image item clicked"),
  },
  {
    title: "Tree list",
    description: "Create a tree view list that toggles",
    icon: TreeIcon, // Use a placeholder component for now
    isBuild: false,
    componentType: "tree",
    onClick: () => console.log("Image item clicked"),
  },
  {
    title: "Quote",
    description: "Add a custom Quote block",
    icon: QuoteIcon, // Use a placeholder component for now
    isBuild: false,
    componentType: "textquote",
    onClick: () => console.log("Image item clicked"),
  },
  {
    title: "Divider",
    description: "Create a visual section divider",
    icon: DividerIcon, // Use a placeholder component for now
    isBuild: false,
    componentType: "divider",
    onClick: () => console.log("Image item clicked"),
  },
  {
    title: "Connect",
    description: "Create a connection to an item",
    icon: ConnectionIcon, // Use a placeholder component for now
    isBuild: false,
    componentType: "Some",
    onClick: () => console.log("Image item clicked"),
  },
  {
    title: "Call out",
    description: "Add a section to call out text",
    icon: InlineIcon, // Use a placeholder component for now
    isBuild: false,
    componentType: "callout",
    onClick: () => console.log("Image item clicked"),
  },
  {
    title: "Code",
    description: "Add a block for code in text",
    icon: CodeIcon, // Use a placeholder component for now
    isBuild: false,
    componentType: "code",
    onClick: () => console.log("Image item clicked"),
  },
  {
    title: "file",
    description: "Upload or add an outside file",
    icon: FileIcon, // Use a placeholder component for now
    isBuild: false,
    componentType: "file",
    onClick: () => console.log("Image item clicked"),
  },
  {
    title: "Froala",
    description: "Add dynamic text content",
    icon: FroalaIcon, // Use a placeholder component for now
    isBuild: false,
    componentType: "froalaeditor", 
    onClick: () => console.log("Image item clicked"),
  },
];

const MEDIA_ELEMENTS: DocumentCommandItem[] = [
  {
    title: "Image",
    description: "Add a image or images",
    icon: documentCommandsMenuPng,
    isBuild: false,
    componentType: "image",
    onClick: () => console.log("Lock page clicked"),
  },
  {
    title: "Video",
    description: "Add a video or videos",
    icon: VideoIcon,
    isBuild: false,
    componentType: "video",
    onClick: () => console.log("Lock page clicked"),
  },
  {
    title: "Audio",
    description: "Add an audio file",
    icon: AudioIcon,
    isBuild: false,
    componentType: "Some",
    onClick: () => console.log("Lock page clicked"),
  },
  {
    title: "Internet bookmark",
    description: "Save a link from the internet",
    icon: BookmarkIcon,
    isBuild: false,
    componentType: "Some",
    onClick: () => console.log("Lock page clicked"),
  },
];

const ADVANCED_ELEMENTS: DocumentCommandItem[] = [
  {
    title: "table of contents",
    description: "Show an outline of your entire page",
    icon: TableBoldIcon,
    isBuild: false,
    componentType: "Some",
    onClick: () => console.log("Lock page clicked"),
  },
  {
    title: "Block equation",
    description: "Display and create math equations",
    icon: EquationIcon,
    isBuild: false,
    componentType: "Some",
    onClick: () => console.log("Lock page clicked"),
  },
  {
    title: "Button",
    description: "Run automations and actions",
    icon: ButtonIcon,
    isBuild: false,
    componentType: "Some",
    onClick: () => console.log("Lock page clicked"),
  },
  {
    title: "Sync elements",
    description: "Allow elements to be interconneted",
    icon: SyncIcon,
    isBuild: false,
    componentType: "Some",
    onClick: () => console.log("Lock page clicked"),
  },
  {
    title: "Connect elements",
    description: "Connect elements within workspace",
    icon: ConnectionIcon,
    isBuild: false,
    componentType: "Some",
    onClick: () => console.log("Lock page clicked"),
  },
  {
    title: "Heading 1 Toggles",
    description: "Hide items within as element",
    text: "H1",
    isBuild: false,
    componentType: "Some",
    onClick: () => console.log("Lock page clicked"),
  },
  {
    title: "Heading 2 Toggles",
    description: "Hide items within as element",
    text: "H2",
    isBuild: false,
    componentType: "Some",
    onClick: () => console.log("Lock page clicked"),
  },
  {
    title: "Heading 3 Toggles",
    description: "Hide items within as element",
    text: "H3",
    isBuild: false,
    componentType: "Some",
    onClick: () => console.log("Lock page clicked"),
  },
  {
    title: "Create 2 columns",
    description: "Create 2 columns within page",
    icon: AddColumnIcon,
    isBuild: false,
    componentType: "Some",
    onClick: () => console.log("Lock page clicked"),
  },
  {
    title: "Create 3 columns",
    description: "Create 3 columns within page",
    icon: AddColumnIcon,
    isBuild: false,
    componentType: "Some",
    onClick: () => console.log("Lock page clicked"),
  },
  {
    title: "Create 4 columns",
    description: "Create 4 columns within page",
    icon: AddColumnIcon,
    isBuild: false,
    componentType: "Some",
    onClick: () => console.log("Lock page clicked"),
  },
  {
    title: "Code diagram",
    description: "Create a diagram with code",
    icon: DiagramIcon,
    isBuild: false,
    componentType: "Some",
    onClick: () => console.log("Lock page clicked"),
  },
];

const INLINE_ELEMENTS: DocumentCommandItem[] = [
  {
    title: "Person",
    description: "Connect page to person",
    icon: StrongestrelationshipIcon,
    isBuild: false,
    componentType: "Some",
    onClick: () => console.log("Lock page clicked"),
  },
  {
    title: "Page",
    description: "Mention a certain page",
    icon: DocumentIcon,
    isBuild: false,
    componentType: "Some",
    onClick: () => console.log("Lock page clicked"),
  },
  {
    title: "Reminder",
    description: "Set a reminder about page",
    icon: NotificationIcon,
    isBuild: false,
    componentType: "Some",
    onClick: () => console.log("Lock page clicked"),
  },
  {
    title: "Video report",
    description: "Create a previous video call",
    icon: VideoIcon,
    isBuild: false,
    componentType: "Some",
    onClick: () => console.log("Lock page clicked"),
  },
];
export { BASIC_ELEMENTS, MEDIA_ELEMENTS, ADVANCED_ELEMENTS, INLINE_ELEMENTS };
