import { Box, Fade } from "@mui/material";
import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { CrmComponentMap } from "./OverViewTypes"; // Adjust import as needed

const OverView: React.FC = () => {
  const location = useLocation();
  const { pathname } = location;

  // Extract base path and ID from pathname
  const basePath = pathname.split("/")[2]; // e.g., "people", "company", "deal"
  const id = pathname.split("/")[3]; // e.g., "personId", "companyId", "dealId"

  // Determine the component to render based on the current path
  let Component = null;
  switch (basePath) {
    case "people":
      Component = CrmComponentMap["/CRM/people"];
      break;
    case "company":
      Component = CrmComponentMap["/CRM/company"];
      break;
    case "deal":
      Component = CrmComponentMap["/CRM/deal"];
      break;
    default:
      // Handle unknown paths
      return <Navigate to="/404" />;
  }

  // Prepare props based on the path
  const props = { [`${basePath}Id`]: id };

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Fade in={true} timeout={{ enter: 200, exit: 200 }}>
        <Box sx={{ width: "100%" }}>
          {Component ? <Component {...props} /> : null}
        </Box>
      </Fade>
    </Box>
  );
};

export default OverView;
