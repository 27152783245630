import React, { useState, MouseEvent } from "react";
import { Stack, Tooltip, Menu, MenuItem, Typography } from "@mui/material";
import { ChevronIcon, DecoratedTIcon } from "../../../images";

interface FontPickerDropdownProps {
  onSelect: (font: string) => void;
  height?: string;
  initialValue?: string;
}

const fontOptions = [
  "Source Service Pro",
  "Arial",
  "Courier New",
  "Georgia",
  "Times New Roman",
  "Verdana",
  "Roboto",
  "Helvetica",
  "Tahoma",
  "Trebuchet MS",
  "Comic Sans MS",
];

const FontPickerdDropdown: React.FC<FontPickerDropdownProps> = ({
  onSelect,
  height = "170px",
  initialValue = fontOptions[2],
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedFont, setSelectedFont] = useState<string>(initialValue);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (font: string) => {
    setSelectedFont(font);
    onSelect(font);
    handleClose();
  };

  return (
    <>
      <Tooltip title="font style" placement="top" arrow>
        <Stack
          sx={{
            width: "120px",
            height: "15px",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handleClick}
        >
          <Typography
            sx={{
              maxWidth: "130px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontFamily:
                selectedFont !== "Select font" ? selectedFont : "inherit",
            }}
          >
            {selectedFont}
          </Typography>
          <ChevronIcon w={15} direction={open ? "down" : "up"} />
        </Stack>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        PaperProps={{
          style: {
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            maxHeight: height,
            width: "150px",
            overflowY: "scroll",
            marginTop: "10px",
            scrollbarWidth: "none", // Hide scrollbar in Firefox
            msOverflowStyle: "none", // Hide scrollbar in IE and Edge
          },
        }} container={ document && document?.getElementById("full-ScreenMode")}
        MenuListProps={{
          sx: {
            "&::-webkit-scrollbar": {
              display: "none", // Hide scrollbar in Chrome, Safari, and Opera
            },
          },
        }}
        onClose={handleClose}
      >
        {fontOptions.map((font) => (
          <MenuItem
            key={font}
            onClick={() => handleMenuItemClick(font)}
            sx={{
              height: "30px",
              alignItems: "center",
              padding: "0px 10px",
              boxSizing: "border-box",
              minHeight: 0,
            }}
          >
            <Typography
              sx={{
                color: "black",
                fontSize: 14,
                fontFamily: font,
                fontWeight: "400",
                wordWrap: "break-word",
              }}
            >
              {font}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default FontPickerdDropdown;
