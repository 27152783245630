import * as React from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { TextField } from "@mui/material";

interface SmallTimePickerProps {}

const SmallTimePicker: React.FC<SmallTimePickerProps> = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={[
          "TimePicker",
          "MobileTimePicker",
          "DesktopTimePicker",
          "StaticTimePicker",
        ]}
      >
        <MobileTimePicker
          defaultValue={dayjs()}
          slots={{
            textField: (params) => (
              <TextField
                {...params}
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "5px 10px 5px 10px", // Modify this value to change the padding
                    width: "100px",
                    height: "30px",
                    font: "normal normal 300 13px/17px Source Serif Pro",
                  },
                }}
              />
            ),
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default SmallTimePicker;
