import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";

interface ChannelNameCardProps {
  channelName: string;
  channelDetails: string;
  timestamp: string;
  companyLogoSrc: string;
  channelIconSrc?: string;
}

const ChannelNameCard: React.FC<ChannelNameCardProps> = ({
  channelDetails,
  channelName,
  companyLogoSrc,
  timestamp,
  channelIconSrc,
}) => {
  return (
    <Stack
      sx={{
        flexShrink: 0,
        height: "45px",
        px: "5px",
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
        flexGrow: 1,
        boxSizing: "border-box",
        borderRadius: "5px",
        width: "100%",
        bgcolor: "white",
        ":hover": { bgcolor: adjustHexColor("#fff", 104) },
        cursor: "default",
      }}
    >
      <Box
        component={"img"}
        alt="logo"
        src={channelIconSrc}
        sx={{ height: "30px", width: "30px" }}
      />

      <Stack
        sx={{
          gap: "3px",
          flexDirection: "column",
          width: "calc(100% - (25px + 30px + 30px))",
        }}
      >
        <Typography
          sx={{
            font: "normal normal 600 11px/14px Source Serif Pro",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            flexGrow: 1,
          }}
        >
          {channelName}
        </Typography>
        <Typography
          sx={{
            font: "normal normal 300 8px/10px Source Serif Pro",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "normal", // Allow text to wrap
            display: "-webkit-box", // Enable webkit box model
            WebkitBoxOrient: "vertical", // Set the orientation to vertical
            WebkitLineClamp: 2, // Limit to 2 lines
            lineClamp: 2, // Standard property for non-webkit browsers
            flexGrow: 1,
          }}
        >
          {channelDetails}
        </Typography>
      </Stack>

      <Stack
        sx={{
          gap: "5px",
          width: "40px",
          flexShrink: 0,
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ font: "normal normal 300 8px/10px Source Serif Pro" }}
        >
          {timestamp}
        </Typography>
        <Box
          component={"img"}
          sx={{ height: "15px", width: "15px" }}
          src={companyLogoSrc}
          alt="logo"
        />
      </Stack>
    </Stack>
  );
};

export default ChannelNameCard;
