import { Country, ICountry } from "country-state-city";
import { getAllTimezones, Timezone } from "countries-and-timezones";

/**
 * Interface representing a location option with id and name properties.
 */
export interface ILocationOption {
  id: string; // Unique identifier for the location (country ISO code)
  name: string; // Name of the location (country name)
}

/**
 * Interface representing a timezone option with id and name properties.
 */
export interface ITimezoneOption {
  id: string; // Unique identifier for the timezone (timezone key)
  name: string; // Name of the timezone
}

/**
 * Retrieves all countries and formats them for use in a dropdown selection.
 *
 * @returns {ILocationOption[]} An array of objects, each containing the id (ISO code) and name of a country.
 */
export function getAllCountries(): ILocationOption[] {
  const countries: ICountry[] = Country.getAllCountries(); // Fetch all country data
  return countries.map(
    (country): ILocationOption => ({
      id: country.isoCode, // Use ISO code as id
      name: country.name, // Use country name as name
    })
  );
}

/**
 * Retrieves all timezones and formats them for use in a dropdown selection.
 *
 * @returns {ITimezoneOption[]} An array of objects, each containing the id (timezone key) and name of a timezone.
 */
export function getAllTimezonesFormatted(): ITimezoneOption[] {
  const timezones: Record<string, Timezone> = getAllTimezones(); // Fetch all timezone data
  return Object.keys(timezones).map(
    (timezoneKey): ITimezoneOption => ({
      id: timezoneKey, // Use timezone key as id
      name: timezones[timezoneKey].name || timezoneKey, // Use timezone name if available, otherwise use the key as name
    })
  );
}
