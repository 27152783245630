import React, { useEffect, useState } from "react";
import { BearishOSIcon, Loginbg2x } from "../../../images";
import { useTranslation } from "react-i18next";
import g from "./Login.module.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import {
  ButtonType,
  ButtonXLarge,
  ButtonXLargeColor,
  LargeInputBox,
  SignupOption,
  UnfilledButtonXLarge,
} from "../../common";
import { Stack, Typography } from "@mui/material";
import { SIGN_UP_OPTIONS_CONTANTS } from "../../../Utils";
import { AuthState, loginUser } from "../../../redux";

const containerStyle = {
  backgroundImage: `url(${Loginbg2x})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  // Add other styles as needed
};

const Login = () => {
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const [loaderLogin, setLoaderLogin] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated, user, error } = useSelector(
    (state: RootState) => state.auth as AuthState
  );

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoaderLogin(true);
    const payload = {
      email: username,
      password: password,
    };

    await dispatch(loginUser(payload));
    // navigate("/profile-Mapping");
  };

  // Effect to handle successful login
  useEffect(() => {
    if (isAuthenticated && user && !error) {
      setLoaderLogin(false);
      // Store user token in localStorage
      localStorage.setItem("token", user.token);
      // Navigate to '/dashboard'
      navigate("/dashboard");
    } else if (error !== null) {
      setLoaderLogin(false);
    }
  }, [isAuthenticated, user, error, navigate]);

  return (
    <div className={`${g.container}`} style={containerStyle}>
      <div className={`${g.container_two}`}>
        <BearishOSIcon w={75} />
        <Typography
          children={t("wb")}
          variant="h1"
          sx={{
            font: "normal normal 600 40px/54px Source Serif Pro",
            margin: "30px 0 0 0",
          }}
        />
        <form
          style={{ gap: "0", padding: "0" }}
          autoComplete="off"
          className={g.form}
          onSubmit={handleLogin}
        >
          <Stack direction={"column"} gap={"25px"}>
            {" "}
            <LargeInputBox
              label={t("ea")}
              value={username}
              onChange={(e) => {
                const value = typeof e === "string" ? e : e.target.value;
                setUsername(value);
              }}
            />
            <LargeInputBox
              type="password"
              label={t("pw")}
              value={password}
              onChange={(e) => {
                const value = typeof e === "string" ? e : e.target.value;
                setPassword(value);
              }}
            />
          </Stack>

          <Stack
            onClick={() => navigate("/forgot-password")}
            width={"100%"}
            alignItems={"flex-end"}
            justifyContent={"flex-start"}
            mt={"8px"}
            mb={"20px"}
          >
            <Typography children={"Forgot Password"} className={`${g.fp}`} />
          </Stack>

          <ButtonXLarge
            colorVarient={ButtonXLargeColor.Orange}
            buttonSize="default"
            label={t("Login")}
            fullwidth={true}
            types={ButtonType.Submit}
            loaderLogin={loaderLogin}
          />
        </form>

        <SignupOption index={1} />

        <Stack mt={"20px"} width={"100%"} direction={"column"} gap={"20px"}>
          {SIGN_UP_OPTIONS_CONTANTS.map((oi) => {
            return (
              <UnfilledButtonXLarge
                key={oi.index}
                title={t(oi.title)}
                iconSvg={oi.i}
              />
            );
          })}
        </Stack>
      </div>
    </div>
  );
};

export default Login;
