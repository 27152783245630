import {
  DocBlueIcon,
  EmailIcon,
  FolderIcon,
  Mp3OrangeIcon,
  Mp4PurpleIcon,
  PdfRedIcon,
  PngGreenIcon,
  PptRedIcon,
  XlsGreenIcon,
} from "../../../images";
import { AttachmentTypeEnum } from "./FileTypeIconType";

interface FileTypeIconProps {
  filetype: AttachmentTypeEnum;
  iconSize?: number;
}

export const FileTypeIcon: React.FC<FileTypeIconProps> = ({
  filetype,
  iconSize = 20,
}) => {
  switch (filetype) {
    case AttachmentTypeEnum.FOLDER:
      return <FolderIcon color="#a53a01" w={iconSize} />;
    case AttachmentTypeEnum.PDF:
      return <PdfRedIcon w={iconSize} />;
    case AttachmentTypeEnum.DOC:
      return <DocBlueIcon w={iconSize} />;
    case AttachmentTypeEnum.EMAIL:
      return <EmailIcon w={iconSize} />;
    case AttachmentTypeEnum.PPT:
      return <PptRedIcon w={iconSize} />;
    case AttachmentTypeEnum.MP4:
      return <Mp4PurpleIcon w={iconSize} />;
    case AttachmentTypeEnum.MP3:
      return <Mp3OrangeIcon w={iconSize} />;
    case AttachmentTypeEnum.PNG:
      return <PngGreenIcon w={iconSize} />;
    case AttachmentTypeEnum.XLS:
      return <XlsGreenIcon w={iconSize} />;
    default:
      return null;
  }
};
