import { useSnackbar, SnackbarKey } from "notistack";

// Define a type for different Snackbar variants
type CustomVariantType = "default" | "success" | "error" | "warning" | "info";

// Define the function signature for enqueueing Snackbar notifications
type EnqueueSnackbarFn = (
  variant: CustomVariantType, // Type of Snackbar notification (success, error, etc.)
  message: string, // Message to display in the Snackbar
  options?: {
    // Optional parameter for additional options
    position?: {
      // Positioning options for the Snackbar
      horizontal: "left" | "center" | "right"; // Horizontal position (left, center, right)
      vertical: "top" | "bottom"; // Vertical position (top, bottom)
    };
    autoHideDuration?: number; // Optional auto hide duration in milliseconds
  }
) => SnackbarKey | null; // Return type: SnackbarKey or null

/**
 * Custom hook for displaying Snackbars using the notistack library.
 * This hook provides a function to enqueue Snackbars with custom options.
 * @returns EnqueueSnackbarFn - Function to enqueue a Snackbar notification
 */
export function useCustomSnackbar(): EnqueueSnackbarFn {
  // Destructure the enqueueSnackbar function from the useSnackbar hook provided by notistack
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Customized version of enqueueSnackbar function.
   * Enqueues a Snackbar with specified variant, message, and optional options.
   * @param variant - Type of notification (success, error, warning, info)
   * @param message - Message to be displayed in the notification
   * @param options - Optional object with additional configuration options:
   *                  - position: Specifies where the Snackbar should appear on the screen
   *                             Defaults to bottom center if not provided.
   *                  - autoHideDuration: Optional duration in milliseconds after which
   *                                      the Snackbar automatically dismisses.
   * @returns SnackbarKey or null - A key representing the Snackbar or null if enqueue fails
   */
  const enqueueSnackbar_v1: EnqueueSnackbarFn = (variant, message, options) => {
    // Destructure position and autoHideDuration from options, defaulting to empty object and 400ms respectively
    const { position, autoHideDuration } = options || {};

    // Define default options for the Snackbar
    const defaultOptions = {
      variant, // Set the Snackbar variant (success, error, etc.)
      autoHideDuration: autoHideDuration !== undefined ? autoHideDuration : 400, // Set auto hide duration or default to 400ms
      anchorOrigin: {
        // Set the anchor origin based on position object or default to bottom left
        vertical: position?.vertical || "bottom",
        horizontal: position?.horizontal || "left",
      },
    };

    // Enqueue the Snackbar using notistack's enqueueSnackbar function with defaultOptions
    return enqueueSnackbar(message, defaultOptions);
  };

  // Return the customized enqueueSnackbar function for use in components
  return enqueueSnackbar_v1;
}
