import React, { ReactNode, useEffect, useState } from "react";
import { Box, CssBaseline, Stack } from "@mui/material";
import { ROUTES } from "../../../Router/index.utils";
import g from "./CommonContainer.module.css";
import { DrawerNavbar, TopNavbar } from "../../common";
import { styled } from "@mui/material/styles";
import { LayoutState } from "../../../redux/Slice/Layout/layoutTypes";
import { RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { pathToDrawerStateKey } from "./CommanDrawerType";
import { DrawerManager } from "./DrawerManager";
import { changeLayoutState } from "../../../redux";

interface CommonContainerProps {
  children: ReactNode;
}

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(0), // Set padding manually in all components pages for better evaluating layout
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${215}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const CommonContainer: React.FC<CommonContainerProps> = ({ children }) => {
  const location = useLocation();
  const layoutState = useSelector(
    (state: RootState) => state.layout as LayoutState
  );
  const dispatch = useDispatch();

  const [drawerOpen, setDrawerOpen] = useState<LayoutState>(layoutState);

  useEffect(() => {
    const currentPath = location.pathname;

    const drawerKey = Object.keys(pathToDrawerStateKey).find((key) =>
      currentPath.startsWith(key)
    );

    if (drawerKey) {
      const drawerType = pathToDrawerStateKey[drawerKey];
      if (drawerOpen[drawerType] === undefined) {
        setDrawerOpen((prev) => ({
          ...prev,
          [drawerType]: false,
        }));
      }
    }
  }, [location.pathname, drawerOpen]);

  const handleChangeDrawer = () => {
    const currentPath = location.pathname;
    const drawerKey = Object.keys(pathToDrawerStateKey).find((key) =>
      currentPath.startsWith(key)
    );

    if (drawerKey) {
      const drawerType = pathToDrawerStateKey[drawerKey];
      dispatch(
        changeLayoutState({ type: drawerType, value: !drawerOpen[drawerType] })
      );
      setDrawerOpen((prev) => ({
        ...prev,
        [drawerType]: !prev[drawerType],
      }));
    }
  };

  // Determine if any drawer should be open based on the current path
  const isAnyDrawerOpen = Object.keys(pathToDrawerStateKey).some(
    (key) =>
      location.pathname.startsWith(key) && drawerOpen[pathToDrawerStateKey[key]]
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <TopNavbar
        drawerOpen={isAnyDrawerOpen}
        drawerOpenOrCloser={() => handleChangeDrawer()}
      />

      <CssBaseline />
      <Stack
        sx={{
          flexShrink: 0,
          "& .MuiPaper-root": {
            boxSizing: "border-box",
            alignItems: "center",
            backgroundColor: "#EDF1F5",
            zIndex: "1202 !important",
          },
        }}
        className={g.drawerbearish20}
      >
        <DrawerNavbar ROUTES={ROUTES} />
        {Object.keys(pathToDrawerStateKey).map((path, index) => (
          <DrawerManager
            key={index + path}
            drawerOpen={drawerOpen}
            handleChangeDrawer={() => handleChangeDrawer()}
          />
        ))}
      </Stack>
      <Main
        open={isAnyDrawerOpen}
        sx={{
          flexGrow: 1,
          backgroundColor: "#FFFFFF",
          marginLeft: isAnyDrawerOpen ? "270px" : "55px",
          minHeight: "93.68vh",
          maxHeight: "93.68vh",
          overflow: "scroll", // Allow scrolling
          overflowX: "hidden", // Prevent horizontal scrolling
          overflowY: "scroll", // Allow vertical scrolling
          scrollbarWidth: "none", // Hide scrollbar in Firefox
          "-ms-overflow-style": "none", // Hide scrollbar in IE and Edge
          "&::-webkit-scrollbar": {
            display: "none", // Hide scrollbar in WebKit-based browsers
          },
          border: "5px solid transparent",
        }}
      >
        {children}
      </Main>
    </Box>
  );
};

export default CommonContainer;
