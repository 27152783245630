import { Stack, Typography } from "@mui/material";
import React from "react";
import g from "./checkliststyle.module.css";
type Props = {};

const Smallheader = (props: Props) => {
  return (
    <div style={{ position: "relative" }}>
      <Stack
        p={1}
        borderRadius={1}
        width={"100%"}
        direction={"row"}
        alignItems={"center"}
        position="absolute"
        left={'20px'}
      >
        <Stack sx={{ mr: "250px", ml: "105px" }}>
          <Typography className={g.h__header__checklist}>Name</Typography>
        </Stack>
        <Stack sx={{ mr: "30px", ml: "123px" }}>
          <Typography className={g.h__header__checklist}>Assignee</Typography>
        </Stack>
        <Stack sx={{ mr: "32px" }}>
          <Typography className={g.h__header__checklist}>Priority</Typography>
        </Stack>
        <Stack sx={{ mr: "30px", ml: "33px" }}>
          <Typography className={g.h__header__checklist}>Due Date</Typography>
        </Stack>
        <Stack sx={{ mr: "38px", ml: "93px" }}>
          <Typography className={g.h__header__checklist}>Add</Typography>
        </Stack>
      </Stack>
    </div>
  );
};

export default Smallheader;
