import * as React from "react";
import {
  useAutocomplete,
  AutocompleteGetTagProps,
} from "@mui/base/useAutocomplete";
import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { DropdownWithImgBadgeInterface } from "./DropdownWithImageBadgeTypes";
import { AvatarNormal } from "../AvatarNormal";
import { Paper, Popper, Stack, Typography } from "@mui/material";
import { XCircleIcon } from "../../../images";
import { SmallContactCardHover } from "../SmallContactCardHover";

// Styled components
const Root = styled("div")(
  ({ theme }) => `
  width:100%;
  box-sizing: border-box;
  color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"
  };
  font-size: 14px;
`
);

const InputWrapper = styled("div")(
  () => `
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  & input {
    color: #000;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
    font:normal normal 300 13px/16px Source Serif Pro;
  }
`
);

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  userData: DropdownWithImgBadgeInterface;
  popoverPlace?: "bottom" | "right" | "left" | "top";
}

function Tag(props: TagProps) {
  const { userData, popoverPlace = "bottom", onDelete, ...other } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  const handleChipHover = (event: React.MouseEvent<HTMLElement>) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setAnchorEl(event.currentTarget);
  };

  const handleChipLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setAnchorEl(null);
    }, 0);
  };

  const getPopperPlacement = () => {
    switch (popoverPlace) {
      case "bottom":
        return "bottom-start";

      case "top":
        return "top-start";

      case "left":
        return "left-start";

      case "right":
        return "right-start";

      default:
        return "bottom-start"; // Default value if none of the cases match
    }
  };

  return (
    <div
      {...other}
      onMouseEnter={handleChipHover}
      onMouseLeave={handleChipLeave}
    >
      <AvatarNormal
        size={20}
        username={userData.name}
        imgSrc={userData.userIconSrc}
        avatarColor="green"
      />
      <span style={{ flexGrow: 1 }}>{userData.name}</span>
      <span style={{ display: "flex", flexShrink: 0 }} onClick={onDelete}>
        <XCircleIcon w={15} />
      </span>

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement={getPopperPlacement()}
        onMouseLeave={handleChipLeave}
        sx={{
          zIndex: 10,
          border: "0px",
          borderRadius: "5px",
        }}
      >
        <Paper
          sx={{
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
          }}
        >
          <SmallContactCardHover
            userEmail={userData.email}
            userName={userData.name}
            userIconSrc={userData.userIconSrc}
            userAvatarColor={"green"}
          />
        </Paper>
      </Popper>
    </div>
  );
}

const StyledTag = styled(Tag)<TagProps>(
  () => `
  display: flex;
  align-items: center;
  margin:2px;
  height: 30px;
  min-height: 30px;
  border: 1px solid #E9EDF2;
  border-radius:100px;
  box-sizing: content-box;
  padding: 0px 8px;
  gap:5px;
  outline: 0;
  overflow: hidden;
  flexShrink: 0;
  cursor: pointer;

  &:focus {
    border-color:  #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font:normal normal 300 13px/16px Source Serif Pro;
  }

  & svg {
    cursor: pointer;
  }
`
);

const Listbox = styled("ul")(
  ({ theme }) => `
  box-sizing: border-box;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
  scrollbar-width: none;

  & li {
    padding: 5px 12px;
    display: flex;
    box-sizing: border-box;
  }

  & li[aria-selected='true'] {
    background-color:#e6f7ff;
  }

  & li.${autocompleteClasses.focused} {
    background-color: #ebebeb;
    cursor: pointer;
  }
`
);

/**
 * Props for the CustomizedHook component.
 */
interface CustomizedHookProps {
  /**
   * The list of user options to display in the autocomplete.
   */
  options: DropdownWithImgBadgeInterface[];

  /**
   * Callback function that is called when the selected options change.
   * @param selectedOptions - The currently selected user options.
   */
  onChangeSelection: (selectedOptions: DropdownWithImgBadgeInterface[]) => void;

  /**
   * The maximum number of users to display in chips. Any additional users will be shown as "+num".
   * If not provided, all selected users will be displayed as chips.
   * Select positive number only, do not use 0 or negative number here
   */
  showMaxSelected?: number;

  /**
   * If true, users cannot be added by typing an email. Only options from the list can be selected.
   * Default is false.
   */
  disableAddUserInList?: boolean;

  /**
   * Placeholder text to display when no users are selected or typed.
   */
  placeholder?: string;
}

interface CustomizedHookProps {
  options: DropdownWithImgBadgeInterface[];
  onChangeSelection: (selectedOptions: DropdownWithImgBadgeInterface[]) => void;
  showMaxSelected?: number;
  disableAddUserInList?: boolean;
  placeholder?: string;
}

export default function CustomizedHook({
  options,
  onChangeSelection,
  showMaxSelected,
  disableAddUserInList = false,
  placeholder,
}: CustomizedHookProps) {
  const [inputValue, setInputValue] = React.useState("");
  const [customOptions, setCustomOptions] =
    React.useState<DropdownWithImgBadgeInterface[]>(options);

  const [selectedUserList, setSelectedUserList] = React.useState<
    DropdownWithImgBadgeInterface[]
  >([]);

  const [additionalUserList, setAdditionalUserList] = React.useState<
    DropdownWithImgBadgeInterface[]
  >([]);

  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.com$/.test(email);
  };

  React.useEffect(() => {
    const lastOptionIndex = customOptions.findIndex(
      (option) => option.name === inputValue && option.email === inputValue
    );

    if (
      isValidEmail(inputValue) &&
      lastOptionIndex === -1 &&
      !disableAddUserInList
    ) {
      // Add the new valid email option
      setCustomOptions([
        ...customOptions,
        { name: inputValue, email: inputValue, userIconSrc: "" },
      ]);
    } else if (!isValidEmail(inputValue) && lastOptionIndex !== -1) {
      // Remove the invalid email option
      const updatedOptions = customOptions.filter(
        (option) => option.name !== inputValue
      );
      setCustomOptions(updatedOptions);
    }
  }, [inputValue, customOptions, options]);

  const changeHandler = (newValue: DropdownWithImgBadgeInterface[]) => {
    if (showMaxSelected && newValue.length > showMaxSelected) {
      setAdditionalUserList(newValue.slice(showMaxSelected));
      setSelectedUserList(newValue.slice(0, showMaxSelected));
    } else {
      setSelectedUserList(newValue);
      setAdditionalUserList([]);
    }
    onChangeSelection(newValue);
  };

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook-demo",
    multiple: true,
    options: customOptions,
    getOptionLabel: (option) => option.email + option.name,

    inputValue: inputValue,
    onInputChange: (event, newInputValue) => {
      setInputValue(newInputValue);
    },
    onChange: (event, newValue) => {
      changeHandler(newValue);
    },
  });

  interface UserCountProps {
    userList: DropdownWithImgBadgeInterface[];
    startIndex: number;
  }

  const UserCount: React.FC<UserCountProps> = ({
    userList,
    startIndex = 0,
  }) => {
    const userLength = userList.length;
    const [listAnchorEl, setListAnchorEl] = React.useState<null | HTMLElement>(
      null
    );

    const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
      setListAnchorEl(event.currentTarget);
    };

    const handleMouseLeave = () => {
      if (listAnchorEl && !listAnchorEl.contains(document.activeElement)) {
        setListAnchorEl(null);
      }
    };

    return (
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          minWidth: "25px",
          // bgcolor: "green",
          cursor: "pointer",
          width: "fit-content",
          font: "normal normal 300 13px/16px Source Serif Pro",
          color: "#2A30B5",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Typography
          sx={{
            font: "inherit",
            color: "inherit",
          }}
        >
          +{userLength}
        </Typography>

        <Popper
          open={Boolean(listAnchorEl)}
          anchorEl={listAnchorEl}
          placement="bottom-start"
          onMouseLeave={handleMouseLeave}
          sx={{
            zIndex: 10,
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            borderRadius: "5px",
          }}
        >
          <Stack
            sx={{
              bgcolor: "white",
              borderRadius: "5px",
              width: "200px",
              height: "auto",
              maxHeight: "150px",
              overflow: "auto",
              scrollbarWidth: "none",
            }}
          >
            {userList.map((user, index) => {
              const i: number = index + startIndex;
              const { key, ...tagProps } = getTagProps({ index: i });
              return (
                <StyledTag
                  popoverPlace="right"
                  key={key}
                  {...tagProps}
                  userData={user}
                />
              );
            })}
          </Stack>
        </Popper>
      </Stack>
    );
  };

  return (
    <Root>
      <div {...getRootProps()}>
        <InputWrapper ref={setAnchorEl} className={focused ? "focused" : ""}>
          {selectedUserList.map(
            (option: DropdownWithImgBadgeInterface, index: number) => {
              const { key, ...tagProps } = getTagProps({ index });
              return (
                <StyledTag
                  key={key}
                  {...tagProps}
                  userData={option}
                  popoverPlace="top"
                />
              );
            }
          )}
          {showMaxSelected && additionalUserList.length > 0 && (
            <UserCount
              startIndex={showMaxSelected}
              userList={additionalUserList}
            />
          )}
          <input
            {...getInputProps()}
            value={inputValue}
            style={{ minWidth: "50px" }}
            placeholder={
              placeholder && selectedUserList.length === 0 ? placeholder : ""
            }
          />
        </InputWrapper>

        {/* <InputWrapper {...getRootProps()}>
          {selectedUserList.map((option, index) => (
            <StyledTag
              label={option.name}
              imgSrc={option.userIconSrc}
              {...getTagProps({ index })}
              key={index}
            />
          ))}
          {additionalUserList.length > 0 && (
            <UserCount userList={additionalUserList} />
          )}
          <input {...getInputProps()} placeholder="Type to select..." />
        </InputWrapper> */}
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {(groupedOptions as DropdownWithImgBadgeInterface[]).map(
            (option, index) => {
              const { ...optionProps } = getOptionProps({ option, index });
              return (
                <li
                  // key={index}
                  {...optionProps}
                  style={{
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <AvatarNormal
                    username={option.name}
                    size={30}
                    imgSrc={option.userIconSrc}
                  />
                  <Stack>
                    <Typography
                      sx={{
                        font: "normal normal 400 14px/18px Source Serif Pro",
                      }}
                    >
                      {option.name}
                    </Typography>
                    <Typography
                      sx={{
                        font: "normal normal 300 12px/14px Source Serif Pro",
                      }}
                    >
                      {option.email}
                    </Typography>
                  </Stack>
                </li>
              );
            }
          )}
        </Listbox>
      ) : null}
    </Root>
  );
}
