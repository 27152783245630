import { GmailLogoPng, MicrosoftTeamsLogoPng, SlackPng } from "../../../images";

export interface HostDropdownListInterface {
  companyUrl: string;
  companyName: string;
}

export const DUMMY_HOST_LIST: HostDropdownListInterface[] = [
  { companyName: "gmail", companyUrl: GmailLogoPng },
  { companyName: "slack", companyUrl: SlackPng },
  { companyName: "teams", companyUrl: MicrosoftTeamsLogoPng },
];
