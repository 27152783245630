import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from "@mui/material/ToggleButtonGroup";
import { BoldIcon, ItalicIcon, UnderlineIcon } from "../../../images";
import { Typography } from "@mui/material";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5),
    "&.Mui-selected": {
      border: `0px solid ${theme.palette.action.active}`,
    },
    "&:not(:first-of-type)": {
      marginLeft: 0,
      borderLeft: `0px solid ${theme.palette.divider}`,
    },
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
}));

interface FontStyleBoldProps {
  iconsSize?: number;
  showLabel?: boolean;
}

const FontStyleBold: React.FC<FontStyleBoldProps> = ({
  iconsSize = 15,
  showLabel = false,
}) => {
  const [formats, setFormats] = React.useState(() => [""]);

  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: string[]
  ) => {
    setFormats(newFormats);
  };

  const buttons = [
    { value: "bold", label: "Bold", Icon: BoldIcon },
    { value: "italic", label: "Italic", Icon: ItalicIcon },
    { value: "underlined", label: "Underline", Icon: UnderlineIcon },
  ];

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          boxSizing: "border-box",
        }}
      >
        <StyledToggleButtonGroup
          value={formats}
          onChange={handleFormat}
          aria-label="text formatting"
        >
          {buttons.map((button) => (
            <ToggleButton
              key={button.value}
              value={button.value}
              aria-label={button.value}
              sx={{
                color: "black",
                flexDirection: "column",
                textTransform: "none",
              }}
            >
              <button.Icon w={iconsSize} />
              {showLabel && (
                <Typography
                  sx={{
                    font: "normal normal 300 11px/14px Source Serif Pro",
                  }}
                >
                  {button.label}
                </Typography>
              )}
            </ToggleButton>
          ))}
        </StyledToggleButtonGroup>
      </Paper>
    </div>
  );
};

export default FontStyleBold;
