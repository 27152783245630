import React, { useState, MouseEvent } from "react";
import { Stack, Tooltip, Menu, MenuItem, Typography } from "@mui/material";
import { ChevronIcon, DecoratedTIcon } from "../../../images";

interface TextPickerDropdownProps {
  onSelect: (option: string) => void;
  height?: string;
}

const options = [
  "Normal text",
  "Title text",
  "Subtitle",
  "Heading 1",
  "Heading 2",
  "Heading 3",
  "Heading 4",
  "Heading 5",
  "Heading 6",
  "Settings",
];

const TextPickerDropdown: React.FC<TextPickerDropdownProps> = ({
  onSelect,
  height = "335px",
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option: string) => {
    onSelect(option);
    handleClose();
  };

  return (
    <>
      <Tooltip title="text style" placement="top" arrow>
        <Stack
          sx={{
            width: "35px",
            height: "15px",
            flexDirection: "row",
            justifyContent: "space-between",
            cursor: "pointer", // Add cursor pointer to indicate it's clickable
          }}
          onClick={handleClick}
        >
          <DecoratedTIcon w={15} />
          <ChevronIcon w={15} direction={open ? "down" : "up"} />
        </Stack>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        PaperProps={{
          style: {
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            maxHeight: height,
            overflowY: "scroll",
            scrollbarWidth: "none", // Hide scrollbar in Firefox
            msOverflowStyle: "none", // Hide scrollbar in IE and Edge
          },
        }}
        container={document && document?.getElementById("full-ScreenMode")}
        MenuListProps={{
          sx: {
            "&::-webkit-scrollbar": {
              display: "none", // Hide scrollbar in Chrome, Safari, and Opera
            },
          },
        }}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            onClick={() => handleMenuItemClick(option)}
            sx={{
              height: "30px",
              alignItems: "center",
              padding: "0px 10px",
              boxSizing: "border-box",
              minHeight: 0,
            }}
          >
            <Typography
              sx={{
                color: "black",
                fontSize: 14,
                fontFamily: "Source Serif Pro",
                fontWeight: "400",
                wordWrap: "break-word",
              }}
            >
              {option}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default TextPickerDropdown;
