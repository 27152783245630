import { createAction } from "@reduxjs/toolkit";
import { LayoutState } from "./layoutTypes";

/**
 * Interface representing the payload for changing layout state.
 */
interface ChangeLayoutStatePayload {
    type: keyof LayoutState;
    value: boolean;
}

/**
 * Action to change the layout state.
 * This action can be dispatched to update the layout state in the Redux store.
 *
 * @example
 * Example usage:
 * dispatch(changeLayoutState({ type: 'sidebarOpen', value: true }));
 */
export const changeLayoutState = createAction<ChangeLayoutStatePayload>(
    "layout/changeLayoutState"
);
