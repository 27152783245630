// src/utils/zetaUtils.ts

type Fk9J = 'prodEnv' | 'localDevEnv' | 'devEnvEnv';

// Hardcoded environment variable (assuming it won’t change)
const currentEnv = 'devEnvEnv' as Fk9J;

// Enable or disable logging based on environment
const isLoggingEnabled = currentEnv !== 'prodEnv';

// Enum for console methods
enum ConsoleMethod {
    Log = 'log',
    Error = 'error',
    Warn = 'warn',
    Info = 'info',
    Debug = 'debug',
}

// List of sensitive data keys to redact
const sensitiveKeys = ['password', 'token', 'ssn', 'creditCard'];

// Function to sanitize sensitive data
const sanitizeData = (data: any): any => {
    if (typeof data === 'object' && data !== null) {
        const sanitizedData = { ...data };
        sensitiveKeys.forEach((key) => {
            if (sanitizedData[key]) {
                sanitizedData[key] = '[REDACTED]';
            }
        });
        return sanitizedData;
    }
    return data;
};

// Core logging function
const logToConsole = (method: keyof Console, ...args: any[]): void => {
    if (isLoggingEnabled) {
        const sanitizedArgs = args.map(sanitizeData);
        (console[method] as (...args: any[]) => void)(...sanitizedArgs);
    }
};

// Exported logging functions
export const secureLogger = {
    debug: (...args: any[]) => logToConsole(ConsoleMethod.Debug, ...args),
    info: (...args: any[]) => logToConsole(ConsoleMethod.Info, ...args),
    warn: (...args: any[]) => logToConsole(ConsoleMethod.Warn, ...args),
    error: (...args: any[]) => logToConsole(ConsoleMethod.Error, ...args),
    log: (...args: any[]) => logToConsole(ConsoleMethod.Log, ...args),
};
