import { Box, Stack, Typography } from "@mui/material";
import React from "react";

interface DrawerSectionTextProps {
  title: string;
  gap?: number;
}

const DrawerSectionText: React.FC<DrawerSectionTextProps> = ({
  gap = 3,
  title,
}) => {
  return (
    <Stack direction={"row"} gap={gap} alignItems={"center"}>
      <Typography sx={{ font: "normal normal 600 15px/21px Source Serif Pro" }}>
        {title}
      </Typography>
      <Box sx={{ border: "1px solid #E9EDF2", height: "0px", flexGrow: 1 }} />
    </Stack>
  );
};

export default DrawerSectionText;
