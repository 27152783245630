import { PeopleID as PeoplePage } from "./PeopleID";
import { CompanyID as CompanyPage } from "./CompanyID";
import { DealID as DealPage } from "./DealID";

// Define the base paths
export type CrmPaths =
    | "/CRM/people"
    | "/CRM/company"
    | "/CRM/deal";

// Define the props that each component expects
export type CrmComponentProps = {
    peopleId?: string;
    companyId?: string;
    dealId?: string;
};

export const CrmComponentMap: Record<CrmPaths, React.ComponentType<CrmComponentProps>> = {
    "/CRM/people": PeoplePage,
    "/CRM/company": CompanyPage,
    "/CRM/deal": DealPage,
};
