import { useState } from "react";
import { IconButton, Stack, Tooltip, Typography, Popover } from "@mui/material";
import React from "react";
import { ElementsIcon, PlusIcon } from "../../../../../../../images";

import { adjustHexColor } from "../../../../../../../Utils";
import CustomField from "../CustomFields/CustomField";
import { CUSTOM_FIELD_LIST } from "../CustomFields/CustomFieldData";
import { AddElementPopoutOptions, ElementsOptionsList } from "../../../../../../common";

const CustomElements: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (value: string) => {
    console.log("Selected value:", value);
    // handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Stack gap={"10px"}>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack sx={{ flexDirection: "row", gap: "10px", alignItems: "center" }}>
          <ElementsIcon w={24} />
          <Typography
            sx={{ font: "normal normal normal 18px/25px Source Serif Pro" }}
          >
            Custom elements
          </Typography>
        </Stack>

        <Tooltip title="Add custom elements" placement="top" arrow>
          <IconButton sx={{ padding: 1 }} onClick={handleClick}>
            <PlusIcon w={14} />
          </IconButton>
        </Tooltip>
      </Stack>

      {/* Added element will show here */}
      <Stack>
        <Stack
          sx={{
            height: "35px",
            border: "0.5px solid #E9EDF2",
            borderRadius: "5px 5px 0px 0px",
            flexDirection: "row",
            alignItems: "center",
            pl: "10px",
            boxSizing: "border-box",
            bgcolor: adjustHexColor("#E9EDF2", 30),
          }}
        >
          <Typography
            sx={{ font: "normal normal normal 14px/20px Source Serif Pro" }}
          >
            Custom fields
          </Typography>
        </Stack>

        {/* use drap and drop here */}
        {/* here render the list which was created by user */}
        <Stack>
          {CUSTOM_FIELD_LIST.map((element, index) => (
            <CustomField key={index} data={element} />
          ))}
        </Stack>
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPaper-root": {
            boxShadow: "0px 3px 6px #00000029",
            border: "0.5px solid #E9EDF2",
          },
        }}
      >
        <ElementsOptionsList
          options={AddElementPopoutOptions}
          onSelect={handleSelect}
          onClose={handleClose}
        />
      </Popover>
    </Stack>
  );
};

export default CustomElements;
