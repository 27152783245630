import React, { MouseEvent, useState, useEffect } from "react";
import { Menu, MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import {
  DotGridIcon,
  LineGridIcon,
  NoGridIcon,
} from "../../../images";

interface GridDropdownMenuProps {
  initialValue?: string;
  onSelect?: (selectedValue: string) => void;
}

interface OptionDataInterface {
  id: number;
  name: string;
  value: string;
  icon: React.ElementType;
}

const OptionData: OptionDataInterface[] = [
  {
    id: 1,
    name: "No grid",
    value: "noGrid",
    icon: NoGridIcon,
  },
  {
    id: 2,
    name: "Line grid",
    value: "lineGrid",
    icon: LineGridIcon,
  },
  {
    id: 3,
    name: "Dot grid",
    value: "dotGrid",
    icon: DotGridIcon,
  },
];

const GridDropdownMenu: React.FC<GridDropdownMenuProps> = ({
  initialValue,
  onSelect,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOption, setSelectedOption] = useState<OptionDataInterface>(
    OptionData.find(option => option.value === initialValue) || OptionData[0]
  );

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (initialValue) {
      const initialOption = OptionData.find(
        (option) => option.value === initialValue
      );
      if (initialOption) {
        setSelectedOption(initialOption);
      }
    }
  }, [initialValue]);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (option: OptionDataInterface) => {
    setSelectedOption(option);
    if (onSelect) {
      onSelect(option.value);
    }
    handlePopoverClose();
  };

  return (
    <>
      <Tooltip title="Grid" placement="top" arrow>
        <Stack
          onClick={handlePopoverOpen}
          sx={{
            width: "15px",
            height: "15px",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          {React.createElement(selectedOption.icon, { w: 15 })}
        </Stack>
      </Tooltip>

      <Menu
        open={open}container={ document && document?.getElementById("full-ScreenMode")}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ marginTop: "10px" }}
        PaperProps={{
          style: { boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)" },
        }}
      >
        <Stack
          sx={{
            width: "100px",
            padding: "10px 0px 5px 0px",
            gap: "10px",
          }}
        >
          <Stack paddingX={"10px"}>
            <Typography
              sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
            >
              Grid
            </Typography>
          </Stack>
          <Stack>
            {OptionData.map((item) => (
              <MenuItem
                key={item.id}
                sx={{
                  padding: "0px",
                  height: "30px",
                  minHeight: "30px",
                  maxHeight: "30px",
                }}
                onClick={() => handleSelect(item)}
              >
                <Stack
                  sx={{
                    flexDirection: "row",
                    gap: "10px",
                    padding: "0px 10px",
                  }}
                >
                  {React.createElement(item.icon, { w: 15 })}
                  <Typography
                    sx={{
                      font: "normal normal 300 12px/15px Source Serif Pro",
                    }}
                  >
                    {item.name}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </Stack>
        </Stack>
      </Menu>
    </>
  );
};

export default GridDropdownMenu;
