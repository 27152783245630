// Ensure that these types are imported from the correct path
import { EmailListInterface } from "./EmailInCRMPreviewTypes";
import React from "react";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { AvatarNormal } from "../../../../common";
import { FullViewIcon, ProjectIcon } from "../../../../../images";
import AttachmentDisplay from "./AttachmentDisplay/AttachmentDisplay";
import MailGroupedPerson from "./MailGroupedPerson/MailGroupedPerson";

// Props for the email preview component
interface EmailInCRMPreviewProps {
  emailData: EmailListInterface;
}

const EmailInCRMPreview: React.FC<EmailInCRMPreviewProps> = ({ emailData }) => {
  const { date, emailContent, recipients, sender, subject, attachments } =
    emailData;

  return (
    <Stack sx={{ p: "15px", gap: "10px", borderBottom: "1px solid #E9EDF2" }}>
      {/* avatar and email-headers here */}
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "start",
          justifyContent: "space-between",
        }}
      >
        <Stack sx={{ flexDirection: "row", gap: "10px" }}>
          <AvatarNormal
            size={40}
            username={sender.name}
            imgSrc={sender.avatarUrl}
          />
          <Stack>
            <Typography
              sx={{ font: "normal normal 900 14px/20px Source Serif Pro" }}
            >
              {subject}
            </Typography>
            <Stack sx={{ flexDirection: "row", gap: "5px" }}>
              <Typography
                sx={{ font: "normal normal 300 11px/15px Source Serif Pro" }}
              >
                {sender.name}
              </Typography>
              <Typography
                sx={{ font: "normal normal 300 11px/15px Source Serif Pro" }}
              >
                {sender.email}
              </Typography>
              <Box>
                <MailGroupedPerson
                  recipients={recipients}
                  senderPerson={sender}
                />
              </Box>
            </Stack>
          </Stack>
        </Stack>

        <Stack sx={{ flexDirection: "row", gap: "10px" }}>
          <Tooltip title="Action Button 1" arrow placement="top">
            <IconButton
              sx={{ padding: "0px", marginRight: "5px" }}
              disableRipple
            >
              <ProjectIcon w={15} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Action Button 2" arrow placement="top">
            <IconButton sx={{ padding: "0px" }} disableRipple>
              <FullViewIcon w={15} />
            </IconButton>
          </Tooltip>

          <Typography
            sx={{ font: "normal normal 900 14px/20px Source Serif Pro" }}
          >
            {date}
          </Typography>
        </Stack>
      </Stack>

      {/* email body here */}
      <Stack sx={{ gap: "15px" }}>
        {/* email/message and reply button here */}
        <Stack>
          <Typography
            sx={{
              font: "normal normal 300 11px/15px Source Serif Pro",
              maxHeight: "45px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
            }}
          >
            {emailContent.text}
          </Typography>
          <Stack sx={{ flexDirection: "row", justifyContent: "end" }}>
            <Tooltip title="Reply this mail" placement="top" arrow>
              <IconButton sx={{ padding: "0px" }} disableRipple>
                <Typography
                  sx={{
                    font: "normal normal 300 11px/15px Source Serif Pro",
                    color: "#0027FF",
                  }}
                >
                  Reply
                </Typography>
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>

        {/* attecments here */}
        <Stack sx={{ flexDirection: "row", gap: "5px", flexWrap: "wrap" }}>
          {attachments?.map((item, i) => (
            <AttachmentDisplay key={i} {...item} />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EmailInCRMPreview;
