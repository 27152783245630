import React, { useState } from "react";
import { Stack, TextareaAutosize } from "@mui/material";

interface ParagraphViewProps {
  initialValue?: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

const ParagraphView: React.FC<ParagraphViewProps> = ({
  initialValue = "",
  onChange,
  placeholder,
}) => {
  const [value, setValue] = useState<string>(initialValue);
  const [isEditing, setIsEditing] = useState(false);

  const handleBlur = () => {
    setIsEditing(false);
    onChange(value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  const handleClick = () => {
    setIsEditing(true);
  };

  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", flexGrow: 1 }}>
      <TextareaAutosize
        placeholder={placeholder}
        minRows={1}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onClick={handleClick}
        autoFocus={isEditing}
        style={{
          flexGrow: 1,
          resize: "none",
          border: "0px",
          outline: "none",
          padding: 0,
          font: "normal normal 300 13px/17px Source Serif Pro",
        }}
      />
    </Stack>
  );
};

export default ParagraphView;
