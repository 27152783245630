import {
  ApprovalIcon,
  CallIcon,
  CheckboxIcon,
  ClockIcon,
  ColorIcon,
  ConnectedIcon,
  ConnectionIcon,
  DeadlineIcon,
  DetailsIcon,
  DropdownIcon,
  EmailIcon,
  EquationIcon,
  HashtagIcon,
  LastCalendarIcon,
  LikeIcon,
  LocationIcon,
  MoneyIcon,
  MultiselectIcon,
  ParagraphIcon,
  PercentageIcon,
  ProgressIcon,
  RelationshipStrengthIcon,
  RollupIcon,
  SelectIcon,
  StrongestrelationshipIcon,
  TasksInvolvedIcon,
  TeamMemberIcon,
  ViewDocumentIcon,
} from "../../../images";

export enum AddElementOptionValuesEnum {
  ShortText = "Short text",
  Paragraph = "Paragraph",
  Number = "Number",
  Checkbox = "Checkbox",
  Date = "Date",
  Timestamp = "Timestamp",
  SelectDropdown = "Select(Dropdown)",
  Approved = "Approved",
  MultiselectLabels = "Mult-select(Labels)",
  Person = "Person",
  FileMedia = "File & Media",
  URL = "URL",
  Email = "Email",
  Phone = "Phone",
  Formula = "Formula",
  Relation = "Relation",
  Rollup = "Rollup",
  ProgressManual = "Progress (Manual)",
  ProgressAuto = "Progress (Auto)",
  Rating = "Rating",
  Money = "Money",
  Location = "Location",
  DependentDropdown = "Dependent dropdown",
  RichText = "Rich text",
  ColorPicker = "Color picker",
  Countdown = "Countdown",
  Connection = "Connection",
  Voting = "Voting",
  Percentage = "Percentage",
}

export interface AddElementListInterface {
  id: number;
  icon: React.ElementType;
  value: AddElementOptionValuesEnum;
  commingSoon?: boolean;
}

export interface AddElementPopoutFormDataInterface {
  fieldname: string;
  description?: string;
  formType: AddElementOptionValuesEnum;
  additionalValue?: any;
  emoji?: any;
}

export const AddElementPopoutOptions: AddElementListInterface[] = [
  {
    id: 1,
    icon: DetailsIcon,
    value: AddElementOptionValuesEnum.ShortText,
  },
  {
    id: 2,
    icon: ParagraphIcon,
    value: AddElementOptionValuesEnum.Paragraph,
  },
  {
    id: 3,
    icon: HashtagIcon,
    value: AddElementOptionValuesEnum.Number,
  },
  {
    id: 4,
    icon: CheckboxIcon,
    value: AddElementOptionValuesEnum.Checkbox,
  },
  {
    id: 5,
    icon: ClockIcon,
    value: AddElementOptionValuesEnum.Date,
  },
  {
    id: 6,
    icon: LastCalendarIcon,
    value: AddElementOptionValuesEnum.Timestamp,
  },
  {
    id: 7,
    icon: SelectIcon,
    value: AddElementOptionValuesEnum.SelectDropdown,
  },
  {
    id: 8,
    icon: ApprovalIcon,
    value: AddElementOptionValuesEnum.Approved,
  },
  {
    id: 9,
    icon: MultiselectIcon,
    value: AddElementOptionValuesEnum.MultiselectLabels,
  },
  {
    id: 10,
    icon: StrongestrelationshipIcon,
    value: AddElementOptionValuesEnum.Person,
  },
  {
    id: 11,
    icon: ViewDocumentIcon,
    value: AddElementOptionValuesEnum.FileMedia,
  },
  {
    id: 12,
    icon: ConnectedIcon,
    value: AddElementOptionValuesEnum.URL,
  },
  {
    id: 13,
    icon: EmailIcon,
    value: AddElementOptionValuesEnum.Email,
  },
  {
    id: 14,
    icon: CallIcon,
    value: AddElementOptionValuesEnum.Phone,
  },
  {
    id: 15,
    icon: EquationIcon,
    value: AddElementOptionValuesEnum.Formula,
  },
  {
    id: 16,
    icon: TeamMemberIcon,
    value: AddElementOptionValuesEnum.Relation,
  },
  {
    id: 17,
    icon: RollupIcon,
    value: AddElementOptionValuesEnum.Rollup,
  },
  {
    id: 18,
    icon: ProgressIcon,
    value: AddElementOptionValuesEnum.ProgressManual,
  },
  {
    id: 19,
    icon: ProgressIcon,
    value: AddElementOptionValuesEnum.ProgressAuto,
  },
  {
    id: 20,
    icon: RelationshipStrengthIcon,
    value: AddElementOptionValuesEnum.Rating,
  },
  {
    id: 21,
    icon: MoneyIcon,
    value: AddElementOptionValuesEnum.Money,
  },
  {
    id: 22,
    icon: LocationIcon,
    value: AddElementOptionValuesEnum.Location,
  },
  {
    id: 23,
    icon: DropdownIcon,
    value: AddElementOptionValuesEnum.DependentDropdown,
    commingSoon: true,
  },
  {
    id: 24,
    icon: TasksInvolvedIcon,
    value: AddElementOptionValuesEnum.RichText,
  },
  {
    id: 25,
    icon: ColorIcon,
    value: AddElementOptionValuesEnum.ColorPicker,
  },
  {
    id: 26,
    icon: DeadlineIcon,
    value: AddElementOptionValuesEnum.Countdown,
  },
  {
    id: 27,
    icon: ConnectionIcon,
    value: AddElementOptionValuesEnum.Connection,
  },
  {
    id: 28,
    icon: LikeIcon,
    value: AddElementOptionValuesEnum.Voting,
  },
  {
    id: 29,
    icon: PercentageIcon,
    value: AddElementOptionValuesEnum.Percentage,
  },
];
