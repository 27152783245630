import React, { useState, FC, ChangeEvent } from "react";
import { InputBase, Stack } from "@mui/material";
import g from "./SearchBar.module.css";
import { SearchIcon } from "../../../../../../../images";

// Define the props interface with TypeScript
interface SearchBarProps {
  placeholder?: string;
  font?: string;
  borderLine?: boolean;
  iconSize?: number;
  onSearch?: (value: string) => void;
}

const SearchBar: FC<SearchBarProps> = ({
  placeholder = "Search…",
  font = "normal normal normal 14px/20px Source Serif Pro",
  borderLine = true,
  iconSize = 20,
  onSearch,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (onSearch) {
        onSearch(value);
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      width="100%"
      gap={0.5}
      className={borderLine ? g.searchParamBorder : ""}
    >
      <SearchIcon w={iconSize} />
      <InputBase
        type="search"
        placeholder={placeholder}
        sx={{ font }}
        value={searchTerm}
        onChange={handleSearchChange}
        className={g.searchParams}
      />
    </Stack>
  );
};

export default SearchBar;
