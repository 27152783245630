import { LayoutState } from "../../../redux";

export const pathToDrawerStateKey: { [key: string]: keyof LayoutState } = {
  "/dashboard": "isDrawer_Dashboard",
  "/Calls": "isDrawer_Call",
  "/Email": "isDrawer_Email",
  "/Calendar": "isDrawer_Calendar",
  "/workspace": "isDrawer_Workspace",
  "/Message": "isDrawer_Messages",
  "/CRM": "isDrawer_CRM",
  "/Trax": "isDrawer_Trax",
  "/Settings": "isDrawer_Settings",
  "/Project": "isDrawer_Project",
  "/cloud-storage/": "isDrawer_CloudStorage",
};
