import { Box, Grow, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  CloudStorageItemGrid,
  CloudStorageItemList,
  DetailsCloudStorage,
  GreenArrowButton,
  MediumSearchbar,
  SwitchSmall,
} from "../../common";
import { ViewMode } from "../Projects";
import { CloudStorageFileCompanyTypeEnum } from "../../common/CloudStorageFileIcon/CloudStorageFileType";
import { useNavigate } from "react-router-dom";
// Define a type for the items in the state
interface CloudStorageItem {
  id: number; // Unique identifier
  plateform: CloudStorageFileCompanyTypeEnum;
  opendBy: string;
  fileSize: string;
  isSelected: boolean;
  timeStamp: string;
  fileName: string;
}

// Initial data for items
const initialItems: CloudStorageItem[] = [
  {
    id: 1,
    plateform: CloudStorageFileCompanyTypeEnum.BearishOS,
    opendBy: "Alice Johnson",
    fileSize: "15MB",
    isSelected: false,
    timeStamp: "15th september 2024",
    fileName: "Document1.png",
  },
  {
    id: 2,
    plateform: CloudStorageFileCompanyTypeEnum.GoogleDrive,
    opendBy: "Bob Smith",
    fileSize: "25MB",
    isSelected: false,
    timeStamp: "15th september 2024",
    fileName: "Document2.mp4",
  },
  // Add more unique items here...
  {
    id: 15,
    plateform: CloudStorageFileCompanyTypeEnum.DropBox,
    opendBy: "Zara Lee",
    fileSize: "35MB",
    isSelected: false,
    timeStamp: "15th september 2024",
    fileName: "Document15.pdf",
  },
];

const CloudStorage = () => {
  const navigate = useNavigate();
  // State to manage the list of items
  const [items, setItems] = useState<CloudStorageItem[]>(initialItems);
  const handleItemClick = (id: number) => {
    setItems((prevItems) => {
      const updatedItems = prevItems.map((item) =>
        item.id === id
          ? { ...item, isSelected: !item.isSelected } // Toggle selection
          : item
      );
      return updatedItems;
    });
  };

  const handleNavigate = () => {
    navigate(`/cloud-storage/12folderId/png/12FileId`);
  };

  const [viewMode, setViewMode] = useState<ViewMode>("stacked");

  const [collapsed, setCollapsed] = useState<boolean>(false);

  return (
    <Box>
      <Stack p="15px 25px 2px 25px">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className="boxProject"
          position="relative"
        >
          <Typography
            sx={{ font: "normal normal 400 20px/11px Source Serif Pro" }}
            children={"All Itmes"}
          />

          <Stack direction="row" alignItems="center" gap={2}>
            <SwitchSmall viewMode={viewMode} setViewMode={setViewMode} />
            <MediumSearchbar ph={"Search all Items"} />
          </Stack>

          <Stack position={"absolute"} right={"15px"} top={"44px"}>
            <IconButton onClick={() => setCollapsed(collapsed ? false : true)}>
              <GreenArrowButton />
            </IconButton>
          </Stack>
        </Stack>

        <Stack mt={3} direction={"column"} gap={0}>
          {viewMode !== "square" && (
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              gap={5}
            >
              <Typography
                sx={{
                  font: "normal normal 300 14px/17px Source Serif Pro",
                  marginLeft: "60px",
                }}
                children={"Name"}
              />
              <Typography
                sx={{
                  font: "normal normal 300 14px/17px Source Serif Pro",
                  marginLeft: "18.3%",
                }}
                children={"Owner"}
              />
              {!collapsed ? (
                <>
                  <Typography
                    sx={{
                      font: "normal normal 300 14px/17px Source Serif Pro",
                      marginLeft: "18%",
                    }}
                    children={"Last opened"}
                  />
                  <Typography
                    sx={{
                      font: "normal normal 300 14px/17px Source Serif Pro",
                      marginLeft: "15%",
                    }}
                    children={"Size"}
                  />
                </>
              ) : null}
            </Stack>
          )}

          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            gap={0.5}
            justifyContent={"space-between"}
          >
            <Stack
              flexWrap={"wrap"}
              direction={viewMode === "square" ? "row" : "column"}
              gap={viewMode === "square" ? 3 : 0}
            >
              {items.map((item, index) => (
                <Grow
                  key={item.id} // Unique key for each item
                  in={true} // Always show animation (you can refine this based on your needs)
                  timeout={500} // Animation duration
                  mountOnEnter
                  unmountOnExit
                  style={{ transformOrigin: "top" }}
                >
                  <div
                    style={{
                      width: viewMode !== "square" ? "100%" : "auto",
                    }}
                  >
                    {viewMode === "square" ? (
                      <CloudStorageItemGrid
                        plateform={item.plateform}
                        opendBy={item.opendBy}
                        fileSize={item.fileSize}
                        isSelected={item.isSelected}
                        onContainerClick={() => handleNavigate()} // Handle click to set selected item
                        timeStamp={item.timeStamp}
                        fileName={item.fileName}
                        checkBoxClick={() => handleItemClick(item.id)}
                      />
                    ) : (
                      <CloudStorageItemList
                        plateform={item.plateform}
                        opendBy={item.opendBy}
                        fileSize={item.fileSize}
                        isSelected={item.isSelected}
                        onContainerClick={() => handleNavigate()} // Handle click to set selected item
                        timeStamp={item.timeStamp}
                        fileName={item.fileName}
                        checkBoxClick={() => handleItemClick(item.id)}
                        collapsedView={collapsed}
                      />
                    )}
                  </div>
                </Grow>
              ))}
            </Stack>
            {collapsed && (
              <DetailsCloudStorage closeDetails={() => setCollapsed(false)} />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default CloudStorage;
