import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "./NotesCard.module.css";
import { AvatarNormal } from "../AvatarNormal";

interface NotesCardProps {
  cardTitleIcon: string | React.ElementType;
  cardTitleText: string;
  notesTitle: string;
  notesDescription: string;
  userName: string;
  userIcon?: string;
  dateStamp: string;
}

const NotesCard: React.FC<NotesCardProps> = ({
  cardTitleIcon,
  cardTitleText,
  dateStamp,
  notesDescription,
  notesTitle,
  userName,
  userIcon,
}) => {
  return (
    <Stack className={styles["notesCard"]}>
      <Stack className={styles["notesCard__content"]}>
        <Stack className={styles["notesCard__header"]}>
          {typeof cardTitleIcon === "string" ? (
            <Box
              component="img"
              src={cardTitleIcon}
              alt="logo"
              className={styles["notesCard__logo"]}
            />
          ) : (
            React.createElement(cardTitleIcon, { w: 20 })
          )}

          <Typography className={styles["notesCard__platformName"]}>
            {cardTitleText}
          </Typography>
        </Stack>
        <Stack className={styles["notesCard__body"]}>
          <Typography className={styles["notesCard__noteName"]}>
            {notesTitle}
          </Typography>
          <Stack className={styles["notesCard__description"]}>
            <Typography className={styles["notesCard__descriptionText"]}>
              {notesDescription}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack className={styles["notesCard__dividerContainer"]}>
        <Stack className={styles["notesCard__divider"]} />
      </Stack>
      <Stack className={styles["notesCard__footer"]}>
        <Stack className={styles["notesCard__userInfo"]}>
          <AvatarNormal size={20} username={userName} imgSrc={userIcon} />
          <Typography
            className={styles["notesCard__userName"]}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "200px",
            }}
          >
            {userName}
          </Typography>
        </Stack>
        <Typography className={styles["notesCard__date"]}>
          {dateStamp}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default NotesCard;
