import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BearishOSIcon, FirstLogin2x } from "../../../images";
import g from "./FirstLogin.module.css";
import {
  ButtonXLarge,
  ThreePageMarker,
  ButtonType,
  ButtonXLargeColor,
  useCustomSnackbar,
} from "../../common";
import { Stack, Typography } from "@mui/material";
import { Steppers } from "./components";
import { FirstLoginData, initialState } from "./components/StepsType";
import { useNavigate } from "react-router-dom";
import {
  AuthState,
  SignUpPayload,
  signupUser,
  tempAuthStoreRequestAction,
} from "../../../redux";
import { RootState } from "../../../redux/store";

// Define props interface for FirstLogin component
type FirstLoginProps = {};

/**
 * FirstLogin component responsible for handling the initial login steps.
 * Users provide necessary information to set up their account.
 *
 * @returns FirstLogin component
 */
const FirstLogin: React.FC<FirstLoginProps> = () => {
  const { error, user, isSignUp, email_temp, password_temp } = useSelector(
    (state: RootState) => state.auth as AuthState
  );
  const dispatch = useDispatch();
  // Navigate function for routing
  const navigate = useNavigate();
  const enqueueSnackbar_v1 = useCustomSnackbar();

  useEffect(() => {
    if (!email_temp || !password_temp) {
      // Handle the case where email or password is null
      enqueueSnackbar_v1("error", "Please sign up first to continue.", {
        position: { vertical: "top", horizontal: "center" },
        autoHideDuration: 5000, // Optional custom auto-hide duration in milliseconds
      });
      const timer = setTimeout(() => {
        navigate("/signup");
      }, 3000); // 3000 milliseconds = 3 seconds

      return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts or dependencies change
    }
    // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [email_temp, password_temp, navigate]);

  // State variables for first login data and current step
  const [firstLoginData, setFirstLoginData] =
    useState<FirstLoginData>(initialState);

  console.log(firstLoginData, "firstLoginData");

  // Function to handle moving between steps
  const [currentStep, setCurrentStep] = useState<number>(1);

  const emails = email_temp;
  const passwords = password_temp;

  const handleMoveStep = async (step: number) => {
    if (step === 1 || step === 2 || step === 3) {
      setCurrentStep(step);
    } else if (step === 4) {
      if (!emails || !passwords) {
        // Handle the case where email or password is null
        enqueueSnackbar_v1("error", "Email or password is missing.", {
          position: { vertical: "top", horizontal: "center" },
          autoHideDuration: 5000, // Optional custom auto-hide duration in milliseconds
        });
        return;
      } else if (
        !firstLoginData.firstName ||
        !firstLoginData.lastName ||
        !firstLoginData.companyName ||
        !firstLoginData.departMent
      ) {
        enqueueSnackbar_v1("error", "All fields are required to be filled.", {
          position: { vertical: "top", horizontal: "center" },
          autoHideDuration: 5000, // Optional custom auto-hide duration in milliseconds
        });
        return;
      }

      if (!firstLoginData.companyRegistryChecked) {
        enqueueSnackbar_v1(
          "error",
          "Your company is not registered or the registration is incomplete.",
          {
            position: { vertical: "top", horizontal: "center" },
            autoHideDuration: 5000, // Optional custom auto-hide duration in milliseconds
          }
        );
        return;
      }

      const departmentIds = firstLoginData?.departMent?.map(
        (department) => department._id
      );

      const isValidFile = (file: any) => {
        const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
        return file && allowedTypes.includes(file.type);
      };

      const formData = new FormData();

      // Append fields to FormData
      formData.append("firstName", firstLoginData.firstName || "");
      formData.append("lastName", firstLoginData.lastName || "");
      formData.append("email", emails || "");
      formData.append("agreedToTerms", "true"); // FormData accepts only strings or Blob
    // Append departmentIds as separate form data entries if it's an array
if (Array.isArray(departmentIds)) {
  departmentIds.forEach((id, index) => {
    formData.append(`departmentIds[${index}]`, id);
  });
} else {
  formData.append("departmentIds", ""); // Handle if departmentIds is empty or undefined
}

      // Handle avatar conditionally
      if (firstLoginData.avatarFile && isValidFile(firstLoginData.avatarFile)) {
        formData.append("avatar", firstLoginData.avatarFile); // Append file
      } else {
        formData.append(
          "avatar",
          `${firstLoginData.avatarDefaultColor || "#833BC3"}|${firstLoginData.firstName?.charAt(0) || "A"}${firstLoginData.lastName?.charAt(0) || "A"}`
        ); // Append string
      }

      // Append remaining fields
      formData.append("companyName", firstLoginData.companyName || "");
      formData.append("domain", firstLoginData?.domain || "");
      formData.append("projectName", firstLoginData?.projectName || "");

      // Dispatch the FormData instead of JSON payload
      const action = signupUser(formData);

      await dispatch(action);
    }
  };

  // Function to determine main text based on current step
  const handleMainText = (currentStep: number): string => {
    if (currentStep === 1) {
      return `Welcome to Bearish OS, What should we call you?`;
    } else if (currentStep === 2) {
      return `What Company and Department are you in?`;
    } else if (currentStep === 3) {
      return `Create your first project`;
    }
    return "";
  };

  useEffect(() => {
    if (!error && isSignUp && user) {
      dispatch(tempAuthStoreRequestAction());
      navigate("/login");
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [error, user, isSignUp, navigate]);

  // Container style for background image
  const containerStyleFirstLogin = {
    backgroundImage: `url(${FirstLogin2x})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <div className={`${g.containerFl}`} style={containerStyleFirstLogin}>
      <BearishOSIcon w={75} />

      {/* Main text based on current step */}
      <Typography className={g.firstlogin__main__text}>
        {handleMainText(currentStep)}
      </Typography>

      {/* Stack for layout */}
      <Stack width={"476px"} alignItems={"center"} justifyContent={"center"}>
        {/* Additional text for step 3 */}
        {currentStep === 3 && (
          <Typography
            className={g.currentSte3Italic__main}
            children={`In Bearish OS everything falls into a project, add team members, set budgets, communicate with sake holders, share anything, and so much more`}
          />
        )}

        {/* Steppers component */}
        <Steppers
          currentStep={currentStep}
          firstLoginData={firstLoginData}
          setFirstLoginData={setFirstLoginData}
        />

        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={1}
          width={"100%"}
        >
          {/* Button for proceeding to next step */}

          {currentStep !== 1 && (
            <ButtonXLarge
              colorVarient={ButtonXLargeColor.Orange}
              buttonSize="default"
              label={"Previous"}
              fullwidth={true}
              types={ButtonType.Button}
              loaderLogin={false}
              someValue={currentStep - 1}
              onClick={handleMoveStep}
            />
          )}
          <ButtonXLarge
            colorVarient={ButtonXLargeColor.Orange}
            buttonSize="default"
            label={currentStep === 3 ? "Start Now" : "Next"}
            fullwidth={true}
            types={ButtonType.Button}
            loaderLogin={false}
            someValue={currentStep + 1}
            onClick={handleMoveStep}
          />
        </Stack>

        {/* ThreePageMarker component */}
        <Stack width={"100%"} mt={"20px"}>
          <ThreePageMarker
            handleMoveStep={handleMoveStep}
            totalPage={3}
            activePage={currentStep}
          />
        </Stack>
      </Stack>
    </div>
  );
};

export default FirstLogin;
