import React from "react";
import g from "./VerifyOpt.module.css";
import { IconButton, InputBase, Stack, Typography } from "@mui/material";
import { CloseIcon } from "../../../images";
import { useTranslation } from "react-i18next";
import { ButtonXLarge } from "../Buttons";
import { ButtonType, ButtonXLargeColor } from "../Buttons/AllButtonProps";
type Props = {
  emails?: string;
  onClick: (type: number) => void;
  handleChangeSetOPT: (value: string) => void;
  opt?: string;
};

const STRING_CONSTANTS = [
  {
    id: "verify",
    str: "verify",
    font: "normal normal bold 40px/54px Source Serif Pro",
  },
  {
    id: "cst",
    str: "codeSendTo",
    font: "italic normal normal 15px/21px Source Serif Pro",
  },
];

const VerifyOpt: React.FC<Props> = ({
  emails = "johnsmith@bearishfh.com",
  onClick,
  handleChangeSetOPT,
  opt,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Stack className={g.cVerifyOpt}>
        <Stack
          width={"100%"}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
        >
          <IconButton onClick={() => onClick(1)}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          width={"100%"}
          gap={"20px"}
        >
          {STRING_CONSTANTS.map((strings) => {
            return (
              <Typography sx={{ font: strings.font }} key={strings.id}>
                {t(strings.str)}
              </Typography>
            );
          })}

          <Typography
            sx={{
              font: "normal normal 600 15px/21px Source Serif Pro",
              marginTop: "10px",
            }}
          >
            {emails}
          </Typography>
        </Stack>
        <form
          style={{ gap: "0", padding: "0", width: "100%" }}
          autoComplete="off"
        >
          <Stack width={"100%"} gap={"20px"}>
            <InputBase
              type="text"
              onChange={(e) => handleChangeSetOPT(e.target.value)}
              value={opt}
              placeholder={t("oneTimeCode")}
              className={g.verifyOTPINPUTclass}
            />

            <ButtonXLarge
              colorVarient={ButtonXLargeColor.Green}
              buttonSize="default"
              label={t("Continue")}
              fullwidth={true}
              types={ButtonType.Button}
              loaderLogin={false}
              onClick={onClick}
              someValue={2}
            />

            <ButtonXLarge
              colorVarient={ButtonXLargeColor.Orange}
              buttonSize="default"
              label={t("resendOPTCode")}
              fullwidth={true}
              types={ButtonType.Button}
              loaderLogin={false}
              onClick={onClick}
              someValue={3}
            />
          </Stack>
        </form>
      </Stack>
    </>
  );
};

export default VerifyOpt;
