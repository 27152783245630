import React from "react";
import {
  BoxDriveIconCloudStorage,
  OneDriveIconCloudStorage,
  GoogleDriveIconCloudStroge,
  BearishOSIconCloudStroge,
  DropBoxIconCloudStorage,
} from "../images";
import { ExternalDrive } from "../redux";

/**
 * Utility function to get the connection icon for an external drive.
 * @param driveType The type of the external drive.
 * @param size Optional size for the icon (defaults to 15).
 * @returns The corresponding icon component or null if no icon exists.
 */
export const getDriveIcon = (
  driveType: ExternalDrive["type"],
  size: number = 15
) => {
  const driveIcons: Record<ExternalDrive["type"], JSX.Element | null> = {
    BearishOS: <BearishOSIconCloudStroge w={size} />,
    GoogleDrive: <GoogleDriveIconCloudStroge w={size} />,
    OneDrive: <OneDriveIconCloudStorage w={size} />,
    Dropbox: <DropBoxIconCloudStorage w={size} />,
    Box: <BoxDriveIconCloudStorage w={size} />,
    Other: null, // Explicitly handle 'Other' or unhandled types with null
  };

  // Return the icon if it exists, otherwise return null
  return driveIcons[driveType] || null;
};
