import {
  CenterIcon,
  JustifyIcon,
  LeftAlignmentIcon,
  RightAlignmentIcon,
} from "../../../images";

export enum LineSpacingDropdownValueEnum {
  LeftAlignment = "LeftAlignment",
  Justify = "Justify",
  Center = "Center",
  RightAlignment = "RightAlignment",
}

export interface LineSpacingDropdownInterface {
  value: LineSpacingDropdownValueEnum;
  icon: React.ElementType;
}

export const LineSpacingDropdownOptions: LineSpacingDropdownInterface[] = [
  {
    value: LineSpacingDropdownValueEnum.LeftAlignment,
    icon: LeftAlignmentIcon,
  },
  { value: LineSpacingDropdownValueEnum.Justify, icon: JustifyIcon },
  { value: LineSpacingDropdownValueEnum.Center, icon: CenterIcon },
  {
    value: LineSpacingDropdownValueEnum.RightAlignment,
    icon: RightAlignmentIcon,
  },
];
