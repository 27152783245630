import React from "react";
import { Popover, Stack } from "@mui/material";
import { ColoredBadge } from "../../../../common";
import { adjustHexColor } from "../../../../../Utils";
import { CategoryMenu } from "../MenuScroll";

// Define the types for the badge data
type BadgeData = {
  id: string;
  title: string;
  bgColor: string;
};
// TypeScript interface for component props
interface Category {
  id: string;
  name: string;
  color: string;
}

type CategoriesTableProps = {
  words: Category[];
};

// Function to generate a random color
const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const CategoriesTable: React.FC<CategoriesTableProps> = ({ words }) => {
  // Map over categories and generate colors if not provided
  const renderedCategories = words.map((category) => ({
    ...category,
    color: category.color || getRandomColor(), // Use provided color or generate a random one
  }));

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const predefinedBadges: BadgeData[] = [
    { id: "1", title: "3D Printing", bgColor: "green" },
    { id: "2", title: "Accounting", bgColor: "blue" },
    { id: "3", title: "Aerospace", bgColor: "red" },
    { id: "4", title: "Agriculture", bgColor: "green" },
    { id: "5", title: "Airline", bgColor: "blue" },
    { id: "6", title: "Animation", bgColor: "red" },
    { id: "7", title: "Apparel", bgColor: "green" },
    { id: "8", title: "Architecture", bgColor: "blue" },
    { id: "9", title: "Arts", bgColor: "red" },
    { id: "10", title: "Asset Management", bgColor: "red" },
  ];

  const handleBadgeSelection = (id: string) => {
    console.log(id, "Badge Seleteted");
  };

  return (
    <>
      <Stack
        direction={"row"}
        onClick={(e: any) => handleClick(e)}
        alignItems={"center"}
        flexWrap={"wrap"}
        gap={1}
        justifyContent={"center"}
      >
        {renderedCategories.map((category, index) => (
          <ColoredBadge
            key={index}
            title={category.name || "random category"}
            ColoredBadgePadding={"5px 9px"}
            bgColor={adjustHexColor(category.color, 35)}
          />
        ))}
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <CategoryMenu
          badges={predefinedBadges}
          onBadgeSelect={handleBadgeSelection}
        />
      </Popover>
    </>
  );
};

export default CategoriesTable;
