import * as React from "react";
import {
  Box,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import S from "./IconTextLongDropdown.module.css";
import { GreenDropdownIcon } from "../../../images";
import { IconTextLongDropdownOptionInterface } from "./IconTextLongDropdownTypes";
import { SearchInDropdown } from "../SearchInDropdown";

interface IconTextLongDropdownProps {
  width?: string;
  options: IconTextLongDropdownOptionInterface[];
  onChange?: (selectedOption: IconTextLongDropdownOptionInterface) => void;
  initialSelectedOption?: IconTextLongDropdownOptionInterface;
  placeholder?: string;
  label?: string;
}

const IconTextLongDropdown: React.FC<IconTextLongDropdownProps> = ({
  width = "305px",
  options,
  onChange,
  initialSelectedOption,
  placeholder,
  label,
  ...props
}) => {
  const [selectedOption, setSelectedOption] = React.useState<
    IconTextLongDropdownOptionInterface | undefined
  >(initialSelectedOption);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selected = options.find(
      (option) => option.text === event.target.value
    );
    setSelectedOption(selected);
    if (onChange && selected) {
      onChange(selected);
    }
  };

  const handleSearchInputMouseDown = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleSearchInputKeyDown = (event: React.KeyboardEvent) => {
    if (selectedOption && event.key.length === 1) {
      event.stopPropagation();
    }
  };

  const menuProps = {
    PaperProps: {
      className: S["icon-text-long-dropdown__menu"],
      style: { width: "inherit" },
    },
    anchorOrigin: {
      vertical: "bottom" as const,
      horizontal: "right" as const,
    },
    transformOrigin: {
      vertical: "top" as const,
      horizontal: "right" as const,
    },
  };

  const renderSelectedValue = (selected: string | undefined) => {
    if (!selected) {
      return (
        <em
          style={{ paddingLeft: "5px" }}
          className={S["icon-text-long-dropdown__select-placeholder"]}
        >
          {placeholder}
        </em>
      );
    }

    const selectedOption = options.find((option) => option.text === selected);

    return (
      <span className={S["icon-text-long-dropdown__selected"]}>
        {selectedOption?.icon && (
          <Stack sx={{ marginRight: "5px" }}>
            {React.createElement(selectedOption.icon, { w: 20 })}
          </Stack>
        )}
        {selectedOption?.text}
      </span>
    );
  };

  return (
    <div style={{ width: width }}>
      <FormControl style={{ width }} {...props}>
        {label && (
          <Typography
            sx={{
              font: "normal normal 300 10px/13px Source Serif Pro",
              marginBottom: "5px",
            }}
          >
            {label}
          </Typography>
        )}
        <Select
          open={isOpen}
          onClick={!isOpen ? () => setIsOpen(true) : () => null}
          IconComponent={() => <GreenDropdownIcon open={isOpen} />}
          displayEmpty
          value={selectedOption?.text}
          onChange={handleChange}
          input={
            <OutlinedInput
              className={S["icon-text-long-dropdown__inputbox"]}
              classes={{
                notchedOutline: S["icon-text-long-dropdown__notchedOutline"],
                focused: S["icon-text-long-dropdown__focused"],
              }}
            />
          }
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          renderValue={(selected) => renderSelectedValue(selected)}
          MenuProps={menuProps}
        >
          <Stack
            sx={{ padding: "5px 10px" }}
            onMouseDown={handleSearchInputMouseDown}
            onKeyDown={handleSearchInputKeyDown}
          >
            <SearchInDropdown width="100%" />
          </Stack>
          {options.map((option, index) => (
            <MenuItem
              className={S["icon-text-long-dropdown__menu-item"]}
              key={index}
              value={option.text}
            >
              <Stack direction="row" alignItems="center" gap={"5px"}>
                {React.createElement(option.icon, { w: 15 })}
                <Typography className={S["icon-text-long-dropdown__title"]}>
                  {option.text}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default IconTextLongDropdown;
