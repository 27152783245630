import React, { FC } from "react";
import g from "./TemplateBlock.module.css";
import { Box, Stack, Typography } from "@mui/material";
import { ActivtiyIcon, TemplateIcon } from "../../../../../images";
import { ColoredBadge } from "../../../../common";

interface TemplateBlockProps {
  templateBlockImageUrl?: string;
  templateName: string;
  templateEmoji: string;
  templateDescription: string;
  template_fall_under_category: string[];
}

const TemplateBlock: FC<TemplateBlockProps> = ({
  templateBlockImageUrl,
  templateName = "Sales",
  templateEmoji = "💸",
  templateDescription = "All the essential tools a Sales team requires to track their deals. Capture key information such as contract value, point of contact, and deal stage, supplemented by automatic relationship analytics.",
  template_fall_under_category = ["sales", "fundraising"], // string as id
}) => {
  return (
    <Stack className={g["template-block"]}>
      <Stack m={0.5} direction={"row"} alignItems={"flex-start"} gap={2.5}>
        {/* Template Image */}
        <img
          className={g["template-block__image"]}
          src={
            templateBlockImageUrl ||
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRH3R0yyjPzMCJOBaA3XjBqgfXrj9Iu_V5QtA&s"
          }
          alt="template-block-by-name"
        />
        <Stack direction={"column"} flexGrow={1} gap={1}>
          {/* Template Name and Emoji */}
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <Typography> {templateEmoji} </Typography>
            <Typography className={g["template-block__name"]}>
              {templateName}
            </Typography>
          </Stack>
          {/* Template Description and Icons */}
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
            gap={1}
            flexGrow={1}
          >
            <Stack sx={{ flexGrow: 1 }}>
              <Typography className={g["template-block__description"]}>
                {templateDescription}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={1}
              width={"100px"}
              flexShrink={0}
            >
              <ActivtiyIcon w={15} />
              <TemplateIcon w={15} />
              <ActivtiyIcon w={15} />
              <ActivtiyIcon w={15} />
            </Stack>
          </Stack>
          {/* Template Categories */}
          <Stack mt={0.5} direction={"row"} alignItems={"center"} gap={1}>
            {template_fall_under_category.map((category, index) => (
              <ColoredBadge
                title={category}
                key={index}
                showIcon={false}
                bgColor={"pink"}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TemplateBlock;
