import React, { useState } from "react";
import { EditPencilIcon, StrikethroughIcon, TxIcon } from "../../../images";
import { Divider, IconButton, Stack, Popover, Tooltip } from "@mui/material";
import { TextPickerDropdown } from "../TextPickerDropdown";
import { FontPickerdDropdown } from "../FontPickerdDropdown";
import { TextSizeDropdown } from "../TextSizeDropdown";
import { FontStyleBold } from "../FontStyleBold";
import { SmallColorPicker } from "../SmallColorPicker";
import { LineSpacingDropdown } from "../LineSpacingDropdown";
import { LineSpacingDropdownOptions } from "../LineSpacingDropdown/LineSpacingDropdownType";
import { BulletPointDropdownOptions } from "../BulletPointDropdown/BulletPointDropdownType";
import { BulletPointDropdown } from "../BulletPointDropdown";
import { AttechFile } from "../AttechFile";
import { AddLinkDropdown } from "../AddLinkDropdown";
import { AddComment } from "../AddComment";

import styles from "./EmailRichTextMenu.module.css";

interface EmailRichTextMenuProps {}

const EmailRichTextMenu: React.FC<EmailRichTextMenuProps> = ({}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "rich-text-menu-popover" : undefined;

  return (
    <>
      <Tooltip title="Text Decorator" placement="top" arrow>
        <IconButton sx={{ p: 0 }} disableRipple onClick={handleClick}>
          <EditPencilIcon w={15} />
        </IconButton>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        PaperProps={{
          sx: { p: 0, boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.15)" },
        }}
      >
        <Stack className={styles.emailRichTextMenu}>
          {/* ---- options list 1 here ----- */}
          <Stack className={styles.emailRichTextMenu__optionsList}>
            <TextPickerDropdown onSelect={(value) => null} />

            <Divider
              orientation="vertical"
              className={styles.emailRichTextMenu__divider}
            />

            <FontPickerdDropdown onSelect={(value) => null} />

            <Divider
              orientation="vertical"
              className={styles.emailRichTextMenu__divider}
            />

            <TextSizeDropdown />

            <Divider
              orientation="vertical"
              className={styles.emailRichTextMenu__divider}
            />

            <FontStyleBold />
          </Stack>
          {/* ---- options list 1 end here ----- */}
          {/* -----------------------------------*/}
          {/* ---- options list 2 here ----- */}
          <Stack className={styles.emailRichTextMenu__optionsList}>
            <SmallColorPicker onSelect={(value) => null} />
            <LineSpacingDropdown
              option={LineSpacingDropdownOptions}
              onSelect={(value) => null}
            />
            <BulletPointDropdown
              option={BulletPointDropdownOptions}
              onSelect={(value) => null}
            />

            <Divider
              orientation="vertical"
              className={styles.emailRichTextMenu__divider}
            />

            <AddLinkDropdown onApply={(value) => null} />
            <AttechFile onSelect={(file) => console.log(file)} />

            <Divider
              orientation="vertical"
              className={styles.emailRichTextMenu__divider}
            />

            <IconButton
              className={styles.emailRichTextMenu__iconButton}
              disableRipple
            >
              <StrikethroughIcon w={15} />
            </IconButton>

            <IconButton
              className={styles.emailRichTextMenu__iconButton}
              disableRipple
            >
              <TxIcon w={15} />
            </IconButton>

            <Divider
              orientation="vertical"
              className={styles.emailRichTextMenu__divider}
            />

            <AddComment />
          </Stack>
          {/* ---- options list 2 end here ----- */}
        </Stack>
      </Popover>
    </>
  );
};

export default EmailRichTextMenu;
