export interface LongSearchBearishOSListType {
  id: string;
  text: string;
  value: string;
  connectionType?: string;
}

// this is sample data for rener

// Remeber to export interface
export const OPTIONS: LongSearchBearishOSListType[] = [
  { id: "1", text: "data1", value: "value1", connectionType: "crm" },
  { id: "2", text: "data2", value: "value2", connectionType: "crm" },
  { id: "3", text: "data3", value: "value3", connectionType: "workspace" },
  { id: "4", text: "data4", value: "value4", connectionType: "crm" },
  { id: "5", text: "data5", value: "value5", connectionType: "project" },
  { id: "6", text: "data6", value: "value6", connectionType: "crm" },
  { id: "7", text: "data7", value: "value7", connectionType: "crm" },
  { id: "8", text: "data8", value: "value8", connectionType: "video" },
  { id: "9", text: "data9", value: "value9", connectionType: "crm" },
  { id: "10", text: "data10", value: "value10", connectionType: "crm" },
  { id: "11", text: "data11", value: "value11", connectionType: "crm" },
  { id: "12", text: "data12", value: "value12", connectionType: "crm" },
];
