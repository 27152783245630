import React, { useEffect, useState } from "react";
import { Box, Stack, Zoom } from "@mui/material";
import { WelcomeWalkthrough } from "../../common";
import { getUser, isAuthenticated } from "../../../Utils";

/**
 * Dashboard component.
 *
 * This component represents the main dashboard view.
 */
const Dashboard: React.FC = () => {
  // Check if the welcome walkthrough has been shown before
  const showWelcomeLocalStorage: string | null =
    localStorage.getItem("WelcomeWalkthrough");

  // State to control whether to show the welcome walkthrough
  const [showWelcome, setShowWelcome] = useState<boolean>(false);

  // Effect to determine whether to show the welcome walkthrough based on local storage
  useEffect(() => {
    if (!showWelcomeLocalStorage || showWelcomeLocalStorage === undefined) {
      // If no entry in local storage or undefined, show the walkthrough
      setShowWelcome(true);
    } else if (showWelcomeLocalStorage === "false") {
      // If entry is "false", do not show the walkthrough
      setShowWelcome(false);
    }
  }, [showWelcomeLocalStorage]);

  const auth = isAuthenticated();
  const user = getUser();

  return (
    <Box>
      Dashboard
      {/* Stack for positioning */}
      <Stack position={"absolute"} bottom={"30px"} right={"30px"}>
        {/* Zoom animation for the welcome walkthrough */}
        <Zoom
          in={showWelcome}
          style={{ transitionDelay: showWelcome ? "500ms" : "0ms" }}
        >
          <div>
            {/* Welcome walkthrough component */}
            <WelcomeWalkthrough
              setShowWelcome={setShowWelcome}
              userFirstName={
                auth ? user?.data?.firstName + " " + user?.data?.lastName : ""
              }
            />
          </div>
        </Zoom>
      </Stack>
    </Box>
  );
};

export default Dashboard;
