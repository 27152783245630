import { IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { GreenDropdownIcon } from "../../../../../images";
import styles from "./SidebarMsgContainer.module.css";

interface SidebarMsgContainerProps {
  label: string;
  viewAllButtonClick: () => void;
  children: React.ReactNode;
}

const SidebarMsgContainer: React.FC<SidebarMsgContainerProps> = ({
  children,
  label,
  viewAllButtonClick,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <Stack
      className={styles["sidebar-msg-container"]}
      style={{ maxHeight: !isOpened ? "20px" : "250px" }}
    >
      <Stack className={styles["sidebar-msg-container__header"]}>
        <Typography
          onClick={() => setIsOpened((value) => !value)}
          className={styles["sidebar-msg-container__label"]}
        >
          {label}
        </Typography>
        <IconButton
          className={styles["sidebar-msg-container__icon-button"]}
          disableRipple
          onClick={() => setIsOpened((value) => !value)}
        >
          <GreenDropdownIcon sx={{ height: "10px" }} open={isOpened} />
        </IconButton>
        <Stack className={styles["sidebar-msg-container__divider"]} />
      </Stack>

      <Stack className={styles["sidebar-msg-container__content"]}>
        {children}
      </Stack>

      <Stack className={styles["sidebar-msg-container__footer"]}>
        <Typography
          onClick={viewAllButtonClick}
          className={styles["sidebar-msg-container__view-all"]}
        >
          View all
        </Typography>
      </Stack>
    </Stack>
  );
};

export default SidebarMsgContainer;
