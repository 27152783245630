import { Stack, Typography } from "@mui/material";
import React from "react";

type ConnectionAddedProps = {
  connection?: {
    id: string;
    text: string;
    connectionType?: string;
  };
};

/* 
Create a function to identify the connection type
*/

const ConnectionAdded: React.FC<ConnectionAddedProps> = ({ connection }) => {
  return (
    <div>
      {connection ? (
        <>
          <Stack>
            {/* Icon here.... */}
            <Typography key={connection.id}>{connection.text}</Typography>
          </Stack>
        </>
      ) : (
        <Typography>No connection data available</Typography>
      )}
    </div>
  );
};

export default ConnectionAdded;
