import React, { MouseEvent, useState } from "react";
import { InputBase, Popover, Stack, Tooltip } from "@mui/material";
import { ConnectedIcon } from "../../../images";
import { adjustHexColor } from "../../../Utils";
import { SmallButtonDropdown } from "../Buttons";

interface AddLinkDropdownProps {
  onApply: (data: { text: string; url: string }) => void;
}

const AddLinkDropdown: React.FC<AddLinkDropdownProps> = ({ onApply }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [text, setText] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleApply = () => {
    onApply({ text, url });
    setText("");
    setUrl("");
    handlePopoverClose();
  };

  return (
    <>
      <Tooltip title="Add link" placement="top" arrow>
        <Stack
          onClick={handlePopoverOpen}
          sx={{
            width: "15px",
            height: "15px",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <ConnectedIcon w={15} />
        </Stack>
      </Tooltip>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        container={document && document?.getElementById("full-ScreenMode")}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ marginTop: "10px" }}
        PaperProps={{
          style: { boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)" },
        }}
      >
        <Stack
          sx={{
            width: "145px",
            height: "85px",
            padding: "15px 5px",
            gap: "15px",
          }}
        >
          <InputBase
            placeholder="Text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            sx={{
              border: `0.5px solid ${adjustHexColor("#D9E3EF", 35)}`,
              borderRadius: "5px",
              padding: "0px 5px",
              height: "20px",
              font: "normal normal 300 14px/18px Source Serif Pro",
            }}
          />
          <InputBase
            placeholder="URL"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            sx={{
              border: `0.5px solid ${adjustHexColor("#D9E3EF", 35)}`,
              borderRadius: "5px",
              padding: "0px 5px",
              height: "20px",
              font: "normal normal 300 14px/18px Source Serif Pro",
            }}
          />
          <Stack sx={{ flexDirection: "row", justifyContent: "end" }}>
            <SmallButtonDropdown label="Apply" onClick={handleApply} />
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};

export default AddLinkDropdown;
