import React, { useEffect, useState } from "react";
import { DropdownLarge } from "../../../common";
import { Department, FirstLoginData, FirstLoginDataProps } from "./StepsType";
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  AuthState,
  CompanyAvailability,
  companyAvailibityPayload,
  companyRegistryCheck,
  fetchCompanyLists,
  getAllDepartment,
} from "../../../../redux";
import { RootState } from "../../../../redux/store";
import CompanyNameAutocomplete from "./CompanyNameAutocomplete/CompanyNameAutocomplete ";

interface StepTwoProps extends FirstLoginDataProps {
  firstLoginData: FirstLoginData;
  setFirstLoginData: React.Dispatch<React.SetStateAction<FirstLoginData>>;
}

const StepTwo: React.FC<StepTwoProps> = ({
  firstLoginData,
  setFirstLoginData,
}) => {
  const {
    departmentList = [],
    companyAvailibility = {},
    companyList,
  } = useSelector((state: RootState) => state.auth as AuthState);

  const dispatch = useDispatch();

  const [companyAvalibilityCheckLoader, setCompanyAvalibilityCheckLoader] =
    useState<boolean>(false);

  // Function to update the department in firstLoginData
  const updateDepartment = (departments: Department[]) => {
    setFirstLoginData({
      ...firstLoginData,
      departMent: departments,
    });
  };

  const getALlDepartments = async () => {
    await dispatch(getAllDepartment());
  };

  useEffect(() => {
    if (departmentList.length === 0) {
      getALlDepartments();
    }
    // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [firstLoginData]);

  const handleCompanyRegistryList = async (value: string) => {
    setCompanyAvalibilityCheckLoader(true);
    if (!value) {
      return;
    }
    const payload: companyAvailibityPayload = {
      companyName: value,
    };
    await dispatch(fetchCompanyLists(payload));
    setCompanyAvalibilityCheckLoader(false);
  };

  const handleCompanyRegistryCheck = async () => {
    if (!firstLoginData.companyName) {
      return;
    }
    setCompanyAvalibilityCheckLoader(true);
    const payload: companyAvailibityPayload = {
      companyName: firstLoginData.companyName,
    };
    await dispatch(companyRegistryCheck(payload));

    setCompanyAvalibilityCheckLoader(false);
  };

  // Debounce the company registry check
  useEffect(() => {
    handleCompanyRegistryCheck();
    // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [firstLoginData.companyName]);

  useEffect(() => {
    if (
      companyAvailibility &&
      "message" in companyAvailibility &&
      "success" in companyAvailibility
    ) {
      const availability = companyAvailibility as CompanyAvailability;
      setFirstLoginData({
        ...firstLoginData,
        companyRegistryChecked:
          availability.message === "Company name is available" &&
          availability.success === true,
      });
    }    // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [companyAvailibility]);

  return (
    <Stack
      width={"100%"}
      gap={"15px"}
      alignItems={"center"}
      justifyContent={"center"}
      mb={"20px"}
    >
      {/* <LargeInputBox
        borderColor="black"
        loader={companyAvalibilityCheckLoader || false}
        label="Company name"
        value={firstLoginData?.companyName || ""}
        autoComplete="off"
        onChange={(e) => {
          const value = typeof e === "string" ? e : e.target.value;
          setFirstLoginData({
            ...firstLoginData,
            companyName: value,
          });
        }}
      /> */}

      <CompanyNameAutocomplete
        companyOptions={companyList?.data ?? []}
        firstLoginData={firstLoginData}
        setFirstLoginData={setFirstLoginData}
        companyAvalibilityCheckLoader={companyAvalibilityCheckLoader || false}
        handleCompanyRegistryList={handleCompanyRegistryList}
      />

      <DropdownLarge
        width="476px"
        componentHeader="Department"
        options={departmentList}
        onChange={updateDepartment} // Pass the updateDepartment function to the onChange prop
        borderColor="black"
        multiple={true} // Ensure multiple selection is enabled
        initialSelectedOptions={firstLoginData.departMent}
      />
    </Stack>
  );
};

export default StepTwo;
