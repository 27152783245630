import React, { useEffect, useState } from "react";
import { Gantt, Task, ViewMode as GanttViewMode } from "gantt-task-react";
import "gantt-task-react/dist/index.css"; // Import the required CSS

// Define a type for the component props (currently empty)
type GanttOverViewProps = {
  workspaceItemData?: any;
  taskWorkspaceItemData?: any;
};

// Sample data for tasks with dependencies
const initialTasks: Task[] = [
  {
    id: "Task 1",
    name: "Redesign website",
    start: new Date("2024-08-08"),
    end: new Date("2024-08-20"),
    progress: 20,
    type: "task",
    dependencies: [], // No dependencies
  },
  {
    id: "Task 2",
    name: "Develop mobile app",
    start: new Date("2024-08-10"),
    end: new Date("2024-08-25"),
    progress: 50,
    type: "task",
    dependencies: ["Task 1"], // Depends on Task 1
  },
  {
    id: "Task 3",
    name: "QA and testing",
    start: new Date("2024-08-15"),
    end: new Date("2024-08-30"),
    progress: 10,
    type: "task",
    dependencies: ["Task 2"], // Depends on Task 2
  },
];

const GanttOverView: React.FC<GanttOverViewProps> = ({
  taskWorkspaceItemData,
}) => {
  const [tasks, setTasks] = useState<Task[]>(initialTasks);
  const [viewMode, setViewMode] = useState<GanttViewMode>(GanttViewMode.Day);
  const transformToGanttTasks = (workspaceItemData: any) => {
    return workspaceItemData.reduce((acc: any, group: any) => {
      group.tasks.forEach((task: any) => {
        const ganttTask = {
          id: task._id,
          name: task.name,
          start: new Date(task.createdAt), // Assuming task creation as start date
          end: new Date(task.dueDate),
          progress: task.status.value === "Done" ? 100 : 0, // Example to set progress
          type: "task",
          dependencies: task.dependencies
            ? task.dependencies.map((dep: any) => dep._id)
            : [], // Ensure dependencies exist
        };
        acc.push(ganttTask);
      });
      return acc;
    }, []);
  };

  // Handle view mode toggle
  const toggleViewMode = () => {
    setViewMode((prevMode) =>
      prevMode === GanttViewMode.Day ? GanttViewMode.Week : GanttViewMode.Day
    );
  };
  // Update tasks whenever the workspaceItemData prop changes
  useEffect(() => {
    if (taskWorkspaceItemData) {
      const transformedTasks = transformToGanttTasks(taskWorkspaceItemData);
      setTasks(transformedTasks);
    }
  }, [taskWorkspaceItemData]);
  // Handle task updates (e.g., on drag or resize)
  const handleTaskChange = (task: Task) => {
    setTasks((prevTasks) =>
      prevTasks.map((t) => (t.id === task.id ? { ...t, ...task } : t))
    );
  };

  // Note: You will need to handle task deletion through other means if the library does not support it

  return (
    <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
      <div
        style={{
          padding: "10px",
          background: "#f0f0f0",
          borderBottom: "1px solid #ddd",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <button onClick={toggleViewMode}>Toggle View Mode</button>
        {/* Additional control buttons can be added here */}
      </div>
      <Gantt
        tasks={tasks}
        viewMode={viewMode}
        // onTaskChange={handleTaskChange} // Register the task change handler
        // Add any other required props here
      />
    </div>
  );
};

export default GanttOverView;
