import React from "react";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/css/plugins/emoticons.min.css";
import Froalaeditor from "froala-editor";
import "./comment__frolal.css";
import { doubleImagef, Tvf } from "../../../../../../images";
const FroalalComment = ({ content, handleContentChange }) => {
  // Define the icon for the color picker
  Froalaeditor.DefineIcon("DoubleImagePicker", {
    NAME: "doubleImagePicker",
    template: "image",
    PATH: doubleImagef,
  });

  Froalaeditor.DefineIcon("TVs", {
    NAME: "tvs",
    template: "image",
    PATH: Tvf,
  });

  Froalaeditor.RegisterCommand("TVs", {
    title: "",
  });
  // Register the command for inserting media from the computer
  Froalaeditor.RegisterCommand("DoubleImagePicker", {
    title: "Insert Media from File",
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      // Trigger Froala's image and video insert popups
      const mediaOptions = ["image", "video"];

      // Creating a hidden file input for selecting both image and video files
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*,video/*"; // Accept both image and video files
      input.style.display = "none";

      // When a file is selected, insert it into the editor
      input.onchange = (event) => {
        const file = event.target.files[0];
        if (file) {
          // Check if the file is an image or video
          if (file.type.startsWith("image/")) {
            this.image.upload([file]);
          } else if (file.type.startsWith("video/")) {
            this.video.upload([file]);
          } else {
            alert("Invalid file type. Please select an image or video file.");
          }
        }
      };

      // Simulate a click on the file input
      input.click();
    },
  });

  const config = {
    pluginsEnabled: [
      "align",
      "charCounter",
      "codeBeautifier",
      "codeView",
      "colors",
      "draggable",
      "emoticons",
      "fontAwesome",
      "fontSize",
      "fullscreen",
      "image",
      "imageManager",
      "inlineClass",
      "inlineStyle",
      "link",
      "lists",
      "paragraphFormat",
      "paragraphStyle",
      "quickInsert",
      "quote",
      "save",
      "table",
      "url",
      "video",
      "wordPaste",
    ],
    language: "en",
    heightMax: 210,
    heightMin: 210,
    widthMin: 210,
    widthMax: 210,toolbarBottom: true,
    listAdvancedTypes: true,
    key: "8JF3bB2A5A4C3A1E2E2zPAENHMf1JPQRFZBTBf1WWEPYDbB3H3E2A16A19B7C5C6A2C3==",
    attribution: false,
    charCounterCount: false,
    quickInsertEnabled: false,
    toolbarButtons: [
      [
        "bold",
        "italic",
        "underline",
        "|",
        "Align",
        "List",
        "emoticons",
        "|",
        "DoubleImagePicker",
        "TVs",
      ],
    ],
    initOnClick: false,
    autofocus: true,
  };
  return (
    <div
      className="comment__frolal"
      style={{ minWidth: 308, overflow: "hidden" }}
    >
      <FroalaEditor
        tag="textarea"
        model={content}
        onModelChange={handleContentChange}
        config={config}
      />
    </div>
  );
};

export default FroalalComment;
