import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { GreenArrowButton, MediumInputIconBox } from "../../../../../../common";
import {
  HugeCreateCardImage1,
  HugeCreateCardImage2,
  HugeCreateCardImage3,
  HugeCreateCardImage4,
  HugeCreateCardImage5,
  HugeCreateCardImage6,
  HugeCreateCardImage7,
  HugeCreateCardImage8,
  HugeCreateCardImage9,
  HugeCreateCardImage10,
  HugeCreateCardImage11,
  HugeCreateCardImage12,
  BearPng,
} from "../../../../../../../images";
import S from "./MainDetails.module.css";
import { CreateCardData } from "../../../MainTop/types";

interface MainDetailsProps {
  createCardData: CreateCardData;
  updateMainDetails: (value: Partial<CreateCardData["mainDetails"]>) => void;
}

const IMG_ARRAY = [
  { id: 1, imgSrc: HugeCreateCardImage1 },
  { id: 2, imgSrc: HugeCreateCardImage2 },
  { id: 3, imgSrc: HugeCreateCardImage3 },
  { id: 4, imgSrc: HugeCreateCardImage4 },
  { id: 5, imgSrc: HugeCreateCardImage5 },
  { id: 6, imgSrc: HugeCreateCardImage6 },
  { id: 7, imgSrc: HugeCreateCardImage7 },
  { id: 8, imgSrc: HugeCreateCardImage8 },
  { id: 9, imgSrc: HugeCreateCardImage9 },
  { id: 10, imgSrc: HugeCreateCardImage10 },
  { id: 11, imgSrc: HugeCreateCardImage11 },
  { id: 12, imgSrc: HugeCreateCardImage12 },
];

const MainDetails: React.FC<MainDetailsProps> = ({
  createCardData,
  updateMainDetails,
}) => {
  const [imgDisplay, setImgDisplay] = useState<"one" | "two">("one"); // Local state for image display

  // Ref for the file input element to programmatically trigger it
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  // Function to trigger the file input click
  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  // Function to handle the file input change event
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // Update mainDetails with uploaded image info
      updateMainDetails({
        defaultProjectImage: false,
        defaultProjectImageIndex: -1,
        uploadProjectImage: file,
      });
    }
  };

  // Function to handle image box click and select the image
  const handleBoxClick = (index: number) => {
    // Update mainDetails with selected image index
    updateMainDetails({
      defaultProjectImageIndex: index,
      defaultProjectImage: true,
      uploadProjectImage: null,
    });
  };

  // Function to toggle between 'one' and 'two' states for imgDisplay
  const handleArrowClick = () => {
    // Toggle image display state locally
    setImgDisplay((prev) => (prev === "one" ? "two" : "one"));
  };

  return (
    <Stack className={S["main-details"]}>
      {/* Input boxes */}
      <Stack gap="10px">
        <MediumInputIconBox
          sx={{ width: "100%", height: "30px" }}
          label="Name"
          placeholder="Give your Project a Unique Name"
          value={createCardData.mainDetails.projectName}
          onChange={(e) => {
            const value = typeof e === "string" ? e : e.target.value;
            updateMainDetails({ projectName: value });
          }}
        />
        <MediumInputIconBox
          sx={{ width: "100%", height: "100px" }}
          multiline
          label="Details"
          placeholder="Add some details to better describe your Project and keep things flowing."
          value={createCardData.mainDetails.projectDetails}
          onChange={(e) => {
            const value = typeof e === "string" ? e : e.target.value;
            updateMainDetails({ projectDetails: value });
          }}
        />
      </Stack>
      <Typography className={S["main-details__upload-text"]}>
        Upload or Choose an Image
      </Typography>

      {/* Image list */}
      <Stack className={S["main-details__image-container"]}>
        <span style={{ position: "relative" }}>
          <GreenArrowButton
            sx={{
              position: "absolute",
              right: "-20px",
              top: "34px",
              transform:
                imgDisplay === "one" ? "rotate(0deg)" : "rotate(180deg)",
            }}
            onClick={handleArrowClick}
          />
        </span>
        {imgDisplay === "one" ? (
          <Stack className={S["main-details__image-list"]}>
            {IMG_ARRAY.map((item, index) => (
              <Box
                component="div"
                key={index}
                className={`${S["main-details__box"]} ${
                  createCardData.mainDetails.defaultProjectImageIndex === index
                    ? S["main-details__box--selected"]
                    : ""
                }`}
                onClick={() => handleBoxClick(index)}
              >
                <Box
                  component="img"
                  src={item.imgSrc}
                  className={S["main-details__image"]}
                />
              </Box>
            ))}
          </Stack>
        ) : (
          <>
            <div
              onClick={handleButtonClick}
              style={{ height: "100%", cursor: "pointer" }}
            >
              <Stack className={S["main-details__drag-drop-container"]}>
                <Box
                  component={"img"}
                  src={BearPng}
                  height={"75px"}
                  width={"65px"}
                />
                <Stack sx={{ width: "190px" }}>
                  <Typography className={S["main-details__drag-drop-text"]}>
                    Drag and Drop an Image here,
                  </Typography>
                  <Typography className={S["main-details__drag-drop-text"]}>
                    Choose one from the list or BEBA
                  </Typography>
                  <Typography className={S["main-details__drag-drop-text"]}>
                    AI will create one for you
                  </Typography>
                </Stack>
              </Stack>
            </div>

            {/* Hidden file input for image upload */}
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default MainDetails;
