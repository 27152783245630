import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TaskIcon } from "../../../../../../../images";
import {
  GreenArrowButton,
  ObjectiveTaskDropdown,
} from "../../../../../../common";
import { useDispatch } from "react-redux";
import {
  createSubTask__api,
  CreateSubTaskPayload,
  getAllSubTasks__api,
} from "../../../../../../../redux";

interface SubTasksProps {
  componentId?: string;
}

const SubTasks: React.FC<SubTasksProps> = ({ componentId = "" }) => {
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const [subTaskData, setSubTaskData] = useState<any[]>([]); // State for storing subtasks

  const toggleContainer = () => {
    setIsExpanded(!isExpanded);
  };

  const handleCreateSubTask = async (
    taskName: string,
    status: any,
    dueDate: string,
    selectedUser: any
  ) => {
    if (!componentId) {
      return;
    }

    // Map selectedUser to an array of user IDs
    const assignedUserIds = Array.isArray(selectedUser)
      ? selectedUser.map((user: any) => user._id)
      : [selectedUser._id]; // Handle the case where selectedUser might be a single object

    // Ensure assignedUserIds is not empty
    if (assignedUserIds.length === 0) {
      console.error("At least one user must be assigned to the subtask.");
      return;
    }

    const payload: CreateSubTaskPayload = {
      name: taskName,
      details: "",
      assignedUserIds: assignedUserIds,
      dueDate: dueDate,
      status: status,
      taskColor: "#FFA500",
    };

    const action = createSubTask__api(componentId, payload);

    await dispatch(action);
    handleGetAllSubtasks(); // Refresh the subtask list after creating a new one
  };

  const handleGetAllSubtasks = async () => {
    if (!componentId) return;
    const action = getAllSubTasks__api(componentId, 1, 10, "asc");
    const res: any = await dispatch(action);
    if (res?.data) {
      setSubTaskData(res.data); // Update state with fetched subtask data
    }
  };

  useEffect(() => {
    if (!componentId) return;
    handleGetAllSubtasks();
  }, [componentId]);

  return (
    <Stack sx={{ bgcolor: "white" }}>
      <Stack sx={{ flexDirection: "row", gap: "10px", alignItems: "center" }}>
        <TaskIcon w={24} />
        <Typography
          sx={{ font: "normal normal normal 18px/25px Source Serif Pro" }}
        >
          Sub-Task
        </Typography>
        <Typography
          sx={{ font: "normal normal bold 14px/20px Source Serif Pro" }}
        >
          {subTaskData.length || 0} {/* Display total number of sub-tasks */}
        </Typography>
        <GreenArrowButton
          sx={{
            transform: isExpanded ? "rotate(90deg)" : "rotate(270deg)",
            transition: "transform 0.2s ease-in-out",
          }}
          onClick={toggleContainer}
        />
      </Stack>
      <Stack
        sx={{
          height: isExpanded ? "270px" : "0px",
          overflow: "hidden",
          transition: "height 0.3s ease",
          overflowY: "scroll",
          scrollbarWidth: "none",
          marginTop: "15px",
          gap: "25px",
        }}
      >
        {/* Render ObjectiveTaskDropdown for each subtask */}
        {subTaskData.length ? (
          subTaskData.map((data) => (
            <ObjectiveTaskDropdown
              handleCreateSubTask={handleCreateSubTask}
              key={data._id}
              options={[data]} // Pass subtask data
            />
          ))
        ) : (
          <ObjectiveTaskDropdown
            handleCreateSubTask={handleCreateSubTask}
            options={[]} // Pass empty options if no subtasks
          />
        )}
      </Stack>
    </Stack>
  );
};

export default SubTasks;
