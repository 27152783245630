import React from 'react'

type Props = {}

const InsideFileCloudStorage = (props: Props) => {
  return (
    <div>InsideFileCloudStorage</div>
  )
}

export default InsideFileCloudStorage