import { Box, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import styles from "./LargeCardAndImage.module.css";
import {
  ColorPickerDot,
  SmallThreeDotMenu,
  // StyledTooltip,
} from "../../../../common";
import { formatDate } from "../../../../../Utils";

interface LargeCardAndImageProps {
  title: string;
  dateCreated: string;
  imageUrl: string | File;
  tabActive?: number /** tabActive will be used to show user's project and shared project state */;
  handleNavigate?: (args: string) => void;
  _id: string;
  onClickAction?: (someArg: string, _id: string) => void;
  cardColor?: string;onThemeUpdate?: (args: string) => void;
}

const LargeCardAndImage: React.FC<LargeCardAndImageProps> = ({
  title,
  dateCreated,
  imageUrl,
  handleNavigate,
  _id,
  onClickAction,
  cardColor = "#461959",onThemeUpdate
}) => {
  const formattedDate = formatDate(dateCreated);
  return (
    <Stack className={styles.largeCardAndImage}>
      <Stack
        onClick={() =>
          handleNavigate && handleNavigate(_id || "brokenIdentifier")
        }
        className={styles.largeCardAndImage__imageContainer}
      >
        <Box
          component={"img"}
          alt="cardImg"
          src={imageUrl as string}
          className={styles.largeCardAndImage__image}
        />
      </Stack>
      <Stack className={styles.largeCardAndImage__details}>
        <Stack className={styles.largeCardAndImage__detailsContainer}>
          <Stack
            onClick={() =>
              handleNavigate && handleNavigate(_id || "brokenIdentifier")
            }
            className={styles.largeCardAndImage__textContainer}
          >
            <Typography className={styles.largeCardAndImage__title}>
              {title}
            </Typography>
            <Typography className={styles.largeCardAndImage__date}>
              Created on: {formattedDate}
            </Typography>
          </Stack>
          <Stack className={styles.largeCardAndImage__actions}>
            <Tooltip title="Edit color">
              <ColorPickerDot onColorChange={(color:string) => onThemeUpdate && onThemeUpdate(color)} initialColor={cardColor ? cardColor : "#461959"} />
            </Tooltip>
            <SmallThreeDotMenu
              onClickAction={(action) => {
                onClickAction && onClickAction(action, _id);
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LargeCardAndImage;
