import { Middleware } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";
import CryptoJS from "crypto-js";
import { showSnackbar } from "../Slice";

// Define the structure of actions that interact with APIs
interface ApiAction {
  type: string;
  payload: {
    axiosRequired: boolean;
    method: AxiosRequestConfig["method"];
    url: string;
    data?: any;
    onStart?: string;
    onSuccess: string;
    onError: string;
    headers?: Record<string, string>; // New headers field
  };
}

const clearCookies = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
};

const clearAllStorage = () => {
  // Clear localStorage
  localStorage.clear();

  // Clear sessionStorage
  sessionStorage.clear();

  // Clear cookies
  clearCookies();
};

// Type guard to check if an action is an API action
const isApiAction = (action: any): action is ApiAction => {
  return (
    typeof action === "object" &&
    action !== null &&
    "payload" in action &&
    typeof action.payload === "object" &&
    action.payload !== null &&
    "axiosRequired" in action.payload &&
    typeof action.payload.axiosRequired === "boolean" &&
    "method" in action.payload &&
    typeof action.payload.method === "string" &&
    "url" in action.payload &&
    typeof action.payload.url === "string" &&
    "onSuccess" in action.payload &&
    typeof action.payload.onSuccess === "string" &&
    "onError" in action.payload &&
    typeof action.payload.onError === "string"
  );
};

// Encryption settings
const secretKey = process.env.REACT_APP_CRYPTO_SECRET;
const enableEncryption = false;

// Function to encrypt data
const encryptData = (data: any): string => {
  if (!secretKey) {
    throw new Error("Missing encryption secret key");
  }
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

// Function to decrypt data
const decryptData = (ciphertext: string): any => {
  if (!secretKey) {
    throw new Error("Missing encryption secret key");
  }
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

// Determine the base URL based on the environment
const baseURL =
  process.env.REACT_APP_NODE === "production"
    ? process.env.REACT_APP_BACKEND_BASEURL_PRODUCTION
    : process.env.REACT_APP_NODE === "localDevelopment"
      ? process.env.REACT_APP_BACKEND__LOCAL_BASEURL_DEVELOPMENT
      : process.env.REACT_APP_BACKEND_BASEURL_DEVELOPMENT;

// API Middleware
const ApiMiddleware: Middleware =
  ({ dispatch, getState }) =>
    (next) =>
      async (action: any) => {
        // Explicitly type the action as `any`

        if (!isApiAction(action) || !action.payload.axiosRequired) {
          return next(action);
        }

        // Destructure action payload
        const {
          method,
          url,
          data,
          onStart,
          onSuccess,
          onError,
          headers: actionHeaders,
        } = action.payload;

        // Default headers
        const defaultHeaders: Record<string, string> = {
          language: "en",
          Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        };

        // Merge default headers with action headers
        const headers = { ...defaultHeaders, ...actionHeaders };

        // Dispatch onStart action if provided
        if (onStart) {
          dispatch({ type: onStart });
        }

        // Determine if data needs encryption
        const isFormData = data instanceof FormData;
        const requestData =
          enableEncryption && data && !isFormData ? encryptData(data) : data;

        try {
          // Make API call with headers
          const response = await axios({
            method,
            url: `${baseURL}${url}`,
            data: requestData,
            headers,
            transformResponse: [
              (responseData) => {
                // Decrypt response if encryption is enabled
                return enableEncryption && !isFormData
                  ? decryptData(responseData)
                  : responseData;
              },
            ],
          });

          // Check if response data is JSON
          let responseData;
          try {
            responseData =
              typeof response.data === "string"
                ? JSON.parse(response.data)
                : response.data;
          } catch (parseError) {
            console.warn("Error parsing response", parseError);

            // Dispatch onError action with response data
            dispatch({ type: onError, payload: "Error parsing response" });

            // Show success message if provided in response
            dispatch(
              showSnackbar({
                message: "Error parsing response",
                severity: "error",
              })
            );

            throw new Error("Response is not valid JSON");
          }

          // Dispatch onSuccess action with response data
          dispatch({ type: onSuccess, payload: responseData });

          // Show success message if provided in response
          if (responseData.message) {
            dispatch(
              showSnackbar({
                message: responseData.message,
                severity: "success",
              })
            );
          }  
          // console.log(responseData, "responseData")
              // Return the response as a promise so the dispatch can chain .then()
      return Promise.resolve(responseData);
        } catch (error: any) {
          const getErrors = [
            "Invalid user",
            "Not authorized, token failed",
            "Needs to loggedIn first!",
            "Token Expired",
            "Authentication Failed",
            "Unauthorized request"
          ];

          let responseData;

          // Try to parse error response
          try {
            responseData =
              typeof error?.response?.data === "string"
                ? JSON.parse(error.response.data)
                : error.response.data;
          } catch (parseError) {
            responseData = error?.response?.data;
          }

          // Dispatch onError action with error message
          dispatch({
            type: onError,
            payload: responseData || "Something went wrong",
          });

          // Show error message from API response if available
          dispatch(
            showSnackbar({
              message: responseData?.message || "Something went wrong",
              severity: "error",
            })
          );

          if (getErrors.includes(responseData?.message)) {
            clearAllStorage();
            window.location.replace("/");
          }

          // Log the error for debugging purposes
          console.error("API Middleware Error: ", error);
        }
        return getState();  
      };

export default ApiMiddleware;
