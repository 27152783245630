import {
  TextField,
  TextFieldProps,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import React from "react";

// Define a type that includes the required label prop
type LargeInputBoxProps = TextFieldProps & {
  label: string;
  value: string;
  onChange: (value: string) => void; // Define the onChange handler type
  borderColor?: "black" | "white" | "#E9EDF2"; // Add optional prop for border color
  loader?: boolean;
  height?: string; // Add optional prop for height
};

const LargeInputBox: React.FC<LargeInputBoxProps> = ({
  label,
  value,
  onChange,
  borderColor = "white",
  loader = false,
  height, // Default height if not provided
  ...props
}) => {
  // Define an internal onChange handler that calls the provided onChange prop
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChange(e.target.value);
  };

  return (
    <TextField
      label={label}
      variant="standard"
      size="medium"
      fullWidth
      autoComplete="new-password"
      value={value}
      onChange={handleChange} // Pass the internal onChange handler
      InputProps={{
        sx: {
          fontFamily: "Source Serif Pro",
          fontWeight: 300,
          padding: "5px",
          fontSize: "15px",
          lineHeight: "21px",
          border: `1px solid ${borderColor}`,
          borderRadius: "5px",
          outline: "none",
          height, // Apply the height prop here
          "&:before": {
            borderBottom: "none",
          },
          "&:after": {
            borderBottom: "none",
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
          },
        },
        endAdornment: loader ? (
          <InputAdornment position="end">
            <CircularProgress size={20} />
          </InputAdornment>
        ) : null,
      }}
      InputLabelProps={{
        sx: {
          fontFamily: "Source Serif Pro",
          fontWeight: 300,
          fontSize: "16px",
          padding: "0px 5px",
          color: "black",
          "&.Mui-focused": {
            color: "black",
            transform: "translate(0, -5px) scale(0.75)", // Adjust the position and scale when focused
          },
          "&.MuiFormLabel-filled": {
            transform: "translate(0, -5px) scale(0.75)", // Adjust the position and scale when the input is filled
          },
        },
      }}
      {...props}
    />
  );
};

export default LargeInputBox;
