// Define the structure for each connection item
export interface ConnectionItem {
    id: string;
    text: string;
    value: string;
    connectionType?: string;
}

// Define the structure for each share project item (same as ConnectionItem)
export interface ShareWorkspaceItem {
    userId: string;
    text: string;
    value: string;
    permissions: "view" | "edit",
    role: "owner" | "admin" | "editor" | "viewer"
    data?: any;
}

// Update the CreateCardData interface
export interface CreateCardData {
    mainDetails: {
        workspaceName: string;
        workspaceDetails: string;
        defaultWorkspaceImage: boolean;
        defaultWorkspaceImageIndex: number;
        uploadWorkspaceImage: File | string;
    };
    connections: {
        connectionList: ConnectionItem[];
    };
    shareWorkspace: {
        shareWorkspaceList: ShareWorkspaceItem[];
    };
}

// Define the initialCreateCardData with the new structure
export const initialCreateCardData: CreateCardData = {
    mainDetails: {
        workspaceName: "",
        workspaceDetails: "",
        defaultWorkspaceImage: true,
        defaultWorkspaceImageIndex: 0,
        uploadWorkspaceImage: "",
    },
    connections: {
        connectionList: [],
    },
    shareWorkspace: {
        shareWorkspaceList: [],
    },
};
