// src/components/TypeFilterComponent.tsx
import React, { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { CheckboxMedium } from "../../../../common";
import {
  SalesIcon,
  StartUpIcon,
  FundraisingIcon,
  VentureCapitalIcon,
  InvestingIcon,
  RecruitingIcon,
  HRIcon,
  MarketingIcon1,
  OperationsIcon,
} from "../../../../../images";
import g from "./CRMListTemplate.module.css";

export const FILTER_TYPES = [
  { _id: "sales", FilterName: "Sales", icon: <SalesIcon /> },
  { _id: "startups", FilterName: "Startups", icon: <StartUpIcon /> },
  { _id: "fundraising", FilterName: "Fundraising", icon: <FundraisingIcon /> },
  {
    _id: "ventureCapital",
    FilterName: "Venture Capital",
    icon: <VentureCapitalIcon />,
  },
  { _id: "investing", FilterName: "Investing", icon: <InvestingIcon /> },
  { _id: "recruiting", FilterName: "Recruiting", icon: <RecruitingIcon /> },
  { _id: "hr", FilterName: "HR", icon: <HRIcon /> },
  { _id: "marketing", FilterName: "Marketing", icon: <MarketingIcon1 /> },
  { _id: "operations", FilterName: "Operations", icon: <OperationsIcon /> },
];

interface TypeRenderComponentProps {
  IconTypeFilter: React.ReactNode;
  TypeFilter: string;
  FilterId: string; // Pass the _id for each filter type
  onToggleFilter: (filterId: string) => void;
  isChecked: boolean;
}

export const TypeRenderComponent: FC<TypeRenderComponentProps> = ({
  IconTypeFilter,
  TypeFilter,
  FilterId,
  onToggleFilter,
  isChecked,
}) => {
  const handleCheckboxClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation(); // This prevents the click from affecting other elements
    onToggleFilter(FilterId);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={0.5}
      onClick={() => onToggleFilter(FilterId)}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        {IconTypeFilter}
        <Typography className={g.filterName}>{TypeFilter}</Typography>
      </Stack>
      <div onClick={handleCheckboxClick}>
        <CheckboxMedium checked={isChecked} />
      </div>
    </Stack>
  );
};
