import { Stack, Typography } from "@mui/material";
import React from "react";
import styles from "./PageIndicator.module.css";

interface Page {
  id: number;
  label: string;
}

interface PageIndicatorProps {
  activePage: number;
  setCurrentPage: (pageid: number) => void;
}

const PageIndicator: React.FC<PageIndicatorProps> = ({
  activePage = 1,
  setCurrentPage,
}) => {
  const pages: Page[] = [
    { id: 1, label: "Main Details" },
    { id: 2, label: "Connect" },
    { id: 3, label: "Share Project" },
  ];

  return (
    <Stack className={styles["huge-create-page-indicator"]}>
      {pages.map((page) => (
        <span
          key={page.id}
          onClick={() => setCurrentPage(page.id)}
          className={styles["huge-create-page-indicator__item"]}
        >
          <Stack
            className={
              activePage === page.id
                ? styles["huge-create-page-indicator__border--selected"]
                : styles["huge-create-page-indicator__border--default"]
            }
          />
          <Typography
            className={
              activePage === page.id
                ? styles["huge-create-page-indicator__text--selected"]
                : styles["huge-create-page-indicator__text--default"]
            }
          >
            {page.label}
          </Typography>
        </span>
      ))}
    </Stack>
  );
};

export default PageIndicator;
