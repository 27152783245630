import React, { useState } from "react";
import { IconButton, TextField, Tooltip, Box, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import g from "../centralComponentStyle.module.css";
import { CorrectIcon } from "../../../../../../../images";
import { RootState } from "../../../../../../../redux/store";
import { WorkspaceState } from "../../../../../../../redux";
import { useSelector } from "react-redux";
import { Delete } from "@mui/icons-material";
import FroalaEditor from "../../../Froala/FroalaEditor";

interface TextComponentProps {
  initialText: string; // The initial text or paragraph
  onUpdate: (newText: string) => void; // Callback to update the parent component or store
  onDelete: () => void; // Callback to update the parent component or store
}

const TextComponent: React.FC<TextComponentProps> = ({
  initialText,
  onUpdate,
  onDelete,
}) => {
  
  const [text, setText] = useState<string>(initialText);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const workspaceState = useSelector(
    (state: RootState) => state.workspace as WorkspaceState
  );
  const { lockPage } = workspaceState || {};

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleChange = (event: any) => {
    setText(event);
  };

  const handleSave = () => {
    onUpdate(text); // Notify parent or store with the updated text
    setIsEditing(false);
  };

  const handleCancel = () => {
    setText(initialText); // Revert text to initial value
    setIsEditing(false);
  };

  return (
    <Box className={g.textComponent}>
      {isEditing ? (
        <Box className={g.editorContainer}>
          {/* <TextField
            multiline
            value={text}
            onChange={handleChange}
            rows={4}
            variant="outlined"
            className={g.textArea}
          /> */}
          <FroalaEditor content={text} setContent={handleChange} />
          <Box className={g.buttonContainer}>
            <Tooltip title="Save">
              <IconButton
                className={g.iconButtonWC}
                color="primary"
                onClick={handleSave}
              >
                <CorrectIcon w={19} color="green" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel">
              <IconButton
                className={g.iconButtonWC}
                color="error"
                onClick={handleCancel}
              >
                <CancelIcon sx={{ fontSize: 19 }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ) : (
        <Box className={g.textDisplay}>
          <div dangerouslySetInnerHTML={{ __html: text }} />
          {!lockPage && (
            <Stack
              className={g.editButton}
              direction={"row"}
              alignItems={"center"}
              gap={1}
            >
              <Tooltip title="Edit">
                <IconButton color="primary" onClick={handleEdit}>
                  <EditIcon sx={{ fontSize: 19 }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit">
                <IconButton color="error" onClick={onDelete}>
                  <Delete sx={{ fontSize: 19 }} />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TextComponent;
