import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import styles from "./IconTextComponent.module.css"; // Import the CSS module

interface IconTextComponentProps {
  isActive: boolean;
  label?: string;
  children: React.ReactNode;
  onClick?: () => void;
}

// Pass only icon as children and text as props
const IconTextComponent: React.FC<IconTextComponentProps> = ({
  label,
  isActive,
  children,
  onClick,
}) => {
  return (
    <Button
      disableElevation
      onClick={onClick}
      variant="text"
      className={styles.iconTextButton}
    >
      <Stack
        justifyContent="end"
        alignItems="center"
        className={`${styles["iconTextButton__stack"]} ${isActive ? styles["iconTextButton--active"] : ""}`}
      >
        <Box className={styles["iconTextButton__icon"]}>{children}</Box>
        <Typography
          className={`${styles["iconTextButton__text"]} ${isActive ? styles["iconTextButton__text--active"] : ""}`}
        >
          {label}
        </Typography>
      </Stack>
    </Button>
  );
};

export default IconTextComponent;
