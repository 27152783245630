import { IconButton, InputBase, Stack, Typography } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { AddCalMinusIcon, AddCalPlusIcon } from "../../../images";

interface ZoomButtonProps {
  steps?: number;
  minValue?: number;
  maxValue?: number;
  onChange: (value: number) => void;
  iconSize?: 15 | 25;
}

const ZoomButton: React.FC<ZoomButtonProps> = ({
  steps = 1,
  minValue = 25,
  maxValue = 250,
  onChange,
  iconSize = 15,
}) => { 
  const [zoomValue, setZoomValue] = useState<number>(100);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const updateZoomValue = (value: number) => {
    const newValue = Math.max(minValue, Math.min(maxValue, value));
    setZoomValue(newValue);
    onChange(newValue); // Un-commented
  };

  const increaseHandler = () => {
    setZoomValue((prevZoomValue) => {
      const newValue = Math.max(
        minValue,
        Math.min(maxValue, prevZoomValue + steps)
      );
      onChange(newValue); // Un-commented
      return newValue;
    });
  };

  const decreaseHandler = () => {
    setZoomValue((prevZoomValue) => {
      const newValue = Math.max(
        minValue,
        Math.min(maxValue, prevZoomValue - steps)
      );
      onChange(newValue); // Un-commented
      return newValue;
    });
  };

  const handleMouseDown = (handler: () => void) => {
    handler();
    intervalRef.current = setInterval(handler, 100);
  };

  const handleMouseUp = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    if (!isNaN(value) || event.target.value === "") {
      setZoomValue(event.target.value === "" ? 0 : value);
    }
  };

  const handleInputBlur = () => {
    updateZoomValue(zoomValue);
    setIsEditing(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      updateZoomValue(zoomValue);
      setIsEditing(false);
    } else if (event.key === "ArrowUp") {
      updateZoomValue(zoomValue + steps);
    } else if (event.key === "ArrowDown") {
      updateZoomValue(zoomValue - steps);
    }
  };

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (onChange) {
      if (zoomValue <= maxValue && zoomValue >= minValue) {
        onChange(zoomValue);
      }
    }
  }, [zoomValue]);

  const getFontStyle = () => {
    switch (iconSize) {
      case 15:
        return "normal normal 900 14px/18px Source Serif Pro";

      case 25:
        return "normal normal 900 18px/23px Source Serif Pro";

      default:
        break;
    }
  };

  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
      <IconButton
        sx={{ p: 0 }}
        disableRipple
        onMouseDown={() => handleMouseDown(decreaseHandler)}
        onMouseUp={handleMouseUp}
      >
        <AddCalMinusIcon w={iconSize} />
      </IconButton>
      <Stack
        sx={{
          width: iconSize === 15 ? "54px" : iconSize === 25 ? "75px" : "auto",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 0,
          flexShrink: 0,
          padding: "0px 9px",
          boxSizing: "border-box",
        }}
        onClick={() => {
          setIsEditing(true);
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
      >
        <InputBase
          inputRef={inputRef}
          value={zoomValue === 0 && isEditing ? "" : zoomValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyDown={handleKeyDown}
          sx={{
            font: getFontStyle(),
            textAlign: "right",
            "& input": {
              textAlign: "center",
            },
          }}
        />
        <Typography sx={{ font: getFontStyle() }}>%</Typography>
      </Stack>
      <IconButton
        sx={{ p: 0 }}
        disableRipple
        onMouseDown={() => handleMouseDown(increaseHandler)}
        onMouseUp={handleMouseUp}
      >
        <AddCalPlusIcon w={iconSize} />
      </IconButton>
    </Stack>
  );
};

export default ZoomButton;
