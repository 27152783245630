import React, { memo } from "react";
import { ContentType } from "../../../../../redux";
import {
  CheckListComponent,
  CodeComponent,
  FileComponent,
  HeadingComponent,
  ListComponent,
  QuoteComponent,
  TableComponent,
  TextComponent,
} from "./Components";
import { Divider } from "@mui/material";
import isEqual from "lodash/isEqual"; // Use lodash's deep comparison for itemData
import FroalaEditor from "../Froala/FroalaEditor";

interface ContentDisplayProps {
  type: ContentType;
  itemData: any;
  componentId: string;
  onContentUpdate: (
    componentId: string,
    updatedContent: any,
    type: ContentType
  ) => void;
  onContentDelete: (componentId: string) => void;
  d: boolean;
}

const ContentDisplay: React.FC<ContentDisplayProps> = memo(
  ({ type, itemData, componentId, onContentUpdate, onContentDelete, d }) => {
    if (d) return null;
    switch (type) {
      case ContentType.TEXT:
        return (
          <TextComponent
            initialText={itemData || "This is sample text"}
            onUpdate={(updatedText) =>
              onContentUpdate(componentId, updatedText, type)
            }
            onDelete={() => {
              onContentDelete(componentId);
            }}
          />
        );
      case ContentType.CHECKLIST:
        return (
          <CheckListComponent
            initialItems={itemData.items || []}
            onUpdate={(updatedItems) =>
              onContentUpdate(componentId, updatedItems, type)
            }
          />
        );
      case ContentType.TABLE:
        return (
          <TableComponent
            columns={itemData.columns || []}
            rows={itemData.rows || []}
            onUpdate={(updatedData) =>
              onContentUpdate(componentId, updatedData, type)
            }
          />
        );
      case ContentType.BULLETLIST:
        return (
          <ListComponent
            initialItems={itemData || []}
            type="bulleted"
            onSave={(updatedItems) =>
              onContentUpdate(componentId, updatedItems, type)
            }
          />
        );
      case ContentType.NUMERICLIST:
        return (
          <ListComponent
            initialItems={itemData || []}
            type="numbered"
            onSave={(updatedItems) =>
              onContentUpdate(componentId, updatedItems, type)
            }
          />
        );
      case ContentType.HEADING_LARGE:
      case ContentType.HEADING_MEDIUM:
      case ContentType.HEADING_SMALL:
        return (
          <HeadingComponent
            initialText={itemData?.text || "This is sample text"}
            initialAlignment={itemData?.alignment || "left"}
            initialUnderline={itemData?.underline || false}
            size={type} // Pass the size prop based on the ContentType
            onSave={(updatedData) =>
              onContentUpdate(componentId, updatedData, type)
            }
          />
        );
      case ContentType.QUOTE:
        return (
          <QuoteComponent
            initialText={
              itemData ||
              "The only thing we're allowed to believe is that we won't regret the choice we made."
            }
            onSave={(updatedData) =>
              onContentUpdate(componentId, updatedData, type)
            }
          />
        );
      case ContentType.DIVIDER:
        return (
          <Divider
            sx={{ width: "100%", height: "2px", backgroundColor: "#ddd" }}
          />
        );
      case ContentType.CODE:
        return (
          <CodeComponent
            initialCode={itemData?.code || ""}
            initialLanguage={itemData?.language || "JavaScript"}
            onSave={(updatedData) =>
              onContentUpdate(componentId, updatedData, type)
            }
          />
        );
      case ContentType.FILE:
        return (
          <FileComponent
            initialFileUrl={itemData?.url || ""} // Pass file URL if available
            onSave={(updatedData) =>
              onContentUpdate(componentId, updatedData, type)
            }
          />
        );
      case ContentType.FROALAEDITOR:
        return <FroalaEditor />;
      default:
        return <div>Non-Build Component content type: {type}</div>;
    }
  },
  (prevProps, nextProps) => {
    // Only re-render if the componentId or itemData has changed
    return (
      prevProps.componentId === nextProps.componentId &&
      isEqual(prevProps.itemData, nextProps.itemData)
    );
  }
);

export default ContentDisplay;
