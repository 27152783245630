import React, { useState } from "react";
import { Box, Divider, Stack } from "@mui/material";
import { DropDownButton, TotalCRM } from "../components";
import {
  BriefCaseIcon,
  FiltersIcon,
  ImportIcon,
  SortIcon,
  VisibleIcon,
} from "../../../../images";
import { DealTable } from "./components";
import { AddColumnCrmTable } from "../common";
import { SwitchSmall } from "../../../common";
import { ViewMode } from "../../Projects/types";
import { DealStageMap } from "./DealStageMap";

// Define the types for DropDownButtonProps
interface DropDownButtonProps {
  componentIcon: React.ReactNode;
  label: string;
  componentType: string;
}

// Define the props for the Deal component
interface DealProps {}

// Define the data structure for the component
const dropDownButtonData: DropDownButtonProps[] = [
  {
    componentIcon: <BriefCaseIcon w={17} />,
    label: "Deals",
    componentType: "allPeople",
  },
  { componentIcon: <VisibleIcon />, label: "Visible", componentType: "Sort" },
  { componentIcon: <ImportIcon />, label: "Import", componentType: "CSV" },
  { componentIcon: <SortIcon />, label: "Sort", componentType: "Sort" },
  { componentIcon: <FiltersIcon />, label: "Filter", componentType: "Filter" },
];

// The Deal component
const Deal: React.FC<DealProps> = () => {
  const [ViewMode, setViewMode] = useState<ViewMode>("square");

  return (
    <Box sx={{ padding: "10px 0 25px 0" }}>
      <Stack mb={1} p={1} direction={"column"} gap={1.2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={0.5}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
            <DropDownButton {...dropDownButtonData[0]} />
            <TotalCRM label="Total Deals" totalCount={9000} />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
            <DropDownButton {...dropDownButtonData[1]} />
            <DropDownButton {...dropDownButtonData[2]} />
          </Stack>
        </Stack>
        <Divider />
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={0.5}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
            <DropDownButton {...dropDownButtonData[3]} />
            <DropDownButton {...dropDownButtonData[4]} />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
            <SwitchSmall viewMode={ViewMode} setViewMode={setViewMode} />
          </Stack>
        </Stack>
      </Stack>
      {ViewMode === "square" ? (
        <Stack direction={"row"} alignItems={"flex-start"} gap={0}>
          <DealTable />
          <AddColumnCrmTable />
        </Stack>
      ) : (
        <DealStageMap />
      )}
    </Box>
  );
};

export default Deal;
