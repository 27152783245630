import React, { useState, useRef, useEffect } from "react";
import { InputBase, Typography, Box, Tooltip } from "@mui/material";

interface EditableTimeTextFieldProps {
  initialValue: string;
  onSave: (newValue: string) => void;
  variant: "hrs" | "mins";
}

const EditableTimeTextField: React.FC<EditableTimeTextFieldProps> = ({
  initialValue,
  onSave,
  variant,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [showError, setShowError] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const formatValue = (val: string) => {
    if (val === "") return "00";
    const numericValue = parseInt(val, 10);
    return numericValue < 10 ? `0${numericValue}` : `${numericValue}`;
  };

  const handleBlur = () => {
    let formattedValue = formatValue(value);
    const numericValue = parseInt(formattedValue, 10);
    if (
      (variant === "mins" && (numericValue < 0 || numericValue > 59)) ||
      (variant === "hrs" && (numericValue < 0 || numericValue > 99))
    ) {
      setShowError(true);
    } else {
      setShowError(false);
      setIsEditing(false);
      setValue(formattedValue);
      onSave(formattedValue);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      let formattedValue = formatValue(value);
      const numericValue = parseInt(formattedValue, 10);
      if (
        (variant === "mins" && (numericValue < 0 || numericValue > 59)) ||
        (variant === "hrs" && (numericValue < 0 || numericValue > 99))
      ) {
        setShowError(true);
      } else {
        setShowError(false);
        setIsEditing(false);
        setValue(formattedValue);
        onSave(formattedValue);
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (/^\d*$/.test(inputValue)) {
      setValue(inputValue);
      setShowError(false);
    }
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  return (
    <Tooltip
      title={
        showError
          ? variant === "mins"
            ? "Minutes must be between 0 and 59"
            : "Hours must be between 0 and 99"
          : ""
      }
      open={showError}
      placement="top"
      arrow
    >
      <Box display="flex" alignItems="center" sx={{ cursor: "pointer" }}>
        {isEditing ? (
          <InputBase
            inputRef={inputRef}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            sx={{
              height: "20px",
              padding: 0,
              width: "15px",
              maxWidth: "15px",
              borderBottom: "1px solid transparent",
              font: "normal normal 300 14px/20px Source Serif Pro",
              color:"black",
            }}
          />
        ) : (
          <Typography
            sx={{
              font: "normal normal 300 14px/20px Source Serif Pro",
              color:"black",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              borderBottom: "1px solid transparent",
              width: "15px",
            }}
            onClick={() => setIsEditing(true)}
          >
            {formatValue(value)}
          </Typography>
        )}
        <Typography
          onClick={() => setIsEditing(true)}
          sx={{
            font: "normal normal 300 14px/20px Source Serif Pro",
            marginLeft: "4px",
            borderBottom: "1px solid transparent",
            color:"black"
          }}
        >
          {variant}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default EditableTimeTextField;
