import {
  BoardIcon,
  ChecklistIcon,
  DecksIcon,
  DocumentIcon,
  // GanttIcon,
  GridsIcon,
  ListsIcon,
  WhiteboardIcon,
  WikiIcon,
} from "../../../../../images";

export enum AddToWorkspaceOptionsEnum {
  Board = "Board",
  // Gantt = "Gantt",
  Checklist = "Checklist",
  List = "List",
  Grid = "Grid",
  Document = "Document",
  Wiki = "Wiki",
  Whiteboard = "Whiteboard",
  Decks = "Decks",
}

export interface AddToWorkspaceOptionsInterface {
  id: number;
  icon: React.ElementType;
  value: AddToWorkspaceOptionsEnum;
}

export const AddToWorkspaceOptionsList: AddToWorkspaceOptionsInterface[] = [
  { id: 1, icon: BoardIcon, value: AddToWorkspaceOptionsEnum.Board },
  // { id: 2, icon: GanttIcon, value: AddToWorkspaceOptionsEnum.Gantt },
  { id: 3, icon: ChecklistIcon, value: AddToWorkspaceOptionsEnum.Checklist },
  { id: 4, icon: ListsIcon, value: AddToWorkspaceOptionsEnum.List },
  { id: 5, icon: GridsIcon, value: AddToWorkspaceOptionsEnum.Grid },
  { id: 6, icon: DocumentIcon, value: AddToWorkspaceOptionsEnum.Document },
  { id: 7, icon: WikiIcon, value: AddToWorkspaceOptionsEnum.Wiki },
  { id: 8, icon: WhiteboardIcon, value: AddToWorkspaceOptionsEnum.Whiteboard },
  { id: 9, icon: DecksIcon, value: AddToWorkspaceOptionsEnum.Decks },
];
