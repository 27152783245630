import React from "react";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { stringAvatar } from "../../../../../../Utils";

// Define the types for the component props
type HeaderType = "people" | "groups" | "company" | "deal";
type ShapeType = "sq" | "rd" | "cr";

type HeaderOverViewNameProps = {
  imageUrl?: string;
  headerType: HeaderType;
  name: string;
  roundedOrSquare?: ShapeType;
};

// Define a central data structure for the component data
// const predefinedData = {
//   headerType: "people" as HeaderType,
//   name: "John Doe",
//   imageUrl: "",
//   roundedOrSquare: "rd" as ShapeType,
// };

const HeaderOverViewName: React.FC<HeaderOverViewNameProps> = ({
  imageUrl = "",
  headerType,
  name,
  roundedOrSquare,
}) => {
  const avatarStyles = stringAvatar(name);
  const borderRadius =
    roundedOrSquare === "sq" ? 0 : roundedOrSquare === "rd" ? "20%" : "50%";

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={1.5}
      >
        <Avatar
          src={imageUrl}
          sx={{
            width: 50,
            height: 50,
            borderRadius: borderRadius,
            ...avatarStyles.sx,
          }}
        >
          {avatarStyles.children}
        </Avatar>
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          gap={0.5}
        >
          <Typography
            sx={{ font: "normal normal 900 20px/28px Source Serif Pro" }}
          >
            {name}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default HeaderOverViewName;
