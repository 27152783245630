import React from "react";
import { Stack, IconButton, Typography } from "@mui/material";
import BoardCardDisplay from "./BoardCardDisplay/BoardCardDisplay";
import { PlusIcon } from "../../../../../images";
import { adjustHexColor } from "../../../../../Utils";
import styles from "./BoardCard.module.css";

interface Item {
  id: number;
  text: string;
}

interface BoardCardProps {
  title: string;
  selectedColor: string;
  items: Item[];
  parentIndex: number;
}

const BoardCard: React.FC<BoardCardProps> = ({
  selectedColor,
  items,
  title,
}) => {
  const newColorForBg = adjustHexColor(selectedColor, 25);

  return (
    <Stack className={styles["board-card"]}>
      <Stack
        className={styles["board-card__body"]}
        sx={{
          bgcolor: newColorForBg,
        }}
        direction={"column"}
      >
        {items.map((item, index) => (
          <BoardCardDisplay
            selectedColor={selectedColor}
            titleMain={title}
            title={item.text}
          />
        ))}
      </Stack>

      {/* footer >> Add button here */}
      <Stack
        className={styles["board-card__footer"]}
        sx={{
          bgcolor: newColorForBg,
        }}
      >
        <IconButton
          disableTouchRipple
          sx={{
            ":hover": {
              bgcolor: adjustHexColor("#fff", 35),
            },
          }}
          className={styles["board-card__footer-button"]}
        >
          <PlusIcon w={10} />
          <Typography
            sx={{
              font: "normal normal normal 14px/20px Source Serif Pro",
              color: "black",
            }}
          >
            Add task
          </Typography>
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default BoardCard;
