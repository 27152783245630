// Define the structure for each connection item
export interface ConnectionItem {
    id: string;
    text: string;
    value: string;
    connectionType?: string;
}

// Define the structure for each share project item (same as ConnectionItem)
export interface ShareProjectItem {
    userId: string;
    text: string;
    value: string;
    permissions?: "view" | "edit",
    role?: "owner" | "admin" | "editor" | "viewer"
}


// Update the CreateCardData interface
export interface CreateCardData {
    mainDetails: {
        projectName: string;
        projectDetails: string;
        defaultProjectImage: boolean;
        defaultProjectImageIndex: number;
        uploadProjectImage: File | null;
    };
    connections: {
        connectionList: ConnectionItem[];
    };
    shareProject: {
        shareProjectList: ShareProjectItem[];
    };
}


// Define the initialCreateCardData with the new structure
export const initialCreateCardData: CreateCardData = {
    mainDetails: {
        projectName: "",
        projectDetails: "",
        defaultProjectImage: true,
        defaultProjectImageIndex: 0,
        uploadProjectImage: null,
    },
    connections: {
        connectionList: [],
    },
    shareProject: {
        shareProjectList: [],
    },
};
