import {
  CircleIcon,
  PageShapesIcon,
  SquareIcon,
  SquareRoundedIcon,
  StarToolIcon,
  TriangleIcon,
} from "../../../images";

export interface ShapeButtonListInterface {
  shapeName: string;
  shapeIcon: React.ElementType;
}

export const shapesButtonList: ShapeButtonListInterface[] = [
  { shapeName: "Square", shapeIcon: SquareIcon },
  { shapeName: "RoundedSquare", shapeIcon: SquareRoundedIcon },
  { shapeName: "Circle", shapeIcon: CircleIcon },
  { shapeName: "Triangle", shapeIcon: TriangleIcon },
  { shapeName: "Star", shapeIcon: StarToolIcon },
  { shapeName: "PageShapes", shapeIcon: PageShapesIcon },
];
