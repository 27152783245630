import {
  Typography,
  Popover,
  Box,
  Stack,
  Divider,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { AvatarNormal } from "../../../../../common";
import { UserProfileInterface } from "../EmailInCRMPreviewTypes";

interface MailGroupedPersonProps {
  senderPerson: UserProfileInterface;
  recipients: {
    to: UserProfileInterface[];
    cc?: UserProfileInterface[];
    bcc?: UserProfileInterface[];
  };
}

const InlineComponent: React.FC<UserProfileInterface> = ({
  email,
  name,
  avatarUrl,
}) => {
  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
      <Tooltip title={email} placement="top" arrow>
        <span>
          <AvatarNormal size={20} username={name} imgSrc={avatarUrl} />
        </span>
      </Tooltip>
      <Typography
        sx={{
          font: "normal normal normal 14px/20px Source Serif Pro",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {name}
      </Typography>
    </Stack>
  );
};

const MailGroupedPerson: React.FC<MailGroupedPersonProps> = ({
  senderPerson,
  recipients,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Calculate the total number of recipients excluding the sender
  const totalRecipients =
    (recipients.cc?.length || 0) + (recipients.bcc?.length || 0);

  return (
    <>
      <Typography
        sx={{
          color: "#0027FF",
          font: "normal normal 300 11px/15px Source Serif Pro",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        +{totalRecipients} others
      </Typography>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            border: "1px solid #E9EDF2",
            boxShadow: "0px 3px 6px #00000029",
          },
        }}
      >
        <Box
          sx={{
            p: 2,
            width: "175px",
            maxWidth: "175px",
            maxHeight: "300px",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              display: "none", // Hide scrollbar for Chrome, Safari, and Opera
            },
            scrollbarWidth: "none", // Hide scrollbar for Firefox
          }}
        >
          <Stack gap={"10px"}>
            <Typography
              sx={{ font: "normal normal normal 11px/15px Source Serif Pro" }}
            >
              From
            </Typography>
            <InlineComponent
              email={senderPerson?.email}
              name={senderPerson?.name}
              avatarUrl={senderPerson?.avatarUrl}
            />
          </Stack>

          {recipients.cc && (
            <>
              <Divider sx={{ marginY: "10px" }} />

              <Stack gap={"10px"}>
                <Typography
                  sx={{
                    font: "normal normal normal 11px/15px Source Serif Pro",
                  }}
                >
                  Cc
                </Typography>
                {recipients.cc?.map((item) => (
                  <InlineComponent key={item.email} {...item} />
                ))}
              </Stack>
            </>
          )}

          {recipients.bcc && (
            <>
              <Divider sx={{ marginY: "10px" }} />
              <Stack gap={"10px"}>
                <Typography
                  sx={{
                    font: "normal normal normal 11px/15px Source Serif Pro",
                  }}
                >
                  Bcc
                </Typography>
                {recipients.bcc.map((item) => (
                  <InlineComponent key={item.email} {...item} />
                ))}
              </Stack>
            </>
          )}
        </Box>
      </Popover>
    </>
  );
};

export default MailGroupedPerson;
