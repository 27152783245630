import { Drawer, Stack } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import CompanyIconAndName from "../../CompanyIconAndName";
import { MainActionButton } from "../../../Buttons";
import styles from "../../DrawerNavbar.module.css";
import { ButtonType, MainActionColor } from "../../../Buttons/AllButtonProps";
import {
  cs,
  FacebookBlackPng,
  MicrosoftTeamsLogoPng,
  SlackPng,
  XBlackPng,
} from "../../../../../images";
import {
  ChannelNameCard,
  SidebarMsgContainer,
  SidebarPinnedChatContainer,
} from "../../../../pages/Message/components";
import { MessageNameCard } from "../../../MessageNameCard";
import { SmallPinnedCard } from "../../../SmallPinnedCard";

type MessageDrawerProps = {
  drawerOpen: boolean;
  drawerCloser: () => void;
};

const DrawerContainer = styled("div")({
  position: "fixed",
  top: 0,
  height: "100vh",
  zIndex: 1100, // Ensure the drawer is behind the navbar
});
const MessageDrawer: React.FC<MessageDrawerProps> = ({
  drawerOpen,
  drawerCloser,
}) => {
  const msgCardData = {
    companyImgSrc: FacebookBlackPng,
    isUserActive: false,
    message:
      "This is a dummy message by the user, used for testing and displaying random text.",
    timeStamp: "3 hours",
    userName: "Jordan",
    userIconSrc: "https://randomuser.me/api/portraits/men/63.jpg",
  };
  const channelData = {
    channelName: "Name here",
    channelDetails:
      "These are the details of the channel that were there at These are the details of the channel that were there at..",
    channelIconSrc: XBlackPng,
    companyLogoSrc: SlackPng,
    timestamp: "3 hours",
  };
  const DUMMY_PINNED_USERS_CHATS = Array.from({ length: 10 }, (_, index) => ({
    isActive: index % 2 === 0,
    companyLogoSrc: MicrosoftTeamsLogoPng,
    userIconSrc: `https://randomuser.me/api/portraits/men/${60 + index}.jpg`,
    userName: `Random user ${index + 1}`,
  }));

  return (
    <>
      <DrawerContainer>
        <Drawer
          sx={{
            position: "absolute",
            top: 0,
            height: "100vh",
            width: "215px",
            // left: '-240px', // Start hidden behind the Navbar
            transition: drawerOpen
              ? "left 225ms ease-out"
              : "left 195ms ease-in",
            left: drawerOpen ? "30px" : "-215px",
            zIndex: 1100, // Ensure the drawer is behind the navbar
            "& .MuiPaper-root": {
              position: "absolute",
              top: 0,
              height: "100vh",
              width: "215px",
              left: 0,
              boxSizing: "border-box",
              zIndex: 1100, // Ensure the paper is also behind the navbar
            },
          }}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
        >
          <Stack
            bgcolor={"white"}
            direction="column"
            flex={1}
            className={`${styles.drawerNavbar__drawer}`}
          >
            {/* Company logo and name here */}
            <CompanyIconAndName arrowButtonClickHandler={drawerCloser} />
            <Stack className={styles.drawerNavbar__content}>
              {/* Buttons and other lists here */}
              <MainActionButton
                sx={{ marginY: "25px" }}
                onClick={() => console.log("Home button clicked")}
                colorVarient={MainActionColor.Orange}
                label="Message"
                types={ButtonType.Button}
              />
              <Stack sx={{ marginBottom: "30px" }}>
                <SidebarPinnedChatContainer>
                  {DUMMY_PINNED_USERS_CHATS.map((chatItem, index) => (
                    <SmallPinnedCard size={40} {...chatItem} key={index} />
                  ))}
                </SidebarPinnedChatContainer>
              </Stack>
              <Stack
                sx={{
                  overflowY: "scroll",
                  scrollbarWidth: "none",
                  gap: "10px",
                  paddingBottom: "50px",
                }}
              >
                <SidebarMsgContainer
                  label="Internal Chats"
                  viewAllButtonClick={() => null}
                >
                  {[...Array(10)].map((_, index) => (
                    <MessageNameCard key={index} {...msgCardData} />
                  ))}
                </SidebarMsgContainer>

                <SidebarMsgContainer
                  label="External Chats"
                  viewAllButtonClick={() => null}
                >
                  {[...Array(8)].map((_, index) => (
                    <MessageNameCard
                      key={index}
                      {...msgCardData}
                      showDetailsLine={index % 2 === 0 ? 2 : undefined}
                    />
                  ))}
                </SidebarMsgContainer>

                <SidebarMsgContainer
                  label="Channels"
                  viewAllButtonClick={() => null}
                >
                  <ChannelNameCard {...channelData} />
                  <ChannelNameCard {...channelData} />
                  <ChannelNameCard {...channelData} />
                  <ChannelNameCard {...channelData} />
                  <ChannelNameCard {...channelData} />
                  <ChannelNameCard {...channelData} />
                  <ChannelNameCard {...channelData} />
                  <ChannelNameCard {...channelData} />
                  <ChannelNameCard {...channelData} />
                  <ChannelNameCard {...channelData} />
                </SidebarMsgContainer>
              </Stack>
            </Stack>
          </Stack>
        </Drawer>
      </DrawerContainer>
    </>
  );
};

export default MessageDrawer;
