import {
    ClipboardPng,
    ConnectionsPng,
    SettingPng,
    globePersonPng,
    multiPersonPng,
    notificationPng,
    securityPng,
    singlePersonPng,
} from "../../../../../images";

interface SettingsOptions {
    id: number;
    name: string;
    iconSrcs: string;
    nUrl?: string;
}

export const SETTINGS_1_NAVBAR_ROUTES: SettingsOptions[] = [
    {
        id: 1,
        name: "General",
        iconSrcs: SettingPng,
        nUrl: "/Settings/general",
    },
    {
        id: 2,
        name: "Plan Details",
        iconSrcs: ClipboardPng,
        nUrl: "/Settings/plan-details",
    },
    {
        id: 3,
        name: "App Notifications",
        iconSrcs: notificationPng,
        nUrl: "/Settings/app-notifications",
    },
    {
        id: 3,
        name: "Your Profile",
        iconSrcs: singlePersonPng,
        nUrl: "/Settings/profile",
    },
];

export const SETTINGS_2_NAVBAR_ROUTES: SettingsOptions[] = [
    {
        id: 5,
        name: "Security",
        iconSrcs: securityPng,
        nUrl: "/Settings/app-security",
    },
    {
        id: 6,
        name: "Admin Console",
        iconSrcs: multiPersonPng,
        nUrl: "/Settings/admin-console",
    },
];

export const SETTINGS_3_NAVBAR_ROUTES: SettingsOptions[] = [
    {
        id: 5,
        name: "BEBA AI",
        iconSrcs: globePersonPng,
        nUrl: "/Settings/beba-ai",
    },
    {
        id: 6,
        name: "Intelligent Connections",
        iconSrcs: ConnectionsPng,
        nUrl: "/Settings/intelligent-connections",
    },
];
