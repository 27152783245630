import { IconButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LockIcon, UnlockIcon } from "../../../images";

interface LockAndUnlockedProps {
  iconSize?: number;
  initialValue?: "lock" | "unlock";
  onSelect: (value: string) => void;
}

const LockAndUnlocked: React.FC<LockAndUnlockedProps> = ({
  onSelect,
  iconSize = 15,
  initialValue = "lock",
}) => {
  const [value, setValue] = useState<"lock" | "unlock">(initialValue);

  const clickHandler = () => {
    if (value === "lock") {
      setValue("unlock");
    } else {
      setValue("lock");
    }
  };

  useEffect(() => {
    if (onSelect) {
      onSelect(value);
    }
  }, [value]);

  return (
    <IconButton sx={{ p: 0 }} disableRipple onClick={clickHandler}>
      {value === "lock" ? (
        <LockIcon w={iconSize} />
      ) : (
        <UnlockIcon w={iconSize} />
      )}
    </IconButton>
  );
};

export default LockAndUnlocked;
