import React from "react";
import { Tabs, Tab, SxProps } from "@mui/material";
import { styled } from "@mui/system";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  style?: React.CSSProperties;
}

export const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  "aria-controls": `simple-tabpanel-${index}`,
});

export const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  style,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        ...style,
        flexGrow: 1,
        overflowY: "scroll",
        scrollbarWidth: "none",
      }}
    >
      {value === index && children}
    </div>
  );
};

const StyledTabs = styled(Tabs)({
  boxSizing: "border-box",
  minHeight: 0,
  minWidth: 0,
  flexShrink: 0,
  "& .MuiTabs-flexContainer": {
    gap: "15px",
  },
});

export const StyledTab = styled(Tab)({
  flexDirection: "row",
  font: "normal normal 300 14px/18px Source Serif Pro",
  minWidth: 0,
  minHeight: 0,
  boxSizing: "border-box",
  color: "black !important",
  gap: "5px",
  padding: "0px 0px 5px 0px",
  textTransform: "capitalize",
});

// New component that handles tab state and change logic
interface TabsWithStateProps {
  children: React.ReactNode;
  ariaLabel: string;
  tabIndicatorColor?: string;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  sx?: SxProps;
}

export const TabsWithState: React.FC<TabsWithStateProps> = ({
  children,
  ariaLabel,
  tabIndicatorColor = "#775EE2",
  value,
  onChange,
  sx,
}) => {
  return (
    <StyledTabs
      value={value}
      onChange={onChange}
      aria-label={ariaLabel}
      TabIndicatorProps={{
        style: {
          backgroundColor: tabIndicatorColor,
          bottom: "1px",
          borderRadius: "5px",
        },
      }}
      sx={{ ...sx }}
    >
      {children}
    </StyledTabs>
  );
};
