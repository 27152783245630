import * as React from "react";
import {
  Box,
  FormControl,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import S from "./ViewPicker.module.css";
import { DocumentIcon, EyeIcon, SpreadIcon } from "../../../images";

interface Option {
  id: number;
  name: string;
  value: string;
  icon: React.ComponentType<{ w?: number }>;
}

interface ViewPickerProps {
  options?: Option[];
  initialValue?: string;
  width?: string;
  onChange?: (selectedOption: { id: number; value: string }) => void;
}

const OptionsList: Option[] = [
  { id: 1, name: "Full Screen", value: "Full", icon: SpreadIcon },
  { id: 2, name: "Document", value: "Document", icon: DocumentIcon },
];

const ViewPicker: React.FC<ViewPickerProps> = ({
  options = OptionsList,
  initialValue,
  width = "130px",
  onChange,
  ...props
}) => {
  const [selectedValue, setSelectedValue] = React.useState<string>(
    initialValue || options[0].value
  );

  const handleChange = React.useCallback(
    (event: SelectChangeEvent<string>) => {
      const value = event.target.value;
      const selectedOption = options.find((option) => option.value === value);
      if (selectedOption) {
        setSelectedValue(selectedOption.value);
        if (onChange) {
          onChange({ id: selectedOption.id, value: selectedOption.value });
        }
      }
    },
    [onChange, options]
  );

  const MenuProps = React.useMemo(
    () => ({
      PaperProps: {
        className: S["view-picker__menu"],
        style: { width: width },
      },
    }),
    [width]
  );

  return (
    <FormControl size="small" style={{ width }} {...props}>
      <Select
        displayEmpty
        IconComponent={() => null}
        value={selectedValue}
        onChange={handleChange}
        input={
          <OutlinedInput
            className={S["view-picker__input-box"]}
            classes={{
              notchedOutline: S["view-picker__notched-outline"],
              focused: S["view-picker__focused"],
            }}
            startAdornment={
              <InputAdornment position="start">
                <EyeIcon w={14} />
              </InputAdornment>
            }
          />
        }
        renderValue={(selected) => (
          <Box
            sx={{
              paddingTop: "2px",
              font: "normal normal 300 14px/20px Source Serif Pro",
            }}
          >
            View: {selected}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem
            className={S["view-picker__menu-item"]}
            key={option.id}
            value={option.value}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
              {React.createElement(option.icon, { w: 15 })}
              <ListItemText
                primary={option.name}
                primaryTypographyProps={{
                  className: S["view-picker__list-item-text"],
                }}
              />
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ViewPicker;
