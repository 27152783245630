import {
  FacebookBlackPng,
  InstagramBlackPng,
  LinkedInBlackPng,
  WebexPng,
  XBlackPng,
} from "../../../images";

export interface CompanyLongDropdownOptionInterface {
  id: Number;
  companyIconSrc: string;
  companyName: string;
  comapnyMail: string;
}

export const DUMMY_CompanyLongDropdownOption: CompanyLongDropdownOptionInterface[] =
  [
    {
      id: 1,
      companyName: "Webex",
      comapnyMail: "webexsupport@webex.com",
      companyIconSrc: WebexPng,
    },
    {
      id: 2,
      companyName: "Facebook",
      comapnyMail: "facebook@webex.com",
      companyIconSrc: FacebookBlackPng,
    },
    {
      id: 3,
      companyName: "instagram",
      comapnyMail: "instagram@webex.com",
      companyIconSrc: InstagramBlackPng,
    },
    {
      id: 4,
      companyName: "comapny4",
      comapnyMail: "company@webex.com",
      companyIconSrc: XBlackPng,
    },
    {
      id: 5,
      companyName: "Linkedin",
      comapnyMail: "linkedin@webex.com",
      companyIconSrc: LinkedInBlackPng,
    },
    {
      id: 11,
      companyName: "Webex",
      comapnyMail: "webexsupport@webex.com",
      companyIconSrc: WebexPng,
    },
    {
      id: 21,
      companyName: "Facebook",
      comapnyMail: "facebook@webex.com",
      companyIconSrc: FacebookBlackPng,
    },
    {
      id: 31,
      companyName: "instagram",
      comapnyMail: "instagram@webex.com",
      companyIconSrc: InstagramBlackPng,
    },
    {
      id: 41,
      companyName: "comapny4",
      comapnyMail: "company@webex.com",
      companyIconSrc: XBlackPng,
    },
    {
      id: 51,
      companyName: "Linkedin",
      comapnyMail: "linkedin@webex.com",
      companyIconSrc: LinkedInBlackPng,
    },
  ];
