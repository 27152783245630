import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import FroalaEditorComponent from "react-froala-wysiwyg";
import "./Froala.css";
import { FC } from "react";

// Define props interface with optional content and setContent
interface FroalaEditorProps {
  content?: string;
  setContent?: (content: string) => void;
}

const FroalaEditor: FC<FroalaEditorProps> = ({
  content = "",
  setContent = () => {},
}) => {
  // Froala editor configuration
  const config = {
    placeholderText: "Edit Your Document",
    heightMin: 100,
    key: "8JF3bB2A5A4C3A1E2E2zPAENHMf1JPQRFZBTBf1WWEPYDbB3H3E2A16A19B7C5C6A2C3==", // License key to remove watermark
    attribution: false, // This will ensure the Froala watermark is removed
  };

  return (
    <div>
      <FroalaEditorComponent
        tag="textarea"
        model={content}
        onModelChange={setContent}
        config={config}
      />
    </div>
  );
};

export default FroalaEditor;
