import React, { MouseEvent, useState } from "react";
import { IconButton, Popover, Stack } from "@mui/material";
import { ShapesIcon } from "../../../images";
import { ShapeButtonListInterface, shapesButtonList } from "./ShapesButtonType";
import { iconButtonStyles } from "../../../Utils";

interface ShapesButtonProps {
  selectedTool: string;
  handleSelectTool: (toolName: string) => void;
  options?: ShapeButtonListInterface[];
  addShape?: (shapeName:string) => void;
}

const ShapesButton: React.FC<ShapesButtonProps> = ({
  selectedTool,
  handleSelectTool,
  options = shapesButtonList,
  addShape,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleShapeSelect = (shapeName: string) => {
    if (addShape) {
      addShape(shapeName);
    }
    handleSelectTool(shapeName);
    handlePopoverClose();
  };

  return (
    <>
      <IconButton
        sx={iconButtonStyles(selectedTool === "Shapes")}
        disableRipple
        onClick={handlePopoverOpen}
      >
        <ShapesIcon w={25} />
      </IconButton>

      <Popover
        open={open}
        container={document && document?.getElementById("full-ScreenMode")}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ marginLeft: "25px" }}
        PaperProps={{
          style: { boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)" },
        }}
      >
        <Stack sx={{ bgcolor: "white", padding: "15px 10px", gap: "15px" }}>
          {options.map((item, index) => (
            <IconButton
              key={index}
              sx={iconButtonStyles(selectedTool === item.shapeName)}
              disableRipple
              onClick={() => handleShapeSelect(item.shapeName)}
            >
              {React.createElement(item.shapeIcon, { w: 20 })}
            </IconButton>
          ))}
        </Stack>
      </Popover>
    </>
  );
};

export default ShapesButton;
