import { IconButton, Tooltip } from "@mui/material";
import React, { useRef } from "react";
import { AttechIcon } from "../../../images";

interface AttechFileProps {
  onSelect: (file: File) => void;
}

const AttechFile: React.FC<AttechFileProps> = ({ onSelect }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      onSelect(event.target.files[0]);
    }
  };

  return (
    <>
      <Tooltip title={"Attect file"} placement="top" arrow>
        <IconButton
          sx={{ p: 0, borderRadius: "0px" }}
          disableRipple
          onClick={handleButtonClick}
        >
          <AttechIcon w={15} />
        </IconButton>
      </Tooltip>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </>
  );
};

export default AttechFile;
