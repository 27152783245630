import React from "react";
import style from "./AddView.module.css";
import { Box, Button, Popover, Stack, Typography } from "@mui/material";
import { IconKey, RenderWorkspaceIcon } from "../../../Utils";
import { ComponentKey } from "../../pages/Workspace/OverView/Overview";

type AddViewProps = {
  componentType?: string;
  handleAddView?: (view: ComponentKey) => void;
};

const AddView = ({ componentType, handleAddView }: AddViewProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Define the available views
  const availableViews: IconKey[] = [
    "Tree",
    "Calendar",
    "Gantt",
    "Board",
    "List",
    "Checklist",
  ];

  // Filter out the componentType from the available views
  const filteredViews = availableViews.filter((view) => view !== componentType);

  return (
    <Box>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        className={style.add__view__title}
      >
        Add view
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack spacing={0} sx={{ padding: 2 }}>
          {filteredViews.map((view) => (
            <Box
              key={view}
              onClick={() => {
                view !== "Tree" &&
                  handleAddView &&
                  handleAddView(view as ComponentKey);
              }}
              display="flex"
              alignItems="center"
              sx={{
                padding: "8px 4px",
                borderRadius: "4px",
                transition: "background-color 0.3s",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#f0f0f0", // Light grey hover effect
                },
              }}
            >
              <RenderWorkspaceIcon component={view} w={15} color="black" />
              <Typography className={style.addView__option} sx={{ ml: 1 }}>
                {view}{" "}
                {view === "Tree" && (
                  <span className={style.add__view__titl_app}>soon</span>
                )}
              </Typography>
            </Box>
          ))}
        </Stack>
      </Popover>
    </Box>
  );
};

export default AddView;
