import { Stack, Typography } from "@mui/material";
import React from "react";

interface UserProfileProps {
  fullName: string;
  email: string;
}

const UserProfile: React.FC<UserProfileProps> = ({ fullName, email }) => {
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      sx={{ gap: "15px", paddingTop: "15px" }}
    >
      <Typography
        sx={{
          font: "normal normal normal 15px/17px Averia Serif Libre",
          pl: "15px",
          ":hover": {
            cursor: "default",
          },
        }}
      >
        {email}
      </Typography>
      <Typography
        sx={{
          font: "normal normal normal 15px/17px Averia Serif Libre",
          pl: "15px",
          ":hover": {
            cursor: "default",
          },
        }}
      >
        {fullName}
      </Typography>
    </Stack>
  );
};

export default UserProfile;
