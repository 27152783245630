import React, { useRef } from "react";
import { Button, ButtonProps } from "@mui/material";
import style from "./IconButtonLong.module.css";
import { UploadIcon } from "../../../../images";
import { ButtonType } from "../AllButtonProps";

interface IconButtonLongProps extends ButtonProps {
  label?: string;
  buttonType?: ButtonType;
  onFileSelect: (file: File) => void;
}

const IconButtonLong: React.FC<IconButtonLongProps> = ({
  buttonType = ButtonType.Button,
  label = "Upload Image",
  onFileSelect,
  ...props
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && onFileSelect) {
      onFileSelect(file);
    }
  };

  return (
    <>
      <Button
        size="small"
        type={buttonType}
        className={style["iconButtonLong"]}
        variant="outlined"
        disableElevation
        disableRipple
        disableTouchRipple
        startIcon={<UploadIcon w={14} color="black" />}
        onClick={handleButtonClick}
        sx={{
          "& .MuiButton-startIcon": {
            // Custom CSS for start icon
            marginRight: "0px", // Adjust the margin here
          },
        }}
        {...props}
      >
        {label}
      </Button>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </>
  );
};

export default IconButtonLong;
