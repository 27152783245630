import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { GreenArrowButton, MediumInputIconBox } from "../../../../../../common";
import {
  BearPng,
} from "../../../../../../../images";
import S from "./MainDetails.module.css";
import { CreateCardData } from "../../../MainTop/types";

interface MainDetailsProps {
  createCardData: CreateCardData;
  updateMainDetails: (value: Partial<CreateCardData["mainDetails"]>) => void;
}

export const API_IMAGE_DATA = [
  {
    name: "BoatonLake.jpg",
    url: "https://bearishos20.blob.core.windows.net/bearishospublic/workspace-cover-thumb/BoatonLake.jpg",
    url2: "https://bearishos20.blob.core.windows.net/bearishospublic/workspace-cover/BoatonLake.jpg",
  },
  {
    name: "HimsaDessert.jpg",
    url: "https://bearishos20.blob.core.windows.net/bearishospublic/workspace-cover-thumb/HimsaDessert.jpg",
    url2: "https://bearishos20.blob.core.windows.net/bearishospublic/workspace-cover/HimsaDessert.jpg",
  },
  {
    name: "LushForrest.jpg",
    url: "https://bearishos20.blob.core.windows.net/bearishospublic/workspace-cover-thumb/LushForrest.jpg",
    url2: "https://bearishos20.blob.core.windows.net/bearishospublic/workspace-cover/LushForrest.jpg",
  },
  {
    name: "Moon.jpg",
    url: "https://bearishos20.blob.core.windows.net/bearishospublic/workspace-cover-thumb/Moon.jpg",
    url2: "https://bearishos20.blob.core.windows.net/bearishospublic/workspace-cover/Moon.jpg",
  },
  {
    name: "MotivationOne.jpg",
    url: "https://bearishos20.blob.core.windows.net/bearishospublic/workspace-cover-thumb/MotivationOne.jpg",
    url2: "https://bearishos20.blob.core.windows.net/bearishospublic/workspace-cover/MotivationOne.jpg",
  },
  {
    name: "Nighsky.jpg",
    url: "https://bearishos20.blob.core.windows.net/bearishospublic/workspace-cover-thumb/Nighsky.jpg",
    url2: "https://bearishos20.blob.core.windows.net/bearishospublic/workspace-cover/Nighsky.jpg",
  },
  {
    name: "PencilBlue.jpg",
    url: "https://bearishos20.blob.core.windows.net/bearishospublic/workspace-cover-thumb/PencilBlue.jpg",
    url2: "https://bearishos20.blob.core.windows.net/bearishospublic/workspace-cover/PencilBlue.jpg",
  },
  {
    name: "PolarBear.jpg",
    url: "https://bearishos20.blob.core.windows.net/bearishospublic/workspace-cover-thumb/PolarBear.jpg",
    url2: "https://bearishos20.blob.core.windows.net/bearishospublic/workspace-cover/PolarBear.jpg",
  },
  {
    name: "PortreePoint.jpg",
    url: "https://bearishos20.blob.core.windows.net/bearishospublic/workspace-cover-thumb/PortreePoint.jpg",
    url2: "https://bearishos20.blob.core.windows.net/bearishospublic/workspace-cover/PortreePoint.jpg",
  },
  {
    name: "SunsetCover.jpg",
    url: "https://bearishos20.blob.core.windows.net/bearishospublic/workspace-cover-thumb/SunsetCover.jpg",
    url2: "https://bearishos20.blob.core.windows.net/bearishospublic/workspace-cover/SunsetCover.jpg",
  },
  {
    name: "TwoPencils.jpg",
    url: "https://bearishos20.blob.core.windows.net/bearishospublic/workspace-cover-thumb/TwoPencils.jpg",
    url2: "https://bearishos20.blob.core.windows.net/bearishospublic/workspace-cover/TwoPencils.jpg",
  },
  {
    name: "grizzlybear.jpg",
    url: "https://bearishos20.blob.core.windows.net/bearishospublic/workspace-cover-thumb/grizzlybear.jpg",
    url2: "https://bearishos20.blob.core.windows.net/bearishospublic/workspace-cover/grizzlybear.jpg",
  },
];

const MainDetails: React.FC<MainDetailsProps> = ({
  createCardData,
  updateMainDetails,
}) => {
  const [imgDisplay, setImgDisplay] = useState<"one" | "two">("one"); // Local state for image display

  // Ref for the file input element to programmatically trigger it
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  // Function to trigger the file input click
  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  // Function to handle the file input change event
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // Update mainDetails with uploaded image info
      updateMainDetails({
        defaultWorkspaceImage: false,
        defaultWorkspaceImageIndex: -1,
        uploadWorkspaceImage: file,
      });
    }
  };

  // Function to handle image box click and select the image
  const handleBoxClick = (index: string) => {
    // Update mainDetails with selected image index
    updateMainDetails({
      defaultWorkspaceImageIndex: -1,
      defaultWorkspaceImage: true,
      uploadWorkspaceImage: index,
    });
  };

  // Function to toggle between 'one' and 'two' states for imgDisplay
  const handleArrowClick = () => {
    // Toggle image display state locally
    setImgDisplay((prev) => (prev === "one" ? "two" : "one"));
  };

  return (
    <Stack className={S["workspace-main-details"]}>
      {/* Input boxes */}
      <Stack gap="10px">
        <MediumInputIconBox
          sx={{ width: "100%", height: "30px" }}
          label="Name"
          placeholder="Give your Workspace a Unique Name"
          value={createCardData.mainDetails.workspaceName}
          onChange={(e) => {
            const value = typeof e === "string" ? e : e.target.value;
            updateMainDetails({ workspaceName: value });
          }}
        />
        <MediumInputIconBox
          sx={{ width: "100%", height: "100px" }}
          multiline
          label="Details"
          placeholder="Add some details to better describe your Workspace and keep things flowing."
          value={createCardData.mainDetails.workspaceDetails}
          onChange={(e) => {
            const value = typeof e === "string" ? e : e.target.value;

            updateMainDetails({ workspaceDetails: value });
          }}
        />
      </Stack>
      <Typography className={S["workspace-main-details__upload-text"]}>
        Upload or Choose an Image
      </Typography>

      {/* Image list */}
      <Stack className={S["workspace-main-details__image-container"]}>
        <span style={{ position: "relative" }}>
          <GreenArrowButton
            sx={{
              position: "absolute",
              right: "-20px",
              top: "34px",
              transform:
                imgDisplay === "one" ? "rotate(0deg)" : "rotate(180deg)",
            }}
            onClick={handleArrowClick}
          />
        </span>
        {imgDisplay === "one" ? (
          <Stack className={S["workspace-main-details__image-list"]}>
            {API_IMAGE_DATA.map((item, index) => (
              <Box
                component="div"
                key={index}
                className={`${S["workspace-main-details__box"]} ${
                  createCardData.mainDetails.uploadWorkspaceImage === item.url2
                    ? S["workspace-main-details__box--selected"]
                    : ""
                }`}
                onClick={() => handleBoxClick(item.url2)}
              >
                <Box
                  component="img"
                  src={item.url}
                  className={S["workspace-main-details__image"]}
                />
              </Box>
            ))}
          </Stack>
        ) : (
          <>
            <div
              onClick={handleButtonClick}
              style={{ height: "100%", cursor: "pointer" }}
            >
              <Stack
                className={S["workspace-main-details__drag-drop-container"]}
              >
                <Box
                  component={"img"}
                  src={BearPng}
                  height={"75px"}
                  width={"65px"}
                />
                <Stack sx={{ width: "190px" }}>
                  <Typography
                    className={S["workspace-main-details__drag-drop-text"]}
                  >
                    Drag and Drop an Image here,
                  </Typography>
                  <Typography
                    className={S["workspace-main-details__drag-drop-text"]}
                  >
                    Choose one from the list or BEBA
                  </Typography>
                  <Typography
                    className={S["workspace-main-details__drag-drop-text"]}
                  >
                    AI will create one for you
                  </Typography>
                </Stack>
              </Stack>
            </div>

            {/* Hidden file input for image upload */}
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default MainDetails;
