// Define the union type for view modes
export type ViewMode = "square" | "stacked";

// Main Interface
export interface CreateWorkspace {
  name: string;
  description: Description;
  ownerId?: string | null;
  visibility?: string;
  appearance: Appearance;
  members: Member[];
  content: ContentItem[];
  customFields: CustomField[];
  tags?: string[];
  templates?: Template[];
  appliedTemplate?: AppliedTemplate;
  integrations?: Integration[];
  activity?: Activity[];
  columns?: number;
  connectedItems?: ConnectedItem[];
  itemType?: string;
  reminders?: Reminder[];
  videoReports?: VideoReport[];
  codeBlocks?: CodeBlock[];
  files?: File[];
  mediaElements?: MediaElement[];
  internetBookmarks?: InternetBookmark[];
  tableOfContents?: TableOfContents;
  equations?: Equation[];
  buttons?: Button[];
  syncElements?: SyncElement[];
  headingToggles?: HeadingToggle[];
  isDeleted?: boolean;
}
// Sub Interfaces
export interface Description {
  content: string;
  formatting?: Formatting[];
}
export interface Formatting {
  type: string;
  start: number;
  end: number;
  data: any;
}
interface Appearance {
  coverImage?: File | string; // Allows either a File object or a URL string
  icon: string;
  theme?: Theme;
}

interface Theme {
  primaryColor: string;
  secondaryColor: string;
  textColor: string;
  font: string;
}
interface Member {
  // Define properties for members if any
}

export enum ContentType {
  TEXT = "text",
  HEADING_LARGE = "headinglarge",
  HEADING_MEDIUM = "headingmedium",
  HEADING_SMALL = "headingsmall",
  IMAGE = "image",
  VIDEO = "video",
  FILE = "file",
  AUDIO = "audio",
  EMBED = "embed",
  CODE = "code",
  CALLOUT = "callout",
  TABLE = "table",
  WHITEBOARD = "whiteboard",
  CALENDAR = "calendar",
  BOARD = "board",
  GANTT = "gantt",
  TIMELINE = "timeline",
  LIST = "list",
  CHECKLIST = "checklist",
  TREE = "tree",
  DIVIDER = "divider",
  QUOTE = "textquote",
  NUMERICLIST = "textnumberedlist",
  BULLETLIST = "textbulletedlist",
  FROALAEDITOR = "froalaeditor",
}

export interface ContentItem {
  type: ContentType;
  data: any;
  isDeleted: boolean;
  _id?: string;
}
interface CustomField {
  name: string;
  type: string;
  options: any;
  value: string;
}
interface Template {
  // Define properties for templates if any
}
interface AppliedTemplate {
  name: string;
  description: string;
  content: string;
}
interface Integration {
  type: string;
  config: {
    key: string;
  };
  credentials: Credentials;
}
interface Credentials {
  username: string;
  password: string;
}
interface Activity {
  // Define properties for activity if any
}
interface ConnectedItem {
  componentName: string;
  componentId: string;
  _id: string;
}
interface Reminder {
  // Define properties for reminders if any
}
interface VideoReport {
  // Define properties for videoReports if any
}
interface CodeBlock {
  language: string;
  code: string;
  description: string;
}
interface File {
  // Define properties for files if any
}
interface MediaElement {
  type: string;
  url: string;
  caption: string;
}
interface InternetBookmark {
  url: string;
  title: string;
  description: string;
  tags: string[];
}
interface TableOfContents {
  enabled: boolean;
  items: TableOfContentsItem[];
}
interface TableOfContentsItem {
  title: string;
  level: number;
}
interface Equation {
  latex: string;
  display: boolean;
  description: string;
}
interface Button {
  label: string;
  action: string;
}
interface SyncElement {
  // Define properties for syncElements if any
}
interface HeadingToggle {
  level: number;
  content: string;
  isCollapsed: boolean;
  defaultState: number;
}

export type WorkspaceList = {
  workspaceList: WorkspaceData[];
  api_call_timeStamp: string;
  is_api_called: boolean;
  WorkspaceListError: null | string;
};

export type WorkspaceSharedList = {
  workspaceSharedList: WorkspaceData[];
  WorkspaceSharedListError: null | string;
};

export interface WorkspaceData {
  name: string;
  description: Description;
  ownerId?: string | null;
  visibility?: string;
  appearance: Appearance;
  members: Member[];
  content: ContentItem[];
  customFields: CustomField[];
  tags?: string[];
  templates?: Template[];
  appliedTemplate?: AppliedTemplate;
  integrations?: Integration[];
  activity?: Activity[];
  columns?: number;
  connectedItems?: ConnectedItem[];
  itemType?: string;
  reminders?: Reminder[];
  videoReports?: VideoReport[];
  codeBlocks?: CodeBlock[];
  files?: File[];
  mediaElements?: MediaElement[];
  internetBookmarks?: InternetBookmark[];
  tableOfContents?: TableOfContents;
  equations?: Equation[];
  buttons?: Button[];
  syncElements?: SyncElement[];
  headingToggles?: HeadingToggle[];
  isDeleted?: boolean;
  createdAt: string;
  _id: string;
}

export interface Get_delete_By____Id {
  _id: string;
}

export interface UpdateWorkspace {
  name: string;
  description?: Description;
  visibility?: string;
  appearance?: Appearance;
}

export interface AddWorkspaceContent {
  content: ContentItem[];
}

export interface UpdateWorkSpaceContent {
  type: string;
  data: string;
}

export interface AddWorkspaceMember {
  memberId: string;
  memberRole: string;
  permissions: string;
}

export interface UpdateWorkspaceMember {
  role: string;
  permissions: string;
  workspaceId: string;
  memberId: string;
}

export interface Add_or_update__WorkspaceCustomField {
  name: string;
  type: string;
  options: any;
  value: string;
  workspaceId: string;
  fieldId?: string;
}

// Add Workspace Tag
export interface AddWorkspaceTag {
  tags: string[];
  workspaceId: string;
  tagId?: string;
}

// Delete Workspace Tag
export interface DeleteWorkspaceTag {
  tags: string;
  workspaceId: string;
}

// Add Workspace Template
export interface AddWorkspaceTemplate {
  name: string;
  description: string;
  content: TemplateContent;
}

interface TemplateContent {
  type: string;
  data: string;
}

// Add Workspace Integration
export interface AddWorkspaceIntegration {
  type: string;
  config: IntegrationConfig;
  credentials: IntegrationCredentials;
  workspaceId: string;
}

interface IntegrationConfig {
  endpoint: string;
  port: number;
  path: string;
}

interface IntegrationCredentials {
  key: string;
  value: string;
}

// Update Workspace Integration
export interface Update__Delete__WorkspaceIntegration {
  config?: IntegrationConfig;
  credentials?: IntegrationCredentialsUpdate;
  workspaceId: string;
  integrationId: string;
}

interface IntegrationCredentialsUpdate {
  username: string;
  password: string;
}

// Add Workspace Activity
export interface AddWorkspaceActivity {
  type: string;
  userId: string;
  timestamp: string;
  details: ActivityDetails;
  workspaceId: string;
}

interface ActivityDetails {
  ipAddress: string;
  device: string;
}

// Get Workspace All Activity (params)
export interface GetWorkspaceAllActivityParams {
  page: number;
  limit: number;
  startDate: string;
  endDate: string;
  type: string;
  userId: string;
  workspaceId: string;
}

// Update Workspace Column
export interface UpdateWorkspaceColumn {
  column: number;
  workspaceId: string;
  columnId?: string;
}

// Add Workspace Connection
export interface AddWorkspaceConnection {
  componentId: string;
  workspaceId: string;
}

// Add Workspace Reminder
export interface AddWorkspaceReminder {
  date: string;
  message: string;
  workspaceId: string;
  assignees: string[];
}

// Add Workspace Video Report
export interface AddWorkspaceVideoReport {
  callId: string;
  reportUrl: string;
  summary: string;
  participants: string[];
  workspaceId: string;
}

// Add Workspace File
export interface AddWorkspaceFile {
  name: string;
  url: string;
  type: string;
  workspaceId: string;
  metadata: FileMetadata;
}

interface FileMetadata {
  size: string;
  creationDate: string;
  lastModified: string;
  duration: string;
  resolution: string;
}

// Add Workspace Media
export interface AddWorkspaceMedia {
  type: string;
  url: string;
  caption: string;
  workspaceId: string;
}

// Add Workspace Bookmark
export interface AddWorkspaceBookmark {
  url: string;
  title: string;
  description: string;
  tags: string[];
  workspaceId: string;
}

// Add Workspace Table of Contents
export interface AddWorkspaceTableOfContents {
  enabled: boolean;
  items: TableOfContentsItem[];
  workspaceId: string;
}

interface TableOfContentsItem {
  title: string;
  level: number;
  workspaceId: string;
}

// Add Workspace Equation
export interface AddWorkspaceEquation {
  latex: string;
  display: boolean;
  description: string;
  workspaceId: string;
}

// Add Workspace Button
export interface AddWorkspaceButton {
  label: string;
  action: string;
  style: string;
  conditions: ButtonConditions;
  workspaceId: string;
}

interface ButtonConditions {
  isEditable: boolean;
}

// Add Workspace Sync Element
export interface AddWorkspaceSyncElement {
  sourceId: string;
  targetId: string;
  type: string;
  syncConfig: SyncConfig;
  workspaceId: string;
}

interface SyncConfig {
  frequency: string;
  time: string;
  timezone: string;
}

// Add Workspace Heading Toggle
export interface AddWorkspaceHeadingToggle {
  level: number;
  content: string;
  isCollapsed: boolean;
  defaultState: number;
  workspaceId: string;
}

// Add Workspace Code Block
export interface AddWorkspaceCodeBlock {
  language: string;
  code: string;
  description: string;
  workspaceId: string;
}

// Delete Workspace In Bulk
export interface DeleteWorkspaceInBulk {
  WorkSpaceIds: string[];
}

export interface InsideWorkspaceList { }

export interface InsideWorkspaceItem { }

interface User {
  firstName: string;
  lastName: string;
  email: string;
  department?: string;
  teamName?: string;
}

interface Members {
  userId: User;
}

export interface WorkspaceMember {
  _id: string;
  visibility: "public" | "private"; // Adjust based on your actual data
  members: Members[];
}

export type UpdatableFields = "lockPage";

/* Grid Section */

interface GridCollaborator {
  userId: string;
  permissions: "view" | "edit";
}

interface GridVersion {
  number: string;
  description: string;
  createdBy: string;
  createdAt: string; // Date in ISO string format
  sheets: string[];
}

interface CustomFunction {
  functionName: string;
  description: string;
}

interface DataSourceConfig {
  url: string;
}

interface DataSource {
  name: string;
  type: "api" | "database" | "file";
  config: DataSourceConfig;
  refreshInterval: number; // in seconds
}

interface GridSettings {
  exampleSetting: string;
}

export interface AddGridPayload {
  name: string;
  description: string;
  sheets: string[];
  currentSheet: string;
  collaborators: GridCollaborator[];
  versions: GridVersion[];
  currentVersion: string;
  settings: GridSettings;
  customFunctions: CustomFunction[];
  dataSources: DataSource[];
  tags: string[];
}

export interface GetAllGridsParams {
  limit: number;
  page: number;
  sort: "asc" | "desc";
}

export interface UpdateGridPayload {
  name: string;
  description: string;
  sheets: string[];
  currentSheet: string;
  currentVersion: string;
  settings: GridSettings;
  customFunctions: CustomFunction[];
  dataSources: DataSource[];
  tags: string[];
}

interface CellRange {
  row: number;
  col: number;
}

interface MergedCell {
  start: CellRange;
  end: CellRange;
}

interface ConditionalFormat {
  conditionType: string;
  style: string;
}

interface DataValidation {
  validationType: string;
  criteria: string;
}

interface Chart {
  type: string;
  data: string;
}

interface PivotTable {
  source: string;
  config: string;
}

interface ProtectedRange {
  range: {
    start: CellRange;
    end: CellRange;
  };
  allowedUsers: string[];
}

export interface AddSheetPayload {
  gridId: string;
  name: string;
  cells: string[];
  frozenRows: number;
  frozenColumns: number;
  hiddenRows: number[];
  hiddenColumns: number[];
  columnWidths: Record<number, number>;
  rowHeights: Record<number, number>;
  mergedCells: MergedCell[];
  conditionalFormats: ConditionalFormat[];
  dataValidations: DataValidation[];
  charts: Chart[];
  pivotTables: PivotTable[];
  protectedRanges: ProtectedRange[];
}

export interface UpdateSheetPayload {
  gridId: string;
  sheetId: string;
  name: string;
  cells: string[];
  frozenRows: number;
  frozenColumns: number;
  hiddenRows: number[];
  hiddenColumns: number[];
  columnWidths: Record<number, number>;
  rowHeights: Record<number, number>;
  mergedCells: MergedCell[];
  conditionalFormats: ConditionalFormat[];
  dataValidations: DataValidation[];
  charts: Chart[];
  pivotTables: PivotTable[];
  protectedRanges: ProtectedRange[];
}

export interface ReorderSheetsPayload {
  sheets: string[];
}

interface CellFormat {
  type: "text" | "number" | "currency" | "percentage" | "date" | "other";
  currency?: string; // Only applicable if type is "currency"
}

interface CellValidation {
  rule: string;
}

export interface UpdateCellPayload {
  value: string;
  formattedValue: string;
  formula: string;
  format: CellFormat;
  dataType: "text" | "number" | "boolean" | "date";
  validation: CellValidation;
  locked: boolean;
  lastEditedBy: string;
  lastEditedAt: string; // Date in ISO string format
}

export interface AddComponentPayload {
  name: string;
  componentType: string;
  description: string;
  isFreeform?:boolean;
}
export interface GetAllComponentsParams {
  workspaceId: string;
  componentType: string;
  page?: number;
  limit?: number;
  sort?: string;
}
export interface GetOneComponentParams {
  workspaceId: string;
  componentId: string;
}
export interface UpdateComponentPayload {
  name: string;
  description: string;
}

export interface UpdateComponentParams {
  workspaceId: string;
  componentId: string;
  data: UpdateComponentPayload;
}
export interface DeleteOneComponentParams {
  workspaceId: string;
  componentId: string;
}
export interface DeleteBulkComponentsPayload {
  componentIds: string[];
}

// Define the structure of a single component
// workspaceTypes.ts

// Define the structure of a single component item
export interface ComponentItem {
  id: string;
  name?: string;
  title?: string;
  description: string;
  workspaceId: string; // Add this line
  _id?: string; // Add this line
  componentType?: string; // Add this line
  createdBy?: any;
  createdAt?: any;
  updatedAt?: any;
  // Add other properties as needed
}

// Define the structure for the list of components
export interface ComponentList {
  items: ComponentItem[]; // Array of component items
  // Add other properties if necessary
}

// Define the structure of components data for each workspace
export interface WorkspaceComponentsData {
  [workspaceId: string]: {
    loading: boolean;
    data: ComponentList;
  };
}

export interface CreateTaskPayload {
  name: string;
  details: string;
  componentId: string;
  assignedUserIds: string[];
  priority: {
    id: number;
    value: string;
    defaultColorHex: string;
    customColorHex: string;
  }; // Updated type
  dueDate: string; // ISO 8601 date string
  status: {
    id: number;
    value: string;
    defaultColorHex: string;
    customColorHex: string;
  }; // Updated type
  taskColor: string;
  estimatedTime: number; // in minutes (after converting from hours and minutes)
  dependencies: number;
  cardColor: string;
  emoji: string;
  taskType: string;
}

export interface UpdateTaskPayload {
  taskId: string;
  name?: string;
  details?: string;
  assignedUserIds?: string[];
  priority?: {
    id: number;
    value: string;
    defaultColorHex: string;
    customColorHex?: string;
  };
  dueDate?: string; // ISO 8601 date string
  status?: {
    id: number;
    value: string;
    defaultColorHex: string;
    customColorHex?: string;
  };
  taskColor?: string; // Hex color code
  estimatedTime?: number;
  dependencies?: number;
  cardColor?: string; // Hex color code
  emoji?: string;
  taskType?: string;
  customElements?: {
    shortText?: string;
    paragraph?: string;
    number?: number;
    checkbox?: boolean;
    date?: string;
    timestamp?: string;
    selectDropdown?: string[];
    approved?: boolean;
    multiSelectLabels?: string[];
    person?: string;
    filesMedia?: string;
    url?: string;
    email?: string;
    phone?: string;
    formula?: Record<string, any>; // Custom formula object
    relation?: Record<string, any>;
    rollup?: Record<string, any>;
    progressManual?: number;
    progressAuto?: number;
    rating?: number;
    money?: number;
    location?: Record<string, any>;
    dependentDropdown?: string[];
    richText?: string;
    colorPicker?: string; // Hex color code
    countdown?: string; // ISO 8601 date string
    connection?: Record<string, any>;
    voting?: Record<string, any>;
    currency?: string;
    geolocation?: Record<string, any>;
    percentage?: number;
  };
  checklists?: Array<{
    name: string;
    item: Array<{
      itemName: string;
      assignee: string;
      isChecked: boolean;
    }>;
  }>;
}

export interface CreateSubTaskPayload {
  name: string;
  details: string;
  assignedUserIds: string[];
  priority?: string;
  dueDate: string; // ISO 8601 date string
  status: string;
  taskColor?: string; // Hex color code
  estimatedTime?: number;
  dependencies?: number;
  cardColor?: string;
  emoji?: string;
  taskType?: string;
  customElements?: {
    shortText?: string;
    paragraph?: string;
    number?: number;
    checkbox?: boolean;
    date?: string;
    timestamp?: string;
    selectDropdown?: string[];
    approved?: boolean;
    multiSelectLabels?: string[];
    person?: string;
    filesMedia?: string;
    url?: string;
    email?: string;
    phone?: string;
    formula?: object;
    relation?: object;
    rollup?: object;
    progressManual?: number;
    progressAuto?: number;
    rating?: number;
    money?: number;
    location?: object;
    dependentDropdown?: string[];
    richText?: string;
    colorPicker?: string;
    countdown?: string;
    connection?: object;
    voting?: object;
    currency?: string;
    geolocation?: object;
    percentage?: number;
  };
  checklists?: {
    name: string;
    item: {
      itemName: string;
      assignee: string;
      isChecked: boolean;
    }[];
  }[];
}

export interface DeleteBulkTasksPayload {
  taskIds: string[];
}

// Add Whiteboard
export interface AddWhiteboardRequest {
  title: string;
  description: string;
}

// Update Whiteboard
export interface UpdateWhiteboardRequest {
  title: string;
  content: string;
}

// Add Collaborator
export interface AddCollaboratorRequest {
  memberId: string;
  memberRole: "viewer" | "editor" | "owner"; // Assuming possible roles
}

// Get All Collaborators
export interface Collaborator {
  memberId: string;
  memberRole: "viewer" | "editor" | "owner"; // Assuming possible roles
}

// Update Collaborator
export type UpdateCollaboratorRequest = AddCollaboratorRequest;

// Delete Collaborator
export interface DeleteCollaboratorRequest {
  memberId: string;
}

// Create Version
export interface VersionObject {
  type: string; // E.g., "rectangle", "circle", etc.
  properties: {
    position: { x: number; y: number; z: number };
    size: { width: number; height: number };
    rotation: number;
  };
}

export interface CreateVersionRequest {
  description: string;
  objects: VersionObject[];
}

// Restore Version
export interface RestoreVersionRequest {
  versionId: string;
}

// Define the structure of the payload for adding an object
export interface AddObjectRequest {
  // Example fields; adjust based on your actual API requirements
  type: string; // e.g., "rectangle", "circle"
  properties: {
    position: {
      x: number;
      y: number;
      z: number;
    };
    size: {
      width: number;
      height: number;
    };
    rotation?: number; // Optional field
  };
}

// Define the structure of the payload for updating an object
export interface UpdateObjectRequest {
  // Object ID or some unique identifier, if manually assigned
  objectID?: string;

  // Since we can't ensure the type, use `any`
  type?: any;

  // Properties specific to the object, based on fabric.js, using `any` for flexibility
  properties?: {
    [key: string]: any; // Allow any additional properties (from fabric.js or custom ones)
  };
}

export interface AddDocPayload {
  title: string;
  content: string;
  folderId?: string;
  templateId?: string;
  collaborators?:
  | Array<{
    userId: string;
    role: "editor" | "viewer";
  }>
  | []
  | null;
  isPublic: boolean;
  password?: string;
  expirationDate?: string;
}

// Type for adding a Wiki
export interface AddWiki {
  name: string;
  description: string;
  homePageId?: string;
}

// Type for the content of a Wiki page
interface WikiPageContent {
  text: string;
}

// Type for adding a Wiki Page
export interface AddWikiPage {
  title: string;
  content: WikiPageContent;
  parentPageId: string;
}

// Update the WorkspaceState interface
export interface WorkspaceState {
  viewMode: ViewMode;
  createWorkspace?: CreateWorkspace;
  workspaceList: WorkspaceList;
  sharedWorkspaceList: WorkspaceSharedList;
  insideWorkspaceList: InsideWorkspaceList;
  workspaceContentUpdated: any | null;
  insideWorkspaceItem: any;
  memberOfWorkspace: WorkspaceMember | null;
  error: Error | string | null;
  data: any; // For other API data
  lockPage: boolean | null;
  insideWorkspaceComponentData: any | null;
  componentsData: WorkspaceComponentsData; // Updated to reflect the new structure
}
