import React from "react";
import { CompaniesIcon, DealsIcon, PeopleIcon } from "../../../images";

export interface IconTextLongDropdownOptionInterface {
  id: number;
  icon: React.ElementType;
  text: string;
}

export const DUMMY_IconTextLongDropdownOption: IconTextLongDropdownOptionInterface[] =
  [
    {
      id: 1,
      text: "People",
      icon: PeopleIcon,
    },
    {
      id: 2,
      text: "Companies",
      icon: CompaniesIcon,
    },
    {
      id: 3,
      text: "Deals",
      icon: DealsIcon,
    },
  ];
