export interface DropdownWithImgBadgeInterface {
  name: string;
  email: string;
  userIconSrc?: string;
}

export const DUMMY_USER_EMAIL_LIST: DropdownWithImgBadgeInterface[] = [
  {
    name: "John Doe",
    email: "john.doe@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/men/1.jpg",
  },
  {
    name: "Jane Smith",
    email: "jane.smith@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/women/1.jpg",
  },
  {
    name: "Alice Johnson",
    email: "alice.johnson@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/women/2.jpg",
  },
  {
    name: "Rahul",
    email: "bob.brown@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/men/2.jpg",
  },
  {
    name: "Charlie Davis",
    email: "charlie.davis@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/men/3.jpg",
  },
  {
    name: "Diana Green",
    email: "diana.green@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/women/3.jpg",
  },
  {
    name: "Eve White",
    email: "eve.white@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/women/4.jpg",
  },
  {
    name: "Frank Black",
    email: "frank.black@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/men/4.jpg",
  },
  {
    name: "Grace Hill",
    email: "grace.hill@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/women/5.jpg",
  },
  {
    name: "Henry King",
    email: "henry.king@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/men/5.jpg",
  },
  {
    name: "Isabel Lee",
    email: "isabel.lee@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/women/6.jpg",
  },
  {
    name: "Jack White",
    email: "jack.white@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/men/6.jpg",
  },
  {
    name: "Kathy Brown",
    email: "kathy.brown@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/women/7.jpg",
  },
  {
    name: "Leo Green",
    email: "leo.green@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/men/7.jpg",
  },
  {
    name: "Mona Blue",
    email: "mona.blue@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/women/8.jpg",
  },
  {
    name: "Nick Gray",
    email: "nick.gray@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/men/8.jpg",
  },
  {
    name: "Olivia King",
    email: "olivia.king@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/women/9.jpg",
  },
  {
    name: "Paul Walker",
    email: "paul.walker@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/men/9.jpg",
  },
  {
    name: "Quincy Brown",
    email: "quincy.brown@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/men/10.jpg",
  },
  {
    name: "Rachel Green",
    email: "rachel.green@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/women/10.jpg",
  },
  {
    name: "Sam Blue",
    email: "sam.blue@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/men/11.jpg",
  },
  {
    name: "Tina Black",
    email: "tina.black@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/women/11.jpg",
  },
  {
    name: "Ursula White",
    email: "ursula.white@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/women/12.jpg",
  },
  {
    name: "Victor Red",
    email: "victor.red@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/men/12.jpg",
  },
  {
    name: "Wendy Blue",
    email: "wendy.blue@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/women/13.jpg",
  },
  {
    name: "Xander Gray",
    email: "xander.gray@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/men/13.jpg",
  },
  {
    name: "Yara Smith",
    email: "yara.smith@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/women/14.jpg",
  },
  {
    name: "Zane Hill",
    email: "zane.hill@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/men/14.jpg",
  },
  {
    name: "Amy Clark",
    email: "amy.clark@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/women/15.jpg",
  },
  {
    name: "Ben King",
    email: "ben.king@example.com",
    userIconSrc: "https://randomuser.me/api/portraits/men/15.jpg",
  },
];
