interface CheckboxIconProps {
  iconSize: number;
}

export const NotSelectedIcon: React.FC<CheckboxIconProps> = ({
  iconSize = 20,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={iconSize}
    height={iconSize}
    viewBox="0 0 20 20"
  >
    <g data-name="Light 1px/checkbox">
      <g transform="translate(3.125 3.125)">
        <path
          d="M11.459 13.75H2.291A2.294 2.294 0 010 11.459V2.291A2.294 2.294 0 012.291 0h9.167a2.294 2.294 0 012.292 2.291v9.167a2.294 2.294 0 01-2.291 2.292zM2.291.764A1.529 1.529 0 00.764 2.291v9.167a1.529 1.529 0 001.528 1.528h9.167a1.529 1.529 0 001.528-1.528V2.291A1.529 1.529 0 0011.459.764z"
          data-name="Combined Shape"
        ></path>
      </g>
    </g>
  </svg>
);
export const SelectedIcon: React.FC<CheckboxIconProps> = ({
  iconSize = 20,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={iconSize}
    height={iconSize}
    viewBox="0 0 20 20"
  >
    <g data-name="Light 1px/checkbox">
      <g transform="translate(3.125 3.125)">
        <path
          d="M11.459 13.75H2.291A2.294 2.294 0 010 11.459V2.291A2.294 2.294 0 012.291 0h9.167a2.294 2.294 0 012.292 2.291v9.167a2.294 2.294 0 01-2.291 2.292zM2.291.764A1.529 1.529 0 00.764 2.291v9.167a1.529 1.529 0 001.528 1.528h9.167a1.529 1.529 0 001.528-1.528V2.291A1.529 1.529 0 0011.459.764z"
          data-name="Combined Shape"
        ></path>
      </g>
    </g>
    <path
      d="M4.82.889L2.093 6.4c-.3.61-.791.61-1.093 0L.075 4.535a.936.936 0 010-.737c.1-.2.264-.2.364 0l.924 1.868c.1.2.264.2.364 0L4.456.153c.1-.2.264-.2.364 0a.936.936 0 010 .736z"
      data-name="Vector 408 (Stroke)"
      transform="translate(7.292 6.414)"
    ></path>
  </svg>
);
