import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

type TableDataType = {
  value: string;
};

type DomainTableProps = {
  TableData: TableDataType;
};

const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

// Function to extract and clean the main domain name from a URL
const extractMainDomain = (url: string): string => {
  try {
    const hostname = new URL(url).hostname;
    // Split hostname into parts by '.'
    const parts = hostname.split(".");

    // To handle common cases:
    // - Remove subdomains like 'www', 'm', 'mobile', etc.
    // - Use the last two parts of the hostname (domain + TLD)
    // This approach will cover common cases but may need refinement for special cases

    // If there are more than two parts, remove the subdomain
    if (parts.length > 2) {
      // We can assume the main domain is usually the last two parts
      return parts.slice(-2).join(".");
    }

    return hostname;
  } catch (error) {
    return "";
  }
};

const DomainTable: React.FC<DomainTableProps> = ({ TableData }) => {
  const domainName = extractMainDomain(TableData.value);

  console.log("🚀 ----------------------------🚀");
  console.log("🚀 ~ domainName:", domainName);
  console.log("🚀 ----------------------------🚀");


  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        typography: "body1",
        "& > :not(style) ~ :not(style)": {
          ml: 2,
        },
      }}
      onClick={preventDefault}
    >
      <Link
        href={TableData.value}
        underline="hover"
        target="_blank"
        rel="noopener noreferrer"
        sx={{ font: "normal normal normal 12px/16px Source Serif Pro" }}
      >
        {TableData.value}
      </Link>
    </Box>
  );
};

export default DomainTable;
