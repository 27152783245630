import React, { useState } from "react";
import { Box, Popper, ClickAwayListener, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat"; // for the 'Do' format

dayjs.extend(advancedFormat);

interface BoardCardDatePickerProps {
  initialDate?: Dayjs | null;
  onSelect: (date: Dayjs) => void;
}

const BoardCardDatePicker: React.FC<BoardCardDatePickerProps> = ({
  initialDate = null,
  onSelect,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(initialDate);

  React.useEffect(() => {
    if (initialDate) {
      setSelectedDate(initialDate);
    }
  }, [initialDate]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "board-card-date-picker-popper" : undefined;

  const formattedDate = selectedDate
    ? selectedDate.format("MMMM Do, YYYY")
    : "Select Date";

  const handleDateChange = (newValue: Dayjs | null) => {
    setSelectedDate(newValue);
    setAnchorEl(null);
    if (newValue) {
      onSelect(newValue);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box sx={{ cursor: "pointer", position: "relative" }}>
          <Typography
            onClick={handleClick}
            sx={{
              boxSizing: "border-box",
              font: "normal normal normal 14px/20px Source Serif Pro",
              cursor: "pointer",
              color:"black"
            }}
          >
            {formattedDate}
          </Typography>

          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
            style={{ zIndex: 1 }}
          >
            <Box
              sx={{
                p: 2,
                bgcolor: "background.paper",
                boxShadow: 1,
                cursor: "pointer",
                mt: 1,
              }}
            >
              <DateCalendar value={selectedDate} onChange={handleDateChange} />
            </Box>
          </Popper>
        </Box>
      </ClickAwayListener>
    </LocalizationProvider>
  );
};

export default BoardCardDatePicker;
