import { Box, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { SoonHorizontal } from "../../../SoonHorizontal";
import { PersonDropdown } from "../../../PersonDropdown";
import { AvatarNamePlateSmall } from "../../../AvatarNamePlateSmall";
import { InternalSharedButton } from "../../InternalSharedButton";
import { ShareOptionDropdown } from "../ShareOptionDropdown";
import { ConnectedIcon } from "../../../../../images";
import S from "../ShareButton.module.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import {
  WorkspaceMember,
} from "../../../../../redux";
import { useParams } from "react-router-dom";
type PopOverModelProps = {
  selectedTab: string;
  setSelectedTab: (string: string) => void;
  tooltipTitle: string;
  handleSelectedData: (data: { userData: any; action: any }) => void;
  resetSelection: () => void;
  selectedOption: string;
  handleCopyLinkClick: () => void;
  wId?: string;
  getAllMembers: () => void;
};
type Params = Record<string, string | undefined>;

const PopOverModel = ({
  setSelectedTab,
  selectedTab,
  tooltipTitle,
  handleSelectedData,
  resetSelection,
  selectedOption,
  handleCopyLinkClick,
  getAllMembers,
}: PopOverModelProps) => {
  const { workspaceId } = useParams<Params>();
  const workspaceState = useSelector((state: RootState) => state.workspace);
  const { memberOfWorkspace } = workspaceState || {};

  // Check if memberOfWorkspace exists and has members
  const members = memberOfWorkspace?.members;

  // Use dummyContent if members is empty or not present
  const contentToDisplay = members && members.length > 0 ? members : null;

  useEffect(() => {
    getAllMembers();
  }, [workspaceId]); // Dependency array includes workspaceId

  return (
    <div>
      {" "}
      <Stack className={S["share-button__popover"]}>
        {/* Header with Share and Public tabs */}
        <Stack className={S["share-button__header"]}>
          <Box
            component="span"
            className={`${S["share-button__header-item"]} ${selectedTab === "share" ? S["share-button__header-item--selected"] : ""}`}
            onClick={() => setSelectedTab("share")}
          >
            <Typography
              sx={{ font: "normal normal 300 14px/20px Source Serif Pro" }}
            >
              Share
            </Typography>
          </Box>
          <Box
            component="span"
            className={`${S["share-button__header-item"]} ${selectedTab === "public" ? S["share-button__header-item--selected"] : ""}`}
            onClick={() => setSelectedTab("public")}
            sx={{ marginLeft: "20px", marginRight: "5px" }}
          >
            <Typography
              sx={{ font: "normal normal 300 14px/20px Source Serif Pro" }}
            >
              Public
            </Typography>
          </Box>
          <SoonHorizontal />
        </Stack>

        {/* Middle section with search box and button */}
        <Stack p={"15px"}>
          <PersonDropdown
            selectedData={handleSelectedData}
            resetSelection={resetSelection}
            selectedOption={selectedOption}
          />
        </Stack>

        {/* Middle section where all shared content will appear */}
        <Stack className={S["share-button__scrollable-content"]}>
          {contentToDisplay?.map((item, index) => (
            <Stack
              key={index}
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <AvatarNamePlateSmall
                userName={
                  item.userId
                    ? `${item.userId.firstName} ${item.userId.lastName}`
                    : ""
                }
                size="default"
                department={item?.userId?.department || "Unknown Department"}
                teamName={item?.userId?.teamName || "Unknown Team"}
              />
              <InternalSharedButton
                selectedOption="Editor"
                onSelectionChange={(value) =>
                  console.log("value selected for this", value)
                }
              />
            </Stack>
          ))}
        </Stack>
        {/* Footer with filter and copy link */}
        <Stack className={S["share-button__footer"]}>
          <ShareOptionDropdown />
          <span
            className={S["share-button__copy-link"]}
            onClick={handleCopyLinkClick}
          >
            <Tooltip placement="top" title={tooltipTitle}>
              <Stack direction={"row"} alignItems={"center"} gap={"5px"}>
                <ConnectedIcon w={15} />
                <Typography className={S["share-button__tooltip-text"]}>
                  Copy link
                </Typography>
              </Stack>
            </Tooltip>
          </span>
        </Stack>
      </Stack>
    </div>
  );
};

export default PopOverModel;
// dummy_data here
const dummyContent: WorkspaceMember["members"] = [
  {
    userId: {
      firstName: "Jane",
      lastName: "Hornetsnestmore",
      email: "jane.hornetsnestmore@example.com",
      department: "Department name",
      teamName: "Team Beta",
    },
  },
  {
    userId: {
      firstName: "John",
      lastName: "Doe",
      email: "john.doe@example.com", // Example email, adjust as needed
      department: "Department name",
      teamName: "Team Alfa",
    },
  },
  {
    userId: {
      firstName: "Alice",
      lastName: "Smith",
      email: "alice.smith@example.com",
      department: "Department name",
      teamName: "Team Gamma",
    },
  },
  {
    userId: {
      firstName: "Bob",
      lastName: "Johnson",
      email: "bob.johnson@example.com",
      department: "Department name",
      teamName: "Team Delta",
    },
  },
];
