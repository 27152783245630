import { Popover, Stack, Typography } from "@mui/material";
import React,{useMemo } from "react";
import { CrmInfoPopup } from "../CrmInfoPopup";
import { useNavigate } from "react-router-dom";
// Define the type for the component's props
type CNameProps = {
  value: string;
  br?: string;
  hPopover?: boolean;
  targetOpen?: boolean;
  targetLocation?: string;
};

const randomPic = [
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZ99clJ9uWmomyjNVgO-PjeZrnur-_sswiUg&s",
  "https://www.betterpic.io/_vercel/image?url=%2Fassets%2Freusable-media%2Fbatchs%2Fmixed_ethnicity_and_gender%2Fbatch_2%2F09315-woman,_hispanic-25-blonde_hair-gray_business_dress-busy_business_district.jpg&w=1536&q=80",
  "https://i.pinimg.com/originals/69/ae/1f/69ae1f5c6bfead7525f0997dcf3401db.jpg",
]; // Function to randomly select a URL
const getRandomPic = () => {
  const randomIndex = Math.floor(Math.random() * randomPic.length);
  return randomPic[randomIndex];
};

export const CName: React.FC<CNameProps> = ({
  value,
  br = "50%",
  hPopover = false,
  targetOpen = false,
  targetLocation = "people",
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleNavigate = (id: string) => {
    if (targetOpen) {
      navigate(`/CRM/${targetLocation}/${id || "id-string"}`);
    }
  };
  // Memoize the random image URL
  const randomImageSrc = useMemo(() => getRandomPic(), []);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        gap={1}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <img
          style={{
            objectFit: "cover",
            width: "18px",
            height: "18px",
            borderRadius: br,
          }}
          src={randomImageSrc}
          alt="Profile"
        />
        <Typography
          onClick={() => handleNavigate(`${targetLocation}-id`)}
          sx={{
            fontFamily: "Source Serif Pro, serif",
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: "normal",
            cursor: "pointer",
            transition: "font-weight 0.3s", // Optional: Smooth transition for font weight change
            "&:hover": {
              fontWeight: "bold",
            },
          }}
        >
          {value}
        </Typography>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={open && hPopover}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <CrmInfoPopup />
        </Popover>
      </Stack>
    </>
  );
};
