import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";

interface NavigationRouteProps {
  label: string;
  path: string;
  labelFont?: string;
  icon: React.ReactNode;
}

const NavigationRoute: React.FC<NavigationRouteProps> = ({
  label,
  path,
  labelFont,
  icon: Icon,
}) => {
  return (
    <Link
      component={RouterLink}
      to={path}
      underline="none"
      color="inherit"
      style={{ display: "block", width: "100%" }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        {Icon && Icon}
        <Typography variant="body1" style={{ font: labelFont }}>
          {label}
        </Typography>
      </Stack>
    </Link>
  );
};

export default NavigationRoute;
