import React from "react";
import g from "./CrmInfoPopup.module.css";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { BrandFetchCInfo, ColoredBadge } from "../../../../common";
import {
  DescriptionIcon,
  DomainIcon,
  IconProps,
  LocationIcon,
  TrendUpIcon,
} from "../../../../../images";
import { MultiIconButton } from "../../common";



type CrmInfoPopupProps = {};

interface InfoItem {
  icon: React.FC<IconProps>;
  text?: string;
  component?: React.ReactNode;
}

const iconSize = 15;

const infoItems: InfoItem[] = [
  {
    icon: DescriptionIcon,
    text: "Webex is a global leader in cloud-based solutions, offering video meetings, calling, messaging, events, and customer experience solutions with a strong emphasis on inclusive experiences and security.",
  },
  { icon: DomainIcon, text: "webex.com" },
  {
    icon: LocationIcon,
    text: "Santa Clara California, United States of America",
  },
  {
    icon: TrendUpIcon,
    component: <ColoredBadge title={"UI Redesign"} bgColor={"orange"} />,
  },
];



const CrmInfoPopup: React.FC<CrmInfoPopupProps> = () => {
  return (
    <Box className={g.crminfoPopupBox}>
      <Stack
        direction={"row"}
        alignItems={"flex-start"}
        padding={2}
        justifyContent={"flex-start"}
      >
        <BrandFetchCInfo
          imgSizeH={50}
          imgSizeW={50}
          roundedOrSquare="cr"
          domainRequired={true}
          domainFont="normal normal 300 13px/17px Source Serif Pro"
          companyFont="normal normal 900 20px/28px Source Serif Pro"
        />
      </Stack>
      <Divider />
      <Stack padding={"0 20px"} gap={1.5} mt={"20px"}>
        {infoItems.map((item, index) => (
          <Stack
            key={index}
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            gap={1.5}
          >
            <item.icon w={iconSize} style={{ flexShrink: 0 }} />
            {item.text ? (
              <Typography className={g.descrquoteBox} style={{ flexGrow: 1 }}>
                {item.text}
              </Typography>
            ) : (
              item.component
            )}
          </Stack>
        ))}
       <MultiIconButton />
      </Stack>
    </Box>
  );
};

export default CrmInfoPopup;
