// Enum for DealStageCurrency
export enum DealStageCurrencyEnum {
  EUR = "EUR",
  GBP = "GBP",
  USD = "USD",
  CAD = "CAD",
  AUD = "AUD",
  JPY = "JPY",
  CNY = "CNY",
}

// Mapping of currency symbols
export const DealStageCurrencySymbols: {
  [key in DealStageCurrencyEnum]: string;
} = {
  [DealStageCurrencyEnum.EUR]: "€",
  [DealStageCurrencyEnum.GBP]: "£",
  [DealStageCurrencyEnum.USD]: "$",
  [DealStageCurrencyEnum.CAD]: "$",
  [DealStageCurrencyEnum.AUD]: "$",
  [DealStageCurrencyEnum.JPY]: "¥",
  [DealStageCurrencyEnum.CNY]: "¥",
};

// Interface for currency list
export interface DealStageCurrencyListInterface {
  id: number;
  name: string;
  value: DealStageCurrencyEnum;
}

// List of currencies
export const DealStageCurrencyList: DealStageCurrencyListInterface[] = [
  { id: 1, name: "Euro", value: DealStageCurrencyEnum.EUR },
  { id: 2, name: "Pound Sterling", value: DealStageCurrencyEnum.GBP },
  { id: 3, name: "US dollar", value: DealStageCurrencyEnum.USD },
  { id: 4, name: "Canadian", value: DealStageCurrencyEnum.CAD },
  { id: 5, name: "Australian", value: DealStageCurrencyEnum.AUD },
  { id: 6, name: "Japanese Yen", value: DealStageCurrencyEnum.JPY },
  { id: 7, name: "Chinese", value: DealStageCurrencyEnum.CNY },
];

// Interface for deal stage status
export interface DealStageStatus {
  id: number;
  name: string;
  color: string; // Can be a CSS color value
}

// Example of predefined statuses
export const predefinedDealStageStatuses: DealStageStatus[] = [
  { id: 1, name: "Lead", color: "blue" },
  { id: 2, name: "Bought In", color: "green" },
  { id: 3, name: "Won", color: "gold" },
  { id: 4, name: "Lost", color: "red" },
];

// Function to add custom status
export function addCustomDealStageStatus(
  status: DealStageStatus,
  statuses: DealStageStatus[] = predefinedDealStageStatuses
): DealStageStatus[] {
  return [...statuses, status];
}
