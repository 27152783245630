import { Dispatch, SetStateAction } from "react";
import { BgColorCode } from "../../../common";

// Define a type for department objects
export type Department = {
    _id: string;
    name: string;
    isDeleted: boolean;
    createdAt?: string;
    updatedAt?: string;
    __v?: number;
};

// Define a type for the state object
export type FirstLoginData = {
    firstName: string;
    lastName: string;
    avatarFile: File | null;
    companyName: string;
    companyNameSearch?: string;
    departMent: Department[];
    projectName: string;
    avatarDefaultColor: BgColorCode;
    companyRegistryChecked?: boolean;
    domain: string;
};

// Use the useState hook to initialize the state
export const initialState: FirstLoginData = {
    firstName: "",
    lastName: "",
    avatarFile: null,
    companyName: "",
    departMent: [],
    projectName: "",
    avatarDefaultColor: BgColorCode.Purple,
    companyRegistryChecked: false,
    companyNameSearch: "",
    domain: "",
};

// Define the props interface
export interface FirstLoginDataProps {
    firstLoginData: FirstLoginData;
    setFirstLoginData: Dispatch<SetStateAction<FirstLoginData>>;
}
