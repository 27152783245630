import { Stack } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";

interface DetailsPosterProps {}

const DetailsPoster: React.FC<DetailsPosterProps> = ({}) => {
  return (
    <Stack
      sx={{
        height: "150px",
        borderRadius: "5px",
        bgcolor: adjustHexColor("#E9EDF2", 25),
        flexShrink: 0,
      }}
    />
  );
};

export default DetailsPoster;
