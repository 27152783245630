import React, { useState, MouseEvent, useRef, useEffect } from "react";
import {
  IconButton,
  Popover,
  Stack,
  Typography,
  Box,
  InputBase,
  Skeleton,
} from "@mui/material";
import { CoverIcon } from "../../../../../images";
import { EmojiPickerSmall, ProjectImageSelector } from "../../../../common";

interface WordWorkspaceProps {
  eIcon: string;
  workSpaceName: string;
  handle__Update_workspace: (Icondata: string, name: string, appearance?: string | File) => void;
  sklLoader?: boolean;
}

const WordWorkspace: React.FC<WordWorkspaceProps> = ({
  workSpaceName,
  eIcon,
  handle__Update_workspace,
  sklLoader = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(workSpaceName);
  const [eIconI, eIconSet] = useState(eIcon);
  const inputRef = useRef<HTMLInputElement | null>(null); // Explicitly typing the ref
  const nameRef = useRef<HTMLDivElement | null>(null);
  const handleDoubleClick = () => {
    if(sklLoader) { return; }
    setIsEditing(true);
    setTimeout(() => inputRef.current?.focus(), 0); // Focus the input field immediately after rendering
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setIsEditing(false);
      handle__Update_workspace(eIcon, name);
    }
  };
  const [anchorElUpdateCover, setAnchorElUpdateCover] =
    useState<HTMLButtonElement | null>(null);

  const handleOpenPopover = (event: MouseEvent<HTMLDivElement>) => {
    if(sklLoader) {return}
    setAnchorEl(nameRef.current || event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleOpenUpdateCover = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElUpdateCover(event.currentTarget);
  };

  const handleCloseUpdateCover = () => {
    setAnchorElUpdateCover(null);
  };

  const openPopover = Boolean(anchorEl);
  const openUpdateCover = Boolean(anchorElUpdateCover);

  const popoverStyle = {
    padding: "4px",
    boxShadow: "none",
    border: "none",
  };

  useEffect(() => {
    setName(workSpaceName);
    eIconSet(eIcon);
  }, [workSpaceName, eIcon]);

  return (
    <Stack direction="column" gap="23px" bgcolor="white">
      <Box ref={nameRef} style={{ cursor: "pointer" }}>
        <Typography
          sx={{
            position: "relative",
            font: "normal normal 900 25px/34px Source Serif Pro",
          }}
          onDoubleClick={handleDoubleClick}
        >
          <Stack
            onClick={handleOpenPopover}
            position={"absolute"}
            top={`-50px`}
            fontSize={45}
          >
            {sklLoader || !eIconI ? (
              <Skeleton
                variant="circular"
                width={45}
                height={45}
                sx={{ bgcolor: "lightgray", opacity: 0.6 }}
              />
            ) : (
              eIconI || `🚀`
            )}
          </Stack>

          {isEditing ? (
            <InputBase
              ref={inputRef}
              value={name}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onBlur={() => {setIsEditing(false); handle__Update_workspace(eIcon, name);}} // Hide input when losing focus
              sx={{
                fontSize: "25px",
                fontWeight: 900,
                fontFamily: "Source Serif Pro",
                width: "100%",
                border: "1px solid #ccc",
                padding: "4px 8px",
                borderRadius: "4px",
              }}
            />
          ) : (sklLoader || !name) ? (
            <Skeleton variant="rounded" width={210} height={40} sx={{ fontSize: '1rem', bgcolor: "lightgray", opacity: 0.6 }} />
          ) : (
            name
          )}
        </Typography>
      </Box>
      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          ".MuiPopover-paper": {
            boxShadow: "none",
            border: "none",
            padding: "0px",
            animation: "none",
          },
        }}
      >
        <Stack direction="row" gap="20px">
          <Stack direction="row" alignItems={"center"} gap="5px">
            <EmojiPickerSmall
              w={20}
              onSelect={(emoji) => {
                handle__Update_workspace(emoji?.native, name);
              }}
            />
            <Typography
              sx={{ font: "normal normal 300 14px/20px Source Serif Pro" }}
            >
              Add icon
            </Typography>
          </Stack>

          <Stack direction="row" alignItems={"center"} gap="5px">
            <IconButton onClick={handleOpenUpdateCover}>
              <CoverIcon w={20} />
            </IconButton>
            <Typography
              sx={{ font: "normal normal 300 14px/20px Source Serif Pro" }}
            >
              Update cover
            </Typography>
          </Stack>
          <Popover
            open={openUpdateCover}
            anchorEl={anchorElUpdateCover}
            onClose={handleCloseUpdateCover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              ".MuiPopover-paper": {
                ...popoverStyle,
                animation: "none",
              },
            }}
          >
            <ProjectImageSelector onAppearanceSelectionsChanged={
              (avatar) => {
                handle__Update_workspace(eIconI, name, avatar)
              }
            } />
          </Popover>
        </Stack>
      </Popover>
    </Stack>
  );
};

export default WordWorkspace;
