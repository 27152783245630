import React, { useState, ChangeEvent, useCallback } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import ImageIcon from "@mui/icons-material/Image";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import DescriptionIcon from "@mui/icons-material/Description";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import styles from "../centralComponentStyle.module.css";
import { RootState } from "../../../../../../../redux/store";
import { WorkspaceState } from "../../../../../../../redux";
import { useSelector } from "react-redux";

// Define the shape of the data and initial state
interface FileComponentProps {
  initialFileUrl?: string;
  onSave: (data: { url: string; filename: string }) => void;
}

const FileComponent: React.FC<FileComponentProps> = ({
  initialFileUrl,
  onSave,
}) => {
  const workspaceState = useSelector((state: RootState) => state.workspace as WorkspaceState);
  const { lockPage } = workspaceState || {};

  const [file, setFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | undefined>(initialFileUrl);
  const [isEditing, setIsEditing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleFileChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
      setIsEditing(true); // Switch to editing mode when a file is selected
    }
  }, []);

  const handleUpload = () => {
    if (file) {
      onSave({
        url: "https://www.meistertask.com/blog/wp-content/uploads/2017/11/Modern-office.png",
        filename: "upload-file",
      });
      setFile(null);
      setPreview(undefined);
      setIsEditing(false);
      setIsHovered(false);
    }
  };

  const handleClear = () => {
    setFile(null);
    setPreview(undefined);
    setIsEditing(false);
    setIsHovered(false);
  };

  const handleCancel = () => {
    setFile(null);
    setPreview(initialFileUrl);
    setIsEditing(false);
  };

  const renderFileIcon = () => {
    if (!file && !preview) return <DescriptionIcon />;
    const fileType = file ? file.type.split("/")[0] : "";
    switch (fileType) {
      case "image":
        return <ImageIcon />;
      case "video":
        return <VideoLibraryIcon />;
      case "audio":
        return <AudiotrackIcon />;
      default:
        return <DescriptionIcon />;
    }
  };

  return (
    <Box
      className={styles.fileComponent}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <input
        type="file"
        onChange={handleFileChange}
        className={styles.fileInput}
        accept="image/*,video/*,audio/*,*/*"
        style={{ display: "none" }}
        id="file-upload-input"
      />
      <Box className={styles.previewContainer}>
        <Box className={styles.previewContent}>
          {preview && <Box className={styles.fileIcon}>{renderFileIcon()}</Box>}
          {preview ? (
            <img src={preview} alt="Preview" className={styles.previewImage} />
          ) : (
            <Typography variant="body2">No file selected</Typography>
          )}
        </Box>
        {!lockPage && isHovered && (
          <Box className={`${styles.buttonContainerEditClear} ${styles.buttonContainer}`}>
            <Tooltip title="Edit" aria-label="Edit">
              <label htmlFor="file-upload-input">
                <IconButton component="span" color="primary">
                  <EditIcon />
                </IconButton>
              </label>
            </Tooltip>
            {preview && (
              <Tooltip title="Clear" aria-label="Clear">
                <IconButton color="error" onClick={handleClear}>
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}
      </Box>
      {isEditing && (
        <Box className={styles.buttonContainer}>
          <Tooltip title="Save" aria-label="Save">
            <IconButton color="primary" onClick={handleUpload}>
              <SaveIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel" aria-label="Cancel">
            <IconButton color="error" onClick={handleCancel}>
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default FileComponent;
