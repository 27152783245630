import React from "react";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";

interface GreenIconProps extends IconButtonProps {
  iconColor?: string;
  w?: number;
}

const GreenArrowButton: React.FC<GreenIconProps> = ({
  w = 7.5,
  iconColor = "#00767B",
  sx,
  ...props
}) => {
  return (
    <IconButton
      {...props}
      disableRipple
      disableFocusRipple
      sx={{
        px: 1.2,
        py: 1,
        "&:hover": {
          "& .MuiSvgIcon-root path": {
            // change this color for see hover effect
            fill: iconColor,
          },
        },
        "&:active": {
          backgroundColor: "transparent",
        },
        "&:focus": {
          backgroundColor: "transparent",
        },
        ...sx, // Spread the sx prop to allow custom styling
      }}
    >
      <SvgIcon viewBox="0 0 336 511.46" sx={{ width: w, height: (w / 7) * 15 }}>
        <path
          fill={iconColor}
          fillRule="nonzero"
          style={{ transition: "fill 0.3s ease" }}
          d="M0 469V42.42c.02-9.89 3.46-19.81 10.45-27.85 15.39-17.66 42.2-19.53 59.86-4.15L321.46 229.2c1.69 1.51 3.32 3.17 4.81 4.97 14.92 18.04 12.4 44.78-5.64 59.7L71.14 500.3c-7.56 6.93-17.62 11.16-28.68 11.16C19.02 511.46 0 492.44 0 469z"
        />
      </SvgIcon>
    </IconButton>
  );
};

export default GreenArrowButton;
