import React from "react";

function BearishIcon({ width = 150, height = 150, color = "#00B188" }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            x="0"
            y="0"
            height={height}
            version="1.1"
            viewBox="0 0 120 120"
            xmlSpace="preserve"
        >
            <path opacity="1"></path>
            <path
                fill={color}
                d="M17.676 91.173c-1.085-2.12-2.373-3.931-2.568-5.852-.369-3.624-.109-7.311-.109-10.972 0-7.842 0-7.842 8.817-10.126.402 5.792 1.026 11.411 1.1 17.038.048 3.579 1.262 5.608 5.083 6.166 0-6.247-.104-12.38.034-18.51.107-4.71.445-9.305 5.897-11.55 1.373-.564 2.25-2.66 3.056-4.209 1.767-3.4 4.73-4.989 8.119-3.944 3.76 1.16 5.565 4.31 4.895 8.474-.586 3.647-2.664 5.548-6.403 5.295-4.457-.301-5.696 1.99-5.65 6.07.11 9.481-.007 18.966-.168 28.446-.031 1.851-.673 3.692-1.23 6.547-7.38-4.523-13.981-8.568-20.873-12.873m30.429-32.774c-.235-1.686-.067-3.63-.899-4.937-.346-.543-3.156-.171-4.218.623-.709.53-.832 3.401-.304 3.722 1.358.825 3.224.815 5.421.592z"
                opacity="1"
            ></path>
            <path
                fill={color}
                d="M39.912 35.729c3.606-1.95 6.656-2.011 10 .118 1.632 1.04 4.067 1.043 6.151 1.103 3.844.11 6.166 1.86 6.908 5.61.742 3.752-.957 6.566-4.174 8.004-3.451 1.543-6.827.377-8.477-2.977-2.152-4.376-4.722-4.075-8.344-2.078-8.545 4.713-17.18 9.265-25.854 13.738-1.338.69-3.07.618-5.302 1.022.434-8.457.786-16.353 1.31-24.238.066-1.003.78-2.342 1.609-2.855 5.253-3.254 10.557-6.443 16.014-9.334 1.015-.537 2.957.07 4.193.697 1.753.89 3.247 2.29 4.954 3.547-5.403 7.48-17.209 6.61-18.414 18.107 6.96-3.724 13.04-6.976 19.426-10.464m19.547 7.283L54.257 41.2c-.338 1.499-1.118 3.086-.843 4.463.157.788 2.497 2.007 3.038 1.68 1.336-.806 2.201-2.392 3.007-4.33zM92.648 85.728c1.543-1.019 2.669-2.18 4.018-2.573 4.01-1.168 5.157-3.687 3.965-7.932-6.952 3.476-13.86 7.014-20.863 10.353-1.255.599-2.983.752-4.353.46-3.923-.838-8.011-1.52-11.563-3.237-2.08-1.007-3.976-3.654-4.687-5.955-1.21-3.912 1.783-6.486 5.006-7.22 1.995-.454 5.376.693 6.63 2.297 4.27 5.464 4.025 5.682 10.256 2.525 9.365-4.746 18.715-9.521 28.537-14.521 2.691 4.198.979 8.857 1.247 13.237.036.585-.845 1.282-.729 1.798 3.09 13.705-8.255 16.09-16.4 20.958-1.243.744-2.603 1.292-3.911 1.934-.288-.144-.63-.235-.852-.45-6.848-6.565-6.846-6.567 3.7-11.674m-23.644-9.441c-1.243-1.116-2.342-2.613-3.799-3.158-.57-.214-2.609 1.61-2.758 2.678-.15 1.072 1.351 3.42 1.894 3.35 1.6-.202 3.083-1.324 4.663-2.87z"
                opacity="1"
            ></path>
            <path
                fill={color}
                d="M59.772 97.24A1266.117 1266.117 0 0047.7 88.271c-.392-.287-1.264-.333-1.319-.608-1.12-5.682-2.871-11.37-2.967-17.082-.07-4.143 4.817-6.011 9.345-4.546 3.468 1.122 5.2 5.133 3.83 8.87-2.574 7.023-2.512 6.932 3.729 11.177 8.34 5.673 16.573 11.505 25.374 17.633-1.474 1.337-2.365 2.606-3.583 3.172-4.978 2.314-10.344 3.933-15.016 6.73-4.65 2.783-8.261 1.206-12.02-1.114-2.642-1.63-5.048-3.639-7.644-5.351-5.44-3.59-5.883-6.486-1.925-11.76 7.628 1.485 12.041 10.96 22.878 8.205-3.52-2.58-5.93-4.346-8.611-6.357M47.01 72.425c1.31 1.14 2.491 2.58 4.01 3.235.437.188 2.505-1.815 2.625-2.94.114-1.08-1.47-3.375-2.06-3.296-1.587.214-3.042 1.41-4.575 3.001zM63.227 24.742a601.12 601.12 0 0010.281 7.242c2.563 1.76 3.8 3.887 3.644 7.253-.114 2.453.765 5.154 1.874 7.413 1.68 3.419 1.17 6.734-1.745 8.167-2.37 1.165-6.133 1.045-8.645-.011-3.363-1.414-3.332-4.86-1.898-8.103 2.816-6.366 2.689-6.64-3.343-10.63a2955.016 2955.016 0 01-23.13-15.437c-.915-.618-1.578-1.61-2.993-3.095 2.053-1.072 3.662-1.93 5.288-2.756 3.47-1.76 6.903-3.605 10.437-5.228 5.01-2.3 9.97-4.773 15.008.358 1.888 1.923 4.533 3.158 6.952 4.492 7.57 4.178 6.048 4.729 2.888 12.131-7.628-1.537-12.379-10.995-22.231-7.161a530.388 530.388 0 017.613 5.365m6.495 23.38c.66 1.58 1.041 3.414 2.129 4.602.398.436 3.22-.314 3.603-1.14.56-1.21.317-4.065-.29-4.287-1.475-.54-3.357.036-5.442.825z"
                opacity="1"
            ></path>
            <path
                fill={color}
                d="M104.12 29.025c1.218 1.91 2.513 3.681 2.787 5.598.625 4.38.675 8.84 1.1 13.255.467 4.835-1.083 7.145-5.795 8.014-2.494.46-4.272-.17-4.224-3.242.065-4.162.376-8.37-.122-12.474-.306-2.515-1.93-4.87-2.96-7.297l-2.21.508c-.232 2.635-.515 5.268-.676 7.908-.102 1.66-.019 3.33-.019 4.995.002 13.826-.025 13.798-9.9 23.153-2.745 2.602-6.181 3.18-8.692 1-1.694-1.47-2.073-4.844-2.372-7.444-.469-4.082 2.606-5.125 5.765-4.919 5.11.335 6.25-2.41 6.219-6.69-.067-9.16-.087-18.32.032-27.479.025-1.99.715-3.972 1.308-7.033 7.038 4.386 13.205 8.23 19.759 12.147M80.797 65.306c-1.278-1.405-2.466-2.923-3.937-4.083-.199-.156-2.434 1.365-2.619 2.323-.234 1.214.733 3.81 1.061 3.791 1.726-.103 3.413-.843 5.495-2.03z"
                opacity="1"
            ></path>
            <path
                fill={"#FFFFFF"}
                d="M47.838 58.672c-1.93-.05-3.796-.04-5.154-.865-.528-.32-.405-3.192.304-3.722 1.062-.794 3.872-1.166 4.218-.623.832 1.307.664 3.25.632 5.21z"
                opacity="1"
            ></path>
            <path
                fill={"#FFFFFF"}
                d="M59.583 43.345c-.93 1.606-1.795 3.192-3.131 3.999-.541.326-2.88-.893-3.038-1.68-.275-1.378.505-2.965.843-4.464 1.734.604 3.468 1.208 5.326 2.145z"
                opacity="1"
            ></path>
            <path
                fill={"#FFFFFF"}
                d="M68.98 76.679c-1.555 1.154-3.038 2.276-4.638 2.478-.543.07-2.045-2.278-1.894-3.35.15-1.068 2.187-2.892 2.758-2.678 1.457.545 2.556 2.042 3.773 3.55z"
                opacity="1"
            ></path>
            <path
                fill={"#FFFFFF"}
                d="M47.023 72.033c1.52-1.199 2.975-2.395 4.562-2.609.59-.08 2.174 2.216 2.06 3.296-.12 1.125-2.188 3.128-2.625 2.94-1.519-.655-2.7-2.094-3.997-3.627z"
                opacity="1"
            ></path>
            <path
                fill={"#FFFFFF"}
                d="M69.906 47.79c1.9-.457 3.783-1.032 5.258-.493.607.222.85 3.077.29 4.287-.382.826-3.205 1.576-3.603 1.14-1.088-1.188-1.468-3.023-1.945-4.933z"
                opacity="1"
            ></path>
            <path
                fill={"#FFFFFF"}
                d="M80.607 65.64c-1.892.854-3.58 1.594-5.305 1.697-.328.02-1.295-2.577-1.06-3.791.184-.958 2.42-2.48 2.618-2.323 1.47 1.16 2.66 2.678 3.747 4.417z"
                opacity="1"
            ></path>
        </svg>
    );
}

export default BearishIcon;
