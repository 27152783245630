import { Stack, Typography } from "@mui/material";
import React from "react";
import { AvatarNormal } from "../AvatarNormal";
import { InternalSharedButton } from "../Buttons/InternalSharedButton";
import { InternalShareButtonOptionValue } from "../Buttons/AllButtonProps";
import styles from "./ItemInsightsAccessCard.module.css";

interface ItemInsightsAccessCardProps {
  userNameOrEmail: string;
  userIconSrc?: string;
  timestamp: string;
  datestamp: string;
}

const ItemInsightsAccessCard: React.FC<ItemInsightsAccessCardProps> = ({
  timestamp,
  userNameOrEmail,
  datestamp,
  userIconSrc,
}) => {
  return (
    <Stack className={styles["item-insights-access-card"]}>
      <Stack className={styles["item-insights-access-card__details"]}>
        <AvatarNormal
          size={40}
          username={userNameOrEmail}
          imgSrc={userIconSrc}
        />
        <Stack className={styles["item-insights-access-card__text-container"]}>
          <Typography className={styles["item-insights-access-card__username"]}>
            {userNameOrEmail}
          </Typography>
          <Typography
            className={styles["item-insights-access-card__timestamp"]}
          >
            {timestamp} at {datestamp}
          </Typography>
        </Stack>
      </Stack>
      <InternalSharedButton
        selectedOption={InternalShareButtonOptionValue.Guest}
        onSelectionChange={(value) => null}
      />
    </Stack>
  );
};

export default ItemInsightsAccessCard;
