import { Drawer, Stack } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import CompanyIconAndName from "../../CompanyIconAndName";
import { MainActionButton } from "../../../Buttons";
import styles from "../../DrawerNavbar.module.css";
import { ButtonType, MainActionColor } from "../../../Buttons/AllButtonProps";
import DrawerSectionText from "../../DrawerSectionText";
import { NavigationRoute } from "../../../NavigationRoute";
import { CompaniesIcon, DealsIcon, PeopleIcon } from "../../../../../images";
type CrmDrawerProps = {
  drawerOpen: boolean;
  drawerCloser: () => void;
};

const DrawerContainer = styled("div")({
  position: "fixed",
  top: 0,
  height: "100vh",
  zIndex: 1100, // Ensure the drawer is behind the navbar
});
const CrmDrawer: React.FC<CrmDrawerProps> = ({ drawerOpen, drawerCloser }) => {
  return (
    <>
      <DrawerContainer>
        <Drawer
          sx={{
            position: "absolute",
            top: 0,
            height: "100vh",
            width: "215px",
            // left: '-240px', // Start hidden behind the Navbar
            transition: drawerOpen
              ? "left 225ms ease-out"
              : "left 195ms ease-in",
            left: drawerOpen ? "30px" : "-215px",
            zIndex: 1100, // Ensure the drawer is behind the navbar
            "& .MuiPaper-root": {
              position: "absolute",
              top: 0,
              height: "100vh",
              width: "215px",
              left: 0,
              boxSizing: "border-box",
              zIndex: 1100, // Ensure the paper is also behind the navbar
            },
          }}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
        >
          <Stack
            bgcolor={"white"}
            direction="column"
            flex={1}
            className={`${styles.drawerNavbar__drawer}`}
          >
            {/* Company logo and name here */}
            <CompanyIconAndName arrowButtonClickHandler={drawerCloser} />
            <Stack className={styles.drawerNavbar__content}>
              {/* Buttons and other lists here */}
              <MainActionButton
                sx={{ marginY: "25px" }}
                onClick={() => console.log("Home button clicked")}
                colorVarient={MainActionColor.Orange}
                label="Create"
                types={ButtonType.Button}
              />
              <Stack
                direction="column"
                gap={3} // Adjusted for simplicity
              >
                <Stack>
                  <DrawerSectionText gap={0} title="Relationships" />
                  <Stack mt={2} direction={'column'} gap={1.5}>
                    <NavigationRoute
                      label="People"
                      path="/CRM/people"
                      labelFont="normal normal 500 14px/19px Source Serif Pro"
                      icon={<PeopleIcon w={20} />}
                    />
                    <NavigationRoute
                      label="Companies"
                      path="/CRM/company"
                      labelFont="normal normal 500 14px/19px Source Serif Pro"
                      icon={<CompaniesIcon w={20} />}
                    />
                    <NavigationRoute
                      label="Deals"
                      path="/CRM/deal"
                      labelFont="normal normal 500 14px/19px Source Serif Pro"
                      icon={<DealsIcon w={20} />}
                    />
                  </Stack>
                </Stack>
                <DrawerSectionText title="Groups" />
              </Stack>
            </Stack>
          </Stack>
        </Drawer>
      </DrawerContainer>
    </>
  );
};

export default CrmDrawer;
