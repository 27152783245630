import React, { useState, MouseEvent } from "react";
import { IconButton, Popover, Box, SxProps, Tooltip } from "@mui/material";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { SmilyFaceIcon } from "../../../images";

interface EmojiSkin {
  unified: string;
  native: string;
  src?: string;
  sheetX?: number;
  sheetY?: number;
}

interface EmojiData {
  id: string;
  name: string;
  native: string;
  unified: string;
  shortcodes?: string;
  keywords: string[];
  skins?: EmojiSkin[];
}

interface EmojiCategory {
  id: string;
  name: string;
  emojis: string[];
}

interface EmojiMartData {
  emojis: Record<string, EmojiData>;
  categories: EmojiCategory[];
}

interface SelectedEmoji extends EmojiData {
  shortcodes: string;
}

interface EmojiPickerSmallProps {
  onSelect: (selectedEmoji: SelectedEmoji) => void;
  showEmojiOnSelect?: boolean;
  componentIcon?: string | React.ElementType;
  w?: number;
  sx?: SxProps;
  tooltipTitle?: string;
  transformOrigin?: any;
}

const emojiData: EmojiMartData = data as EmojiMartData;

const EmojiPickerSmall: React.FC<EmojiPickerSmallProps> = ({
  onSelect,
  showEmojiOnSelect = false,
  componentIcon,
  w = 15,
  tooltipTitle,
  sx = {},
  transformOrigin,
}) => {
  const [anchorElAddIcon, setAnchorElAddIcon] =
    useState<HTMLButtonElement | null>(null);
  const [selectedEmoji, setSelectedEmoji] = useState<SelectedEmoji | null>(
    null
  );

  const handleOpenAddIcon = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElAddIcon(event.currentTarget);
  };

  const openAddIcon = Boolean(anchorElAddIcon);

  const handleCloseAddIcon = () => {
    setAnchorElAddIcon(null);
  };

  const handleEmojiSelect = (emoji: SelectedEmoji) => {
    setSelectedEmoji(emoji);
    onSelect(emoji);
    handleCloseAddIcon();
  };

  const popoverStyle = {
    padding: "4px",
    boxShadow: "none",
    border: "none",
  };

  const renderIcon = () => {
    if (showEmojiOnSelect && selectedEmoji) {
      return <Box fontSize={w}>{selectedEmoji.native}</Box>;
    } else if (typeof componentIcon === "string") {
      return <Box fontSize={w}>{componentIcon}</Box>;
    } else if (componentIcon) {
      return React.createElement(componentIcon, { w });
    } else {
      return <SmilyFaceIcon w={w} />;
    }
  };

  const IconButtonComponent = (
    <IconButton
      disableRipple
      sx={{
        maxWidth: `${w}px`,
        maxHeight: `${w}px`,
        padding: "1px",
        boxSizing: "border-box",
        ...sx,
        color: "inherit",
        flexShrink: 0,
      }}
      onClick={handleOpenAddIcon}
    >
      {renderIcon()}
    </IconButton>
  );

  return (
    <>
      {tooltipTitle ? (
        <Tooltip title={tooltipTitle} placement="top" arrow>
          {IconButtonComponent}
        </Tooltip>
      ) : (
        IconButtonComponent
      )}
      <Popover
        open={openAddIcon}
        anchorEl={anchorElAddIcon}
        onClose={handleCloseAddIcon}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: transformOrigin ? transformOrigin?.vertical : "top",
          horizontal: transformOrigin ? transformOrigin?.horizontal : "left",
        }}
        sx={{
          ".MuiPopover-paper": {
            ...popoverStyle,
            animation: "none",
          },
        }}
      >
        <Picker
          previewPosition={"none"}
          theme={"dark"}
          data={emojiData}
          onEmojiSelect={handleEmojiSelect}
        />
      </Popover>
    </>
  );
};

export default EmojiPickerSmall;
