import { Stack, TextareaAutosize, Typography } from "@mui/material";
import React from "react";
import { LockAndUnlocked } from "../../../LockAndUnlocked";
import { adjustHexColor } from "../../../../../Utils";

interface ItemPropertiesViewProps {}

const ItemPropertiesView: React.FC<ItemPropertiesViewProps> = ({}) => {
  return (
    <Stack gap={"10px"}>
      {/* -------------- */}
      <Stack sx={{ flexDirection: "row", gap: "20px" }}>
        <Stack>
          <Typography
            sx={{
              font: "normal normal 400 14px/18px Source Serif Pro",
              paddingBottom: "5px",
              borderBottom: "1px solid #E9EDF2",
            }}
          >
            Item Properties
          </Typography>
        </Stack>

        <LockAndUnlocked onSelect={(value) => console.log(value)} />
      </Stack>
      {/* -------------- */}

      {/* -------------- */}
      <Stack gap={"10px"}>
        <Typography
          sx={{ font: "normal normal 400 14px/18px Source Serif Pro" }}
        >
          Description
        </Typography>
        <TextareaAutosize
          placeholder="Enter your description here..."
          minRows={3}
          maxRows={3}
          // value={inputValue}
          // onChange={handleChange}
          // onBlur={handleBlur}
          style={{
            resize: "none",
            border: "0px",
            outline: "none",
            padding: "10px",
            font: "normal normal 400 14px/18px Source Serif Pro",
            scrollbarWidth: "none",
            backgroundColor: adjustHexColor("#E9EDF2", 15),
          }}
        />
      </Stack>

      {/* -------------- */}
    </Stack>
  );
};

export default ItemPropertiesView;
