import { IconButton, Popover, Stack, Typography } from "@mui/material";
import React from "react";
import { TeamMemberIcon } from "../../../../images";
import S from "./ShareButton.module.css";
import { PopOverModel } from "./PopOverModel";
import { useShareButton } from "./useShareButton"; // Import the custom hook
import { useParams } from "react-router-dom";
import { addWorkspaceMember__api, getAllWorkspaceMember__api } from "../../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

interface ShareButtonProps {
  wId?: string;
}
type Params = Record<string, string | undefined>;

const ShareButton: React.FC<ShareButtonProps> = ({ wId }) => {
  const dispatch = useDispatch();
  const { workspaceId } = useParams<Params>();
  const {
    anchorEl,
    tooltipTitle,
    selectedTab,
    selectedOption,
    open,
    id,
    handleClick,
    handleClose,
    handleCopyLinkClick,
    resetSelection,
    setSelectedTab,
  } = useShareButton();
  const workspaceState = useSelector((state: RootState) => state.workspace);
  const { error } = workspaceState || {};
  // Handle selected data from PersonDropdown
  const handleSelectedData = async (data: { userData: any; action: any }) => {
    // Map roles
    let memberRole = data.action;
    if (memberRole === "Guest" || memberRole === "Commenter") {
      memberRole = "viewer"; // Map "Guest" and "Commenter" to "viewer"
    }

    // Check if workspaceId is available, then call the add member API
    if (workspaceId) {
      const payload = {
        memberId: data.userData._id,
        memberRole: memberRole, // Mapped role
        permissions: "view", // Modify permissions as per your logic
      };

      try {
        // Dispatch the action to add a member to the workspace
        const response = await dispatch(
          addWorkspaceMember__api(payload, workspaceId)
        );
        console.log("Add member response:", response);
        getAllMembers();
      } catch (error) {
        console.error("Error adding member:", error);
      }
    } else {
      console.warn("No workspaceId provided, skipping member addition.");
    }
  };

  const getAllMembers = async () => {
    if (workspaceId) {
      try {
        await dispatch(getAllWorkspaceMember__api({ workspaceId }));
      } catch (err) {
        // Handle API error or dispatch error
        if (error) {
          console.error("Error from state:", error);
        } else {
          console.error("Failed to fetch members:", err);
        }
      }
    } else {
      console.log("No workspaceId provided, skipping member fetch.");
    }
  };

  return (
    <>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        className={S["share-button"]}
        disableRipple
        disableFocusRipple
      >
        <Stack direction={"row"} alignItems={"center"} gap={"5px"}>
          <TeamMemberIcon w={20} />
          <Typography
            sx={{ font: "normal normal 300 14px/20px Source Serif Pro" }}
          >
            Share
          </Typography>
        </Stack>
      </IconButton>

      {/* Popover that opens on Share button click */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          className: S["share-button__popover"],
        }}
      >
        <PopOverModel
          tooltipTitle={tooltipTitle}
          resetSelection={resetSelection}
          handleSelectedData={handleSelectedData}
          selectedOption={selectedOption}
          handleCopyLinkClick={handleCopyLinkClick}
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
          getAllMembers={getAllMembers}
        />
      </Popover>
    </>
  );
};

export default ShareButton;
