import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "./SmallContactCardHover.module.css";
import { AvatarNormal } from "../AvatarNormal";
import { QuickActionsMenu } from "../QuickActionsMenu";
import { AddUserIcon } from "../../../images";

interface SmallContactCardHoverProps {
  userName: string;
  userEmail: string;
  userIconSrc?: string;
  userAvatarColor?: string;
}

const SmallContactCardHover: React.FC<SmallContactCardHoverProps> = ({
  userEmail,
  userName,
  userIconSrc,
  userAvatarColor,
}) => {
  return (
    <Stack className={styles["smallContactCardHover"]}>
      {/* -- first line -- */}
      <Stack className={styles["smallContactCardHover__header"]}>
        <Stack className={styles["smallContactCardHover__header-content"]}>
          <AvatarNormal
            size={30}
            username={userName}
            imgSrc={userIconSrc}
            avatarColor={userAvatarColor}
          />
          <Stack className={styles["smallContactCardHover__text-container"]}>
            <Typography
              className={styles["smallContactCardHover__companyName"]}
            >
              {userName}
            </Typography>
            <Typography className={styles["smallContactCardHover__email"]}>
              {userEmail}
            </Typography>
          </Stack>
        </Stack>

        <IconButton sx={{ p: 0 }} disableRipple>
          <AddUserIcon w={15} />
        </IconButton>
      </Stack>

      {/* -- second line -- */}

      <Stack className={styles["smallContactCardHover__actions"]}>
        <IconButton
          className={styles["smallContactCardHover__openDetailsButton"]}
          // disableRipple
        >
          <Typography
            className={styles["smallContactCardHover__openDetailsText"]}
          >
            Open contact details
          </Typography>
        </IconButton>

        <QuickActionsMenu onSelect={(value) => null} />
      </Stack>
    </Stack>
  );
};

export default SmallContactCardHover;
