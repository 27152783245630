/**
 * Custom error for invalid name inputs.
 */
class InvalidNameError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'InvalidNameError';
  }
}

/**
 * Extracts the initials from a given name.
 * @param name - The full name from which initials are derived.
 * @returns The initials of the name, in uppercase.
 * @throws InvalidNameError if the provided name is not a valid string or is empty.
 */
export const avatarInitialLetters = (name: unknown): string => {
  // Validate input type
  if (typeof name !== 'string') {
    return "";
  }

  // Trim and check for empty string
  const trimmedName = name.trim();
  if (trimmedName.length === 0) {
    // throw new InvalidNameError('The provided name cannot be empty.');
    return "";
  }

  // Normalize and split the name into words
  const words = trimmedName.split(/\s+/).slice(0, 2);

  // Map the first letter of each word to uppercase and join them
  const initials = words.map(word => {
    if (word.length > 0) {
      return word.charAt(0).toUpperCase();
    }
    return '';
  }).join('');

  return initials;
};
