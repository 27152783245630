import * as React from "react";
import {
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  InputBase,
  Tooltip,
} from "@mui/material";
import S from "./CustomCheckboxDropdown.module.css";
import { GreenDropdownIcon } from "../../../images";
import { CustomCheckboxDropdownOptionInterface } from "./CustomCheckboxDropdownType";
import { ColorPickerDot } from "../ColorPickerDot";
import { CheckboxMedium } from "../CheckboxMedium";
interface CustomCheckboxDropdownProps {
  options: CustomCheckboxDropdownOptionInterface[];
  placeHolder?: string;
  multiple?: boolean;
  width?: string;
  onChange?: (selectedOption: string) => void;
}
const CustomCheckboxDropdown: React.FC<CustomCheckboxDropdownProps> =
  React.memo(
    ({
      options,
      placeHolder,
      multiple = false,
      width = "125px",
      onChange,
      ...props
    }) => {
      const [personName, setPersonName] = React.useState<string[]>([]);
      const [open, setOpen] = React.useState(false);
      const [localOptions, setLocalOptions] =
        React.useState<CustomCheckboxDropdownOptionInterface[]>(options);
      const [addingCustom, setAddingCustom] = React.useState(false);
      const [newCustomValue, setNewCustomValue] = React.useState("");
      const [newCustomColor, setNewCustomColor] = React.useState("#007BB2");
      const handleChange = React.useCallback(
        (event: SelectChangeEvent<string[]>) => {
          const {
            target: { value },
          } = event;
          const updatedValue =
            typeof value === "string" ? value.split(",") : value;
          setPersonName(updatedValue);
          if (onChange) {
            const selectedValue =
              typeof value === "string" ? value : value.join(", ");
            onChange(selectedValue);
          }
        },
        [onChange]
      );
      const stopPropagation = (
        event: React.MouseEvent | React.KeyboardEvent
      ) => {
        event.stopPropagation();
      };
      const handleAddCustomClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        setAddingCustom(true);
      };
      const handleSaveCustomClick = () => {
        if (newCustomValue.trim() !== "") {
          const newCustomOption: CustomCheckboxDropdownOptionInterface = {
            value: newCustomValue,
            color: newCustomColor,
          };
          setLocalOptions([...localOptions, newCustomOption]);
        }
        setAddingCustom(false);
        setNewCustomValue("");
        setNewCustomColor("#000000");
      };
      const handleClose = () => {
        if (addingCustom && newCustomValue.trim() !== "") {
          handleSaveCustomClick();
        }
        setAddingCustom(false);
        setOpen(false);
      };
      const handleColorChange = (value: string, color: string) => {
        const updatedOptions = localOptions.map((option) =>
          option.value === value ? { ...option, color: color } : option
        );
        setLocalOptions(updatedOptions);
      };
      const MenuProps = {
        PaperProps: {
          className: S["custom-checkbox-dropdown__menu"],
          style: { width: width },
        },
      };
      return (
        <div>
          <FormControl size="small" style={{ width }} {...props}>
            <Select
              multiple={multiple}
              displayEmpty
              IconComponent={() =>
                open ? (
                  <GreenDropdownIcon open={open} sx={{ marginRight: "4px" }} />
                ) : (
                  <></>
                )
              }
              value={personName}
              onChange={handleChange}
              input={
                <OutlinedInput
                  className={S["custom-checkbox-dropdown__inputbox"]}
                  classes={{
                    notchedOutline:
                      S["custom-checkbox-dropdown__notched-outline"],
                    focused: S["custom-checkbox-dropdown__focused"],
                  }}
                  style={{ outline: "none" }}
                />
              }
              onOpen={() => setOpen(true)}
              onClose={handleClose}
              renderValue={(selected) => {
                if (selected?.length === 0) {
                  return (
                    <em
                      className={
                        S["custom-checkbox-dropdown__select-placeholder"]
                      }
                    >
                      {placeHolder}
                    </em>
                  );
                }
                return (
                  <span className={S["custom-checkbox-dropdown__selected"]}>
                    <span
                      style={{
                        maxWidth: "70%",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        display: "inline-block",
                      }}
                    >
                      {selected?.join(", ")}
                    </span>
                    <span
                      onMouseDown={stopPropagation}
                      onClick={stopPropagation}
                    >
                      <ColorPickerDot />
                    </span>
                  </span>
                );
              }}
              MenuProps={MenuProps}
            >
              {localOptions.map((item) => (
                <MenuItem
                  className={S["custom-checkbox-dropdown__menu-item"]}
                  key={item.value}
                  value={item.value}
                >
                  <>
                    <CheckboxMedium
                      boxSize={14}
                      checked={personName.includes(item.value)}
                    />
                    <ListItemText
                      primary={item.value}
                      primaryTypographyProps={{
                        className:
                          S["custom-checkbox-dropdown__list-item-text"],
                      }}
                    />
                    <div
                      onMouseDown={stopPropagation}
                      onClick={stopPropagation}
                    >
                      <ColorPickerDot
                        initialColor={item.color}
                        onColorChange={(color) =>
                          handleColorChange(item.value, color)
                        }
                      />
                    </div>
                  </>
                </MenuItem>
              ))}
              {addingCustom ? (
                <Stack className={S["custom-checkbox-dropdown__menu-item"]}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <InputBase
                      value={newCustomValue}
                      onChange={(e) => setNewCustomValue(e.target.value)}
                      placeholder="Enter text"
                      fullWidth
                      onMouseDown={stopPropagation}
                      onClick={stopPropagation}
                      onKeyDown={stopPropagation} // Prevent key events from propagating
                      onKeyUp={stopPropagation} // Prevent key events from propagating
                      sx={{
                        height: "20px",
                        border: "0.5px solid #D9E3EF",
                        font: "normal normal 300 12px/16px Source Serif Pro",
                      }}
                    />
                    <div
                      onMouseDown={stopPropagation}
                      onClick={stopPropagation}
                    >
                      <ColorPickerDot
                        onColorChange={(color) => setNewCustomColor(color)}
                      />
                    </div>
                  </Stack>
                </Stack>
              ) : (
                <MenuItem className={S["custom-checkbox-dropdown__menu-item"]}>
                  <Tooltip title="Add new priority" placement="right" arrow>
                    <ListItemText
                      onClick={(e) => handleAddCustomClick(e)}
                      primary="Add Custom"
                      primaryTypographyProps={{
                        className:
                          S["custom-checkbox-dropdown__list-item-text"],
                      }}
                    />
                  </Tooltip>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
      );
    }
  );
export default CustomCheckboxDropdown;
