import { Stack, Typography } from "@mui/material";
import React from "react";
import {
  LongSearchBearishOS,
  StyledTooltip,
  LongSearchBearishOSListType,
} from "../../../../../../common";
import { InfoIconBlue } from "../../../../../../../images";
import SharedPersonLists from "./SharedPersonLists";
import {
  ConnectionItem,
  CreateCardData,
  ShareWorkspaceItem,
} from "../../../MainTop/types";
import { getUserList } from "../../../../../../../Utils";
interface ShareWorkspaceProps {
  createCardData: CreateCardData;
  updateShareWorkspace: (
    value: Partial<CreateCardData["shareWorkspace"]>
  ) => void;
}

const ShareWorkspace: React.FC<ShareWorkspaceProps> = ({
  createCardData,
  updateShareWorkspace,
}) => {
  const usersList = getUserList();

  console.log("🚀 ----------------🚀");
  console.log("🚀 ~ usersList:", usersList);
  console.log("🚀 ----------------🚀");

  const handleAddShareWorkspace = (newShareWorkspace: ConnectionItem[]) => {
    if (newShareWorkspace.length === 0) return; // Ensure there are new share projects to add

    const updatedShareList: ShareWorkspaceItem[] = [
      ...(createCardData?.shareWorkspace?.shareWorkspaceList || []),
      ...newShareWorkspace?.map((shareWorkspace: any) => ({
        userId: shareWorkspace?._id,
        text: shareWorkspace?.email,
        value: shareWorkspace?.email,
        permissions: "view" as "view" | "edit",
        role: "owner" as "owner" | "admin" | "editor" | "viewer",
        data: shareWorkspace,
      })),
    ];

    updateShareWorkspace({ shareWorkspaceList: updatedShareList });
  };

  return (
    <Stack sx={{ boxSizing: "border-box", paddingTop: "30px" }}>
      <Stack
        direction={"row"}
        gap={"5px"}
        height={"15px"}
        alignItems={"end"}
        mb={"5px"}
      >
        <Typography
          sx={{ font: "normal normal normal 10px/13px Source Serif Pro" }}
        >
          Guests
        </Typography>
        <StyledTooltip title="Invite your guest">
          <InfoIconBlue />
        </StyledTooltip>
      </Stack>
      <LongSearchBearishOS
        options={usersList}
        buttonLabel="Invite"
        inputPlaceHolder="Search Bearish OS"
        width="322px"
        onSelectOption={handleAddShareWorkspace}
      />
      <Stack mt={"30px"} sx={{ height: "210px" }}>
        {/* <Typography
          sx={{
            font: "normal normal normal 10px/13px Source Serif Pro",
            marginBottom: "10px",
          }}
        >
          Added Connections
        </Typography> */}
        {/* this is thw box were all shared person list are appear */}
        {/* hide the typography and show list here */}
        <Stack
          sx={{
            gap: "10px",
            height: "90%px",
            overflowY: "auto",
            "::-webkit-scrollbar": {
              width: 0,
              height: 0,
            },
            scrollbarWidth: "none",
          }}
        >
          {createCardData.shareWorkspace.shareWorkspaceList.length > 0 ? (
            createCardData.shareWorkspace.shareWorkspaceList.map(
              (connection) => (
                <SharedPersonLists
                  key={connection.userId}
                  connection={connection || {}}
                />
              )
            )
          ) : (
            <Typography
              sx={{
                font: "normal normal 300 12px/16px Source Serif Pro",
                textAlign: "center",
                opacity: 0.5,
              }}
            >
              Your added Connections will be listed here once you choose them…
            </Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ShareWorkspace;

// dummy data for select list
const OPTIONS: LongSearchBearishOSListType[] = [
  { id: "1", text: "email-1@bearishfh.com", value: "email-1@bearishfh.com" },
  { id: "2", text: "email-2@bearishfh.com", value: "email-2@bearishfh.com" },
  { id: "3", text: "email-3@bearishfh.com", value: "email-3@bearishfh.com" },
  { id: "4", text: "email-4@bearishfh.com", value: "email-4@bearishfh.com" },
  { id: "5", text: "email-5@bearishfh.com", value: "email-5@bearishfh.com" },
  { id: "6", text: "email-6@bearishfh.com", value: "email-6@bearishfh.com" },
  { id: "7", text: "email-7@bearishfh.com", value: "email-7@bearishfh.com" },
  { id: "8", text: "email-8@bearishfh.com", value: "email-8@bearishfh.com" },
  { id: "9", text: "email-9@bearishfh.com", value: "email-9@bearishfh.com" },
  { id: "10", text: "email-10@bearishfh.com", value: "email-10@bearishfh.com" },
  { id: "11", text: "email-11@bearishfh.com", value: "email-11@bearishfh.com" },
  { id: "12", text: "email-12@bearishfh.com", value: "email-12@bearishfh.com" },
];
