import React, { useState, useEffect } from "react";
import { SingleDate } from "../SingleDate";
import { Stack, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";

interface DateState {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

interface CountdownValueProps {
  onSelect: (data: { name: string; value: string }[]) => void;
}

const CountdownValue: React.FC<CountdownValueProps> = ({ onSelect }) => {
  const initialDate: Dayjs = dayjs();

  const [dates, setDates] = useState<DateState>({
    startDate: initialDate,
    endDate: initialDate,
  });

  const handleDateSelect = (name: keyof DateState, date: Dayjs) => {
    setDates((prevDates) => ({ ...prevDates, [name]: date }));
  };

  useEffect(() => {
    const data = [
      {
        name: "startDate",
        value: dates.startDate ? dates.startDate.format("DD-MM-YYYY") : "",
      },
      {
        name: "endDate",
        value: dates.endDate ? dates.endDate.format("DD-MM-YYYY") : "",
      },
    ];
    onSelect(data);
  }, [dates, onSelect]);

  return (
    <Stack sx={{ flexDirection: "row", gap: "15px" }}>
      <Stack sx={{ flexGrow: 1 }}>
        <Typography
          sx={{ font: "normal normal 300 11px/15px Source Serif Pro" }}
        >
          Start date
        </Typography>
        <SingleDate
          initialDate={dates.startDate}
          onSelect={(date) => handleDateSelect("startDate", date)}
          width="100%"
        />
      </Stack>
      <Stack sx={{ flexGrow: 1 }}>
        <Typography
          sx={{ font: "normal normal 300 11px/15px Source Serif Pro" }}
        >
          End date
        </Typography>
        <SingleDate
          initialDate={dates.endDate}
          onSelect={(date) => handleDateSelect("endDate", date)}
          width="100%"
        />
      </Stack>
    </Stack>
  );
};

export default CountdownValue;
