import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import {
  CloseDayIcon,
  CommentsIcon,
  DescriptionIcon,
  FullViewIcon,
  FundingIcon,
} from "../../../../../images";
import { DealStageCurrencySymbols } from "../../../../common";
import styles from "./CrmBoardCard.module.css";

export interface CrmBoardCardProps {
  iconSrc: string;
  cardHeader: string;
  description: string;
  funding: string | number;
  timeStamp: string;
  bgcolor?: string;
}

const CrmBoardCard: React.FC<CrmBoardCardProps> = ({
  cardHeader,
  description,
  funding,
  iconSrc,
  timeStamp,bgcolor
}) => {
  const currenySymbol = DealStageCurrencySymbols.USD;
  return (
    <Stack bgcolor={bgcolor} className={styles["crm-board-card"]}>
      {/* card head components */}
      <Stack className={styles["crm-board-card__header"]}>
        <Stack className={styles["crm-board-card__header-left"]}>
          <Box
            component={"img"}
            className={styles["crm-board-card__header-logo"]}
            src={iconSrc}
            alt="logo"
          />
          <Typography className={styles["crm-board-card__header-title"]}>
            {cardHeader}
          </Typography>
        </Stack>
        <Tooltip title="full view" placement="top" arrow>
          <IconButton sx={{ padding: 0 }} disableRipple>
            <FullViewIcon w={15} />
          </IconButton>
        </Tooltip>
      </Stack>

      {/*  card body component */}
      <Stack className={styles["crm-board-card__body"]}>
        <Stack className={styles["crm-board-card__description-container"]}>
          <Box
            className={styles["crm-board-card__description-icon"]}
            component={"span"}
          >
            <DescriptionIcon w={15} />
          </Box>
          <Typography className={styles["crm-board-card__description-text"]}>
            {description}
          </Typography>
        </Stack>
        <Stack className={styles["crm-board-card__footer"]}>
          <Stack className={styles["crm-board-card__footer-left"]}>
            <Stack className={styles["crm-board-card__footer-item"]}>
              <FundingIcon w={15} />
              <Typography className={styles["crm-board-card__footer-text"]}>
                {currenySymbol}
                {funding}
              </Typography>
            </Stack>
            <Stack className={styles["crm-board-card__footer-item"]}>
              <CloseDayIcon w={15} />
              <Typography className={styles["crm-board-card__footer-text"]}>
                {timeStamp}
              </Typography>
            </Stack>
          </Stack>
          <IconButton sx={{ p: 0 }} disableRipple>
            <CommentsIcon w={15} />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CrmBoardCard;
