/* DataTable.tsx */
import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ColDef, CellValueChangedEvent } from "ag-grid-community";
import "./table.css";

interface DataTableProps<T> {
  columnDefs: ColDef<T>[];
  rowData: T[];
  pinnedBottomRowData: T[];
  onCellValueChanged: (event: CellValueChangedEvent<T>) => void;
}

const DataTable = <T,>({
  columnDefs,
  rowData,
  pinnedBottomRowData,
  onCellValueChanged,
}: DataTableProps<T>) => {
  return (
    <div
      className="ag-theme-alpine table-container"
      style={{
        width: "100%",
        maxHeight: 575,
        height: 575,
        overflow: "scroll", // Enable scrolling
        scrollbarWidth: "none", // Hide scrollbar for Firefox
      }}
    >
      <style>
        {`
      .table-container::-webkit-scrollbar {
        display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
      }
      .table-container {
        -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
      }
    `}
      </style>
      <AgGridReact<T>
        rowData={rowData}
        columnDefs={columnDefs}
        pinnedBottomRowData={pinnedBottomRowData}
        onCellValueChanged={onCellValueChanged}
        rowSelection={"multiple"}
        suppressMenuHide={true}
        animateRows={true}
        domLayout={"normal"}
        alwaysShowVerticalScroll={true}
        suppressHorizontalScroll={true}
        suppressMovableColumns={true}
      />
    </div>
  );
};

export default DataTable;
