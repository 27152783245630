import {
  CallIcon,
  CheckboxIcon,
  ClockIcon,
  DataIDnumberIcon,
  DateIcon,
  DealNameIcon,
  DealStageIcon,
  FundingIcon,
  HashtagIcon,
  LocationIcon,
  MultiselectIcon,
  RelationshipStrengthIcon,
  SelectIcon,
  StrongestrelationshipIcon,
} from "../../../../../images";
import { IconTextLongDropdownOptionInterface } from "../../../../common";

// here record have no preview
export enum CreateAttributeOptionsEnum {
  Text = "Text",
  Numbers = "Numbers",
  Checkbox = "Checkbox",
  Date = "Date",
  Rating = "Rating",
  SingleSelect = "Single Select",
  Timestamp = "Timestamp",
  Multiselect = "Multiselect",
  Currency = "Currency",
  Record = "Record",
  Status = "Status",
  Location = "Location",
  Team = "Team",
  PhoneNumber = "Phone number",
}

export interface CreateAttributeFormDataInterface {
  name: string;
  description?: string;
  formType: CreateAttributeOptionsEnum;

  additionalValue?: any;
}

export type CreateAttributeOptionInterface = Omit<
  IconTextLongDropdownOptionInterface,
  "text"
> & {
  text: CreateAttributeOptionsEnum;
};

export const CreateAttributeOptionsList: CreateAttributeOptionInterface[] = [
  { id: 1, text: CreateAttributeOptionsEnum.Text, icon: DealNameIcon },
  { id: 2, text: CreateAttributeOptionsEnum.Numbers, icon: HashtagIcon },
  { id: 3, text: CreateAttributeOptionsEnum.Checkbox, icon: CheckboxIcon },
  { id: 4, text: CreateAttributeOptionsEnum.Date, icon: DateIcon },
  {
    id: 5,
    text: CreateAttributeOptionsEnum.Rating,
    icon: RelationshipStrengthIcon,
  },
  { id: 6, text: CreateAttributeOptionsEnum.SingleSelect, icon: SelectIcon },
  { id: 7, text: CreateAttributeOptionsEnum.Timestamp, icon: ClockIcon },
  {
    id: 8,
    text: CreateAttributeOptionsEnum.Multiselect,
    icon: MultiselectIcon,
  },
  { id: 9, text: CreateAttributeOptionsEnum.Currency, icon: FundingIcon },
  { id: 10, text: CreateAttributeOptionsEnum.Record, icon: DataIDnumberIcon },
  { id: 11, text: CreateAttributeOptionsEnum.Status, icon: DealStageIcon },
  { id: 12, text: CreateAttributeOptionsEnum.Location, icon: LocationIcon },
  {
    id: 13,
    text: CreateAttributeOptionsEnum.Team,
    icon: StrongestrelationshipIcon,
  },
  { id: 14, text: CreateAttributeOptionsEnum.PhoneNumber, icon: CallIcon },
];
