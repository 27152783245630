import React from "react";
import { Box, Stack, styled, SxProps } from "@mui/material";
import { AvatarNormal } from "../AvatarNormal";

interface AvatarWithCompanyProps {
  size: 30 | 40;
  userName: string;
  userIconSrc?: string;
  isActive?: boolean;
  companyLogoSrc?: string;
  sx?: SxProps;
}

const AvatarWithCompany: React.FC<AvatarWithCompanyProps> = ({
  size,
  isActive,
  userName,
  companyLogoSrc,
  userIconSrc,
  sx,
}) => {
  // Styled Badge for the top badge (image)
  const TopBadgeStyle = {
    position: "absolute",
    top: size === 40 ? 0 : 0,
    right: size === 40 ? 0 : -1,
    width: size === 40 ? "10px" : "8px",
    height: size === 40 ? "10px" : "8px",
    boxSizing: "border-box",
  };

  // Custom Box for the bottom badge (color)
  const BottomBadge = styled(Box)(({ theme }) => ({
    position: "absolute",
    bottom: size === 40 ? 0 : 0.5,
    right: size === 40 ? 0 : 1,
    width: size === 40 ? "10px" : "5px",
    height: size === 40 ? "10px" : "5px",
    boxSizing: "border-box",
    borderRadius: "50%",
    backgroundColor: isActive ? "#008D4C" : "#FFB100",
  }));

  return (
    <Stack
      sx={{
        position: "relative",
        width: `${size}px`,
        height: `${size}px`,
        boxSizing: "border-box",
        borderRadius: "50%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <AvatarNormal
        username={userName}
        size={size}
        imgSrc={userIconSrc}
        sx={{
          border: size === 40 ? "2px solid #E9EDF2" : "1px solid #E9EDF2",
          ...sx,
        }}
      />
      {companyLogoSrc && (
        <Box
          component={"img"}
          src={companyLogoSrc}
          alt="Top Badge"
          sx={TopBadgeStyle}
        />
      )}

      {isActive !== undefined && <BottomBadge />}
    </Stack>
  );
};

export default AvatarWithCompany;
