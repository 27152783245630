import React, { useState } from "react";
import { Box, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import g from "../centralComponentStyle.module.css";
import { CorrectIcon } from "../../../../../../../images";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../redux/store";
import { WorkspaceState } from "../../../../../../../redux";

interface ListComponentProps {
  initialItems: string[];
  onSave: (items: string[]) => void;
  type: "bulleted" | "numbered"; // Specify the type of list
}

const ListComponent: React.FC<ListComponentProps> = ({
  initialItems,
  onSave,
  type,
}) => {
  const workspaceState = useSelector(
    (state: RootState) => state.workspace as WorkspaceState
  );
  const { lockPage } = workspaceState || {};

  const [items, setItems] = useState<string[]>(initialItems);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const handleItemChange = (index: number, newValue: string) => {
    const updatedItems = [...items];
    updatedItems[index] = newValue;
    setItems(updatedItems);
  };

  const handleAddItem = () => {
    setItems([...items, ""]);
  };

  const handleKeyPress = (event: React.KeyboardEvent, index: number) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAddItem();
    }
  };

  const handleSave = () => {
    onSave(items.filter((item) => item.trim() !== "")); // Only save non-empty items
    setIsEditing(false);
  };

  const handleCancel = () => {
    setItems(initialItems);
    setIsEditing(false);
  };

  return (
    <Box className={g.listComponent}>
      {isEditing ? (
        <Box className={g.editorContainer}>
          {items.map((item, index) => (
            <Box key={index} className={g.listItem}>
              <Typography
                sx={{
                  fontFamily: "Source Serif Pro",
                  marginRight: "5px",
                  fontSize: "20px",
                }}
              >
                {index + 1}
              </Typography>

              <TextField
                value={item}
                onChange={(e) => handleItemChange(index, e.target.value)}
                onKeyPress={(e) => handleKeyPress(e, index)}
                variant="outlined"
                className={g.listTextField}
                multiline
              />
            </Box>
          ))}
          <Box className={g.newItemContainer}>
            <Tooltip title="Add Item">
              <IconButton color="primary" onClick={handleAddItem}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className={g.buttonContainer}>
            <Tooltip title="Save">
              <IconButton
                className={g.iconButtonWC}
                color="primary"
                onClick={handleSave}
              >
                <CorrectIcon w={19} color="green" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel">
              <IconButton
                className={g.iconButtonWC}
                color="error"
                onClick={handleCancel}
              >
                <CancelIcon sx={{ fontSize: 19 }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ) : (
        <Box className={g.listDisplay}>
          {items.length === 0 ? (
            <p>No List -- Edit</p>
          ) : type === "bulleted" ? (
            <ul style={{ paddingLeft: "15px" }}>
              {items.map((item, index) => (
                <li
                  style={{ margin: "5px 0px" }}
                  key={index}
                  onClick={() => setIsEditing(true)}
                >
                  {item}
                </li>
              ))}
            </ul>
          ) : (
            <ol style={{ paddingLeft: "15px" }}>
              {items.map((item, index) => (
                <li
                  style={{ margin: "5px 0px" }}
                  key={index}
                  onClick={() => setIsEditing(true)}
                >
                  {item}
                </li>
              ))}
            </ol>
          )}

          {!lockPage && (
            <Tooltip title="Edit">
              <IconButton
                color="primary"
                className={g.editButton}
                onClick={() => setIsEditing(true)}
              >
                <EditIcon sx={{ fontSize: 19 }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ListComponent;
