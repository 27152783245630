import React, { FC } from "react";
import g from "./CommonDragOption.module.css";

import {
  Box,
  IconButton,
  InputBase,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import {
  AllDirArrowsIcon,
  DragAndDropIcon,
  StoreIcon,
} from "../../../../../../../images";
import { SearchBar } from "../SearchBar";
import {
  allowDrop,
  handleDragStart,
  handleDrop,
  handleKeyPress,
  MenuItemData,
} from "../../../DropDownButton";
import { addColumnOption } from "./Options";
import { CheckboxMedium } from "../../../../../../common";

export interface CommonDragOptionProps {
  showCheckBox?: boolean;
  cardLable: string;
  menuItems: MenuItemData[];
  setMenuItems: React.Dispatch<React.SetStateAction<MenuItemData[]>>;
  ctype: string;
}
const CommonDragOption: FC<CommonDragOptionProps> = ({
  menuItems = addColumnOption,
  setMenuItems,
  ctype = "addColumn",
  showCheckBox = false,
  cardLable,
}) => {
  return (
    <Box className={g.CommonDragOptionbox}>
      {/* Common Component for arrow and label --start */}
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        gap={1}
        p={"10px 10px 5px 10px"}
        mb={"5px"}
      >
        <IconButton>
          <AllDirArrowsIcon w={9} direction="left" />
        </IconButton>
        <Typography className={g.comOptionLabeltext}>{cardLable}</Typography>
      </Stack>
      {/* Common Component for arrow and label --end */}

      {/* search box to filter option --start */}
      <Stack p={"0 10px 5px 10px"}>
        <SearchBar />
      </Stack>
      {/* search box to filter option --end */}

      {/* Menu Option --start */}

      <Stack
        minHeight={250}
        maxHeight={280}
        sx={{
          overflowX: "hidden",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none", // for Internet Explorer and Edge
          scrollbarWidth: "none", // for Firefox
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem sx={{ width: "100%" }} key={index}>
            <div
              data-index={index}
              draggable={item.draggable}
              onDragStart={(e) => handleDragStart(e, index)}
              onDrop={(e) => handleDrop(e, menuItems, setMenuItems)}
              onDragOver={allowDrop}
              style={{
                width: "100%",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  gap={1}
                  width={"100%"}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    gap={0.5}
                    width={"100%"}
                  >
                    {item.draggable && (
                      <IconButton
                        style={{ cursor: item.draggable ? "move" : "default" }}
                      >
                        {item.draggable && <DragAndDropIcon w={15} />}
                      </IconButton>
                    )}
                    {item.icon ? item.icon : <StoreIcon />}
                    {!item.inputType ? (
                      <Typography
                        className={g.boxDropDownOption}
                        children={item.label}
                      />
                    ) : (
                      <InputBase
                        type="text"
                        placeholder={item.label}
                        onKeyPress={(e: any) =>
                          handleKeyPress(e, e.target.value, setMenuItems)
                        }
                      />
                    )}
                  </Stack>
                </Stack>
                {showCheckBox && !item.inputType && <CheckboxMedium />}
              </Stack>
            </div>
          </MenuItem>
        ))}
      </Stack>

      {/* Menu Option --end */}
    </Box>
  );
};

export default CommonDragOption;
