import { PayloadAction } from "@reduxjs/toolkit";
import { FileUploadBlobState } from "../FileUploadManagerType";

export const updloadFile__Failure = (
    state: FileUploadBlobState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.dataUrl = null;
};
