import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  UpdatableFields,
  WorkspaceComponentsData,
  WorkspaceState,
} from "./workspaceTypes";
import {
  /* Create workspace */
  createWorkspace__Request,
  createWorkspace__Failure,
  createWorkspace__Success,
  /* Get all workspaces */
  getAllWorkspace__Request,
  getAllWorkspace__Failure,
  getAllWorkspace__Success,
  /* Get one workspace */
  getOneWorkspace__Request,
  getOneWorkspace__Failure,
  getOneWorkspace__Success,
  /* Update workspace */
  updateWorkspace__Request,
  updateWorkspace__Failure,
  updateWorkspace__Success,
  /* Delete workspace */
  deleteWorkspace__Request,
  deleteWorkspace__Failure,
  deleteWorkspace__Success,
  /* Add workspace content */
  addWorkspaceContent__Request,
  addWorkspaceContent__Failure,
  addWorkspaceContent__Success,
  /* Reorder workspace content */
  reOrderWorkspaceContent__Request,
  reOrderWorkspaceContent__Failure,
  reOrderWorkspaceContent__Success,
  /* Update workspace content */
  updateWorkspaceContent__Request,
  updateWorkspaceContent__Failure,
  updateWorkspaceContent__Success,
  /* Delete workspace content */
  deleteWorkspaceContent__Request,
  deleteWorkspaceContent__Failure,
  deleteWorkspaceContent__Success,
  /* Add workspace member */
  addWorkspaceMember__Request,
  addWorkspaceMember__Failure,
  addWorkspaceMember__Success,
  /* Get all workspace members */
  getAllWorkspaceMember__Request,
  getAllWorkspaceMember__Failure,
  getAllWorkspaceMember__Success,
  /* Update workspace member */
  updateWorkspaceMember__Request,
  updateWorkspaceMember__Failure,
  updateWorkspaceMember__Success,
  /* Delete workspace member */
  deleteWorkspaceMember__Request,
  deleteWorkspaceMember__Failure,
  deleteWorkspaceMember__Success,
  /* Add workspace custom field */
  addWorkspaceCustomField__Request,
  addWorkspaceCustomField__Failure,
  addWorkspaceCustomField__Success,
  /* Update workspace custom field */
  updateWorkspaceCustomField__Request,
  updateWorkspaceCustomField__Failure,
  updateWorkspaceCustomField__Success,
  /* Delete workspace custom field */
  deleteWorkspaceCustomField__Request,
  deleteWorkspaceCustomField__Failure,
  deleteWorkspaceCustomField__Success,
  /* Add workspace tag */
  addWorkspaceTag__Request,
  addWorkspaceTag__Failure,
  addWorkspaceTag__Success,
  /* Update workspace tag */
  updateWorkspaceTag__Request,
  updateWorkspaceTag__Failure,
  updateWorkspaceTag__Success,
  /* Delete workspace tag */
  deleteWorkspaceTag__Request,
  deleteWorkspaceTag__Failure,
  deleteWorkspaceTag__Success,
  /* Add workspace template */
  addWorkspaceTemplate__Request,
  addWorkspaceTemplate__Failure,
  addWorkspaceTemplate__Success,
  /* Apply workspace template */
  applyWorkspaceTemplate__Request,
  applyWorkspaceTemplate__Failure,
  applyWorkspaceTemplate__Success,
  /* Add workspace integration */
  addWorkspaceIntegration__Request,
  addWorkspaceIntegration__Failure,
  addWorkspaceIntegration__Success,
  /* Update workspace integration */
  updateWorkspaceIntegration__Request,
  updateWorkspaceIntegration__Failure,
  updateWorkspaceIntegration__Success,
  /* Delete workspace integration */
  deleteWorkspaceIntegration__Request,
  deleteWorkspaceIntegration__Failure,
  deleteWorkspaceIntegration__Success,
  /* Add workspace activity */
  addWorkspaceActivity__Request,
  addWorkspaceActivity__Failure,
  addWorkspaceActivity__Success,
  /* Get all workspace activities */
  getAllWorkspaceActivity__Request,
  getAllWorkspaceActivity__Failure,
  getAllWorkspaceActivity__Success,
  /* Update workspace column */
  updateWorkspaceColumn__Request,
  updateWorkspaceColumn__Failure,
  updateWorkspaceColumn__Success,
  /* Add workspace connection */
  addWorkspaceConnection__Request,
  addWorkspaceConnection__Failure,
  addWorkspaceConnection__Success,
  /* Add workspace reminder */
  addWorkspaceReminder__Request,
  addWorkspaceReminder__Failure,
  addWorkspaceReminder__Success,
  /* Add workspace video report */
  addWorkspaceVideoReport__Request,
  addWorkspaceVideoReport__Failure,
  addWorkspaceVideoReport__Success,
  /* Add workspace file */
  addWorkspaceFile__Request,
  addWorkspaceFile__Failure,
  addWorkspaceFile__Success,
  /* Add workspace media */
  addWorkspaceMedia__Request,
  addWorkspaceMedia__Failure,
  addWorkspaceMedia__Success,
  /* Add workspace bookmark */
  addWorkspaceBookMark__Request,
  addWorkspaceBookMark__Failure,
  addWorkspaceBookMark__Success,
  /* Add workspace table of content */
  addWorkspaceTableOfContent__Request,
  addWorkspaceTableOfContent__Failure,
  addWorkspaceTableOfContent__Success,
  /* Add workspace equation */
  addWorkspaceEquation__Request,
  addWorkspaceEquation__Failure,
  addWorkspaceEquation__Success,
  /* Add workspace button */
  addWorkspaceButton__Request,
  addWorkspaceButton__Failure,
  addWorkspaceButton__Success,
  /* Add workspace sync element */
  addWorkspaceSyncElement__Request,
  addWorkspaceSyncElement__Failure,
  addWorkspaceSyncElement__Success,
  /* Add workspace heading toggle */
  addWorkspaceHeadingToggle__Request,
  addWorkspaceHeadingToggle__Failure,
  addWorkspaceHeadingToggle__Success,
  /* Add workspace code block */
  addWorkspaceCodeBlock__Request,
  addWorkspaceCodeBlock__Failure,
  addWorkspaceCodeBlock__Success,
  /* Delete workspace in bulk */
  deleteWorkspaceInBulk__Request,
  deleteWorkspaceInBulk__Failure,
  deleteWorkspaceInBulk__Success,
  /* add grid workspace */
  addGrid__Request,
  addGrid__Failure,
  addGrid__Success,

  /* get all grids */
  getAllGrids__Request,
  getAllGrids__Failure,
  getAllGrids__Success,

  /* get one grid */
  getOneGrid__Request,
  getOneGrid__Failure,
  getOneGrid__Success,

  /* update one grid */
  updateOneGrid__Request,
  updateOneGrid__Failure,
  updateOneGrid__Success,

  /* delete grid */
  deleteGrid__Request,
  deleteGrid__Failure,
  deleteGrid__Success,

  /* add sheet */
  addSheet__Request,
  addSheet__Failure,
  addSheet__Success,

  /* update sheet */
  updateSheet__Request,
  updateSheet__Failure,
  updateSheet__Success,

  /* delete sheet */
  deleteSheet__Request,
  deleteSheet__Failure,
  deleteSheet__Success,

  /* reorder sheets */
  reorderSheets__Request,
  reorderSheets__Failure,
  reorderSheets__Success,

  /* update cell */
  updateCell__Request,
  updateCell__Failure,
  updateCell__Success,

  /* Add Component actions */
  addComponent__Request,
  addComponent__Failure,
  addComponent__Success,

  /* Get All Components actions */
  getAllComponents__Request,
  getAllComponents__Failure,
  getAllComponents__Success,

  /* Get One Component actions */
  getOneComponent__Request,
  getOneComponent__Failure,
  getOneComponent__Success,

  /* Update One Component actions */
  updateOneComponent__Request,
  updateOneComponent__Failure,
  updateOneComponent__Success,

  /* Delete One Component actions */
  deleteOneComponent__Request,
  deleteOneComponent__Failure,
  deleteOneComponent__Success,

  /* Delete Bulk Components actions */
  deleteBulkComponents__Request,
  deleteBulkComponents__Failure,
  deleteBulkComponents__Success,

  /* Create Task */
  createTask__Request,
  createTask__Failure,
  createTask__Success,

  /* Get One Task */
  getOneTask__Request,
  getOneTask__Failure,
  getOneTask__Success,

  /* Update One Task */
  updateOneTask__Request,
  updateOneTask__Failure,
  updateOneTask__Success,

  /* Delete One Task */
  deleteOneTask__Request,
  deleteOneTask__Failure,
  deleteOneTask__Success,

  /* Get All Tasks */
  getAllTasks__Request,
  getAllTasks__Failure,
  getAllTasks__Success,

  /* Create Sub Task */
  createSubTask__Request,
  createSubTask__Failure,
  createSubTask__Success,

  /* Get All Sub Tasks */
  getAllSubTasks__Request,
  getAllSubTasks__Failure,
  getAllSubTasks__Success,

  /* Delete Bulk Tasks */
  deleteBulkTasks__Request,
  deleteBulkTasks__Failure,
  deleteBulkTasks__Success,

  /* Shared Workspace */
  getAllSharedWorkspace__Request,
  getAllSharedWorkspace__Failure,
  getAllSharedWorkspace__Success,

  /* Add Whiteboard */
  AddWhiteboard__Request,
  AddWhiteboard__Failure,
  AddWhiteboard__Success,

  /* Get All Whiteboards */
  GetAllWhiteboards__Request,
  GetAllWhiteboards__Failure,
  GetAllWhiteboards__Success,

  /* Get One Whiteboard */
  GetOneWhiteboard__Request,
  GetOneWhiteboard__Failure,
  GetOneWhiteboard__Success,

  /* Update Whiteboard */
  UpdateWhiteboard__Request,
  UpdateWhiteboard__Failure,
  UpdateWhiteboard__Success,

  /* Delete Whiteboard */
  DeleteWhiteboard__Request,
  DeleteWhiteboard__Failure,
  DeleteWhiteboard__Success,

  /* Add Object */
  AddObject__Request,
  AddObject__Failure,
  AddObject__Success,

  /* Update Object */
  UpdateObject__Request,
  UpdateObject__Failure,
  UpdateObject__Success,

  /* Delete Object */
  DeleteObject__Request,
  DeleteObject__Failure,
  DeleteObject__Success,

  /* Add Collaborator */
  AddCollaborator__Request,
  AddCollaborator__Failure,
  AddCollaborator__Success,

  /* Get All Collaborators */
  GetAllCollaborators__Request,
  GetAllCollaborators__Failure,
  GetAllCollaborators__Success,

  /* Update Collaborator */
  UpdateCollaborator__Request,
  UpdateCollaborator__Failure,
  UpdateCollaborator__Success,

  /* Delete Collaborator */
  DeleteCollaborator__Request,
  DeleteCollaborator__Failure,
  DeleteCollaborator__Success,

  /* Create Version */
  CreateVersion__Request,
  CreateVersion__Failure,
  CreateVersion__Success,

  /* Get All Versions */
  GetAllVersions__Request,
  GetAllVersions__Failure,
  GetAllVersions__Success,

  /* Restore Version */
  RestoreVersion__Request,
  RestoreVersion__Failure,
  RestoreVersion__Success,

  /* Add Docs */
  addDocs__Request,
  addDocs__Failure,
  addDocs__Success,

  /* Get All Docs */
  getAllDocs__Request,
  getAllDocs__Failure,
  getAllDocs__Success,

  /* Get One Doc */
  getOneDoc__Request,
  getOneDoc__Failure,
  getOneDoc__Success,

  /* Update One Doc */
  updateOneDoc__Request,
  updateOneDoc__Failure,
  updateOneDoc__Success,

  // Add Wiki
  AddWiki__Request,
  AddWiki__Failure,
  AddWiki__Success,

  // Get All Wikis
  GetAllWikis__Request,
  GetAllWikis__Failure,
  GetAllWikis__Success,

  // Get One Wiki
  GetOneWiki__Request,
  GetOneWiki__Failure,
  GetOneWiki__Success,

  // Add Wiki Page
  AddWikiPage__Request,
  AddWikiPage__Failure,
  AddWikiPage__Success,

  // Get Wiki Page
  GetWikiPage__Request,
  GetWikiPage__Failure,
  GetWikiPage__Success,

  // Update Wiki Page
  UpdateWikiPage__Request,
  UpdateWikiPage__Failure,
  UpdateWikiPage__Success,

  // Add Component View
  AddComponentView__Request,
  AddComponentView__Failure,
  AddComponentView__Success,

  /* and so on... */
} from "./WorkspaceSliceAction";
import {
  // getLocalStorageValue,
  setLocalStorageValue,
} from "../..";

/*
 * ==============================================
 * Define the initial state for the workspace slice
 * ==============================================
 */

// workspaceSlice.ts

const initialState: WorkspaceState = {
  viewMode: "square",
  workspaceList: {
    workspaceList: [],
    api_call_timeStamp: new Date().toISOString(),
    is_api_called: false,
    WorkspaceListError: null,
  },
  sharedWorkspaceList: {
    workspaceSharedList: [],
    WorkspaceSharedListError: null,
  },
  insideWorkspaceList: [],
  insideWorkspaceItem: [],
  error: null,
  data: null,
  memberOfWorkspace: null,
  workspaceContentUpdated: null,
  // lockPage: getLocalStorageValue("lockPage", false),
  lockPage: false,
  insideWorkspaceComponentData: null,
  componentsData: {} as WorkspaceComponentsData, // Initialize as empty object with type assertion
};

/*
 * ==============================================
 * Create a slice for workspace logic
 * ==============================================
 */
const workspaceSlice = createSlice({
  name: "workspace", // Name of the slice
  initialState, // Initial state of the slice
  reducers: {
    /* Create workspace */
    createWorkspace__Request,
    createWorkspace__Failure,
    createWorkspace__Success,
    /* Get all workspaces */
    getAllWorkspace__Request,
    getAllWorkspace__Failure,
    getAllWorkspace__Success,
    /* Get one workspace */
    getOneWorkspace__Request,
    getOneWorkspace__Failure,
    getOneWorkspace__Success,
    /* Update workspace */
    updateWorkspace__Request,
    updateWorkspace__Failure,
    updateWorkspace__Success,
    /* Delete workspace */
    deleteWorkspace__Request,
    deleteWorkspace__Failure,
    deleteWorkspace__Success,
    /* Add workspace content */
    addWorkspaceContent__Request,
    addWorkspaceContent__Failure,
    addWorkspaceContent__Success,
    /* Reorder workspace content */
    reOrderWorkspaceContent__Request,
    reOrderWorkspaceContent__Failure,
    reOrderWorkspaceContent__Success,
    /* Update workspace content */
    updateWorkspaceContent__Request,
    updateWorkspaceContent__Failure,
    updateWorkspaceContent__Success,
    /* Delete workspace content */
    deleteWorkspaceContent__Request,
    deleteWorkspaceContent__Failure,
    deleteWorkspaceContent__Success,
    /* Add workspace member */
    addWorkspaceMember__Request,
    addWorkspaceMember__Failure,
    addWorkspaceMember__Success,
    /* Get all workspace members */
    getAllWorkspaceMember__Request,
    getAllWorkspaceMember__Failure,
    getAllWorkspaceMember__Success,
    /* Update workspace member */
    updateWorkspaceMember__Request,
    updateWorkspaceMember__Failure,
    updateWorkspaceMember__Success,
    /* Delete workspace member */
    deleteWorkspaceMember__Request,
    deleteWorkspaceMember__Failure,
    deleteWorkspaceMember__Success,
    /* Add workspace custom field */
    addWorkspaceCustomField__Request,
    addWorkspaceCustomField__Failure,
    addWorkspaceCustomField__Success,
    /* Update workspace custom field */
    updateWorkspaceCustomField__Request,
    updateWorkspaceCustomField__Failure,
    updateWorkspaceCustomField__Success,
    /* Delete workspace custom field */
    deleteWorkspaceCustomField__Request,
    deleteWorkspaceCustomField__Failure,
    deleteWorkspaceCustomField__Success,
    /* Add workspace tag */
    addWorkspaceTag__Request,
    addWorkspaceTag__Failure,
    addWorkspaceTag__Success,
    /* Update workspace tag */
    updateWorkspaceTag__Request,
    updateWorkspaceTag__Failure,
    updateWorkspaceTag__Success,
    /* Delete workspace tag */
    deleteWorkspaceTag__Request,
    deleteWorkspaceTag__Failure,
    deleteWorkspaceTag__Success,
    /* Add workspace template */
    addWorkspaceTemplate__Request,
    addWorkspaceTemplate__Failure,
    addWorkspaceTemplate__Success,
    /* Apply workspace template */
    applyWorkspaceTemplate__Request,
    applyWorkspaceTemplate__Failure,
    applyWorkspaceTemplate__Success,
    /* Add workspace integration */
    addWorkspaceIntegration__Request,
    addWorkspaceIntegration__Failure,
    addWorkspaceIntegration__Success,
    /* Update workspace integration */
    updateWorkspaceIntegration__Request,
    updateWorkspaceIntegration__Failure,
    updateWorkspaceIntegration__Success,
    /* Delete workspace integration */
    deleteWorkspaceIntegration__Request,
    deleteWorkspaceIntegration__Failure,
    deleteWorkspaceIntegration__Success,
    /* Add workspace activity */
    addWorkspaceActivity__Request,
    addWorkspaceActivity__Failure,
    addWorkspaceActivity__Success,
    /* Get all workspace activities */
    getAllWorkspaceActivity__Request,
    getAllWorkspaceActivity__Failure,
    getAllWorkspaceActivity__Success,
    /* Update workspace column */
    updateWorkspaceColumn__Request,
    updateWorkspaceColumn__Failure,
    updateWorkspaceColumn__Success,
    /* Add workspace connection */
    addWorkspaceConnection__Request,
    addWorkspaceConnection__Failure,
    addWorkspaceConnection__Success,
    /* Add workspace reminder */
    addWorkspaceReminder__Request,
    addWorkspaceReminder__Failure,
    addWorkspaceReminder__Success,
    /* Add workspace video report */
    addWorkspaceVideoReport__Request,
    addWorkspaceVideoReport__Failure,
    addWorkspaceVideoReport__Success,
    /* Add workspace file */
    addWorkspaceFile__Request,
    addWorkspaceFile__Failure,
    addWorkspaceFile__Success,
    /* Add workspace media */
    addWorkspaceMedia__Request,
    addWorkspaceMedia__Failure,
    addWorkspaceMedia__Success,
    /* Add workspace bookmark */
    addWorkspaceBookMark__Request,
    addWorkspaceBookMark__Failure,
    addWorkspaceBookMark__Success,
    /* Add workspace table of content */
    addWorkspaceTableOfContent__Request,
    addWorkspaceTableOfContent__Failure,
    addWorkspaceTableOfContent__Success,
    /* Add workspace equation */
    addWorkspaceEquation__Request,
    addWorkspaceEquation__Failure,
    addWorkspaceEquation__Success,
    /* Add workspace button */
    addWorkspaceButton__Request,
    addWorkspaceButton__Failure,
    addWorkspaceButton__Success,
    /* Add workspace sync element */
    addWorkspaceSyncElement__Request,
    addWorkspaceSyncElement__Failure,
    addWorkspaceSyncElement__Success,
    /* Add workspace heading toggle */
    addWorkspaceHeadingToggle__Request,
    addWorkspaceHeadingToggle__Failure,
    addWorkspaceHeadingToggle__Success,
    /* Add workspace code block */
    addWorkspaceCodeBlock__Request,
    addWorkspaceCodeBlock__Failure,
    addWorkspaceCodeBlock__Success,
    /* Delete workspace in bulk */
    deleteWorkspaceInBulk__Request,
    deleteWorkspaceInBulk__Failure,
    deleteWorkspaceInBulk__Success,
    /* add grid workspace */
    addGrid__Request,
    addGrid__Failure,
    addGrid__Success,

    /* get all grids */
    getAllGrids__Request,
    getAllGrids__Failure,
    getAllGrids__Success,

    /* get one grid */
    getOneGrid__Request,
    getOneGrid__Failure,
    getOneGrid__Success,

    /* update one grid */
    updateOneGrid__Request,
    updateOneGrid__Failure,
    updateOneGrid__Success,

    /* delete grid */
    deleteGrid__Request,
    deleteGrid__Failure,
    deleteGrid__Success,

    /* add sheet */
    addSheet__Request,
    addSheet__Failure,
    addSheet__Success,

    /* update sheet */
    updateSheet__Request,
    updateSheet__Failure,
    updateSheet__Success,

    /* delete sheet */
    deleteSheet__Request,
    deleteSheet__Failure,
    deleteSheet__Success,

    /* reorder sheets */
    reorderSheets__Request,
    reorderSheets__Failure,
    reorderSheets__Success,

    /* update cell */
    updateCell__Request,
    updateCell__Failure,
    updateCell__Success,

    /* Add Component actions */
    addComponent__Request,
    addComponent__Failure,
    addComponent__Success,

    /* Get All Components actions */
    getAllComponents__Request,
    getAllComponents__Failure,
    getAllComponents__Success,

    /* Get One Component actions */
    getOneComponent__Request,
    getOneComponent__Failure,
    getOneComponent__Success,

    /* Update One Component actions */
    updateOneComponent__Request,
    updateOneComponent__Failure,
    updateOneComponent__Success,

    /* Delete One Component actions */
    deleteOneComponent__Request,
    deleteOneComponent__Failure,
    deleteOneComponent__Success,

    /* Delete Bulk Components actions */
    deleteBulkComponents__Request,
    deleteBulkComponents__Failure,
    deleteBulkComponents__Success,

    /* Create Task */
    createTask__Request,
    createTask__Failure,
    createTask__Success,

    /* Get One Task */
    getOneTask__Request,
    getOneTask__Failure,
    getOneTask__Success,

    /* Update One Task */
    updateOneTask__Request,
    updateOneTask__Failure,
    updateOneTask__Success,

    /* Delete One Task */
    deleteOneTask__Request,
    deleteOneTask__Failure,
    deleteOneTask__Success,

    /* Get All Tasks */
    getAllTasks__Request,
    getAllTasks__Failure,
    getAllTasks__Success,

    /* Create Sub Task */
    createSubTask__Request,
    createSubTask__Failure,
    createSubTask__Success,

    /* Get All Sub Tasks */
    getAllSubTasks__Request,
    getAllSubTasks__Failure,
    getAllSubTasks__Success,

    /* Delete Bulk Tasks */
    deleteBulkTasks__Request,
    deleteBulkTasks__Failure,
    deleteBulkTasks__Success,

    /* Shared Workspace List */
    getAllSharedWorkspace__Request,
    getAllSharedWorkspace__Failure,
    getAllSharedWorkspace__Success,

    AddWhiteboard__Request,
    AddWhiteboard__Failure,
    AddWhiteboard__Success,

    /* Get All Whiteboards */
    GetAllWhiteboards__Request,
    GetAllWhiteboards__Failure,
    GetAllWhiteboards__Success,

    /* Get One Whiteboard */
    GetOneWhiteboard__Request,
    GetOneWhiteboard__Failure,
    GetOneWhiteboard__Success,

    /* Update Whiteboard */
    UpdateWhiteboard__Request,
    UpdateWhiteboard__Failure,
    UpdateWhiteboard__Success,

    /* Delete Whiteboard */
    DeleteWhiteboard__Request,
    DeleteWhiteboard__Failure,
    DeleteWhiteboard__Success,

    /* Add Object */
    AddObject__Request,
    AddObject__Failure,
    AddObject__Success,

    /* Update Object */
    UpdateObject__Request,
    UpdateObject__Failure,
    UpdateObject__Success,

    /* Delete Object */
    DeleteObject__Request,
    DeleteObject__Failure,
    DeleteObject__Success,

    /* Add Collaborator */
    AddCollaborator__Request,
    AddCollaborator__Failure,
    AddCollaborator__Success,

    /* Get All Collaborators */
    GetAllCollaborators__Request,
    GetAllCollaborators__Failure,
    GetAllCollaborators__Success,

    /* Update Collaborator */
    UpdateCollaborator__Request,
    UpdateCollaborator__Failure,
    UpdateCollaborator__Success,

    /* Delete Collaborator */
    DeleteCollaborator__Request,
    DeleteCollaborator__Failure,
    DeleteCollaborator__Success,

    /* Create Version */
    CreateVersion__Request,
    CreateVersion__Failure,
    CreateVersion__Success,

    /* Get All Versions */
    GetAllVersions__Request,
    GetAllVersions__Failure,
    GetAllVersions__Success,

    /* Restore Version */
    RestoreVersion__Request,
    RestoreVersion__Failure,
    RestoreVersion__Success,

    /* Add Docs */
    addDocs__Request,
    addDocs__Failure,
    addDocs__Success,

    /* Get All Docs */
    getAllDocs__Request,
    getAllDocs__Failure,
    getAllDocs__Success,

    /* Get One Doc */
    getOneDoc__Request,
    getOneDoc__Failure,
    getOneDoc__Success,

    /* Update One Doc */
    updateOneDoc__Request,
    updateOneDoc__Failure,
    updateOneDoc__Success,

    // Add Wiki
    AddWiki__Request,
    AddWiki__Failure,
    AddWiki__Success,

    // Get All Wikis
    GetAllWikis__Request,
    GetAllWikis__Failure,
    GetAllWikis__Success,

    // Get One Wiki
    GetOneWiki__Request,
    GetOneWiki__Failure,
    GetOneWiki__Success,

    // Add Wiki Page
    AddWikiPage__Request,
    AddWikiPage__Failure,
    AddWikiPage__Success,

    // Get Wiki Page
    GetWikiPage__Request,
    GetWikiPage__Failure,
    GetWikiPage__Success,

    // Update Wiki Page
    UpdateWikiPage__Request,
    UpdateWikiPage__Failure,
    UpdateWikiPage__Success,

    // Add Component View
    AddComponentView__Request,
    AddComponentView__Failure,
    AddComponentView__Success,

    updateField(
      state,
      action: PayloadAction<{ field: UpdatableFields; value: boolean }>
    ) {
      const { field, value } = action.payload;
      state[field] = value as WorkspaceState[UpdatableFields]; // Type assertion here
      setLocalStorageValue(field, value);
    },
  },
});

/*
 * ======================================================
 * Export the actions to be used in dispatch calls
 * ======================================================
 */

export const {
  updateField: updateFieldWorkspaceAction,

  /* Create workspace action */
  createWorkspace__Request: createWorkspace__RequestAction,
  createWorkspace__Failure: createWorkspace__FailureAction,
  createWorkspace__Success: createWorkspace__SuccessAction,

  /* Get all workspaces action */
  getAllWorkspace__Request: getAllWorkspace__RequestAction,
  getAllWorkspace__Failure: getAllWorkspace__FailureAction,
  getAllWorkspace__Success: getAllWorkspace__SuccessAction,

  /* Get one workspace action */
  getOneWorkspace__Request: getOneWorkspace__RequestAction,
  getOneWorkspace__Failure: getOneWorkspace__FailureAction,
  getOneWorkspace__Success: getOneWorkspace__SuccessAction,

  /* Update workspace action */
  updateWorkspace__Request: updateWorkspace__RequestAction,
  updateWorkspace__Failure: updateWorkspace__FailureAction,
  updateWorkspace__Success: updateWorkspace__SuccessAction,

  /* Delete workspace action */
  deleteWorkspace__Request: deleteWorkspace__RequestAction,
  deleteWorkspace__Failure: deleteWorkspace__FailureAction,
  deleteWorkspace__Success: deleteWorkspace__SuccessAction,

  /* Add workspace content action */
  addWorkspaceContent__Request: addWorkspaceContent__RequestAction,
  addWorkspaceContent__Failure: addWorkspaceContent__FailureAction,
  addWorkspaceContent__Success: addWorkspaceContent__SuccessAction,

  /* Reorder workspace content action */
  reOrderWorkspaceContent__Request: reOrderWorkspaceContent__RequestAction,
  reOrderWorkspaceContent__Failure: reOrderWorkspaceContent__FailureAction,
  reOrderWorkspaceContent__Success: reOrderWorkspaceContent__SuccessAction,

  /* Update workspace content action */
  updateWorkspaceContent__Request: updateWorkspaceContent__RequestAction,
  updateWorkspaceContent__Failure: updateWorkspaceContent__FailureAction,
  updateWorkspaceContent__Success: updateWorkspaceContent__SuccessAction,

  /* Delete workspace content action */
  deleteWorkspaceContent__Request: deleteWorkspaceContent__RequestAction,
  deleteWorkspaceContent__Failure: deleteWorkspaceContent__FailureAction,
  deleteWorkspaceContent__Success: deleteWorkspaceContent__SuccessAction,

  /* Add workspace member action */
  addWorkspaceMember__Request: addWorkspaceMember__RequestAction,
  addWorkspaceMember__Failure: addWorkspaceMember__FailureAction,
  addWorkspaceMember__Success: addWorkspaceMember__SuccessAction,

  /* Get all workspace members action */
  getAllWorkspaceMember__Request: getAllWorkspaceMember__RequestAction,
  getAllWorkspaceMember__Failure: getAllWorkspaceMember__FailureAction,
  getAllWorkspaceMember__Success: getAllWorkspaceMember__SuccessAction,

  /* Update workspace member action */
  updateWorkspaceMember__Request: updateWorkspaceMember__RequestAction,
  updateWorkspaceMember__Failure: updateWorkspaceMember__FailureAction,
  updateWorkspaceMember__Success: updateWorkspaceMember__SuccessAction,

  /* Delete workspace member action */
  deleteWorkspaceMember__Request: deleteWorkspaceMember__RequestAction,
  deleteWorkspaceMember__Failure: deleteWorkspaceMember__FailureAction,
  deleteWorkspaceMember__Success: deleteWorkspaceMember__SuccessAction,

  /* Add workspace custom field action */
  addWorkspaceCustomField__Request: addWorkspaceCustomField__RequestAction,
  addWorkspaceCustomField__Failure: addWorkspaceCustomField__FailureAction,
  addWorkspaceCustomField__Success: addWorkspaceCustomField__SuccessAction,

  /* Update workspace custom field action */
  updateWorkspaceCustomField__Request:
  updateWorkspaceCustomField__RequestAction,
  updateWorkspaceCustomField__Failure:
  updateWorkspaceCustomField__FailureAction,
  updateWorkspaceCustomField__Success:
  updateWorkspaceCustomField__SuccessAction,

  /* Delete workspace custom field action */
  deleteWorkspaceCustomField__Request:
  deleteWorkspaceCustomField__RequestAction,
  deleteWorkspaceCustomField__Failure:
  deleteWorkspaceCustomField__FailureAction,
  deleteWorkspaceCustomField__Success:
  deleteWorkspaceCustomField__SuccessAction,

  /* Add workspace tag action */
  addWorkspaceTag__Request: addWorkspaceTag__RequestAction,
  addWorkspaceTag__Failure: addWorkspaceTag__FailureAction,
  addWorkspaceTag__Success: addWorkspaceTag__SuccessAction,

  /* Update workspace tag action */
  updateWorkspaceTag__Request: updateWorkspaceTag__RequestAction,
  updateWorkspaceTag__Failure: updateWorkspaceTag__FailureAction,
  updateWorkspaceTag__Success: updateWorkspaceTag__SuccessAction,

  /* Delete workspace tag action */
  deleteWorkspaceTag__Request: deleteWorkspaceTag__RequestAction,
  deleteWorkspaceTag__Failure: deleteWorkspaceTag__FailureAction,
  deleteWorkspaceTag__Success: deleteWorkspaceTag__SuccessAction,

  /* Add workspace template action */
  addWorkspaceTemplate__Request: addWorkspaceTemplate__RequestAction,
  addWorkspaceTemplate__Failure: addWorkspaceTemplate__FailureAction,
  addWorkspaceTemplate__Success: addWorkspaceTemplate__SuccessAction,

  /* Apply workspace template action */
  applyWorkspaceTemplate__Request: applyWorkspaceTemplate__RequestAction,
  applyWorkspaceTemplate__Failure: applyWorkspaceTemplate__FailureAction,
  applyWorkspaceTemplate__Success: applyWorkspaceTemplate__SuccessAction,

  /* Add workspace integration action */
  addWorkspaceIntegration__Request: addWorkspaceIntegration__RequestAction,
  addWorkspaceIntegration__Failure: addWorkspaceIntegration__FailureAction,
  addWorkspaceIntegration__Success: addWorkspaceIntegration__SuccessAction,

  /* Update workspace integration action */
  updateWorkspaceIntegration__Request:
  updateWorkspaceIntegration__RequestAction,
  updateWorkspaceIntegration__Failure:
  updateWorkspaceIntegration__FailureAction,
  updateWorkspaceIntegration__Success:
  updateWorkspaceIntegration__SuccessAction,

  /* Delete workspace integration action */
  deleteWorkspaceIntegration__Request:
  deleteWorkspaceIntegration__RequestAction,
  deleteWorkspaceIntegration__Failure:
  deleteWorkspaceIntegration__FailureAction,
  deleteWorkspaceIntegration__Success:
  deleteWorkspaceIntegration__SuccessAction,

  /* Add workspace activity action */
  addWorkspaceActivity__Request: addWorkspaceActivity__RequestAction,
  addWorkspaceActivity__Failure: addWorkspaceActivity__FailureAction,
  addWorkspaceActivity__Success: addWorkspaceActivity__SuccessAction,

  /* Get all workspace activities action */
  getAllWorkspaceActivity__Request: getAllWorkspaceActivity__RequestAction,
  getAllWorkspaceActivity__Failure: getAllWorkspaceActivity__FailureAction,
  getAllWorkspaceActivity__Success: getAllWorkspaceActivity__SuccessAction,

  /* Update workspace column action */
  updateWorkspaceColumn__Request: updateWorkspaceColumn__RequestAction,
  updateWorkspaceColumn__Failure: updateWorkspaceColumn__FailureAction,
  updateWorkspaceColumn__Success: updateWorkspaceColumn__SuccessAction,

  /* Add workspace connection action */
  addWorkspaceConnection__Request: addWorkspaceConnection__RequestAction,
  addWorkspaceConnection__Failure: addWorkspaceConnection__FailureAction,
  addWorkspaceConnection__Success: addWorkspaceConnection__SuccessAction,

  /* Add workspace reminder action */
  addWorkspaceReminder__Request: addWorkspaceReminder__RequestAction,
  addWorkspaceReminder__Failure: addWorkspaceReminder__FailureAction,
  addWorkspaceReminder__Success: addWorkspaceReminder__SuccessAction,

  /* Add workspace video report action */
  addWorkspaceVideoReport__Request: addWorkspaceVideoReport__RequestAction,
  addWorkspaceVideoReport__Failure: addWorkspaceVideoReport__FailureAction,
  addWorkspaceVideoReport__Success: addWorkspaceVideoReport__SuccessAction,

  /* Add workspace file action */
  addWorkspaceFile__Request: addWorkspaceFile__RequestAction,
  addWorkspaceFile__Failure: addWorkspaceFile__FailureAction,
  addWorkspaceFile__Success: addWorkspaceFile__SuccessAction,

  /* Add workspace media action */
  addWorkspaceMedia__Request: addWorkspaceMedia__RequestAction,
  addWorkspaceMedia__Failure: addWorkspaceMedia__FailureAction,
  addWorkspaceMedia__Success: addWorkspaceMedia__SuccessAction,

  /* Add workspace bookmark action */
  addWorkspaceBookMark__Request: addWorkspaceBookMark__RequestAction,
  addWorkspaceBookMark__Failure: addWorkspaceBookMark__FailureAction,
  addWorkspaceBookMark__Success: addWorkspaceBookMark__SuccessAction,

  /* Add workspace table of content action */
  addWorkspaceTableOfContent__Request:
  addWorkspaceTableOfContent__RequestAction,
  addWorkspaceTableOfContent__Failure:
  addWorkspaceTableOfContent__FailureAction,
  addWorkspaceTableOfContent__Success:
  addWorkspaceTableOfContent__SuccessAction,

  /* Add workspace equation action */
  addWorkspaceEquation__Request: addWorkspaceEquation__RequestAction,
  addWorkspaceEquation__Failure: addWorkspaceEquation__FailureAction,
  addWorkspaceEquation__Success: addWorkspaceEquation__SuccessAction,

  /* Add workspace button action */
  addWorkspaceButton__Request: addWorkspaceButton__RequestAction,
  addWorkspaceButton__Failure: addWorkspaceButton__FailureAction,
  addWorkspaceButton__Success: addWorkspaceButton__SuccessAction,

  /* Add workspace sync element action */
  addWorkspaceSyncElement__Request: addWorkspaceSyncElement__RequestAction,
  addWorkspaceSyncElement__Failure: addWorkspaceSyncElement__FailureAction,
  addWorkspaceSyncElement__Success: addWorkspaceSyncElement__SuccessAction,

  /* Add workspace heading toggle action */
  addWorkspaceHeadingToggle__Request: addWorkspaceHeadingToggle__RequestAction,
  addWorkspaceHeadingToggle__Failure: addWorkspaceHeadingToggle__FailureAction,
  addWorkspaceHeadingToggle__Success: addWorkspaceHeadingToggle__SuccessAction,

  /* Add workspace code block action */
  addWorkspaceCodeBlock__Request: addWorkspaceCodeBlock__RequestAction,
  addWorkspaceCodeBlock__Failure: addWorkspaceCodeBlock__FailureAction,
  addWorkspaceCodeBlock__Success: addWorkspaceCodeBlock__SuccessAction,

  /* Delete workspace in bulk action */
  deleteWorkspaceInBulk__Request: deleteWorkspaceInBulk__RequestAction,
  deleteWorkspaceInBulk__Failure: deleteWorkspaceInBulk__FailureAction,
  deleteWorkspaceInBulk__Success: deleteWorkspaceInBulk__SuccessAction,

  /* Add Grid workspace action */
  addGrid__Request: addGrid__RequestAction,
  addGrid__Failure: addGrid__FailureAction,
  addGrid__Success: addGrid__SuccessAction,

  /* Get All Grids action */
  getAllGrids__Request: getAllGrids__RequestAction,
  getAllGrids__Failure: getAllGrids__FailureAction,
  getAllGrids__Success: getAllGrids__SuccessAction,

  /* Get One Grid action */
  getOneGrid__Request: getOneGrid__RequestAction,
  getOneGrid__Failure: getOneGrid__FailureAction,
  getOneGrid__Success: getOneGrid__SuccessAction,

  /* Update One Grid action */
  updateOneGrid__Request: updateOneGrid__RequestAction,
  updateOneGrid__Failure: updateOneGrid__FailureAction,
  updateOneGrid__Success: updateOneGrid__SuccessAction,

  /* Delete Grid action */
  deleteGrid__Request: deleteGrid__RequestAction,
  deleteGrid__Failure: deleteGrid__FailureAction,
  deleteGrid__Success: deleteGrid__SuccessAction,

  /* Add Sheet action */
  addSheet__Request: addSheet__RequestAction,
  addSheet__Failure: addSheet__FailureAction,
  addSheet__Success: addSheet__SuccessAction,

  /* Update Sheet action */
  updateSheet__Request: updateSheet__RequestAction,
  updateSheet__Failure: updateSheet__FailureAction,
  updateSheet__Success: updateSheet__SuccessAction,

  /* Delete Sheet action */
  deleteSheet__Request: deleteSheet__RequestAction,
  deleteSheet__Failure: deleteSheet__FailureAction,
  deleteSheet__Success: deleteSheet__SuccessAction,

  /* Reorder Sheets action */
  reorderSheets__Request: reorderSheets__RequestAction,
  reorderSheets__Failure: reorderSheets__FailureAction,
  reorderSheets__Success: reorderSheets__SuccessAction,

  /* Update Cell action */
  updateCell__Request: updateCell__RequestAction,
  updateCell__Failure: updateCell__FailureAction,
  updateCell__Success: updateCell__SuccessAction,

  /* Add Component actions */
  addComponent__Request: addComponent__RequestAction,
  addComponent__Failure: addComponent__FailureAction,
  addComponent__Success: addComponent__SuccessAction,

  /* Get All Components actions */
  getAllComponents__Request: getAllComponents__RequestAction,
  getAllComponents__Failure: getAllComponents__FailureAction,
  getAllComponents__Success: getAllComponents__SuccessAction,

  /* Get One Component actions */
  getOneComponent__Request: getOneComponent__RequestAction,
  getOneComponent__Failure: getOneComponent__FailureAction,
  getOneComponent__Success: getOneComponent__SuccessAction,

  /* Update One Component actions */
  updateOneComponent__Request: updateOneComponent__RequestAction,
  updateOneComponent__Failure: updateOneComponent__FailureAction,
  updateOneComponent__Success: updateOneComponent__SuccessAction,

  /* Delete One Component actions */
  deleteOneComponent__Request: deleteOneComponent__RequestAction,
  deleteOneComponent__Failure: deleteOneComponent__FailureAction,
  deleteOneComponent__Success: deleteOneComponent__SuccessAction,

  /* Delete Bulk Components actions */
  deleteBulkComponents__Request: deleteBulkComponents__RequestAction,
  deleteBulkComponents__Failure: deleteBulkComponents__FailureAction,
  deleteBulkComponents__Success: deleteBulkComponents__SuccessAction,

  /* Create Task */
  createTask__Request: createTask__RequestAction,
  createTask__Failure: createTask__FailureAction,
  createTask__Success: createTask__SuccessAction,

  /* Get One Task */
  getOneTask__Request: getOneTask__RequestAction,
  getOneTask__Failure: getOneTask__FailureAction,
  getOneTask__Success: getOneTask__SuccessAction,

  /* Update One Task */
  updateOneTask__Request: updateOneTask__RequestAction,
  updateOneTask__Failure: updateOneTask__FailureAction,
  updateOneTask__Success: updateOneTask__SuccessAction,

  /* Delete One Task */
  deleteOneTask__Request: deleteOneTask__RequestAction,
  deleteOneTask__Failure: deleteOneTask__FailureAction,
  deleteOneTask__Success: deleteOneTask__SuccessAction,

  /* Get All Tasks */
  getAllTasks__Request: getAllTasks__RequestAction,
  getAllTasks__Failure: getAllTasks__FailureAction,
  getAllTasks__Success: getAllTasks__SuccessAction,

  /* Create Sub Task */
  createSubTask__Request: createSubTask__RequestAction,
  createSubTask__Failure: createSubTask__FailureAction,
  createSubTask__Success: createSubTask__SuccessAction,

  /* Get All Sub Tasks */
  getAllSubTasks__Request: getAllSubTasks__RequestAction,
  getAllSubTasks__Failure: getAllSubTasks__FailureAction,
  getAllSubTasks__Success: getAllSubTasks__SuccessAction,

  /* Delete Bulk Tasks */
  deleteBulkTasks__Request: deleteBulkTasks__RequestAction,
  deleteBulkTasks__Failure: deleteBulkTasks__FailureAction,
  deleteBulkTasks__Success: deleteBulkTasks__SuccessAction,

  /* Shared Workspace List */

  getAllSharedWorkspace__Request: getALllSharedWorkspace__RequestAction,
  getAllSharedWorkspace__Failure: getALllSharedWorkspace__FailureAction,
  getAllSharedWorkspace__Success: getAllSharedWorkspace__SuccessAction,

  /* Add Whiteboard actions */
  AddWhiteboard__Request: AddWhiteboard__RequestAction,
  AddWhiteboard__Failure: AddWhiteboard__FailureAction,
  AddWhiteboard__Success: AddWhiteboard__SuccessAction,

  /* Get All Whiteboards actions */
  GetAllWhiteboards__Request: GetAllWhiteboards__RequestAction,
  GetAllWhiteboards__Failure: GetAllWhiteboards__FailureAction,
  GetAllWhiteboards__Success: GetAllWhiteboards__SuccessAction,

  /* Get One Whiteboard actions */
  GetOneWhiteboard__Request: GetOneWhiteboard__RequestAction,
  GetOneWhiteboard__Failure: GetOneWhiteboard__FailureAction,
  GetOneWhiteboard__Success: GetOneWhiteboard__SuccessAction,

  /* Update Whiteboard actions */
  UpdateWhiteboard__Request: UpdateWhiteboard__RequestAction,
  UpdateWhiteboard__Failure: UpdateWhiteboard__FailureAction,
  UpdateWhiteboard__Success: UpdateWhiteboard__SuccessAction,

  /* Delete Whiteboard actions */
  DeleteWhiteboard__Request: DeleteWhiteboard__RequestAction,
  DeleteWhiteboard__Failure: DeleteWhiteboard__FailureAction,
  DeleteWhiteboard__Success: DeleteWhiteboard__SuccessAction,

  /* Add Object actions */
  AddObject__Request: AddObject__RequestAction,
  AddObject__Failure: AddObject__FailureAction,
  AddObject__Success: AddObject__SuccessAction,

  /* Update Object actions */
  UpdateObject__Request: UpdateObject__RequestAction,
  UpdateObject__Failure: UpdateObject__FailureAction,
  UpdateObject__Success: UpdateObject__SuccessAction,

  /* Delete Object actions */
  DeleteObject__Request: DeleteObject__RequestAction,
  DeleteObject__Failure: DeleteObject__FailureAction,
  DeleteObject__Success: DeleteObject__SuccessAction,

  /* Add Collaborator actions */
  AddCollaborator__Request: AddCollaborator__RequestAction,
  AddCollaborator__Failure: AddCollaborator__FailureAction,
  AddCollaborator__Success: AddCollaborator__SuccessAction,

  /* Get All Collaborators actions */
  GetAllCollaborators__Request: GetAllCollaborators__RequestAction,
  GetAllCollaborators__Failure: GetAllCollaborators__FailureAction,
  GetAllCollaborators__Success: GetAllCollaborators__SuccessAction,

  /* Update Collaborator actions */
  UpdateCollaborator__Request: UpdateCollaborator__RequestAction,
  UpdateCollaborator__Failure: UpdateCollaborator__FailureAction,
  UpdateCollaborator__Success: UpdateCollaborator__SuccessAction,

  /* Delete Collaborator actions */
  DeleteCollaborator__Request: DeleteCollaborator__RequestAction,
  DeleteCollaborator__Failure: DeleteCollaborator__FailureAction,
  DeleteCollaborator__Success: DeleteCollaborator__SuccessAction,

  /* Create Version actions */
  CreateVersion__Request: CreateVersion__RequestAction,
  CreateVersion__Failure: CreateVersion__FailureAction,
  CreateVersion__Success: CreateVersion__SuccessAction,

  /* Get All Versions actions */
  GetAllVersions__Request: GetAllVersions__RequestAction,
  GetAllVersions__Failure: GetAllVersions__FailureAction,
  GetAllVersions__Success: GetAllVersions__SuccessAction,

  /* Restore Version actions */
  RestoreVersion__Request: RestoreVersion__RequestAction,
  RestoreVersion__Failure: RestoreVersion__FailureAction,
  RestoreVersion__Success: RestoreVersion__SuccessAction,

  /* Add Docs actions */
  addDocs__Request: addDocs__RequestAction,
  addDocs__Failure: addDocs__FailureAction,
  addDocs__Success: addDocs__SuccessAction,

  /* Get All Docs actions */
  getAllDocs__Request: getAllDocs__RequestAction,
  getAllDocs__Failure: getAllDocs__FailureAction,
  getAllDocs__Success: getAllDocs__SuccessAction,

  /* Get One Doc actions */
  getOneDoc__Request: getOneDoc__RequestAction,
  getOneDoc__Failure: getOneDoc__FailureAction,
  getOneDoc__Success: getOneDoc__SuccessAction,

  /* Update One Doc */
  updateOneDoc__Request: updateOneDoc__RequestAction,
  updateOneDoc__Failure: updateOneDoc__FailureAction,
  updateOneDoc__Success: updateOneDoc__SuccessAction,

  /* Add Wiki */
  AddWiki__Request: AddWiki__RequestAction,
  AddWiki__Failure: AddWiki__FailureAction,
  AddWiki__Success: AddWiki__SuccessAction,

  /* Get All Wikis */
  GetAllWikis__Request: GetAllWikis__RequestAction,
  GetAllWikis__Failure: GetAllWikis__FailureAction,
  GetAllWikis__Success: GetAllWikis__SuccessAction,

  /* Get One Wiki */
  GetOneWiki__Request: GetOneWiki__RequestAction,
  GetOneWiki__Failure: GetOneWiki__FailureAction,
  GetOneWiki__Success: GetOneWiki__SuccessAction,

  /* Add Wiki Page */
  AddWikiPage__Request: AddWikiPage__RequestAction,
  AddWikiPage__Failure: AddWikiPage__FailureAction,
  AddWikiPage__Success: AddWikiPage__SuccessAction,

  /* Get Wiki Page */
  GetWikiPage__Request: GetWikiPage__RequestAction,
  GetWikiPage__Failure: GetWikiPage__FailureAction,
  GetWikiPage__Success: GetWikiPage__SuccessAction,

  /* Update Wiki Page */
  UpdateWikiPage__Request: UpdateWikiPage__RequestAction,
  UpdateWikiPage__Failure: UpdateWikiPage__FailureAction,
  UpdateWikiPage__Success: UpdateWikiPage__SuccessAction,

  /* Add Component View */
  AddComponentView__Request: AddComponentView__RequestAction,
  AddComponentView__Failure: AddComponentView__FailureAction,
  AddComponentView__Success: AddComponentView__SuccessAction,

  /* and so on... */
} = workspaceSlice.actions;

export default workspaceSlice.reducer;
