import { PlusIcon, StoreIcon } from "../../../../../../../images";
import { MenuItemData } from "../../../DropDownButton";

export const addColumnOption: MenuItemData[] = [
  {
    icon: <StoreIcon />,
    label: "Data ID number",
    draggable: true,
  },
  {
    icon: <StoreIcon />,
    label: "Domain",
    draggable: true,
  },
  {
    icon: <StoreIcon />,
    label: "Full name",
    draggable: true,
  },
  {
    icon: <StoreIcon />,
    label: "Description",
    draggable: true,
  },
  {
    icon: <StoreIcon />,
    label: "Team",
    draggable: true,
  },
  {
    icon: <StoreIcon />,
    label: "Categories",
    draggable: true,
  },
  {
    icon: <StoreIcon />,
    label: "Primary Location",
    draggable: true,
  },
  {
    icon: <StoreIcon />,
    label: "CrunchBase",
    draggable: true,
  },
  {
    icon: <StoreIcon />,
    label: "Facebook",
    draggable: true,
  },
  {
    icon: <PlusIcon />,
    label: "Create a new type",
    draggable: false,
    inputType: true,
  },
];
