// HugeCreateItemBearishOS.tsx

import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { ProjectIcon } from "../../../../../images";
import { MainDetails, Connect, ShareProject } from "./view";
import { ButtonSmall, ButtonType, SmallButtonColor } from "../../../../common";
import { PageIndicator } from "./PageIndicator";
import styles from "./HugeCreateItemBearishOS.module.css";

import { CreateCardData } from "../MainTop/types";

interface HugeCreateItemBearishOSProps {
  createCardData: CreateCardData;
  updateMainDetails: (value: Partial<CreateCardData["mainDetails"]>) => void;
  updateConnections?: (value: Partial<CreateCardData["connections"]>) => void;
  updateShareProject?: (value: Partial<CreateCardData["shareProject"]>) => void;
}

const HugeCreateItemBearishOS: React.FC<HugeCreateItemBearishOSProps> = ({
  createCardData,
  updateMainDetails,
  updateConnections = () => {}, // Default function if updateConnections is undefined
  updateShareProject = () => {}, // Default function if updateShareProject is undefined
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  /*   const createButtonHandler = (): void => {
    console.log("create button clicked");
  }; */

  const cancelClickHandler = (): void => {
    console.log("cancel button clicked");
  };

  const handleNextPage = (): void => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, 3));
  };

  const renderCurrentPage = () => {
    switch (currentPage) {
      case 1:
        return (
          <MainDetails
            createCardData={createCardData}
            updateMainDetails={updateMainDetails}
          />
        );
      case 2:
        return (
          <Connect
            createCardData={createCardData}
            updateConnections={updateConnections}
          />
        );
      case 3:
        return (
          <ShareProject
            createCardData={createCardData}
            updateShareProject={updateShareProject}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Stack className={styles["huge-create-item"]}>
      {/* header here svg and text */}
      <Stack className={styles["huge-create-item__header"]}>
        <Box className={styles["huge-create-item__icon-container"]}>
          <ProjectIcon color="black" w={25} />
        </Box>
        <Typography className={styles["huge-create-item__title"]}>
          Create a Project
        </Typography>
      </Stack>

      {/* page indicator for selected page */}
      <PageIndicator
        activePage={currentPage}
        setCurrentPage={(pageNo: number) => setCurrentPage(pageNo)}
      />

      {/* dynamic page here */}
      <Stack sx={{ flexGrow: 1 }}>{renderCurrentPage()}</Stack>

      {/* button here */}
      <Stack className={styles["huge-create-item__button-container"]}>
        <ButtonSmall
          onClick={() => cancelClickHandler()}
          label="Cancel"
          types={ButtonType.Button}
          colorVarient={SmallButtonColor.Transparent}
        />
        <Stack sx={{ flexDirection: "row", gap: "10px" }}>
          <ButtonSmall
            label="Create"
            types={ButtonType.Button}
            colorVarient={SmallButtonColor.Orange}
          />
          {(currentPage === 1 || currentPage === 2) && (
            <ButtonSmall
              onClick={handleNextPage}
              label="Next"
              types={ButtonType.Button}
              colorVarient={SmallButtonColor.Gray}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default HugeCreateItemBearishOS;
