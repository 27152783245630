import React from "react";
import style from "./UnfilledButtonXLarge.module.css";
import { Box, Button, ButtonProps } from "@mui/material";

interface IconButtonProps extends ButtonProps {
  title: string;
  iconSvg: React.ReactNode;
  handleExternallySignUp?: (value: string) => void;
  authState?: string;
}

const UnfilledButtonXLarge: React.FC<IconButtonProps> = ({
  title,
  iconSvg,
  handleExternallySignUp,
  authState,
  ...props
}) => {
  return (
    <Button
      // disableElevation
      className={`${style["unfilledButtonXLarge"]}`}
      onClick={() =>
        handleExternallySignUp &&
        handleExternallySignUp(authState ? authState : "")
      }
      {...props}
    >
      <Box className={`${style["unfilledButtonXLarge__image"]}`}>{iconSvg}</Box>
      {title}
    </Button>
  );
};

export default UnfilledButtonXLarge;
