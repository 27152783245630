import React from "react";
// import { DragAndDropColumns } from "./components";
import DynamicTabs from "../OverView/Overview";


type Props = {};

const InsideWorkspaceItem = (props: Props) => {
  return (
    <div>
      <DynamicTabs />
    </div>
  );
};

export default InsideWorkspaceItem;
