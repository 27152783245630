import { Dispatch, SetStateAction } from "react";

// Define a type for department objects
export type Department = {
    _id: string;
    name: string;
    isDeleted: boolean;
    createdAt?: string;
    updatedAt?: string;
    __v?: number;
};
// Define a type for the state object
export type UserProfileData = {
    firstName: string;
    lastName: string;
    avatarPic: File | null;
    avatarDefault: string; // Assuming avatarDefault is a color code or initial
    emailAddress: string;
    language: string; // Language selection as string
    jobTitle: string;
    department: Department []; // Department selection as an object
    phoneNumber: string;
    mobileNumber?: string;
    location: string;
    timeZone: string;
    timeFormat: '12h' | '24h'; // Time format selection
    dateFormat: 'year-long-numeric' | 'numeric-long-year'; // Date format selection
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
    workingLocation: string; // Working location
};

// Use the useState hook to initialize the state
export const initialUserProfileData: UserProfileData = {
    firstName: "",
    lastName: "",
    avatarPic: null,
    avatarDefault: "",
    emailAddress: "",
    language: "", // Initial language selection can be empty
    jobTitle: "",
    department: [],
    phoneNumber: "",
    mobileNumber : "",
    workingLocation: "",
    location: "",
    timeZone: "",
    timeFormat: "12h", // Initial time format selection
    dateFormat: "year-long-numeric", // Initial date format selection
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
};

// Define the props interface
export interface UserProfileProps {
    userProfileData: UserProfileData;
    setUserProfileData: Dispatch<SetStateAction<UserProfileData>>;
}
