import React from "react";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { NotSelectedIcon, SelectedIcon } from "./CheckboxIcons";

interface CheckboxMediumProps extends CheckboxProps {
  boxSize?: number;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxMedium: React.FC<CheckboxMediumProps> = ({
  boxSize = 20,
  checked = false,
  onChange,
  ...props
}) => {
  // Create a wrapper function for the onChange handler
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault(); // Prevent default action
    event.stopPropagation(); // Stop event propagation
    if (onChange) {
      onChange(event); // Call the provided onChange handler
    }
  };

  return (
    <Checkbox
      {...props}
      size="small"
      sx={{
        p: 0,
        borderRadius: "5px",
        height: `${boxSize}px`,
        width: `${boxSize}px`,
      }}
      checked={checked}
      onChange={handleChange} // Attach the wrapped onChange handler
      icon={<NotSelectedIcon iconSize={boxSize} />}
      checkedIcon={<SelectedIcon iconSize={boxSize} />}
    />
  );
};

export default CheckboxMedium;
