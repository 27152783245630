import * as React from "react";
import {
  FormControl,
  ListItemText,
  MenuItem,
  Stack,
  InputBase,
  Tooltip,
  Typography,
  Paper,
  ClickAwayListener,
} from "@mui/material";
import S from "./OpenDropdownWithColor.module.css";
import { ColorPickerDot } from "../ColorPickerDot";
import { DropdownWithColorOptionTypes } from "../DropdownWithColor/DropdownWithColorTypes";

interface OpenDropdownWithColorProps {
  options: DropdownWithColorOptionTypes[];
  width?: string;
  label?: string;
  onChange: (updatedOptions: DropdownWithColorOptionTypes[]) => void;
  onSelect: (selectedOption: DropdownWithColorOptionTypes) => void;
  initialValue?: DropdownWithColorOptionTypes;
}

const OpenDropdownWithColor: React.FC<OpenDropdownWithColorProps> = React.memo(
  ({
    options,
    width = "125px",
    onChange,
    onSelect,
    label,
    initialValue,
    ...props
  }) => {
    const [selectedValue, setSelectedValue] = React.useState<string>(
      initialValue ? initialValue?.value : ""
    );
    const [selectedColor, setSelectedColor] = React.useState<any>(
      initialValue ? initialValue?.customColorHex : "#007BB2"
    );
    const [open, setOpen] = React.useState(true);
    const [localOptions, setLocalOptions] =
      React.useState<DropdownWithColorOptionTypes[]>(options);
    const [addingCustom, setAddingCustom] = React.useState(false);
    const [newCustomValue, setNewCustomValue] = React.useState("");
    const [newCustomColor, setNewCustomColor] = React.useState("#007BB2");
    const [focusInput, setFocusInput] = React.useState(false);

    React.useEffect(() => {
      if (initialValue) {
        setSelectedValue(initialValue?.value);
        setSelectedColor(initialValue?.customColorHex);
      }
    }, [initialValue]);

    const handleChange = React.useCallback(
      (value: string) => {
        const selectedOption = localOptions?.find(
          (option) => option?.value === value
        );
        if (selectedOption) {
          setSelectedValue(selectedOption?.value);
          setSelectedColor(selectedOption?.customColorHex);
          if (onSelect) {
            onSelect(selectedOption);
          }
        }
      },
      [localOptions, onSelect]
    );

    const handleAddCustomClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      setAddingCustom(true);
      setFocusInput(true);
    };

    const handleSaveCustomClick = () => {
      if (newCustomValue?.trim() !== "") {
        const newCustomOption: DropdownWithColorOptionTypes = {
          id: localOptions.length + 1,
          value: newCustomValue,
          defaultColorHex: newCustomColor,
          customColorHex: newCustomColor,
        };
        const updatedOptions = [...localOptions, newCustomOption];
        setLocalOptions(updatedOptions);
        if (onChange) {
          onChange(updatedOptions);
        }
        handleChange(newCustomValue);
      }
      setAddingCustom(false);
      setNewCustomValue("");
      setFocusInput(false);
    };

    const handleColorChange = (id: number, color: string) => {
      const updatedOptions = localOptions?.map((option) =>
        option?.id === id ? { ...option, customColorHex: color } : option
      );
      setLocalOptions(updatedOptions);
      if (onChange) {
        onChange(updatedOptions);
      }
      if (
        selectedValue ===
        localOptions?.find((option) => option?.id === id)?.value
      ) {
        setSelectedColor(color);
      }
    };

    const getMaxWidth = (width: string) => {
      const widthValue = parseInt(width, 10);
      const maxWidth = widthValue - 25;
      return `${maxWidth}px`;
    };

    const handleClickAway = () => {
      if (addingCustom) {
        handleSaveCustomClick();
      }
      setOpen(false);
    };

    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <FormControl style={{ width }} {...props}>
            {label && (
              <Typography
                sx={{
                  font: "normal normal 300 10px/13px Source Serif Pro",
                  marginBottom: "5px",
                }}
                className={S["open_dropdown-with-color__label"]}
              >
                {label}
              </Typography>
            )}
            <Stack
              className={S["open_dropdown-with-color__inputbox"]}
              onClick={() => setOpen(true)}
            >
              <span className={S["open_dropdown-with-color__selected"]}>
                <span
                  style={{
                    maxWidth: getMaxWidth(width),
                    paddingRight: "5px",
                    boxSizing: "border-box",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    display: "inline-block",
                  }}
                >
                  {selectedValue}
                </span>
                <span
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                >
                  {selectedValue && (
                    <ColorPickerDot
                      initialColor={selectedColor}
                      onColorChange={(color) => {
                        const selectedOption = options.find(
                          (item) => item.value === selectedValue
                        );
                        if (selectedOption) {
                          handleColorChange(selectedOption?.id, color);
                        }
                      }}
                    />
                  )}
                </span>
              </span>
            </Stack>
            {open && (
              <Paper className={S["open_dropdown-with-color__menu"]}>
                {localOptions.map((item) => (
                  <MenuItem
                    className={S["open_dropdown-with-color__menu-item"]}
                    key={item?.id}
                    onClick={() => handleChange(item.value)}
                  >
                    <ListItemText
                      primary={item.value}
                      primaryTypographyProps={{
                        className:
                          S["open_dropdown-with-color__list-item-text"],
                      }}
                    />
                    <div
                      onMouseDown={(e) => e.stopPropagation()}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <ColorPickerDot
                        initialColor={item.customColorHex}
                        onColorChange={(color) =>
                          handleColorChange(item?.id, color)
                        }
                      />
                    </div>
                  </MenuItem>
                ))}
                {addingCustom ? (
                  <Stack sx={{ padding: "5px 5px 5px 10px" }}>
                    <Stack
                      direction="row"
                      justifyContent={"space-between"}
                      spacing={1}
                      alignItems="center"
                    >
                      <InputBase
                        value={newCustomValue}
                        fullWidth
                        autoFocus={focusInput}
                        onChange={(e) => setNewCustomValue(e.target.value)}
                        placeholder="Add new..."
                        onBlur={handleSaveCustomClick}
                        onMouseDown={(e) => e.stopPropagation()}
                        onClick={(e) => e.stopPropagation()}
                        onKeyDown={(e) => e.stopPropagation()}
                        onKeyUp={(e) => e.stopPropagation()}
                        sx={{
                          height: "20px",
                          borderRadius: "2px",
                          border: "0.5px solid #D9E3EF",
                          font: "normal normal 300 12px/16px Source Serif Pro",
                        }}
                      />
                      <div
                        onMouseDown={(e) => e.stopPropagation()}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <ColorPickerDot
                          initialColor={newCustomColor}
                          onColorChange={(color) => setNewCustomColor(color)}
                        />
                      </div>
                    </Stack>
                  </Stack>
                ) : (
                  <MenuItem
                    className={S["open_dropdown-with-color__menu-item"]}
                  >
                    <Tooltip title="Add new" placement="right" arrow>
                      <ListItemText
                        onClick={(e) => handleAddCustomClick(e)}
                        primary="Add Custom"
                        primaryTypographyProps={{
                          className:
                            S["open_dropdown-with-color__list-item-text"],
                        }}
                      />
                    </Tooltip>
                  </MenuItem>
                )}
              </Paper>
            )}
          </FormControl>
        </div>
      </ClickAwayListener>
    );
  }
);

export default OpenDropdownWithColor;
