import React, { useState } from "react";
import { Stack, InputBase } from "@mui/material";

interface NumberViewProps {
  initialValue?: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

const NumberView: React.FC<NumberViewProps> = ({
  initialValue = "",
  onChange,
  placeholder,
}) => {
  const [value, setValue] = useState<string>(initialValue);
  const [isEditing, setIsEditing] = useState(false);

  const handleBlur = () => {
    setIsEditing(false);
    onChange(value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (/^\d*$/.test(newValue)) {
      setValue(newValue);
    }
  };

  const handleClick = () => {
    setIsEditing(true);
  };

  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", flexGrow: 1 }}>
      <InputBase
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onClick={handleClick}
        autoFocus={isEditing}
        inputProps={{ "aria-label": "number input" }}
        sx={{
          flexGrow: 1,
          border: 0,
          outline: 0,
          font: "normal normal 300 13px/17px Source Serif Pro",
          padding: 0,
        }}
      />
    </Stack>
  );
};

export default NumberView;
