import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/**
 * Defines the state structure for the Snackbar component.
 *
 * @property {boolean} open - Indicates if the snackbar is visible.
 * @property {string} message - The message displayed in the snackbar.
 * @property {"error" | "warning" | "info" | "success"} severity - The type of message (error, warning, info, success).
 */
interface SnackbarState {
  open: boolean;
  message: string;
  severity: "error" | "warning" | "info" | "success";
}

/**
 * Initial state for the snackbar, with default values.
 */
const initialState: SnackbarState = {
  open: false,
  message: "",
  severity: "success",
};

/**
 * Creates a slice for snackbar with actions to show and close it.
 */
const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    /**
     * Action to show the snackbar.
     *
     * @param {SnackbarState} state - The current state of the snackbar.
     * @param {PayloadAction<{ message: string; severity: SnackbarState['severity'] }>} action - The payload containing the message and severity.
     */
    showSnackbar: (
      state,
      action: PayloadAction<{
        message: string;
        severity: SnackbarState["severity"];
      }>
    ) => {
      state.open = true;
      state.message = action.payload.message;
      state.severity = action.payload.severity;
    },

    /**
     * Action to close the snackbar.
     *
     * @param {SnackbarState} state - The current state of the snackbar.
     */
    closeSnackbar: (state) => {
      state.open = false;
    },
  },
});

/**
 * Exports the actions to be used in the components.
 */
export const { showSnackbar, closeSnackbar } = snackbarSlice.actions;

/**
 * Exports the reducer to be included in the store.
 */
export default snackbarSlice.reducer;
