import { Stack } from "@mui/material";
import React from "react";
import { CustomCheckboxDropdown } from "../../../../../../../../common";

interface SelectDropdownProps {
  options: { value: string; color: string }[];
}

const SelectDropdownView: React.FC<SelectDropdownProps> = ({ options }) => {
  console.log(options);
  return (
    <Stack>
      <CustomCheckboxDropdown placeHolder="Select..." options={options} />
    </Stack>
  );
};
export default SelectDropdownView;
