import React, { useState, MouseEvent, useEffect } from "react";
import { Stack, Tooltip, Popover, IconButton } from "@mui/material";
import { ChevronIcon } from "../../../images";
import {
  LineSpacingDropdownInterface,
  LineSpacingDropdownValueEnum,
} from "./LineSpacingDropdownType";

interface LineSpacingDropdownProps {
  initialvalue?: LineSpacingDropdownValueEnum;
  option: LineSpacingDropdownInterface[];
  onSelect: (value: LineSpacingDropdownValueEnum) => void;
}

const LineSpacingDropdown: React.FC<LineSpacingDropdownProps> = ({
  onSelect,
  option,
  initialvalue,
}) => {
  const [selectedValue, setSelectedValue] =
    useState<LineSpacingDropdownValueEnum>(initialvalue || option[0].value);

  const DisplayIcon =
    option.find((item) => item.value === selectedValue)?.icon || option[0].icon;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const selectHandler = (value: LineSpacingDropdownValueEnum) => {
    setSelectedValue(value);
    handlePopoverClose();
  };

  useEffect(() => {
    onSelect(selectedValue);
  }, [selectedValue, onSelect]);

  return (
    <>
      <Tooltip title="Line Spacing" placement="top" arrow>
        <Stack
          onClick={handlePopoverOpen}
          sx={{
            width: "35px",
            height: "15px",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <DisplayIcon w={15} />
          <ChevronIcon w={15} direction={!open ? "up" : "down"} />
        </Stack>
      </Tooltip>

      <Popover
        open={open}
        anchorEl={anchorEl}container={ document && document?.getElementById("full-ScreenMode")}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ marginTop: "10px" }}
        PaperProps={{
          style: { boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)" },
        }}
      >
        <Stack
          sx={{
            padding: "10px",
            gap: "15px",
            boxSizing: "border-box",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {option.map((item) => (
            <IconButton
              key={item.value}
              sx={{ p: 0, bgcolor: "white", borderRadius: "0px" }}
              disableRipple
              onClick={() => selectHandler(item.value)}
            >
              {React.createElement(item.icon, { w: 15 })}
            </IconButton>
          ))}
        </Stack>
      </Popover>
    </>
  );
};

export default LineSpacingDropdown;
