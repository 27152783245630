import { Stack, TextareaAutosize, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { DetailsIcon } from "../../../../../../../images";

interface DetailsTextAreaProps {
  initialValue: string;
  onChange: (value: string) => void;
}

const DetailsTextArea: React.FC<DetailsTextAreaProps> = ({
  initialValue,
  onChange,
}) => {
  const [value, setValue] = useState<string>(initialValue);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleBlur = () => {
    setIsEditing(false);
    onChange(value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  const handleClick = () => {
    setIsEditing(true);
  };

  return (
    <Stack gap="10px">
      <Stack sx={{ flexDirection: "row", gap: "10px" }}>
        <DetailsIcon w={25} />
        <Typography
          sx={{ font: "normal normal normal 18px/25px Source Serif Pro" }}
        >
          Details
        </Typography>
      </Stack>
      <TextareaAutosize
        placeholder="Type some details about your task here…"
        minRows={5}
        maxRows={5}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onClick={handleClick}
        autoFocus={isEditing}
        style={{
          maxHeight: "125px",
          border: "0.5px solid #E9EDF2",
          borderRadius: "5px",
          overflow: "auto",
          resize: "none",
          outline: "none",
          padding: "8px",
          font: "normal normal 300 13px/17px Source Serif Pro",
          lineHeight: "17px",
          scrollbarWidth: "none",
        }}
      />
    </Stack>
  );
};

export default DetailsTextArea;
