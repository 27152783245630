import React, { useState } from "react";
import { Box, TextField, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import styles from "../centralComponentStyle.module.css"; // Assuming you have a CSS module
import { RootState } from "../../../../../../../redux/store";
import { WorkspaceState } from "../../../../../../../redux";
import { useSelector } from "react-redux";

interface QuoteComponentProps {
  initialText: string;
  onSave: (text: string) => void;
}

const QuoteComponent: React.FC<QuoteComponentProps> = ({
  initialText,
  onSave,
}) => {
  const workspaceState = useSelector(
    (state: RootState) => state.workspace as WorkspaceState
  );
  const { lockPage } = workspaceState || {};

  const [text, setText] = useState<string>(initialText);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const handleSave = () => {
    onSave(text);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setText(initialText);
    setIsEditing(false);
  };

  return (
    <Box className={styles.quoteComponent}>
      {isEditing ? (
        <Box className={styles.editorContainer}>
          <TextField
            value={text}
            onChange={(e) => setText(e.target.value)}
            variant="outlined"
            fullWidth
            className={styles.textArea}
            aria-label="Quote text editor"
          />
          <Box className={styles.buttonContainer}>
            <Tooltip title="Save" aria-label="Save">
              <IconButton color="primary" onClick={handleSave}>
                <SaveIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel" aria-label="Cancel">
              <IconButton color="error" onClick={handleCancel}>
                <CancelIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ) : (
        <Box className={styles.quoteDisplay}>
          <blockquote className={styles.blockquote}>{text}</blockquote>
          {!lockPage && (
            <Tooltip title="Edit" aria-label="Edit">
              <IconButton
                color="primary"
                onClick={() => setIsEditing(true)}
                className={styles.editButton}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      )}
    </Box>
  );
};

export default QuoteComponent;
