import { DeviceType } from "../../../../../common";

export type DeviceDataType = {
  id: string;
  device: DeviceType;
  browser: string;
  location: string;
  ipAddress: string;
  date: string;
  time: string;
};

export type SecurityOptions = {
  id: string;
  name: string;
  popupStr: string;
  danger: boolean;
};

export const DeviceData: DeviceDataType[] = [
  {
    id: "abc_i1",
    device: DeviceType.Ipad,
    browser: "safari",
    location: "San Francisco, California, USA",
    ipAddress: "2510:1650:251h:313:g7j3:7627:2f50:1eh1",
    date: "01-00-0000",
    time: "00:00",
  },
  {
    id: "abc_i2",
    device: DeviceType.Ipad,
    browser: "safari",
    location: "San Francisco, California, USA",
    ipAddress: "2510:1650:251h:313:g7j3:7627:2f50:1eh1",
    date: "01-00-0000",
    time: "00:00",
  },
  {
    id: "abc_i3",
    device: DeviceType.Ipad,
    browser: "safari",
    location: "San Francisco, California, USA",
    ipAddress: "2510:1650:251h:313:g7j3:7627:2f50:1eh1",
    date: "01-00-0000",
    time: "00:00",
  },
  {
    id: "abc_i4",
    device: DeviceType.Ipad,
    browser: "safari",
    location: "San Francisco, California, USA",
    ipAddress: "2510:1650:251h:313:g7j3:7627:2f50:1eh1",
    date: "01-00-0000",
    time: "00:00",
  },
];

export const securityOptions: SecurityOptions[] = [
  {
    id: "security-options-1",
    name: "Setup 2 Factor authentication",
    popupStr: "setup2FactorAuthentication",
    danger: false,
  },
  {
    id: "security-options-2",
    name: "Update your existing password",
    popupStr: "updateExistingPassword",
    danger: false,
  },
  {
    id: "security-options-3",
    name: "Setup ip address restrictions",
    popupStr: "setupIpRestrictions",
    danger: false,
  },
  {
    id: "security-options-4",
    name: "Deactivate your account",
    popupStr: "deactivateYourAccount",
    danger: true,
  },
];
