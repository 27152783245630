// components/CodeComponent.tsx
import React, { useState } from "react";
import {
  Box,
  TextField,
  IconButton,
  Tooltip,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import styles from "../centralComponentStyle.module.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../redux/store";
import { WorkspaceState } from "../../../../../../../redux";

// data/languages.ts
export interface Language {
  label: string;
  value: string;
}

export const languages: Language[] = [
  { label: "JavaScript", value: "javascript" },
  { label: "Python", value: "python" },
  { label: "Java", value: "java" },
  { label: "C++", value: "c++" },
  { label: "TypeScript", value: "typescript" },
];


// Define the types for props and component state
interface CodeComponentProps {
  initialCode: string;
  initialLanguage: string;
  onSave: (data: { code: string; language: string }) => void;
}

const CodeComponent: React.FC<CodeComponentProps> = ({
  initialCode,
  initialLanguage,
  onSave,
}) => {
  const workspaceState = useSelector((state: RootState) => state.workspace as WorkspaceState);
  const { lockPage } = workspaceState || {};

  const [code, setCode] = useState<string>(initialCode);
  const [language, setLanguage] = useState<string>(initialLanguage);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const handleSave = () => {
    onSave({ code, language });
    setIsEditing(false);
  };

  const handleCancel = () => {
    setCode(initialCode);
    setLanguage(initialLanguage);
    setIsEditing(false);
  };

  const currentLanguageLabel = languages.find((lang) => lang.value === language)?.label || "Unknown Language";

  return (
    <Box className={styles.codeComponent}>
      {isEditing ? (
        <Box className={styles.editorContainer}>
          <FormControl fullWidth className={styles.languageSelectContainer}>
            <InputLabel>Language</InputLabel>
            <Select
              value={language}
              onChange={(e) => setLanguage(e.target.value as string)}
              variant="outlined"
              className={styles.languageSelect}
            >
              {languages.map((lang: Language) => (
                <MenuItem key={lang.value} value={lang.value}>
                  {lang.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            value={code}
            onChange={(e) => setCode(e.target.value)}
            multiline
            rows={10}
            variant="outlined"
            fullWidth
            className={styles.codeArea}
            aria-label="Code editor"
          />
          <Box className={styles.buttonContainer}>
            <Tooltip title="Save" aria-label="Save">
              <IconButton color="primary" onClick={handleSave}>
                <SaveIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel" aria-label="Cancel">
              <IconButton color="error" onClick={handleCancel}>
                <CancelIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ) : (
        <Box className={styles.codeDisplay}>
          <Box className={styles.languageLabel}>
            {currentLanguageLabel}
          </Box>
          <pre className={styles.codeBlock}>
            <code>
              {code.length === 0
                ? "Code goes here—write your magic!" // Placeholder text
                : code}
            </code>
          </pre>
          {!lockPage && (
            <Tooltip title="Edit" aria-label="Edit">
              <IconButton
                className={styles.editButton}
                color="primary"
                onClick={() => setIsEditing(true)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      )}
    </Box>
  );
};

export default CodeComponent;
