import React from 'react'

type Props = {}

const Calls = (props: Props) => {
  return (
    <div>Calls</div>
  )
}

export default Calls