import { IconButton, IconButtonProps, Stack, Typography } from "@mui/material";
import React from "react";
import { FirstMessageIcon } from "../../../images";

interface AddCommentProps extends IconButtonProps {
  iconSize?: 15 | 25;
  addCommentToObject?: (comment: string) => void;
}

const AddComment: React.FC<AddCommentProps> = ({
  iconSize = 15,
  addCommentToObject,
  ...props
}) => {
  const getFontStyle = () => {
    switch (iconSize) {
      case 15:
        return "normal normal 300 12px/15px Source Serif Pro";

      case 25:
        return "normal normal 300 14px/18px Source Serif Pro";

      default:
        break;
    }
  };

  const getFlaxGap = () => {
    switch (iconSize) {
      case 15:
        return "5px";
      case 25:
        return "10px";
    }
  };

  return (
    <IconButton sx={{ p: 0, borderRadius: 0 }} disableRipple {...props}>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: getFlaxGap(),
          flexShrink: 0,
        }}
        onClick={() => addCommentToObject && addCommentToObject("Hello World")}
      >
        <FirstMessageIcon w={iconSize} />
        <Typography sx={{ font: getFontStyle() }}>Add comment</Typography>
      </Stack>
    </IconButton>
  );
};

export default AddComment;
