import React, { useState, useEffect, useCallback } from "react";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins.pkgd.min.js";
import Froalaeditor from "froala-editor";
import { debounce } from "lodash";
import "./custom-froala-styles.css";
import {
  addcommentf,
  alignmenticonf,
  arrowDown,
  arrowUp,
  attachmentf,
  boldIconf,
  bulletf,
  clearFormattingIconf,
  colorF,
  fulljustifyalignmentf,
  italicIconf,
  linkf,
  numericalignmentf,
  rightalignmentf,
  spacalAlignmentf,
  strikethroughIconf,
  underlineiconf,
} from "../../../images";
const FroalaEditorComponent = ({
  initialContent,
  onContentChange,
  isCommentButtonClicked,
  setCommentButtonClicked,
}) => {
  const [content, setContent] = useState(initialContent);
  // State to track whether the "Add Comment" button has been clicked

  // Update content when initialContent changes (e.g., when data is fetched)
  useEffect(() => {
    if (initialContent !== content) {
      setContent(initialContent); // Update Froala content with initialContent
    }
  }, [initialContent]);

  const debouncedContentChange = useCallback(
    debounce((updatedContent) => {
      onContentChange(updatedContent);
    }, 1000),
    [onContentChange]
  );
  Froalaeditor.ICON_TEMPLATES = {
    font_awesome: '<i class="fa fa-[NAME]" aria-hidden="true"></i>,',
    font_awesome_5: '<i class="fas fa-[FA5NAME]" aria-hidden="true"></i>',
    font_awesome_5s: '<i class="far fa-[FA5NAME]" aria-hidden="true"></i>',
    text: '<span style="">[NAME]</span>',
    image: '<img  width="100%" height="100%" src="[PATH]" >',
    svg: '<svg class="fr-svg" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="[PATH]"></path></svg>',
  };
  const handleContentChange = (updatedContent) => {
    setContent(updatedContent);
    debouncedContentChange(updatedContent); // Debounced change
  };

  Froalaeditor.DefineIcon("HeadingSelections", {
    NAME: "headingSelectionsIcon",
    template: "svg",
    PATH: "M4.959 1.25H10.04c.554 0 1.032 0 1.414.051.41.055.802.18 1.12.498.32.319.444.711.499 1.121.051.382.051.86.051 1.414v.635a.625.625 0 11-1.25 0v-.594c0-.607-.001-1-.04-1.288-.037-.271-.096-.356-.143-.404-.048-.048-.133-.106-.404-.143C11 2.501 10.607 2.5 10 2.5H8.125v10.625a.625.625 0 11-1.25 0V2.5H5c-.607 0-1 .001-1.288.04-.271.037-.356.095-.404.143-.048.048-.106.133-.143.404-.039.289-.04.681-.04 1.288v.594a.625.625 0 11-1.25 0v-.594-.041c0-.554 0-1.032.051-1.414.055-.41.18-.802.498-1.12.319-.32.711-.444 1.121-.499.382-.051.86-.051 1.414-.051z",
  });

  const headingStyles = {
    normal: "font-size: 16px;",
    title: "font-size: 24px;",
    subtitle: "font-size: 20px;",
    h1: "font-size: 32px;",
    h2: "font-size: 28px;",
    h3: "font-size: 24px;",
    h4: "font-size: 22px;",
    h5: "font-size: 20px;",
    h6: "font-size: 18px;",
  };

  Froalaeditor.RegisterCommand("HeadingSelections", {
    title: "Heading Selections",
    type: "dropdown",
    options: {
      normal: "Normal text",
      title: "Title text",
      subtitle: "Subtitle",
      h1: "Heading 1",
      h2: "Heading 2",
      h3: "Heading 3",
      h4: "Heading 4",
      h5: "Heading 5",
      h6: "Heading 6",
    },
    callback: function (cmd, param) {
      const style = headingStyles[param];
      if (this.selection.text()) {
        this.html.insert(
          `<span style="${style}">${this.selection.text()}</span>`
        );
      }
    },
  });

  const fontFamilies = {
    sourceServicePro: "Source Service Pro",
    arial: "Arial",
    courierNew: "Courier New",
    georgia: "Georgia",
    timesNewRoman: "Times New Roman",
    verdana: "Verdana",
    roboto: "Roboto",
    helvetica: "Helvetica",
    tahoma: "Tahoma",
    trebuchetMS: "Trebuchet MS",
    comicSansMS: "Comic Sans MS",
  };

  Froalaeditor.DefineIcon("FontFamilySelections", {
    NAME: "fontFamilySelections",
    template: "text",
    text: getCurrentFontFamily(this),
  });
  function getCurrentFontFamily(editor) {
    if (editor && editor.selection) {
      const selectionElement = editor.selection.element();
      if (selectionElement) {
        return window
          .getComputedStyle(selectionElement, null)
          .getPropertyValue("font-family");
      }
    }
    return "Default Font"; // Default if no selection or editor is not ready
  }

  Froalaeditor.RegisterCommand("FontFamilySelections", {
    title: "Font Family",
    type: "dropdown",
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    options: fontFamilies,
    callback: function (cmd, param) {
      const fontFamily = fontFamilies[param];
      this.format.applyStyle("font-family", fontFamily);
    },
    refresh: function ($btn) {
      var fontFamily = getCurrentFontFamily(this);
      $btn.text(fontFamily.replace(/['"]+/g, "")); // Ensure this updates the button text correctly
    },
  });

  Froalaeditor.RegisterCommand("currentFontSize", {
    title: "Current Font Size",
    type: "button",
    focus: false,
    undo: false,
    refreshAfterCallback: false,
    callback: function () {
      // No action needed on click
    },
    refresh: function ($btn) {
      const editor = this;
      const selectionElement = editor.selection.element();
      if (selectionElement) {
        const currentSize = window
          .getComputedStyle(selectionElement, null)
          .getPropertyValue("font-size");
        $btn?.text(`${currentSize}`);
      } else {
        $btn?.text(`${"25px"}`);
      }
    },
  });
  // Function to parse and adjust font size
  function adjustFontSize(editor, adjustment) {
    const selectionElement = editor?.selection?.element();
    if (selectionElement) {
      let currentSize = window
        .getComputedStyle(selectionElement, null)
        .getPropertyValue("font-size");
      let newSize = parseInt(currentSize, 10) + adjustment;
      if (newSize < 8) newSize = 8; // Minimum font size
      editor.format.applyStyle("font-size", `${newSize}px`);
    }
  }

  Froalaeditor.DefineIcon("increaseFontSize", {
    NAME: "increaseFontSize",
    template: "image",
    PATH: arrowUp,
    SVG: `<img src="${arrowUp}" style="width: 20px; height: auto;" />`, // Apply inline style
  });

  Froalaeditor.DefineIcon("decreaseFontSize", {
    NAME: "decrEaseFontSize",
    template: "image",
    PATH: arrowDown,
    SVG: `<img src="${arrowDown}" style="width: 20px; height: auto;" />`, // Apply inline style
  });

  Froalaeditor.RegisterCommand("increaseFontSize", {
    title: "Increase Font Size",
    callback: function () {
      adjustFontSize(this, 1); // Increase font size by 1px
    },
  });

  Froalaeditor.RegisterCommand("decreaseFontSize", {
    title: "Decrease Font Size",
    callback: function () {
      adjustFontSize(this, -1); // Decrease font size by 1px
    },
  });

  // Bold Icon
  Froalaeditor.DefineIcon("bold", {
    NAME: "bold",
    template: "image",
    PATH: boldIconf, // Replace with your icon path
  });

  // Italic Icon
  Froalaeditor.DefineIcon("italic", {
    NAME: "italic",
    template: "image",
    PATH: italicIconf, // Replace with your icon path
  });

  // Underline Icon
  Froalaeditor.DefineIcon("underline", {
    NAME: "underline",
    template: "image",
    PATH: underlineiconf, // Replace with your icon path
  });

  // Strike Through Icon
  Froalaeditor.DefineIcon("strikeThrough", {
    NAME: "strikeThrough",
    template: "image",
    PATH: strikethroughIconf, // Replace with your icon path
  });

  // Clear Formatting Icon
  Froalaeditor.DefineIcon("clearFormatting", {
    NAME: "clearFormatting",
    template: "image",
    PATH: clearFormattingIconf, // Replace with your icon path
  });
  // Commands for Bold, Italic, Underline, StrikeThrough, and Clear Formatting
  Froalaeditor.RegisterCommand("bold", {
    title: "Bold",
    undo: true,
    focus: true,
    callback: function () {
      // Prevent recursive call
      document.execCommand("bold"); // Using a native execCommand as a test
    },
  });

  Froalaeditor.RegisterCommand("italic", {
    title: "Italic",
    undo: true,
    focus: true,
    callback: function () {
      document.execCommand("italic");
    },
  });

  Froalaeditor.RegisterCommand("underline", {
    title: "Underline",
    undo: true,
    focus: true,
    callback: function () {
      document.execCommand("underline");
    },
  });

  Froalaeditor.RegisterCommand("strikeThrough", {
    title: "Strike Through",
    undo: true,
    focus: true,
    callback: function () {
      document.execCommand("strikeThrough");
    },
  });

  Froalaeditor.RegisterCommand("clearFormatting", {
    title: "Clear Formatting",
    undo: true,
    focus: true,
    callback: function () {
      document.execCommand("removeFormat", false, null);
    },
  });

  // Define the button in the toolbar
  Froalaeditor.RegisterCommand("myColorPicker", {
    title: "Color Picker",
    icon: "colors", // Ensure this uses a valid icon
    undo: false,
    focus: false,
    callback: function () {
      // Ensure to use the correct reference to the toolbar button
      this.colorPicker.showPicker(
        this.toolbar
          .getEl()
          .querySelector('.fr-command[data-cmd="myColorPicker"]')
      );
    },
  });

  // Define the icon for the color picker
  Froalaeditor.DefineIcon("ColorPicker", {
    NAME: "colorPickerIcon",
    template: "image",
    PATH: colorF,
  });

  // Mapping colors and labels
  const colorOptions = {
    black: "Black",
    red: "Red",
    blue: "Blue",
    green: "Green",
    yellow: "Yellow",
    orange: "Orange",
    purple: "Purple",
  };

  // Register the command for the color picker dropdown
  Froalaeditor.RegisterCommand("ColorPicker", {
    title: "Color Picker",
    type: "dropdown",
    displaySelectionWidth: 30, // Adjust width as needed
    html: function () {
      // Constructing the HTML for the dropdown
      let c =
        '<ul style="display:flex; align-items:center;" class="fr-dropdown-list" role="presentation">';

      for (let color in colorOptions) {
        const colorName = colorOptions[color];
        const colorBox = `<span style="display: inline-block; width: 16px; border-radius:5px; height: 16px; background-color: ${color}; margin-right: 4px; vertical-align: middle;"></span>`;

        // Concatenate list items
        c += `
        <li role="presentation">
          <a class="fr-command" tabIndex="-1" role="option" data-cmd="ColorPicker" data-param1="${color}" title="${colorName}">
            ${colorBox}
          </a>
        </li>`;
      }

      c += "</ul>";
      return c;
    },
    callback: function (cmd, color) {
      if (this.selection.text()) {
        this.html.insert(
          `<span style="color: ${color};">${this.selection.text()}</span>`
        );
      }
    },
    refresh: function ($btn) {
      // Optional: Add logic to refresh the button if needed
    },
    refreshOnShow: function ($btn, $dropdown) {
      // Optional: Add logic to refresh the dropdown when shown
    },
  });

  // Define the icon for the color picker
  Froalaeditor.DefineIcon("Align", {
    NAME: "align",
    template: "image",
    PATH: alignmenticonf,
  });

  // Register the command for alignment dropdown
  Froalaeditor.RegisterCommand("Align", {
    title: "Align",
    type: "dropdown",
    displaySelectionWidth: 50,
    html: function () {
      // Constructing the HTML for the dropdown
      let c =
        '<ul style="display: flex; align-items: center;" class="fr-dropdown-list" role="presentation">';

      // Mapping alignments to their icons
      const alignmentIcons = {
        left: alignmenticonf,
        center: spacalAlignmentf,
        right: rightalignmentf,
        justify: fulljustifyalignmentf,
      };

      const alignments = ["left", "center", "right", "justify"];

      alignments.forEach((align) => {
        const icon = alignmentIcons[align]; // Get the corresponding icon
        c += `
        <li role="presentation" style="display: flex; align-items: center;">
          <a class="fr-command" tabIndex="-1" role="option" data-cmd="Align" data-param1="${align}" title="${align}">
            <img src="${icon}" alt="${align}" style="width: 20px; height: 20px; margin-right: 5px;" />
          </a>
        </li>`;
      });

      c += "</ul>";
      return c;
    },
    callback: function (cmd, align) {
      // Apply the selected alignment
      this.align.apply(align);
    },
    refresh: function ($btn) {
      // Optional: Refresh button state
    },
    refreshOnShow: function ($btn, $dropdown) {
      // Optional: Refresh dropdown state when shown
    },
  });

  // Define the icon for the color picker
  Froalaeditor.DefineIcon("List", {
    NAME: "list",
    template: "image",
    PATH: bulletf,
  });

  // Register the command for bullet and numbered list dropdown
  Froalaeditor.RegisterCommand("List", {
    title: "List",
    type: "dropdown",
    displaySelectionWidth: 50,
    html: function () {
      // Constructing the HTML for the dropdown
      let c =
        '<ul style="display: flex; align-items: center;" class="fr-dropdown-list" role="presentation">';

      // Mapping list types to their icons
      const listIcons = {
        unordered: bulletf,
        ordered: numericalignmentf,
      };

      const listTypes = {
        unordered: "Bullet List",
        ordered: "Numbered List",
      };

      for (let type in listTypes) {
        const icon = listIcons[type]; // Get the corresponding icon
        c += `
        <li role="presentation" style="display: flex; align-items: center;">
          <a class="fr-command" tabIndex="-1" role="option" data-cmd="List" data-param1="${type}" title="${listTypes[type]}">
            <img src="${icon}" alt="${type}" style="width: 20px; height: 20px; margin-right: 5px;" />
          </a>
        </li>`;
      }

      c += "</ul>";
      return c;
    },
    callback: function (cmd, type) {
      // Apply the selected list type
      if (type === "unordered") {
        this.lists.format("UL");
      } else if (type === "ordered") {
        this.lists.format("OL");
      }
    },
    refresh: function ($btn) {
      // Optional: Refresh button state
    },
    refreshOnShow: function ($btn, $dropdown) {
      // Optional: Refresh dropdown state when shown
    },
  });
  // Define the icon for the color picker
  Froalaeditor.DefineIcon("AddLink", {
    NAME: "list",
    template: "image",
    PATH: linkf,
  });

  // Register the command for adding a link to selected text
  Froalaeditor.RegisterCommand("AddLink", {
    title: "Add Link",
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      // Get the URL from the user
      const linkURL = prompt("Enter the URL for the link:", "https://");

      // Check if a URL was provided
      if (linkURL) {
        // Insert the link into the selected text
        this.link.insert(linkURL);
      }
    },
  });

  // Define the icon for the color picker
  Froalaeditor.DefineIcon("InsertMediaFromFile", {
    NAME: "insertMediaFromFile",
    template: "image",
    PATH: attachmentf,
  });

  // Register the command for inserting media from the computer
  Froalaeditor.RegisterCommand("InsertMediaFromFile", {
    title: "Insert Media from File",
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      // Trigger Froala's image and video insert popups
      const mediaOptions = ["image", "video"];

      // Creating a hidden file input for selecting both image and video files
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*,video/*"; // Accept both image and video files
      input.style.display = "none";

      // When a file is selected, insert it into the editor
      input.onchange = (event) => {
        const file = event.target.files[0];
        if (file) {
          // Check if the file is an image or video
          if (file.type.startsWith("image/")) {
            this.image.upload([file]);
          } else if (file.type.startsWith("video/")) {
            this.video.upload([file]);
          } else {
            alert("Invalid file type. Please select an image or video file.");
          }
        }
      };

      // Simulate a click on the file input
      input.click();
    },
  });

  // Define the "Add Comment" command
  Froalaeditor.RegisterCommand("addComment", {
    title: "Add Comment",
    icon: "addCommentIcon", // Use the defined icon
    focus: false,
    undo: false,
    callback: function () {
      // Change the state when button is clicked
      setCommentButtonClicked(isCommentButtonClicked ? false : true);
      // Additional logic for adding a comment can be placed here
    },
  });

  // Define the icon for the "Add Comment" button
  Froalaeditor.DefineIcon("addCommentIcon", {
    NAME: "addCommentIcon",
    template: "image",
    PATH: addcommentf, // Use your image source
    SVG: `<span>
    <img src="${addcommentf}" style="width: 16px; height: 16px; vertical-align: middle;"/> <span 
    
    style={{
        position: "relative",
    width: "100%",
    color: "black",
    height: "20px",
    }}>Add Comment</span></span>`, // Add text next to the icon
  });

  const config = {
    pluginsEnabled: [
      "align",
      "charCounter",
      "codeBeautifier",
      "codeView",
      "colors",
      "draggable",
      "emoticons",
      "fontAwesome",
      "fontSize",
      "fullscreen",
      "image",
      "imageManager",
      "inlineClass",
      "inlineStyle",
      "link",
      "lists",
      "paragraphFormat",
      "paragraphStyle",
      "quickInsert",
      "quote",
      "save",
      "table",
      "url",
      "video",
      "wordPaste",
    ],
    language: "en",
    heightMax: 550,
    placeholderText: "Start writing, press the spacebar for BEBA AI, or “/” for commands",
    heightMin: 550,
    widthMin: 850,
    widthMax: 850,
    listAdvancedTypes: true,
    key: "8JF3bB2A5A4C3A1E2E2zPAENHMf1JPQRFZBTBf1WWEPYDbB3H3E2A16A19B7C5C6A2C3==",
    attribution: false,
    charCounterCount: false,
    quickInsertEnabled: false,
    toolbarButtons: [
      [
        "HeadingSelections",
        "|",
        "FontFamilySelections",
        "|",
        "increaseFontSize",
        "currentFontSize",
        "decreaseFontSize",
        "|",
        "bold",
        "italic",
        "underline",
        "strikeThrough",
        "clearFormatting",
        "|",
        "ColorPicker",
        "Align",
        "List",
        "AddLink",
        "InsertMediaFromFile",
        "|",
        "addComment",
      ],
    ],
    initOnClick: false,
    autofocus: true,
  };

  return (
    <div className="main__frolal">
      <FroalaEditor
      tag="textarea"
      model={content}
      onModelChange={handleContentChange}
      config={config}
    />
    </div>
  );
};

export default FroalaEditorComponent;
