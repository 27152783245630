import { Stack, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";

interface AiSummaryViewProps {}

const AiSummaryView: React.FC<AiSummaryViewProps> = ({}) => {
  return (
    <Stack gap={"10px"}>
      <Stack flexDirection={"row"}>
        <Typography
          sx={{
            font: "normal normal 400 14px/18px Source Serif Pro",
            paddingBottom: "5px",
            borderBottom: "1px solid #E9EDF2",
          }}
        >
          BEBA AI
        </Typography>
      </Stack>

      {/* -------------- */}
      <Typography sx={{ font: "normal normal 600 14px/18px Source Serif Pro" }}>
        Summary
      </Typography>

      <Stack sx={{ padding: "10px" }}>
        <Typography
          sx={{
            font: "normal normal 400 14px/18px Source Serif Pro",
            bgcolor: adjustHexColor("#E9EDF2", 15),
          }}
        >
          This is a summary of the document or item that has been created by
          BEBA AI so it can be about this big, and expand all the way to right
          here but not further.
        </Typography>
      </Stack>
      {/* -------------- */}
      {/* -------------- */}

      <Typography sx={{ font: "normal normal 600 14px/18px Source Serif Pro" }}>
        Sentiment
      </Typography>
      <Typography
        sx={{
          font: "normal normal 400 14px/18px Source Serif Pro",
        }}
      >
        Sentiment of item
      </Typography>
      {/* -------------- */}
      {/* -------------- */}

      <Typography sx={{ font: "normal normal 600 14px/18px Source Serif Pro" }}>
        Compliance Check
      </Typography>
      <Typography
        sx={{
          font: "normal normal 400 14px/18px Source Serif Pro",
        }}
      >
        In compliance
      </Typography>
      {/* -------------- */}
    </Stack>
  );
};

export default AiSummaryView;
