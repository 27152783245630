import {
  IconButton,
  InputBase,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { DragAndDropIcon } from "../../../../../../../images";

import {
  ApprovedView,
  CheckboxView,
  ColorPickerView,
  ConnectionView,
  CountdownView,
  DateView,
  DependentDropdownView,
  EmailView,
  FileMediaView,
  FormulaView,
  LocationView,
  MoneyView,
  MultiSelectLabelView,
  NumberView,
  ParagraphView,
  PercentageView,
  PersonView,
  PhoneView,
  ProgressAutoView,
  ProgressManualView,
  RatingView,
  RelationView,
  RichTextView,
  RollupView,
  SelectDropdownView,
  ShortTextView,
  TimestampView,
  UrlView,
  VotingView,
} from "./FieldsView";
import {
  AddElementOptionValuesEnum,
  AddElementPopoutFormDataInterface,
} from "../../../../../../common";

interface CustomFieldProps {
  data: AddElementPopoutFormDataInterface;
}

const CustomField: React.FC<CustomFieldProps> = ({ data }) => {
  const { fieldname, formType, additionalValue, description, emoji } = data;

  const renderElement = () => {
    // fieldtype is came from props
    switch (formType) {
      case AddElementOptionValuesEnum.ShortText:
        return <ShortTextView onChange={(value) => null} />;

      case AddElementOptionValuesEnum.Paragraph:
        return <ParagraphView onChange={(value) => null} />;

      case AddElementOptionValuesEnum.Number:
        return <NumberView onChange={(value) => null} />;

      case AddElementOptionValuesEnum.Checkbox:
        return <CheckboxView />;

      case AddElementOptionValuesEnum.Date:
        return <DateView />;

      case AddElementOptionValuesEnum.Timestamp:
        return <TimestampView onChange={(value) => console.log(value)} />;

      case AddElementOptionValuesEnum.SelectDropdown:
        return <SelectDropdownView options={additionalValue} />;

      case AddElementOptionValuesEnum.Approved:
        return <ApprovedView options={additionalValue} />;

      case AddElementOptionValuesEnum.MultiselectLabels:
        return <MultiSelectLabelView options={additionalValue} />;

      case AddElementOptionValuesEnum.Person:
        return <PersonView />;

      case AddElementOptionValuesEnum.FileMedia:
        return <FileMediaView />;

      case AddElementOptionValuesEnum.URL:
        return <UrlView />;

      case AddElementOptionValuesEnum.Email:
        return <EmailView />;

      case AddElementOptionValuesEnum.Phone:
        return <PhoneView />;

      case AddElementOptionValuesEnum.Formula:
        return <FormulaView />;

      case AddElementOptionValuesEnum.Relation:
        return <RelationView />;

      case AddElementOptionValuesEnum.Rollup:
        return <RollupView />;

      case AddElementOptionValuesEnum.ProgressManual:
        return (
          <ProgressManualView initialValue={20} onChange={(value) => null} />
        );

      case AddElementOptionValuesEnum.ProgressAuto:
        return <ProgressAutoView progress={30} />;

      case AddElementOptionValuesEnum.Rating:
        return <RatingView />;

      case AddElementOptionValuesEnum.Money:
        return <MoneyView currencyType={additionalValue} />;

      case AddElementOptionValuesEnum.Location:
        return <LocationView onChange={(value) => null} />;

      case AddElementOptionValuesEnum.DependentDropdown:
        return <DependentDropdownView />;

      case AddElementOptionValuesEnum.RichText:
        return <RichTextView />;

      case AddElementOptionValuesEnum.ColorPicker:
        return <ColorPickerView options={additionalValue} />;

      case AddElementOptionValuesEnum.Countdown:
        return <CountdownView onChange={(value) => null} />;

      case AddElementOptionValuesEnum.Connection:
        return <ConnectionView />;

      case AddElementOptionValuesEnum.Voting:
        return <VotingView />;

      case AddElementOptionValuesEnum.Percentage:
        return <PercentageView initialValue={100} onChange={(value) => null} />;

      default:
        break;
    }
  };

  return (
    <Stack
      sx={{
        flexDirection: "row",
        height: "min-content",
        minHeight: "40px",
        borderRight: "0.5px solid #E9EDF2",
        borderBottom: "0.5px solid #E9EDF2",
        borderLeft: "0.5px solid #E9EDF2",
        boxSizing: "border-box",
      }}
    >
      {/* left part >> dragAndDropIcon and Emoji and FieldName */}
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "start",
          flex: "0 0 45%",
          borderRight: "1px solid #E9EDF2",
          gap: "10px",
          padding: "10px 10px",
          boxSizing: "border-box",
        }}
      >
        <Stack sx={{ flexDirection: "row", gap: "10px" }}>
          <IconButton sx={{ p: 0 }} disableRipple>
            <DragAndDropIcon w={15} />
          </IconButton>

          {emoji && (
            <InputBase
              sx={{
                width: "15px",
                height: "15px",
                font: "normal normal 300 13px/17px Source Serif Pro",
              }}
              value={emoji.native}
              readOnly
            />
          )}
        </Stack>

        <Tooltip placement="top" arrow title={description}>
          <Typography
            sx={{
              font: "normal normal 300 13px/17px Source Serif Pro",
              cursor: "default",
            }}
          >
            {fieldname}
          </Typography>
        </Tooltip>
      </Stack>
      {/* right part >> details and input field here */}
      <Stack
        sx={{
          flexGrow: 1,
          padding: "5px 10px",
          boxSizing: "border-box",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {renderElement()}
      </Stack>
    </Stack>
  );
};

export default CustomField;
