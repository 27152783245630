import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Divider, Tabs, Tab, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  CalendarOverview,
  CheckListOverView,
  DocumentOverView,
  FabricWhiteboard,
  GanttOverView,
  ListOverView,
} from "./components";
import {
  addComponentView__api,
  getAllTasks__api,
  getOneComponent__api,
} from "../../../../redux";
import { RootState } from "../../../../redux/store";
import {
  AddTask,
  AddView,
  IconwordSmallDropdown,
  ShareButton,
  ThreeDotMenuLarge,
} from "../../../common";
import { DragAndDropColumns } from "../InsideWorkspaceItem/components";
import { menuItems } from "../InsideWorkspace/InsideWorkspaceType";
import { IconKey, RenderWorkspaceIcon } from "../../../../Utils";
import { SearchIcon } from "../../../../images";
import { Wiki } from "./components/WikiOverView";

// Define types for components that could be viewed
export type ComponentKey =
  | "Overview"
  | "Board"
  | "Checklist"
  | "List"
  | "Tree"
  | "Gantt"
  | "Calendar";
type FullPageKey = "Whiteboard" | "Document" | "Wiki";

interface Props {
  workspaceItemData: any; // Consider using a more specific type if possible
  taskWorkspaceItemData: any;
  setTaskWorkspaceItemData: (data: any) => void;
  fetchComponentData: () => void;
}

// Define the DragAndDropColumns component if not already defined as a separate component
const MemoizedDragAndDropColumns = React.memo(
  ({
    taskWorkspaceItemData,
    workspaceItemData,
    setTaskWorkspaceItemData,
    fetchComponentData,
  }: Props) => (
    <DragAndDropColumns
      taskWorkspaceItemData={taskWorkspaceItemData}
      workspaceItemData={workspaceItemData}
      setTaskWorkspaceItemData={setTaskWorkspaceItemData}
      fetchComponentData={fetchComponentData}
    />
  )
);

// Do the same for CheckListOverView and other components if necessary
const MemoizedCheckListOverView = React.memo(
  ({
    taskWorkspaceItemData,
    workspaceItemData,
    setTaskWorkspaceItemData,
  }: Props) => (
    <CheckListOverView
      taskWorkspaceItemData={taskWorkspaceItemData}
      workspaceItemData={workspaceItemData}
      setTaskWorkspaceItemData={setTaskWorkspaceItemData}
    />
  )
);

// Continue for other components as needed...

const COMPONENT_TAB_MAP = (
  workspaceItemData: any,
  taskWorkspaceItemData: any,
  setTaskWorkspaceItemData: any,
  fetchComponentData: any
): Record<ComponentKey, React.ReactNode> => ({
  Overview: <Typography>Overview</Typography>,
  Board: (
    <MemoizedDragAndDropColumns
      taskWorkspaceItemData={taskWorkspaceItemData}
      workspaceItemData={workspaceItemData}
      setTaskWorkspaceItemData={setTaskWorkspaceItemData}
      fetchComponentData={fetchComponentData}
    />
  ),
  Checklist: (
    <MemoizedCheckListOverView
      taskWorkspaceItemData={taskWorkspaceItemData}
      workspaceItemData={workspaceItemData}
      setTaskWorkspaceItemData={setTaskWorkspaceItemData}
      fetchComponentData={fetchComponentData}
    />
  ),
  List: (
    <ListOverView
      taskWorkspaceItemData={taskWorkspaceItemData}
      workspaceItemData={workspaceItemData}
    />
  ),
  Tree: <div>Tree Component Placeholder</div>,
  Gantt: (
    <GanttOverView
      workspaceItemData={workspaceItemData}
      taskWorkspaceItemData={taskWorkspaceItemData}
    />
  ),
  Calendar: (
    <CalendarOverview
      workspaceItemData={workspaceItemData}
      taskWorkspaceItemData={taskWorkspaceItemData}
    />
  ),
});

const COMPONENT_FULL_PAGE_MAP: Record<FullPageKey, React.ReactNode> = {
  Whiteboard: <FabricWhiteboard />,
  Document: <DocumentOverView />,
  Wiki: <Wiki />,
};

type Params = Record<string, string | undefined>;

const DynamicComponentRenderer = () => {
  const dispatch = useDispatch();
  const { workspaceId, componentId, componentType } = useParams<Params>();
  const [workspaceItemData, setWorkspaceItemData] = useState<any>(null);
  const [taskWorkspaceItemData, setTaskWorkspaceItemData] = useState<any>(null);
  const workspaceState = useSelector((state: RootState) => state.workspace);
  const [activeTab, setActiveTab] = useState(0); // State to track the active tab

  const fetchComponentData = async (justTask: boolean = false) => {
    if (workspaceId && componentId) {
      if (
        !justTask &&
        (componentType === "Board" ||
          componentType === "List" ||
          componentType === "Checklist")
      ) {
        try {
          // Fetch component data
          await dispatch(getOneComponent__api(workspaceId, componentId));
        } catch (error) {
          console.error("Failed to fetch component data or users:", error);
        }

        try {
          // Fetch all tasks
          const taskResponse: any = await dispatch(
            getAllTasks__api(workspaceId, componentId)
          );

          if (taskResponse?.success && taskResponse?.data?.length > 0) {
            setTaskWorkspaceItemData(taskResponse.data);
          } else {
            setTaskWorkspaceItemData([]);
          }
        } catch (error) {
          console.error("Failed to fetch tasks:", error);
        }
      }
    } else {
      console.warn("Workspace ID or Component ID is missing.");
    }
  };



  useEffect(() => {
    fetchComponentData();
  }, [dispatch, workspaceId, componentId]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
    fetchComponentData();
  };

  useEffect(() => {
    if (workspaceState?.insideWorkspaceComponentData) {
      setWorkspaceItemData(workspaceState?.insideWorkspaceComponentData);
    }
  }, [workspaceState?.insideWorkspaceComponentData]);

  const handleAddView = async (newView: ComponentKey) => {
    setWorkspaceItemData((prevState: any) => {
      const updatedViews = [...(prevState.views || [])]; // Create a new array copy

      if (!updatedViews.includes(newView)) {
        console.log(`Adding new view: ${newView}`);
        updatedViews.push(newView);
      }

      return {
        ...prevState,
        views: updatedViews,
      };
    });

    const payload = {
      view: newView,
    };

    if (workspaceId && componentId && newView) {
      const action = addComponentView__api(payload, workspaceId, componentId);
      await dispatch(action);
    }
  };

  const renderTabs = () => {
    if (!workspaceId || !workspaceItemData) {
      console.log("Missing workspace ID or item data");
      return null;
    }

    // Define initial tabs array including 'Overview' and the current 'componentType'
    let initialTabs = ["Overview"];
    if (!initialTabs.includes(componentType as ComponentKey)) {
      initialTabs.push(componentType as ComponentKey);
    }

    // Combine initial tabs and views from workspaceItemData, avoiding duplicates
    const availableTabs = [...initialTabs, ...(workspaceItemData.views || [])]
      .filter((v, i, a) => a.indexOf(v) === i) // Remove duplicates
      .map((viewType) => ({
        label: viewType,
        component: viewType as ComponentKey,
      }));

    return (
      <>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={0.4}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="inherit"
              onChange={handleTabChange}
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#b2481f",
                  height: "3px",
                  borderRadius: "2px",
                },
                "& .MuiTab-root": {
                  minWidth: 100,
                  fontWeight: "bold",
                  textTransform: "none",
                  padding: "2px 4px",
                  marginBottom: "-10px",
                  fontFamily: "Source Serif Pro",
                },
              }}
            >
              {availableTabs.map((tab, index) => (
                <Tab
                  key={tab.label || index}
                  label={
                    <Box display="flex" alignItems="center">
                      {tab.label && (
                        <RenderWorkspaceIcon
                          component={tab.label as IconKey}
                          w={20}
                          color="black"
                        />
                      )}
                      <Box ml={1}>{tab.label}</Box>
                    </Box>
                  }
                  iconPosition="start"
                />
              ))}
            </Tabs>
            {!workspaceItemData?.isFreeform && (
              <AddView
                componentType={componentType}
                handleAddView={handleAddView}
              />
            )}
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            gap={2}
            pr={2}
          >
            {!workspaceItemData?.isFreeform ? (
              <IconwordSmallDropdown />
            ) : (
              <Typography
                sx={{ font: "normal normal bold 14px/21px Source Serif Pro" }}
                children={`FreeForm ${componentType}`}
              />
            )}
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              gap={1}
            >
              <SearchIcon />
              <Typography
                sx={{ font: "normal normal 300 14px/20px Source Serif Pro" }}
              >
                Search
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Divider />
        <Box sx={{ padding: 2 }}>
          {
            COMPONENT_TAB_MAP(
              workspaceItemData,
              taskWorkspaceItemData,
              setTaskWorkspaceItemData,
              fetchComponentData
            )?.[availableTabs?.[activeTab]?.component]
          }
        </Box>
      </>
    );
  };
  // useEffect(() => {
  //   if (workspaceItemData) {
  //     // Define initial tabs array including 'Overview' and the current 'componentType'
  //     let initialTabs = ["Overview"];
  //     if (!initialTabs.includes(componentType as ComponentKey)) {
  //       initialTabs.push(componentType as ComponentKey);
  //     }

  //     // Combine initial tabs and views from workspaceItemData
  //     const availableViews = [
  //       ...initialTabs,
  //       ...(workspaceItemData.views || []),
  //     ].filter((v, i, a) => a.indexOf(v) === i); // Remove duplicates

  //     // Find the index of the current active tab
  //     const currentComponentType = availableViews[activeTab] as ComponentKey;

  //     // If the current component type matches the one we're trying to set, do nothing
  //     if (currentComponentType === componentType) {
  //       return;
  //     }

  //     // Find the new active tab index
  //     const newActiveIndex = availableViews.indexOf(componentType as ComponentKey);
  //     if (newActiveIndex !== -1) {
  //       setActiveTab(newActiveIndex);
  //     } else {
  //       setActiveTab(0); // Default to the first tab if not found
  //     }
  //   }
  // }, [componentType, workspaceItemData]);

  const renderFullPageComponent = () => {
    return (
      <Box sx={{ padding: 2 }}>
        {COMPONENT_FULL_PAGE_MAP[componentType as FullPageKey]}
      </Box>
    );
  };

  if (["Whiteboard", "Document", "Wiki"].includes(componentType!)) {
    return renderFullPageComponent();
  } else {
    return (
      <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: 1 }}>
        <Stack
          width={"100%"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            sx={{
              fontFamily: "Source Serif Pro",
              fontWeight: "600",
              fontSize: "18px",
              margin: "10px 0 0 20px",
            }}
          >
            {workspaceItemData?.name ||
              workspaceItemData?.title ||
              componentType}
          </Typography>

          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={1}
            justifyContent={"flex-start"}
            pr={2}
          >
            <ShareButton />
            <AddTask
              fetchComponentData={fetchComponentData}
              workspaceItemData={workspaceItemData}
            />
            <ThreeDotMenuLarge options={menuItems} />
          </Stack>
        </Stack>
        {renderTabs()}
      </Box>
    );
  }
};

export default DynamicComponentRenderer;
