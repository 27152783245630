import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../Utils";
import styles from "./VersionCloudStorage.module.css";

interface VersionCloudStorageProps {
  vNumber: number | string;
  title: string;
  uploadedBy: string;
  dateStamp: string;
  timeStamp: string;
  storageSize: string;
  isClicked: boolean;
  onContainerClick: () => void;
}

const VersionCloudStorage: React.FC<VersionCloudStorageProps> = ({
  dateStamp,
  isClicked,
  storageSize,
  uploadedBy,
  timeStamp,
  title,
  vNumber,
  onContainerClick,
}) => {
  return (
    <Stack
      onClick={onContainerClick}
      className={`${styles.versionCloudStorage}`}
      sx={{
        border: isClicked
          ? `1px solid ${adjustHexColor("#008d4c", 25)} `
          : `1px solid ${adjustHexColor("#e9edf2", 25)}`,
        bgcolor: adjustHexColor("#e9edf2", 25),
      }}
    >
      <Stack>
        <Stack className={styles["versionCloudStorage__versionBox"]}>
          <Typography className={styles["versionCloudStorage__versionNumber"]}>
            v{vNumber}
          </Typography>
        </Stack>
      </Stack>
      <Stack className={styles["versionCloudStorage__details"]}>
        <Typography className={styles["versionCloudStorage__title"]}>
          {title}
        </Typography>
        <Typography className={styles["versionCloudStorage__uploadedBy"]}>
          Uploaded by {uploadedBy}
        </Typography>

        <Stack className={styles["versionCloudStorage__info"]}>
          <Typography
            className={styles["versionCloudStorage__date"]}
            sx={{ width: "213px", maxWidth: "213px" }}
          >
            {dateStamp}, at {timeStamp}
          </Typography>
          <Stack className={styles["versionCloudStorage__info"]}>
            <Box className={styles["versionCloudStorage__dot"]} />
            <Typography
              className={styles["versionCloudStorage__size"]}
              sx={{ width: "55px" }}
            >
              {storageSize}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default VersionCloudStorage;
