import { Divider, IconButton, Stack, Tooltip } from "@mui/material";
import React from "react";
import { FullViewIcon } from "../../../images";
import { GridDropdownMenu } from "../GridDropdownMenu";
import { LightCirclesColor } from "../LightCirclesColor";
import { LightCirclesColorOptionsList } from "../LightCirclesColor/LightCirclesColorType";
import { ZoomButton } from "../ZoomButton";

interface WhiteboardZoomControllersProps {
  zoomWhiteboard?: (zoomValue: number) => void;
  changeWhiteboardBgColor?: (color: string) => void;
  applyWhiteboardGrid?: (gridValue: string) => void;
  toggleWhiteboardFullScreen?: () => void;
}

const WhiteboardZoomControllers: React.FC<WhiteboardZoomControllersProps> = ({
  zoomWhiteboard,
  changeWhiteboardBgColor,
  applyWhiteboardGrid,
  toggleWhiteboardFullScreen,
}) => {
  return (
    <Stack
      sx={{
        // width: "200px",
        height: "30px",
        boxSizing: "border-box",
        flexDirection: "row",
        alignItems: "center",
        padding: "5px 10px",
        gap: "10px",
        borderRadius: "5px",
        boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.15)",
      }}
    >
      <ZoomButton onChange={(zV) => zoomWhiteboard && zoomWhiteboard(zV)} />
      {/* ----------------- */}
      <Divider sx={{ borderColor: "#E9EDF2" }} orientation={"vertical"} />
      {/* ----------------- */}
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "15px" }}>
        <Tooltip title="Full Screen" placement="top" arrow>
          <IconButton onClick={() => toggleWhiteboardFullScreen && toggleWhiteboardFullScreen()}  sx={{ p: 0, borderRadius: "0px" }} disableRipple>
            <FullViewIcon w={15} />
          </IconButton>
        </Tooltip>
        <GridDropdownMenu
          onSelect={(gridType) =>
            applyWhiteboardGrid && applyWhiteboardGrid(gridType)
          }
        />

        <LightCirclesColor
          colorsList={LightCirclesColorOptionsList}
          initialSelect={LightCirclesColorOptionsList[3].colorvalue}
          onSelect={(color) =>
            changeWhiteboardBgColor && changeWhiteboardBgColor(color)
          }
        />
      </Stack>
    </Stack>
  );
};

export default WhiteboardZoomControllers;
