// authService.ts

import { User } from "../redux";
import { RootState, store } from "../redux/store";

/**
 * Retrieves the authentication state from the Redux store.
 * This function handles errors and ensures that the state and auth slice are properly accessed.
 *
 * @returns {RootState['auth'] | null} The auth state if accessible, otherwise null.
 */
const getAuthState = () => {
    try {
        const state: RootState = store.getState();
        if (!state || !state?.auth) {
            console.error("Auth state is not initialized");
            return null;
        }
        return state?.auth;
    } catch (error) {
        console.error("Error accessing auth state:", error);
        return null;
    }
};

/**
 * Checks if the user is authenticated.
 * This function relies on the getAuthState function to retrieve the current authentication state.
 *
 * @returns {boolean} True if the user is authenticated, false otherwise.
 */
export const isAuthenticated = (): boolean => {
    const authState = getAuthState();
    if (!authState) return false;
    return !!authState.isAuthenticated;
};

/**
 * Retrieves the user information from the authentication state.
 * This function ensures that the authentication state and user information are properly accessed and handled.
 *
 * @returns {User | null} The user information if available, otherwise null.
 */
export const getUser = (): User | null => {
    const authState = getAuthState();
    if (!authState || !authState.user) return null;
    return authState.user;
};

export const getUserList = (): any | null => {
    const authState = getAuthState();
    if (!authState || !authState?.usersList) return null;
    return authState?.usersList;
};

export const getDepartmentList = () => {
    const authState = getAuthState();
    if (!authState || !authState.departmentList) return [];
    return authState.departmentList;
};
