import React from "react";

function HomeIcon({ w = 20 }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={w}
            height={w}
            data-name="Light 1px/home"
            viewBox="0 0 25 25"
        >
            <path
                d="M17.708 20.8H3.125A3.128 3.128 0 010 17.671V9.687a3.129 3.129 0 011.034-2.322L8.326.8a3.125 3.125 0 014.181 0L19.8 7.365a3.129 3.129 0 011.034 2.322v7.984a3.128 3.128 0 01-3.126 3.129zM9.375 10.379h2.083a3.129 3.129 0 013.126 3.121v6.25h3.125a2.085 2.085 0 002.083-2.083v-7.98a2.082 2.082 0 00-.69-1.548l-7.291-6.562a2.082 2.082 0 00-2.787 0L1.732 8.139a2.082 2.082 0 00-.69 1.548v7.984a2.085 2.085 0 002.083 2.083H6.25V13.5a3.128 3.128 0 013.125-3.121zm0 1.042A2.085 2.085 0 007.292 13.5v6.25h6.249V13.5a2.085 2.085 0 00-2.083-2.083z"
                data-name="Combined Shape"
                transform="translate(2.083 2.121)"
            ></path>
        </svg>
    );
}

function VideoIcon({ w = 20 }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={w}
            height={w}
            data-name="Group 2320"
            viewBox="0 0 25 25"
        >
            <g data-name="Light 1px/video_recorder">
                <path
                    d="M12.5 14.584H3.125A3.129 3.129 0 010 11.458V3.125A3.128 3.128 0 013.125 0H12.5a3.129 3.129 0 013.126 3.125v1.216l2.646-2.2a1.534 1.534 0 01.992-.365 1.571 1.571 0 011.57 1.565v7.912a1.572 1.572 0 01-1.571 1.566 1.534 1.534 0 01-.991-.365l-2.646-2.2v1.215a3.129 3.129 0 01-3.126 3.115zM3.125 1.042a2.085 2.085 0 00-2.083 2.083v8.333a2.085 2.085 0 002.083 2.083H12.5a2.086 2.086 0 002.084-2.083V3.125A2.086 2.086 0 0012.5 1.042zm16.144 1.772a.512.512 0 00-.331.122L15.625 5.7v3.187l3.312 2.76a.512.512 0 00.331.121.534.534 0 00.363-.144.513.513 0 00.161-.377V3.336a.523.523 0 00-.523-.522z"
                    data-name="Combined Shape"
                    transform="translate(2.083 5.208)"
                ></path>
            </g>
        </svg>
    );
}

function MailIcon({ w = 20 }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={w}
            height={w}
            data-name="Light 1px/mail"
            viewBox="0 0 25 25"
        >
            <path
                d="M17.708 16.667H3.125A3.129 3.129 0 010 13.541V3.125A3.128 3.128 0 013.125 0h14.583a3.128 3.128 0 013.125 3.125v10.416a3.129 3.129 0 01-3.125 3.126zM1.042 3.592v9.95a2.086 2.086 0 002.083 2.084h14.583a2.086 2.086 0 002.083-2.084v-9.95L11.887 9a2.6 2.6 0 01-2.94 0zm2.083-2.55a2.09 2.09 0 00-1.959 1.372L9.534 8.14a1.563 1.563 0 001.764 0l8.369-5.726a2.09 2.09 0 00-1.959-1.372z"
                data-name="Combined Shape"
                transform="translate(2.083 4.167)"
            ></path>
        </svg>
    );
}

function PlanIcon({ w = 20 }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={w}
            height={w}
            data-name="Group 2328"
            viewBox="0 0 25 25"
        >
            <g data-name="Light 1px/calendar">
                <path
                    fill="#fff"
                    d="M0 0H25V25H0z"
                    data-name="Light 1px/calendar background"
                    opacity="0.003"
                ></path>
                <path
                    d="M17.708 20.313H3.125A3.129 3.129 0 010 17.187V4.688a3.128 3.128 0 013.125-3.125h1.563V.521a.521.521 0 111.042 0v1.042h9.37V.521a.521.521 0 111.042 0v1.042h1.563a3.128 3.128 0 013.125 3.125v12.5a3.129 3.129 0 01-3.122 3.125zM1.042 9.375v7.812a2.086 2.086 0 002.083 2.084h14.583a2.086 2.086 0 002.083-2.084V9.375zM3.125 2.6a2.085 2.085 0 00-2.083 2.088v3.645h18.75V4.688A2.085 2.085 0 0017.708 2.6h-1.562v1.046a.521.521 0 01-1.042 0V2.6H5.729v1.046a.521.521 0 01-1.042 0V2.6z"
                    data-name="Combined Shape"
                    transform="translate(2.083 2.604)"
                ></path>
            </g>
        </svg>
    );
}

function TaskIcon({ w = 20 }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={w}
            height={w}
            data-name="Light 1px/check_circled"
            viewBox="0 0 25 25"
        >
            <path
                d="M10.417 20.833a10.417 10.417 0 1110.416-10.416 10.428 10.428 0 01-10.416 10.416zm0-19.792A9.373 9.373 0 1018.3 5.348l-7.82 7.826a1.565 1.565 0 01-2.211 0L4.84 9.743a.521.521 0 11.736-.737l3.43 3.43a.521.521 0 00.737 0L17.685 4.5a9.346 9.346 0 00-7.268-3.458z"
                data-name="Combined Shape"
                transform="translate(2.083 2.083)"
            ></path>
        </svg>
    );
}

function StreamIcon({ w = 20 }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={w}
            height={w}
            data-name="Light 1px/message"
            viewBox="0 0 25 25"
        >
            <g transform="translate(2.083 3.125)">
                <path
                    d="M2.094 19.514a2.137 2.137 0 01-1.451-.578A2.05 2.05 0 010 17.427V3.125A3.128 3.128 0 013.125 0h14.583a3.128 3.128 0 013.125 3.125v10.416a3.129 3.129 0 01-3.125 3.126H6.25l-2.833 2.361a2.048 2.048 0 01-1.323.486zM3.125 1.042a2.085 2.085 0 00-2.083 2.083v14.3a1.048 1.048 0 001.047 1.045 1.025 1.025 0 00.662-.243l2.832-2.361a1.047 1.047 0 01.668-.241h11.457a2.086 2.086 0 002.083-2.084V3.125a2.085 2.085 0 00-2.083-2.083z"
                    data-name="Combined Shape"
                ></path>
                <path
                    d="M.521 0h8.333a.521.521 0 110 1.042H.521A.521.521 0 01.521 0z"
                    data-name="Vector 409 (Stroke)"
                    transform="translate(5.729 5.729)"
                ></path>
                <path
                    d="M.521 0h4.167a.521.521 0 010 1.042H.521A.521.521 0 01.521 0z"
                    data-name="Vector 410 (Stroke)"
                    transform="translate(5.729 9.896)"
                ></path>
            </g>
        </svg>
    );
}

function CRMIcon({ w = 20 }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={w}
            height={w}
            data-name="Light 1px/group"
            viewBox="0 0 25 25"
        >
            <g transform="translate(2.604 3.125)">
                <path
                    d="M4.518 4.688v2.083a.521.521 0 101.042 0V4.688A4.688 4.688 0 00.872 0H0a5.762 5.762 0 011.132 1.051 3.646 3.646 0 013.386 3.637z"
                    transform="translate(14.232 10.938)"
                ></path>
                <path
                    d="M1.042 4.688v2.083a.521.521 0 11-1.042 0V4.688A4.688 4.688 0 014.688 0h6.25a4.687 4.687 0 014.687 4.688v2.083a.521.521 0 11-1.042 0V4.688a3.646 3.646 0 00-3.646-3.646H4.688a3.646 3.646 0 00-3.646 3.646z"
                    data-name="Vector 631 (Stroke)"
                    transform="translate(0 10.938)"
                ></path>
                <path
                    d="M4.037 4.167A3.125 3.125 0 00.913 1.042 5.239 5.239 0 000 .1a4.167 4.167 0 110 8.133 5.239 5.239 0 00.913-.942 3.125 3.125 0 003.124-3.124z"
                    data-name="Subtract"
                    transform="translate(11.067)"
                ></path>
                <path
                    d="M4.166 8.333a4.167 4.167 0 114.167-4.167 4.171 4.171 0 01-4.167 4.167zm0-7.292a3.125 3.125 0 103.126 3.125 3.128 3.128 0 00-3.126-3.124z"
                    data-name="Combined Shape"
                    transform="translate(3.646)"
                ></path>
            </g>
        </svg>
    );
}

function TraxIcon({ w = 20 }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={w}
            height={w}
            data-name="Light 1px/person"
            viewBox="0 0 25 25"
        >
            <g transform="translate(3.646 2.083)">
                <path
                    d="M1.042 5.729v1.042a.521.521 0 11-1.042 0V5.729A5.729 5.729 0 015.729 0h6.25a5.729 5.729 0 015.729 5.729v1.042a.521.521 0 11-1.042 0V5.729a4.687 4.687 0 00-4.687-4.687h-6.25a4.688 4.688 0 00-4.687 4.687z"
                    data-name="Vector 631 (Stroke)"
                    transform="translate(0 13.021)"
                ></path>
                <path
                    d="M5.209 10.417a5.208 5.208 0 115.208-5.208 5.214 5.214 0 01-5.208 5.208zm0-9.375a4.167 4.167 0 104.166 4.167 4.172 4.172 0 00-4.166-4.167z"
                    data-name="Combined Shape"
                    transform="translate(3.646)"
                ></path>
            </g>
        </svg>
    );
}

export {
    HomeIcon,
    VideoIcon,
    MailIcon,
    PlanIcon,
    TaskIcon,
    StreamIcon,
    CRMIcon,
    TraxIcon,
};
