import * as React from "react";
import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import S from "../../../../common/DealStageDropdown/DealStageDropdown.module.css";
import { GreenDropdownIcon } from "../../../../../images";
import { DealStageStatus, predefinedDealStageStatuses } from "../../../../common";

interface DealStatusDropdownTableProps {
  width?: string;
  params: any;
}

const DealStatusDropdownTable: React.FC<DealStatusDropdownTableProps> = ({
  width = "125px",
  params,
  ...props
}) => {
  const defaultInitialValue = params.initialValue || predefinedDealStageStatuses[0];
  const [selectedOption, setSelectedOption] = React.useState<string>(
    defaultInitialValue.name
  );

  const [options, setOption] = React.useState(predefinedDealStageStatuses)

  const [isOpen, setIsOpen] = React.useState(false);

  const onChange = (event: any) => {}

  React.useEffect(() => {
    if (onChange) {
      onChange(selectedOption);
    }
  }, [selectedOption, onChange]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setSelectedOption(value);
    if (onChange) {
      onChange(value);
    }
  };

  const selectedOptionData = options.find(
    (option) => option.name === selectedOption
  );

  const menuProps = {
    PaperProps: {
      className: S["deal-stage-dropdown__menu"],
    },
    anchorOrigin: {
      vertical: "bottom" as "bottom",
      horizontal: "right" as "right",
    },
    transformOrigin: {
      vertical: "top" as "top",
      horizontal: "right" as "right",
    },
  };

  return (
    <div>
      <FormControl size="small" style={{ minWidth: width }} {...props}>
        <Typography
          sx={{
            font: "normal normal 300 10px/13px Source Serif Pro",
            marginBottom: "5px",
          }}
          children={"Deal stage"}
        />
        <Select
          IconComponent={() => <GreenDropdownIcon open={isOpen} />}
          value={selectedOption}
          onChange={handleChange}
          input={
            <OutlinedInput
              className={S["deal-stage-dropdown__inputbox"]}
              classes={{
                notchedOutline: S["deal-stage-dropdown__notched-outline"],
                focused: S["deal-stage-dropdown__focused"],
              }}
            />
          }
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          renderValue={(selected) => {
            if (!selected) {
              return (
                <em className={S["deal-stage-dropdown__select-placeholder"]}>
                  Select option
                </em>
              );
            }

            return (
              <span className={S["deal-stage-dropdown__selected"]}>
                {selectedOptionData?.name || "Select Status"}
                <span
                  style={{
                    borderRadius: "50%",
                    backgroundColor: selectedOptionData?.color || "black",
                    width: "10px",
                    height: "10px",
                    marginLeft: "5px",
                  }}
                ></span>
              </span>
            );
          }}
          MenuProps={menuProps}
        >
          {options.map((option) => (
            <MenuItem
              className={S["deal-stage-dropdown__menu-item"]}
              key={option.id}
              value={option.name}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                width="100%"
              >
                <Typography className={S["deal-stage-dropdown__name"]}>
                  {option.name}
                </Typography>
                <div
                  style={{
                    borderRadius: "50%",
                    backgroundColor: option?.color || "black",
                    width: "10px",
                    height: "10px",
                    marginLeft: "5px",
                  }}
                ></div>
              </Stack>
            </MenuItem>
          ))}
          <MenuItem className={S["deal-stage-dropdown__menu-item"]}>
            <Stack direction="row" justifyContent="space-between" width="100%">
              <Typography className={S["deal-stage-dropdown__name"]}>
                Add Custome
              </Typography>
            </Stack>
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default DealStatusDropdownTable;
