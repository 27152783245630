import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { ConnectedIcon, PlusIcon } from "../../../../../../../images";

const ConnectedToTask: React.FC = () => {
  return (
    <Stack>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack sx={{ flexDirection: "row", gap: "10px", alignItems: "center" }}>
          <ConnectedIcon w={24} />
          <Typography
            sx={{ font: "normal normal normal 18px/25px Source Serif Pro" }}
          >
            Connect to task
          </Typography>
        </Stack>

        <Tooltip title="Connect to task" placement="top" arrow>
          <IconButton sx={{ padding: 1 }} onClick={() => null}>
            <PlusIcon w={14} />
          </IconButton>
        </Tooltip>
      </Stack>

      {/* when something added then render here */}
      {/* <Stack>connect to task element will render here</Stack> */}
    </Stack>
  );
};

export default ConnectedToTask;
