import { GoogleIcon, MicorsoftIcon, SSOIcon } from "../images";

export const SIGN_UP_OPTIONS_CONTANTS = [
    {
      title: "cwg",
      i: <GoogleIcon />,
      index: 1,
      authState: "GoogleAuth"
    },
    {
      title: "cwm",
      i: <MicorsoftIcon />,
      index: 2,
      authState: "MicrosoftOAuth"
    },
    {
      title: "cwsso",
      i: <SSOIcon />,
      index: 3,
      authState: "bearisSSO"
    },
  ];