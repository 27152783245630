import { bearishOSOctaIcon, GoogleMeetLogoPng, MicrosoftTeamsLogoPng, WebexPng, ZoomLogoPng, ZoomVideoPng } from "../../../images";

export interface DropdownShortLargeListInterface {
    title: string;
    imgSrc: string
}

export const DropdownShortLargeList: DropdownShortLargeListInterface[] = [
    { title: "Bearish OS Video Calls", imgSrc: bearishOSOctaIcon },
    { title: "Zoom", imgSrc: ZoomVideoPng },
    { title: "Google Meet", imgSrc: GoogleMeetLogoPng },
    { title: "Cisco Webex", imgSrc: WebexPng },
    { title: "Microsoft Teams", imgSrc: MicrosoftTeamsLogoPng },
]