import {
  General as AppGeneral,
  Notification as AppNotifications,
  Security as AppSecurity,
} from "./Components";

export type SettingsPaths =
  | "/Settings/general"
  | "/Settings/app-notifications"
  | "/Settings/app-security";

export const SettingsComponentMap: Record<SettingsPaths, React.ComponentType> =
{
  "/Settings/general": AppGeneral,
  "/Settings/app-notifications": AppNotifications,
  "/Settings/app-security": AppSecurity,
};
