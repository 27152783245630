export enum ColumnId {
  Device = "device",
  Location = "location",
  Time = "time",
  Logout = "logout",
}

export enum DeviceType {
  Mac = "mac",
  Ipad = "ipad",
  Iphone = "iphone",
}

export interface Column {
  id: ColumnId;
  label: string;
  minWidth?: number;
}

export type TableRowType = {
  id: string;
  device: DeviceType;
  browser: string;
  location: string;
  ipAddress: string;
  date: string;
  time: string;
};

export interface MediumTableAndButtonsProps {
  deviceData: TableRowType[];
}
