import { Stack, Typography } from "@mui/material";
import React from "react";
import { dCompany } from "../../../../../images";

interface CompanyOption {
  claimed: boolean;
  name: string | null;
  domain: string;
  icon: string | null;
  brandId: string; // Unique identifier
}

interface Props {
  company: CompanyOption;
}

const CompanyDetails: React.FC<Props> = ({ company }) => {
  return (
    <Stack
      direction={"row"}
      gap={"10px"}
      alignContent={"center"}
      justifyContent={"center"}
    >
      {company.icon ? (
        <Stack>
          <img
            style={{
              objectFit: "cover",
              borderRadius: "50%",
              width:"35px",
              height:"35px",
            }}
            src={company.icon}
            alt=""
          />
        </Stack>
      ) : (
        <Stack>
          <img
            style={{
              objectFit: "cover",
              borderRadius: "50%",
              width:"35px",
              height:"35px",
            }}
            src={dCompany}
            alt=""
          />
        </Stack>
      )}
      <Stack gap={"3px"}>
        {/* Render other details */}
        <Typography
          sx={{ font: "normal normal 500 18px/17px Source Serif Pro" }}
        >
          {company.name || "Unknown Company"}
        </Typography>
        <Typography
          sx={{
            font: "normal normal 300 13px/17px Source Serif Pro",
            color: "blue",
          }}
        >
          {company.domain || "Unknown Domain"}
        </Typography>
        {/* Add more details as needed */}
      </Stack>
    </Stack>
  );
};

export default CompanyDetails;
