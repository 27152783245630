import {
  Stack,
  Typography,
  styled,
  Tooltip,
  TooltipProps,
} from "@mui/material";
import React, { lazy, Suspense, useState } from "react";
import {
  a11yProps,
  StyledTab,
  TabPanel,
  TabsWithState,
} from "../../../../common/CardTabsWithoutIcon";

interface CreateNewMessagesProps {}

const LazyInternalChatTab = lazy(
  () => import("./tabs/InternalChatTab/InternalChatTab")
);
const LazyExternalChatTab = lazy(
  () => import("./tabs/ExternalChatTab/ExternalChatTab")
);
const LazyChannelTab = lazy(() => import("./tabs/ChannelTab/ChannelTab"));

const tabs: {
  label: string;
  content: React.ReactNode;
  tooltipText: string;
}[] = [
  {
    label: "Internal Chat",
    content: <LazyInternalChatTab />,
    tooltipText:
      "Internal Chats are chats that happen between team members within your organization.",
  },
  {
    label: "External Chat",
    content: <LazyExternalChatTab />,
    tooltipText:
      "External Chats are chats that take place between you and external customers, contractors, or other parties.",
  },
  {
    label: "Channel",
    content: <LazyChannelTab />,
    tooltipText:
      "Channels are places where you and other stakeholders can discuss and chat about your projects and tasks.",
  },
];

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#616161", // Background color
    maxWidth: 200, // Tooltip width
    font: "normal normal 15px/20px Source Sarif pro",
  },
  [`& .MuiTooltip-arrow`]: {
    color: "#616161", // Arrow color same as background
  },
}));

// Usage

const CreateNewMessages: React.FC<CreateNewMessagesProps> = ({}) => {
  const [tabValue, setTabValue] = useState(0);
  return (
    <Stack
      sx={{
        width: "360px",
        minHeight: "340px",
        padding: "15px",
        boxSizing: "border-box",
        gap: "10px",
        bgcolor: "white",
      }}
    >
      <Typography sx={{ font: "normal normal 700 15px/19px Source Serif Pro" }}>
        Create new...
      </Typography>

      <TabsWithState
        ariaLabel="channel details tabs"
        value={tabValue}
        onChange={(event, newValue) => setTabValue(newValue)}
      >
        {tabs.map((tab, index) => (
          <CustomTooltip
            title={tab.tooltipText}
            arrow
            placement="top"
            enterDelay={1000}
          >
            <StyledTab
              key={index}
              disableRipple
              label={tab.label}
              {...a11yProps(index)}
            />
          </CustomTooltip>
        ))}
      </TabsWithState>

      {tabs.map((tab, index) => (
        <TabPanel
          style={{ display: "contents" }}
          key={index}
          value={tabValue}
          index={index}
        >
          <Suspense fallback={<div>Loading...</div>}>{tab.content}</Suspense>
        </TabPanel>
      ))}
    </Stack>
  );
};

export default CreateNewMessages;
