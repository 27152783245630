import React, { useState } from "react";
import {
  MenuItem,
  Stack,
  Typography,
  InputBase,
  IconButton,
} from "@mui/material";
import {
  SearchIcon,
  DragIcon,
  ImportCSVIcon,
  StoreIcon,
} from "../../../../../../images";
import {
  allowDrop,
  handleDragStart,
  handleDrop,
  MenuItemData,
} from "../menuUtils";
import g from "../DropDownButton.module.css";
export const sortOrFilterItemMenu: MenuItemData[] = [
  {
    icon: <ImportCSVIcon />,
    label: "Data ID number",
    draggable: true,
  },

  {
    icon: <ImportCSVIcon />,
    label: "Domain",
    draggable: true,
  },
  {
    icon: <ImportCSVIcon />,
    label: "Full name",
    draggable: true,
  },

  {
    icon: <ImportCSVIcon />,
    label: "Description",
    draggable: true,
  },

  {
    icon: <ImportCSVIcon />,
    label: "Teams",
    draggable: true,
  },
  {
    icon: <ImportCSVIcon />,
    label: "Categories",
    draggable: true,
  },
  {
    icon: <ImportCSVIcon />,
    label: "Primary location",
    draggable: true,
  },
  {
    icon: <ImportCSVIcon />,
    label: "AngelList",
    draggable: true,
  },
  {
    icon: <ImportCSVIcon />,
    label: "Facebook",
    draggable: true,
  },

  {
    icon: <ImportCSVIcon />,
    label: "Help me import",
    draggable: false,
  },
];
type SortAndFilterMenuProps = {
  label: string;
  menuItems: MenuItemData[];
  setMenuItems: React.Dispatch<React.SetStateAction<MenuItemData[]>>;
};

export const SortAndFilterMenu: React.FC<SortAndFilterMenuProps> = ({
  label,
  menuItems,
  setMenuItems,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value);
  };

  const filteredItems = menuItems.filter((item) =>
    item.label?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      {/* <IconButton aria-label="Sort and Filter">
        <Stack direction="row" alignItems="center" spacing={1}>
          {label === "Sort" ? <SearchIcon /> : <SearchIcon />}
          <Typography className={g.labelSortFilter}>{label}</Typography>
        </Stack>
      </IconButton> */}

      {true && (
        <div>
          <MenuItem sx={{ width: "100%" }}>
            <InputBase
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchInputChange}
            />
            <IconButton disabled>
              <SearchIcon />
            </IconButton>
          </MenuItem>

          {filteredItems.map((item, index) => (
            <div
              data-index={index}
              draggable={item.draggable}
              onDragStart={(e) => handleDragStart(e, index)}
              onDrop={(e) => handleDrop(e, menuItems, setMenuItems)}
              onDragOver={allowDrop}
            >
              <MenuItem key={index}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  {" "}
                  <IconButton
                    style={{ cursor: item.draggable ? "move" : "default" }}
                  >
                    {item.draggable && <DragIcon />}
                  </IconButton>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    gap={1}
                  >
                    {item.icon ? item.icon : <StoreIcon />}
                    <Typography className={g.boxDropDownOption}>
                      {item.label}
                    </Typography>
                  </Stack>
                </Stack>
              </MenuItem>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
