import React from "react";
import { IconButton, IconButtonProps } from "@mui/material";
import { ButtonType, SendMessageButtonColor } from "../AllButtonProps";
import { SendIcon } from "../../../../images";
import { adjustHexColor } from "../../../../Utils";

interface SendMessageButtonProps extends IconButtonProps {
  colorVarient: SendMessageButtonColor;
  buttonSize?: number;
  types?: ButtonType;
}

const SendMessageButton: React.FC<SendMessageButtonProps> = ({
  colorVarient,
  buttonSize = 30,
  types = ButtonType.Button,
  ...props
}) => {
  let bgColor: string;

  switch (colorVarient) {
    case SendMessageButtonColor.Blue:
      bgColor = "#2A30B5";
      break;
    case SendMessageButtonColor.MessagePurple:
      bgColor = "#775EE2";
      break;
    default:
      bgColor = "#775EE2"; // Provide a default value if needed
      break;
  }
  return (
    <IconButton
      sx={{
        height: buttonSize,
        width: buttonSize,
        p: 0,
        borderRadius: "5px",
        bgcolor: bgColor,
        border: "2px solid #E9EDF2",
        boxSizing: "border-box",
        ":hover": { bgcolor: adjustHexColor(bgColor, 90) },
      }}
      type={types}
      {...props}
      disableRipple
      aria-label="Send Message"
    >
      <SendIcon w={buttonSize - 10} color="#fff" />
    </IconButton>
  );
};

export default SendMessageButton;
