import {
  Box,
  Divider,
  IconButton,
  Popover,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { MouseEvent, useState } from "react";
import { MarkerIcon } from "../../../images";
import { iconButtonStyles } from "../../../Utils";

interface MarkerButtonProps {
  selectedTool?: string;
  handleSelectTool?: (toolName: string) => void;
  initialValue?: string;
  direction?: "horizontal" | "vertical";
  togglePencilMode?: (
    wantPenMode: boolean,
    colorValue: string,
    pencilType: string,
    strokeSize: number
  ) => void;
  isPencilModeActive?: boolean;
}

const MarkerButton: React.FC<MarkerButtonProps> = ({
  selectedTool,
  handleSelectTool,
  initialValue,
  direction = "vertical",
  togglePencilMode,
  isPencilModeActive,
}) => {
  const colorsList: { colorName: string; colorValue: string }[] = [
    { colorName: "Red", colorValue: "#FF0000" },
    { colorName: "Cancelled", colorValue: "#757575" },
    { colorName: "CRM Orange", colorValue: "#E2875E" },
    { colorName: "Calls Salmon", colorValue: "#FF8181" },
    { colorName: "Mid Green", colorValue: "#21B481" },
    { colorName: "Purple", colorValue: "#4500FF" },
    { colorName: "Blue", colorValue: "#0027FF" },
    { colorName: "Light Blue", colorValue: "#E9EDF2" },
    { colorName: "Black", colorValue: "#000000" },
  ];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedColor, setSelectedColor] = useState<string>(
    initialValue || colorsList[8].colorValue
  );

  const [strokeSize, setStrokeSize] = useState<number>(5);

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    if (togglePencilMode) {
      togglePencilMode(
        false,
        "black",
        "PencilBrush",
        strokeSize !== 0 ? strokeSize : 5
      );
    }
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleColorSelect = (colorValue: string) => {
    if (togglePencilMode) {
      togglePencilMode(
        true,
        colorValue,
        "PencilBrush",
        strokeSize !== 0 ? strokeSize : 5
      );
    }
    setSelectedColor(colorValue);
    if (handleSelectTool) {
      handleSelectTool("Marker");
    }
  };


  return (
    <>
      <Tooltip title={"Write"} placement="top" arrow>
        <IconButton
          sx={iconButtonStyles(isPencilModeActive ? isPencilModeActive : false)}
          disableRipple
          onClick={handlePopoverOpen}
        >
          <MarkerIcon w={25} />
        </IconButton>
      </Tooltip>

      <Popover
        open={open}
        container={document && document?.getElementById("full-ScreenMode")}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: direction === "horizontal" ? "top" : "top",
          horizontal: direction === "horizontal" ? "left" : "right",
        }}
        transformOrigin={{
          vertical: direction === "horizontal" ? "bottom" : "top",
          horizontal: direction === "horizontal" ? "left" : "left",
        }}
        sx={{
          marginBottom: direction === "horizontal" ? "25px" : 0,
          marginLeft: direction === "vertical" ? "25px" : 0,
        }}
        PaperProps={{
          style: { boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)" },
        }}
      >
        <Stack
          sx={{
            bgcolor: "white",
            padding: "10px 10px",
            gap: "15px",
            flexDirection: direction === "horizontal" ? "row" : "column",
          }}
        >
          <Stack
            sx={{ flexDirection: "inherit", gap: "15px", alignItems: "center" }}
          >
            {[8, 12, 14].map((size) => (
              <Box
                key={size}
                onClick={() => {
                  setStrokeSize(size);
                }}
                sx={{
                  width: `${size}px`,
                  height: `${size}px`,
                  bgcolor: selectedColor,
                  borderRadius: "100px",
                }}
              />
            ))}
          </Stack>

          {direction === "horizontal" && (
            <Stack>
              {" "}
              <Divider orientation="vertical" />{" "}
            </Stack>
          )}
          {direction === "vertical" && <Divider orientation="horizontal" />}

          <Stack
            sx={{ gap: "15px", flexDirection: "inherit", alignItems: "center" }}
          >
            {colorsList.map((color, index) => (
              <Box
                key={index}
                onClick={() => handleColorSelect(color.colorValue)}
                sx={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "100px",
                  bgcolor: color.colorValue,
                  cursor: "pointer",
                }}
              />
            ))}
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};

export default MarkerButton;
