import { Avatar, SxProps, Typography } from "@mui/material";
import React from "react";
import { avatarInitialLetters } from "../../../Utils";

interface AvatarNormalProps {
  size: number;
  username: string;
  imgSrc?: string;
  avatarColor?: string;
  sx?: SxProps;
}

const AvatarNormal: React.FC<AvatarNormalProps> = ({
  size,
  username,
  avatarColor,
  imgSrc,
  sx,
}) => {
  const fontSize = Math.floor(size * 0.4);
  const lineHeight = Math.floor(size * 0.6);

  return (
    <Avatar
      sx={{
        width: size,
        height: size,
        backgroundColor: avatarColor || "default",
        boxSizing: "border-box",
        alignItems: "center",
        justifyContent: "center",
        font: `normal normal 600 ${fontSize}px/${fontSize}px Source Serif Pro`,
        ...sx,
      }}
      src={imgSrc}
      alt={avatarInitialLetters(username)}
    >
      {/* <Typography
        sx={{
          font: "inherit",
          textAlign: "center",
          color: "inherit",
        }}
      > */}
        {avatarInitialLetters(username)}
      {/* </Typography> */}
    </Avatar>
  );
};

export default AvatarNormal;
