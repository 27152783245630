import React, { useState } from "react";
import { IconButton, Stack, TextareaAutosize, Typography } from "@mui/material";
import { DragAndDropIcon } from "../../../images";
import { CheckboxMedium } from "../CheckboxMedium";
import { IconDropdownUser } from "../IconDropdownUser";
import { IconDropdownUserOptionInterface } from "../IconDropdownUser/IconDropdownUserDataTypes";

interface SmallChecklistProps {
  value: string;
  onSave: (value: string) => void;
}

const SmallChecklist: React.FC<SmallChecklistProps> = ({ value, onSave }) => {
  return (
    <Stack>
      <Stack
        sx={{
          height: "35px",
          bgcolor: "rgba(233, 237, 242,0.3)",
          flexDirection: "row",
          alignItems: "center",
          boxSizing: "border-box",
          paddingLeft: "10px",
          border: "0.5px solid #E9EDF2",
          borderRadius: "5px 5px 0px 0px",
        }}
      >
        <Typography
          sx={{ font: "normal normal normal 14px/20px Source Serif Pro" }}
        >
          Checklist
        </Typography>
      </Stack>
      <Stack
        sx={{
          borderBottom: "0.5px solid #E9EDF2",
          borderLeft: "0.5px solid #E9EDF2",
          borderRight: "0.5px solid #E9EDF2",
          borderRadius: "0px 0px 5px 5px",
        }}
      >
        {/* this component is inline and locate the bottom of this file */}
        <CheckListContainer value={value} onSave={onSave} />
        <CheckListContainer value={value} onSave={onSave} />
        <CheckListContainer value={value} onSave={onSave} />
      </Stack>
    </Stack>
  );
};

export default SmallChecklist;

// inline component interface
interface CheckListContainerProps {
  value: string;
  onSave: (value: string) => void;
}

// inline component for display checklist text area and drap and drop options
const CheckListContainer: React.FC<CheckListContainerProps> = ({
  value,
  onSave,
}) => {
  const [inputValue, setInputValue] = useState(value);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };

  const handleBlur = () => {
    onSave(inputValue);
  };

  return (
    <Stack
      sx={{
        minHeight: "40px",
        flexDirection: "row",
        alignItems: "flex-start",
        padding: "15px 10px 10px 10px",
        gap: "10px",
        boxSizing: "border-box",
        borderTop: "0.5px solid #E9EDF2",
      }}
    >
      <IconButton sx={{ p: 0 }}>
        <DragAndDropIcon />
      </IconButton>

      <CheckboxMedium />

      <TextareaAutosize
        placeholder="Add your checklist"
        minRows={1}
        value={inputValue}
        onChange={handleChange}
        onBlur={handleBlur}
        style={{
          height: "20px",
          flexGrow: 1,
          resize: "none",
          border: "0px",
          outline: "none",
          paddingRight: "45px",
          font: "normal normal 300 14px/20px Source Serif Pro",
        }}
      />

      <IconDropdownUser
        componentType="showUser"
        showSelectedUser={DUMMY_DATA[2]}
        options={DUMMY_DATA}
        onSelect={(item) => console.log(item)}
      />
    </Stack>
  );
};

const DUMMY_DATA: IconDropdownUserOptionInterface[] = [
  {
    id: 1,
    name: "Richard Cornsmith",
    imgSrc: "https://randomuser.me/api/portraits/men/1.jpg",
    avatarColor: "#FF5733",
    email: "richard.cornsmith@example.com",
  },
  {
    id: 2,
    name: "John Doe",
    email: "john.doe@example.com",
  },
  {
    id: 3,
    name: "Jane Smith",
    imgSrc: "https://randomuser.me/api/portraits/women/3.jpg",
    avatarColor: "#3357FF",
    email: "jane.smith@example.com",
  },
  {
    id: 4,
    name: "Alice Johnson",
    imgSrc: "https://randomuser.me/api/portraits/women/4.jpg",
    avatarColor: "#FF33A6",
    email: "alice.johnson@example.com",
  },
  {
    id: 5,
    name: "Bob Brown",
    avatarColor: "#33FFA2",
    email: "bob.brown@example.com",
  },
  {
    id: 6,
    name: "Charlie Davis",
    email: "charlie.davis@example.com",
  },
  {
    id: 7,
    name: "Diana Evans",
    imgSrc: "https://randomuser.me/api/portraits/women/7.jpg",
    avatarColor: "#FFA233",
    email: "diana.evans@example.com",
  },
  {
    id: 8,
    name: "Edward Harris",
    avatarColor: "#33D6FF",
    email: "edward.harris@example.com",
  },
  {
    id: 9,
    name: "Fiona Green",
    imgSrc: "https://randomuser.me/api/portraits/women/9.jpg",
    avatarColor: "#FF5733",
    email: "fiona.green@example.com",
  },
  {
    id: 10,
    name: "George Hill",
    imgSrc: "https://randomuser.me/api/portraits/men/10.jpg",
    avatarColor: "#33FF57",
    email: "george.hill@example.com",
  },
  {
    id: 11,
    name: "Hannah Lee",
    imgSrc: "https://randomuser.me/api/portraits/women/11.jpg",
    avatarColor: "#3357FF",
    email: "hannah.lee@example.com",
  },
  {
    id: 12,
    name: "Ian Kelly",
    imgSrc: "https://randomuser.me/api/portraits/men/12.jpg",
    avatarColor: "#FF33A6",
    email: "ian.kelly@example.com",
  },
  {
    id: 13,
    name: "Jackie Moore",
    imgSrc: "https://randomuser.me/api/portraits/women/13.jpg",
    avatarColor: "#33FFA2",
    email: "jackie.moore@example.com",
  },
  {
    id: 14,
    name: "Kevin Nelson",
    imgSrc: "https://randomuser.me/api/portraits/men/14.jpg",
    avatarColor: "#A233FF",
    email: "kevin.nelson@example.com",
  },
  {
    id: 15,
    name: "Laura Owens",
    imgSrc: "https://randomuser.me/api/portraits/women/15.jpg",
    avatarColor: "#FFA233",
    email: "laura.owens@example.com",
  },
  {
    id: 16,
    name: "Michael Perez",
    imgSrc: "https://randomuser.me/api/portraits/men/16.jpg",
    avatarColor: "#33D6FF",
    email: "michael.perez@example.com",
  },
  {
    id: 17,
    name: "Nancy Queen",
    imgSrc: "https://randomuser.me/api/portraits/women/17.jpg",
    avatarColor: "#FF5733",
    email: "nancy.queen@example.com",
  },
  {
    id: 18,
    name: "Oscar Roberts",
    imgSrc: "https://randomuser.me/api/portraits/men/18.jpg",
    avatarColor: "#33FF57",
    email: "oscar.roberts@example.com",
  },
  {
    id: 19,
    name: "Paula Scott",
    imgSrc: "https://randomuser.me/api/portraits/women/19.jpg",
    avatarColor: "#3357FF",
    email: "paula.scott@example.com",
  },
  {
    id: 20,
    name: "Quincy Turner",
    imgSrc: "https://randomuser.me/api/portraits/men/20.jpg",
    avatarColor: "#FF33A6",
    email: "quincy.turner@example.com",
  },
];
