import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { ChevronIcon } from "../../../images";

interface TextSizeDropdownProps {
  initialSize?: string; // Initial text size as a string (optional)
  minValue?: number;
  maxValue?: number;
  onSizeChange?: (size: string) => void; // Callback to return the updated size as a string
}

const TextSizeDropdown: React.FC<TextSizeDropdownProps> = ({
  initialSize = "15",
  minValue = 10,
  maxValue = 100,
  onSizeChange,
}) => {
  const [textSize, setTextSize] = useState<number>(parseInt(initialSize));
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const isMounted = useRef<boolean>(false);

  const updateTextSize = (value: number) => {
    const newSize = Math.max(minValue, Math.min(maxValue, value));
    setTextSize(newSize);
  };

  const increaseHandler = () => {
    updateTextSize(textSize + 1);
  };

  const decreaseHandler = () => {
    updateTextSize(textSize - 1);
  };

  const handlePointerDown = (handler: () => void) => {
    handler();
    intervalRef.current = setInterval(handler, 100);
  };

  const handlePointerUp = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true; // Mark as mounted
    } else if (onSizeChange) {
      onSizeChange(textSize.toString());
    }
  }, [textSize, onSizeChange]);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return (
    <Stack
      sx={{
        width: "65px",
        height: "15px",
        flexDirection: "row",
        alignItems: "center",
        flexShrink: 0,
        gap: "10px",
        boxSizing: "border-box",
      }}
    >
      <IconButton
        sx={{ p: 0, borderRadius: 0 }}
        disableRipple
        onPointerDown={() => handlePointerDown(increaseHandler)}
        onPointerUp={handlePointerUp}
        onPointerCancel={handlePointerUp}
      >
        <ChevronIcon w={15} direction="up" />
      </IconButton>
      <Box>
        <Typography
          sx={{ font: "normal normal 600 14px/18px Source Serif Pro" }}
        >
          {textSize}
        </Typography>
      </Box>
      <IconButton
        sx={{ p: 0, borderRadius: 0 }}
        disableRipple
        onPointerDown={() => handlePointerDown(decreaseHandler)}
        onPointerUp={handlePointerUp}
        onPointerCancel={handlePointerUp}
      >
        <ChevronIcon w={15} direction="down" />
      </IconButton>
    </Stack>
  );
};

export default TextSizeDropdown;
