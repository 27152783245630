// MainTopWorkspace.tsx

import React, { useEffect, useRef, useState } from "react";
import { Stack, Popover } from "@mui/material";
import {
  MediumSearchbar,
  SwitchSmall,
  XlargeCreateButton,
  XlargeCreateButtonColor,
} from "../../../../common";
import {
  ConnectionItem,
  CreateCardData,
  ShareWorkspaceItem,
  initialCreateCardData,
} from "./types";
import { ProjectIcon } from "../../../../../images";
import { HugeCreateItemWorkspace } from "../HugeCreateItemWorkspace";
import { MainTopWorkspaceProps } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import {
  AddWorkspaceMember,
  addWorkspaceMember__api,
  // CreateWorkspace,
  createWorkspace__api,
  // Description,
  // Formatting,
  // UpdateWorkspace,
  WorkspaceState,
} from "../../../../../redux";
import {
  //  getUser,
  isAuthenticated,
} from "../../../../../Utils";
const MainTopWorkspace: React.FC<MainTopWorkspaceProps> = ({
  viewMode,
  setViewMode,
  getAllWorkspace,
  handleEditPopUp,
  editOpenPopUp,
  intialData,
  initialDataOfWorkspaceId,
  updateWorkspace,
  iconApp,
  theme,
}) => {
  const auth = isAuthenticated();
  const dispatch = useDispatch();
  const { error } = useSelector(
    (state: RootState) => state && (state?.workspace as WorkspaceState)
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [createCardData, setCreateCardData] = useState<CreateCardData>(
    intialData ? intialData : initialCreateCardData
  );

  // console.log(createCardData, "createCardData");

  const [createLoader, setCreateLoader] = useState<boolean>(false);

  const popRef = useRef(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (handleEditPopUp) {
      handleEditPopUp();
    }
  };

  useEffect(() => {
    if (editOpenPopUp && intialData) {
      setCreateCardData(intialData);
      setAnchorEl(popRef.current);
    }
  }, [editOpenPopUp]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const updateMainDetails = (value: Partial<CreateCardData["mainDetails"]>) => {
    setCreateCardData((prevData) => ({
      ...prevData,
      mainDetails: {
        ...prevData.mainDetails,
        ...value,
      },
    }));
  };

  const updateConnections = (
    value: Partial<{ connectionList: ConnectionItem[] }>
  ) => {
    setCreateCardData((prevData) => {
      const existingConnectionList = prevData.connections.connectionList;
      const newConnections =
        value.connectionList?.filter(
          (newConnection) =>
            newConnection &&
            typeof newConnection.id === "string" &&
            typeof newConnection.text === "string" &&
            !existingConnectionList.some(
              (existingConnection) => existingConnection.id === newConnection.id
            )
        ) || [];
      return {
        ...prevData,
        connections: {
          ...prevData.connections,
          connectionList: [...existingConnectionList, ...newConnections],
        },
      };
    });
  };

  const updateShareWorkspace = (
    value: Partial<{ shareWorkspaceList: ShareWorkspaceItem[] }>
  ) => {
    setCreateCardData((prevData) => {
      const existingShareWorkspaceList =
        prevData.shareWorkspace.shareWorkspaceList;
      const newShareWorkspaceItems =
        value.shareWorkspaceList?.filter(
          (newItem) =>
            newItem &&
            typeof newItem.userId === "string" &&
            typeof newItem.text === "string" &&
            typeof newItem.value === "string" &&
            !existingShareWorkspaceList.some(
              (existingItem) => existingItem.userId === newItem.userId
            )
        ) || [];
      return {
        ...prevData,
        shareWorkspace: {
          ...prevData.shareWorkspace,
          shareWorkspaceList: [
            ...existingShareWorkspaceList,
            ...newShareWorkspaceItems,
          ],
        },
      };
    });
  };

  const handleCreateWorkspace = async () => {
    const formData = new FormData();

    if (editOpenPopUp && updateWorkspace && initialDataOfWorkspaceId) {
      const emojis = ["🫠", "🌟", "🚀", "🎉", "🧩"];
      const getRandomEmoji = () =>
        emojis[Math.floor(Math.random() * emojis.length)];

      // Add form data for editing a workspace
      formData.append("name", createCardData?.mainDetails?.workspaceName);

      formData.append(
        "description[content]",
        createCardData?.mainDetails?.workspaceDetails
      );

      // Check if uploadWorkspaceImage is a File object
      const coverImage = createCardData?.mainDetails?.uploadWorkspaceImage;

      if (coverImage instanceof File) {
        formData.append(
          "appearance[coverImage]",
          coverImage || intialData?.mainDetails?.uploadWorkspaceImage
        );
      } else {
        formData.append(
          "appearance[coverImage]",
          coverImage || intialData?.mainDetails?.uploadWorkspaceImage
        );
        console.warn(
          "Cover image is not a file or user specified default image"
        );
        // Handle non-file scenarios if needed (e.g., URLs)
      }

      formData.append("appearance[icon]", iconApp || getRandomEmoji());

      formData.append("appearance[theme][primaryColor]", theme?.primaryColor);

      setCreateLoader(true);
      await updateWorkspace(formData, initialDataOfWorkspaceId);

      if (!error) {
        setCreateCardData(initialCreateCardData);
      }
      setCreateLoader(false);
      handleClose();
      await getAllWorkspace();
    } else {
      try {
        if (!auth) return;

        formData.append("name", createCardData.mainDetails.workspaceName);
        formData.append("visibility", "public");

        // Handle cover image
        const coverImage = createCardData?.mainDetails?.uploadWorkspaceImage;
        if (coverImage instanceof File) {
          formData.append("appearance[coverImage]", coverImage);
        } else {
          formData.append("appearance[coverImage]", coverImage);
          console.warn("Cover image is not a file:", coverImage);
          // Handle non-file scenarios if needed (e.g., URLs)
        }

        formData.append("appearance[icon]", "🍊");
        formData.append("appearance[theme][primaryColor]", "#ff5733");
        formData.append("appearance[theme][secondaryColor]", "#33ff57");
        formData.append("appearance[theme][textColor]", "#333333");
        formData.append("appearance[theme][font]", "Avenir Next");

        // Members
        createCardData.connections.connectionList.forEach((member, index) => {
          formData.append(`members[${index}]`, JSON.stringify(member));
        });

        // Custom Fields
        formData.append("customFields[0][name]", "Custom1");
        formData.append("customFields[0][type]", "text");
        formData.append("customFields[0][options]", "");
        formData.append("customFields[0][value]", "Sample Value");

        // // Reminders
        // createCardData.shareWorkspace.shareWorkspaceList.forEach(
        //   (reminder, index) => {
        //     formData.append(`reminders[${index}]`, JSON.stringify(reminder));
        //   }
        // );

        setCreateLoader(true);
        const res: any = await dispatch(createWorkspace__api(formData));
        console.log(res?.data?._id, "createWorkspace on  success");

        if (res?.data?._id) {
          const workspaceId = res?.data?._id;
          const shareWorkspaceList =
            createCardData.shareWorkspace.shareWorkspaceList;

          // Check if the shareWorkspaceList is not empty
          if (shareWorkspaceList && shareWorkspaceList.length > 0) {
            // console.log(
            //   shareWorkspaceList,
            //   "createCardData.shareWorkspace.shareWorkspaceList"
            // );

            // Iterate over each member in the shareWorkspaceList
            for (const member of shareWorkspaceList) {
              const payload: AddWorkspaceMember = {
                memberId: member.userId,
                memberRole: member.role,
                permissions: member.permissions,
              };

              try {
                // Dispatch the action to add the member to the workspace
                const action = addWorkspaceMember__api(payload, workspaceId);
                await dispatch(action);
                console.log(
                  `Added member ${member.userId} to workspace ${workspaceId}`
                );
              } catch (error) {
                console.error(`Error adding member ${member.userId}:`, error);
              }
            }
          } else {
            console.log("No members to add in the shareWorkspaceList.");
          }
        }

        setCreateCardData(initialCreateCardData);
        setCreateLoader(false);
        handleClose();
        await getAllWorkspace();
      } catch (error) {
        setCreateLoader(false);
        handleClose();
        console.error("Error creating workspace:", error);
      }
    }
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className="boxProject" // Replace with your actual CSS class name
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          gap={1}
        >
          <XlargeCreateButton
            label="Create a New Workspace"
            subtitle="Workspaces hold Docs, Lists, Wikis, and More"
            colorVarient={XlargeCreateButtonColor.Orange}
            icon={<ProjectIcon color="white" />}
            onClick={handleClick}
            popRef={popRef}
          />
          <XlargeCreateButton
            label="Cloud Storage"
            subtitle="Open Cloud Storage panel to the right of this screen"
            colorVarient={XlargeCreateButtonColor.LightOrange}
            icon={<ProjectIcon color="white" />}
            // onClick={handleClick}
          />
        </Stack>
        <Stack direction="row" alignItems="center" gap={2}>
          <SwitchSmall viewMode={viewMode} setViewMode={setViewMode} />
          <MediumSearchbar ph={"Search all Workspace"} />
        </Stack>
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <HugeCreateItemWorkspace
          createCardData={createCardData}
          updateMainDetails={updateMainDetails}
          updateConnections={updateConnections}
          loader={createLoader}
          handleClickButton={handleCreateWorkspace}
          updateShareWorkspace={updateShareWorkspace}
          editOpenPopUp={editOpenPopUp}
        />
      </Popover>
    </>
  );
};

export default MainTopWorkspace;
