import React, { useState, useMemo, useCallback } from "react";
import { InputBase, Paper, List, ListItem } from "@mui/material";
import { InternalSharedButton } from "../Buttons/InternalSharedButton";
import S from "./PersonDropdown.module.css";
import { AvatarNamePlateSmall } from "../AvatarNamePlateSmall";
import { InternalShareButtonOptionValue } from "../Buttons/AllButtonProps";
import { getUserList } from "../../../Utils";

interface PersonOption {
  id: number;
  name: string;
  userName: string;
  imgSrc?: string;
  avatarColor?: string;
  teamName: string;
  department: string;
  _id?: string;
}

interface PersonDropdownProps {
  selectedData: (data: {
    userData: PersonOption;
    action: InternalShareButtonOptionValue;
  }) => void;
  resetSelection: () => void;
  selectedOption: string;
}

const AVATAR_OPTIONS: PersonOption[] = [
  {
    id: 1,
    name: "John Doe",
    userName: "jdoe",
    imgSrc: "https://randomuser.me/api/portraits/men/1.jpg",
    avatarColor: "#FF5733",
    teamName: "Team Alpha",
    department: "Engineering",
  },
  {
    id: 2,
    name: "Jane Smith",
    userName: "jsmith",
    imgSrc: "https://randomuser.me/api/portraits/women/2.jpg",
    avatarColor: "#33FF57",
    teamName: "Team Beta",
    department: "Marketing",
  },
  {
    id: 3,
    name: "Alice Johnson",
    userName: "ajohnson",
    imgSrc: "https://randomuser.me/api/portraits/women/3.jpg",
    avatarColor: "#3357FF",
    teamName: "Team Gamma",
    department: "Design",
  },
  {
    id: 4,
    name: "Bob Brown",
    userName: "bbrown",
    imgSrc: "https://randomuser.me/api/portraits/men/4.jpg",
    avatarColor: "#FF33A1",
    teamName: "Team Delta",
    department: "Sales",
  },
  {
    id: 5,
    name: "Charlie Davis",
    userName: "cdavis",
    imgSrc: "https://randomuser.me/api/portraits/men/5.jpg",
    avatarColor: "#A133FF",
    teamName: "Team Epsilon",
    department: "HR",
  },
  {
    id: 6,
    name: "Eve Green",
    userName: "egreen",
    imgSrc: "https://randomuser.me/api/portraits/women/6.jpg",
    avatarColor: "#FFA133",
    teamName: "Team Zeta",
    department: "Finance",
  },
  {
    id: 7,
    name: "Frank Black",
    userName: "fblack",
    imgSrc: "https://randomuser.me/api/portraits/men/7.jpg",
    avatarColor: "#33FFA1",
    teamName: "Team Eta",
    department: "Support",
  },
  {
    id: 8,
    name: "Grace Miller",
    userName: "gmiller",
    imgSrc: "https://randomuser.me/api/portraits/women/8.jpg",
    avatarColor: "#FF5733",
    teamName: "Team Theta",
    department: "Engineering",
  },
  {
    id: 9,
    name: "Hank Wilson",
    userName: "hwilson",
    imgSrc: "https://randomuser.me/api/portraits/men/9.jpg",
    avatarColor: "#57FF33",
    teamName: "Team Iota",
    department: "Marketing",
  },
  {
    id: 10,
    name: "Ivy Clark",
    userName: "iclark",
    imgSrc: "https://randomuser.me/api/portraits/women/10.jpg",
    avatarColor: "#5733FF",
    teamName: "Team Kappa",
    department: "Design",
  },
];

const PersonDropdown: React.FC<PersonDropdownProps> = ({
  selectedData,
  resetSelection,
  selectedOption,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<PersonOption | null>(null);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  const userList = getUserList();

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
      setDropdownVisible(event.target.value?.length > 0);
      // setButtonDisabled(event.target.value.length === 0);
    },
    []
  );

  const handleItemSelect = useCallback((item: PersonOption) => {
    setSelectedItem(item);
    setInputValue(item.userName);
    setDropdownVisible(true);
    setButtonDisabled(false);
  }, []);

  const filteredItems = useMemo(
    () =>
      userList?.filter((item: any) =>
        item?.email.toLowerCase()?.includes(inputValue?.toLowerCase())
      ),
    [inputValue]
  );

  const handleSelectionChange = useCallback(
    (value: string) => {
      if (selectedItem) {
        selectedData({
          userData: selectedItem,
          action: value as InternalShareButtonOptionValue,
        });
        resetSelection();
        setInputValue(""); // Reset input value
        setSelectedItem(null);
        setButtonDisabled(true);
        setDropdownVisible(false); // Close the dropdown
      }
    },
    [selectedItem, selectedData, resetSelection]
  );

  return (
    <div className={S["person-dropdown__container"]}>
      <div className={S["person-dropdown__input-container"]}>
        <InputBase
          className={S["person-dropdown__input"]}
          sx={{ height: "30px", marginRight: "10px" }}
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => setDropdownVisible(inputValue?.length > 0)}
          placeholder="Select a person"
          aria-haspopup="listbox"
        />
        <InternalSharedButton
          disabled={buttonDisabled}
          selectedOption={selectedOption}
          resetSelection={resetSelection}
          onSelectionChange={handleSelectionChange}
        />
      </div>
      <Paper
        className={S["person-dropdown__dropdown"]}
        sx={{
          display:
            dropdownVisible && filteredItems?.length > 0 ? "block" : "none",
        }}
        role="listbox"
      >
        <List>
          {filteredItems?.map((item: any, index: number) => (
            <ListItem
              key={index}
              className={`${S["person-dropdown__list-item"]} ${
                selectedItem && selectedItem.id === item.id
                  ? S["person-dropdown__list-item--selected"]
                  : ""
              }`}
              onClick={() => handleItemSelect(item)}
              role="option"
              aria-selected={
                selectedItem && selectedItem._id === item._id ? true : false
              }
            >
              <AvatarNamePlateSmall
                size="default"
                userName={item?.firstName + item?.lastName}
                imgSrc={item?.imgSrc}
                avatarColor={item?.avatarColor || "orange"}
                teamName={item?.teamName || "default Team"}
                department={item?.department || "default Department"}
                sx={{ width: "100%" }}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </div>
  );
};

export default PersonDropdown;
