/**
 * This file contains action creators for workspace related actions.
 */

// Importing action creators and types from workspaceSlice

import { createApiAction } from "../constantApi_config";
// import { ApiPayload } from "../constantType";

import {
    /* Create workspace action api */
    createWorkspace__RequestAction,
    createWorkspace__FailureAction,
    createWorkspace__SuccessAction,

    /* Get all workspaces action api */
    getAllWorkspace__RequestAction,
    getAllWorkspace__FailureAction,
    getAllWorkspace__SuccessAction,

    /* Get one workspace action api */
    getOneWorkspace__RequestAction,
    getOneWorkspace__FailureAction,
    getOneWorkspace__SuccessAction,

    /* Update workspace action api */
    updateWorkspace__RequestAction,
    updateWorkspace__FailureAction,
    updateWorkspace__SuccessAction,

    /* Delete workspace action api */
    deleteWorkspace__RequestAction,
    deleteWorkspace__FailureAction,
    deleteWorkspace__SuccessAction,

    /* Add workspace content action api */
    addWorkspaceContent__RequestAction,
    addWorkspaceContent__FailureAction,
    addWorkspaceContent__SuccessAction,

    /* Reorder workspace content action api */
    reOrderWorkspaceContent__RequestAction,
    reOrderWorkspaceContent__FailureAction,
    reOrderWorkspaceContent__SuccessAction,

    /* Update workspace content action api */
    updateWorkspaceContent__RequestAction,
    updateWorkspaceContent__FailureAction,
    updateWorkspaceContent__SuccessAction,

    /* Delete workspace content action api */
    deleteWorkspaceContent__RequestAction,
    deleteWorkspaceContent__FailureAction,
    deleteWorkspaceContent__SuccessAction,

    /* Add workspace member action api */
    addWorkspaceMember__RequestAction,
    addWorkspaceMember__FailureAction,
    addWorkspaceMember__SuccessAction,

    /* Get all workspace members action api */
    getAllWorkspaceMember__RequestAction,
    getAllWorkspaceMember__FailureAction,
    getAllWorkspaceMember__SuccessAction,

    /* Update workspace member action api */
    updateWorkspaceMember__RequestAction,
    updateWorkspaceMember__FailureAction,
    updateWorkspaceMember__SuccessAction,

    /* Delete workspace member action api */
    deleteWorkspaceMember__RequestAction,
    deleteWorkspaceMember__FailureAction,
    deleteWorkspaceMember__SuccessAction,

    /* Add workspace custom field action api */
    addWorkspaceCustomField__RequestAction,
    addWorkspaceCustomField__FailureAction,
    addWorkspaceCustomField__SuccessAction,

    /* Update workspace custom field action api */
    updateWorkspaceCustomField__RequestAction,
    updateWorkspaceCustomField__FailureAction,
    updateWorkspaceCustomField__SuccessAction,

    /* Delete workspace custom field action api */
    deleteWorkspaceCustomField__RequestAction,
    deleteWorkspaceCustomField__FailureAction,
    deleteWorkspaceCustomField__SuccessAction,

    /* Add workspace tag action api */
    addWorkspaceTag__RequestAction,
    addWorkspaceTag__FailureAction,
    addWorkspaceTag__SuccessAction,

    /* Update workspace tag action api */
    updateWorkspaceTag__RequestAction,
    updateWorkspaceTag__FailureAction,
    updateWorkspaceTag__SuccessAction,

    /* Delete workspace tag action api */
    deleteWorkspaceTag__RequestAction,
    deleteWorkspaceTag__FailureAction,
    deleteWorkspaceTag__SuccessAction,

    /* Add workspace template action api */
    addWorkspaceTemplate__RequestAction,
    addWorkspaceTemplate__FailureAction,
    addWorkspaceTemplate__SuccessAction,

    /* Apply workspace template action api */
    applyWorkspaceTemplate__RequestAction,
    applyWorkspaceTemplate__FailureAction,
    applyWorkspaceTemplate__SuccessAction,

    /* Add workspace integration action api */
    addWorkspaceIntegration__RequestAction,
    addWorkspaceIntegration__FailureAction,
    addWorkspaceIntegration__SuccessAction,

    /* Update workspace integration action api */
    updateWorkspaceIntegration__RequestAction,
    updateWorkspaceIntegration__FailureAction,
    updateWorkspaceIntegration__SuccessAction,

    /* Delete workspace integration action api */
    deleteWorkspaceIntegration__RequestAction,
    deleteWorkspaceIntegration__FailureAction,
    deleteWorkspaceIntegration__SuccessAction,

    /* Add workspace activity action api */
    addWorkspaceActivity__RequestAction,
    addWorkspaceActivity__FailureAction,
    addWorkspaceActivity__SuccessAction,

    /* Get all workspace activities action api */
    getAllWorkspaceActivity__RequestAction,
    getAllWorkspaceActivity__FailureAction,
    getAllWorkspaceActivity__SuccessAction,

    /* Update workspace column action api */
    updateWorkspaceColumn__RequestAction,
    updateWorkspaceColumn__FailureAction,
    updateWorkspaceColumn__SuccessAction,

    /* Add workspace connection action api */
    addWorkspaceConnection__RequestAction,
    addWorkspaceConnection__FailureAction,
    addWorkspaceConnection__SuccessAction,

    /* Add workspace reminder action api */
    addWorkspaceReminder__RequestAction,
    addWorkspaceReminder__FailureAction,
    addWorkspaceReminder__SuccessAction,

    /* Add workspace video report action api */
    addWorkspaceVideoReport__RequestAction,
    addWorkspaceVideoReport__FailureAction,
    addWorkspaceVideoReport__SuccessAction,

    /* Add workspace file action api */
    addWorkspaceFile__RequestAction,
    addWorkspaceFile__FailureAction,
    addWorkspaceFile__SuccessAction,

    /* Add workspace media action api */
    addWorkspaceMedia__RequestAction,
    addWorkspaceMedia__FailureAction,
    addWorkspaceMedia__SuccessAction,

    /* Add workspace bookmark action api */
    addWorkspaceBookMark__RequestAction,
    addWorkspaceBookMark__FailureAction,
    addWorkspaceBookMark__SuccessAction,

    /* Add workspace table of content action api */
    addWorkspaceTableOfContent__RequestAction,
    addWorkspaceTableOfContent__FailureAction,
    addWorkspaceTableOfContent__SuccessAction,

    /* Add workspace equation action api */
    addWorkspaceEquation__RequestAction,
    addWorkspaceEquation__FailureAction,
    addWorkspaceEquation__SuccessAction,

    /* Add workspace button action api */
    addWorkspaceButton__RequestAction,
    addWorkspaceButton__FailureAction,
    addWorkspaceButton__SuccessAction,

    /* Add workspace sync element action api */
    addWorkspaceSyncElement__RequestAction,
    addWorkspaceSyncElement__FailureAction,
    addWorkspaceSyncElement__SuccessAction,

    /* Add workspace heading toggle action api */
    addWorkspaceHeadingToggle__RequestAction,
    addWorkspaceHeadingToggle__FailureAction,
    addWorkspaceHeadingToggle__SuccessAction,

    /* Add workspace code block action api */
    addWorkspaceCodeBlock__RequestAction,
    addWorkspaceCodeBlock__FailureAction,
    addWorkspaceCodeBlock__SuccessAction,

    /* Delete workspace in bulk action api */
    deleteWorkspaceInBulk__RequestAction,
    deleteWorkspaceInBulk__FailureAction,
    deleteWorkspaceInBulk__SuccessAction,

    /* Add Grid workspace actions */
    addGrid__RequestAction,
    addGrid__FailureAction,
    addGrid__SuccessAction,

    /* Get All Grids actions */
    getAllGrids__RequestAction,
    getAllGrids__FailureAction,
    getAllGrids__SuccessAction,

    /* Get One Grid actions */
    getOneGrid__RequestAction,
    getOneGrid__FailureAction,
    getOneGrid__SuccessAction,

    /* Update One Grid actions */
    updateOneGrid__RequestAction,
    updateOneGrid__FailureAction,
    updateOneGrid__SuccessAction,

    /* Delete Grid actions */
    deleteGrid__RequestAction,
    deleteGrid__FailureAction,
    deleteGrid__SuccessAction,

    /* Add Sheet actions */
    addSheet__RequestAction,
    addSheet__FailureAction,
    addSheet__SuccessAction,

    /* Update Sheet actions */
    updateSheet__RequestAction,
    updateSheet__FailureAction,
    updateSheet__SuccessAction,

    /* Delete Sheet actions */
    deleteSheet__RequestAction,
    deleteSheet__FailureAction,
    deleteSheet__SuccessAction,

    /* Reorder Sheets actions */
    reorderSheets__RequestAction,
    reorderSheets__FailureAction,
    reorderSheets__SuccessAction,

    /* Update Cell actions */
    updateCell__RequestAction,
    updateCell__FailureAction,
    updateCell__SuccessAction,

    /* Add Component actions */
    addComponent__RequestAction,
    addComponent__FailureAction,
    addComponent__SuccessAction,

    /* Get All Components actions */
    getAllComponents__RequestAction,
    getAllComponents__FailureAction,
    getAllComponents__SuccessAction,

    /* Get One Component actions */
    getOneComponent__RequestAction,
    getOneComponent__FailureAction,
    getOneComponent__SuccessAction,

    /* Update One Component actions */
    updateOneComponent__RequestAction,
    updateOneComponent__FailureAction,
    updateOneComponent__SuccessAction,

    /* Delete One Component actions */
    deleteOneComponent__RequestAction,
    deleteOneComponent__FailureAction,
    deleteOneComponent__SuccessAction,

    /* Delete Bulk Components actions */
    deleteBulkComponents__RequestAction,
    deleteBulkComponents__FailureAction,
    deleteBulkComponents__SuccessAction,

    /* Create Task */
    createTask__RequestAction,
    createTask__FailureAction,
    createTask__SuccessAction,

    /* Get One Task */
    getOneTask__RequestAction,
    getOneTask__FailureAction,
    getOneTask__SuccessAction,

    /* Update One Task */
    updateOneTask__RequestAction,
    updateOneTask__FailureAction,
    updateOneTask__SuccessAction,

    /* Delete One Task */
    deleteOneTask__RequestAction,
    deleteOneTask__FailureAction,
    deleteOneTask__SuccessAction,

    /* Get All Tasks */
    getAllTasks__RequestAction,
    getAllTasks__FailureAction,
    getAllTasks__SuccessAction,

    /* Create Sub Task */
    createSubTask__RequestAction,
    createSubTask__FailureAction,
    createSubTask__SuccessAction,

    /* Get All Sub Tasks */
    getAllSubTasks__RequestAction,
    getAllSubTasks__FailureAction,
    getAllSubTasks__SuccessAction,

    /* Delete Bulk Tasks */
    deleteBulkTasks__RequestAction,
    deleteBulkTasks__FailureAction,
    deleteBulkTasks__SuccessAction,

    /* Shared Workspace */
    getALllSharedWorkspace__RequestAction,
    getAllSharedWorkspace__SuccessAction,
    getALllSharedWorkspace__FailureAction,

    /* Add Whiteboard actions */
    AddWhiteboard__RequestAction,
    AddWhiteboard__FailureAction,
    AddWhiteboard__SuccessAction,

    /* Get All Whiteboards actions */
    GetAllWhiteboards__RequestAction,
    GetAllWhiteboards__FailureAction,
    GetAllWhiteboards__SuccessAction,

    /* Get One Whiteboard actions */
    GetOneWhiteboard__RequestAction,
    GetOneWhiteboard__FailureAction,
    GetOneWhiteboard__SuccessAction,

    /* Update Whiteboard actions */
    UpdateWhiteboard__RequestAction,
    UpdateWhiteboard__FailureAction,
    UpdateWhiteboard__SuccessAction,

    /* Delete Whiteboard actions */
    DeleteWhiteboard__RequestAction,
    DeleteWhiteboard__FailureAction,
    DeleteWhiteboard__SuccessAction,

    /* Add Object actions */
    AddObject__RequestAction,
    AddObject__FailureAction,
    AddObject__SuccessAction,

    /* Update Object actions */
    UpdateObject__RequestAction,
    UpdateObject__FailureAction,
    UpdateObject__SuccessAction,

    /* Delete Object actions */
    DeleteObject__RequestAction,
    DeleteObject__FailureAction,
    DeleteObject__SuccessAction,

    /* Add Collaborator actions */
    AddCollaborator__RequestAction,
    AddCollaborator__FailureAction,
    AddCollaborator__SuccessAction,

    /* Get All Collaborators actions */
    GetAllCollaborators__RequestAction,
    GetAllCollaborators__FailureAction,
    GetAllCollaborators__SuccessAction,

    /* Update Collaborator actions */
    UpdateCollaborator__RequestAction,
    UpdateCollaborator__FailureAction,
    UpdateCollaborator__SuccessAction,

    /* Delete Collaborator actions */
    DeleteCollaborator__RequestAction,
    DeleteCollaborator__FailureAction,
    DeleteCollaborator__SuccessAction,

    /* Create Version actions */
    CreateVersion__RequestAction,
    CreateVersion__FailureAction,
    CreateVersion__SuccessAction,

    /* Get All Versions actions */
    GetAllVersions__RequestAction,
    GetAllVersions__FailureAction,
    GetAllVersions__SuccessAction,

    /* Restore Version actions */
    RestoreVersion__RequestAction,
    RestoreVersion__FailureAction,
    RestoreVersion__SuccessAction,

    /* Add Docs actions */
    addDocs__RequestAction,
    addDocs__FailureAction,
    addDocs__SuccessAction,

    /* Get All Docs actions */
    getAllDocs__RequestAction,
    getAllDocs__FailureAction,
    getAllDocs__SuccessAction,

    /* Get One Doc actions */
    getOneDoc__RequestAction,
    getOneDoc__FailureAction,
    getOneDoc__SuccessAction,

    /* Update One Doc */
    updateOneDoc__RequestAction,
    updateOneDoc__FailureAction,
    updateOneDoc__SuccessAction,

    /* Add Wiki */
    AddWiki__RequestAction,
    AddWiki__FailureAction,
    AddWiki__SuccessAction,

    /* Get All Wikis */
    GetAllWikis__RequestAction,
    GetAllWikis__FailureAction,
    GetAllWikis__SuccessAction,

    /* Get One Wiki */
    GetOneWiki__RequestAction,
    GetOneWiki__FailureAction,
    GetOneWiki__SuccessAction,

    /* Add Wiki Page */
    AddWikiPage__RequestAction,
    AddWikiPage__FailureAction,
    AddWikiPage__SuccessAction,

    /* Get Wiki Page */
    GetWikiPage__RequestAction,
    GetWikiPage__FailureAction,
    GetWikiPage__SuccessAction,

    /* Update Wiki Page */
    UpdateWikiPage__RequestAction,
    UpdateWikiPage__FailureAction,
    UpdateWikiPage__SuccessAction,
    AddComponentView__RequestAction,
    AddComponentView__SuccessAction,
    AddComponentView__FailureAction,

    /* and so on... */
} from "./workspaceSlice";
import {
    Add_or_update__WorkspaceCustomField,
    AddCollaboratorRequest,
    AddComponentPayload,
    AddDocPayload,
    AddGridPayload,
    AddObjectRequest,
    AddSheetPayload,
    AddWhiteboardRequest,
    AddWiki,
    AddWikiPage,
    AddWorkspaceActivity,
    AddWorkspaceBookmark,
    AddWorkspaceButton,
    AddWorkspaceCodeBlock,
    AddWorkspaceConnection,
    AddWorkspaceContent,
    AddWorkspaceEquation,
    AddWorkspaceFile,
    AddWorkspaceHeadingToggle,
    AddWorkspaceIntegration,
    AddWorkspaceMedia,
    AddWorkspaceMember,
    AddWorkspaceReminder,
    AddWorkspaceSyncElement,
    AddWorkspaceTableOfContents,
    AddWorkspaceTag,
    AddWorkspaceTemplate,
    AddWorkspaceVideoReport,
    CreateSubTaskPayload,
    CreateTaskPayload,
    CreateVersionRequest,
    CreateWorkspace,
    DeleteBulkComponentsPayload,
    DeleteBulkTasksPayload,
    DeleteWorkspaceInBulk,
    DeleteWorkspaceTag,
    Get_delete_By____Id,
    GetAllComponentsParams,
    GetWorkspaceAllActivityParams,
    ReorderSheetsPayload,
    Update__Delete__WorkspaceIntegration,
    UpdateCellPayload,
    UpdateCollaboratorRequest,
    UpdateComponentPayload,
    UpdateGridPayload,
    UpdateObjectRequest,
    UpdateSheetPayload,
    UpdateTaskPayload,
    UpdateWhiteboardRequest,
    UpdateWorkspace,
    UpdateWorkspaceColumn,
    UpdateWorkSpaceContent,
    UpdateWorkspaceMember,
} from "./workspaceTypes";

/* createWorkspace__api */
/* Done */
export const createWorkspace__api = (payload: CreateWorkspace | FormData) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        headers: {
            "Content-Type": "multipart/form-data",
        },
        url: "/workspace",
        data: payload,
        onStart: createWorkspace__RequestAction.type,
        onSuccess: createWorkspace__SuccessAction.type,
        onError: createWorkspace__FailureAction.type,
    });

/* getAllWorkspace__api */
/* Done */
export const getAllWorkspace__api = () =>
    createApiAction({
        axiosRequired: true, // Explicitly setting axiosRequired
        method: "GET",
        url: "/workspace",
        onStart: getAllWorkspace__RequestAction.type,
        onSuccess: getAllWorkspace__SuccessAction.type,
        onError: getAllWorkspace__FailureAction.type,
    });

export const getAllSharedWorkspace__api = () =>
    createApiAction({
        axiosRequired: true, // Explicitly setting axiosRequired
        method: "GET",
        url: "/workspace/shared",
        onStart: getALllSharedWorkspace__RequestAction.type,
        onSuccess: getAllSharedWorkspace__SuccessAction.type,
        onError: getALllSharedWorkspace__FailureAction.type,
    });

/* getOneWorkspace__api */
/* Done */
export const getOneWorkspace__api = (payload: Get_delete_By____Id) =>
    createApiAction({
        axiosRequired: true, // Explicitly setting axiosRequired
        method: "GET",
        url: `/workspace/${payload._id}`,
        onStart: getOneWorkspace__RequestAction.type,
        onSuccess: getOneWorkspace__SuccessAction.type,
        onError: getOneWorkspace__FailureAction.type,
    });

/* updateWorkspace__api */
/* Done */
export const updateWorkspace__api = (
    payload: UpdateWorkspace | FormData,
    workspaceId: string
) =>
    createApiAction({
        axiosRequired: true, // Explicitly setting axiosRequired
        method: "PUT",
        url: `/workspace/${workspaceId}`,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        data: payload,
        onStart: updateWorkspace__RequestAction.type,
        onSuccess: updateWorkspace__SuccessAction.type,
        onError: updateWorkspace__FailureAction.type,
    });
/* deleteWorkspace__api */
/* Done */
export const deleteWorkspace__api = (payload: Get_delete_By____Id) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/workspace/${payload._id}`,
        onStart: deleteWorkspace__RequestAction.type,
        onSuccess: deleteWorkspace__SuccessAction.type,
        onError: deleteWorkspace__FailureAction.type,
    });

/* addWorkspaceContent__api */
/* Done */
export const addWorkspaceContent__api = (
    payload: AddWorkspaceContent,
    workspaceId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${workspaceId}/content`,
        data: payload,
        onStart: addWorkspaceContent__RequestAction.type,
        onSuccess: addWorkspaceContent__SuccessAction.type,
        onError: addWorkspaceContent__FailureAction.type,
    });

/* reOrderWorkspaceContent__api */
/* Done --- some funtionality pending */
export const reOrderWorkspaceContent__api = (payload: {
    workspaceId: string;
}) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/workspace/${payload.workspaceId}/content/reorder`,
        data: payload,
        onStart: reOrderWorkspaceContent__RequestAction.type,
        onSuccess: reOrderWorkspaceContent__SuccessAction.type,
        onError: reOrderWorkspaceContent__FailureAction.type,
    });

/* updateWorkspaceContent__api */
/* Done */
export const updateWorkspaceContent__api = (
    payload: UpdateWorkSpaceContent,
    workspaceId: string,
    contentId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/workspace/${workspaceId}/content/${contentId}`,
        data: payload,
        onStart: updateWorkspaceContent__RequestAction.type,
        onSuccess: updateWorkspaceContent__SuccessAction.type,
        onError: updateWorkspaceContent__FailureAction.type,
    });

/* deleteWorkspaceContent__api */
/* Done -- need delete button in frontend to implement */
export const deleteWorkspaceContent__api = (
    componentId: string,
    workspaceId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/workspace/${workspaceId}/content/${componentId}`, // Matches your router setup
        onStart: deleteWorkspaceContent__RequestAction.type,
        onSuccess: deleteWorkspaceContent__SuccessAction.type,
        onError: deleteWorkspaceContent__FailureAction.type,
    });

/* addWorkspaceMember__api */
/* Can't be setup untill we have team setup in settings */
export const addWorkspaceMember__api = (
    payload: AddWorkspaceMember,
    workspaceId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${workspaceId}/members`,
        data: payload,
        onStart: addWorkspaceMember__RequestAction.type,
        onSuccess: addWorkspaceMember__SuccessAction.type,
        onError: addWorkspaceMember__FailureAction.type,
    });

/* getAllWorkspaceMember__api */
/* Done */
export const getAllWorkspaceMember__api = (payload: { workspaceId: string }) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/workspace/${payload.workspaceId}/members`,
        onStart: getAllWorkspaceMember__RequestAction.type,
        onSuccess: getAllWorkspaceMember__SuccessAction.type,
        onError: getAllWorkspaceMember__FailureAction.type,
    });

/* updateWorkspaceMember__api */
/* Can't be setup untill we have team setup in settings */
export const updateWorkspaceMember__api = (payload: UpdateWorkspaceMember) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/workspace/${payload.workspaceId}/member/${payload.memberId}`,
        data: payload,
        onStart: updateWorkspaceMember__RequestAction.type,
        onSuccess: updateWorkspaceMember__SuccessAction.type,
        onError: updateWorkspaceMember__FailureAction.type,
    });

/* deleteWorkspaceMember__api */
export const deleteWorkspaceMember__api = (
    payload: Get_delete_By____Id,
    memberId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/workspace/${payload._id}/members/${memberId}`,
        onStart: deleteWorkspaceMember__RequestAction.type,
        onSuccess: deleteWorkspaceMember__SuccessAction.type,
        onError: deleteWorkspaceMember__FailureAction.type,
    });

/* addWorkspaceCustomField__api */
/* No UI yet */
export const addWorkspaceCustomField__api = (
    payload: Add_or_update__WorkspaceCustomField
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/custom-fields`,
        data: payload,
        onStart: addWorkspaceCustomField__RequestAction.type,
        onSuccess: addWorkspaceCustomField__SuccessAction.type,
        onError: addWorkspaceCustomField__FailureAction.type,
    });

/* updateWorkspaceCustomField__api */
/* No UI yet */
export const updateWorkspaceCustomField__api = (
    payload: Add_or_update__WorkspaceCustomField
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/workspace/${payload.workspaceId}/custom-fields/${payload.fieldId}`,
        data: payload,
        onStart: updateWorkspaceCustomField__RequestAction.type,
        onSuccess: updateWorkspaceCustomField__SuccessAction.type,
        onError: updateWorkspaceCustomField__FailureAction.type,
    });

/* deleteWorkspaceCustomField__api */
/* No UI yet */
export const deleteWorkspaceCustomField__api = (payload: {
    workspaceId: string;
    fieldId: string;
}) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/workspace/${payload.workspaceId}/custom-fields/${payload.fieldId}`,
        onStart: deleteWorkspaceCustomField__RequestAction.type,
        onSuccess: deleteWorkspaceCustomField__SuccessAction.type,
        onError: deleteWorkspaceCustomField__FailureAction.type,
    });

/* addWorkspaceTag__api */
/* No UI yet */
export const addWorkspaceTag__api = (payload: AddWorkspaceTag) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/tags`,
        data: payload,
        onStart: addWorkspaceTag__RequestAction.type,
        onSuccess: addWorkspaceTag__SuccessAction.type,
        onError: addWorkspaceTag__FailureAction.type,
    });

/* updateWorkspaceTag__api */
/* No UI yet */
export const updateWorkspaceTag__api = (payload: AddWorkspaceTag) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/workspace/${payload.workspaceId}/tags/${payload.tagId}`,
        data: payload,
        onStart: updateWorkspaceTag__RequestAction.type,
        onSuccess: updateWorkspaceTag__SuccessAction.type,
        onError: updateWorkspaceTag__FailureAction.type,
    });

/* deleteWorkspaceTag__api */
/* No UI yet */
export const deleteWorkspaceTag__api = (payload: DeleteWorkspaceTag) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/workspace/${payload.workspaceId}/tags`,
        data: payload,
        onStart: deleteWorkspaceTag__RequestAction.type,
        onSuccess: deleteWorkspaceTag__SuccessAction.type,
        onError: deleteWorkspaceTag__FailureAction.type,
    });

/* addWorkspaceTemplate__api */
/* Done */
export const addWorkspaceTemplate__api = (
    payload: AddWorkspaceTemplate,
    workspaceId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${workspaceId}/templates`,
        data: payload,
        onStart: addWorkspaceTemplate__RequestAction.type,
        onSuccess: addWorkspaceTemplate__SuccessAction.type,
        onError: addWorkspaceTemplate__FailureAction.type,
    });

/* applyWorkspaceTemplate__api */
/* Done -- Remain need to render templates */
export const applyWorkspaceTemplate__api = (payload: {
    workspaceId: string;
    templateId: string;
}) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/apply-template/${payload.templateId}`,
        onStart: applyWorkspaceTemplate__RequestAction.type,
        onSuccess: applyWorkspaceTemplate__SuccessAction.type,
        onError: applyWorkspaceTemplate__FailureAction.type,
    });

/* addWorkspaceIntegration__api */
/* Not Mention in doc */
export const addWorkspaceIntegration__api = (
    payload: AddWorkspaceIntegration
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/integrations`,
        data: payload,
        onStart: addWorkspaceIntegration__RequestAction.type,
        onSuccess: addWorkspaceIntegration__SuccessAction.type,
        onError: addWorkspaceIntegration__FailureAction.type,
    });

/* updateWorkspaceIntegration__api */
/* Not Mention in doc */
export const updateWorkspaceIntegration__api = (
    payload: Update__Delete__WorkspaceIntegration
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/workspace/${payload.workspaceId}/integrations/${payload.integrationId}`,
        data: payload,
        onStart: updateWorkspaceIntegration__RequestAction.type,
        onSuccess: updateWorkspaceIntegration__SuccessAction.type,
        onError: updateWorkspaceIntegration__FailureAction.type,
    });

/* deleteWorkspaceIntegration__api */
/* Not Mention in doc */
export const deleteWorkspaceIntegration__api = (
    payload: Update__Delete__WorkspaceIntegration
) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/workspace/${payload.workspaceId}/integrations/${payload.integrationId}`,
        onStart: deleteWorkspaceIntegration__RequestAction.type,
        onSuccess: deleteWorkspaceIntegration__SuccessAction.type,
        onError: deleteWorkspaceIntegration__FailureAction.type,
    });

/* addWorkspaceActivity__api */
/* Not Mention in doc */
export const addWorkspaceActivity__api = (payload: AddWorkspaceActivity) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/activities`,
        data: payload,
        onStart: addWorkspaceActivity__RequestAction.type,
        onSuccess: addWorkspaceActivity__SuccessAction.type,
        onError: addWorkspaceActivity__FailureAction.type,
    });

/* getAllWorkspaceActivity__api */
/* Not Mention in doc */
export const getAllWorkspaceActivity__api = (
    payload: GetWorkspaceAllActivityParams
) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/workspace/${payload.workspaceId}/activity?page=${payload.page}&limit==${payload.limit}&startDate==${payload.startDate}&endDate==${payload.endDate}&type==${payload.type}&userId==${payload.userId}`,
        onStart: getAllWorkspaceActivity__RequestAction.type,
        onSuccess: getAllWorkspaceActivity__SuccessAction.type,
        onError: getAllWorkspaceActivity__FailureAction.type,
    });

/* updateWorkspaceColumn__api */
/* Not Mention in doc */
export const updateWorkspaceColumn__api = (payload: UpdateWorkspaceColumn) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/workspace/${payload.workspaceId}/columns`,
        data: payload,
        onStart: updateWorkspaceColumn__RequestAction.type,
        onSuccess: updateWorkspaceColumn__SuccessAction.type,
        onError: updateWorkspaceColumn__FailureAction.type,
    });

/* addWorkspaceConnection__api */
/* Not set up yet */
export const addWorkspaceConnection__api = (payload: AddWorkspaceConnection) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/connect`,
        data: payload,
        onStart: addWorkspaceConnection__RequestAction.type,
        onSuccess: addWorkspaceConnection__SuccessAction.type,
        onError: addWorkspaceConnection__FailureAction.type,
    });

/* addWorkspaceReminder__api */
/* Not set up yet */
export const addWorkspaceReminder__api = (payload: AddWorkspaceReminder) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/reminders`,
        data: payload,
        onStart: addWorkspaceReminder__RequestAction.type,
        onSuccess: addWorkspaceReminder__SuccessAction.type,
        onError: addWorkspaceReminder__FailureAction.type,
    });

/* addWorkspaceVideoReport__api */
/* Not set up yet */
export const addWorkspaceVideoReport__api = (
    payload: AddWorkspaceVideoReport
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/video-reports`,
        data: payload,
        onStart: addWorkspaceVideoReport__RequestAction.type,
        onSuccess: addWorkspaceVideoReport__SuccessAction.type,
        onError: addWorkspaceVideoReport__FailureAction.type,
    });

/* addWorkspaceFile__api */
/* Not set up yet */
export const addWorkspaceFile__api = (payload: AddWorkspaceFile) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/files`,
        data: payload,
        onStart: addWorkspaceFile__RequestAction.type,
        onSuccess: addWorkspaceFile__SuccessAction.type,
        onError: addWorkspaceFile__FailureAction.type,
    });

/* addWorkspaceMedia__api */
/* Not set up yet */
export const addWorkspaceMedia__api = (payload: AddWorkspaceMedia) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/media`,
        data: payload,
        onStart: addWorkspaceMedia__RequestAction.type,
        onSuccess: addWorkspaceMedia__SuccessAction.type,
        onError: addWorkspaceMedia__FailureAction.type,
    });

/* addWorkspaceBookMark__api */
/* Not set up yet */
export const addWorkspaceBookMark__api = (payload: AddWorkspaceBookmark) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/bookmarks`,
        data: payload,
        onStart: addWorkspaceBookMark__RequestAction.type,
        onSuccess: addWorkspaceBookMark__SuccessAction.type,
        onError: addWorkspaceBookMark__FailureAction.type,
    });

/* addWorkspaceTableOfContent__api */
/* Not set up yet, nor UI */
export const addWorkspaceTableOfContent__api = (
    payload: AddWorkspaceTableOfContents
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/workspace/${payload.workspaceId}/table-of-contents`,
        data: payload,
        onStart: addWorkspaceTableOfContent__RequestAction.type,
        onSuccess: addWorkspaceTableOfContent__SuccessAction.type,
        onError: addWorkspaceTableOfContent__FailureAction.type,
    });

/* addWorkspaceEquation__api */
/* Not set up yet */
export const addWorkspaceEquation__api = (payload: AddWorkspaceEquation) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/equations`,
        data: payload,
        onStart: addWorkspaceEquation__RequestAction.type,
        onSuccess: addWorkspaceEquation__SuccessAction.type,
        onError: addWorkspaceEquation__FailureAction.type,
    });

/* addWorkspaceButton__api */
/* Not set up yet */
export const addWorkspaceButton__api = (payload: AddWorkspaceButton) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/buttons`,
        data: payload,
        onStart: addWorkspaceButton__RequestAction.type,
        onSuccess: addWorkspaceButton__SuccessAction.type,
        onError: addWorkspaceButton__FailureAction.type,
    });

/* addWorkspaceSyncElement__api */
/* Not set up yet */
export const addWorkspaceSyncElement__api = (
    payload: AddWorkspaceSyncElement
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/sync-elements`,
        data: payload,
        onStart: addWorkspaceSyncElement__RequestAction.type,
        onSuccess: addWorkspaceSyncElement__SuccessAction.type,
        onError: addWorkspaceSyncElement__FailureAction.type,
    });

/* addWorkspaceHeadingToggle__api */
/* Not set up yet */
export const addWorkspaceHeadingToggle__api = (
    payload: AddWorkspaceHeadingToggle
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/heading-toggles`,
        data: payload,
        onStart: addWorkspaceHeadingToggle__RequestAction.type,
        onSuccess: addWorkspaceHeadingToggle__SuccessAction.type,
        onError: addWorkspaceHeadingToggle__FailureAction.type,
    });

/* addWorkspaceCodeBlock__api */
/* Not set up yet */
export const addWorkspaceCodeBlock__api = (payload: AddWorkspaceCodeBlock) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/code-blocks`,
        data: payload,
        onStart: addWorkspaceCodeBlock__RequestAction.type,
        onSuccess: addWorkspaceCodeBlock__SuccessAction.type,
        onError: addWorkspaceCodeBlock__FailureAction.type,
    });

/* deleteWorkspaceInBulk__api */
/* Not set up yet */
export const deleteWorkspaceInBulk__api = (
    workspaceIds: DeleteWorkspaceInBulk
) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: "/workspace/bulk",
        data: { workspaceIds },
        onStart: deleteWorkspaceInBulk__RequestAction.type,
        onSuccess: deleteWorkspaceInBulk__SuccessAction.type,
        onError: deleteWorkspaceInBulk__FailureAction.type,
    });

/* Add Grid workspace API */
export const addGridWorkspace__api = (payload: AddGridPayload) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: "/grid",
        data: payload,
        onStart: addGrid__RequestAction.type,
        onSuccess: addGrid__SuccessAction.type,
        onError: addGrid__FailureAction.type,
    });

/* Get All Grids API */
export const getAllGrids__api = (limit: number, page: number, sort: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/grids?limit=${limit}&page=${page}&sort=${sort}`,
        onStart: getAllGrids__RequestAction.type,
        onSuccess: getAllGrids__SuccessAction.type,
        onError: getAllGrids__FailureAction.type,
    });

/* Get One Grid API */
export const getOneGrid__api = (gridId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/grid/${gridId}`,
        onStart: getOneGrid__RequestAction.type,
        onSuccess: getOneGrid__SuccessAction.type,
        onError: getOneGrid__FailureAction.type,
    });

/* Update One Grid API */
export const updateOneGrid__api = (
    gridId: string,
    payload: UpdateGridPayload
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/grid/${gridId}`,
        data: payload,
        onStart: updateOneGrid__RequestAction.type,
        onSuccess: updateOneGrid__SuccessAction.type,
        onError: updateOneGrid__FailureAction.type,
    });

/* Delete Grid API */
export const deleteGrid__api = (gridId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/grid/${gridId}`,
        onStart: deleteGrid__RequestAction.type,
        onSuccess: deleteGrid__SuccessAction.type,
        onError: deleteGrid__FailureAction.type,
    });

/* Add Sheet API */
export const addSheet__api = (payload: AddSheetPayload) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: "/sheet",
        data: payload,
        onStart: addSheet__RequestAction.type,
        onSuccess: addSheet__SuccessAction.type,
        onError: addSheet__FailureAction.type,
    });

/* Update Sheet API */
export const updateSheet__api = (
    sheetId: string,
    payload: UpdateSheetPayload
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/sheet/${sheetId}`,
        data: payload,
        onStart: updateSheet__RequestAction.type,
        onSuccess: updateSheet__SuccessAction.type,
        onError: updateSheet__FailureAction.type,
    });

/* Delete Sheet API */
export const deleteSheet__api = (sheetId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/sheet/${sheetId}`,
        onStart: deleteSheet__RequestAction.type,
        onSuccess: deleteSheet__SuccessAction.type,
        onError: deleteSheet__FailureAction.type,
    });

/* Reorder Sheets API */
export const reorderSheets__api = (payload: ReorderSheetsPayload) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: "/sheets/reorder",
        data: payload,
        onStart: reorderSheets__RequestAction.type,
        onSuccess: reorderSheets__SuccessAction.type,
        onError: reorderSheets__FailureAction.type,
    });

/* Update Cell API */
export const updateCell__api = (cellId: string, payload: UpdateCellPayload) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/cell/${cellId}`,
        data: payload,
        onStart: updateCell__RequestAction.type,
        onSuccess: updateCell__SuccessAction.type,
        onError: updateCell__FailureAction.type,
    });

/* Add Component API */
export const addComponent__api = (
    payload: AddComponentPayload,
    workspaceId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/component/${workspaceId}`,
        data: payload,
        onStart: addComponent__RequestAction.type,
        onSuccess: addComponent__SuccessAction.type,
        onError: addComponent__FailureAction.type,
    });

/* Add Component View API */
export const addComponentView__api = (
    payload: { view: string },
    workspaceId: string,
    componentId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/component/${workspaceId}/${componentId}/view`,
        data: payload,
        onStart: AddComponentView__RequestAction.type,
        onSuccess: AddComponentView__SuccessAction.type,
        onError: AddComponentView__FailureAction.type,
    });

export const getAllComponents__api = (payload: GetAllComponentsParams) => {
    return createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/component/${payload.workspaceId}?page=${payload.page}&limit=${payload.limit}&sort=${payload.sort}`,
        onStart: getAllComponents__RequestAction.type,
        onSuccess: getAllComponents__SuccessAction.type,
        onError: getAllComponents__FailureAction.type,
        data: payload.workspaceId,
    });
};

/* Get One Component API */
export const getOneComponent__api = (
    workspaceId: string,
    componentId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/component/${workspaceId}/${componentId}`,
        onStart: getOneComponent__RequestAction.type,
        onSuccess: getOneComponent__SuccessAction.type,
        onError: getOneComponent__FailureAction.type,
    });

/* Update One Component API */
export const updateOneComponent__api = (
    workspaceId: string,
    componentId: string,
    payload: UpdateComponentPayload
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/component/${workspaceId}/${componentId}`,
        data: payload,
        onStart: updateOneComponent__RequestAction.type,
        onSuccess: updateOneComponent__SuccessAction.type,
        onError: updateOneComponent__FailureAction.type,
    });

/* Delete One Component API */
export const deleteOneComponent__api = (
    workspaceId: string,
    componentId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/component/${workspaceId}/${componentId}`,
        onStart: deleteOneComponent__RequestAction.type,
        onSuccess: deleteOneComponent__SuccessAction.type,
        onError: deleteOneComponent__FailureAction.type,
    });

/* Delete Bulk Components API */
export const deleteBulkComponents__api = (
    payload: DeleteBulkComponentsPayload,
    workspaceId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/component/${workspaceId}`,
        data: payload,
        onStart: deleteBulkComponents__RequestAction.type,
        onSuccess: deleteBulkComponents__SuccessAction.type,
        onError: deleteBulkComponents__FailureAction.type,
    });

// Create Task
export const createTask__api = (payload: CreateTaskPayload) =>
    createApiAction({
        onStart: createTask__RequestAction.type,
        onSuccess: createTask__SuccessAction.type,
        onError: createTask__FailureAction.type,
        method: "POST",
        url: `/task`,
        data: payload,
        axiosRequired: true,
    });

// Get One Task
export const getOneTask__api = (workspaceId: string, taskId: string) =>
    createApiAction({
        onStart: getOneTask__RequestAction.type,
        onSuccess: getOneTask__SuccessAction.type,
        onError: getOneTask__FailureAction.type,
        method: "GET",
        url: `/task/${workspaceId}/${taskId}`,
        axiosRequired: true,
    });

// Update One Task
export const updateOneTask__api = (
    workspaceId: string,
    payload: UpdateTaskPayload
) =>
    createApiAction({
        onStart: updateOneTask__RequestAction.type,
        onSuccess: updateOneTask__SuccessAction.type,
        onError: updateOneTask__FailureAction.type,
        method: "PUT",
        url: `/task/${workspaceId}`,
        data: payload,
        axiosRequired: true,
    });

// Delete One Task
export const deleteOneTask__api = (workspaceId: string, taskId: string) =>
    createApiAction({
        onStart: deleteOneTask__RequestAction.type,
        onSuccess: deleteOneTask__SuccessAction.type,
        onError: deleteOneTask__FailureAction.type,
        method: "DELETE",
        url: `/task/${workspaceId}/${taskId}`,
        axiosRequired: true,
    });

// Get All Tasks
export const getAllTasks__api = (workspaceId: string, componentId: string) =>
    createApiAction({
        onStart: getAllTasks__RequestAction.type,
        onSuccess: getAllTasks__SuccessAction.type,
        onError: getAllTasks__FailureAction.type,
        method: "GET",
        url: `/task/get-all-task?workspaceId=${workspaceId}&componentId=${componentId}`,
        axiosRequired: true,
    });

// Create Sub Task
export const createSubTask__api = (
    componentId: string,
    payload: CreateSubTaskPayload
) =>
    createApiAction({
        onStart: createSubTask__RequestAction.type,
        onSuccess: createSubTask__SuccessAction.type,
        onError: createSubTask__FailureAction.type,
        method: "POST",
        url: `/task/${componentId}/subtask`,
        data: payload,
        axiosRequired: true,
    });

// Get All Sub Tasks
export const getAllSubTasks__api = (
    taskID: string,
    page: number,
    limit: number,
    sort: string
) =>
    createApiAction({
        onStart: getAllSubTasks__RequestAction.type,
        onSuccess: getAllSubTasks__SuccessAction.type,
        onError: getAllSubTasks__FailureAction.type,
        method: "GET",
        url: `/task/${taskID}/subtask?page=${page}&limit=${limit}&sort=${sort}`,
        axiosRequired: true,
    });

// Delete Bulk Tasks
export const deleteBulkTasks__api = (payload: DeleteBulkTasksPayload) =>
    createApiAction({
        onStart: deleteBulkTasks__RequestAction.type,
        onSuccess: deleteBulkTasks__SuccessAction.type,
        onError: deleteBulkTasks__FailureAction.type,
        method: "POST",
        url: `/task/delete-bulk`,
        data: payload,
        axiosRequired: true,
    });

/* Add Whiteboard */
export const addWhiteboard__api = (
    workspaceID: string,
    payload: AddWhiteboardRequest
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/whiteboard/${workspaceID}`,
        data: payload,
        onStart: AddWhiteboard__RequestAction.type,
        onSuccess: AddWhiteboard__SuccessAction.type,
        onError: AddWhiteboard__FailureAction.type,
    });

/* Get All Whiteboards */
export const getAllWhiteboards__api = (payload: {
    workspaceId: string;
    page: number;
    limit: number;
    sort: string;
}) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/whiteboard/${payload?.workspaceId}?page=${payload?.page}&limit=${payload?.limit}&sort=${payload?.sort}`,
        onStart: GetAllWhiteboards__RequestAction.type,
        onSuccess: GetAllWhiteboards__SuccessAction.type,
        onError: GetAllWhiteboards__FailureAction.type,
    });

/* Get One Whiteboard */
export const getOneWhiteboard__api = (
    workspaceID: string,
    whiteBoardID: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}`,
        onStart: GetOneWhiteboard__RequestAction.type,
        onSuccess: GetOneWhiteboard__SuccessAction.type,
        onError: GetOneWhiteboard__FailureAction.type,
    });

/* Update Whiteboard */
export const updateWhiteboard__api = (
    workspaceID: string,
    whiteBoardID: string,
    payload: UpdateWhiteboardRequest
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}`,
        data: payload,
        onStart: UpdateWhiteboard__RequestAction.type,
        onSuccess: UpdateWhiteboard__SuccessAction.type,
        onError: UpdateWhiteboard__FailureAction.type,
    });

/* Delete Whiteboard */
export const deleteWhiteboard__api = (
    workspaceID: string,
    whiteBoardID: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}`,
        onStart: DeleteWhiteboard__RequestAction.type,
        onSuccess: DeleteWhiteboard__SuccessAction.type,
        onError: DeleteWhiteboard__FailureAction.type,
    });

/* Add Object */
export const addObject__api = (
    workspaceID: string,
    whiteBoardID: string,
    payload: AddObjectRequest
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}/object`,
        data: payload,
        onStart: AddObject__RequestAction.type,
        onSuccess: AddObject__SuccessAction.type,
        onError: AddObject__FailureAction.type,
    });

/* Update Object */
export const updateObject__api = (
    workspaceID: string,
    whiteBoardID: string,
    payload: UpdateObjectRequest
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}/object`,
        data: payload,
        onStart: UpdateObject__RequestAction.type,
        onSuccess: UpdateObject__SuccessAction.type,
        onError: UpdateObject__FailureAction.type,
    });

/* Delete Object */
export const deleteObject__api = (
    workspaceID: string,
    whiteBoardID: string,
    objectID: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}/object/${objectID}`,
        onStart: DeleteObject__RequestAction.type,
        onSuccess: DeleteObject__SuccessAction.type,
        onError: DeleteObject__FailureAction.type,
    });

/* Add Collaborator */
export const addCollaborator__api = (
    workspaceID: string,
    whiteBoardID: string,
    payload: AddCollaboratorRequest
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}/collaborator`,
        data: payload,
        onStart: AddCollaborator__RequestAction.type,
        onSuccess: AddCollaborator__SuccessAction.type,
        onError: AddCollaborator__FailureAction.type,
    });

/* Get All Collaborators */
export const getAllCollaborators__api = (
    workspaceID: string,
    whiteBoardID: string,
    page: number = 1,
    limit: number = 10,
    sort: string = "asc"
) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}/collaborator?page=${page}&limit=${limit}&sort=${sort}`,
        onStart: GetAllCollaborators__RequestAction.type,
        onSuccess: GetAllCollaborators__SuccessAction.type,
        onError: GetAllCollaborators__FailureAction.type,
    });

/* Update Collaborator */
export const updateCollaborator__api = (
    workspaceID: string,
    whiteBoardID: string,
    payload: UpdateCollaboratorRequest
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}/collaborator`,
        data: payload,
        onStart: UpdateCollaborator__RequestAction.type,
        onSuccess: UpdateCollaborator__SuccessAction.type,
        onError: UpdateCollaborator__FailureAction.type,
    });

/* Delete Collaborator */
export const deleteCollaborator__api = (
    workspaceID: string,
    whiteBoardID: string,
    memberID: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}/collaborator/${memberID}`,
        onStart: DeleteCollaborator__RequestAction.type,
        onSuccess: DeleteCollaborator__SuccessAction.type,
        onError: DeleteCollaborator__FailureAction.type,
    });

/* Create Version */
export const createVersion__api = (
    workspaceID: string,
    whiteBoardID: string,
    payload: CreateVersionRequest
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}/version`,
        data: payload,
        onStart: CreateVersion__RequestAction.type,
        onSuccess: CreateVersion__SuccessAction.type,
        onError: CreateVersion__FailureAction.type,
    });

/* Get All Versions */
export const getAllVersions__api = (
    workspaceID: string,
    whiteBoardID: string,
    page: number = 1,
    limit: number = 10,
    sort: string = "asc"
) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}/version?page=${page}&limit=${limit}&sort=${sort}`,
        onStart: GetAllVersions__RequestAction.type,
        onSuccess: GetAllVersions__SuccessAction.type,
        onError: GetAllVersions__FailureAction.type,
    });

/* Restore Version */
export const restoreVersion__api = (
    workspaceID: string,
    whiteBoardID: string,
    versionID: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}/version/${versionID}/restore`,
        onStart: RestoreVersion__RequestAction.type,
        onSuccess: RestoreVersion__SuccessAction.type,
        onError: RestoreVersion__FailureAction.type,
    });

/* Add Docs */
export const addDocs__api = (workspaceID: string, payload: AddDocPayload) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/document/${workspaceID}`,
        data: payload,
        onStart: addDocs__RequestAction.type,
        onSuccess: addDocs__SuccessAction.type,
        onError: addDocs__FailureAction.type,
    });

/* Get All Docs */
export const getAllDocs__api = (payload: {
    folderId?: string;
    isStarred?: boolean;
    page: number;
    limit: number;
    sort: string;
    filter?: string;
    workspaceId: string;
}) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/document/${payload?.workspaceId}`,
        onStart: getAllDocs__RequestAction.type,
        onSuccess: getAllDocs__SuccessAction.type,
        onError: getAllDocs__FailureAction.type,
    });

/* Get One Doc */
export const getOneDoc__api = (workspaceID: string, docID: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/document/${workspaceID}/${docID}`,
        onStart: getOneDoc__RequestAction.type,
        onSuccess: getOneDoc__SuccessAction.type,
        onError: getOneDoc__FailureAction.type,
    });

/* Update One Doc */
export const updateOneDoc__api = (
    workspaceID: string,
    docID: string,
    payload: any
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        data: payload,
        url: `/document/${workspaceID}/${docID}`,
        onStart: updateOneDoc__RequestAction.type,
        onSuccess: updateOneDoc__SuccessAction.type,
        onError: updateOneDoc__FailureAction.type,
    });

/* Add Wiki */
export const addWiki__api = (payload: AddWiki, workspaceId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/wiki/${workspaceId}`,
        onStart: AddWiki__RequestAction.type,
        onSuccess: AddWiki__SuccessAction.type,
        onError: AddWiki__FailureAction.type,
    });

/* Get All Wikis */
export const getAllWikis__api = (workspaceId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/wiki/${workspaceId}`,
        onStart: GetAllWikis__RequestAction.type,
        onSuccess: GetAllWikis__SuccessAction.type,
        onError: GetAllWikis__FailureAction.type,
    });

/* Get One Wiki */
export const getOneWiki__api = (workspaceId: string, wikiID: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/wiki/${workspaceId}/${wikiID}`,
        onStart: GetOneWiki__RequestAction.type,
        onSuccess: GetOneWiki__SuccessAction.type,
        onError: GetOneWiki__FailureAction.type,
    });

/* Add Wiki Page */
export const addWikiPage__api = (
    wikiID: string,
    payload: AddWikiPage,
    workspaceId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/wiki/${workspaceId}/${wikiID}/page`,
        onStart: AddWikiPage__RequestAction.type,
        onSuccess: AddWikiPage__SuccessAction.type,
        onError: AddWikiPage__FailureAction.type,
    });

/* Get Wiki Page */
export const getWikiPage__api = (workspaceID:string, wikiID: string, pageID: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/wiki/${workspaceID}/${wikiID}/page/${pageID}`,
        onStart: GetWikiPage__RequestAction.type,
        onSuccess: GetWikiPage__SuccessAction.type,
        onError: GetWikiPage__FailureAction.type,
    });

/* Update Wiki Page */
export const updateWikiPage__api = (
    wikiID: string,
    workspaceID: string,
    pageID: string,
    payload: any
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        data: payload,
        url: `/wiki/${workspaceID}/${wikiID}/page/${pageID}`,
        onStart: UpdateWikiPage__RequestAction.type,
        onSuccess: UpdateWikiPage__SuccessAction.type,
        onError: UpdateWikiPage__FailureAction.type,
    });
