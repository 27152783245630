/* ChecklistItemPM.tsx */
import { IconButton, Popover, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  CardIcon,
  ChecklistWithBoxIcon,
  DragAndDropIcon,
  FlagIcon,
  NotepadIcon,
  PlayArrowIcon,
  PlusIcon,
  SelectIcon,
} from "../../../../../images";
import {
  AddElementPopoutOptions,
  CheckboxMedium,
  DUMMY_PRIORITY_OPTIONS,
  DUMMY_TODO_OPTIONS,
  ElementsOptionsList,
  IconDropdownUser,
  OpenDropdownWithColor,
  SingleDatePickerSmall,
} from "../../../../common";
import { getUserList } from "../../../../../Utils";

export interface ChecklistItemPMProps {
  componentType: "main" | "subTask";
  opened: boolean;
  showDetails?: boolean;
  checked?: boolean;
  title?: string;
  onCheckChange?: () => void;
  statusColor?: string;
  priority?: any;
  dueDate?: string;
  updateDroppable?: any;
  item?: any;
}

const ChecklistItemPM: React.FC<ChecklistItemPMProps> = ({
  componentType,
  opened,
  showDetails = true,
  checked = false,
  onCheckChange, // Destructure the prop
  title = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, possimus.",
  statusColor = "#346eeb",
  priority = {
    id: 0,
    value: "Low",
    customColorHex: "#FFA500",
    defaultColorHex: "#FFA500",
  },
  dueDate = "",
  updateDroppable,
  item,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showDrag, setShowDrag] = useState<boolean>(false);
  const [statusAnchorEl, setStatusAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [flagAnchorEl, setFlagAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setStatusAnchorEl(null);
    setFlagAnchorEl(null);
  };

  const handleSelect = (value: string) => {
    console.log("Selected value:", value);
    // handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const statusOpen = Boolean(statusAnchorEl);
  const statusId = statusOpen ? "status-popover" : undefined;

  const flagOpen = Boolean(flagAnchorEl);
  const flagId = flagOpen ? "flag-popover" : undefined;

  const userList = getUserList();

  return (
    <Stack
      onMouseEnter={() => setShowDrag(true)}
      onMouseLeave={() => setShowDrag(false)}
      width={"100%"}
      sx={{
        height: "35px",
        flexDirection: "row",
        gap: "15px",
        alignItems: "center",
        borderBottom: "2px solid #E9EDF2",
      }}
      p={"8px 2px"}
    >
      {/* --------------------  show when hover component here ------------------- */}
      <Stack sx={{ width: "40px", flexShrink: 0 }}>
        <Stack
          sx={{
            flexDirection: "row",
            gap: "10px",
            display: showDrag ? "flex" : "none",
          }}
        >
          <IconButton sx={{ p: 0, borderRadius: 0 }} disableRipple>
            <DragAndDropIcon w={15} />
          </IconButton>

          <CheckboxMedium
            onChange={onCheckChange}
            checked={checked}
            boxSize={15}
          />
        </Stack>
      </Stack>
      {/* --------------------  show when hover component end --------------------- */}

      {/* arrow button for expand and see sub-task */}
      <IconButton sx={{ p: 0, borderRadius: 0 }} disableRipple>
        <PlayArrowIcon w={15} direction={opened ? "down" : "right"} />
      </IconButton>

      {/* ------------------------------------------------------------------------- */}
      {/* task-name, status button and details */}
      <Stack
        sx={{
          width: "425px",
          paddingLeft: componentType === "main" ? "0px" : "30px",
          boxSizing: "border-box",
          flexShrink: "0px",
          flexDirection: "row",
          alignItems: "center",
          gap: "15px",
        }}
      >
        {/* status button */}
        <IconButton
          sx={{ p: 0, borderRadius: 0 }}
          disableRipple
          onClick={(e) => setStatusAnchorEl(e.currentTarget)}
        >
          <SelectIcon w={15} color={statusColor} />
        </IconButton>

        {/* title */}
        <Typography
          sx={{
            width: "230px",
            font: "normal normal 300 15px/19px Source Serif Pro",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {title}
        </Typography>

        <Tooltip title="--detial--" placement="top" arrow>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
              display: showDetails ? "flex" : "none",
            }}
          >
            <NotepadIcon w={15} />
            <Typography
              sx={{
                font: "normal normal 900 12px/12px Source Serif Pro",
                paddingTop: "2px",
              }}
            >
              0
            </Typography>
          </Stack>
        </Tooltip>

        <Tooltip title="detial 2" placement="top" arrow>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
              display: showDetails ? "flex" : "none",
            }}
          >
            <ChecklistWithBoxIcon w={15} />
            <Typography
              sx={{
                font: "normal normal 900 12px/12px Source Serif Pro",
                paddingTop: "2px",
              }}
            >
              0
            </Typography>
          </Stack>
        </Tooltip>
      </Stack>

      {/* ------------------------------------------------------------------------- */}

      {/* flexible area // responsive area */}
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          flexGrow: 1,
          justifyContent: "space-between",
        }}
      >
        <IconDropdownUser
          componentType="showUser"
          options={userList}
          showSelectedUser={userList[0]}
          onSelect={(value) => console.log(value)}
        />

        <Stack sx={{ flexDirection: "row", gap: "10px", alignItems: "center" }}>
          <IconButton
            sx={{ p: 0, borderRadius: 0 }}
            disableRipple
            onClick={(e) => setFlagAnchorEl(e.currentTarget)}
          >
            <FlagIcon
              w={15}
              color={
                item?.priority?.customColorHex ||
                item?.priority?.defaultColorHex ||
                "#D0021B"
              }
            />
          </IconButton>
          <Typography
            sx={{ font: "normal normal 300 12px/15px Source Serif Pro" }}
          >
            {priority?.value}
          </Typography>
        </Stack>

        <SingleDatePickerSmall
          initialDate={dueDate}
          onSelect={(date: any) => {
            if (updateDroppable) {
              updateDroppable("dueDate", date);
            }
          }}
        />

        <Tooltip title="Add custom elements" placement="top" arrow>
          <IconButton sx={{ padding: 0 }} disableRipple onClick={handleClick}>
            <PlusIcon w={14} />
          </IconButton>
        </Tooltip>

        <IconButton sx={{ p: 0, borderRadius: 0 }} disableRipple>
          <CardIcon w={15} />
        </IconButton>

        {/* --------- popover for add element --------- */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          sx={{
            "& .MuiPaper-root": {
              boxShadow: "0px 3px 6px #00000029",
              border: "0.5px solid #E9EDF2",
            },
          }}
        >
          <ElementsOptionsList
            options={AddElementPopoutOptions}
            onSelect={handleSelect}
            onClose={handleClose}
          />
        </Popover>
        {/* -------------------------------------------- */}

        {/* --------- popover for status --------- */}
        <Popover
          id={statusId}
          open={statusOpen}
          anchorEl={statusAnchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          sx={{
            "& .MuiPaper-root": {
              boxShadow: "0px 3px 6px #00000029",
              border: "0.5px solid #E9EDF2",
            },
          }}
        >
          <OpenDropdownWithColor
            options={DUMMY_TODO_OPTIONS}
            initialValue={DUMMY_TODO_OPTIONS[0]}
            onChange={(value) => console.log(value)}
            onSelect={(value) => {
              if (updateDroppable) {
                updateDroppable("status", value);
              }
            }}
          />
        </Popover>
        {/* -------------------------------------------- */}

        {/* --------- popover for flag --------- */}
        <Popover
          id={flagId}
          open={flagOpen}
          anchorEl={flagAnchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          sx={{
            "& .MuiPaper-root": {
              boxShadow: "0px 3px 6px #00000029",
              border: "0.5px solid #E9EDF2",
            },
          }}
        >
          <OpenDropdownWithColor
            options={DUMMY_PRIORITY_OPTIONS}
            initialValue={item?.priority}
            onChange={(value) => console.log(value)}
            onSelect={(value) => {
              if (updateDroppable) {
                updateDroppable("priority", value);
              }
            }}
          />
        </Popover>
        {/* -------------------------------------------- */}
      </Stack>
    </Stack>
  );
};

export default ChecklistItemPM;
