import React from "react";

import {
  BoardIcon,
  CalendarIcon,
  ChecklistIcon,
  DecksIcon,
  DocumentIcon,
  GanttIcon,
  GridsIcon,
  ListsIcon,
  TreeIcon,
  WhiteboardIcon,
  WikiIcon,
} from "../images";

// Mapping component types to their corresponding icons with strong typing
const iconMap = {
  Boards: BoardIcon,
  Checklists: ChecklistIcon,
  Decks: DecksIcon,
  Document: DocumentIcon,
  Gantt: GanttIcon,
  Grid: GridsIcon,
  Lists: ListsIcon,
  Whiteboard: WhiteboardIcon,
  Wiki: WikiIcon,
  Calendar: CalendarIcon,
  Tree: TreeIcon,

  Board: BoardIcon,
  Checklist: ChecklistIcon,
  List: ListsIcon,
} as const;

export type IconKey = keyof typeof iconMap;

interface RenderWorkspaceIconProps {
  component: IconKey;
  w?: number;
  color?: string;
}

export const RenderWorkspaceIcon: React.FC<RenderWorkspaceIconProps> = ({
  component,
  w = 24,
  color = "black",
}) => {
  // Securely map component to the corresponding icon
  const IconComponent = iconMap[component];

  // Prevent rendering if the icon is not found or invalid
  if (!IconComponent) {
    // console.error(`No icon found for component: ${component}`);
    return null;
  }

  // Safeguard against potential issues by sanitizing props
  const sanitizedWidth = Number(w) || 24;

  return <IconComponent w={sanitizedWidth} color={color} />;
};
