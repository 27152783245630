import { Box, Typography } from "@mui/material";
import React from "react";

const SoonHorizontal: React.FC = () => {
  return (
    <Box
      sx={{
        maxHeight: "max-content",
        maxWidth: "max-content",
        background: "#008D4C 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "5px",
      }}
    >
      <Typography
        sx={{
          font: "normal normal 900 10px/13px Source Serif Pro",
          color: "white",
          px: "3.5px",
          py: "1px",
        }}
      >
        Soon
      </Typography>
    </Box>
  );
};

export default SoonHorizontal;
