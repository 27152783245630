import * as React from "react";
import {
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import S from "./DropdownLargeSingle.module.css";
import { GreenDropdownIcon } from "../../../images";
import { CheckboxMedium } from "../CheckboxMedium";

interface DropdownLargeSingleProps {
  label?: string;
  options: string[];
  componentHeader: string;
  width?: string; // Add width prop
  onChange?: (selectedOption: string) => void;
  borderColor: "black" | "lightBlue"; // Add border color
}

const DropdownLargeSingle: React.FC<DropdownLargeSingleProps> = React.memo(
  ({
    label,
    options,
    componentHeader,
    width = "375px", // Default to full width
    onChange,
    borderColor = "lightBlue",
    ...props
  }) => {
    const [selectedValues, setSelectedValues] = React.useState<string[]>([]);
    const [isOpen, setIsOpen] = React.useState(false); // Manage open state

    const handleChange = React.useCallback(
      (event: SelectChangeEvent<typeof selectedValues>) => {
        const {
          target: { value },
        } = event;
        setSelectedValues(typeof value === "string" ? value.split(",") : value);

        if (onChange) {
          const selectedValue =
            typeof value === "string" ? value : value.join(", ");
          onChange(selectedValue);
        }
      },
      [onChange]
    );

    const menuProps = {
      PaperProps: {
        className: `${S["dropdown-large-single__menu"]} ${S[`dropdown-large-single__menu--border-${borderColor}`]}`,
        style: { width: width },
      },
    };

    return (
      <div>
        <FormControl
          size="small"
          style={{ width }} // Apply the width prop dynamically
          {...props}
        >
          {label && (
            <Typography
              sx={{
                font: "normal normal normal 10px/13px Source Serif Pro",
                color: "black",
                marginBottom: "5px",
              }}
            >
              {label}
            </Typography>
          )}
          <Select
            onClick={!isOpen ? () => setIsOpen(true) : () => null}
            open={isOpen}
            displayEmpty
            IconComponent={() => (
              <GreenDropdownIcon open={isOpen} sx={{ marginRight: "10px" }} />
            )} // Pass open state as prop
            value={selectedValues}
            onChange={handleChange} // Use handleChange function
            input={
              <OutlinedInput
                className={`${S["dropdown-large-single__inputbox"]} ${S[`dropdown-large-single__inputbox--border-${borderColor}`]}`}
                classes={{
                  notchedOutline: S["dropdown-large-single__notchedOutline"],
                  focused: S["dropdown-large-single__focused"],
                }}
              />
            }
            onOpen={() => setIsOpen(true)} // Handle open state
            onClose={() => setIsOpen(false)} // Handle close state
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <em
                    className={S["dropdown-large-single__select-placeholder"]}
                  >
                    {componentHeader}
                  </em>
                );
              }

              return (
                <span className={S["dropdown-large-single__selected"]}>
                  {selected.join(", ")}
                </span>
              );
            }}
            MenuProps={menuProps}
          >
            {options.map((option) => (
              <MenuItem
                className={S["dropdown-large-single__menu-item"]}
                key={option}
                value={option}
              >
                <CheckboxMedium
                  className={S["dropdown-large-single__checkbox"]}
                  checked={selectedValues.indexOf(option) > -1}
                />
                <ListItemText
                  primary={option}
                  primaryTypographyProps={{
                    className: S["dropdown-large-single__list-item-text"],
                  }}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
);

export default DropdownLargeSingle;
