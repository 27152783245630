import React from "react";
import { Stack, Typography } from "@mui/material";
import { GreenArrowButton } from "../../../../Buttons";
import { useNavigate } from "react-router-dom";

interface WorkspaceComponentProps {
  workspaceId: string;
  itemId?: string;
}

const WorkspaceRoute: React.FC<WorkspaceComponentProps> = ({
  workspaceId,
  itemId,
}) => {
  const navigate = useNavigate();
  if (!workspaceId) return null;

  const text = itemId ? "Back to workspace cover" : "Back to all workspaces";

  const handleNavigate = () => {
    if (itemId) {
      navigate(`/workspace/${workspaceId}`); // Navigate to workspace cover
    } else {
      navigate(`/workspace`); // Navigate to all workspaces
    }
  };

  return (
    <Stack
      direction={"row"}
      sx={{ cursor: "pointer" }}
      alignItems={"center"}
      gap={1}
      onClick={handleNavigate}
    >
      <GreenArrowButton
        sx={{
          display: "block",
          transform: "rotate(180deg)",
        }}
      />
      <Typography sx={{ font: "normal normal 600 15px/21px Source Serif Pro" }}>
        {text}
      </Typography>
    </Stack>
  );
};

export default WorkspaceRoute;
