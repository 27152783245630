import React, { useRef, useState } from "react";
import {
  ArrowsIcon,
  EraseIcon,
  ImageIcon,
  MouseArrowIcon,
  RightArrowBlackIcon,
  SlashIcon,
  TextTIcon,
  WhiteBoardDragIcon,
} from "../../../images";
import { Divider, IconButton, Stack } from "@mui/material";
import { MarkerButton } from "../MarkerButton";
import { ShapesButton } from "../ShapesButton";
import { iconButtonStyles } from "../../../Utils";

interface WhiteBoardToolsProps {
  addText: () => void;
  handleImageUpload: (e: Event) => void;
  addShape: (typeOfShape: string) => void;
  togglePencilMode: (
    wantPenMode: boolean,
    colorValue: string,
    typeOfPencilMode: string,
    strokeSize: number
  ) => void;
  toggleEraserMode: (wantEraser: boolean, strokeSize?: number) => void;
  addLine: (typeOfLine: string) => void;
  togglePanMode: (value: boolean) => void;
  isPanModeActive: boolean;
  isPencilModeActive: boolean;
  isEraserModeActive?: boolean;
}

const WhiteBoardTools: React.FC<WhiteBoardToolsProps> = ({
  addText,
  handleImageUpload,
  addShape,
  togglePencilMode,
  toggleEraserMode,
  addLine,
  togglePanMode,
  isPanModeActive = false,
  isPencilModeActive = false,
  isEraserModeActive = false,
}) => {
  const [selectedTool, setSelectedTool] = useState<string>("MouseArrow");

  const handleSelectTool = (toolName: string) => {
    setSelectedTool(toolName);
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    // Trigger the hidden file input when the button is clicked
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      // Call the provided onImageUpload callback with the selected file
      handleImageUpload(event);

      event.target.value = "";
    }
  };

  return (
    <Stack
      sx={{
        flexDirection: "column",
        padding: "15px 2px",
        width: "35px",
        gap: "5px",
        borderRadius: "5px",
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
      }}
    >
      <IconButton
        sx={iconButtonStyles(isPanModeActive ? false : true)}
        disableRipple
        onClick={() => {
          handleSelectTool("MouseArrow");
          togglePanMode && togglePanMode(false);
        }}
      >
        <MouseArrowIcon w={25} />
      </IconButton>
      <IconButton
        sx={iconButtonStyles(isPanModeActive)}
        disableRipple
        onClick={() => togglePanMode && togglePanMode(true)}
      >
        <WhiteBoardDragIcon w={25} />
      </IconButton>

      <Divider sx={{ borderColor: "#E9EDF2" }} />

      <MarkerButton
        togglePencilMode={togglePencilMode}
        selectedTool={selectedTool}
        handleSelectTool={handleSelectTool}
        isPencilModeActive={isPencilModeActive}
      />

      <IconButton
        sx={iconButtonStyles(selectedTool === "TextT")}
        disableRipple
        onClick={() => addText && addText()}
      >
        <TextTIcon w={25} />
      </IconButton>

      <Divider sx={{ borderColor: "#E9EDF2" }} />

      <ShapesButton
        selectedTool={selectedTool}
        addShape={addShape}
        handleSelectTool={handleSelectTool}
      />

      <IconButton
        sx={iconButtonStyles(selectedTool === "Slash")}
        disableRipple
        onClick={() => addLine && addLine("straight")}
      >
        <SlashIcon w={25} />
      </IconButton>

      <IconButton
        sx={iconButtonStyles(selectedTool === "RightArrowBlack")}
        disableRipple
        onClick={() => addLine && addLine("arrow")}
      >
        <RightArrowBlackIcon w={25} />
      </IconButton>

      <Divider sx={{ borderColor: "#E9EDF2" }} />

      <IconButton
        sx={iconButtonStyles(selectedTool === "Arrows")}
        disableRipple
        onClick={() => addLine && addLine("curve")}
      >
        <ArrowsIcon w={25} />
      </IconButton>

      <IconButton
        sx={iconButtonStyles(selectedTool === "Image")}
        disableRipple
        onClick={handleClick}
      >
        <ImageIcon w={25} />
      </IconButton>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <IconButton
        sx={iconButtonStyles(isEraserModeActive)}
        disableRipple
        onClick={() =>
          toggleEraserMode &&
          toggleEraserMode(isEraserModeActive ? false : true, 5)
        }
      >
        <EraseIcon w={25} />
      </IconButton>
    </Stack>
  );
};

export default WhiteBoardTools;
