import React, { useState, useRef, useEffect } from "react";
import { Stack, TextField, Typography } from "@mui/material";
import { GreenDropdownIcon } from "../../../images";
import styles from "./ObjectiveTaskDropdown.module.css";
import dayjs from "dayjs";
import { CheckboxMedium } from "../CheckboxMedium";
import { SmallButtonDropdown } from "../Buttons";
import {
  ButtonType,
  SmallButtonDropdownColor,
} from "../Buttons/AllButtonProps";
import { IconDropdownUser } from "../IconDropdownUser";
import { DropdownWithColor } from "../DropdownWithColor";
import { DUMMY_TODO_OPTIONS } from "../DropdownWithColor/DropdownWithColorTypes";
import { DatePickerWithIcon } from "../DatePickerWithIcon";
import { getUserList } from "../../../Utils/authService";

interface ObjectiveTaskDropdownProps {
  options: any; // Update this according to your data structure
  handleCreateSubTask?: (
    task: string,
    status: any,
    dueDate: any,
    selectedUser: any
  ) => void;
}

const ObjectiveTaskDropdown: React.FC<ObjectiveTaskDropdownProps> = ({
  options,
  handleCreateSubTask,
}) => {
  const [expanded, setExpanded] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [taskName, setTaskName] = useState("");
  const [status, setStatus] = useState<any>(null); // Ensure status is properly typed
  const [dueDate, setDueDate] = useState("");
  const [selectedUser, setSelectedUser] = useState<any>(null); // Ensure selectedUser is properly typed

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (contentRef.current) {
      if (expanded) {
        contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
      } else {
        contentRef.current.style.maxHeight = "0px";
      }
    }
  }, [expanded]);

  const addSubtask = () => {
    if (!status || !selectedUser || !taskName || !dueDate) {
      return;
    }
    if (handleCreateSubTask) {
      handleCreateSubTask(taskName, status, dueDate, selectedUser);
    }
    // Reset fields
    setTaskName("");
    setStatus(null);
    setDueDate("");
    setSelectedUser(null);
  };

  const userList = getUserList();

  return (
    <Stack className={styles["objective-task-dropdown"]}>
      <Stack className={styles["objective-task-dropdown__item-header"]}>
        <Stack sx={{ flexGrow: 1 }} onClick={toggleExpand}>
          <Stack
            sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}
          >
            <GreenDropdownIcon sx={{ height: 13 }} open={expanded} />
            <Typography
              sx={{ font: "normal normal 300 12px/16px Source Serif Pro" }}
            >
              Add your subtask
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        ref={contentRef}
        className={`${styles["objective-task-dropdown__content"]} ${expanded ? styles.expanded : ""}`}
      >
        <Stack direction={"row"} spacing={2}>
          <CheckboxMedium boxSize={19} />
          <TextField
            label="Task Name"
            value={taskName}
            onChange={(e) => setTaskName(e.target.value)}
          />
        </Stack>

        <Stack ml={4.2} mt={2} gap={3} direction={"row"}>
          <DropdownWithColor
            options={DUMMY_TODO_OPTIONS}
            onChange={(value) => null}
            onSelect={(value: any) => {
              setStatus(value);
            }}
            initialValue={DUMMY_TODO_OPTIONS[1]}
          />
          <DatePickerWithIcon
            initialDate={dueDate ? dayjs(dueDate) : null}
            onDateChange={(date: string | undefined) => setDueDate(date || "")}
          />
          <IconDropdownUser
            componentType="showUser"
            showSelectedUser={selectedUser}
            onSelect={(item) => setSelectedUser(item)}
            options={userList}
          />
          <SmallButtonDropdown
            label="Add Task"
            onClick={addSubtask}
            types={ButtonType.Button}
            colorVarient={SmallButtonDropdownColor.Orange}
          />
        </Stack>
      </Stack>
      {/* Display all subtasks */}
      <Stack>
        {options.length ? (
          options.map((data: any, index: number) => (
            <Stack mt={2} direction={"column"}>
              <Stack direction={"row"} gap={3} alignItems={'center'}>
                <CheckboxMedium boxSize={19} />
                <Typography>{data?.name}</Typography>
              </Stack>

              <Stack ml={4.2} mt={2} gap={3} direction={"row"}>
                <DropdownWithColor
                  options={DUMMY_TODO_OPTIONS}
                  onChange={(value) => null}
                  onSelect={(value: any) => {
                    setStatus(value);
                  }}
                  initialValue={data?.status || DUMMY_TODO_OPTIONS[1]}
                />
                <DatePickerWithIcon
                  initialDate={data?.dueDate ? dayjs(data?.dueDate) : null}
                  onDateChange={(date: string | undefined) =>
                    setDueDate(date || "")
                  }
                />
                <IconDropdownUser
                  componentType="showUser"
                  showSelectedUser={selectedUser}
                  onSelect={(item) => setSelectedUser(item)}
                  options={userList}
                />
              </Stack>
            </Stack>
          ))
        ) : (
          <Typography sx={{ color: "grey" }}>No subtasks available</Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default ObjectiveTaskDropdown;
