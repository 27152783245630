import {
  Calendar,
  Calls,
  CloudStorage,
  Crm,
  Dashboard,
  Email,
  FirstLogin,
  ForgotPassword,
  InsideFile_CloudStorage,
  InsideWorkspace,
  InsideWorkspaceItem,
  Login,
  Message,
  OverView,
  Project,
  Settings,
  Signup,
  Trax,
  Workspace,
} from "../components/pages";
import {
  HomeIcon,
  VideoIcon,
  MailIcon,
  PlanIcon,
  TaskIcon,
  StreamIcon,
  CRMIcon,
  TraxIcon,
} from "./RouteIcon";
import { RouteConfig } from "./RouteTypes";

export const ROUTES: RouteConfig[] = [
  {
    path: "/login",
    component: Login,
    isPrivate: false,
    useCommonLayout: false,
    name: "Login",
  },

  /**
   *
   * after login, one route page will be served by default
   *
   */

  {
    path: "/profile-Mapping",
    component: FirstLogin,
    isPrivate: false,
    useCommonLayout: false,
    name: "ProfileMapping",
  },

  {
    path: "/signup",
    component: Signup,
    isPrivate: false,
    useCommonLayout: false,
    name: "Sign Up",
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    isPrivate: false,
    useCommonLayout: false,
    name: "Forgot Password",
  },

  // Dummy routes
  {
    path: "/dashboard",
    component: Dashboard,
    isPrivate: true,
    useCommonLayout: true,
    name: "Home",
    icon: HomeIcon,
    uniColor: "#a53a0187",
    isDrawerTab: true,
  },
  {
    path: "/Calls",
    component: Calls,
    isPrivate: true,
    useCommonLayout: true,
    name: "Calls",
    icon: VideoIcon,
    uniColor: "#ff818180",
    isDrawerTab: true,
  },

  {
    path: "/Email",
    component: Email,
    isPrivate: true,
    useCommonLayout: true,
    name: "Email",
    icon: MailIcon,
    uniColor: "#2a31b587",
    isDrawerTab: true,
  },
  {
    path: "/Calendar",
    component: Calendar,
    isPrivate: true,
    useCommonLayout: true,
    name: "Plan",
    icon: PlanIcon,
    uniColor: "#b5005b85",
    isDrawerTab: true,
  },
  {
    path: "/workspace",
    component: Workspace,
    isPrivate: true,
    useCommonLayout: true,
    name: "Workspace",
    icon: TaskIcon,
    uniColor: "#0073b585",
    isDrawerTab: true,
  },
  {
    path: `/workspace/:workspaceId`,
    component: InsideWorkspace,
    isPrivate: true,
    useCommonLayout: true,
    name: "Workspace",
    icon: TaskIcon,
    uniColor: "#0073b585",
    isDrawerTab: false,
  },
  {
    path: `/workspace/:isPublic/:workspaceId/:componentType/:componentId?/:subcomponentId?`,
    component: InsideWorkspaceItem,
    isPrivate: true,
    useCommonLayout: true,
    name: "Workspace",
    icon: TaskIcon,
    uniColor: "#0073b585",
    isDrawerTab: false,
  },

  {
    path: "/cloud-storage/",
    component: CloudStorage,
    isPrivate: true,
    useCommonLayout: true,
    name: "Cloud Storage",
    icon: TaskIcon,
    uniColor: "#0073b585",
    isDrawerTab: true,
  },

  {
    path: "/cloud-storage/:folderId/:fileType/:fileId",
    component: InsideFile_CloudStorage,
    isPrivate: true,
    useCommonLayout: true,
    name: "Cloud Storage",
    icon: TaskIcon,
    uniColor: "#0073b585",
    isDrawerTab: false,
  },

  {
    path: "/Project",
    component: Project,
    isPrivate: true,
    useCommonLayout: true,
    name: "Project",
    icon: TaskIcon,
    uniColor: "#0073b585",
    isDrawerTab: false,
  },
  {
    path: "/Message",
    component: Message,
    isPrivate: true,
    useCommonLayout: true,
    name: "Message",
    icon: StreamIcon,
    uniColor: "#765ee286",
    isDrawerTab: true,
  },
  {
    path: "/CRM",
    component: Crm,
    isPrivate: true,
    useCommonLayout: true,
    name: "CRM",
    icon: CRMIcon,
    uniColor: "#e2885e85",
    isDrawerTab: true,
  },
  {
    path: "/CRM/people",
    component: Crm,
    isPrivate: true,
    useCommonLayout: true,
    name: "CRM",
    icon: CRMIcon,
    uniColor: "#e2885e85",
    isDrawerTab: false,
  },
  {
    path: "/CRM/people/:personId",
    component: OverView,
    isPrivate: true,
    useCommonLayout: true,
    name: "CRM",
    icon: CRMIcon,
    uniColor: "#e2885e85",
    isDrawerTab: false,
  },
  {
    path: "/CRM/company",
    component: Crm,
    isPrivate: true,
    useCommonLayout: true,
    name: "CRM",
    icon: CRMIcon,
    uniColor: "#e2885e85",
    isDrawerTab: false,
  },
  {
    path: "/CRM/company/:companyId",
    component: OverView,
    isPrivate: true,
    useCommonLayout: true,
    name: "CRM",
    icon: CRMIcon,
    uniColor: "#e2885e85",
    isDrawerTab: false,
  },
  {
    path: "/CRM/deal",
    component: Crm,
    isPrivate: true,
    useCommonLayout: true,
    name: "CRM",
    icon: CRMIcon,
    uniColor: "#e2885e85",
    isDrawerTab: false,
  },
  {
    path: "/CRM/deal/:dealId",
    component: OverView,
    isPrivate: true,
    useCommonLayout: true,
    name: "CRM",
    icon: CRMIcon,
    uniColor: "#e2885e85",
    isDrawerTab: false,
  },
  {
    path: "/Trax",
    component: Trax,
    isPrivate: true,
    useCommonLayout: true,
    name: "Trax",
    icon: TraxIcon,
    uniColor: "#30879381",
    isDrawerTab: true,
  },
  {
    path: "/Settings",
    component: Settings,
    isPrivate: true,
    useCommonLayout: true,
    name: "Settings",
    icon: TraxIcon,
    uniColor: "#30879381",
    isDrawerTab: false,
  },
  {
    path: "/Settings/general",
    component: Settings,
    isPrivate: true,
    useCommonLayout: true,
    name: "Settings",
    icon: TraxIcon,
    uniColor: "#30879381",
    isDrawerTab: false,
  },
  {
    path: "/Settings/app-notifications",
    component: Settings,
    isPrivate: true,
    useCommonLayout: true,
    name: "Settings",
    icon: TraxIcon,
    uniColor: "#30879381",
    isDrawerTab: false,
  },
  {
    path: "/Settings/app-security",
    component: Settings,
    isPrivate: true,
    useCommonLayout: true,
    name: "Settings",
    icon: TraxIcon,
    uniColor: "#30879381",
    isDrawerTab: false,
  },
];
