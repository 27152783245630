import { IconButton, Stack } from "@mui/material";
import React from "react";
import { RedoBoldIcon, UndoBoldIcon } from "../../../images";
import { iconButtonStyles } from "../../../Utils";

interface UndoRedoButtonsProps {
  handleUndo: () => void;
  handleRedo: () => void;
}

const UndoRedoButtons: React.FC<UndoRedoButtonsProps> = ({
  handleUndo,
  handleRedo,
}) => {
  return (
    <Stack
      sx={{
        flexDirection: "column",
        padding: "15px 2px",
        width: "35px",
        gap: "5px",
        borderRadius: "5px",
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
      }}
    >
      <IconButton
        sx={iconButtonStyles(false, {
          selectedBgColor: "#ff5733",
          hoverBgColor: "#ffbd69",
          selectedBorderColor: "#c70039",
          hoverBorderColor: "#900c3f",
          borderStyle: "dashed",
          borderWidth: "1px",
          padding: "8px",
          transitionDuration: "0.2s",
        })}
        disableRipple
        onClick={handleUndo}
      >
        <UndoBoldIcon w={25} />
      </IconButton>
      <IconButton
        sx={iconButtonStyles(false, {
          selectedBgColor: "#ff5733",
          hoverBgColor: "#ffbd69",
          selectedBorderColor: "#c70039",
          hoverBorderColor: "#900c3f",
          borderStyle: "dashed",
          borderWidth: "1px",
          padding: "8px",
          transitionDuration: "0.2s",
        })}
        disableRipple
        onClick={handleRedo}
      >
        <RedoBoldIcon w={25} />
      </IconButton>
    </Stack>
  );
};

export default UndoRedoButtons;
