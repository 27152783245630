import React from "react";
import {
  ChecklistIcon,
  EmailIcon,
  NotesIcon,
  StreamIcon,
  VideoIconCrm,
} from "../../../../../images";
import { PlanIcon } from "../../../../../Router/RouteIcon";
import { IconButton, Stack } from "@mui/material";
type MultiIconButtonProps = {};
const actionIcons = [
  VideoIconCrm,
  EmailIcon,
  StreamIcon,
  ChecklistIcon,
  NotesIcon,
  PlanIcon,
];
const MultiIconButton: React.FC<MultiIconButtonProps> = () => {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      gap={1}
      justifyContent={"flex-start"}
    >
      {actionIcons.map((Icon, index) => (
        <IconButton key={index}>
          <Icon w={15} />
        </IconButton>
      ))}
    </Stack>
  );
};

export default MultiIconButton;
