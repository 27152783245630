import React from "react";
import { Stack, Typography, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./DividerOrDivider.module.css";

// Define a type for the component's props
interface DividerOrDividerProps {
  translatedText: string;
}

// Centralize data management for future API integration
const initialData = {
  translatedText: "or"
};

const DividerOrDivider: React.FC<DividerOrDividerProps> = ({ translatedText }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      gap={1} // Using MUI spacing system
      height="100%"
      width="100%"
    >
      <Divider className={styles.divider} />
      <Typography className={styles.text}>
        {translatedText}
      </Typography>
      <Divider className={styles.divider} />
    </Stack>
  );
};

// Wrapper to handle translation using i18next
const DividerOrDividerContainer: React.FC = () => {
  const { t } = useTranslation();
  const translatedText = t(initialData.translatedText);

  return <DividerOrDivider translatedText={translatedText} />;
};

export default DividerOrDividerContainer;
