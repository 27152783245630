import React from "react";
import { Button, ButtonProps, Typography } from "@mui/material";
import style from "./SmallButtonDropdown.module.css";
import { ButtonType, SmallButtonDropdownColor } from "../AllButtonProps";

interface SmallButtonDropdownProps extends ButtonProps {
  colorVarient?: SmallButtonDropdownColor;
  label: string;
  types?: ButtonType;
}

const SmallButtonDropdown: React.FC<SmallButtonDropdownProps> = React.memo(
  ({
    colorVarient = SmallButtonDropdownColor.Orange,
    label,
    types = ButtonType.Submit,
    sx,
    ...props
  }) => {
    const isTransparent = colorVarient === SmallButtonDropdownColor.Transparent;
    const buttonClass = `${style.SmallButtonDropdown} ${style[`SmallButtonDropdown--${colorVarient}`]}`;

    return (
      <Button
        sx={{
          width: "50px",
          height: "15px",
          font: isTransparent
            ? "normal normal 900 11px/15px Source Serif Pro"
            : "normal normal normal 10px/13px Source Serif Pro",
          minWidth: "50px",
          ...sx,
        }} // Default width with sx override
        className={buttonClass}
        variant="contained"
        type={types}
        disableRipple={isTransparent}
        disableElevation
        aria-label={label} // Improve accessibility
        {...props}
      >
        <Typography
          sx={{
            font: isTransparent
              ? "normal normal 900 11px/15px Source Serif Pro"
              : "normal normal normal 10px/13px Source Serif Pro",
            paddingTop: "1.5px",
          }}
        >
          {label}
        </Typography>
      </Button>
    );
  }
);

export default SmallButtonDropdown;
