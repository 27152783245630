import React from "react";
import { Avatar } from "@mui/material";
import styles from "./AvatarLarge.module.css";
import { AvatarLargeProps, AvatarSize, BgColorCode } from "./AvatarLargeTypes";

const AvatarLarge: React.FC<AvatarLargeProps> = React.memo(
  ({
    size,
    text = "AA",
    bgColorCode = BgColorCode.Default,
    imgSrc,
    ...props
  }) => {
    const sizeClass = React.useMemo(
      () =>
        size === AvatarSize.Large
          ? styles.AvatarLarge__large
          : styles.AvatarLarge__small,
      [size]
    );

    const avatarStyle = {
      backgroundColor: bgColorCode,
      font: "normal normal 600 20px/28px Source Serif Pro",
    };

    return (
      <Avatar
        className={`${sizeClass} ${styles.AvatarLarge__customBg}`}
        src={imgSrc}
        style={avatarStyle}
        {...props}
      >
        {!imgSrc && text}
      </Avatar>
    );
  }
);

export default AvatarLarge;
