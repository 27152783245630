/* store.ts */
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./rootReducer";
import ApiMiddleware from "./middleware/apiMiddleware";

/**
 * Configuration object for redux-persist
 * - key: The key used for storing persisted state
 * - storage: The storage engine to use (localStorage in this case)
 * - whitelist: Array of reducer names that should be persisted
 */
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "layout"],
};

/**
 * Create a persisted reducer using the persistConfig and rootReducer
 */
const persistedReducer = persistReducer(persistConfig, rootReducer);

/**
 * Configure and create the Redux store
 * - reducer: Use the persisted reducer
 * - middleware: Apply default middleware with additional settings and custom middleware
 */
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        /**
         * Configuration for ignoring specific action types, action paths,
         * and state paths during serializable check
         */
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
        ignoredActionPaths: ["meta.arg", "payload.timestamp"],
        ignoredPaths: ["items.dates"],
      },
    }).concat(ApiMiddleware),
});

/**
 * Create the persistor which is responsible for persisting and rehydrating the store
 */
export const persistor = persistStore(store);

/**
 * Type definitions for dispatch and state
 * - AppDispatch: The type for the dispatch function
 * - RootState: The type for the root state of the Redux store
 */
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
