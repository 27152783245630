/* CompanyTable.tsx */
import React, { useState } from "react";
import { ColDef, CellValueChangedEvent } from "ag-grid-community";
import { ICellRendererParams } from "ag-grid-community";
import {
  AddCalculationsMenu,
  CategoriesTable,
  CName,
  DataTable,
  DomainTable,
  RatingTable,
  CompanyData,
} from "../../../components";
import { AutoSizeTextArea, DateAndTimePicker } from "../../../../../common";
import { CustomHeaderComponent } from "../../../common";

const companyColumnDefs: ColDef<CompanyData>[] = [
  {
    headerName: "Company",
    field: "company",
    headerClass: "my-header-class",
    sortable: true,
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      imageUrl:
        "https://vignette.wikia.nocookie.net/es.pokemon/images/f/f4/Charizard_PLB.png/revision/latest?cb=20170426010226", // URL of the image
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return (
        <CName hPopover={true} br={"25%"} targetOpen={true} targetLocation="company" value={params.value.companyName} />
      );
    },
  },
  {
    headerName: "Categories",
    field: "categories",
    sortable: true,
    filter: true,
    headerClass: "my-header-class",
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      imageUrl:
        "https://vignette.wikia.nocookie.net/es.pokemon/images/f/f4/Charizard_PLB.png/revision/latest?cb=20170426010226", // URL of the image
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <CategoriesTable words={params.value} />;
    },
  },
  {
    headerName: "Last touchpoint",
    field: "lastTouchpoint",
    sortable: true,
    filter: true,
    editable: false,
    wrapText: true,
    headerClass: "my-header-class",
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      imageUrl:
        "https://vignette.wikia.nocookie.net/es.pokemon/images/f/f4/Charizard_PLB.png/revision/latest?cb=20170426010226", // URL of the image
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <DateAndTimePicker />;
    },
  },
  {
    headerName: "Relationship Strength",
    field: "relationshipStrength",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      imageUrl:
        "https://vignette.wikia.nocookie.net/es.pokemon/images/f/f4/Charizard_PLB.png/revision/latest?cb=20170426010226", // URL of the image
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <RatingTable tableData={params} />;
    },
  },
  {
    headerName: "Description",
    field: "description",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      imageUrl:
        "https://vignette.wikia.nocookie.net/es.pokemon/images/f/f4/Charizard_PLB.png/revision/latest?cb=20170426010226", // URL of the image
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <AutoSizeTextArea TableData={params} />;
    },
  },
  {
    headerName: "Domain",
    field: "domain",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      imageUrl:
        "https://vignette.wikia.nocookie.net/es.pokemon/images/f/f4/Charizard_PLB.png/revision/latest?cb=20170426010226", // URL of the image
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <DomainTable TableData={params} />;
    },
  },

  {
    headerName: "Data ID number",
    field: "dataIDNumber",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      imageUrl:
        "https://vignette.wikia.nocookie.net/es.pokemon/images/f/f4/Charizard_PLB.png/revision/latest?cb=20170426010226", // URL of the image
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <AutoSizeTextArea TableData={params} />;
    },
  },

  {
    headerName: "Teams",
    field: "teams",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      imageUrl:
        "https://vignette.wikia.nocookie.net/es.pokemon/images/f/f4/Charizard_PLB.png/revision/latest?cb=20170426010226", // URL of the image
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <CName value={params.value} />;
    },
  },
  {
    headerName: "Primary location",
    field: "primaryLocation",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      imageUrl:
        "https://vignette.wikia.nocookie.net/es.pokemon/images/f/f4/Charizard_PLB.png/revision/latest?cb=20170426010226", // URL of the image
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <AutoSizeTextArea TableData={params} />;
    },
  },
  {
    headerName: "Crunchbase",
    field: "crunchBase",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      imageUrl:
        "https://vignette.wikia.nocookie.net/es.pokemon/images/f/f4/Charizard_PLB.png/revision/latest?cb=20170426010226", // URL of the image
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <DomainTable TableData={params} />;
    },
  },
  {
    headerName: "Estimated ARR",
    field: "estimatedARR",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      imageUrl:
        "https://vignette.wikia.nocookie.net/es.pokemon/images/f/f4/Charizard_PLB.png/revision/latest?cb=20170426010226", // URL of the image
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <CategoriesTable words={params.value} />;
    },
  },
  {
    headerName: "Projects involved in",
    field: "projectsInvolvedIn",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      imageUrl:
        "https://vignette.wikia.nocookie.net/es.pokemon/images/f/f4/Charizard_PLB.png/revision/latest?cb=20170426010226", // URL of the image
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <CName value={params.value} />;
    },
  },
  {
    headerName: "Employee Count",
    field: "employeeCount",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      imageUrl:
        "https://vignette.wikia.nocookie.net/es.pokemon/images/f/f4/Charizard_PLB.png/revision/latest?cb=20170426010226", // URL of the image
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <CategoriesTable words={params.value} />;
    },
  },
  {
    headerName: "Next related task",
    field: "nextRelatedTask",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      imageUrl:
        "https://vignette.wikia.nocookie.net/es.pokemon/images/f/f4/Charizard_PLB.png/revision/latest?cb=20170426010226", // URL of the image
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <AutoSizeTextArea TableData={params} />;
    },
  },
  {
    headerName: "Tasks involved in",
    field: "tasksInvolvedIn",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      imageUrl:
        "https://vignette.wikia.nocookie.net/es.pokemon/images/f/f4/Charizard_PLB.png/revision/latest?cb=20170426010226", // URL of the image
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <CName value={params.value} />;
    },
  },
  {
    headerName: "List of notes",
    field: "listOfNotes",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      imageUrl:
        "https://vignette.wikia.nocookie.net/es.pokemon/images/f/f4/Charizard_PLB.png/revision/latest?cb=20170426010226", // URL of the image
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <CName value={params.value} />;
    },
  },
];

const companyRowData: CompanyData[] = [
  {
    company: {
      companyName: "Red Hair Pirate - Shanks",
      companyLogoUrl:
        "https://static.wikia.nocookie.net/p__/images/e/e0/Red_hair.jpg/revision/latest?cb=20200824232939&path-prefix=protagonist",
    },
    categories: [
      { name: "Software", id: "kd" },
      { name: "Business", id: "eo" },
      { name: "Network", id: "eoe" },
    ],
    lastTouchpoint: Date.now(),
    relationshipStrength: 1.5,
    description:
      "The relationship strength of the company that owns the company.",
    domain: "tech.com",
    dataIDNumber: "hdi-973n",
    teams: "Tech Corp team",
    primaryLocation: {
      state: "MP",
      country: "US",
      zipCode: "555-5556",
      city: "New York",
    },
    crunchBase:  "http://www.angleList.com",
    estimatedARR: ["esstimate arr"],
    projectsInvolvedIn: "projectsInvolvedIn",
    employeeCount: [10],
    nextRelatedTask: "nextRelatedTask",
    tasksInvolvedIn: "tasksInvolvedIn",
    listOfNotes: "listOfNotes",
  },
  {
    company: {
      companyName: "Red Hair Pirate - Shanks",
      companyLogoUrl:
        "https://static.wikia.nocookie.net/p__/images/e/e0/Red_hair.jpg/revision/latest?cb=20200824232939&path-prefix=protagonist",
    },
    categories: [
      { name: "Software", id: "kd" },
      { name: "Business", id: "eo" },
      { name: "Network", id: "eoe" },
    ],
    lastTouchpoint: Date.now(),
    relationshipStrength: 1.5,
    description:
      "The relationship strength of the company that owns the company.",
    domain: "tech.com",
    dataIDNumber: "hdi-973n",
    teams: "Tech Corp team",
    primaryLocation: {
      state: "MP",
      country: "US",
      zipCode: "555-5556",
      city: "New York",
    },
    crunchBase: "http://www.crunchBase.com",
    estimatedARR: ["esstimate arr"],
    projectsInvolvedIn: "projectsInvolvedIn",
    employeeCount: [10],
    nextRelatedTask: "nextRelatedTask",
    tasksInvolvedIn: "tasksInvolvedIn",
    listOfNotes: "listOfNotes",
  },
  {
    company: {
      companyName: "Red Hair Pirate - Shanks",
      companyLogoUrl:
        "https://static.wikia.nocookie.net/p__/images/e/e0/Red_hair.jpg/revision/latest?cb=20200824232939&path-prefix=protagonist",
    },
    categories: [
      { name: "Software", id: "kd" },
      { name: "Business", id: "eo" },
      { name: "Network", id: "eoe" },
    ],
    lastTouchpoint: Date.now(),
    relationshipStrength: 1.5,
    description:
      "The relationship strength of the company that owns the company.",
    domain: "tech.com",
    dataIDNumber: "hdi-973n",
    teams: "Tech Corp team",
    primaryLocation: {
      state: "MP",
      country: "US",
      zipCode: "555-5556",
      city: "New York",
    },
    crunchBase: "http://www.crunchBase.com",
    estimatedARR: ["esstimate arr"],
    projectsInvolvedIn: "projectsInvolvedIn",
    employeeCount: [10],
    nextRelatedTask: "nextRelatedTask",
    tasksInvolvedIn: "tasksInvolvedIn",
    listOfNotes: "listOfNotes",
  },
];

const pinnedBottomRowData: CompanyData[] = [
  {
    company: {
      companyName: "Red Hair Pirate - Shanks",
      companyLogoUrl:
        "https://static.wikia.nocookie.net/p__/images/e/e0/Red_hair.jpg/revision/latest?cb=20200824232939&path-prefix=protagonist",
    },
    categories: [
      { name: "Software", id: "kd" },
      { name: "Business", id: "eo" },
      { name: "Network", id: "eoe" },
    ],
    lastTouchpoint: 25,
    relationshipStrength: 2.5,
    description: "",
    domain: "",
    actions: <AddCalculationsMenu />, // Rendering your component
    dataIDNumber: "hdi-973n",
    teams: "Tech Corp team",
    primaryLocation: {
      state: "MP",
      country: "US",
      zipCode: "555-5556",
      city: "New York",
    },

    crunchBase: "http://www.crunchBase.com",
    estimatedARR: ["esstimate arr"],
    projectsInvolvedIn: "projectsInvolvedIn",
    employeeCount: [10],
    nextRelatedTask: "nextRelatedTask",
    tasksInvolvedIn: "tasksInvolvedIn",
    listOfNotes: "listOfNotes",
  },
];

const updateCompanyData = async (event: CellValueChangedEvent<CompanyData>) => {
  const updatedData = event.data;
  // Make an API call to update the data
  try {
    console.log("Data updated successfully:", updatedData);
  } catch (error) {
    console.error("Error updating data:", error);
  }
};

const CompanyTable = () => {
  const [rowData] = useState(companyRowData);
  const [columnDefs] = useState(companyColumnDefs);

  return (
    <DataTable<CompanyData>
      columnDefs={columnDefs}
      rowData={rowData}
      pinnedBottomRowData={pinnedBottomRowData}
      onCellValueChanged={updateCompanyData}
    />
  );
};

export default CompanyTable;
