import React, { lazy, Suspense, useState } from "react";
import { Stack, IconButton, Typography } from "@mui/material";
import { XCircleIcon } from "../../../../../images";
import {
  a11yProps,
  StyledTab,
  TabPanel,
  TabsWithState,
} from "../../../../common/CardTabsWithoutIcon";

interface ChannelDetailsMenuProps {}

const LazyContactsTab = lazy(() => import("./tabs/ContactsTab/ContactsTab"));
const LazyDetailsTab = lazy(() => import("./tabs/DetailsTab/DetailsTab"));

const Dummy_channel_data = {
  channelName: "Channel Name here",
  channelTopic: "channel topic",
  channelDescription: `This is the description that you gave when you created this new channel
     and you are able to update it This is the description that you gave
      when you created this new channel and you are able to update it`,
  createdBy: "Johnas Johnson",
  createdAt: "timestamp here",
  channelId: 4515425151,
};

const tabs = [
  {
    label: "Details",
    content: <LazyDetailsTab {...Dummy_channel_data} />,
  },
  {
    label: "Contacts",
    content: <LazyContactsTab />,
  },
];

const ChannelDetailsMenu: React.FC<ChannelDetailsMenuProps> = ({}) => {
  const [tabValue, setTabValue] = useState(0);

  return (
    <Stack
      sx={{
        width: "375px",
        height: "430px",
        bgcolor: "white",
        padding: "15px",
        boxSizing: "border-box",
        gap: "15px",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{ font: "normal normal 700 15px/19px Source Serif Pro" }}
        >
          Channel Name
        </Typography>

        <IconButton sx={{ p: 0 }} disableRipple>
          <XCircleIcon w={15} />
        </IconButton>
      </Stack>

      <TabsWithState
        ariaLabel="channel details tabs"
        value={tabValue}
        onChange={(event, newValue) => setTabValue(newValue)}
      >
        {tabs.map((tab, index) => (
          <StyledTab
            key={index}
            disableRipple
            label={tab.label}
            {...a11yProps(index)}
          />
        ))}
      </TabsWithState>

      {tabs.map((tab, index) => (
        <TabPanel key={index} value={tabValue} index={index}>
          <Suspense fallback={<div>Loading...</div>}>{tab.content}</Suspense>
        </TabPanel>
      ))}
    </Stack>
  );
};

export default ChannelDetailsMenu;
