import React from "react";
import { Stack, Typography, SxProps, Avatar } from "@mui/material";
import { SelectIcon } from "../../../images";

// Define types for the component props
type BaseProps = {
  title: string;
  bgColor: string;
  sx?: SxProps; // For MUI styling
  ColoredBadgeFont?: string;
  ColoredBadgePadding?: string;
  imageUrl?: string; // Optional prop for image URL
  imageSize?: number; // Optional prop for image size
};

type IconProps = {
  showIcon: boolean; // Changed to boolean
  icon?: React.ReactNode; // Allow custom icons
  iconColor?: string;
};

type NoIconProps = {
  showIcon?: boolean; // Changed to boolean
  icon?: never;
  iconColor?: never;
};

// Combined type for ColoredBadge props
type ColoredBadgeProps = BaseProps & (IconProps | NoIconProps);

// Define the ColoredBadge component
const ColoredBadge: React.FC<ColoredBadgeProps> = ({
  title,
  bgColor,
  showIcon = false,
  icon,
  iconColor,
  ColoredBadgeFont = "normal normal 600 12px/16px Source Serif Pro",
  ColoredBadgePadding = "5px",
  sx,
  imageUrl,
  imageSize = 20, // Default size
}) => {
  // Determine which content to render in Avatar
  const content = imageUrl ? (
    <Avatar
      src={imageUrl}
      sx={{
        width: imageSize,
        height: imageSize,
        borderRadius: "50%", // Ensure the image is circular
        backgroundColor: "transparent",
      }}
    />
  ) : (
    icon || <SelectIcon color={iconColor} />
  );

  return (
    <Stack
      sx={{
        height: imageSize, // Adjust height based on imageSize
        bgcolor: bgColor,
        flexDirection: "row",
        justifyContent: "center", // Center items horizontally
        alignItems: "center",
        gap: "6px",
        p: ColoredBadgePadding,
        borderRadius: "5px",
        maxWidth: "max-content",
        ...sx,
      }}
    >
      {showIcon && content}
      <Typography sx={{ font: ColoredBadgeFont }}>{title}</Typography>
    </Stack>
  );
};

export default ColoredBadge;
