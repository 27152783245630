// globalStyles.ts
import { SxProps } from "@mui/system";

export interface IconButtonStyleOptions {
    selectedBgColor?: string;
    hoverBgColor?: string;
    selectedBorderColor?: string;
    hoverBorderColor?: string;
    borderStyle?: string;
    borderWidth?: string;
    padding?: number | string;
    selectedPadding?: number | string;
    transitionDuration?: string;
    boxShadow?: string;
    borderColor?: string; // Add borderColor to handle default border
}

export const iconButtonStyles = (
    selected: boolean,
    {
        selectedBgColor = "#74ccffb0",
        hoverBgColor = "lightorange",
        selectedBorderColor = "#47efff78",
        hoverBorderColor = "lightblue",
        borderStyle = "solid",
        borderWidth = "2px",
        padding = "5px",
        selectedPadding = "4px",
        transitionDuration = "0.3s",
        boxShadow = "none",
        borderColor = "transparent", // Default border color
    }: IconButtonStyleOptions = {}
): SxProps => ({
    padding: selected ? selectedPadding : padding,
    borderRadius: "4px",
    backgroundColor: selected ? selectedBgColor : "transparent",
    border: `${borderWidth} ${borderStyle} ${selected ? selectedBorderColor : borderColor}`, // Use borderColor when not selected
    transition: `all ${transitionDuration} ease-in-out`,
    boxShadow: boxShadow,
    "&:hover": {
        backgroundColor: hoverBgColor,
        border: `${borderWidth} ${borderStyle} ${hoverBorderColor}`,
    },
});
