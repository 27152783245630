import {
  CheckedCircleIcon,
  EmailIcon,
  NotesLineIcon,
  QuickMessageIcon,
  VideoCallIcon,
} from "../../../images";
import { CRMIcon, PlanIcon, TraxIcon } from "../../../Router/RouteIcon";

export interface QuickActionsMenuListInterface {
  icon: React.ElementType;
  name: string;
}

export const QuickActionsMenuList: QuickActionsMenuListInterface[] = [
  { icon: NotesLineIcon, name: "Notes" },
  { icon: CheckedCircleIcon, name: "Task" },
  { icon: EmailIcon, name: "Email" },
  { icon: QuickMessageIcon, name: "Message" },
  { icon: PlanIcon, name: "Event" },
  { icon: VideoCallIcon, name: "Call" },
  { icon: CRMIcon, name: "CRM" },
  { icon: TraxIcon, name: "Bill" },
];
