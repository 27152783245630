/**
 * Type definition for props passed to the ActiveProjectTab component.
 */
export type ActiveProjectTabProps = {
    /** The index of the currently active tab. */
    tabActive: number;
    /** Function to set the active tab index. */
    setTabActive: (value: number) => void;
};

/**
 * Array containing configuration objects for each tab in the ActiveProjectTab component.
 * Each object specifies the tab text, ID, and numerical identifier.
 */
export const tabText = [
    {
        /** Text displayed on the tab for 'Your Projects'. */
        tText: "Your Projects",
        /** Unique ID string for 'Your Projects' tab. */
        id: "yourProject",
        /** Numerical identifier for 'Your Projects' tab. */
        idNum: 0,
    },
    {
        /** Text displayed on the tab for 'Shared with me' projects. */
        tText: "Shared with me",
        /** Unique ID string for 'Shared with me' tab. */
        id: "sharedWithMe",
        /** Numerical identifier for 'Shared with me' tab. */
        idNum: 1,
    },
];
