import React, { useState, useEffect } from "react";
import { SketchPicker, ColorResult, RGBColor } from "react-color";
import { Popover, Tooltip, Typography } from "@mui/material";
import namer from "color-namer";

interface RGBAColor extends RGBColor {
  a: number;
}

interface SquareColorPickerProps {
  width?: string;
  height?: string;
  showColorName?: boolean;
  initialColor?: string;
  onColorChange: (color: string) => void;
}

const isValidHex = (hex: string): boolean => {
  const hexRegex = /^#([0-9A-F]{6}|[0-9A-F]{8})$/i;
  return hexRegex.test(hex);
};

const hexToRgba = (hex: string): RGBAColor => {
  let r = 0,
    g = 0,
    b = 0,
    a = 1;
  if (hex.length === 7) {
    r = parseInt(hex.slice(1, 3), 16);
    g = parseInt(hex.slice(3, 5), 16);
    b = parseInt(hex.slice(5, 7), 16);
  } else if (hex.length === 9) {
    r = parseInt(hex.slice(1, 3), 16);
    g = parseInt(hex.slice(3, 5), 16);
    b = parseInt(hex.slice(5, 7), 16);
    a = parseInt(hex.slice(7, 9), 16) / 255;
  }
  return { r, g, b, a };
};

const rgbaToHex = (r: number, g: number, b: number, a: number = 1): string => {
  const hr = r.toString(16).padStart(2, "0");
  const hg = g.toString(16).padStart(2, "0");
  const hb = b.toString(16).padStart(2, "0");
  const ha = Math.round(a * 255)
    .toString(16)
    .padStart(2, "0");
  return `#${hr}${hg}${hb}${ha}`;
};

const getContrastYIQ = (r: number, g: number, b: number): string => {
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
};

const SquareColorPicker: React.FC<SquareColorPickerProps> = ({
  initialColor = "#007BB2FF",
  onColorChange,
  height = "25px",
  width = "125px",
  showColorName = false,
}) => {
  const defaultColor = hexToRgba("#007BB2FF");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [color, setColor] = useState<RGBAColor>(defaultColor);
  const [colorName, setColorName] = useState<string>("");

  useEffect(() => {
    if (isValidHex(initialColor)) {
      const rgba = hexToRgba(initialColor);
      setColor(rgba);
      const names = namer(initialColor);
      setColorName(names.ntc[0].name);
    } else {
      setColor(defaultColor);
      setColorName("");
    }// ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [initialColor]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (color: ColorResult) => {
    const { r, g, b, a } = color.rgb;
    const newColor = { r, g, b, a: a !== undefined ? a : 1 };
    const newHex = rgbaToHex(r, g, b, newColor.a);

    setColor(newColor);
    const names = namer(newHex);
    setColorName(names.ntc[0].name);

    if (onColorChange) {
      onColorChange(newHex);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "color-picker-popover" : undefined;

  return (
    <div>
      <Tooltip placement="top" arrow title="Edit Color">
        <div
          style={{
            height: height,
            width: width,
            borderRadius: "5px",
            cursor: "pointer",
            background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
            display: "flex",
            alignItems: "center",
            paddingLeft: "10px",
            boxSizing: "border-box",
            color: getContrastYIQ(color.r, color.g, color.b),
          }}
          onClick={handleClick}
        >
          <Typography
            sx={{ font: "normal normal 300 12px/16px Source Serif Pro" }}
          >
            {showColorName && colorName}
          </Typography>
        </div>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <SketchPicker color={color} onChange={handleChange} />
      </Popover>
    </div>
  );
};

export default SquareColorPicker;
