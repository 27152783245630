import React from "react";
import { Box, Stack } from "@mui/material";
import styles from "./SwitchSmall.module.css";
import { CardViewIcon, ListViewIcon } from "../../../images";
import { SwitchSmallProps } from "../../pages";

enum DisplayMode {
  List = "square",
  Module = "stacked",
}

const SwitchSmall: React.FC<SwitchSmallProps> = ({ viewMode, setViewMode }) => {

  const viewModes = (displayType: DisplayMode): void => {
    if (viewMode !== displayType) {
      setViewMode(displayType);
      // console.log(`mode of view is ${displayType}`);
    }
  };

  return (
    <Stack direction="row" className={styles.SwitchSmallContainer}>
      <Box
        className={`${styles.SwitchSmall} ${viewMode === DisplayMode.List ? styles["SwitchSmall--selected"] : ""}`}
        component="span"
        onClick={() => viewModes(DisplayMode.List)}
      >
        <ListViewIcon />
      </Box>
      <Box
        className={`${styles.SwitchSmall} ${viewMode === DisplayMode.Module ? styles["SwitchSmall--selected"] : ""}`}
        component="span"
        onClick={() => viewModes(DisplayMode.Module)}
      >
        <CardViewIcon />
      </Box>
    </Stack>
  );
};

export default SwitchSmall;
