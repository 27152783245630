// ImportExportMenu.tsx
import React from "react";
import {
  MenuItem,
  Divider,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import {
  ImportCSVIcon,
  ExportCSVIcon,
  ExportCSVAsExcelIcon,
  HelpCSVIcon,
  DragIcon,
} from "../../../../../../images";
import {
  MenuItemData,
  handleDragStart,
  handleDrop,
  allowDrop,
} from "../menuUtils";
import g from "../DropDownButton.module.css";
export const importExportMenuItems: MenuItemData[] = [
  {
    icon: <ImportCSVIcon />,
    label: "Import CSV",
    draggable: false,
  },
  {
    divider: true,
    draggable: false,
  },
  {
    icon: <ExportCSVIcon />,
    label: "Export as CSV",
    draggable: false,
  },
  {
    icon: <ExportCSVAsExcelIcon />,
    label: "Export as Excel",
    draggable: false,
  },
  {
    divider: true,
    draggable: false,
  },
  {
    icon: <HelpCSVIcon />,
    label: "Help me import",
    draggable: false,
  },
];

interface ImportExportMenuProps {
  menuItems: MenuItemData[];
  setMenuItems: React.Dispatch<React.SetStateAction<MenuItemData[]>>;
}

export const ImportExportMenu: React.FC<ImportExportMenuProps> = ({
  menuItems,
  setMenuItems,
}) => {
  return (
    <>
      {menuItems.map((item, index) => (
        <MenuItem sx={{ width: "100%" }} key={index}>
          <div
            data-index={index}
            draggable={item.draggable}
            onDragStart={(e) => handleDragStart(e, index)}
            onDrop={(e) => handleDrop(e, menuItems, setMenuItems)}
            onDragOver={allowDrop}
            style={{
              width: "100%",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                gap={1}
              >
                {item.divider ? (
                  <Divider
                    flexItem
                    sx={{ color: "red", width: "220px", height: "2px" }}
                  />
                ) : (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    gap={1}
                  >
                    {item.icon}
                    <Typography
                      className={g.boxDropDownOption}
                      children={item.label}
                    />
                  </Stack>
                )}
              </Stack>

              <IconButton
                style={{ cursor: item.draggable ? "move" : "default" }}
              >
                {item.draggable && <DragIcon />}
              </IconButton>
            </Stack>
          </div>
        </MenuItem>
      ))}
    </>
  );
};
