import { Stack, Typography } from "@mui/material";
import React from "react";
import {
  ConnectionAdded,
  LongSearchBearishOS,
  LongSearchBearishOSListType,
  OPTIONS,
  StyledTooltip,
} from "../../../../../../common";
import { InfoIconBlue } from "../../../../../../../images";
import { CreateCardData } from "../../../MainTop/types";

interface ConnectProps {
  createCardData: CreateCardData;
  updateConnections: (value: Partial<CreateCardData["connections"]>) => void;
}

const Connect: React.FC<ConnectProps> = ({
  createCardData,
  updateConnections,
}) => {
  // Adjust handleAddConnection to match the updated prop type
  const handleAddConnection = (
    newConnections: LongSearchBearishOSListType[]
  ) => {
    if (newConnections.length === 0) return;

    const updatedConnectionList = [
      ...(createCardData.connections.connectionList || []),
      ...newConnections.map((connection) => ({
        id: connection.id,
        text: connection.text,
        value: connection.text,
      })),
    ];

    console.log(updatedConnectionList);

    // updateConnections({ connectionList: updatedConnectionList });
  };

  return (
    <Stack sx={{ boxSizing: "border-box", paddingTop: "30px" }}>
      <Stack
        direction={"row"}
        gap={"5px"}
        alignItems={"end"}
        height={"15px"}
        mb={"5px"}
      >
        <Typography
          sx={{ font: "normal normal normal 10px/13px Source Serif Pro" }}
        >
          Add Connections
        </Typography>
        <StyledTooltip title="Add Connection">
          <InfoIconBlue />
        </StyledTooltip>
      </Stack>
      <LongSearchBearishOS
        options={OPTIONS}
        buttonLabel="Add"
        inputPlaceHolder="Search Bearish OS"
        width="322px"
        onSelectOption={handleAddConnection} // Pass the handler function
      />
      <Stack mt={"50px"} sx={{ height: "210px" }}>
        <Typography
          sx={{
            font: "normal normal normal 10px/13px Source Serif Pro",
            marginBottom: "10px",
          }}
        >
          Added Connections
        </Typography>

        <Stack
          sx={{
            height: "170px",
            overflowY: "auto",
            "::-webkit-scrollbar": {
              width: 0,
              height: 0,
            },
            scrollbarWidth: "none",
          }}
        >
          {createCardData.connections.connectionList.length > 0 ? (
            createCardData.connections.connectionList.map((connection) => (
              // <Typography key={connection.id}>{connection.text}</Typography>
              <ConnectionAdded
                key={connection.id}
                connection={connection || {}}
              />
            ))
          ) : (
            <Typography
              sx={{
                font: "normal normal 300 12px/16px Source Serif Pro",
                textAlign: "center",
                opacity: 0.5,
              }}
            >
              Your added Connections will be listed here once you choose them…
            </Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Connect;
