import React, { useState } from "react";
import g from "./ForgotPassword.module.css";
import { useTranslation } from "react-i18next";
import { BearishOSIcon, Loginbg2x } from "../../../images";
import {
  ButtonType,
  ButtonXLarge,
  ButtonXLargeColor,
  LargeInputBox,
} from "../../common";
import { Typography } from "@mui/material";
const containerStyle = {
  backgroundImage: `url(${Loginbg2x})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  // Add other styles as needed
};
const ForgotPassword = () => {
  const { t } = useTranslation();

  const [emailAddress, setEmailAddress] = useState("");

  return (
    <div className={`${g.container}`} style={containerStyle}>
      <div className={`${g.container_two}`}>
        <BearishOSIcon w={75} />
        <Typography
          children={t("fp")}
          variant="h1"
          sx={{
            font: "normal normal 600 40px/54px Source Serif Pro",
            margin: "30px 0 0 0",
          }}
        />
        <form autoComplete="off" className={g.form} onSubmit={() => {}}>
          <LargeInputBox
            label={t("ea")}
            value={emailAddress}
            autoComplete="off"
            onChange={(e) => {
              const value = typeof e === "string" ? e : e.target.value;
              setEmailAddress(value);
            }}
          />
          <ButtonXLarge
            colorVarient={ButtonXLargeColor.Orange}
            buttonSize="default"
            label={t("sendpasslink")}
            fullwidth={true}
            types={ButtonType.Submit}
            loaderLogin={false}
          />
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
