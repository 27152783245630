import { PayloadAction } from "@reduxjs/toolkit";
import {
    AuthState,
    ExternalAuthState,
    OptSendOrVerifyResult,
    User,
    globalApiResult,
    TempAuthenticationResult,
    fetchCompanyList,
    usersPayload,
} from "../authTypes";

export const loginSuccess = (state: AuthState, action: PayloadAction<User>) => {
    state.isAuthenticated = true;
    state.user = action.payload;
    state.error = null;
};

export const optSendSuccess = (
    state: AuthState,
    action: PayloadAction<OptSendOrVerifyResult>
) => {
    state.isOptSend = true;
    state.error = null;
    state.data = action?.payload?.data || null;
};

export const tempAuthStoreSuccess = (
    state: AuthState,
    action: PayloadAction<TempAuthenticationResult>
) => {
    state.email_temp = action.payload.email_temp || "";
    state.password_temp = action.payload.password_temp || "";
    state.timestamp_store_at = action.payload.timestamp_store_at || "";
};

export const companyAvailibitySuccess = (
    state: AuthState,
    action: PayloadAction<any>
) => {
    state.error = null;
    state.companyAvailibility = action?.payload;
};

export const fetchCompanyListSuccess = (
    state: AuthState,
    action: PayloadAction<fetchCompanyList>
) => {
    state.error = null;
    state.companyList = action.payload;
};

export const getAllDepartmentsSuccess = (
    state: AuthState,
    action: PayloadAction<globalApiResult>
) => {
    state.error = null;
    state.departmentList = action?.payload?.data || [];
};

export const optVerificationSuccess = (
    state: AuthState,
    action: PayloadAction<OptSendOrVerifyResult>
) => {
    state.isOptVerify = true;
    state.error = null;
    state.data = action?.payload?.data || null;
};

export const resetOptSend = (state: AuthState) => {
    state.isOptSend = null;
};
export const resetOptVerify = (state: AuthState) => {
    state.isOptVerify = null;
};

export const signUpSuccess = (
    state: AuthState,
    action: PayloadAction<User>
) => {
    state.isSignUp = true;
    state.user = action.payload;
    state.error = null;
};

export const googleAuthSuccess = (
    state: AuthState,
    action: PayloadAction<ExternalAuthState>
) => {
    state.externalEntities = { type: "GoogleAuth" };
    state.message = action.payload.message;
    state.eData = action.payload.data;
    state.eSuccess = action.payload.success;
    state.eError = null; // Extract error if needed
};

export const microsoftAuthSuccess = (
    state: AuthState,
    action: PayloadAction<ExternalAuthState>
) => {
    state.externalEntities = { type: "MicrosoftAuth" };
    state.message = action.payload.message;
    state.eData = action.payload.data;
    state.eSuccess = action.payload.success;
    state.eError = null; // Extract error if needed
};

export const forgotPasswordSuccess = (state: AuthState) => {
    state.error = null;
};

export const getAllUsersSuccess = (
    state: AuthState,
    action: PayloadAction<usersPayload>
) => {
    state.error = null;
    state.usersList = action?.payload?.data || [];
};
