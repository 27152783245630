// DefaultMenu.tsx
import React from "react";
import {
  MenuItem,
  Stack,
  Typography,
  InputBase,
  IconButton,
} from "@mui/material";
import { StoreIcon, PlusIcon, DragIcon } from "../../../../../../images";
import {
  handleDragStart,
  handleDrop,
  allowDrop,
  handleKeyPress,
  MenuItemData,
} from "../menuUtils";
import g from "../DropDownButton.module.css";
export const defaultMenuItems: MenuItemData[] = [
  {
    icon: <StoreIcon />,
    label: "All Companies",
    draggable: true,
  },
  {
    icon: <StoreIcon />,
    label: "Created by",
    draggable: true,
  },
  {
    icon: <PlusIcon />,
    label: "Create a new type",
    draggable: false,
    inputType: true,
  },
];

interface DefaultMenuProps {
  menuItems: MenuItemData[];
  setMenuItems: React.Dispatch<React.SetStateAction<MenuItemData[]>>;
}

export const DefaultMenu: React.FC<DefaultMenuProps> = ({
  menuItems,
  setMenuItems,
}) => {
  return (
    <>
      {menuItems.map((item, index) => (
        <MenuItem sx={{ width: "100%" }} key={index}>
          <div
            data-index={index}
            draggable={item.draggable}
            onDragStart={(e) => handleDragStart(e, index)}
            onDrop={(e) => handleDrop(e, menuItems, setMenuItems)}
            onDragOver={allowDrop}
            style={{
              width: "100%",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                gap={1}    width={'100%'}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={1}
                  width={'100%'}
                >
                  {item.icon ? item.icon : <StoreIcon/>}
                  {!item.inputType ? (
                    <Typography
                      className={g.boxDropDownOption}
                      children={item.label}
                    />
                  ) : (
                    <InputBase
                      type="text"
                      placeholder={item.label}
                      onKeyPress={(e: any) =>
                        handleKeyPress(e, e.target.value, setMenuItems)
                      }
                    />
                  )}
                </Stack>
              </Stack>

              <IconButton
                style={{ cursor: item.draggable ? "move" : "default" }}
              >
                {item.draggable && <DragIcon />}
              </IconButton>
            </Stack>
          </div>
        </MenuItem>
      ))}
    </>
  );
};
