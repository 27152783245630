import { IconButton, Link, Stack, Tooltip } from "@mui/material";
import React from "react";
import { getFileIcon } from "../../../Utils";
import { XCircleIcon } from "../../../images";

// Define the prop types with conditional requirements
interface BaseProps {
  fileName: string;
  onFileClick?: () => void;
}

interface WithCloseButtonProps extends BaseProps {
  showDeleteButton: true;
  onDeleteButtonClick: () => void;
}

interface WithoutCloseButtonProps extends BaseProps {
  showDeleteButton: false;
  onDeleteButtonClick?: never;
}

export type AttachmentWithIconSmallProps =
  | WithCloseButtonProps
  | WithoutCloseButtonProps;

const AttachmentWithIconSmall: React.FC<AttachmentWithIconSmallProps> = ({
  fileName,
  onFileClick,
  onDeleteButtonClick,
  showDeleteButton = false,
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{
        height: "30px",
        borderRadius: "25px",
        border: "0.5px solid #E9EDF2",
        boxSizing: "border-box",
        padding: "0px 10px",
      }}
    >
      {getFileIcon({
        name: fileName,
        iconGroup: "group2",
        iconSize: 20,
      })}
      <Link
        onClick={onFileClick}
        sx={{
          font: "normal normal 400 14px/18px Source Serif Pro",
          color: "#000",
          cursor: onFileClick ? "pointer" : "default",
        }}
        underline="hover"
      >
        {fileName}
      </Link>
      {showDeleteButton && onDeleteButtonClick && (
        <Tooltip title="Remove" placement="top" arrow>
          <IconButton
            sx={{ p: "1px" }}
            disableRipple
            onClick={onDeleteButtonClick}
          >
            <XCircleIcon w={12} />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};

export default AttachmentWithIconSmall;
