import React from "react";
import { Stack, LinearProgress, LinearProgressProps } from "@mui/material";
import styles from "./BorderLinearProgress.module.css";

interface BorderLinearProgressProps extends LinearProgressProps {
  progressPercent: number;
  highlightColor?: string;
  backgroundColor?: string;
}

// use like this, use with width otherwise it not render properly
// <Stack sx={{ flex: 1 "OR" width:"100px" }}> like this
//   <BorderLinearProgress progressPercent={25} backgroundColor="#d1e6dc" highlightColor="#0b452a" />
// </Stack>

const BorderLinearProgress: React.FC<BorderLinearProgressProps> = ({
  progressPercent,
  highlightColor = "#008d4c", // Default highlight color
  backgroundColor = "#e9edf2", // Default background color
  sx,
}) => {
  return (
    <Stack sx={{ flex: 1, height: "5px", ...sx }}>
      <LinearProgress
        variant="determinate"
        value={progressPercent}
        sx={{
          backgroundColor: backgroundColor,
          "& .MuiLinearProgress-bar": {
            backgroundColor: highlightColor,
          },
        }}
        classes={{
          root: styles.linearProgress,
          colorPrimary: styles.colorPrimary,
          bar: styles.bar,
        }}
      />
    </Stack>
  );
};

export default BorderLinearProgress;
