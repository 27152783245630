import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { ColoredBadge } from "../../../ColoredBadge";
import { adjustHexColor } from "../../../../../Utils";
import { AddCalPlusIcon } from "../../../../../images";
import styles from "./ItemInfoView.module.css";

interface ItemInfoViewProps {
  ownerName: string;
  uploader: string;
  createdTimestamp: string;
  lastModifyTimestamp: string;
  size: string;
}

const ItemInfoView: React.FC<ItemInfoViewProps> = ({
  createdTimestamp,
  lastModifyTimestamp,
  ownerName,
  size,
  uploader,
}) => {
  return (
    <Stack className={styles["item-info-view"]}>
      {/* ----- tags ---- */}
      <Stack className={styles["item-info-view__section"]}>
        <Typography className={styles["item-info-view__header"]}>
          Tags
        </Typography>

        <Stack className={styles["item-info-view__tags-container"]}>
          <ColoredBadge
            ColoredBadgeFont="normal normal 400 14px/18px Source Serif Pro"
            bgColor={adjustHexColor("#A1FF9F", 75)}
            title="Business Doc"
            sx={{ px: "10px" }}
          />

          <IconButton
            disableRipple
            className={styles["item-info-view__add-tags-button"]}
          >
            <AddCalPlusIcon w={15} />
            <Typography className={styles["item-info-view__add-tags-text"]}>
              Add tags
            </Typography>
          </IconButton>
        </Stack>
      </Stack>
      {/* ----- tags container end here ---- */}

      {/* ----- Owner ---- */}
      <Stack className={styles["item-info-view__section"]}>
        <Typography className={styles["item-info-view__header"]}>
          Owner
        </Typography>
        <Typography className={styles["item-info-view__text"]}>
          {ownerName}
        </Typography>
      </Stack>
      {/* ----- Owner container end here ---- */}

      {/* ----- Uploader ---- */}
      <Stack className={styles["item-info-view__section"]}>
        <Typography className={styles["item-info-view__header"]}>
          Uploader
        </Typography>
        <Typography className={styles["item-info-view__text"]}>
          {uploader}
        </Typography>
      </Stack>
      {/* ----- Uploader container end here ---- */}

      {/* ----- Created ---- */}
      <Stack className={styles["item-info-view__section"]}>
        <Typography className={styles["item-info-view__header"]}>
          Created
        </Typography>
        <Typography className={styles["item-info-view__text"]}>
          {createdTimestamp}
        </Typography>
      </Stack>
      {/* ----- Created container end here ---- */}

      {/* ----- Last modified ---- */}
      <Stack className={styles["item-info-view__section"]}>
        <Typography className={styles["item-info-view__header"]}>
          Last modified
        </Typography>
        <Typography className={styles["item-info-view__text"]}>
          {lastModifyTimestamp}
        </Typography>
      </Stack>
      {/* ----- Last modified container end here ---- */}

      {/* ----- Size ---- */}
      <Stack className={styles["item-info-view__section"]}>
        <Typography className={styles["item-info-view__header"]}>
          Size
        </Typography>
        <Typography className={styles["item-info-view__text"]}>
          {size}
        </Typography>
      </Stack>
      {/* ----- size container end here ---- */}
    </Stack>
  );
};

export default ItemInfoView;
