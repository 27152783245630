import { Stack } from "@mui/material";
import React from "react";
import { CustomCheckboxDropdownMultiple } from "../../../../../../../../common/CustomCheckboxDropdownMultiple";

interface ColorPickerViewProps {
  options: { value: string; color: string }[];
}

const ColorPickerView: React.FC<ColorPickerViewProps> = ({ options }) => {
  return (
    <Stack>
      <CustomCheckboxDropdownMultiple
        badgeWithoutText
        placeHolder="placeholder..."
        options={options}
        onChange={(value) => console.log(value)}
      />
    </Stack>
  );
};

export default ColorPickerView;
