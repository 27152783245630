import { Stack, Typography } from "@mui/material";
import React from "react";

type LocationData = {
  state: string;
  city: string;
  zipCode?: string;
  country?: string;
};

type PrimaryLocationTableProps = {
  TableData: LocationData;
};

const PrimaryLocationTable: React.FC<PrimaryLocationTableProps> = ({
  TableData,
}) => {
  const { state, city, zipCode, country } = TableData;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      gap={0.5}
      flexWrap="wrap"
    >
      <Typography sx={{ font: "normal normal 300 12px/16px Source Serif Pro" }}>
        {state}
      </Typography>
      <Typography sx={{ font: "normal normal 300 12px/16px Source Serif Pro" }}>
        {city}
      </Typography>
      {country && (
        <Typography
          sx={{ font: "normal normal 300 12px/16px Source Serif Pro" }}
        >
          {country}
        </Typography>
      )}
      {zipCode && (
        <Typography
          sx={{ font: "normal normal 300 12px/16px Source Serif Pro" }}
        >
          - {zipCode}
        </Typography>
      )}
    </Stack>
  );
};

export default PrimaryLocationTable;
