import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { IconDropdownUser } from "../IconDropdownUser";
import { IconDropdownUserDummyData } from "../IconDropdownUser/IconDropdownUserDataTypes";
import { CheckboxMedium } from "../CheckboxMedium";
import { adjustHexColor } from "../../../Utils";
import styles from "./CloudStorageItemList.module.css";
import { CloudStorageFileIcon } from "../CloudStorageFileIcon";
import { CloudStorageFileCompanyTypeEnum } from "../CloudStorageFileIcon/CloudStorageFileType";
import { SmallThreeDotMenu } from "../SmallThreeDotMenu";
import { CloudStorageItemThreeDotOptions } from "./CloudStorageItemListType";

interface CloudStorageItemListProps {
  plateform: CloudStorageFileCompanyTypeEnum;
  fileName: string;
  timeStamp: string;
  opendBy: string;
  fileSize: string;

  isSelected: boolean;
  onContainerClick: () => void;
  checkBoxClick?: () => void;
  collapsedView?: boolean;
}

const CloudStorageItemList: React.FC<CloudStorageItemListProps> = ({
  fileName,
  opendBy,
  plateform,
  fileSize,
  timeStamp,
  isSelected,
  onContainerClick,
  collapsedView = false,
  checkBoxClick,
}) => {
  const [showDrag, setShowDrag] = useState<boolean>(false);

  const stopPropagation = (event: React.MouseEvent | React.KeyboardEvent) => {
    event.stopPropagation();
  };

  return (
    // [------- outer container--------]
    <Stack
      onMouseEnter={() => setShowDrag(true)}
      onMouseLeave={() => setShowDrag(false)}
      onClick={() => onContainerClick()}
      className={styles["cloud-storage-item-list"]}
    >
      {/* <><><> inner container OR main container ( height is 55-5-5 = 45px, width is 100%) <><><>*/}
      <Stack
        className={styles["cloud-storage-item-list__main-container"]}
        sx={{ bgcolor: isSelected ? adjustHexColor("#008D4C", 25) : "inherit" }}
      >
        {/* --------- file icon and file name here --------- */}
        <Stack className={styles["cloud-storage-item-list__file-info"]}>
          <CloudStorageFileIcon
            fileName={fileName || "file.pdf"}
            plateform={plateform}
          />
          <Typography className={styles["cloud-storage-item-list__file-name"]}>
            {fileName}
          </Typography>
        </Stack>
        {/* ------------------------------- */}

        {/*  ----------  Author user info here ---------- */}
        {/* +++ add props for this component +++ */}
        <Stack className={styles["cloud-storage-item-list__author-info"]}>
          <span onMouseDown={stopPropagation} onClick={stopPropagation}>
            <IconDropdownUser
              componentType="showUser"
              options={IconDropdownUserDummyData}
              onSelect={(value) => null}
              showSelectedUser={IconDropdownUserDummyData[2]}
            />
          </span>
          <Typography
            className={styles["cloud-storage-item-list__author-name"]}
          >
            {IconDropdownUserDummyData[2].name}
          </Typography>
        </Stack>
        {/* ------------------------------- */}

        {/* -------------  Timestamp and file opend by user info  --------- */}
        {!collapsedView && (
          <Stack className={styles["cloud-storage-item-list__timestamp-info"]}>
            <Typography
              className={styles["cloud-storage-item-list__timestamp-text"]}
            >
              {timeStamp} by
            </Typography>
            <Typography className={styles["cloud-storage-item-list__author"]}>
              {opendBy}
            </Typography>
          </Stack>
        )}
        {/* ------------------------------- */}

        {/* --------- Storage info here ------------ */}
        {!collapsedView && (
          <Stack className={styles["cloud-storage-item-list__storage-info"]}>
            <Typography
              className={styles["cloud-storage-item-list__storage-size"]}
            >
              {fileSize}
            </Typography>
          </Stack>
        )}
        {/* ------------------------------- */}

        {/* --------------------  show when hover component here ------------------- */}
        <Stack className={styles["cloud-storage-item-list__hover-controls"]}>
          <Stack
            className={styles["cloud-storage-item-list__hover-controls-inner"]}
            sx={{ display: showDrag || isSelected ? "flex" : "none" }}
          >
            <span onMouseDown={stopPropagation} onClick={stopPropagation}>
              <SmallThreeDotMenu
                menuItems={CloudStorageItemThreeDotOptions}
                onClickAction={(value) => console.log(value)}
              />
            </span>

            <CheckboxMedium
              onChange={() => checkBoxClick && checkBoxClick()}
              boxSize={15}
              checked={isSelected}
            />
          </Stack>
        </Stack>
        {/* ------------------------------- */}
      </Stack>
    </Stack>
  );
};

export default CloudStorageItemList;
