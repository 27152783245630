import { Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DividerOrDivider } from "../Divider";

type SignUpOptions = {
  index: number;
};

const SignupOption: React.FC<SignUpOptions> = ({ index }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <Typography
        sx={{
          font: "normal normal 300 13px/17px Source Serif Pro",
          marginTop: "20px"
        }}
      >
        {index === 1 ? t("dhana") : t("ahna")}{" "}
        <span
          onClick={() => navigate(index === 2 ? "/login" : "/signup")}
          style={{ color: "#000AFF", cursor: "pointer" }}
        >
          {index === 1 ? t("su") : t("Login")}
        </span>
      </Typography>

      <Stack width={"100%"} mt={"20px"}>
        <DividerOrDivider />
      </Stack>
    </>
  );
};

export default SignupOption;
