export * from "./Slice";
export * from "./Slice/auth/authTypes";
export * from "./Slice/Layout/layoutTypes";
export * from "./Slice/settings/settingsTypes";
export * from "./Slice/workspace/workspaceTypes";

// Helper function to get a value from localStorage
export const getLocalStorageValue = (key: string, defaultValue: boolean) => {
    const value = localStorage.getItem(key);
  return value !== null ? JSON.parse(value) : defaultValue;
};

// Helper function to set a value in localStorage
export const setLocalStorageValue = (key: string, value: boolean) => {
    localStorage.setItem(key, JSON.stringify(value));
};