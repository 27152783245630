import React from "react";
import { Link, Stack, Typography } from "@mui/material";
import { adjustHexColor } from "../../../../../Utils";
import styles from "./ItemInsightsView.module.css";

interface ItemInsightsViewProps {
  shareCount: number;
  viewCount: number;
  linkCount: number;
}

const ItemInsightsView: React.FC<ItemInsightsViewProps> = ({
  linkCount,
  shareCount,
  viewCount,
}) => {
  return (
    <Stack className={styles.itemInsightsView}>
      <Stack flexDirection="row">
        <Typography className={styles.itemInsightsView__header}>
          Item Insights
        </Typography>
      </Stack>

      <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
        {/* --  1  -- */}
        <Stack
          className={styles.itemInsightsView__item}
          sx={{ bgcolor: adjustHexColor("#A1FF9F", 25) }}
        >
          <Typography className={styles.itemInsightsView__itemTitle}>
            {shareCount}
          </Typography>
          <Typography className={styles.itemInsightsView__itemDescription}>
            Total shares
          </Typography>
        </Stack>

        {/* --  2  -- */}
        <Stack
          className={styles.itemInsightsView__item}
          sx={{ bgcolor: adjustHexColor("#FFDCB2", 25) }}
        >
          <Typography className={styles.itemInsightsView__itemTitle}>
            {viewCount}
          </Typography>
          <Typography className={styles.itemInsightsView__itemDescription}>
            Total views
          </Typography>
        </Stack>

        {/* --  3  -- */}
        <Stack
          className={styles.itemInsightsView__item}
          sx={{ bgcolor: adjustHexColor("#CFA0FF", 25) }}
        >
          <Typography className={styles.itemInsightsView__itemTitle}>
            {linkCount}
          </Typography>
          <Typography className={styles.itemInsightsView__itemDescription}>
            Total links
          </Typography>
        </Stack>

        {/* ----------- */}
      </Stack>

      <Stack flexDirection="row" justifyContent="end">
        <Link
          className={styles.itemInsightsView__viewMoreLink}
          underline="hover"
        >
          <Typography className={styles.itemInsightsView__viewMoreText}>
            View more
          </Typography>
        </Link>
      </Stack>
    </Stack>
  );
};

export default ItemInsightsView;
