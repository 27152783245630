import { Box, Fade } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { People } from "./People";
import { CrmComponentMap, CrmPaths } from "./CRMTypes";

const Crm: React.FC = () => {
  const location = useLocation();
  const [checked, setChecked] = useState(true);
  const [currentPath, setCurrentPath] = useState<CrmPaths>(
    location.pathname as CrmPaths
  );

  const Component =
    CrmComponentMap[currentPath as keyof typeof CrmComponentMap] || People;

  useEffect(() => {
    setChecked(false);
  }, [location.pathname]);

  useEffect(() => {
    if (!checked) {
      const timer = setTimeout(() => {
        setCurrentPath(location.pathname as CrmPaths);
        setChecked(true);
      }, 200); // Duration of the fade out transition
      return () => clearTimeout(timer);
    }
  }, [checked, location.pathname]);

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Fade in={checked} timeout={{ enter: 200, exit: 200 }}>
        <Box sx={{ width: "100%" }}>
          <Component />
        </Box>
      </Fade>
    </Box>
  );
};

export default Crm;
