import React, { useState } from "react";
import { InputBase, Stack, Popover, Typography } from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

interface CountdownViewProps {
  initialDate?: Dayjs | null;
  onChange: (date: Dayjs | null) => void;
}

const CountdownView: React.FC<CountdownViewProps> = ({
  initialDate,
  onChange,
}) => {
  const [anchorElDate, setAnchorElDate] = useState<null | HTMLElement>(null);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    initialDate || dayjs()
  );

  const handleClickDate = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElDate(event.currentTarget);
  };

  const handleCloseDate = () => {
    setAnchorElDate(null);
  };

  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
    onChange(date);
    handleCloseDate();
  };

  const openDate = Boolean(anchorElDate);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack sx={{ flexDirection: "row", gap: "10px" }}>
        <Typography
          sx={{ font: "normal normal 300 14px/20px Source Serif Pro" }}
        >
          Start date
        </Typography>
        <InputBase
          onClick={handleClickDate}
          sx={{
            padding: "0px",
            height: "17px",
            width: "100px",
            font: "normal normal 300 13px/17px Source Serif Pro",
            textAlign: "center",
            cursor: "pointer",
          }}
          inputProps={{
            style: {
              padding: "0px",
            },
            readOnly: true,
          }}
          value={selectedDate ? selectedDate.format("MM-DD-YYYY") : ""}
        />
        <Popover
          open={openDate}
          anchorEl={anchorElDate}
          onClose={handleCloseDate}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <DateCalendar value={selectedDate} onChange={handleDateChange} />
        </Popover>
      </Stack>
    </LocalizationProvider>
  );
};

export default CountdownView;
