import { People as PeoplePage } from "./People";
import { Company as CompanyPage } from "./Company";
import { Deal as DealPage } from "./Deal";

export type CrmPaths = "/CRM/people" | "/CRM/company" | "/CRM/deal";

export const CrmComponentMap: Record<CrmPaths, React.ComponentType> =
{
    "/CRM/people": PeoplePage,
    "/CRM/company": CompanyPage,
    "/CRM/deal": DealPage,
};
