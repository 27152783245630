import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Snackbar, { SnackbarProps } from "@mui/material/Snackbar";
import { Alert } from "@mui/material";
import { closeSnackbar } from "../../../redux/Slice/snackbarSlice/snackbarSlice";
import { RootState } from "../../../redux/store";

/** Defines the severity levels for the Snackbar component. */
type SnackbarSeverity = "error" | "warning" | "info" | "success";

/** Defines the shape of the Snackbar state. */
interface SnackbarState {
  open: boolean;
  message: string;
  severity: SnackbarSeverity;
}

/** SnackbarComponent displays snackbars to provide feedback to users. */
const SnackbarComponent: React.FC = () => {
  // Selects Snackbar state from Redux store
  const { open, message, severity } = useSelector(
    (state: RootState) => state.snackbar as SnackbarState
  );
  const dispatch = useDispatch();

  // Handles the close event of the Snackbar
  const handleClose = (): void => {
    dispatch(closeSnackbar());
  };

  // Props for the Snackbar component
  const snackbarProps: SnackbarProps = {
    open,
    autoHideDuration: 6000,
    onClose: handleClose,
  };

  return (
    <Snackbar {...snackbarProps}>
      <Alert
        onClose={handleClose}
        severity={severity}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
