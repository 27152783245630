import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../../../../redux/store";
import { addWikiPage__api, getOneWiki__api } from "../../../../../../redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  TextField,
  Stack,
} from "@mui/material";
import { PageWiki } from "./PageWiki";

const Wiki = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const workspaceState = useSelector(
    (state: RootState) => state.workspace || {}
  );
  const { insideWorkspaceComponentData } = workspaceState || {};
  const { workspaceId, componentId, subcomponentId } = useParams();

  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");

  const handleGetOneWiki = async () => {
    if (!workspaceId || !componentId) return;
    if (subcomponentId) {
      return;
    }
    const action = getOneWiki__api(workspaceId, componentId);
    await dispatch(action);
  };

  const handleAddPage = async () => {
    if (!workspaceId || !componentId) return;
    const payload = {
      title,
      content: { text: content },
      parentPageId: componentId, // document ID or parent ID
    };
    // Call API to add the page
    await dispatch(addWikiPage__api(componentId, payload, workspaceId));
    await handleGetOneWiki();
    // Reset form fields after submission
    setTitle("");
    setContent("");
  };

  useEffect(() => {
    handleGetOneWiki(); // Call API if data isn't available in Redux
  }, [componentId, workspaceId]);

  const handleNavigate = (
    id: string,
    componentType: string,
    componentId: string,
    subcomponentId: string,
    event: any
  ) => {
    event.preventDefault();
    event.stopPropagation();
    navigate(
      `/workspace/private/${id}/${componentType}/${componentId}/${subcomponentId}`
    );
  };
  // Render subcomponent page if present
  if (subcomponentId) {
    return <PageWiki />;
  }
  return (
    <Box p={2}>
      {/* Add Wiki Page Form */}
      <Card sx={{ mb: 2, padding: 2 }}>
        <Typography variant="h6" gutterBottom>
          Add New Wiki Page
        </Typography>
        <Stack spacing={2}>
          <TextField
            label="Page Title"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
          />
          <TextField
            label="Page Content"
            variant="outlined"
            multiline
            rows={4}
            value={content}
            onChange={(e) => setContent(e.target.value)}
            fullWidth
          />
          <Button variant="contained" color="primary" onClick={handleAddPage}>
            Add Page
          </Button>
        </Stack>
      </Card>

      {/* Render Wiki Pages */}
      <Typography variant="h5" gutterBottom>
        Wiki Pages
      </Typography>
      {insideWorkspaceComponentData?.pages?.length > 0 ? (
        insideWorkspaceComponentData.pages.map((page: any, index: number) => (
          <Card
            onClick={(e: any) => {
              componentId &&
                workspaceId &&
                handleNavigate(workspaceId, "Wiki", componentId, page, e);
            }}
            key={page._id}
            sx={{ mb: 2 }}
          >
            <CardContent>
              <Typography variant="h6">
                {page?.name || `No Name In Api-${index + 1}`}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {page?.description || "No description available"}
              </Typography>
            </CardContent>
          </Card>
        ))
      ) : (
        <Typography variant="body1">No pages available.</Typography>
      )}
    </Box>
  );
};

export default Wiki;
