import { BulletpointsIcon, NumberedIcon } from "../../../images";

export enum BulletPointDropdownValueEnum {
  BulletPoint1 = "BulletPoint",
  Numbered = "Numbered",
}

export interface BulletPointDropdownInterface {
  value: BulletPointDropdownValueEnum;
  icon: React.ElementType;
}

export const BulletPointDropdownOptions: BulletPointDropdownInterface[] = [
  {
    value: BulletPointDropdownValueEnum.BulletPoint1,
    icon: BulletpointsIcon,
  },
  { value: BulletPointDropdownValueEnum.Numbered, icon: NumberedIcon },
];
