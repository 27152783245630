import React, { useState, lazy, Suspense } from "react";
import { Tabs, Tab, Box, Stack } from "@mui/material";
import { styled } from "@mui/system";
import { CommentsIcon, DetailsIcon } from "../../../../../images";

const CommentsTab = lazy(() => import("./Tabs/Comments/Comments"));
const DetailsTab = lazy(() => import("./Tabs/Details/Details"));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

// inline component
const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        overflowY: "scroll",
        scrollbarWidth: "none",
      }}
    >
      {value === index && children}
    </div>
  );
};

const StyledTabs = styled(Tabs)({
  padding: "0px 15px",
  boxSizing: "border-box",
  minHeight: 0,
  height: "45px",
  flexShrink: 0,
  borderBottom: "1px solid #E9EDF2",
  "& .MuiTabs-flexContainer": {
    // justifyContent: "space-between",
    gap: "10px",
  },
});

const StyledTab = styled(Tab)({
  flexDirection: "row",
  font: "normal normal 300 15px/21px Source Serif Pro",
  height: "45px",
  minHeight: 0,
  boxSizing: "border-box",
  color: "black !important",
  gap: "5px",
  padding: 0,
  textTransform: "capitalize",
});

const tabs = [
  {
    label: "Details",
    icon: <DetailsIcon w={20} />,
    content: <DetailsTab />,
  },
  {
    label: "Comments",
    icon: <CommentsIcon w={20} />,
    content: <CommentsTab />,
  },
];

const CrmRightSideTabs: React.FC = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      const newIndex = tabs.findIndex(
        (tab) => tab.label === event.currentTarget.getAttribute("aria-label")
      );
      if (newIndex !== -1) setValue(newIndex);
    }
  };

  const a11yProps = (index: number) => ({
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  });

  return (
    <Stack
      sx={{
        border: "1px solid #E9EDF2",
        display: "flex",
        flexDirection: "column",
        width: "380px",
        flexShrink: 0,
        gap: "20px",
      }}
    >
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="timeline tabs"
        TabIndicatorProps={{
          style: { backgroundColor: "#E2875E", bottom: "1px" },
        }}
      >
        {tabs.map((tab, index) => (
          <StyledTab
            key={index}
            disableRipple
            icon={tab.icon}
            label={tab.label}
            {...a11yProps(index)}
            onKeyDown={handleKeyDown}
          />
        ))}
      </StyledTabs>
      {tabs.map((tab, index) => (
        <TabPanel key={index} value={value} index={index}>
          <Suspense fallback={<div>Loading...</div>}>{tab.content}</Suspense>
        </TabPanel>
      ))}
    </Stack>
  );
};

export default CrmRightSideTabs;
