import React from "react";

function GoogleIcon({ w = 25 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w}
      height={w}
      viewBox="0 0 48 48"
    >
      <path
        fill="#fbc02d"
        d="M43.611 20.083H42V20H24v8h11.303c-1.649 4.657-6.08 8-11.303 8-6.627 0-12-5.373-12-12s5.373-12 12-12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 12.955 4 4 12.955 4 24s8.955 20 20 20 20-8.955 20-20c0-1.341-.138-2.65-.389-3.917z"
      ></path>
      <path
        fill="#e53935"
        d="M6.306 14.691l6.571 4.819C14.655 15.108 18.961 12 24 12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 16.318 4 9.656 8.337 6.306 14.691z"
      ></path>
      <path
        fill="#4caf50"
        d="M24 44c5.166 0 9.86-1.977 13.409-5.192l-6.19-5.238A11.91 11.91 0 0124 36c-5.202 0-9.619-3.317-11.283-7.946l-6.522 5.025C9.505 39.556 16.227 44 24 44z"
      ></path>
      <path
        fill="#1565c0"
        d="M43.611 20.083L43.595 20H24v8h11.303a12.04 12.04 0 01-4.087 5.571l.003-.002 6.19 5.238C36.971 39.205 44 34 44 24c0-1.341-.138-2.65-.389-3.917z"
      ></path>
    </svg>
  );
}

function MicorsoftIcon({ w = 25 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w}
      height={w}
      viewBox="0 0 48 48"
    >
      <path fill="#ff5722" d="M22 22H6V6h16z"></path>
      <path fill="#4caf50" d="M42 22H26V6h16z"></path>
      <path fill="#ffc107" d="M42 42H26V26h16z"></path>
      <path fill="#03a9f4" d="M22 42H6V26h16z"></path>
    </svg>
  );
}

function SSOIcon({ w = 25 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w}
      height={w}
      viewBox="0 0 50 50"
    >
      <path d="M29 8.531c-5.215 0-9.629 3.121-11.75 7.531-1.129-.777-2.438-1.343-3.906-1.343-3.727 0-6.7 2.949-6.906 6.625C2.71 22.689 0 26.164 0 30.344 0 35.672 4.328 40 9.656 40h31.438C46 40 50 36 50 31.094c0-4.551-3.492-8.168-7.906-8.688.016-.242.062-.468.062-.718C42.156 14.438 36.25 8.53 29 8.53zm0 2a11.145 11.145 0 0111.156 11.156c0 .457-.035.906-.093 1.375l-.157 1.125h1.188A6.894 6.894 0 0148 31.095 6.894 6.894 0 0141.094 38H9.656A7.631 7.631 0 012 30.344a7.682 7.682 0 015.625-7.407l.75-.187-.031-.813c-.004-.14 0-.21 0-.218 0-2.777 2.223-5 5-5 1.39 0 2.656.558 3.562 1.468L18 19.313l.531-1.468c1.574-4.25 5.66-7.313 10.469-7.313zm1.547 5.678l-6.184 6.182c-.602-.185-1.2-.391-1.863-.391-3.578 0-6.5 2.922-6.5 6.5s2.922 6.5 6.5 6.5 6.5-2.922 6.5-6.5c0-.673-.211-1.28-.402-1.89l.595-.594h1.54v-1.498h1.497v-1.512h1.536v-1.537h-.028l1.043-1.043-.05-.477-.366-3.418-3.818-.322zm.756 2.07l1.25.106.138 1.302-6.36 6.36.28.627c.25.561.389 1.175.389 1.826 0 2.498-2.002 4.5-4.5 4.5a4.484 4.484 0 01-4.5-4.5c0-2.498 2.002-4.5 4.5-4.5.643 0 1.25.136 1.805.379l.625.273 6.373-6.373zM21.5 28a1.5 1.5 0 000 3 1.5 1.5 0 000-3z"></path>
    </svg>
  );
}

export { GoogleIcon, MicorsoftIcon, SSOIcon };
