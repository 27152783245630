// AnonymousComponent.tsx
import React from "react";
import { useParams } from "react-router-dom";
import { CloudStorageRoute, WorkspaceRoute } from "./components";

const AnonymousComponent: React.FC = () => {
  const params = useParams<{
    workspaceId?: string;
    componentId?: string;
    folderId?: string;
    fileId?: string;
  }>();

  if (params?.workspaceId) {
    return (
      <WorkspaceRoute
        workspaceId={params?.workspaceId}
        itemId={params?.componentId}
      />
    );
  } else if (params?.folderId) {
    return (
      <CloudStorageRoute folderId={params?.folderId} fileId={params?.fileId} />
    );
  }

  return null;
};

export default AnonymousComponent;
