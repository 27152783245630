import React, { FC, useEffect, useState } from "react";
import WorkspaceName from "./WorkspaceName";
import { Box, CircularProgress, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../../../../../redux/store";
import {
  Get_delete_By____Id,
  getAllWorkspace__api,
  getOneWorkspace__api,
  WorkspaceData,
  WorkspaceState,
} from "../../../../../../../redux";

interface WorkspaceListProps {
  filterWorkspace: boolean;
  minMaxEclip?: boolean;
}

const WorkspaceList: FC<WorkspaceListProps> = ({
  minMaxEclip = false,
  filterWorkspace = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadLoader, setLoadLoader] = useState<boolean>(false);
  const { workspaceId } = useParams<{ workspaceId?: string }>();
  const workspaceState = useSelector(
    (state: RootState) => state.workspace as WorkspaceState
  );
  const {
    error,
    workspaceList,
    // insideWorkspaceList = [],
  } = workspaceState || {};
  const {
    workspaceList: workspaces = [],
    api_call_timeStamp,
    is_api_called,
    WorkspaceListError,
  } = workspaceList || {};

  const handleNavigate = (id: string) => {
    navigate(`/workspace/${id}`);
  };

  const getAllWorkspace = async () => {
    setLoadLoader(true);
    try {
      await dispatch(getAllWorkspace__api());
    } catch (err) {
      // console.error("Failed to fetch workspaces:", err);
      if (error) {
        console.error("Error from state:", error);
      }
    }
    setLoadLoader(false);
  };

  const getOneWorkspace__data = async () => {
    try {
      if (workspaceId) {
        const payload: Get_delete_By____Id = { _id: workspaceId };
        console.log("calling getOneWorkspace... 1");
        await dispatch(getOneWorkspace__api(payload));
     
        }
    } catch (err) {
      console.error("Failed to fetch workspace by ID:", err);
    }
  };

  useEffect(() => {
    const currentTime = Date.now();
    const apiCallTime = new Date(api_call_timeStamp).getTime();
    const timeDiff = currentTime - apiCallTime;

    const shouldCallApi = (() => {
      // Check if workspaces array is empty and no error object is present
      const noWorkspaces = Array.isArray(workspaces) && workspaces.length < 1;
      const errorPresent = WorkspaceListError && timeDiff > 90000; // Error present and time since last call > 90 seconds
      const timeSinceLastCallExceeded = timeDiff > 90000 && !WorkspaceListError; // Time since last call > 90 seconds and no error

      // Determine if we should call the API
      return (
        ((noWorkspaces && !WorkspaceListError) || // No workspaces and no error
          timeSinceLastCallExceeded || // Time since last call is sufficient and no error
          errorPresent) && // Error present and enough time has passed
        !filterWorkspace &&
        !is_api_called
      );
    })();

    const timeout = setTimeout(() => {
      if (shouldCallApi) {
        getAllWorkspace(); // Call the API
      }
      // else if (workspaceId && filterWorkspace && !is_api_called) {
      //   console.log("getting called workspace")
      //   getOneWorkspace__data(); // Call for specific workspace data
      // }
    }, 3000); // 3 seconds delay

    return () => clearTimeout(timeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    workspaces,
    api_call_timeStamp,
    workspaceId,
    filterWorkspace,
    is_api_called,
    WorkspaceListError,
  ]);

  const filteredWorkspaces =
    filterWorkspace && workspaceId
      ? workspaces.filter((workspace) => workspace._id === workspaceId)
      : workspaces;

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!filteredWorkspaces || filteredWorkspaces.length === 0) {
        console.log("getting calls to workspace here...");
        getOneWorkspace__data();
      }
    }, 3000); // 3 seconds delay

    return () => clearTimeout(timeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredWorkspaces]);
  return !loadLoader ? (
    <Stack mt={"10px"} gap={"10px"}>
      {filteredWorkspaces?.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
            color: "text.secondary",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          No workspaces found
        </Box>
      ) : (
        filteredWorkspaces?.map((workspace: WorkspaceData, index: number) => (
          <Stack
            onClick={() =>
              handleNavigate &&
              handleNavigate(workspace._id || "brokenIdentifier")
            }
            key={index}
          >
            <WorkspaceName minMaxEclip={minMaxEclip} workspace={workspace} />
          </Stack>
        ))
      )}
    </Stack>
  ) : (
    <CircularProgress color="success" />
  );
};

export default WorkspaceList;
