import React from "react";
import { Box, Link as MuiLink, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import styles from "./IconWordLongButton.module.css"; // Import the CSS module

interface IconWordLongButtonProps {
  iconSrc: string;
  text: string;
  toMuiLink?: string;
}

// This component is used in the drawer-navbar
const IconWordLongButton: React.FC<IconWordLongButtonProps> = ({
  iconSrc,
  text,
  toMuiLink = "#",
}) => {
  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="start"
      className={styles.iconWordLongButton}
    >
      <Box
        component="img"
        src={iconSrc}
        alt="icon"
        className={styles.icon}
      />
      <Typography component="p" className={styles.text}>
        <MuiLink
          component={Link}
          to={toMuiLink}
          className={styles.link}
        >
          {text}
        </MuiLink>
      </Typography>
    </Stack>
  );
};

export default IconWordLongButton;
