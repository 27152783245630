import React, { useState } from "react";
import {
  IconButton,
  InputBase,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { InfoIconBlack, PlusIcon } from "../../../../../images";
import S from "./AddToWorkspace.module.css";
import {
  ButtonSmall,
  ButtonType,
  CheckboxMedium,
  StyledTooltip,
} from "../../../../common";
import { OptionDropdown } from "./OptionDropdown";
import {
  AddToWorkspaceOptionsEnum,
  AddToWorkspaceOptionsList,
} from "./AddToWorkspaceType";
import { useDispatch } from "react-redux";
import {
  addComponent__api,
  AddComponentPayload,
  AddDocPayload,
  addDocs__api,
  addWiki__api,
  addWhiteboard__api,
  AddWhiteboardRequest,
  getAllComponents__api,
  GetAllComponentsParams,
  getAllDocs__api,
  getAllWhiteboards__api,
  AddWiki,
  getAllWikis__api,
} from "../../../../../redux";
import { useParams } from "react-router-dom";
interface AddToWorkspaceProps {
  label?: boolean | string;
  workspaceIds?: string;
  documentTypes?: any;
}
interface defaultValueInterface {
  inputValue: string;
  type: AddToWorkspaceOptionsEnum;
  checkboxSelected: boolean;
}

type Params = Record<string, string | undefined>;
const AddToWorkspace: React.FC<AddToWorkspaceProps> = ({
  label = false,
  workspaceIds = "",
  documentTypes,
}) => {
  const dispatch = useDispatch();

  const defaultValue: defaultValueInterface = {
    inputValue: "",
    type: AddToWorkspaceOptionsEnum.Board,
    checkboxSelected: false,
  };

  const { workspaceId } = useParams<Params>();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [newData, setNewData] = useState<defaultValueInterface>(defaultValue);
  const handleAddComponent = async () => {
    const effectiveWorkspaceId = workspaceId || workspaceIds;
    if (!effectiveWorkspaceId) {
      console.log("No workspaceId found");
      return;
    }

    try {
      let action;
      let postAction;

      switch (newData.type) {
        case "Whiteboard":
          const whiteboardPayload: AddWhiteboardRequest = {
            title: newData.inputValue,
            description: "g",
          };
          action = addWhiteboard__api(effectiveWorkspaceId, whiteboardPayload);
          postAction = async () => {
            const wh = {
              workspaceId: effectiveWorkspaceId,
              page: 1,
              limit: 10,
              sort: "asc",
            };
            await dispatch(getAllWhiteboards__api(wh));
          };
          break;

        case "Document":
          const addMonths = (months: number) => {
            const date = new Date();
            date.setMonth(date.getMonth() + months);
            return date.toISOString();
          };

          const documentPayload: AddDocPayload = {
            title: newData.inputValue,
            content: "Somethings",
            isPublic: true,
            password: "",
            expirationDate: addMonths(6),
          };
          action = addDocs__api(effectiveWorkspaceId, documentPayload);
          postAction = async () => {
            const doc = {
              workspaceId: effectiveWorkspaceId,
              page: 1,
              limit: 10,
              sort: "asc",
              filter: "proposal",
              isStarred: false,
            };
            await dispatch(getAllDocs__api(doc));
          };
          break;

        case "Wiki":
          if (documentTypes?.length > 0) {
            const wikiPayload: AddWiki = {
              name: newData.inputValue,
              description: "Somethings",
              homePageId: documentTypes?.[0]?._id,
            };
            action = addWiki__api(wikiPayload, effectiveWorkspaceId);
            postAction = async () => {
              await dispatch(getAllWikis__api(effectiveWorkspaceId));
            };
          }else{
            alert("please create document first to connect to wiki");
          }

          break;

        default:
          const genericPayload: AddComponentPayload = {
            name: newData.inputValue,
            componentType: `${newData.type}`,
            description: "no description",
            isFreeform: newData?.checkboxSelected || false,
          };
          action = addComponent__api(genericPayload, effectiveWorkspaceId);
          postAction = async () => {
            const payload: GetAllComponentsParams = {
              workspaceId: effectiveWorkspaceId,
              componentType: "Board", // Adjust this according to the type
              page: 1,
              limit: 10,
              sort: "asc",
            };
            await dispatch(getAllComponents__api(payload));
          };
          break;
      }

      if (action) {
        await dispatch(action);

        // Execute the respective post-action to fetch data
        if (postAction) {
          await postAction();
        }
      }
    } catch (error) {
      console.error("Failed to add component:", error);
    }
  };

  const handleCreateButtonClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (newData.inputValue.trim() === "") {
      return;
    }

    handleAddComponent();

    handleClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // Reset the data
    setNewData(defaultValue);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewData((prevData) => ({
      ...prevData,
      inputValue: event.target.value,
    }));
  };

  const handleOptionChange = (selectedOption: AddToWorkspaceOptionsEnum) => {
    setNewData((prevData) => ({
      ...prevData,
      type: selectedOption,
    }));
  };

  const open = Boolean(anchorEl);
  const id = open ? "creat-button-popover" : undefined;

  return (
    <>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        className={S["add-to-workspace__button"]}
        disableRipple
        disableFocusRipple
      >
        <Stack direction={"row"} alignItems={"center"} gap={"5px"}>
          <StyledTooltip title="Add item">
            <PlusIcon w={12} />
          </StyledTooltip>
          {label && (
            <Typography className={S["add-to-workspace__text"]}>
              Add item
            </Typography>
          )}
        </Stack>
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          className: S["add-to-workspace__popover"],
        }}
      >
        <Stack
          flex={1}
          direction={"column"}
          justifyContent={"space-between"}
          gap={"12px"}
        >
          <Stack className={S["add-to-workspace__content"]}>
            <Typography className={S["add-to-workspace__title"]}>
              Create New
            </Typography>

            {/* --------------Dropdown for options----------------- */}
            <OptionDropdown
              onChange={handleOptionChange}
              options={AddToWorkspaceOptionsList}
              initialValue={defaultValue.type}
            />

            {/* --------------Input are here----------------- */}
            <InputBase
              value={newData.inputValue}
              onChange={handleInputChange}
              placeholder="name item"
              className={S["add-to-workspace__input"]}
              onClick={(e) => {
                e.preventDefault(); // Prevents the default action
                e.stopPropagation(); // Stops the event from bubbling up
                // Additional logic for click event
              }}
              onFocus={(e) => {
                e.preventDefault(); // Prevents the default action
                e.stopPropagation(); // Stops the event from bubbling up
                // Additional logic for focus event
              }}
            />

            {/* --------------  Checkbox and buttons here  ----------------- */}
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {(newData.type === "Board" ||
                newData.type === "List" ||
                newData.type === "Checklist") && (
                <>
                  <Stack
                    direction={"row"}
                    gap={"5px"}
                    alignItems={"center"}
                    onClick={(e) => {
                      e.preventDefault(); // Prevents the default action, if any
                      e.stopPropagation(); // Stops the event from bubbling up
                      setNewData((prev) => ({
                        ...prev,
                        checkboxSelected: !prev.checkboxSelected,
                      }));
                    }}
                  >
                    <CheckboxMedium checked={newData.checkboxSelected} />
                    <Typography
                      sx={{
                        font: "normal normal 300 13px/17px Source Serif Pro",
                      }}
                    >
                      Free Form
                    </Typography>
                  </Stack>
                  <Tooltip
                    placement="top-end"
                    title={
                      <Typography
                        sx={{
                          font: "normal normal 300 11px/15px Source Serif Pro",
                          maxWidth: "125px",
                        }}
                      >
                        Free form gives you total control of your items, but can
                        only be viewed in the view selected now.
                      </Typography>
                    }
                    PopperProps={{
                      sx: {
                        "& .MuiTooltip-tooltip": {
                          bgcolor: "#616161",
                          color: "white",
                          fontSize: "12px",
                        },
                        "& .MuiTooltip-arrow": {
                          color: "#616161",
                        },
                      },
                    }}
                  >
                    <span>
                      <InfoIconBlack w={15} />
                    </span>
                  </Tooltip>
                </>
              )}
            </Stack>
          </Stack>
          <Stack direction={"row"} justifyContent={"end"}>
            <ButtonSmall
              label="Create"
              types={ButtonType.Button}
              onClick={(e: any) => handleCreateButtonClick(e)}
            />
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};

export default AddToWorkspace;
