import React, { useState, useEffect } from "react";
import { Box, Grow, Skeleton, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ViewMode } from "../Projects";
import { useDispatch, useSelector } from "react-redux";
import { ActiveWorkspaceTab, MainTopWorkspace } from "./components";
import {
  LargeCardAndImage,
  LargeStackedAndImage,
} from "../Projects/components";
import {
  deleteWorkspace__api,
  getAllSharedWorkspace__api,
  getAllUsers,
  getAllWorkspace__api,
  UpdateWorkspace,
  updateWorkspace__api,
  WorkspaceData,
  // WorkspaceState,
} from "../../../redux";
import { RootState } from "../../../redux/store";
import { getUser, getUserList } from "../../../Utils";

/**
 * Styles for the container box that holds project cards.
 */
const BOX_STYLE = {
  maxHeight: "81.5vh", // Maximum height of the container box
  overflow: "hidden", // Hide overflowing content
  overflowX: "hidden", // Hide horizontal overflow
  overflowY: "scroll", // Enable vertical scrolling for overflow
  "&::-webkit-scrollbar": {
    width: "5px", // Width of the scrollbar
    borderRadius: "4px", // Border radius of the scrollbar
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "gray", // Color of the scrollbar thumb
    borderRadius: "4px", // Border radius of the scrollbar thumb
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "lightgray", // Color of the scrollbar track
    borderRadius: "8px", // Border radius of the scrollbar track
  },
};

/**
 * Sticky tab styles for the top navigation tabs.
 */
const STICKY_TAB_STYLE = {
  position: "sticky", // Stick to the top of the container
  top: 0, // Stick to the very top of the container
  zIndex: 1000, // Ensure it's above other content
  background: "white", // Background color of the sticky tab
  marginTop: "10px", // Space above the sticky tab
};

const Workspace: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [workspaceLoader, setWorkspaceLoader] = useState({
    firstTime: true,
    regularLoader: false,
  });

  const workspaceState = useSelector((state: RootState) => state.workspace);
  const { error, workspaceList, sharedWorkspaceList } = workspaceState || {};
  const {
    workspaceList: workspaces = [],
    api_call_timeStamp,
    is_api_called,
    WorkspaceListError,
  } = workspaceList || {};
  const {
    workspaceSharedList: workspacesShared = [],

    WorkspaceSharedListError,
  } = sharedWorkspaceList || {};

  const [tabActive, setTabActive] = useState<number>(0);
  const [viewMode, setViewMode] = useState<ViewMode>("square");

  const handleNavigate = (id: string) => {
    navigate(`/workspace/${id}`);
  };

  const [editOpenPopUp, setEditOpenPopUp] = useState<boolean>(false);
  const handleEditPopUp = () => {
    setEditOpenPopUp(false);
  };
  const [initialDataOfWorkspace, setIntialDataOfWorkspace] =
    useState<WorkspaceData | null>(null);
  const getAllWorkspace = async () => {
    setWorkspaceLoader((prevState) => ({ ...prevState, regularLoader: true }));
    try {
      await dispatch(getAllWorkspace__api());
    } catch (err) {
      // console.error("Failed to fetch workspaces:", err);
      if (error) {
        console.error("Error from state:", error);
      }
    } finally {
      setWorkspaceLoader((prevState) => ({
        ...prevState,
        regularLoader: false,
        firstTime: false,
      }));
    }
  };

  const getAllSharedWorkspace = async () => {
    setWorkspaceLoader((prevState) => ({ ...prevState, regularLoader: true }));
    try {
      await dispatch(getAllSharedWorkspace__api());
    } catch (err) {
      // console.error("Failed to fetch workspaces:", err);
      if (error) {
        console.error("Error from state:", error);
      }
    } finally {
      setWorkspaceLoader((prevState) => ({
        ...prevState,
        regularLoader: false,
        firstTime: false,
      }));
    }
  };

  const deleteWorkspace = async (_id: string) => {
    try {
      if (!_id) {
        console.error("Workspace ID is not defined");
        return;
      }

      await dispatch(deleteWorkspace__api({ _id: _id }));
    } catch (err) {
      console.error("Failed to delete workspace:", err);
    }
  };

  const updateWorkspace = async (
    data: UpdateWorkspace | FormData,
    workspaceId: string
  ) => {
    try {
      if (!workspaceId) {
        console.error("Workspace ID is not defined", workspaceId, data);
        return;
      }

      await dispatch(updateWorkspace__api(data, workspaceId));
    } catch (err) {
      console.error("Failed to update workspace:", err);
    }
  };

  const handleWorkspaceColorThemes = async (
    data: any,
    workspaceId: string,
    theme: string
  ) => {
    const formData = new FormData();
    const emojis = ["🫠", "🌟", "🚀", "🎉", "🧩"];
    const getRandomEmoji = () =>
      emojis[Math.floor(Math.random() * emojis.length)];

    if (workspaceId && theme && data) {
      formData.append("name", data?.name);
      formData.append("description[content]", data?.description?.content);
      formData.append("appearance[coverImage]", data?.appearance?.coverImage);
      formData.append(
        "appearance[icon]",
        data?.appearance?.icon || getRandomEmoji()
      );
      formData.append("appearance[theme][primaryColor]", theme);
      updateWorkspace(formData, workspaceId);
    }
  };

  const onClickAction = async (args: string, _id: string) => {
    if (!_id) {
      console.error("Failed to get workspace ID", _id, args);
    }

    if (args === "Delete") {
      await deleteWorkspace(_id);
      getAllWorkspace();
    } else if (args === "Update Details" || args === "Rename") {
      setEditOpenPopUp(true);
      const wd = nonSharedWorkspaces.find(
        (workspace: WorkspaceData) => workspace._id === _id
      );
      if (!wd) {
        console.error("Workspace not found for ID:", _id);
        return;
      }

      setIntialDataOfWorkspace(wd ? wd : null);
    }
  };

  useEffect(() => {
    const currentTime = Date.now();
    const apiCallTime = new Date(api_call_timeStamp).getTime();
    const timeDiff = currentTime - apiCallTime;

    // Define the period to wait before making a new API call
    const periodToCallApi = 90000; // 90 seconds

    // Check if WorkspaceListError is an object with a message property
    const isErrorObject =
      typeof WorkspaceListError === "object" &&
      WorkspaceListError !== null &&
      "message" in WorkspaceListError;

    // console.log("Effect triggered:", {
    //   timeDiff,
    //   workspaces,
    //   WorkspaceListError,
    //   is_api_called,
    // });

    // Determine if an API call should be made
    const shouldCallApi = (() => {
      // Check if there are no workspaces and there is no error
      if (workspaces.length === 0 && !isErrorObject) {
        return true;
      }

      // Check if the time since the last API call is greater than the defined period and no API call is in progress
      if (timeDiff > periodToCallApi && !is_api_called) {
        return true;
      }

      // If there is an error and enough time has passed since the last call, make another API call
      if (isErrorObject && timeDiff > periodToCallApi) {
        return true;
      }

      return false;
    })();

    if (shouldCallApi && !is_api_called) {
      // console.log("Calling API...");
      getAllWorkspace(); // Trigger the API call
      if (!WorkspaceSharedListError) {
        getAllSharedWorkspace();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    workspaces,
    api_call_timeStamp,
    WorkspaceListError,
    is_api_called,
    workspaceList,
  ]);

  const handleTabChange = (newTab: number) => {
    setTabActive(newTab);
  };

  const user = getUser();
  const sharedWorkspaces =
    workspacesShared &&
    workspacesShared?.filter(
      (card: WorkspaceData) =>
        card?.visibility === "team" || card?.visibility === "public"
      // &&
      //   card?.ownerId !== user?.data?._id
    );

  const nonSharedWorkspaces =
    workspaces &&
    workspaces?.filter(
      (card: WorkspaceData) =>
        (card?.visibility === "private" || card?.visibility === "public") &&
        card?.ownerId === user?.data?._id
    );

  const handleGetAlluser = async () => {
    await dispatch(getAllUsers());
  };

  useEffect(() => {
    handleGetAlluser();
  }, []);
  return (
    <Box>
      <Stack p="30px 25px 2px 25px">
        <MainTopWorkspace
          getAllWorkspace={getAllWorkspace}
          viewMode={viewMode}
          setViewMode={setViewMode}
          editOpenPopUp={editOpenPopUp}
          handleEditPopUp={handleEditPopUp}
          intialData={{
            mainDetails: {
              workspaceName: initialDataOfWorkspace?.name,
              workspaceDetails: initialDataOfWorkspace?.description?.content,
              defaultWorkspaceImage: true,
              defaultWorkspaceImageIndex: 0,
              uploadWorkspaceImage:
                initialDataOfWorkspace?.appearance?.coverImage,
            },
            connections: {
              connectionList: [],
            },
            shareWorkspace: {
              shareWorkspaceList: [],
            },
          }}
          initialDataOfWorkspaceId={initialDataOfWorkspace?._id}
          updateWorkspace={updateWorkspace}
          iconApp={initialDataOfWorkspace?.appearance.icon}
          theme={initialDataOfWorkspace?.appearance?.theme}
        />
      </Stack>
      <Box sx={BOX_STYLE}>
        {/* Sticky tabs for switching between project views */}
        <Box sx={{ ...STICKY_TAB_STYLE, padding: "10px 0px 0px 25px" }}>
          <Stack>
            <ActiveWorkspaceTab
              tabActive={tabActive}
              setTabActive={handleTabChange}
            />
          </Stack>
        </Box>

        <Stack
          mt="10px"
          direction={viewMode === "square" ? "row" : "column"}
          alignItems="center"
          justifyContent="flex-start"
          gap={1}
          flexWrap="wrap"
          sx={{ padding: "10px" }}
        >
          <>
            {workspaceLoader.firstTime &&
            workspaceLoader.regularLoader &&
            tabActive === 1 ? (
              Array.from({ length: 8 }, (_, index) => (
                <Skeleton
                  key={index}
                  variant="rounded"
                  width={250}
                  height={225}
                  sx={{ bgcolor: "lightgray", opacity: 0.6 }}
                />
              ))
            ) : (
              <>
                {sharedWorkspaces?.length === 0 && tabActive === 1 ? (
                  <Typography variant="body1">
                    You don’t have any shared workspaces at the moment. Consider
                    joining or requesting access to shared spaces.
                  </Typography>
                ) : (
                  sharedWorkspaces?.map((card: any, index: number) => (
                    <Grow
                      key={index}
                      in={tabActive === 1} // Only animate if active tab is for shared projects
                      {...(tabActive === 1 ? { timeout: 500 } : {})}
                      mountOnEnter
                      unmountOnExit
                      style={{ transformOrigin: "top" }}
                    >
                      <div
                        style={{
                          width: viewMode !== "square" ? "100%" : "auto",
                        }}
                      >
                        <SharedProjectCard
                          title={card?.name}
                          dateCreated={card?.createdAt}
                          imageUrl={card?.appearance?.coverImage as string}
                          viewMode={viewMode}
                          handleNavigate={handleNavigate}
                          _id={card?._id}
                          onClickAction={onClickAction}
                          handleWorkspaceColorThemes={(theme: string) => {
                            handleWorkspaceColorThemes(card, card._id, theme);
                          }}
                          cardColor={card?.appearance?.theme?.primaryColor}
                        />
                      </div>
                    </Grow>
                  ))
                )}
              </>
            )}
          </>

          {/* Non-Shared Workspaces */}
          {workspaceLoader.firstTime &&
          workspaceLoader.regularLoader &&
          tabActive === 0 ? (
            Array.from({ length: 8 }, (_, index) => (
              <Skeleton
                key={index}
                variant="rounded"
                width={250}
                height={225}
                sx={{ bgcolor: "lightgray", opacity: 0.6 }}
              />
            ))
          ) : (
            <>
              {nonSharedWorkspaces.length === 0 && tabActive === 0 ? (
                <Typography variant="body1">
                  No workspace found. Please create a new one to get started.
                </Typography>
              ) : (
                nonSharedWorkspaces.map((card, index) => (
                  <Grow
                    key={index}
                    in={tabActive === 0} // Only animate if active tab is for non-shared projects
                    {...(tabActive === 0 ? { timeout: 500 } : {})}
                    mountOnEnter
                    unmountOnExit
                    style={{ transformOrigin: "top" }}
                  >
                    <div
                      style={{ width: viewMode !== "square" ? "100%" : "auto" }}
                    >
                      <NonSharedProjectCard
                        title={card.name}
                        dateCreated={card.createdAt}
                        imageUrl={card?.appearance?.coverImage as string}
                        viewMode={viewMode}
                        handleNavigate={handleNavigate}
                        _id={card._id}
                        onClickAction={onClickAction}
                        handleWorkspaceColorThemes={(theme: string) => {
                          handleWorkspaceColorThemes(card, card._id, theme);
                        }}
                        cardColor={
                          card?.appearance?.theme?.primaryColor || "black"
                        }
                      />
                    </div>
                  </Grow>
                ))
              )}
            </>
          )}
        </Stack>
      </Box>
    </Box>
  );
};
/**
 * Props interface for the card components.
 */
interface CardProps {
  title: string; // Title of the project card
  dateCreated: string; // Date when the project was created
  imageUrl: string | File; // URL of the project's image
  viewMode: string; // View mode of the project
  handleNavigate?: (arg: string) => void; //
  _id: string;
  onClickAction: (arg: string, _id: string) => void;
  handleWorkspaceColorThemes?: (theme: string) => void;
  cardColor: string;
}

/**
 * Component for displaying a shared project card.
 * @param title - Title of the project.
 * @param dateCreated - Date when the project was created.
 * @param imageUrl - URL of the project's image.
 * @param viewMode - View mode of the project
 */
const SharedProjectCard: React.FC<CardProps> = ({
  title,
  dateCreated,
  imageUrl,
  viewMode,
  handleNavigate,
  _id,
  onClickAction,
  handleWorkspaceColorThemes,
  cardColor,
}) => (
  <>
    {viewMode === "square" ? (
      <LargeCardAndImage
        title={title}
        dateCreated={dateCreated}
        imageUrl={imageUrl}
        handleNavigate={handleNavigate}
        _id={_id}
        onClickAction={onClickAction}
        onThemeUpdate={(theme: string) => {
          // Same logic for this component, call the function
          handleWorkspaceColorThemes && handleWorkspaceColorThemes(theme);
        }}
        cardColor={cardColor}
      />
    ) : (
      <LargeStackedAndImage
        projectName={title}
        dateCreated={dateCreated}
        imageUrl={imageUrl}
        handleNavigate={handleNavigate}
        _id={_id}
        onClickAction={onClickAction}
        onThemeUpdate={(theme: string) => {
          // Same logic for this component, call the function
          handleWorkspaceColorThemes && handleWorkspaceColorThemes(theme);
        }}
        cardColor={cardColor}
      />
    )}
  </>
);

/**
 * Component for displaying a non-shared project card.
 * @param title - Title of the project.
 * @param dateCreated - Date when the project was created.
 * @param imageUrl - URL of the project's image.
 * @param viewMode - View mode of the project
 */
const NonSharedProjectCard: React.FC<CardProps> = ({
  title,
  dateCreated,
  imageUrl,
  viewMode,
  handleNavigate,
  _id,
  onClickAction,
  handleWorkspaceColorThemes,
  cardColor,
}) => (
  <>
    {viewMode === "square" ? (
      <LargeCardAndImage
        title={title}
        dateCreated={dateCreated}
        imageUrl={imageUrl}
        handleNavigate={handleNavigate}
        _id={_id}
        onClickAction={onClickAction}
        onThemeUpdate={(theme: string) => {
          // Same logic for this component, call the function
          handleWorkspaceColorThemes && handleWorkspaceColorThemes(theme);
        }}
        cardColor={cardColor}
      />
    ) : (
      <LargeStackedAndImage
        projectName={title}
        dateCreated={dateCreated}
        imageUrl={imageUrl}
        handleNavigate={handleNavigate}
        _id={_id}
        onClickAction={onClickAction}
        onThemeUpdate={(theme: string) => {
          // Same logic for this component, call the function
          handleWorkspaceColorThemes && handleWorkspaceColorThemes(theme);
        }}
        cardColor={cardColor}
      />
    )}
  </>
);

export default Workspace;
