/**
 * Custom error class for invalid ObjectId errors.
 */
class ObjectIdError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'ObjectIdError';
    }
}

/**
 * Checks if a string is a valid MongoDB ObjectId.
 * @param id - The string to check.
 * @returns True if the string is a valid 24-character hexadecimal ObjectId, otherwise false.
 */
function isValidObjectId(id: string): id is string {
    return typeof id === 'string' && id.length === 24 && /^[a-fA-F0-9]{24}$/.test(id);
}

/**
 * Formats a MongoDB ObjectId by adding dashes after every 3 characters.
 * @param id - The original MongoDB ObjectId.
 * @returns The formatted ObjectId or throws an error if the input is invalid.
 */
export function formatObjectId(id: string): string {
    if (!isValidObjectId(id)) {
        throw new ObjectIdError('Invalid MongoDB ObjectId: Must be a 24-character hexadecimal string.');
    }

    return id.match(/.{1,3}/g)?.join('-') || id;
}

/**
 * Checks if a formatted ObjectId is valid.
 * @param formattedId - The formatted ObjectId to check.
 * @returns True if the formatted ObjectId is in the expected format, otherwise false.
 */
function isValidFormattedObjectId(formattedId: string): boolean {
    return typeof formattedId === 'string' && /^([a-fA-F0-9]{3}-){7}[a-fA-F0-9]{3}$/.test(formattedId);
}

/**
 * Reverts a formatted MongoDB ObjectId back to its original form by removing dashes.
 * @param formattedId - The formatted MongoDB ObjectId.
 * @returns The original ObjectId or throws an error if the input is invalid.
 */
export function revertFormattedObjectId(formattedId: string): string {
    if (!isValidFormattedObjectId(formattedId)) {
        throw new ObjectIdError('Invalid formatted ObjectId: Must be in the format "xxx-xxx-xxx-xxx-xxx-xxx-xxx-xxx-xxx".');
    }

    return formattedId.replace(/-/g, '');
}


/*
 
try {
const originalId = '66c5efc85aeaead6ad8b1d2d';
const formattedId = formatObjectId(originalId); // '66c-5ef-c85-aea-ead-6ad-8b1-d2d'
const revertedId = revertFormattedObjectId(formattedId); // '66c5efc85aeaead6ad8b1d2d'

// Example with invalid inputs
const invalidId = '66c5efc85aeaead6ad8b1d'; // Invalid length
const invalidFormattedId = '66c-5ef-c85-aea-ead-6ad-8b1-d2d-'; // Invalid format

formatObjectId(invalidId); // Throws ObjectIdError
revertFormattedObjectId(invalidFormattedId); // Throws ObjectIdError
} catch (error) {
if (error instanceof ObjectIdError) {
  console.error(error.message); // Handle ObjectId errors
} else {
  console.error('An unexpected error occurred:', error);
}
}

 
*/