import { PayloadAction } from "@reduxjs/toolkit";
import { ComponentItem, ContentItem, WorkspaceState } from "../workspaceTypes";
import {
  getAllSharedWorkspace__Failure,
  getAllWorkspace__Failure,
} from "./WorkspaceSliceFailure";
export const createWorkspace__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllWorkspace__Success = (
  state: WorkspaceState,
  action: PayloadAction<{ data: any[] }>
) => {
  const data = action?.payload?.data;

  if (data.length === 0) {
    getAllWorkspace__Failure(state, action as any);
    state.error = "No workspace found";
    return;
  }

  state.workspaceList = {
    workspaceList: data,
    api_call_timeStamp: new Date().toISOString(),
    is_api_called: false,
    WorkspaceListError: null,
  };

  // No need to set state.error here, as it's already handled in the if block
};

export const getAllSharedWorkspace__Success = (
  state: WorkspaceState,
  action: PayloadAction<{ data: any[] }>
) => {
  const data = action?.payload?.data;

  if (data.length === 0) {
    getAllSharedWorkspace__Failure(state, action as any);
    state.error = "No Shared workspace found";
    return;
  }

  state.sharedWorkspaceList = {
    workspaceSharedList: data,
    WorkspaceSharedListError: null,
  };

  // No need to set state.error here, as it's already handled in the if block
};

export const getOneWorkspace__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.insideWorkspaceItem = action?.payload?.data;
  state.data = action?.payload?.data;
};

export const updateWorkspace__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  const updatedWorkspace = action?.payload?.data;

  if (!updatedWorkspace || !updatedWorkspace._id) {
    console.error("Update failed: No workspace data or _id present");
    return;
  }

  // Update workspace data
  state.data = updatedWorkspace;

  // Update workspaceList with the new data
  if (state.workspaceList.workspaceList) {
    state.workspaceList.workspaceList = state.workspaceList.workspaceList.map(
      (workspace) =>
        workspace._id === updatedWorkspace._id ? updatedWorkspace : workspace
    );
  }
};

export const deleteWorkspace__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceContent__Success = (
  state: WorkspaceState,
  action: PayloadAction<{ data: { _id: string; content: ContentItem[] } }>
) => {
  const newData = action.payload.data;
  const workspaceId = newData._id;
  const newContentArray: ContentItem[] = newData.content;

  state.workspaceList.workspaceList = state.workspaceList.workspaceList.map(
    (workspace) => {
      if (workspace._id === workspaceId) {
        // Filter out duplicate content
        const existingContentIds = new Set(
          workspace.content.map((item) => item._id)
        );
        const filteredNewContent = newContentArray.filter(
          (newItem: ContentItem) => !existingContentIds.has(newItem._id)
        );

        return {
          ...workspace,
          content: [...workspace.content, ...filteredNewContent],
          updatedAt: new Date().toISOString(), // Optionally update the timestamp
        };
      }
      return workspace;
    }
  );

  state.workspaceContentUpdated = newContentArray;
  state.data = newContentArray;
};

export const reOrderWorkspaceContent__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const updateWorkspaceContent__Success = (
  state: WorkspaceState,
  action: PayloadAction<{ data: any }>
) => {
  const updatedData = action.payload.data;
  const workspaceId = updatedData._id;
  const updatedContent = updatedData.content;

  state.workspaceList.workspaceList = state.workspaceList.workspaceList.map(
    (workspace) => {
      if (workspace._id === workspaceId) {
        return {
          ...workspace,
          content: workspace.content.map((item) =>
            item._id === updatedContent._id
              ? { ...item, ...updatedContent }
              : item
          ),
          updatedAt: new Date().toISOString(), // Optionally update the timestamp
        };
      } else {
        console.warn("not found workspace " + workspaceId);
      }
      return workspace;
    }
  );

  state.workspaceContentUpdated = updatedContent;
};

export const deleteWorkspaceContent__Success = (
  state: WorkspaceState,
  action: PayloadAction<{ data: any }>
) => {
  const contentId: string = action?.payload?.data?.contentId;

  // Iterate through each workspace and update the content array
  state.workspaceList.workspaceList = state.workspaceList.workspaceList.map(
    (workspace) => {
      if (workspace.content) {
        return {
          ...workspace,
          content: workspace.content.map((item) =>
            item._id === contentId
              ? { ...item, isDeleted: true } // Mark item as deleted
              : item
          ),
          updatedAt: new Date().toISOString(), // Optionally update the timestamp
        };
      }
      return workspace;
    }
  );

  state.workspaceContentUpdated = { contentId }; // Store the contentId of the deleted item
};
export const addWorkspaceMember__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllWorkspaceMember__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.memberOfWorkspace = action?.payload?.data;
};

export const updateWorkspaceMember__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const deleteWorkspaceMember__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceCustomField__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const updateWorkspaceCustomField__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const deleteWorkspaceCustomField__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceTag__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const updateWorkspaceTag__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const deleteWorkspaceTag__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceTemplate__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const applyWorkspaceTemplate__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceIntegration__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const updateWorkspaceIntegration__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const deleteWorkspaceIntegration__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceActivity__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllWorkspaceActivity__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const updateWorkspaceColumn__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceConnection__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceReminder__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceVideoReport__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceFile__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceMedia__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceBookMark__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceTableOfContent__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceEquation__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceButton__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceSyncElement__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceHeadingToggle__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceCodeBlock__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const deleteWorkspaceInBulk__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addGrid__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllGrids__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const getOneGrid__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const updateOneGrid__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const deleteGrid__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const addSheet__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const updateSheet__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const deleteSheet__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const reorderSheets__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const updateCell__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addComponent__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllComponents__Success = (
  state: WorkspaceState,
  action: PayloadAction<{ data: ComponentItem[] }>
) => {
  const { data } = action.payload;

  data.forEach((item) => {
    const workspaceId = item.workspaceId;

    // Validate that the item contains necessary fields
    if (!workspaceId || !item._id || !item.name || !item.componentType) {
      console.error("Invalid item data:", item);
      return;
    }

    if (!state.componentsData[workspaceId]) {
      state.componentsData[workspaceId] = {
        loading: false,
        data: { items: [] },
      };
    } else {
      state.componentsData[workspaceId].loading = false;
    }

    const existingIds = new Set(
      state.componentsData[workspaceId].data.items.map((i) => i._id)
    );

    if (!existingIds.has(item._id)) {
      state.componentsData[workspaceId].data.items.push(item);
    }
  });
};

export const getOneComponent__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
  state.insideWorkspaceComponentData = action?.payload?.data;
};
export const updateOneComponent__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const deleteOneComponent__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const deleteBulkComponents__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const createTask__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getOneTask__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const updateOneTask__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const deleteOneTask__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const getAllTasks__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const createSubTask__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllSubTasks__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const deleteBulkTasks__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const AddWhiteboard__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const GetAllWhiteboards__Success = (
  state: WorkspaceState,
  action: PayloadAction<{ data: ComponentItem[] }>
) => {
  const { data } = action.payload;
  data.forEach((item) => {
    const { workspaceId, _id, title } = item;

    // Validate that the item contains necessary fields
    if (!workspaceId || !_id || !title) {
      console.error("Invalid item data:", item);
      return;
    }

    // Set the componentType manually
    const componentType = "Whiteboard";

    // Create a new item with the componentType
    const updatedItem = {
      ...item,
      title, // Use title from API response
      componentType, // Manually set the componentType
    };

    if (!state.componentsData[workspaceId]) {
      state.componentsData[workspaceId] = {
        loading: false,
        data: { items: [] },
      };
    } else {
      state.componentsData[workspaceId].loading = false;
    }

    const existingIds = new Set(
      state.componentsData[workspaceId].data.items.map((i) => i._id)
    );

    if (!existingIds.has(_id)) {
      state.componentsData[workspaceId].data.items.push(updatedItem);
    }
  });
};

export const GetOneWhiteboard__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.insideWorkspaceComponentData = action?.payload?.data;
  state.data = action?.payload?.data;
};

export const UpdateWhiteboard__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const DeleteWhiteboard__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const AddObject__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const UpdateObject__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const DeleteObject__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const AddCollaborator__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const GetAllCollaborators__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const UpdateCollaborator__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const DeleteCollaborator__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const CreateVersion__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const GetAllVersions__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const RestoreVersion__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addDocs__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllDocs__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  const { data } = action.payload;
  data.forEach((item: any) => {
    const { workspaceId, _id, title } = item;

    // Validate that the item contains necessary fields
    if (!workspaceId || !_id || !title) {
      console.error("Invalid item data:", item);
      return;
    }

    // Set the componentType manually
    const componentType = "Document";

    // Create a new item with the componentType
    const updatedItem = {
      ...item,
      title, // Use title from API response
      componentType, // Manually set the componentType
    };

    if (!state.componentsData[workspaceId]) {
      state.componentsData[workspaceId] = {
        loading: false,
        data: { items: [] },
      };
    } else {
      state.componentsData[workspaceId].loading = false;
    }

    const existingIds = new Set(
      state.componentsData[workspaceId].data.items.map((i) => i._id)
    );

    if (!existingIds.has(_id)) {
      state.componentsData[workspaceId].data.items.push(updatedItem);
    }
  });
};

export const getOneDoc__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.insideWorkspaceComponentData = action?.payload?.data;
  state.data = action?.payload?.data;
};

export const updateOneDoc__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Add Wiki Success
export const AddWiki__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Get All Wikis Success
export const GetAllWikis__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  const { data } = action.payload;
  data.forEach((item: any) => {
    const { workspaceId, _id, name } = item;

    // Validate that the item contains necessary fields
    if (!workspaceId || !_id || !name) {
      console.error("Invalid item data:", item);
      return;
    }

    // Set the componentType manually
    const componentType = "Wiki";

    // Create a new item with the componentType
    const updatedItem = {
      ...item,
      name, // Use name from API response
      componentType, // Manually set the componentType
    };

    if (!state.componentsData[workspaceId]) {
      state.componentsData[workspaceId] = {
        loading: false,
        data: { items: [] },
      };
    } else {
      state.componentsData[workspaceId].loading = false;
    }

    const existingIds = new Set(
      state.componentsData[workspaceId].data.items.map((i) => i._id)
    );

    if (!existingIds.has(_id)) {
      state.componentsData[workspaceId].data.items.push(updatedItem);
    }
  });
};

// Get One Wiki Success
export const GetOneWiki__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
  state.insideWorkspaceComponentData = action?.payload?.data;
};

// Add Wiki Page Success
export const AddWikiPage__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Get Wiki Page Success
export const GetWikiPage__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Update Wiki Page Success
export const UpdateWikiPage__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Add Component View Success
export const AddComponentView__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
