import React, { useMemo, useState } from "react";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemText,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { SearchInDropdown } from "../SearchInDropdown";
import styles from "./IconDropdownUser.module.css";
import { SmallButtonDropdown } from "../Buttons";
import { ButtonType } from "../Buttons/AllButtonProps";
import { IconDropdownUserOptionInterface } from "./IconDropdownUserDataTypes";
import { PlusIcon } from "../../../images";
import { avatarInitialLetters } from "../../../Utils";

// Define the props based on the componentType
type IconDropdownUserProps =
  | {
      componentType: "addUser";
      options: any;
      onSelect: (selectedMember: IconDropdownUserOptionInterface) => void;
    }
  | {
      componentType: "showUser";
      showSelectedUser: any;
      options: any;
      onSelect: (selectedMember: IconDropdownUserOptionInterface) => void;
    };

const IconDropdownUser: React.FC<IconDropdownUserProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [searchQuery, setSearchQuery] = useState("");

  const { componentType, options, onSelect } = props;

  const renderDisplay = () => {
    switch (componentType) {
      case "addUser":
        return <PlusIcon w={14} />;

      case "showUser":
        return (
          <Avatar
            src={
              props?.showSelectedUser?.imgSrc || props?.showSelectedUser?.avatar
            }
            sx={{ backgroundColor: props?.showSelectedUser?.avatar }}
            className={styles["icon-dropdown-user__avatar"]}
          >
            <Typography
              sx={{
                font: "normal normal normal 13px/17px Source Serif Pro",
              }}
            >
              {avatarInitialLetters(props?.showSelectedUser?.firstName)}
            </Typography>
          </Avatar>
        );
      default:
        return null;
    }
  };

  const filteredMembers = useMemo(() => {
    return options.filter(
      (member: any) =>
        member?.firstName?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        member?.lastName?.toLowerCase()?.includes(searchQuery.toLowerCase())
    );
  }, [searchQuery, options]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchQuery("");
  };

  const handleSelect = (member: IconDropdownUserOptionInterface) => {
    onSelect(member);
    handleClose();
  };

  return (
    <>
      <Box
        sx={{
          height: "25px",
          width: "25px",
          borderRadius: "50px",
          display: "flex",
          border: "0px",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        {renderDisplay()}
      </Box>

      <Popover
        id={open ? "simple-popover" : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          elevation: 0,
          className: styles["icon-dropdown-user__popover"],
        }}
      >
        <Stack>
          <Stack className={styles["icon-dropdown-user__search-container"]}>
            <SearchInDropdown
              width="100%"
              value={searchQuery}
              onChange={(value) => setSearchQuery(value)}
              placeHolder="Search team"
            />
          </Stack>

          <List className={styles["icon-dropdown-user__list"]}>
            {filteredMembers.map((member: any) => (
              <ListItem
                key={member?._id}
                className={styles["icon-dropdown-user__list-item"]}
              >
                <Stack
                  className={styles["icon-dropdown-user__list-item-content"]}
                >
                  <Stack
                    className={styles["icon-dropdown-user__list-item-details"]}
                  >
                    <Avatar
                      src={
                        member?.avatar.startsWith("#")
                          ? undefined
                          : member?.avatar
                      }
                      sx={{
                        height: "15px",
                        width: "15px",
                        backgroundColor: member?.avatar.startsWith("#")
                          ? member?.avatar
                          : undefined,
                      }}
                    >
                      <Typography
                        sx={{
                          font: "normal normal normal 7px/9px Source Serif Pro",
                        }}
                      >
                        {avatarInitialLetters(member?.firstName)}
                      </Typography>
                    </Avatar>
                    <ListItemText
                      primary={member.firstName + " " + member.lastName}
                      primaryTypographyProps={{
                        className: styles["icon-dropdown-user__list-item-text"],
                      }}
                    />
                  </Stack>
                  <SmallButtonDropdown
                    label="Select"
                    types={ButtonType.Button}
                    onClick={() => handleSelect(member)}
                  />
                </Stack>
              </ListItem>
            ))}
          </List>
        </Stack>
      </Popover>
    </>
  );
};

export default IconDropdownUser;
