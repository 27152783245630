import { ClockIcon } from "@mui/x-date-pickers";
import { AnalyticsIcon, BearishOSIcon, ConnectedIcon, CopyIcon, CustomizeIcon, DeleteIcon, ExportIcon, LockIcon, ProjectIcon, RightArrowIcon, UndoIcon } from "../../../../images";
import { ThreeDotLargeMenuItemProps } from "../../../common";

export const menuItems: ThreeDotLargeMenuItemProps[] = [
    {
      text: "Lock page",
      icon: LockIcon,
      action: () => console.log("Lock page clicked"),
    },
    {
      text: "Copy link",
      icon: ConnectedIcon,
      action: () => console.log("Copy link clicked"),
    },
    {
      text: "Duplicate",
      icon: CopyIcon,
      action: () => console.log("Duplicate clicked"),
    },
    {
      text: "Move to",
      icon: RightArrowIcon,
      action: () => console.log("Move to clicked"),
    },
    {
      text: "Delete",
      icon: DeleteIcon,
      action: () => console.log("Delete clicked"),
    },
    { divider: true },
    {
      text: "Undo",
      icon: UndoIcon,
      action: () => console.log("Undo clicked"),
    },
    {
      text: "View edit history",
      icon: ClockIcon,
      action: () => console.log("View edit history clicked"),
    },
    {
      text: "View analytics",
      icon: AnalyticsIcon,
      action: () => console.log("View analytics clicked"),
    },
    {
      text: "Export",
      icon: ExportIcon,
      action: () => console.log("Export clicked"),
    },
    { divider: true },
    {
      text: "Open Bearish OS app",
      icon: BearishOSIcon,
      action: () => console.log("Open Bearish OS app clicked"),
    },
    {
      text: "Connect to project",
      icon: ProjectIcon,
      iconProps: { color: "black", w: 15 },
      action: () => console.log("Connect to project clicked"),
    },
    {
      text: "Customize page",
      icon: CustomizeIcon,
      iconProps: { color: "black", w: 15 },
      action: () => console.log("Customize page clicked"),
    },
  ];
  