import {
  AddElementOptionValuesEnum,
  AddElementPopoutFormDataInterface,
  DealStageCurrencyEnum,
} from "../../../../../../common";

// this list is the data , which was controlled by addElementPopover //
// this list will fetch by API...
export const CUSTOM_FIELD_LIST: AddElementPopoutFormDataInterface[] = [
  {
    fieldname: "ShortText",
    description: "about short text",
    emoji: false,
    formType: AddElementOptionValuesEnum.ShortText,
  },
  {
    fieldname: "Paragraph",
    description: "about paragraph",
    emoji: false,
    formType: AddElementOptionValuesEnum.Paragraph,
  },
  {
    fieldname: "Number",
    description: "about number",
    emoji: false,
    formType: AddElementOptionValuesEnum.Number,
  },
  {
    fieldname: "Checkbox for task",
    description: "about checkbox for task",
    emoji: false,
    formType: AddElementOptionValuesEnum.Checkbox,
  },
  {
    fieldname: "Date",
    description: "about date",
    emoji: false,
    formType: AddElementOptionValuesEnum.Date,
  },
  {
    fieldname: "Timestamp",
    description: "about timestamp",
    emoji: false,
    formType: AddElementOptionValuesEnum.Timestamp,
  },
  {
    fieldname: "task dropdown [SelectDropdown single]",
    description: "todo-task dropdown[single dropdown]",
    emoji: false,
    formType: AddElementOptionValuesEnum.SelectDropdown,
    additionalValue: [
      { value: "option one", color: "#bd10e0ff" },
      { value: "option two", color: "#7ed321ff" },
      { value: "option three", color: "#f8e71cff" },
      { value: "option four", color: "#4a90e2ff" },
    ],
  },
  {
    fieldname: "Approved",
    description: "about approval button",
    emoji: false,
    formType: AddElementOptionValuesEnum.Approved,
    additionalValue: [
      {
        id: 1,
        initialValue: "Approved",
        changedValue: "Approved",
        initialColor: "#28A745",
        selectedColor: "#28A745",
      },
      {
        id: 2,
        initialValue: "Waiting",
        changedValue: "Waiting",
        initialColor: "#FFA500",
        selectedColor: "#FFA500",
      },
      {
        id: 3,
        initialValue: "Rejected",
        changedValue: "Rejected",
        initialColor: "#FF4500",
        selectedColor: "#FF4500",
      },
    ],
  },
  {
    fieldname: "MultiselectLabels",
    description: "select multiple options from here",
    emoji: { name: "Houses", native: "🏘️" },
    formType: AddElementOptionValuesEnum.MultiselectLabels,
    additionalValue: [
      { value: "todo", color: "#ff5733ff" },
      { value: "work", color: "#33ff57ff" },
      { value: "option-zx one", color: "#3357ffff" },
      { value: "play", color: "#ff33aaff" },
      { value: "option-yv two", color: "#ffaa33ff" },
      { value: "rest", color: "#aaff33ff" },
      { value: "option-xq three", color: "#ff33ff33" },
      { value: "eat", color: "#33aaffff" },
      { value: "option-wk four", color: "#ff3399ff" },
      { value: "read", color: "#3399ffff" },
      { value: "option-jm five", color: "#5733ff33" },
    ],
  },
  {
    fieldname: "ApprovalStatus",
    description: "status of approval process",
    emoji: false,
    formType: AddElementOptionValuesEnum.Approved,
    additionalValue: [
      {
        id: 1,
        initialValue: "Approved",
        changedValue: "Accepted",
        initialColor: "#28A745",
        selectedColor: "#461bf2",
      },
      {
        id: 2,
        initialValue: "Pending",
        changedValue: "Awaiting",
        initialColor: "#FFA500",
        selectedColor: "#03ff9a",
      },
      {
        id: 3,
        initialValue: "Declined",
        changedValue: "Denied",
        initialColor: "#FF4500",
        selectedColor: "#de26ff",
      },
    ],
  },
  {
    fieldname: "Person",
    description: "about person",
    emoji: false,
    formType: AddElementOptionValuesEnum.Person,
  },
  {
    fieldname: "FileMedia",
    description: "about file and media",
    emoji: false,
    formType: AddElementOptionValuesEnum.FileMedia,
  },
  {
    fieldname: "URL",
    description: "about URL",
    emoji: false,
    formType: AddElementOptionValuesEnum.URL,
  },
  {
    fieldname: "Email",
    description: "about email",
    emoji: false,
    formType: AddElementOptionValuesEnum.Email,
  },
  {
    fieldname: "Phone",
    description: "about phone",
    emoji: false,
    formType: AddElementOptionValuesEnum.Phone,
  },
  {
    fieldname: "Formula",
    description: "about formula",
    emoji: false,
    formType: AddElementOptionValuesEnum.Formula,
  },
  {
    fieldname: "Relation",
    description: "about relation",
    emoji: false,
    formType: AddElementOptionValuesEnum.Relation,
  },
  {
    fieldname: "Rollup",
    description: "about rollup",
    emoji: false,
    formType: AddElementOptionValuesEnum.Rollup,
  },
  {
    fieldname: "ProgressManual",
    description: "about progress manual",
    emoji: false,
    formType: AddElementOptionValuesEnum.ProgressManual,
  },
  {
    fieldname: "ProgressAuto",
    description: "about progress auto",
    emoji: false,
    formType: AddElementOptionValuesEnum.ProgressAuto,
  },
  {
    fieldname: "Rating",
    description: "about rating",
    emoji: false,
    formType: AddElementOptionValuesEnum.Rating,
  },
  {
    fieldname: "Money",
    description: "about money",
    emoji: {
      name: "Unicorn",
      native: "🦄",
    },
    formType: AddElementOptionValuesEnum.Money,
    additionalValue: DealStageCurrencyEnum.JPY,
  },
  {
    fieldname: "Location",
    description: "about location",
    emoji: false,
    formType: AddElementOptionValuesEnum.Location,
  },
  {
    fieldname: "DependentDropdown",
    description: "about dependent dropdown",
    emoji: false,
    formType: AddElementOptionValuesEnum.DependentDropdown,
  },
  {
    fieldname: "RichText",
    description: "about rich text",
    emoji: false,
    formType: AddElementOptionValuesEnum.RichText,
  },
  {
    fieldname: "ColorPicker",
    description: "about color picker",
    emoji: false,
    formType: AddElementOptionValuesEnum.ColorPicker,
    additionalValue: [
      { value: "Crimson", color: "#DC143C" },
      { value: "LimeGreen", color: "#32CD32" },
      { value: "DeepSkyBlue", color: "#00BFFF" },
      { value: "DarkOrange", color: "#FF8C00" },
      { value: "Gold", color: "#FFD700" },
      { value: "MediumOrchid", color: "#BA55D3" },
      { value: "Turquoise", color: "#40E0D0" },
      { value: "Tomato", color: "#FF6347" },
      { value: "SlateBlue", color: "#6A5ACD" },
      { value: "SpringGreen", color: "#00FF7F" },
    ],
  },
  {
    fieldname: "Countdown",
    description: "about countdown",
    emoji: false,
    formType: AddElementOptionValuesEnum.Countdown,
  },
  {
    fieldname: "Connection",
    description: "about connection",
    emoji: false,
    formType: AddElementOptionValuesEnum.Connection,
  },
  {
    fieldname: "Voting",
    description: "about voting",
    emoji: false,
    formType: AddElementOptionValuesEnum.Voting,
  },
  {
    fieldname: "Percentage",
    description: "about percentage",
    emoji: false,
    formType: AddElementOptionValuesEnum.Percentage,
  },
];

