import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { ButtonSmall } from "../Buttons";
import { SoonHorizontal } from "../SoonHorizontal";
import styles from "./ButtonIconXlong.module.css";
import { ButtonType, SmallButtonColor,  } from "../Buttons/AllButtonProps";


interface ButtonIconXlongProps {
  headerText: string;
  bodyText: string;
  imgSrc: string;
  comingSoon?: boolean; // Mark as optional
  buttonClick?: () => void;
}

const ButtonIconXlong: React.FC<ButtonIconXlongProps> = ({
  headerText,
  bodyText,
  imgSrc,
  comingSoon = false, // Default value
  buttonClick,
}) => {
  return (
    <Stack className={styles["button-icon-xlong"]} role="region" aria-label={headerText}>
      <Stack
        className={styles["button-icon-xlong__content"]}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          className={styles["button-icon-xlong__text-group"]}
          direction="row"
          gap={1.2}
          alignItems="center"
        >
          <Box
            className={styles["button-icon-xlong__image"]}
            sx={{
              backgroundImage: `url(${imgSrc})`,
            }}
            role="img"
            aria-label={headerText}
          />
          <Box>
            <Typography className={styles["button-icon-xlong__text"]}>
              {headerText}
            </Typography>
            <Typography className={styles["button-icon-xlong__body-text"]}>
              {bodyText}
            </Typography>
          </Box>
        </Stack>
        <Stack className={styles["button-icon-xlong__actions"]}>
          {comingSoon && <SoonHorizontal />}
          <ButtonSmall
            onClick={buttonClick}
            colorVarient={SmallButtonColor.Orange}
            label="Enable"
            loaderLogin={false}
            types={ButtonType.Button}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ButtonIconXlong;
