import { AuthState } from "../authTypes";

export const loginRequest = (state: AuthState) => {
    state.error = null;
};
export const optSendRequest = (state: AuthState) => {
    state.error = null;
};
export const optVerificationRequest = (state: AuthState) => {
    state.error = null;
};

export const companyAvailibityRequest = (state: AuthState) => {
    state.error = null;
    state.companyAvailibility = null;
};

export const fetchCompanyListRequest = (state: AuthState) => {
    state.error = null;
};

export const getAllDepartmentsRequest = (state: AuthState) => {
    state.error = null;
};

export const tempAuthStoreRequest = (state: AuthState) => {
    state.email_temp = "";
    state.password_temp = "";
    state.timestamp_store_at = "";
};

export const RESET_OPT_SEND = 'auth/resetOptSend';
export const RESET_OPT_VERIFY = 'auth/resetOptVerify';

export const logoutRequest = (state: AuthState) => {
    state.isAuthenticated = false;
    state.user = null;
    state.error = null;
};

export const signUpRequest = (state: AuthState) => {
    state.error = null;
}

export const googleAuthRequest = (state: AuthState) => {
    state.externalEntities = { type: "GoogleAuth" };
    state.eError = null;
    state.error = null;
    state.eData = null;
};
export const microsoftAuthRequest = (state: AuthState) => {
    state.externalEntities = { type: "MicrosoftAuth" };
    state.eError = null;
    state.error = null;
    state.eData = null;
};
export const forgotPasswordRequest = (state: AuthState) => { state.error = null; };

export const getAllUsersRequest = (state: AuthState) => { state.error = null;
    state.usersList = [];
 };