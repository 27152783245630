import { Box } from "@mui/material";
import React from "react";
import { ColoredBadge } from "../../../../../common";
import {
  LasTTouchPointIcon,
  RelationshipStrengthIcon,
} from "../../../../../../images";

// Define types for badge data
interface BadgeData {
  title: string;
  icon?: React.ReactNode;
  imageUrl?: string;
}

// Centralized badge data
const badgeData: BadgeData[] = [
  {
    title: "Relationship Strength",
    icon: <RelationshipStrengthIcon />,
  },
  {
    title: "Last touchpoint",
    icon: <LasTTouchPointIcon />,
  },
  {
    title: "Roronoa Zoro",
    imageUrl:
      "https://i.pinimg.com/736x/c5/a9/68/c5a968eb0a92b427ca26646cf55526bb.jpg",
  },
];

const ReactangleHeaderOverView: React.FC = () => {
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: 4,
        background: "#e9edf2b0 0% 0% no-repeat padding-box",
        borderRadius: "0px 0px 5px 5px",
        padding: "10px 15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "20px",
      }}
    >
      {badgeData.map((badge, index) => (
        <ColoredBadge
          key={index}
          title={badge.title}
          bgColor="" // Default bgColor or pass a value if needed
          showIcon={!!badge.icon || !!badge.imageUrl} // Ensure showIcon is boolean
          icon={badge.icon}
          imageUrl={badge.imageUrl}
        />
      ))}
    </Box>
  );
};

export default ReactangleHeaderOverView;
