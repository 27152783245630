import { Box, Stack } from "@mui/material";
import React, { useState, FC } from "react";
import { SearchBar } from "../SearchBar";
import { ColoredBadge } from "../../../../../../common";
import g from "./CategoryMenu.module.css";
import { adjustHexColor } from "../../../../../../../Utils";

// Define the types for the badge data
type BadgeData = {
  id: string;
  title: string;
  bgColor: string;
};

type CategoryMenuProps = {
  badges?: BadgeData[];
  onBadgeSelect?: (id: string) => void;
};

// Example of predefined data (to be replaced by API data in the future)
const predefinedBadges: BadgeData[] = [
  { id: "1", title: "random category 1", bgColor: "green" },
  { id: "2", title: "random category 2", bgColor: "blue" },
  { id: "3", title: "random category 3", bgColor: "red" },
];

const CategoryMenu: FC<CategoryMenuProps> = ({
  badges = predefinedBadges,
  onBadgeSelect,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredBadges = badges.filter((badge) =>
    badge.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box className={g.CategoryBox}>
      <Stack p={2}>
        <SearchBar borderLine={false} onSearch={setSearchTerm} />

        <Stack
          minHeight={250}
          maxHeight={280}
          sx={{
            overflowX: "hidden",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none", // for Internet Explorer and Edge
            scrollbarWidth: "none", // for Firefox
          }}
          mt={2.5}
          direction={"column"}
          gap={0.5}
        >
          {filteredBadges.map((badge) => (
            <Box
              key={badge.id}
              onClick={() => onBadgeSelect && onBadgeSelect(badge.id)}
              sx={{
                cursor: "pointer",
                padding: 1,
                borderRadius: 1,
                "&:hover": {
                  backgroundColor: adjustHexColor(badge.bgColor, 10),
                },
              }}
            >
              <ColoredBadge
                showIcon={false}
                title={badge.title}
                bgColor={adjustHexColor(badge.bgColor, 50)}
              />
            </Box>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};
export default CategoryMenu;
