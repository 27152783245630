// @flow
import React, { useEffect, useRef, useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Drawer, IconButton, Stack, Typography } from "@mui/material";
import { adjustHexColor } from "../../../../../Utils";
import { BoardCardDisplay } from "../../components/BoardCard/BoardCardDisplay";
import styles from "../../components/BoardCard/BoardCard.module.css";
import { PlusIcon } from "../../../../../images";
import { WorkspaceBoardTitleHeader } from "../../../../common";
import { ObjectiveAndTaskLargeModel } from "../../components/ObjectiveAndTaskLargeModel";
import { updateOneTask__api, UpdateTaskPayload } from "../../../../../redux";

interface userList {
  _id: string;
  firstName: string;
  lastName: string;
  avatar: string;
}
interface Item {
  id: number;
  text: string;
  priority: { value: string; defaultColorHex: string; customColorHex: string }; // Update type
  status: { value: string; defaultColorHex: string; customColorHex: string }; // Update type
  dueDate: string;
  assignedTo: userList[]; // SHOWN BY _ID
  taskType: string; // Task, Milestone, Bugs, Campaign, Update, Feature, Research, Review, Approval, Documentation and testing
  color: string;
  estimatedTime: any;
  detail?: string;
}

interface StackData {
  id: number;
  name: string;
  items: Item[];
  color: string;
  collapsed?: boolean;
}
type Params = Record<string, string | undefined>;

// Map the filterKey to the correct keys in `Item`
const filterKeyMap: Record<string, keyof Item> = {
  Status: "status",
  Priority: "priority",
  Assignedto: "assignedTo", // Correct key is "assignedTo"
  DueDate: "dueDate", // Correct key is "dueDate"
  Tasktype: "taskType", // Correct key is "taskType"
};

interface DragAndDropColumnsProps {
  filterKey?: "Status" | "Priority" | "Assignedto" | "DueDate" | "Tasktype";
  workspaceItemData?: any;
  taskWorkspaceItemData?: any;
  setTaskWorkspaceItemData?: any;
  fetchComponentData?: any;
} // Define color mappings based on filter key (fallback colors)
const colorMapping: { [key: string]: { [value: string]: string } } = {
  status: {
    "To Do": "#808080",
    "In Progress": "#FFA500",
    Delayed: "#FF0000",
    Done: "#008000",
  },
  priority: {
    Low: "#00FF00",
    Medium: "#FFFF00",
    High: "#FFA500",
    Critical: "#FF0000",
    Urgent: "#FF4500",
  },
  // Add mappings for other filter keys like assignedTo, dueDate, taskType, etc.
};

const DragAndDropColumns: React.FC<DragAndDropColumnsProps> = ({
  workspaceItemData,
  taskWorkspaceItemData,
  setTaskWorkspaceItemData,
  fetchComponentData,
}) => {
  const { workspaceId } = useParams<Params>();
  const dispatch = useDispatch();
  // Extract filterKey from the URL query parameters
  const [filterKey, setFilterKey] = React.useState<string>("Status");

  const [stacks, setStacks] = useState<StackData[]>([]); // Start with an empty array

  const [searchParams] = useSearchParams();
  useEffect(() => {
    const newFilterKey = searchParams.get("filterkey");
    setFilterKey(newFilterKey || "Status");
  }, [searchParams]);

  useEffect(() => {
    if (taskWorkspaceItemData && taskWorkspaceItemData.length > 0) {
      console.log(taskWorkspaceItemData, "taskWorkspaceItemData");

      // Convert taskWorkspaceItemData to StackData format
      const initialStacks = taskWorkspaceItemData.map(
        (group: any, index: number) => ({
          id: index,
          name: group.group,
          color: group.tasks[0]?.cardColor || "#808080",
          items: group.tasks.map((task: any) => {
            // Convert estimatedTime to hours and minutes
            const estimatedMinutes = task.estimatedTime || 0;
            const hours = Math.floor(estimatedMinutes / 60);
            const minutes = estimatedMinutes % 60;

            return {
              id: task._id,
              text: task.name,
              priority: task.priority, // Keeping the object
              detail: task.details,
              status: task.status, // Keeping the object
              dueDate: task.dueDate,
              assignedTo: task.assignedUsers.map((user: any) => ({
                _id: user._id,
                firstName: user.firstName,
                lastName: user.lastName,
                avatar: user.avatar,
              })),
              taskType: task.taskType,
              color: task.cardColor || "#808080",
              // Add estimatedTime in hours and minutes
              estimatedTime: {
                hours,
                minutes,
              },
            };
          }),
          collapsed: false,
        })
      );

      setStacks(initialStacks);
    } else {
      setStacks([]);
    }
  }, [taskWorkspaceItemData]);

  /**
   * Generates the updated data payload from the current stack state.
   * @param stack - The current stack state containing tasks to be updated.
   * @returns An array of UpdateTaskPayload objects for each task.
   */
  const getUpdatedTaskPayloads = (stack: Array<any>): UpdateTaskPayload[] => {
    return stack.map((task) => {
      // Extract estimated time in minutes
      const estimatedTime = task.estimatedTime
        ? task.estimatedTime.hours * 60 + task.estimatedTime.minutes
        : undefined;

      // Construct the UpdateTaskPayload for the current task
      const updatePayload: UpdateTaskPayload = {
        taskId: task.id,
        name: task.text,
        details: task?.detail || "",
        assignedUserIds: task.assignedTo.map((user: any) => user._id),
        priority: task.priority
          ? {
              id: task.priority.id,
              value: task.priority.value,
              defaultColorHex: task.priority.defaultColorHex,
              customColorHex: task.priority.customColorHex,
            }
          : undefined,
        dueDate: task.dueDate,
        status: task.status
          ? {
              id: task.status.id,
              value: task.status.value,
              defaultColorHex: task.status.defaultColorHex,
              customColorHex: task.status.customColorHex,
            }
          : undefined,
        taskColor: task.color,
        estimatedTime: estimatedTime,
        taskType: task.taskType,
        // Additional fields like customElements and checklists can be ignored as per the requirement
      };

      return updatePayload;
    });
  };

  /**
   * Updates a specific task in the stack with new data and calls handleUpdateTask.
   * @param stack - The current stack state containing tasks.
   * @param taskId - The ID of the task to update.
   * @param field - The field to update ('text', 'status', 'priority', 'assignedTo', 'dueDate', 'estimatedTime').
   * @param value - The new value for the specified field.
   */
  const updateStackData = async (
    taskId: string,
    field:
      | "text"
      | "taskType"
      | "cardColor"
      | "detail"
      | "emoji"
      | "status"
      | "priority"
      | "assignedTo"
      | "dueDate"
      | "r"
      | "estimatedTime",
    value: any
  ): Promise<void> => {
    const updatedStack = stacks.map((stack: any) => {
      // Iterate through each column
      const updatedItems = stack.items.map((task: any) => {
        // Find the task to update
        if (task.id === taskId) {
          // Create a shallow copy of the task
          let updatedTask = { ...task };

          // Update the specified field with the new value
          switch (field) {
            case "text":
              updatedTask.text = value;
              break;
            case "detail":
              updatedTask.detail = value;
              break;
            case "taskType":
              updatedTask.taskType = value;
              break;
            case "cardColor":
              updatedTask.taskColor = value;
              break;
            case "emoji":
              updatedTask.emoji = value;
              break;
            case "status":
              updatedTask.status = {
                ...updatedTask.status,
                ...value, // Assuming value is an object containing new status properties
              };
              break;
            case "priority":
              updatedTask.priority = {
                ...updatedTask.priority,
                ...value, // Assuming value is an object containing new priority properties
              };
              break;
            case "assignedTo":
              updatedTask.assignedTo = value.map((user: any) => ({ ...user })); // Assuming value is an array of user objects
              break;
            case "dueDate":
              updatedTask.dueDate = value; // Assuming value is a date string
              break;
            case "estimatedTime":
              // Assuming value is an object containing hours and minutes
              updatedTask.estimatedTime = {
                hours: value.hours,
                minutes: value.minutes,
              };
              break;
            default:
              break;
          }

          // Call handleUpdateTask with updated task
          handleUpdateTask(updatedTask);

          return updatedTask; // Return the updated task
        }

        return task; // Return unchanged tasks
      });

      return { ...stack, items: updatedItems }; // Return updated column with updated tasks
    });
    if (field === "r") {
      return;
    }
    // Update the stacks state with the modified stack
    setStacks(updatedStack);
  };

  const handleUpdateTask = async (task: UpdateTaskPayload) => {
    const payload = getUpdatedTaskPayloads([task])[0]; // Prepare payload using the helper function

    try {
      const action = updateOneTask__api(workspaceItemData?._id, payload);
      await dispatch(action);

      // await fetchComponentData();
    } catch (error) {
      console.error("Failed to update task:", error);
    }
  };
  useEffect(() => {
    if (stacks.length > 0) {
      const validFilterKey = filterKeyMap[filterKey] || "status"; // Default to "status"
      const newStacks = groupTasksByFilterKey(validFilterKey);
      setStacks(newStacks);
    }
  }, [filterKey]);

  // Helper function to get color, checking for custom colors first
  const getColorForGroup = (
    filterKey: keyof Item,
    groupValue: string,
    tasksInGroup: Item[]
  ): string => {
    const defaultColor = "#808080"; // Default fallback color

    // Check if any task in the group has a custom color
    const customColor = tasksInGroup.find((task) => task.color)?.color;
    if (customColor) {
      return customColor; // Use custom color if found
    }

    // Fallback to predefined colors
    return colorMapping[filterKey]?.[groupValue] || defaultColor;
  };

  // Helper function to get a valid group key (handles array values like 'assignedTo')
  const getGroupKey = (value: any): string => {
    if (Array.isArray(value)) {
      // For arrays like 'assignedTo', join the IDs or names as a string
      return value.map((user: userList) => user._id).join(",");
    }
    return String(value); // For other types, convert to string
  };

  // Helper function to group tasks based on the selected filter key
  // Helper function to group tasks based on the selected filter key
  const groupTasksByFilterKey = (key: keyof Item): StackData[] => {
    const groupedStacks: { [key: string]: Item[] } = {};

    // Iterate through each stack and each task, then group them by the selected filter key
    stacks?.forEach((stack) => {
      stack?.items?.forEach((task) => {
        let groupByValue;

        // Dynamically group by the key (status, priority, etc.)
        if (key === "status" && typeof task.status === "object") {
          groupByValue = task.status.value; // Accessing value for status
        } else if (key === "priority" && typeof task.priority === "object") {
          groupByValue = task.priority.value; // Accessing value for priority
        } else {
          groupByValue = task[key]; // For other keys
        }

        const groupKey = getGroupKey(groupByValue); // Get a valid key for grouping

        if (!groupedStacks?.[groupKey]) {
          groupedStacks[groupKey] = [];
        }
        groupedStacks?.[groupKey]?.push(task);
      });
    });

    // Now transform the groupedStacks into the StackData format for rendering
    return Object?.keys(groupedStacks)?.map((group, index) => ({
      id: index,
      name: group,
      color: getColorForGroup(key, group, groupedStacks[group]), // Dynamically get color, custom color prioritized
      items: groupedStacks[group],
      collapsed: false, // default collapsed state
    }));
  };

  const modalRef = useRef<HTMLDivElement>(null); // Adjust this ref to your specific case
  const onDragEnd = (result: DropResult) => {
    const { destination, source, type } = result;

    if (!destination) {
      console.log("No destination found, dropping outside the list.");
      return;
    }

    if (type === "COLUMN") {
      // Handle column reordering
      const newStacks = Array.from(stacks);
      const [movedColumn] = newStacks.splice(source.index, 1);
      newStacks.splice(destination.index, 0, movedColumn);
      setStacks(newStacks);
    } else {
      // Handle item reordering or moving between columns
      const startColumnIndex = parseInt(source.droppableId);
      const endColumnIndex = parseInt(destination.droppableId);
      const startColumn = stacks[startColumnIndex];
      const endColumn = stacks[endColumnIndex];

      const startItems = Array.from(startColumn.items);
      const [movedItem] = startItems.splice(source.index, 1);
      const endItems = Array.from(endColumn.items);

      // Prepare the update object
      // let updateFields: Partial<Item> = {};

      // Update the moved item based on filterKey
      if (filterKey === "Assignedto") {
        // Find full user data in the destination column
        const assignedUserData = endColumn.items
          .flatMap((item) => item.assignedTo)
          .find((user) => user._id === endColumn.name);
        if (assignedUserData) {
          movedItem.assignedTo = [assignedUserData];
          // updateFields = { assignedTo: movedItem.assignedTo };
        } else {
          console.warn("Assigned user data not found in destination column.");
        }
      } else if (filterKey === "Status") {
        // Use the full status object from the end column
        const newStatus = endColumn.items[0]?.status || movedItem.status;
        if (newStatus) {
          movedItem.status = { ...newStatus };
          // updateFields = { status: movedItem.status };
        }
      } else if (filterKey === "Priority") {
        // Use the full priority object from the end column
        const newPriority = endColumn.items[0]?.priority || movedItem.priority;
        if (newPriority) {
          movedItem.priority = { ...newPriority };
          // updateFields = { priority: movedItem.priority };
        }
      }

      endItems.splice(destination.index, 0, movedItem);

      const newStacks = Array.from(stacks);
      newStacks[startColumnIndex] = { ...startColumn, items: startItems };
      newStacks[endColumnIndex] = { ...endColumn, items: endItems };
      setStacks(newStacks);
      const tid = movedItem.id.toString();
      updateStackData(tid, "r", "");
    }
  };

  // Function to toggle the collapsed state
  const toggleCollapse = (id: number) => {
    setStacks((prevStacks) =>
      prevStacks.map((stack) =>
        stack.id === id ? { ...stack, collapsed: !stack.collapsed } : stack
      )
    );
  };
  const getItemStyle = (
    isDragging: any,
    draggableStyle: any,
    modalRef: any
  ) => {
    const offset = modalRef.current
      ? modalRef.current.getBoundingClientRect().top
      : 0;
    return {
      ...draggableStyle,
      top: isDragging ? draggableStyle.top - offset : draggableStyle.top,
      left: "auto", // Reset left to prevent horizontal misalignment
    };
  };

  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const [objectTaskMode, setObjectTaskMode] = useState<string>("");
  const [objectTaskData, setObjectTaskData] = useState<any>([]);

  const handleFullView = (mode?: string, dataStack?: any) => {
    const finalMode = mode || "";
    const data = dataStack || {};
    setObjectTaskData(data);
    setObjectTaskMode(finalMode);
    toggleDrawer(open ? false : true)(); // Immediately calls the returned function
  };

  return (
    <div ref={modalRef}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="all-columns"
          direction="horizontal"
          type="COLUMN"
        >
          {(provided) => (
            <Stack
              ref={provided.innerRef}
              {...provided.droppableProps}
              direction="row"
              alignItems="flex-start"
              justifyContent="flex-start"
              width="100%"
              sx={{
                maxHeight: "45rem",
                // minHeight: "45rem",
                overflow: "auto",
                scrollbarWidth: "none", // For Firefox
                "&::-webkit-scrollbar": {
                  display: "none", // For Chrome, Safari, and Edge
                },
              }}
            >
              {stacks.map((stack, index) => (
                <Draggable
                  draggableId={`column-${stack.id}`}
                  index={index}
                  key={stack.id}
                >
                  {(provided) => (
                    <Stack
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      width={"255px"}
                      height="100%"
                      sx={{
                        margin: "10px",
                        bgcolor: adjustHexColor(stack.color, 25),
                        borderRadius: "5px",
                        rotate: stack.collapsed ? "90deg" : "0deg",
                        marginTop: stack.collapsed ? "44px" : "0px",
                      }}
                    >
                      <Stack
                        sx={{
                          bgcolor: adjustHexColor(stack.color, 25),
                          height: "40px",
                          padding: "0px 5px 0px 10px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "move",
                          borderRadius: "5px",
                        }}
                      >
                        <WorkspaceBoardTitleHeader
                          color={stack.color}
                          title={stack.name}
                          onClick={() => toggleCollapse(stack.id)}
                        />
                      </Stack>
                      {!stack.collapsed && (
                        <Droppable droppableId={`${index}`} type="ITEM">
                          {(provided, snapshot) => (
                            <Stack
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              // sx={{
                              //   minHeight: "215px",
                              // }}
                            >
                              <Stack
                                className={styles["board-card__body"]}
                                sx={{
                                  bgcolor: snapshot.isDraggingOver
                                    ? adjustHexColor(stack.color, 20)
                                    : adjustHexColor(stack.color, 25),
                                  minHeight: "215px",
                                }}
                                minWidth={"225px"}
                                direction={"column"}
                              >
                                {stack.items.map((item, itemIndex) => (
                                  <Draggable
                                    key={item.id}
                                    draggableId={`${item.id}`}
                                    index={itemIndex}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          ...provided.draggableProps.style,
                                          ...getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style,
                                            modalRef
                                          ),
                                          userSelect: "none",
                                          margin: "4px 0px",
                                          backgroundColor: snapshot.isDragging
                                            ? "#263B4A"
                                            : "#456C86",
                                          color: "white",
                                          borderRadius: "5px",
                                          border: snapshot.isDragging
                                            ? "2px solid lightgreen"
                                            : "none",
                                        }}
                                      >
                                        <BoardCardDisplay
                                          selectedColor={stack.color}
                                          titleMain={stack.name}
                                          title={item.text}
                                          handleFullView={handleFullView}
                                          stack={item}
                                          updateStackData={updateStackData}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              </Stack>

                              {provided.placeholder}
                              {/* footer >> Add button here */}
                              <Stack
                                className={styles["board-card__footer"]}
                                sx={{
                                  bgcolor: adjustHexColor(stack.color, 25),
                                }}
                              >
                                <IconButton
                                  disableTouchRipple
                                  sx={{
                                    ":hover": {
                                      bgcolor: adjustHexColor("#fff", 29),
                                    },
                                  }}
                                  onClick={() => handleFullView("add")}
                                  className={
                                    styles["board-card__footer-button"]
                                  }
                                >
                                  <PlusIcon w={10} />
                                  <Typography
                                    sx={{
                                      font: "normal normal normal 14px/20px Source Serif Pro",
                                      color: "black",
                                    }}
                                  >
                                    Add task
                                  </Typography>
                                </IconButton>
                              </Stack>
                            </Stack>
                          )}
                        </Droppable>
                      )}
                    </Stack>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
      <div>
        <React.Fragment>
          <Drawer
            anchor="right" // This makes the drawer open from the right
            open={open}
            ModalProps={{ hideBackdrop: true }} // Completely removes the backdrop
            SlideProps={{
              timeout: {
                enter: 200, // Duration in milliseconds for opening
                exit: 200, // Duration in milliseconds for closing
              },
            }}
          >
            <ObjectiveAndTaskLargeModel
              mode={(objectTaskMode as "add" | "view") || "add"}
              toggleDrawer={handleFullView}
              workspaceItemData={workspaceItemData}
              objectTaskData={objectTaskData}
              updateStackData={updateStackData}
            />
          </Drawer>
        </React.Fragment>
      </div>
    </div>
  );
};

export default DragAndDropColumns;
