import React from "react";
import { LargeInputBox } from "../../../common";
import { FirstLoginData, FirstLoginDataProps } from "./StepsType";
import { Stack } from "@mui/material";

interface StepThreeProps extends FirstLoginDataProps {
  firstLoginData: FirstLoginData;
  setFirstLoginData: React.Dispatch<React.SetStateAction<FirstLoginData>>;
}

const StepThree: React.FC<StepThreeProps> = ({
  firstLoginData,
  setFirstLoginData,
}) => {
  return (
    <Stack width={"100%"} mb={"20px"}>
      <LargeInputBox
        label={`
        Project Name`}
        borderColor="black"
        value={firstLoginData?.projectName}
        autoComplete="off"
        onChange={(e) => {
          const value = typeof e === "string" ? e : e.target.value;
          setFirstLoginData({
            ...firstLoginData,
            projectName: value,
          });
        }}
      />
    </Stack>
  );
};

export default StepThree;
