import React, { useState, MouseEvent } from "react";
import { Stack, Tooltip, Popover, IconButton, Box } from "@mui/material";
import { ChevronIcon, ColorIcon } from "../../../images";
import { SketchPicker } from "react-color";

interface SmallColorPickerProps {
  onSelect: (color: string) => void;
}

const colorOptions: { colorName: string; colorvalue: string }[] = [
  { colorName: "Blue", colorvalue: "#0027FF" },
  { colorName: "Mid Green", colorvalue: "#21B481" },
  { colorName: "Red", colorvalue: "#FF0000" },
  { colorName: "Mid Yellow", colorvalue: "#E0D700" },
  { colorName: "Light Orange", colorvalue: "#FFB100" },
  { colorName: "Message Purple", colorvalue: "#775EE2" },
  { colorName: "Workspace Dark Oragne", colorvalue: "#A53A01" },
  { colorName: "Calendar Marron Pink", colorvalue: "#B5005B" },
  { colorName: "Black", colorvalue: "#000000" },
  { colorName: "Cancelled", colorvalue: "#757575" },
  { colorName: "White", colorvalue: "#FFFFFF" },
];

const SmallColorPicker: React.FC<SmallColorPickerProps> = ({ onSelect }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showMoreClr, setShowMoreClr] = useState(false);

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleColorSelect = (color: string) => {
    onSelect(color);
    handlePopoverClose();
  };

  return (
    <>
      <Tooltip title="Color Picker" placement="top" arrow>
        <Stack
          onClick={handlePopoverOpen}
          sx={{
            width: "35px",
            height: "15px",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <ColorIcon w={15} />
          <ChevronIcon w={15} direction={open ? "down" : "up"} />
        </Stack>
      </Tooltip>

      <Popover
        open={open}container={ document && document?.getElementById("full-ScreenMode")}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ marginTop: "10px" }}
        PaperProps={{
          style: { boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)" },
        }}
      >
        <Stack>
          <Stack
            sx={{
              padding: "10px",
              gap: "10px",
              boxSizing: "border-box",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Stack
              sx={{
                width: "265px",
                flexDirection: "row",
                flexWrap: "wrap",
                overflow: "hidden",
                gap: "10px 10px",
                alignItems: "start",
                // manage height from here
                // height: "15px",
              }}
            >
              {colorOptions.map((clr, index) => (
                <Box
                  key={index}
                  component={"span"}
                  sx={{
                    height: "15px",
                    width: "15px",
                    borderRadius: "5px",
                    bgcolor: clr.colorvalue,
                    boxSizing: "border-box",
                    border:
                      clr.colorvalue === "#FFFFFF"
                        ? "0.5px solid #E9EDF2"
                        : "none",
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "scale(1.1)",
                    },
                    cursor: "pointer",
                  }}
                  onClick={() => handleColorSelect(clr.colorvalue)}
                />
              ))}
            </Stack>
            <Stack>
              <Tooltip
                title={showMoreClr ? "Collaps" : "Expand"}
                placement="top"
                arrow
              >
                <IconButton
                  onClick={() => setShowMoreClr((value) => !value)}
                  disableRipple
                  sx={{
                    height: "15px",
                    width: "15px",
                    bgcolor: "#E9EDF2",
                    padding: "0px",
                    borderRadius: "5px",
                    ":hover": {
                      bgcolor: "#E9EDF2",
                    },
                  }}
                >
                  <ChevronIcon w={15} direction={showMoreClr ? "down" : "up"} />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
          <Stack sx={{ display: showMoreClr ? "block" : "none" }}>
            <SketchPicker onChange={(clr) => console.log(clr.hex)} />
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};

export default SmallColorPicker;
