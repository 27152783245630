import * as React from "react";
import {
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  InputBase,
  Tooltip,
  Typography,
} from "@mui/material";
import S from "./DropdownWithColor.module.css";
import { GreenDropdownIcon } from "../../../images";
import { DropdownWithColorOptionTypes } from "./DropdownWithColorTypes";
import { ColorPickerDot } from "../ColorPickerDot";

interface DropdownWithColorProps {
  options: DropdownWithColorOptionTypes[];
  placeholder?: string;
  width?: string;
  label?: string;
  onChange: (updatedOptions: DropdownWithColorOptionTypes[]) => void;
  onSelect: (selectedOption: DropdownWithColorOptionTypes) => void;
  initialValue?: DropdownWithColorOptionTypes;
}

const DropdownWithColor: React.FC<DropdownWithColorProps> = React.memo(
  ({
    options,
    placeholder,
    width = "125px",
    onChange,
    onSelect,
    label,
    initialValue,
    ...props
  }) => {
    const [selectedValue, setSelectedValue] = React.useState<string>(
      initialValue ? initialValue.value : ""
    );
    const [open, setOpen] = React.useState(false);
    const [localOptions, setLocalOptions] =
      React.useState<DropdownWithColorOptionTypes[]>(options);
    const [addingCustom, setAddingCustom] = React.useState(false);
    const [newCustomValue, setNewCustomValue] = React.useState("");

    const [defaultColor] = React.useState("#007BB2");
    const [newCustomColor, setNewCustomColor] = React.useState(defaultColor);

    React.useEffect(() => {
      if (initialValue) {
        setSelectedValue(initialValue.value);
      }
    }, [initialValue]);

    const handleChange = React.useCallback(
      (event: SelectChangeEvent<string>) => {
        const {
          target: { value },
        } = event;
        setSelectedValue(value);

        if (onSelect) {
          const selectedOption = localOptions.find(
            (option) => option.value === value
          );
          if (selectedOption) {
            onSelect(selectedOption);
          }
        }
      },
      [onSelect, localOptions]
    );

    const stopPropagation = (event: React.MouseEvent | React.KeyboardEvent) => {
      event.stopPropagation();
    };

    const handleAddCustomClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      setAddingCustom(true);
    };

    const handleSaveCustomClick = () => {
      if (newCustomValue?.trim() !== "") {
        const newCustomOption: DropdownWithColorOptionTypes = {
          id: localOptions.length + 1,
          value: newCustomValue,
          defaultColorHex: newCustomColor,
          customColorHex: newCustomColor,
        };
        const updatedOptions = [...localOptions, newCustomOption];
        setLocalOptions(updatedOptions);
        if (onChange) {
          onChange(updatedOptions);
        }
      }
      setAddingCustom(false);
      setNewCustomValue("");
      //   setNewCustomColor(newCustomColor);
    };

    const handleClose = () => {
      if (addingCustom && newCustomValue?.trim() !== "") {
        handleSaveCustomClick();
      }
      setAddingCustom(false);
      setOpen(false);
    };

    const handleColorChange = (id: number, color: string) => {
      const updatedOptions = localOptions?.map((option) =>
        option?.id === id ? { ...option, customColorHex: color } : option
      );
      setLocalOptions(updatedOptions);
      if (onChange) {
        onChange(updatedOptions);
      }
    };

    const MenuProps = {
      PaperProps: {
        className: S["dropdown-with-color__menu"],
        style: { width: width },
      },
    };

    const getMaxWidth = (width: string) => {
      const widthValue = parseInt(width, 10);
      const maxWidth = widthValue - 48;
      return `${maxWidth}px`;
    };

    return (
      <div>
        <FormControl style={{ width }} {...props}>
          {label && (
            <Typography
              sx={{
                font: "normal normal 300 10px/13px Source Serif Pro",
                marginBottom: "5px",
              }}
            >
              {label}
            </Typography>
          )}
          <Select
            open={open}
            onClick={!open ? () => setOpen(true) : () => null}
            displayEmpty
            IconComponent={() => (
              <GreenDropdownIcon sx={{ height: "12px" }} open={open} />
            )}
            value={selectedValue}
            onChange={handleChange}
            input={
              <OutlinedInput
                className={S["dropdown-with-color__inputbox"]}
                classes={{
                  notchedOutline: S["dropdown-with-color__notchedOutline"],
                  focused: S["dropdown-with-color__focused"],
                }}
              />
            }
            onOpen={() => setOpen(true)}
            onClose={handleClose}
            renderValue={(selected) => {
              if (!selected) {
                return (
                  <em className={S["dropdown-with-color__selectPlaceholder"]}>
                    {placeholder}
                  </em>
                );
              }
              const selectedOption = localOptions.find(
                (option) => option.value === selected
              );
              return (
                <span className={S["dropdown-with-color__selected"]}>
                  <span
                    style={{
                      maxWidth: getMaxWidth(width),
                      paddingRight: "5px",
                      boxSizing: "border-box",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      display: "inline-block",
                    }}
                  >
                    {selected}
                  </span>
                  <span onMouseDown={stopPropagation} onClick={stopPropagation}>
                    {selectedOption && (
                      <ColorPickerDot
                        initialColor={selectedOption?.customColorHex}
                        onColorChange={(color) =>
                          handleColorChange(selectedOption?.id, color)
                        }
                      />
                    )}
                  </span>
                </span>
              );
            }}
            MenuProps={MenuProps}
          >
            {localOptions.map((item) => (
              <MenuItem
                className={S["dropdown-with-color__menu-item"]}
                key={item.id}
                value={item.value}
              >
                <ListItemText
                  primary={item.value}
                  primaryTypographyProps={{
                    className: S["dropdown-with-color__list-item-text"],
                  }}
                />
                <div onMouseDown={stopPropagation} onClick={stopPropagation}>
                  <ColorPickerDot
                    initialColor={item.customColorHex}
                    onColorChange={(color) => handleColorChange(item.id, color)}
                  />
                </div>
              </MenuItem>
            ))}
            {addingCustom ? (
              <Stack sx={{ padding: "5px 5px 5px 10px" }}>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  spacing={1}
                  alignItems="center"
                >
                  <InputBase
                    value={newCustomValue}
                    fullWidth
                    onChange={(e) => setNewCustomValue(e.target.value)}
                    placeholder="Add new..."
                    onMouseDown={stopPropagation}
                    onClick={stopPropagation}
                    onKeyDown={stopPropagation} // Prevent key events from propagating
                    onKeyUp={stopPropagation} // Prevent key events from propagating
                    sx={{
                      height: "20px",
                      borderRadius: "2px",
                      border: "0.5px solid #D9E3EF",
                      font: "normal normal 300 12px/16px Source Serif Pro",
                    }}
                  />
                  <div onMouseDown={stopPropagation} onClick={stopPropagation}>
                    <ColorPickerDot
                      initialColor={defaultColor}
                      onColorChange={(color) => setNewCustomColor(color)}
                    />
                  </div>
                </Stack>
              </Stack>
            ) : (
              <MenuItem className={S["dropdown-with-color__menu-item"]}>
                <Tooltip title="Add new" placement="right" arrow>
                  <ListItemText
                    onClick={(e) => handleAddCustomClick(e)}
                    primary="Add Custom"
                    primaryTypographyProps={{
                      className: S["dropdown-with-color__list-item-text"],
                    }}
                  />
                </Tooltip>
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </div>
    );
  }
);

export default DropdownWithColor;
