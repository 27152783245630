export enum LightCirclesColorEnum {
  White = "#ffffff",
  PartiallyPaid = "#FFEE58",
  LightGreen = "#A1FF9F",
  LightOrange = "#FFDCB2",
  CallsSalmon = "#FF8181",
  LightPurple = "#CFA0FF",
  LightPink = "#FFC6F2",
  LightTeal = "#CEF8FA",
}

export interface LightCirclesColorListInterface {
  colorvalue: LightCirclesColorEnum;
}

export const LightCirclesColorOptionsList: LightCirclesColorListInterface[] = [
  { colorvalue: LightCirclesColorEnum.White },
  { colorvalue: LightCirclesColorEnum.PartiallyPaid },
  { colorvalue: LightCirclesColorEnum.LightGreen },
  { colorvalue: LightCirclesColorEnum.LightOrange },
  { colorvalue: LightCirclesColorEnum.CallsSalmon },
  { colorvalue: LightCirclesColorEnum.LightPurple },
  { colorvalue: LightCirclesColorEnum.LightPink },
  { colorvalue: LightCirclesColorEnum.LightTeal },
];
