import { PayloadAction } from "@reduxjs/toolkit";
import { WorkspaceState } from "../workspaceTypes";

export const createWorkspace__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const getAllWorkspace__Request = (state: WorkspaceState) => {
  state.error = null;
  state.workspaceList.is_api_called = true;
  state.workspaceList.api_call_timeStamp = new Date().toISOString();
};

export const getAllSharedWorkspace__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const getOneWorkspace__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const updateWorkspace__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const deleteWorkspace__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const addWorkspaceContent__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const reOrderWorkspaceContent__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const updateWorkspaceContent__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const deleteWorkspaceContent__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const addWorkspaceMember__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const getAllWorkspaceMember__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const updateWorkspaceMember__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const deleteWorkspaceMember__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const addWorkspaceCustomField__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const updateWorkspaceCustomField__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const deleteWorkspaceCustomField__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const addWorkspaceTag__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const updateWorkspaceTag__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const deleteWorkspaceTag__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const addWorkspaceTemplate__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const applyWorkspaceTemplate__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const addWorkspaceIntegration__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const updateWorkspaceIntegration__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const deleteWorkspaceIntegration__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const addWorkspaceActivity__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const getAllWorkspaceActivity__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const updateWorkspaceColumn__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const addWorkspaceConnection__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const addWorkspaceReminder__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const addWorkspaceVideoReport__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const addWorkspaceFile__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const addWorkspaceMedia__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const addWorkspaceBookMark__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const addWorkspaceTableOfContent__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const addWorkspaceEquation__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const addWorkspaceButton__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const addWorkspaceSyncElement__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const addWorkspaceHeadingToggle__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const addWorkspaceCodeBlock__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const deleteWorkspaceInBulk__Request = (state: WorkspaceState) => {
  state.error = null;
};

/* Workspace Items */
export const addGrid__Request = (state: WorkspaceState) => {
  state.error = null;
};

// Get All Grids
export const getAllGrids__Request = (state: WorkspaceState) => {
  state.error = null;
};

// Get One Grid
export const getOneGrid__Request = (state: WorkspaceState) => {
  state.error = null;
};
// Update One Grid
export const updateOneGrid__Request = (state: WorkspaceState) => {
  state.error = null;
};

// Delete Grid
export const deleteGrid__Request = (state: WorkspaceState) => {
  state.error = null;
};
// Add Sheet
export const addSheet__Request = (state: WorkspaceState) => {
  state.error = null;
};
// Update Sheet
export const updateSheet__Request = (state: WorkspaceState) => {
  state.error = null;
};
// Delete Sheet
export const deleteSheet__Request = (state: WorkspaceState) => {
  state.error = null;
};
// Reorder Sheets
export const reorderSheets__Request = (state: WorkspaceState) => {
  state.error = null;
};
// Update Cell
export const updateCell__Request = (state: WorkspaceState) => {
  state.error = null;
};
export const addComponent__Request = (state: WorkspaceState) => {
  state.error = null;
};
export const getAllComponents__Request = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.error = null;
};
// Similarly, apply this pattern to other reducers

export const getOneComponent__Request = (state: WorkspaceState) => {
  state.error = null;
  state.insideWorkspaceComponentData = null;
};
export const updateOneComponent__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const deleteOneComponent__Request = (state: WorkspaceState) => {
  state.error = null;
};
export const deleteBulkComponents__Request = (state: WorkspaceState) => {
  state.error = null;
};
// Create Task
export const createTask__Request = (state: WorkspaceState) => {
  state.error = null;
};

// Get One Task
export const getOneTask__Request = (state: WorkspaceState) => {
  state.error = null;
};

// Update One Task
export const updateOneTask__Request = (state: WorkspaceState) => {
  state.error = null;
};

// Delete One Task
export const deleteOneTask__Request = (state: WorkspaceState) => {
  state.error = null;
};

// Get All Tasks
export const getAllTasks__Request = (state: WorkspaceState) => {
  state.error = null;
};

// Create Sub Task
export const createSubTask__Request = (state: WorkspaceState) => {
  state.error = null;
};

// Get All Sub Tasks
export const getAllSubTasks__Request = (state: WorkspaceState) => {
  state.error = null;
};

// Delete Bulk Tasks
export const deleteBulkTasks__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const AddWhiteboard__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const GetAllWhiteboards__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const GetOneWhiteboard__Request = (state: WorkspaceState) => {
  state.insideWorkspaceComponentData = null;
  state.error = null;
};

export const UpdateWhiteboard__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const DeleteWhiteboard__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const AddObject__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const UpdateObject__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const DeleteObject__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const AddCollaborator__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const GetAllCollaborators__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const UpdateCollaborator__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const DeleteCollaborator__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const CreateVersion__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const GetAllVersions__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const RestoreVersion__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const addDocs__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const getAllDocs__Request = (state: WorkspaceState) => {
  state.error = null;
};

export const getOneDoc__Request = (state: WorkspaceState) => {
  state.error = null;
  state.insideWorkspaceComponentData = null;
};

export const updateOneDoc__Request = (state: WorkspaceState) => {
  state.error = null;
};

// Add Wiki Request
export const AddWiki__Request = (state: WorkspaceState) => {
  state.error = null;
};

// Get All Wikis Request
export const GetAllWikis__Request = (state: WorkspaceState) => {
  state.error = null;
};

// Get One Wiki Request
export const GetOneWiki__Request = (state: WorkspaceState) => {
  state.error = null;
};

// Add Wiki Page Request
export const AddWikiPage__Request = (state: WorkspaceState) => {
  state.error = null;
};

// Get Wiki Page Request
export const GetWikiPage__Request = (state: WorkspaceState) => {
  state.error = null;
};

// Update Wiki Page Request
export const UpdateWikiPage__Request = (state: WorkspaceState) => {
  state.error = null;
};

// Add Component View Request
export const AddComponentView__Request = (state: WorkspaceState) => {
  state.error = null;
};
