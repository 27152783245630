import { Box, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import {
  AvatarLarge,
  AvatarSize,
  BgColorCode,
  SmallUnfilledDropdown,
} from "../../../../../../common";
import { CrossIcon } from "../../../../../../../images";
type SharedPersonListsProps = {
  connection?: {
    userId: string;
    text: string;
    connectionType?: string;
  };
};
const SharedPersonLists: React.FC<SharedPersonListsProps> = ({
  connection,
}) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        height: "37px",
        minHeight: "37px",
      }}
    >
      {/* user avatar and their email-id container here */}
      <Stack direction={"row"} alignItems={"center"} gap={"15px"}>
        <Box position="relative">
          <AvatarLarge
            bgColorCode={BgColorCode.Green}
            size={AvatarSize.Small}
            text="P"
          />
          <Tooltip placement="top" arrow title="remove">
            <Box
              onClick={() => console.log("first")}
              component={"span"}
              sx={{
                cursor: "pointer",
                position: "absolute",
                top: "50%",
                left: "100%",
                transform: "translate(-110%, -170%)",
                zIndex: 10,
                height: "10px",
                width: "10px",
                borderRadius: "10px",
                backgroundColor: "#BCBCBC",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CrossIcon w={10} />
            </Box>
          </Tooltip>
        </Box>
        <Typography
          sx={{
            font: "normal normal 300 12px/16px Source Serif Pro",
            opacity: 0.5,
          }}
        >
          {(connection && connection.text) || "Undefined"}
        </Typography>
      </Stack>

      {/* dropdown menu */}
      <SmallUnfilledDropdown
        componentHeader="View"
        options={["can view", "can edit", "can comment"]}
      />
    </Stack>
  );
};

export default SharedPersonLists;
