import React, { useState } from "react";
import { Button, Box } from "@mui/material";
import styles from "./DayPickerlong.module.css";

const WEEK_DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const WeekdaySelector: React.FC = () => {
  const [selectedDays, setSelectedDays] = useState<string[]>([]);

  const toggleDay = (day: string) => {
    setSelectedDays((prevSelectedDays) =>
      prevSelectedDays.includes(day)
        ? prevSelectedDays.filter((d) => d !== day)
        : [...prevSelectedDays, day]
    );
  };

  return (
    <Box className={styles["weekday-selector"]}>
      {WEEK_DAYS.map((day, index) => (
        <Button
          disableElevation
          disableFocusRipple
          disableTouchRipple
          disableRipple
          key={index}
          variant="contained"
          onClick={() => toggleDay(day)}
          className={`${styles["weekday-selector__button"]} ${selectedDays.includes(day) ? styles["weekday-selector__button--selected"] : ""}`}
        >
          {day}
        </Button>
      ))}
    </Box>
  );
};

export default WeekdaySelector;
