import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { LargeInputBox } from "../LargeInputBox";
import { ButtonXLarge } from "../Buttons";
import { ButtonXLargeColor } from "../Buttons/AllButtonProps";
import { CloseIcon } from "../../../images";
import styles from "./PopupLarge.module.css";
import { PopUpProps } from "./popupTypes";

const IpRestrictionsPopup: React.FC<PopUpProps> = ({ handleClosePopup }) => {
  const [ipAddress, setIpAddress] = React.useState<string>("");

  const closeHandler = () => {
    console.log("close icon clicked");
    handleClosePopup?.(null);
  };

  console.log("I am visible");

  return (
    <Stack className={styles.popupLarge}>
      <Box
        component="span"
        className={styles.popupLarge__closeIcon}
        onClick={() => closeHandler()}
      >
        <CloseIcon />
      </Box>

      <Typography className={styles.popupLarge__title}>
        Ip restrictions
      </Typography>
      <Typography className={styles.popupLarge__subtitle}>
        Add custom ip address to limit access to this account
      </Typography>
      <Typography className={styles.popupLarge__email}>
        johnsmith@bearishfh.com
      </Typography>
      <Stack className={styles.popupLarge__content}>
        <LargeInputBox
          borderColor="black"
          label="Add ip"
          value={ipAddress}
          onChange={(e) => {
            const value = typeof e === "string" ? e : e.target.value;
            setIpAddress(value);
          }}
        />
        <ButtonXLarge label="Save" colorVarient={ButtonXLargeColor.Green} />
        <ButtonXLarge label="Close" colorVarient={ButtonXLargeColor.Orange} />
      </Stack>
    </Stack>
  );
};

export default IpRestrictionsPopup;
