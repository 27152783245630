import { Box, Button, Drawer } from "@mui/material";
import React, { useState, useCallback } from "react";
import { PlusIcon } from "../../../images";
import { ObjectiveAndTaskLargeModel } from "../../pages/Workspace/components/ObjectiveAndTaskLargeModel";

// Styling moved outside the component to avoid recreation on each render
const buttonStyles = {
  display: "flex",
  minWidth: "none",
  background: "none",
  border: "none",
  outline: "none",
  color: "black",
  textTransform: "capitalize",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "10px",
  fontFamily: "Source Serif Pro",
  fontWeight: 300,
  fontSize: "14px",
  lineHeight: "17.54px",
};

interface AddTaskProps {
  workspaceItemData?:any;
  fetchComponentData?: (justTask: boolean) => void;
}

const AddTask: React.FC<AddTaskProps> = ({workspaceItemData, fetchComponentData}) => {
  const [open, setOpen] = useState(false);

  // Memoize toggleDrawer function to prevent unnecessary re-renders
  const toggleDrawer = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  return (
    <Box>
      <Button sx={buttonStyles} onClick={toggleDrawer}>
        <PlusIcon />
        Add Task
      </Button>

      <Drawer
        anchor="right"
        open={open}
        ModalProps={{ hideBackdrop: true }}
        SlideProps={{
          timeout: {
            enter: 200,
            exit: 200,
          },
        }}
      >
        <ObjectiveAndTaskLargeModel fetchComponentData={fetchComponentData} workspaceItemData={workspaceItemData} mode="add" toggleDrawer={toggleDrawer} />
      </Drawer>
    </Box>
  );
};

export default AddTask;
