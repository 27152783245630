import React from "react";
import style from "./LoaderDualX.module.css";
type Props = {};

const LoaderDualX = (props: Props) => {
  return (
    <section>
      <div className={`${style["loader"]} ${style["loader-1"]}`}>
        <div className={style["loader-outter"]}></div>
        <div className={style["loader-inner"]}></div>
      </div>
    </section>
  );
};

export default LoaderDualX;
