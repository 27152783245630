// Enum for different types of activity
export enum ActivityTypeEnum {
  CompanyCreate = "CompanyCreate",
  DomainAddon = "DomainAddon",
  DomainDescriptionAddon = "DomainDescriptionAddon",
  LinksAddon = "LinksAddon",
  CategoriesAddon = "CategoriesAddon",
  LocationAddon = "LocationAddon",
  UserActionWithoutLink = "UserActionWithoutLink",
  UserActionWithLink = "UserActionWithLink",
  ContactActionWithLink = "ContactActionWithLink",
  MeetingSchedule = "MeetingSchedule",
}

// Interface for displaying user details in a list
interface UserDetails {
  readonly name: string;
  readonly imgSrc?: string;
  readonly avatarColor?: string;
}

// Base interface for activity props containing timestamp
interface TimeProps {
  readonly timestamp: string; // Changed to string as per user's preference
}

// Common interface for Activity Author
interface ActivityAuthor {
  readonly autherName: string;
  readonly autherIconSrc?: string;
}

// Interface for each activity type props
interface CompanyCreateProps extends TimeProps {
  readonly activityVarient: ActivityTypeEnum.CompanyCreate;
  readonly companyName: string;
  readonly companyLogoSrc: string;
  readonly userDetails: UserDetails;
}

interface DomainAddonProps extends TimeProps {
  readonly activityVarient: ActivityTypeEnum.DomainAddon;
  readonly domainUrl: string;
  readonly activityAuthor: ActivityAuthor;
}

interface DomainDescriptionAddonProps extends TimeProps {
  readonly activityVarient: ActivityTypeEnum.DomainDescriptionAddon;
  readonly companyName: string;
  readonly activityAuthor: ActivityAuthor;
}

interface LinksAddonProps extends TimeProps {
  readonly activityVarient: ActivityTypeEnum.LinksAddon;
  readonly links: {
    id: string | number;
    domainIconSrc: string;
    domainName: string;
    domainUrl: string;
  }[];
  readonly activityAuthor: ActivityAuthor;
}

interface CategoriesAddonProps extends TimeProps {
  readonly activityVarient: ActivityTypeEnum.CategoriesAddon;
  readonly categories: {
    categoryName: string;
    bgColor: string;
  }[];
  activityAuthor: ActivityAuthor;
}

interface LocationAddonProps extends TimeProps {
  readonly activityVarient: ActivityTypeEnum.LocationAddon;
  readonly locationType: string;
  activityAuthor: ActivityAuthor;
}

interface UserActionWithoutLinkProps extends TimeProps {
  readonly activityVarient: ActivityTypeEnum.UserActionWithoutLink;
  readonly userDetails: UserDetails;
  readonly action: string;
}

interface UserActionWithLinkProps extends TimeProps {
  readonly activityVarient: ActivityTypeEnum.UserActionWithLink;
  readonly userDetails: UserDetails;
  readonly action: string;
  readonly link: { linkName: string; linkUrl: string };
}

interface ContactActionWithLinkProps extends TimeProps {
  readonly activityVarient: ActivityTypeEnum.ContactActionWithLink;
  readonly userDetails: UserDetails;
  readonly action: string;
  readonly actionDescription: string;
  readonly link: { linkName: string; linkUrl: string };
}

interface MeetingScheduleProps extends TimeProps {
  readonly activityVarient: ActivityTypeEnum.MeetingSchedule;
  readonly userDetails: UserDetails;
  readonly meetingDetails: string;
  readonly meetingPlatform: { linkName: string; linkUrl: string };
}

// Union type for all activity tab props
export type ActivityTabProps =
  | CompanyCreateProps
  | DomainAddonProps
  | DomainDescriptionAddonProps
  | LinksAddonProps
  | CategoriesAddonProps
  | LocationAddonProps
  | UserActionWithoutLinkProps
  | UserActionWithLinkProps
  | ContactActionWithLinkProps
  | MeetingScheduleProps;
