import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";
import { CloudStorageState, setSelectedDrive } from "../../../../../../redux";
import { Stack, Typography } from "@mui/material";
import { getDriveIcon } from "../../../../../../Utils";
import { useCustomSnackbar } from "../../../../EnqueueSnackbar";

type Props = {};

const AvailableConnection = (props: Props) => {
  const dispatch = useDispatch();
  const enqueueSnackbar_v1 = useCustomSnackbar();
  const cloudStorageState = useSelector(
    (state: RootState) => state.cloudStorage as CloudStorageState
  );
  const { selectedDrive, availableDrives } = cloudStorageState || {};

  const [isDebouncing, setIsDebouncing] = useState(false);

  const handleDriveClick = useCallback(
    (driveName: string) => {
      if (isDebouncing) return; // Prevent action if currently debouncing

      setIsDebouncing(true);

      if (selectedDrive === driveName) {
        enqueueSnackbar_v1("error", `You already selected ${driveName}`, {
          position: { vertical: "top", horizontal: "center" },
          autoHideDuration: 5000, // Optional custom auto-hide duration in milliseconds
        });
        return;
      }

      dispatch(setSelectedDrive(driveName));

      enqueueSnackbar_v1("success", `Drive Changed to ${driveName}`, {
        position: { vertical: "top", horizontal: "center" },
        autoHideDuration: 5000, // Optional custom auto-hide duration in milliseconds
      });

      // Set timeout to reset debouncing state
      setTimeout(() => {
        setIsDebouncing(false);
      }, 1000); // Adjust debounce duration as needed
    },
    [dispatch, enqueueSnackbar_v1, isDebouncing]
  );

  return (
    <div>
      <Stack
        mt={1}
        direction={"column"}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
        gap={1}
      >
        {availableDrives.map((drive) => (
          <Stack
            key={drive.id}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            onClick={() => handleDriveClick(drive.name)} // Handle click to set selected drive
            sx={{
              cursor: "pointer", // Show pointer cursor on hover
              padding: 1,
              width: "100%",
              borderRadius: 1,
              backgroundColor:
                selectedDrive === drive.name ? "#f0f0f0" : "transparent", // Highlight selected drive
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: "#dcffde", // Light hover effect
              },
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              gap={2}
            >
              {getDriveIcon(drive.type, 20)}
              <Typography
                sx={{
                  fontFamily: "Source Serif Pro",
                  fontSize: "14px",
                  fontWeight: "300",
                  color: "black",
                  lineHeight: "17.54px",
                }}
              >
                {drive.name}
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </div>
  );
};

export default AvailableConnection;
