export enum AttachmentTypeEnum {
  XLS = "XLS",
  DOC = "DOC",
  PPT = "PPT",
  PDF = "PDF",
  MP4 = "MP4",
  MP3 = "MP3",
  PNG = "PNG",
  EMAIL = "EMAIL",
  FOLDER = "FOLDER",
}
