import { Stack } from "@mui/material";
import React from "react";
import { BorderLinearProgress } from "../../../../../../../../common";

const ProgressAutoView: React.FC<{ progress: number }> = ({ progress }) => {
  return (
    <Stack sx={{ width: "80px", height: "5px" }}>
      <BorderLinearProgress progressPercent={progress} />
    </Stack>
  );
};

export default ProgressAutoView;
