import * as React from "react";
import {
  Typography,
  MenuItem,
  Menu,
  Button,
  Stack,
  Divider,
} from "@mui/material";
import { AddCalPlusIcon } from "../../../../../images";
import g from "./AddCalculationsMenu.module.css";

// Define interface for calculation options
interface CalculationOption {
  label: string;
  onClick: () => void;
}

const AddCalculationsMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  // Click handler to close menu
  const handleClose = () => {
    setAnchorEl(null);
  };
  // Define calculation options
  const calculationOptions: CalculationOption[] = [
    { label: "Count empty", onClick: handleClose },
    { label: "Count filled", onClick: handleClose },
    { label: "Percentage empty", onClick: handleClose },
    { label: "Percentage filled", onClick: handleClose },
  ];

  // Click handler to open menu
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={g.boxButtonAddCal}
      >
        <AddCalPlusIcon w={20} />
        <Typography className={g.textAddCal} children={"Add calculations"} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Stack p={"10px"} width={"150px"}>
          <Typography
            className={g.aggregateButtonAddCal}
            children={"Aggregations"}
          />
          <Divider />
          {calculationOptions.map((option, index) => (
            <MenuItem
              key={index}
              className={g.aggregateButtonAddCalOptions}
              onClick={option.onClick}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </Menu>
    </div>
  );
};

export default AddCalculationsMenu;
