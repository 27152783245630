export interface IconDropdownUserOptionInterface {
  id: number;
  name: string;
  imgSrc?: string;
  avatarColor?: string;
  email: string;
}

export const IconDropdownUserDummyData: IconDropdownUserOptionInterface[] = [
  {
    id: 1,
    name: "John Doe",
    imgSrc: "https://randomuser.me/api/portraits/men/1.jpg",
    avatarColor: "#FF5733",
    email: "john.doe@example.com",
  },
  {
    id: 2,
    name: "Jane Smith",
    imgSrc: "https://randomuser.me/api/portraits/women/2.jpg",
    avatarColor: "#33FF57",
    email: "jane.smith@example.com",
  },
  {
    id: 3,
    name: "Alice Johnson",
    imgSrc: "https://randomuser.me/api/portraits/women/3.jpg",
    avatarColor: "#3357FF",
    email: "alice.johnson@example.com",
  },
  {
    id: 4,
    name: "Bob Brown",
    imgSrc: "https://randomuser.me/api/portraits/men/4.jpg",
    avatarColor: "#FF33A1",
    email: "bob.brown@example.com",
  },
  {
    id: 5,
    name: "Charlie Green",
    imgSrc: "https://randomuser.me/api/portraits/men/5.jpg",
    avatarColor: "#A1FF33",
    email: "charlie.green@example.com",
  },
  {
    id: 6,
    name: "Diana White",
    imgSrc: "https://randomuser.me/api/portraits/women/6.jpg",
    avatarColor: "#FF5733",
    email: "diana.white@example.com",
  },
  {
    id: 7,
    name: "Edward Black",
    imgSrc: "https://randomuser.me/api/portraits/men/7.jpg",
    avatarColor: "#5733FF",
    email: "edward.black@example.com",
  },
  {
    id: 8,
    name: "Fiona Blue",
    imgSrc: "https://randomuser.me/api/portraits/women/8.jpg",
    avatarColor: "#33FFA1",
    email: "fiona.blue@example.com",
  },
  {
    id: 9,
    name: "George Yellow",
    imgSrc: "https://randomuser.me/api/portraits/men/9.jpg",
    avatarColor: "#FFA133",
    email: "george.yellow@example.com",
  },
  {
    id: 10,
    name: "Hannah Violet",
    imgSrc: "https://randomuser.me/api/portraits/women/10.jpg",
    avatarColor: "#A133FF",
    email: "hannah.violet@example.com",
  },
];
