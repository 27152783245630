import * as React from "react";
import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import S from "./OptionDropdown.module.css";
import {
  AddToWorkspaceOptionsEnum,
  AddToWorkspaceOptionsInterface,
} from "../AddToWorkspaceType";
import { GreenDropdownIcon } from "../../../../../../images";

interface OptionDropdownProps {
  width?: string;
  onChange?: (selectedOption: AddToWorkspaceOptionsEnum) => void;
  options: AddToWorkspaceOptionsInterface[];
  initialValue?: AddToWorkspaceOptionsEnum;
}

const OptionDropdown: React.FC<OptionDropdownProps> = ({
  width = "80px",
  onChange,
  options,
  initialValue,
}) => {
  const [selectedOption, setSelectedOption] =
    React.useState<AddToWorkspaceOptionsEnum>(initialValue || options[0].value);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleChange = (
    event: SelectChangeEvent<AddToWorkspaceOptionsEnum>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    const { value } = event.target;
    setSelectedOption(value as AddToWorkspaceOptionsEnum);
    if (onChange) {
      onChange(value as AddToWorkspaceOptionsEnum);
    }
  };

  const selectedOptionData = options.find(
    (option) => option.value === selectedOption
  );

  const menuProps = {
    PaperProps: {
      className: S["share-option-dropdown__menu"],
      style: { width: "125px" },
    },
    anchorOrigin: {
      vertical: "bottom" as const,
      horizontal: "right" as const,
    },
    transformOrigin: {
      vertical: "top" as const,
      horizontal: "right" as const,
    },
  };

  return (
    <div>
      <FormControl size="small" style={{ minWidth: width }}>
        <Select
          onClick={(e) => {
            e.preventDefault(); // Prevents the default action
            e.stopPropagation(); // Stops the event from bubbling up
            if (!isOpen) {
              setIsOpen(true);
            }
          }}
          open={isOpen}
          displayEmpty
          IconComponent={() => (
            <GreenDropdownIcon open={isOpen} sx={{ marginRight: "2px" }} />
          )}
          value={selectedOption}
          onChange={handleChange}
          input={
            <OutlinedInput
              className={S["share-option-dropdown__inputbox"]}
              classes={{
                notchedOutline: S["share-option-dropdown__notchedOutline"],
                focused: S["share-option-dropdown__focused"],
              }}
            />
          }
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          renderValue={(selected) => {
            if (!selected) {
              return (
                <em className={S["share-option-dropdown__select-placeholder"]}>
                  Select option
                </em>
              );
            }

            return (
              <span className={S["share-option-dropdown__selected"]}>
                {selectedOptionData && (
                  <span style={{ position: "relative", top: "2px" }}>
                    <selectedOptionData.icon w={12} />
                  </span>
                )}
                <span style={{ paddingLeft: "5px" }}>{selected}</span>
              </span>
            );
          }}
          MenuProps={menuProps}
        >
          {options.map((option) => (
            <MenuItem
              className={S["share-option-dropdown__menu-item"]}
              key={option.id}
              value={option.value}
            >
              <Stack direction="row" alignItems="center" gap={"5px"} pl={"5px"}>
                <option.icon w={15} />
                <Stack sx={{ flexDirection: "column", gap: "2px" }}>
                  <Typography className={S["share-option-dropdown__title"]}>
                    {option.value}
                  </Typography>
                </Stack>
              </Stack>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default OptionDropdown;
