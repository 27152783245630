import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { SmallPinnedCard } from "../SmallPinnedCard";
import { adjustHexColor } from "../../../Utils";

interface ChatDrawerCardProps {
  userName: string;
  userIconSrc?: string;
  isUserActive: boolean;
  message: string;
  timeStamp: string;
  companyImgSrc: string;
  showDetailsLine?: 1 | 2;
}

const ChatDrawerCard: React.FC<ChatDrawerCardProps> = ({
  companyImgSrc,
  isUserActive,
  message,
  timeStamp,
  userName,
  userIconSrc,
  showDetailsLine = 1,
}) => {
  return (
    <Stack
      sx={{
        flexShrink: 0,
        height: "45px",
        px: "5px",
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
        flexGrow: 1,
        boxSizing: "border-box",
        borderRadius: "5px",
        width: "100%",
        bgcolor: "white",
        ":hover": { bgcolor: adjustHexColor("#fff", 104) },
        cursor: "default",
      }}
    >
      <SmallPinnedCard
        size={30}
        userName={userName}
        isActive={isUserActive}
        userIconSrc={userIconSrc}
      />

      <Stack
        sx={{
          gap: "3px",
          flexDirection: "column",
          width: "calc(100% - (25px + 30px + 30px))",
        }}
      >
        <Typography
          sx={{
            font: "normal normal 600 11px/14px Source Serif Pro",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            flexGrow: 1,
          }}
        >
          {userName}
        </Typography>
        <Typography
          sx={{
            font: "normal normal 300 8px/10px Source Serif Pro",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "normal", // Allow text to wrap
            display: "-webkit-box", // Enable webkit box model
            WebkitBoxOrient: "vertical", // Set the orientation to vertical
            WebkitLineClamp: showDetailsLine, // Limit to 2 lines
            lineClamp: showDetailsLine, // Standard property for non-webkit browsers
            flexGrow: 1,
          }}
        >
          {message}
        </Typography>
      </Stack>

      <Stack
        sx={{
          gap: "5px",
          width: "40px",
          flexShrink: 0,
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ font: "normal normal 300 8px/10px Source Serif Pro" }}
        >
          {timeStamp}
        </Typography>
        <Box
          component={"img"}
          sx={{ height: "15px", width: "15px" }}
          src={companyImgSrc}
          alt="logo"
        />
      </Stack>
    </Stack>
  );
};

export default ChatDrawerCard;
