// AvatarStringColor.ts

// Define the types for the functions
type AvatarStyle = {
    sx: {
        bgcolor: string;
    };
    children: string;
};

type StringToColor = (str: string) => string;

type StringAvatar = (name: string | null | undefined) => AvatarStyle;

// Utility function to convert a string to a color
const stringToColor: StringToColor = (str) => {
    let hash = 0;

    for (let i = 0; i < str?.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
};

// Utility function to generate avatar style and initials from a string
const stringAvatar: StringAvatar = (name) => {
    if (name === null || name === undefined) {
        return {
            sx: {
                bgcolor: "gray", // Replace with the desired background color for null names
            },
            children: "null",
        };
    }

    const nameParts = name.split(" ");
    const bgColor = stringToColor(name);

    if (nameParts.length === 0) {
        return {
            sx: {
                bgcolor: bgColor,
            },
            children: "",
        };
    }

    if (nameParts.length === 1) {
        return {
            sx: {
                bgcolor: bgColor,
            },
            children: `${nameParts[0][0]}`,
        };
    }

    return {
        sx: {
            bgcolor: bgColor,
        },
        children: `${nameParts[0][0]}${nameParts[1][0]}`,
    };
};


export { stringToColor, stringAvatar };
