import * as React from "react";
import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import S from "./IconWordIconDropdown.module.css";
import {
  ApprovalIcon,
  DocumentIcon,
  EyeIcon,
  FeatureIcon,
  GreenDropdownIcon,
  MarketingIcon,
  ResearchIcon,
  RisksAndIssuesIcon,
  StarIcon,
  TaskIcon,
  TestingIcon,
  UpdateIcon,
} from "../../../images";

interface IconWordIconDropdownProps {
  width?: string;
  onChange?: (selectedOption: string) => void;
  initialSelectedOptions?: string;
}

interface Option {
  id: number;
  icon: React.ElementType;
  title: string;
}

const OPTIONS: Option[] = [
  { id: 1, icon: TaskIcon, title: "Task" },
  { id: 2, icon: StarIcon, title: "Milestone" },
  { id: 3, icon: RisksAndIssuesIcon, title: "Bug" },
  { id: 4, icon: MarketingIcon, title: "Campaign" },
  { id: 5, icon: UpdateIcon, title: "Update" },
  { id: 6, icon: FeatureIcon, title: "Feature" },
  { id: 7, icon: ResearchIcon, title: "Research" },
  { id: 8, icon: EyeIcon, title: "Review" },
  { id: 9, icon: ApprovalIcon, title: "Approval" },
  { id: 10, icon: DocumentIcon, title: "Documentation" },
  { id: 11, icon: TestingIcon, title: "Testing" },
];

const IconWordIconDropdown: React.FC<IconWordIconDropdownProps> = ({
  width = "80px",
  onChange,
  initialSelectedOptions = OPTIONS[0].title,
  ...props
}) => {
  const [selectedOption, setSelectedOption] = React.useState<string>(
    initialSelectedOptions
  );
  const [isOpen, setIsOpen] = React.useState(false);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setSelectedOption(value);
    if (onChange) {
      onChange(value);
    }
  };

  const selectedOptionData = OPTIONS.find(
    (option) => option.title === selectedOption
  );

  const menuProps = {
    PaperProps: {
      className: S["icon-word-icon-dropdown__menu"],
      style: { width: "125px" },
    },
    anchorOrigin: {
      vertical: "bottom" as const,
      horizontal: "right" as const,
    },
    transformOrigin: {
      vertical: "top" as const,
      horizontal: "right" as const,
    },
  };

  return (
    <div>
      <FormControl size="small" style={{ minWidth: width }} {...props}>
        <Select
          open={isOpen}
          onClick={!isOpen ? () => setIsOpen(true) : () => null}
          IconComponent={() => (
            <GreenDropdownIcon
              open={isOpen}
              sx={{ marginRight: "0px", height: "12px" }}
            />
          )}
          value={selectedOption}
          onChange={handleChange}
          input={
            <OutlinedInput
              className={S["icon-word-icon-dropdown__inputbox"]}
              classes={{
                notchedOutline: S["icon-word-icon-dropdown__notchedOutline"],
                focused: S["icon-word-icon-dropdown__focused"],
              }}
            />
          }
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          renderValue={(selected) => {
            if (!selected) {
              return (
                <em
                  className={S["icon-word-icon-dropdown__select-placeholder"]}
                >
                  Select option
                </em>
              );
            }

            return (
              <span className={S["icon-word-icon-dropdown__selected"]}>
                {selectedOptionData && (
                  <span style={{ position: "relative", top: "2px" }}>
                    <selectedOptionData.icon w={20} />
                  </span>
                )}
                <span style={{ paddingLeft: "5px", paddingRight: "0px" }}>
                  {selected}
                </span>
              </span>
            );
          }}
          MenuProps={menuProps}
        >
          {OPTIONS.map((option) => (
            <MenuItem
              className={S["icon-word-icon-dropdown__menu-item"]}
              key={option.id}
              value={option.title}
            >
              <Stack direction="row" alignItems="center" gap={"5px"}>
                <option.icon w={15} />
                <Typography className={S["icon-word-icon-dropdown__title"]}>
                  {option.title}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default IconWordIconDropdown;
