import { Stack, Typography } from "@mui/material";
import React from "react";
import { EmailAttachment } from "../EmailInCRMPreviewTypes";
import { FileTypeIcon } from "../../../../../common";

const AttachmentDisplay: React.FC<EmailAttachment> = ({
  id,
  name,
  type,
  url,
}) => {
  return (
    <Stack
      sx={{
        height: "25px",
        width: "125px",
        border: "0.5px solid #E9EDF2",
        borderRadius: "5px",
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
        paddingX: "5px",
        cursor: "pointer",
      }}
      onClick={() => console.log("attachment clicked", id, url)}
    >
      <Stack sx={{ flexShrink: 0 }}>
        <FileTypeIcon filetype={type} />
      </Stack>
      <Typography
        sx={{
          font: "normal normal normal 13px/17px Source Serif Pro",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          flexGrow: 1,
        }}
      >
        {name}
      </Typography>
    </Stack>
  );
};

export default AttachmentDisplay;
