import React from "react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import ColoredBadge from "../ColoredBadge";
import { GreenArrowButton } from "../../Buttons";
import { PlusIcon } from "../../../../images";
import styles from "./styleHeader.module.css";
import { adjustHexColor } from "../../../../Utils";
type WorkspaceBoardTitleHeaderProps = {
  title?: string;
  color?: string;
  onClick?: () => void; // Updated to correct function type
};

const WorkspaceBoardTitleHeader: React.FC<WorkspaceBoardTitleHeaderProps> = ({
  title = "Done",
  color = "green",
  onClick = () => {},
}) => {
  const darkenedOrSoftenColor = adjustHexColor("#fff", 35);
  return (
    <Stack
      alignItems={"center"}
      width={"100%"}
      justifyContent={"space-between"}
      direction={"row"}

    >
      <ColoredBadge
        title={title}
        bgColor={darkenedOrSoftenColor}
        iconColor={color}
        showIcon
      />
      <Stack className={styles["board-card__header-stack"]}>
        <Stack onClick={onClick}>
          <Tooltip title="button" placement="top" arrow>
            <GreenArrowButton
              sx={{
                transform: "rotate(180deg)",
                py: "2px",
                px: "4px",
              }}
            />
          </Tooltip>
        </Stack>
        <Tooltip title="button" placement="top" arrow>
          <IconButton
            disableRipple
            sx={{ height: "20px", width: "20px", padding: 0 }}
          >
            <PlusIcon w={14} />
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default WorkspaceBoardTitleHeader;
