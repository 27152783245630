import React, { useState, useEffect, useRef } from "react";
import { InputBase, Stack, Typography } from "@mui/material";
import {
  DealStageCurrencyEnum,
  DealStageCurrencySymbols,
} from "../../../../../../../../common";

interface MoneyViewProps {
  initialValue?: number;
  currencyType: DealStageCurrencyEnum;
}

const MoneyView: React.FC<MoneyViewProps> = ({
  initialValue = 0,
  currencyType,
}) => {
  const [amount, setAmount] = useState<string>("0.00");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (initialValue !== undefined) {
      setAmount(formatCurrency(initialValue.toString()));
    }
  }, [initialValue]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace(/[^0-9.]/g, "");

    const decimalIndex = value.indexOf(".");
    if (decimalIndex !== -1) {
      const integerPart = value.slice(0, decimalIndex);
      const decimalPart = value.slice(decimalIndex + 1).slice(0, 2); // Limit to two digits after decimal
      value = `${integerPart}.${decimalPart}`;
    }

    // Prevent more than one decimal point
    const parts = value.split(".");
    if (parts.length > 2) {
      value = parts[0] + "." + parts[1].slice(0, 2);
    }

    setAmount(value);
  };

  const handleBlur = () => {
    setAmount((prevAmount) => formatCurrency(prevAmount));
  };

  const handleFocus = () => {
    if (amount === "0.00") {
      setAmount("");
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleBlur();
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  };

  const formatCurrency = (value: string) => {
    let [integer, decimal] = value.split(".");
    integer = integer.replace(/^0+/, "") || "0"; // Remove leading zeros, default to "0"

    if (decimal === undefined) {
      decimal = "00";
    } else if (decimal.length === 1) {
      decimal = decimal + "0";
    } else if (decimal.length > 2) {
      decimal = decimal.slice(0, 2);
    }

    return `${integer}.${decimal}`;
  };

  return (
    <Stack direction="row" alignItems="center" spacing={"5px"}>
      <Typography sx={{ font: "normal normal 300 14px/20px Source Serif Pro" }}>
        {DealStageCurrencySymbols[currencyType]}
      </Typography>
      <InputBase
        ref={inputRef}
        value={amount}
        onChange={handleInputChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        sx={{
          font: "normal normal 300 14px/20px Source Serif Pro",
          textAlign: "right",
          width: "100px",
          flexGrow: 1,
        }}
        inputProps={{
          style: {},
        }}
      />
      <Typography
        sx={{
          font: "normal normal 300 14px/20px Source Serif Pro",
          whiteSpace: "nowrap",
        }}
      >
        {currencyType}
      </Typography>
    </Stack>
  );
};

export default MoneyView;
