// utils.ts

type TimeFormatOptions = {
    hour12?: boolean;
    locale?: string;
    timeZone?: string;
};

type DateFormatOptions = {
    locale?: string;
    useShortMonth?: boolean;
    timeZone?: string;
};

/**
 * Format a date object into a time string based on the provided options.
 * @param {Date} date - The date object to format.
 * @param {TimeFormatOptions} options - Formatting options.
 * @returns {string} Formatted time string.
 */
const formatTime = (
    date: Date,
    { hour12 = true, locale = "en-US", timeZone = "UTC" }: TimeFormatOptions = {}
): string => {
    return date.toLocaleTimeString(locale, {
        hour: "2-digit",
        minute: "2-digit",
        hour12,
        timeZone,
    });
};

/**
 * Format a date object into a date string based on the provided options.
 * @param {Date} date - The date object to format.
 * @param {DateFormatOptions} options - Formatting options.
 * @returns {string} Formatted date string.
 */
const formatDate = (
    date: Date,
    {
        locale = "en-US",
        useShortMonth = false,
        timeZone = "UTC",
    }: DateFormatOptions = {}
): string => {
    return date.toLocaleDateString(locale, {
        year: "numeric",
        month: useShortMonth ? "short" : "long",
        day: "numeric",
        timeZone,
    });
};

/**
 * Get current time in various formats.
 * @param {TimeFormatOptions} [options] - Custom options for formatting.
 * @returns {string[]} Array of formatted time strings.
 */
export const getCurrentTimeFormats = (
    options: TimeFormatOptions = {}
): string[] => {
    const now = new Date();
    const { hour12 = true, locale = "en-US", timeZone = "UTC" } = options;

    return [
        `12 Hours: ${formatTime(now, { hour12, locale, timeZone })}`,
        `24 Hours: ${formatTime(now, { hour12: false, locale, timeZone })}`,
    ];
};

/**
 * Get current date in various formats.
 * @param {DateFormatOptions} [options] - Custom options for formatting.
 * @returns {string[]} Array of formatted date strings.
 */
export const getCurrentDateFormats = (
    options: DateFormatOptions = {}
): string[] => {
    const now = new Date();
    const { locale = "en-US", useShortMonth = false, timeZone = "UTC" } = options;

    return [
        formatDate(now, { locale, useShortMonth, timeZone }),
        formatDate(now, { locale: "en-GB", useShortMonth, timeZone }),
    ];
};
