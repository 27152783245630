import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import g from "./ActiveProjectTab.module.css";
import { ActiveProjectTabProps, tabText } from "./ActiveProjectTabType";

/**
 * Functional component representing a tabbed navigation for project views.
 *
 * This component renders tabs based on the tabText array provided via props.
 * Each tab displays text and handles click events to update the active tab state.
 *
 * @param {ActiveProjectTabProps} props - The props object containing tabActive and setTabActive function.
 *   - tabActive: number - The index of the currently active tab.
 *   - setTabActive: (newTab: number) => void - A function to update the active tab index.
 *
 * @returns JSX.Element - A JSX element representing the tabbed navigation.
 */
const ActiveProjectTab: React.FC<ActiveProjectTabProps> = React.memo(
  ({ tabActive = 0, setTabActive }) => {
    return (
      <Box className={g.activeTabProject}>
        <Stack direction="row" spacing={2}>
          {/* Render each tab based on the tabText array */}
          {tabText.map((tab) => {
            return (
              <Typography
                key={tab.id}
                noWrap={true}
                variant={"button"}
                className={g.activeTabProject}
                sx={{
                  opacity: tab.idNum === tabActive ? 1 : 0.5,
                }}
                onClick={() => setTabActive(tab.idNum)}
              >
                {tab.tText}
              </Typography>
            );
          })}
        </Stack>
      </Box>
    );
  }
);

export default ActiveProjectTab;
