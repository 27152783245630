import React, { useState } from "react";
import {
  Box,
  IconButton,
  TextField,
  Tooltip,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import g from "../centralComponentStyle.module.css";
import { ChecklistItemPM } from "../../../../components";
import { CorrectIcon } from "../../../../../../../images";
import { RootState } from "../../../../../../../redux/store";
import { WorkspaceState } from "../../../../../../../redux";
import { useSelector } from "react-redux";

interface CheckListItem {
  id: string;
  text: string;
  isChecked: boolean;
}

interface CheckListComponentProps {
  initialItems: CheckListItem[]; // Ensure this is an array
  onUpdate: (newData: any) => void; // Callback to update the parent component or store
}

const CheckListComponent: React.FC<CheckListComponentProps> = ({
  initialItems = [], // Default to an empty array if not provided
  onUpdate,
}) => {

  const workspaceState = useSelector(
    (state: RootState) => state.workspace as WorkspaceState
  );
  const { lockPage } = workspaceState || {};

  const [items, setItems] = useState<CheckListItem[]>(initialItems);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [newItemText, setNewItemText] = useState<string>("");

  const handleAddItem = () => {
    if (newItemText.trim()) {
      setItems([
        ...items,
        { id: Date.now().toString(), text: newItemText, isChecked: false },
      ]);
      setNewItemText("");
    }
  };

  const handleCheck = (id: string) => {
    const updatedItems = items.map((item) =>
      item.id === id ? { ...item, isChecked: !item.isChecked } : item
    );
    setItems(updatedItems);

    if (!isEditing) {
      // Save automatically if not in editing mode
      const apiPayload = {
        items: updatedItems,
      };
      onUpdate(apiPayload);
    }
  };

  const handleChange = (id: string, text: string) => {
    setItems(items.map((item) => (item.id === id ? { ...item, text } : item)));
  };

  const handleSave = () => {
    const apiPayload = {
      items,
    };
    onUpdate(apiPayload);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setItems(initialItems); // Revert to initial items
    setIsEditing(false);
  };

  return (
    <Box className={g.checkListComponent}>
      {isEditing ? (
        <Box className={g.editorContainer}>
          {items.map((item) => (
            <Box key={item.id} className={g.checkListItem}>
              <TextField
                value={item.text}
                onChange={(e) => handleChange(item.id, e.target.value)}
                variant="outlined"
                sx={{ width: "85%", marginRight: "10px" }}
                className={g.checkListTextField}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={item.isChecked}
                    onChange={() => handleCheck(item.id)}
                  />
                }
                label="Done"
              />
            </Box>
          ))}
          <Box className={g.newItemContainer}>
            <TextField
              value={newItemText}
              onChange={(e) => setNewItemText(e.target.value)}
              variant="outlined"
              placeholder="Add new item"
              className={g.checkListTextField}
              sx={{ width: "85%" }}
            />
            <IconButton color="primary" onClick={handleAddItem}>
              <AddIcon sx={{ fontSize: 19 }} />
            </IconButton>
          </Box>
          <Box className={g.buttonContainer}>
            <Tooltip title="Save">
              <IconButton
                className={g.iconButtonWC}
                color="primary"
                onClick={handleSave}
              >
                <CorrectIcon color="green" w={19} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel">
              <IconButton
                className={g.iconButtonWC}
                color="error"
                onClick={handleCancel}
              >
                <CancelIcon sx={{ fontSize: 19 }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ) : (
        <Box
          className={g.checkListDisplay}
          sx={{
            minHeight: "60px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {items && items.length > 0 ? (
            items.map((item) => (
              <React.Fragment key={item.id}>
                <ChecklistItemPM
                  componentType="main"
                  opened={false}
                  checked={item.isChecked}
                  title={item.text}
                  onCheckChange={() => handleCheck(item.id)}
                />
              </React.Fragment>
            ))
          ) : (
            <div style={{ padding: "5px", color: "gray" }}>
              Add An CheckList Item
            </div>
          )}

          {!lockPage && (
            <Tooltip title="Edit">
              <IconButton
                color="primary"
                className={g.editButton}
                onClick={() => setIsEditing(true)}
              >
                <EditIcon sx={{ fontSize: 19 }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      )}
    </Box>
  );
};

export default CheckListComponent;
