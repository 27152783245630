import { Stack, Typography, SxProps, InputBase } from "@mui/material";
import React from "react";
import { CardIcon } from "../../../../../../../images";

interface TaskTitleProps {
  taskTitle: string;
  workspaceName: string;
  onTasknameChange: (newname: string) => void;
  sx?: SxProps; // Adding the optional sx prop
}

const TaskTitle: React.FC<TaskTitleProps> = ({
  taskTitle,
  onTasknameChange,
  workspaceName,
  sx,
}) => {
  const [tName, setTName] = React.useState(taskTitle);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onTasknameChange(tName);
      event.currentTarget.blur(); // Remove focus from the input
    }
  };

  const handleBlur = () => {
    onTasknameChange(tName);
  };

  return (
    <Stack sx={{ flexDirection: "row", gap: "10px", ...sx }}>
      <CardIcon w={25} />
      <Stack gap={"3px"}>
        <InputBase
          sx={{ font: "normal normal normal 18px/25px Source Serif Pro" }}
          value={tName}
          onChange={(e) => setTName(e.target.value)}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
        />
        <Typography
          sx={{ font: "normal normal 300 12px/16px Source Serif Pro" }}
        >
          {workspaceName}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default TaskTitle;
