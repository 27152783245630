import React from "react";
import { InputBase } from "@mui/material";
import { styled } from "@mui/material/styles";
import { SearchIcon } from "../../../images";

interface SearchDropdownProps {
  value?: string;
  onChange?: (textValue: string) => void;
  width?: string;
  placeHolder?: string;
}

const SearchInDropdown: React.FC<SearchDropdownProps> = ({
  value,
  onChange,
  width = "220px",
  placeHolder = "Search all Projects",
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <Search style={{ width }}>
      <SearchIconWrapper>
        <SearchIcon color="black" w={18} />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder={placeHolder}
        inputProps={{ "aria-label": "search" }}
        value={value}
        onChange={handleChange}
      />
    </Search>
  );
};

export default SearchInDropdown;

const Search = styled("div")({
  position: "relative",
  borderRadius: "5px",
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "white",
  },
  marginLeft: 0,
  height: "25px",
  display: "flex",
  alignItems: "center",
  boxSizing: "border-box",
  border: "0.5px solid #E9EDF2",
});

const SearchIconWrapper = styled("div")({
  marginRight: "5px",
  marginLeft: "5px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledInputBase = styled(InputBase)({
  color: "inherit",
  flex: 1,
  "& .MuiInputBase-input": {
    padding: "0 10px 0 0px",
    font: "normal normal 300 13px/17px Source Serif Pro",
    "&::placeholder": {
      color: "inherit",
      font: "normal normal 300 13px/17px Source Serif Pro",
      opacity: 1,
    },
  },
});
