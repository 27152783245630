import { Stack } from "@mui/material";
import React from "react";

import { CommentListInterface } from "./CommentCardType";
import FroalalComment from "./froalalComment/FroalalComment";

interface CommentCardProps {
  cradState?: "new" | "edit" | "Saved";
  data?: CommentListInterface[];
  content?: string;
  handleContentChange?: (arg: string) => void;
}

// min width of this card is  --> 280px <-- max has no limit
const CommentCard: React.FC<CommentCardProps> = ({
  cradState = "new",
  content = "something",
  handleContentChange = () => {},
}) => {
  return (
    <>
      <FroalalComment
        content={content}
        handleContentChange={(e: any) => console.log("Content changed")}
      />
    </>
  );
};

export default CommentCard;
