import { Box, IconButton, Popover, Stack, Tooltip } from "@mui/material";
import React, { MouseEvent, useState, useEffect } from "react";

interface ColorCirclePickerProps {
  initialSelect?: string; // hex color value
  onSelect?: (color: string) => void;
}

const ColorList: { colorName: string; colorvalue: string }[] = [
  { colorName: "Invoiced", colorvalue: "#42A5F5" },
  { colorName: "Partially Paid", colorvalue: "#FFEE58" },
  { colorName: "Light Green", colorvalue: "#A1FF9F" },
  { colorName: "Light Orange", colorvalue: "#FFDCB2" },
  { colorName: "Light Purple", colorvalue: "#CFA0FF" },
  { colorName: "Overdue", colorvalue: "#EF5350" },
  { colorName: "In Review", colorvalue: "#FFA500" },
  { colorName: "Tirquoise", colorvalue: "#59D0D5" },
];

const ColorCirclePicker: React.FC<ColorCirclePickerProps> = ({
  initialSelect,
  onSelect,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedColor, setSelectedColor] = useState<string>(
    initialSelect || ColorList[0].colorvalue
  );

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (initialSelect) {
      setSelectedColor(initialSelect);
    }
  }, [initialSelect]);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleColorSelect = (color: string) => {
    setSelectedColor(color);
    if (onSelect) {
      onSelect(color);
    }
    handlePopoverClose();
  };

  return (
    <>
      <Tooltip title="Color Picker" placement="top" arrow>
        <Stack
          onClick={handlePopoverOpen}
          sx={{
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            bgcolor: selectedColor,
          }}
        />
      </Tooltip>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        container={ document && document?.getElementById("full-ScreenMode")}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ marginTop: "10px" }}
        PaperProps={{
          style: { boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)" },
        }}
      >
        <Stack sx={{ padding: "7px", flexDirection: "row", gap: "15px" }}>
          {ColorList.map((item) => (
            <IconButton
              key={item.colorvalue}
              sx={{ padding: 0 }}
              disableRipple
              onClick={() => handleColorSelect(item.colorvalue)}
            >
              <Box
                sx={{
                  height: "20px",
                  width: "20px",
                  borderRadius: "50%",
                  bgcolor: item.colorvalue,
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              />
            </IconButton>
          ))}
        </Stack>
      </Popover>
    </>
  );
};

export default ColorCirclePicker;
