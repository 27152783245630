import React, { useState } from "react";
import {
  Box,
  IconButton,
  TextField,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import g from "../centralComponentStyle.module.css"; // Adjust path if necessary
import { CorrectIcon } from "../../../../../../../images";
import { RootState } from "../../../../../../../redux/store";
import { WorkspaceState } from "../../../../../../../redux";
import { useSelector } from "react-redux";

interface HeadingComponentProps {
  initialText: string;
  initialAlignment: "left" | "center" | "right";
  initialUnderline: boolean;
  size: "headinglarge" | "headingmedium" | "headingsmall";
  onSave: (data: any) => void;
}

const HeadingComponent: React.FC<HeadingComponentProps> = ({
  initialText,
  initialAlignment,
  initialUnderline,
  size,
  onSave,
}) => {
  const workspaceState = useSelector(
    (state: RootState) => state.workspace as WorkspaceState
  );
  const { lockPage } = workspaceState || {};

  const [text, setText] = useState<string>(initialText);
  const [alignment, setAlignment] = useState<"left" | "center" | "right">(
    initialAlignment
  );
  const [underline, setUnderline] = useState<boolean>(initialUnderline);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const handleSave = () => {
    const updatedData = {
      text,
      alignment,
      underline,
    };

    onSave(updatedData); // Notify parent or save data
    setIsEditing(false); // Exit edit mode
  };

  const handleCancel = () => {
    setText(initialText);
    setAlignment(initialAlignment);
    setUnderline(initialUnderline);
    setIsEditing(false); // Exit edit mode
  };

  return (
    <Box className={g.headingComponent}>
      {isEditing ? (
        <Box className={g.editorContainer}>
          <TextField
            value={text}
            onChange={(e) => setText(e.target.value)}
            variant="outlined"
            className={g.headingTextField}
            sx={{ marginBottom: "10px" }}
          />
          <FormControl variant="outlined" className={g.selectField}>
            <InputLabel sx={{ fontFamily: "Source Serif Pro" }}>
              Alignment
            </InputLabel>
            <Select
              value={alignment}
              sx={{ fontFamily: "Source Serif Pro" }}
              onChange={(e) =>
                setAlignment(e.target.value as "left" | "center" | "right")
              }
              label="Alignment"
            >
              <MenuItem sx={{ fontFamily: "Source Serif Pro" }} value="left">
                Left
              </MenuItem>
              <MenuItem sx={{ fontFamily: "Source Serif Pro" }} value="center">
                Center
              </MenuItem>
              <MenuItem sx={{ fontFamily: "Source Serif Pro" }} value="right">
                Right
              </MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={underline}
                onChange={(e) => setUnderline(e.target.checked)}
              />
            }
            sx={{ fontFamily: "Source Serif Pro" }}
            label="Underline"
          />
          <Box className={g.buttonContainer}>
            <Tooltip title="Save">
              <IconButton
                className={g.iconButtonWC}
                color="primary"
                onClick={handleSave}
              >
                <CorrectIcon color="green" w={19} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel">
              <IconButton
                className={g.iconButtonWC}
                color="error"
                onClick={handleCancel}
              >
                <CancelIcon sx={{ fontSize: 19 }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ) : (
        <Box
          className={g.headingDisplay}
          sx={{
            textAlign: alignment,
            textDecoration: underline ? "underline" : "none",
            fontSize:
              size === "headinglarge"
                ? "48px"
                : size === "headingmedium"
                  ? "36px"
                  : "28px",
          }}
        >
          {text}
          {!lockPage && (
            <Tooltip title="Edit">
              <IconButton
                color="primary"
                className={g.editButton}
                onClick={() => setIsEditing(true)}
              >
                <EditIcon sx={{ fontSize: 19 }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      )}
    </Box>
  );
};

export default HeadingComponent;
