import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import {
  GreenDropdownIcon,
  HDotsIcon,
  WorkspaceIcon2,
} from "../../../../../../../images";
import g from "./workspaceStyle.module.css";
import { useNavigate } from "react-router-dom";
import { StyledTooltip } from "../../../../../StyledTooltip";
import { AddToWorkspace } from "../../../../../../pages/Workspace/components";
import {
  getAllComponents__api,
  GetAllComponentsParams,
  WorkspaceData,
  WorkspaceState,
  ComponentItem,
  getAllWhiteboards__api,
  getAllDocs__api,
  getAllWikis__api,
} from "../../../../../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../redux/store";
import { IconKey, RenderWorkspaceIcon } from "../../../../../../../Utils";

type WorkspaceNameProps = {
  workspace: WorkspaceData;
  minMaxEclip?: boolean;
};

const WorkspaceName: React.FC<WorkspaceNameProps> = ({
  minMaxEclip = false,
  workspace,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const workspaceState = useSelector(
    (state: RootState) => state.workspace as WorkspaceState
  );

  const componentsData = workspaceState?.componentsData || {};
  const workspaceComponents = componentsData[workspace._id]?.data.items || [];
  const [documentTypes, setDocumentTypes] = useState<ComponentItem[]>([]);

  const [componentsLoader, setComponentsLoader] = useState<boolean>(false);

  const handleNavigate = (
    id: string,
    componentType: string,
    componentId: string,
    event: any
  ) => {
    event.preventDefault();
    event.stopPropagation();
    navigate(`/workspace/private/${id}/${componentType}/${componentId}`);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleGetWorkspaceItems = async () => {
    const payload: GetAllComponentsParams = {
      workspaceId: workspace._id,
      componentType: "Boards",
      page: 1,
      limit: 10,
      sort: "asc",
    };

    const wh = { workspaceId: workspace._id, page: 1, limit: 10, sort: "asc" };
    const doc = {
      workspaceId: workspace._id,
      page: 1,
      limit: 10,
      sort: "asc",
      filter: "proposal",
      isStarred: false,
    };

    setComponentsLoader(true);
    try {
      await dispatch(getAllComponents__api(payload));
      await dispatch(getAllWhiteboards__api(wh));
      const docs: any = await dispatch(getAllDocs__api(doc));
      // Extract document types from the fetched documents and update state
      const docTypes = docs?.data?.map((doc: ComponentItem) => ({
        _id: doc?._id,
        name: doc?.name || doc?.title,
        componentType: "Document", // Assuming 'Document' is the type for docs
      }));
      setDocumentTypes(docTypes);
      await dispatch(getAllWikis__api(workspace._id));
    } catch (error) {
      console.error("Failed to get workspace items:", error);
    }
    setComponentsLoader(false);
  };

  const handleToggleDropdown = (event: any): void => {
    event.preventDefault();
    event.stopPropagation();
    if (!isDropdownOpen) {
      handleGetWorkspaceItems();
    }
    setIsDropdownOpen((prev) => !prev);
  };

  const handleMouseEnter = (): void => {
    setIsHovered(true);
  };

  const handleMouseLeave = (): void => {
    setIsHovered(false);
  };

  return (
    <Box>
      <Stack
        width={"100%"}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Stack
          width={"100%"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          gap={1}
        >
          {isDropdownOpen || isHovered ? (
            <IconButton
              sx={{ padding: "2px" }}
              onClick={(e: any) => handleToggleDropdown(e)}
            >
              <GreenDropdownIcon
                sx={{ width: "12px", height: "15px" }}
                open={isDropdownOpen}
              />
            </IconButton>
          ) : (
            <WorkspaceIcon2 w={20} color="black" />
          )}
          <StyledTooltip title={workspace?.name}>
            <Typography
              className={g.wname}
              sx={{
                maxWidth: minMaxEclip ? "160px" : "120px",
                minWidth: minMaxEclip ? "160px" : "120px",
              }}
              noWrap={true}
              children={workspace?.name}
            />
          </StyledTooltip>
        </Stack>
        <Stack
          width={"100%"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <StyledTooltip title="More Options">
            <IconButton onClick={(e: any) => {}} sx={{ padding: "2px" }}>
              <HDotsIcon w={20} color="black" />
            </IconButton>
          </StyledTooltip>
          <IconButton sx={{ padding: "2px" }}>
            <AddToWorkspace
              workspaceIds={workspace?._id}
              documentTypes={documentTypes}
            />
          </IconButton>
        </Stack>
      </Stack>
      {isDropdownOpen &&
        (!componentsLoader ? (
          <Stack className={g.dropdownContent} width={"100%"} gap={"10px"}>
            {workspaceComponents.length > 0 ? (
              workspaceComponents.map((component: ComponentItem) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    marginLeft: "5px",
                  }}
                  key={component._id}
                  onClick={(e: any) => {
                    component?.componentType &&
                      component._id &&
                      handleNavigate(
                        component.workspaceId,
                        component?.componentType,
                        component._id,
                        e
                      );
                  }}
                >
                  <RenderWorkspaceIcon
                    component={(component?.componentType as IconKey) || "Board"}
                    w={20}
                    color="black"
                  />
                  <Typography
                    sx={{
                      fontFamily: "Source Serif Pro",
                      fontWeight: "300",
                      fontSize: "13px",
                      lineHeight: "16.29px",
                      color: "black",
                      cursor: "pointer",
                      "&:hover": {
                        fontWeight: "bold",
                        color: "black",
                      },
                    }}
                    variant="body2"
                  >
                    {component?.name || component?.title || ""}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography variant="body2" color="text.secondary">
                There are no components available at the moment.
              </Typography>
            )}
          </Stack>
        ) : (
          <CircularProgress color="success" />
        ))}
    </Box>
  );
};

export default WorkspaceName;
