import React from "react";

function Phone50Icon({w = 25}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={w}
            height={w}
            viewBox="0 0 40 40"
        >
            <image
                width="40"
                height="40"
                xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAABHNCSVQICAgIfAhkiAAAAzhJREFUWEftmFmIT1EcgI1dKcn24smSlCVlf1CWeFCyb8n6JMmSLeGJEopSXhSDlCXJ+iKMlEIpFMryKkuilOy+b7pX/7ndc/8zc+/8Z5RTXzP/c+4957tnu797qlq18FTVwv1a/fOC7enh6TANBkIXyPtQv6njIzyFi3ABvoVGMtRYW26YCHthUAFSofaVfQwb4Cb8SF6YJqjcUtgDXSs0Rz/QzkY4npRME5zMRacqKBf3gZLz4VpppyQFnXP3YGiFei7ZzCMyRsLXuCApOIeC000458o9t3NSh3MhwRMULCpTy0PKX0bXjONv93KtNrDcebgkJHifguEZFe6mrBpegU87G05C6wZKZF2ug8Ncm5JD/Jy8foG7XTirwMkcJ1f8gSi/KMcXVNQ/JOjQ9Qm0tIL8Iyllvcmrgb4FGTo6f+tK9mCW4DJurE6RcOVvg+3NLehG6nAmd/s25K2G/c0t6PCugc8JEd/Ph8HtoYjU6CF+QuuT4HXCYiG/XclFpUYLKuBrsPRV1Ivfvux7FGVHPbkElVMyToP5x9dTkSmXoAvE+PBKZOQKNkwaW6BhLkE9HNIJ8D6SGs/fq9CxIMncgt8ROQSbwEi4A6yHHQVJ5ha0owzZ14HBxU/oDO6FbtadEj15ht93oBssgNCrNL6tEMFY0mDhelSzPTkGdoFz8h2sBeere6cbek/YAishFGAUJqiX89AouCbqSfNcOAPgDbxN9KY/DTB8sIOQFqoVKhj3pHPQzTr4dZYQVdLAeGbKAxQuaBufoBp2Rr2a0m6dLHvZ8G1GpQRtx9X9DLaC886ANpT8xlZwSEMFswLWcr0Sl7uZ3wI/W90zXSBfII6CXM1GRpsDFWYGrH7RjaivST2u8/TgLhhouKCMP6fA3Ix7dRgVlycD1mMULK5Hw015yVEqXx4SnEXB2ehJm1IiVLfzVofzIcF20ZAMaw472nwAo8EFV5vSjj48NHKPcjJXMjlH58GN0kZDh0d+vO+roKRynnC52df55sk6fvPUwOM3hzvvmWDWnHNY3XZuJ+VCQ1xamXNyKsQHmL47854i/KIOe8yt5xJcThOLJZqqZwqbu/8F83blH2KvqylyAfKJAAAAAElFTkSuQmCC"
            ></image>
        </svg>
    );
}

function Phone64Icon({w = 25}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={w}
        height={w}
        viewBox="0 0 50 50"
      >
        <image
          width="50"
          height="50"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABHNCSVQICAgIfAhkiAAAAXZJREFUaEPtWjtuQjEQhEiICyRlSopcAqUMXVoiwTH4VISGT45BQU9HDXdAiJYSLhAhJcyiREKIhz0Wz+KhsTQF2M+7Ozte+4HzuTtp+TuJIxcaSAUEVIFH4PdKZJgvW2AMTNk5QwIpw8gIeAYeWIOO8T/oXwN1YMbMHRJIEwb6wKVnv9G/SXDkCd8XHU620D9MO5BPGOg4jLQTGDUZvgI9x/Nd9Jsd7xaSEZ9AXuDBMsEL61tkJZAVHJ0AuxOHC/j8DpSyEoi3JBIG3oy0FMgfA8oIIwWfqsXMd26sMsIwqIwQbElaBFmHM5DrrMXMp8V+zEBah0ZlRDt7gAa02AnStI8QZGkfYciStBi2VLUItiQtgixVLYasKNKydxFb8Gm2KIGoahEpVEYIslS1GLIkLYYtVS2CLUmLIEtViyErirQa8GgAhPxK6ROM/RdvFwa+fAb/jwlxJsYVjhocnKcdiM3/BnwAmb5UwxAVbWyItKI5xxjaAy68bzOnQeuGAAAAAElFTkSuQmCC"
        ></image>
      </svg>
    );
  }
export { Phone50Icon, Phone64Icon };