import * as React from "react";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";

interface TableData {
  value: number;
}

interface RatingTableProps {
  tableData: TableData;
}

const RatingTable: React.FC<RatingTableProps> = ({ tableData }) => {
  // State to manage the rating value
  const [ratingValue, setRatingValue] = React.useState(tableData?.value ?? 3.5);

  // Handler function to update the rating value
  const handleRatingChange = (newValue: number | null) => {
    if (newValue !== null) {
      setRatingValue(newValue);
      // Here you can optionally update any parent component or state with the new rating value
    }
  };

  return (
    <Stack spacing={1}>
      <Rating
        name="half-rating"
        value={ratingValue}
        precision={0.5}
        onChange={(event, newValue) => {
          handleRatingChange(newValue);
        }}
      />
    </Stack>
  );
};

export default RatingTable;
