import { MenuItem, Stack, Typography } from "@mui/material";
import React from "react";
import { AddElementListInterface } from "./ElementsOptionsListType";

interface ElementsOptionsPopoverProps {
  options: AddElementListInterface[];
  onSelect: (value: string) => void;
  onClose: () => void;
}

const ElementsOptionsList: React.FC<ElementsOptionsPopoverProps> = ({
  options,
  onSelect,
  onClose,
}) => {
  const handleSelect = (value: AddElementListInterface["value"]) => {
    onSelect(value);
    onClose();
  };

  return (
    <Stack
      sx={{
        width: "150px",
        height: "300px",
        overflowY: "scroll",
        paddingY: "10px",
        "&::-webkit-scrollbar": {
          width: 0,
          height: 0,
        },
        "-ms-overflow-style": "none", // for Internet Explorer and Edge
        "scrollbar-width": "none", // for Firefox
      }}
    >
      {options.map((item) => (
        <MenuItem
          key={item.id}
          sx={{
            padding: "0px 10px",
            alignItems: "center",
            height: "auto",
          }}
          onClick={() => handleSelect(item.value)}
        >
          <Stack
            sx={{
              flexDirection: "row",
              minHeight: "30px",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {React.createElement(item.icon, {
              color: "black",
              w: 15,
            })}
            <Typography
              sx={{ font: "normal normal 300 11px/15px Source Serif Pro" }}
            >
              {item.value}
            </Typography>
          </Stack>
        </MenuItem>
      ))}
    </Stack>
  );
};

export default ElementsOptionsList;
