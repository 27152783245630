import React from "react";
import style from "./XlargeCreateButton.module.css";
import { Box, Button, ButtonProps, Stack, Typography } from "@mui/material";
import { ButtonType, XlargeCreateButtonColor } from "../AllButtonProps";

interface XlargeCreateButtonProps extends ButtonProps {
  label: string;
  subtitle: string;
  colorVarient: XlargeCreateButtonColor;
  icon: React.ReactElement;
  types?: ButtonType;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  popRef?: any;
}

const XlargeCreateButton: React.FC<XlargeCreateButtonProps> = ({
  icon,
  label,
  subtitle,
  colorVarient,
  types = ButtonType.Button,
  onClick,
  popRef,
  ...props
}) => {
  const enhancedIcon = React.cloneElement(icon, { color: "white", w: "15px" });
  return (
    <Button
      disableElevation
      variant="contained"
      type={types}
      ref={popRef && popRef}
      onClick={onClick}
      // startIcon={icon}
      className={`
        ${style.XlargeCreateButton}
        ${style[`XlargeCreateButton--${colorVarient}`]}
      `}
      {...props}
    >
      <Stack direction={"row"} gap={"5px"} alignItems={"center"}>
        <Box height={"15px"} width={"15px"} boxSizing={"border-box"}>
          {enhancedIcon}
        </Box>
        <Stack sx={{ textAlign: "left" }}>
          <Typography className={style.XlargeCreateButton__title}>
            {label}
          </Typography>
          <Typography className={style.XlargeCreateButton__subtitle}>
            {subtitle}
          </Typography>
        </Stack>
      </Stack>
    </Button>
  );
};

export default XlargeCreateButton;
