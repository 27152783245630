// DropDownButton.tsx

import React, { useState } from "react";
import { ArrowAllIcon, StoreIcon } from "../../../../../images";
import { Button, Stack, Typography, IconButton, Menu } from "@mui/material";
import g from "./DropDownButton.module.css";
import {
  DefaultMenu,
  defaultMenuItems,
  ImportExportMenu,
  importExportMenuItems,
  SortAndFilterMenu,
  sortOrFilterItemMenu,
} from "./MenuOption";
import { MenuItemData } from "./menuUtils";

type DropDownButtonProps = {
  label: string;
  componentType?: string;
  componentIcon?: React.ReactNode;
};

const DropDownButton: React.FC<DropDownButtonProps> = React.memo(
  ({ label, componentType, componentIcon }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    // Initialize menuItems based on componentType
    const [menuItems, setMenuItems] = useState<MenuItemData[]>(
      componentType === "CSV"
        ? importExportMenuItems
        : componentType === "Sort" || componentType === "Filter"
          ? sortOrFilterItemMenu
          : defaultMenuItems
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <Button
          className={g.boxDropDownButton}
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          startIcon={componentIcon || <StoreIcon w={18} />}
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={5}
            justifyContent="space-between"
          >
            <Typography className={g.labelDropDownButton} children={label} />
            <IconButton
              sx={{
                padding: "5px",
              }}
            >
              <ArrowAllIcon w={18} open={open} />
            </IconButton>
          </Stack>
        </Button>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{
            width:
              componentType === "Sort" || componentType === "Filter"
                ? "350px"
                : "250px",
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {componentType === "Sort" || componentType === "Filter" ? (
            <SortAndFilterMenu
              label={componentType === "Sort" ? "Sort" : "Filter"}
              menuItems={menuItems}
              setMenuItems={setMenuItems}
            />
          ) : componentType === "CSV" ? (
            <ImportExportMenu
              menuItems={menuItems}
              setMenuItems={setMenuItems}
            />
          ) : (
            <DefaultMenu menuItems={menuItems} setMenuItems={setMenuItems} />
          )}
        </Menu>
      </>
    );
  }
);

export default DropDownButton;
