import React from "react";
import { Drawer, Stack } from "@mui/material";
import { styled } from "@mui/system";
import CompanyIconAndName from "../../CompanyIconAndName";
import { IconWordLongButton, MainActionButton } from "../../../Buttons";
import DrawerSectionText from "../../DrawerSectionText";
import styles from "../../DrawerNavbar.module.css";
import {
  SETTINGS_1_NAVBAR_ROUTES,
  SETTINGS_2_NAVBAR_ROUTES,
  SETTINGS_3_NAVBAR_ROUTES,
} from "./SettingsType";
import { ButtonType, MainActionColor } from "../../../Buttons/AllButtonProps";
type SettingsDrawerProps = {
  drawerOpen: boolean;
  drawerCloser: () => void;
};
const DrawerContainerSettings = styled("div")({
  position: "fixed",
  top: 0,
  height: "100vh",
  zIndex: 1100, // Ensure the drawer is behind the navbar
});
const SettingsDrawer: React.FC<SettingsDrawerProps> = ({
  drawerOpen,
  drawerCloser,
}) => {
  return (
    <>
      <DrawerContainerSettings>
        <Drawer
          sx={{
            position: "absolute",
            top: 0,
            height: "100vh",
            width: "215px",
            // left: '-240px', // Start hidden behind the Navbar
            transition: drawerOpen
              ? "left 225ms ease-out"
              : "left 195ms ease-in",
            left: drawerOpen ? "30px" : "-215px",
            zIndex: 1100, // Ensure the drawer is behind the navbar
            "& .MuiPaper-root": {
              position: "absolute",
              top: 0,
              height: "100vh",
              width: "215px",
              left: 0,
              boxSizing: "border-box",
              zIndex: 1100, // Ensure the paper is also behind the navbar
            },
          }}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
        >
          <Stack
            bgcolor={"white"}
            direction="column"
            flex={1}
            className={`${styles.drawerNavbar__drawer}`}
          >
            {/* Company logo and name here */}
            <CompanyIconAndName arrowButtonClickHandler={drawerCloser} />
            <Stack className={styles.drawerNavbar__content}>
              {/* Buttons and other lists here */}
              <MainActionButton
                sx={{ marginY: "25px" }}
                onClick={() => console.log("Home button clicked")}
                colorVarient={MainActionColor.Blue}
                label="Home"
                types={ButtonType.Button}
              />
              <Stack
                direction="column"
                // Adjust the height of the area where the content of the drawer is visible
                height={"500px"}
                sx={{
                  overflowY: "auto",
                  flexGrow: 1,
                  scrollbarWidth: "none",
                }}
                // sx={{ overflow: "hidden" }}
              >
                <Stack direction="column">
                  <Stack minHeight={"153px"}>
                    <DrawerSectionText title="Basic Settings" />
                    <Stack mt={"15px"} gap={"15px"}>
                      {SETTINGS_1_NAVBAR_ROUTES.map((settings) => {
                        return (
                          <IconWordLongButton
                            key={settings.id}
                            text={settings.name}
                            iconSrc={settings.iconSrcs}
                            toMuiLink={settings.nUrl}
                          />
                        );
                      })}
                    </Stack>
                  </Stack>
                  <Stack mt={"20px"} minHeight={"90px"}>
                    <DrawerSectionText title="Advanced" />
                    <Stack mt={"15px"} gap={"15px"}>
                      {SETTINGS_2_NAVBAR_ROUTES.map((settings) => {
                        return (
                          <IconWordLongButton
                            key={settings.id}
                            text={settings.name}
                            iconSrc={settings.iconSrcs}
                            toMuiLink={settings.nUrl}
                          />
                        );
                      })}
                    </Stack>
                  </Stack>
                  <Stack mt={"25px"} minHeight={"153px"}>
                    <DrawerSectionText title="AI Controls" />
                    <Stack mt={"15px"} gap={"15px"}>
                      {SETTINGS_3_NAVBAR_ROUTES.map((settings) => {
                        return (
                          <IconWordLongButton
                            key={settings.id}
                            text={settings.name}
                            iconSrc={settings.iconSrcs}
                            toMuiLink={settings.nUrl}
                          />
                        );
                      })}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Drawer>{" "}
      </DrawerContainerSettings>
    </>
  );
};

export default SettingsDrawer;
