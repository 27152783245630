import { Box, IconButton, Popover, Stack, Tooltip } from "@mui/material";
import React, { MouseEvent, useState, useEffect } from "react";
import {
  LightCirclesColorEnum,
  LightCirclesColorListInterface,
} from "./LightCirclesColorType";

interface LightCirclesColorProps {
  initialSelect?: LightCirclesColorEnum;
  onSelect?: (color: string) => void;
  colorsList: LightCirclesColorListInterface[];
}

const LightCirclesColor: React.FC<LightCirclesColorProps> = ({
  initialSelect,
  onSelect,
  colorsList,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedColor, setSelectedColor] = useState<string>(
    initialSelect || colorsList[0].colorvalue
  );

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (initialSelect) {
      setSelectedColor(initialSelect);
    }
  }, [initialSelect]);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleColorSelect = (color: string) => {
    setSelectedColor(color);
    onSelect?.(color); // Shortened version of `if (onSelect) { onSelect(color); }`
    handlePopoverClose();
  };

  return (
    <>
      <Tooltip title="Color Picker" placement="top" arrow>
        <Stack
          onClick={handlePopoverOpen}
          sx={{
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            boxSizing: "border-box",
            bgcolor: selectedColor,
            border: selectedColor === "#ffffff" ? "1px solid #E9EDF2" : "none",
          }}
        />
      </Tooltip>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ marginTop: "10px" }}
        PaperProps={{
          style: { boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)" },
        }}
      >
        <Stack sx={{ padding: "7px", flexDirection: "row", gap: "15px" }}>
          {colorsList.map((item) => (
            <IconButton
              key={item.colorvalue}
              sx={{ padding: 0 }}
              disableRipple
              onClick={() => handleColorSelect(item.colorvalue)}
            >
              <Box
                sx={{
                  height: "20px",
                  width: "20px",
                  borderRadius: "50%",
                  bgcolor: item.colorvalue,
                  border:
                    item.colorvalue === "#ffffff"
                      ? "0.5px solid #E9EDF2"
                      : "none",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              />
            </IconButton>
          ))}
        </Stack>
      </Popover>
    </>
  );
};

export default LightCirclesColor;
