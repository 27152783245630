import { Divider, Stack } from "@mui/material";
import React from "react";
import { TextPickerDropdown } from "../TextPickerDropdown";
import { FontPickerdDropdown } from "../FontPickerdDropdown";
import { TextSizeDropdown } from "../TextSizeDropdown";
import { FontDecoration } from "../FontDecoration";
import { SmallColorPicker } from "../SmallColorPicker";
import { LineSpacingDropdown } from "../LineSpacingDropdown";
import { LineSpacingDropdownOptions } from "../LineSpacingDropdown/LineSpacingDropdownType";
import { BulletPointDropdown } from "../BulletPointDropdown";
import { BulletPointDropdownOptions } from "../BulletPointDropdown/BulletPointDropdownType";
import { AddLinkDropdown } from "../AddLinkDropdown";
import { AttechFile } from "../AttechFile";
import { AddComment } from "../AddComment";
import { ColorCirclePicker } from "../ColorCirclePicker";

interface TextRichPopupProps {
  changeObjectFillColor: (color: string) => void;
  changeFontSize: (size: string) => void;
  changeObjectBgColor: (bgColor: string) => void;
  toggleBold: () => void;
  toggleItalic: () => void;
  toggleUnderline: () => void;
  toggleStrikethrough: () => void;
  changeTextAlignment: (args: string) => void;
  applyTextFormat: (args: string) => void;
  applySubscriptSuperscript: (args: string) => void;
  addLinkedText: (text: string, url: string) => void;
  addCommentToObject: (args: string) => void;
  changeFontFamily: (fontFamily: string) => void;
  selectedObject?: any | null;
}

const TextRichPopup: React.FC<TextRichPopupProps> = ({
  changeObjectFillColor,
  changeFontSize,
  changeObjectBgColor,
  toggleBold,
  toggleItalic,
  toggleUnderline,
  toggleStrikethrough,
  changeTextAlignment,
  applyTextFormat,
  applySubscriptSuperscript,
  addLinkedText,
  addCommentToObject,
  changeFontFamily,
  selectedObject = null,
}) => {
  const handleTextStyle = (name: string) => {
    switch (name) {
      case "Normal text":
        changeFontSize("14");
        break;
      case "Title text":
        changeFontSize("24");
        break;
      case "Subtitle":
        changeFontSize("20");
        break;
      case "Heading 1":
        changeFontSize("32");
        break;
      case "Heading 2":
        changeFontSize("28");
        break;
      case "Heading 3":
        changeFontSize("24");
        break;
      case "Heading 4":
        changeFontSize("20");
        break;
      case "Heading 5":
        changeFontSize("18");
        break;
      case "Heading 6":
        changeFontSize("16");
        break;
      default:
        console.warn(`Unknown text style: ${name}`);
        break;
    }
  };
  
  return (
    <Stack
      sx={{
        height: "40px",
        flexShrink: 0,
        border: "0.5px solid #E9EDF2",
        borderRadius: "5px",
        padding: "5px 5px 5px 10px",
        boxSizing: "border-box",
        flexDirection: "row",
        alignItems: "center",
        gap: "15px",
        bgcolor: "white",
      }}
    >
      <ColorCirclePicker onSelect={(value) => changeObjectFillColor(value)} />{" "}
      {/* Marge condition */}
      <ColorCirclePicker
        onSelect={(value) => changeObjectBgColor(value)}
      />{" "}
      {/* Marge condition */}
      {((selectedObject && selectedObject?.type === "i-text") ||
        selectedObject?.type === "textbox") && (
        <TextPickerDropdown onSelect={(value) => handleTextStyle(value)} />
      )}
      {/* -------------Divider----------- */}
      <Divider orientation="vertical" sx={{ border: " 0.5px solid #E9EDF2" }} />
      {/* -------------Divider----------- */}
      {((selectedObject && selectedObject?.type === "i-text") ||
        selectedObject?.type === "textbox") && (
        <FontPickerdDropdown onSelect={(value) => changeFontFamily(value)} />
      )}
      {((selectedObject && selectedObject?.type === "i-text") ||
        selectedObject?.type === "textbox") && (
        <>
          {" "}
          {/* -------------Divider----------- */}
          <Divider
            orientation="vertical"
            sx={{ border: " 0.5px solid #E9EDF2" }}
          />
          {/* -------------Divider----------- */}
          <TextSizeDropdown
            onSizeChange={(value) => changeFontSize(value)}
          />{" "}
          {/* -------------Divider----------- */}
          <Divider
            orientation="vertical"
            sx={{ border: " 0.5px solid #E9EDF2" }}
          />
        </>
      )}
      {/* -------------Divider----------- */}
      {((selectedObject && selectedObject?.type === "i-text") ||
        selectedObject?.type === "textbox") && (
        <>
          <FontDecoration
            toggleUnderline={toggleUnderline}
            toggleStrikethrough={toggleStrikethrough}
            toggleBold={toggleBold}
            toggleItalic={toggleItalic}
            applySubscriptSuperscript={applySubscriptSuperscript}
          />{" "}
          {/* -------------Divider----------- */}
          <Divider
            orientation="vertical"
            sx={{ border: " 0.5px solid #E9EDF2" }}
          />
        </>
      )}
      {/* -------------Divider----------- */}
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "15px" }}>
        <SmallColorPicker onSelect={(value) => console.log(value)} />
        {((selectedObject && selectedObject?.type === "i-text") ||
          selectedObject?.type === "textbox") && (
          <>
            <LineSpacingDropdown
              option={LineSpacingDropdownOptions}
              onSelect={(value) => changeTextAlignment(value)}
            />
            <BulletPointDropdown
              option={BulletPointDropdownOptions}
              onSelect={(value) => applyTextFormat(value)}
            />
            {/* -------------Divider----------- */}
            <Divider
              orientation="vertical"
              sx={{ border: " 0.5px solid #E9EDF2" }}
            />
          </>
        )}
      </Stack>
      {/* -------------Divider----------- */}
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
        <AddLinkDropdown
          onApply={(value) => addLinkedText(value.url, value.text)}
        />
        <AttechFile onSelect={(file) => console.log(file)} />
        <AddComment addCommentToObject={addCommentToObject} />
      </Stack>
    </Stack>
  );
};

export default TextRichPopup;
