import React, { useState } from "react";
import { Button, Menu, MenuItem, Typography, ButtonProps } from "@mui/material";
import styles from "./InternalSharedButton.module.css";
import {
  ButtonType,
  InternalShareButtonOptionValue,
  SmallButtonColor,
} from "../AllButtonProps";

export interface InternalSharedButtonProps extends ButtonProps {
  colorVariant?: SmallButtonColor;
  type?: ButtonType;
  selectedOption: string;
  resetSelection?: () => void;
  onSelectionChange: (value: string) => void;
}

const DROPDOWN_OPTIONS: {
  id: number;
  value: InternalShareButtonOptionValue;
}[] = [
  { id: 1, value: InternalShareButtonOptionValue.Editor },
  { id: 2, value: InternalShareButtonOptionValue.Guest },
  { id: 3, value: InternalShareButtonOptionValue.Commenter },
  { id: 4, value: InternalShareButtonOptionValue.Remove },
];

const InternalSharedButton: React.FC<InternalSharedButtonProps> = React.memo(
  ({
    colorVariant = SmallButtonColor.Orange,
    type = ButtonType.Button,
    selectedOption,
    resetSelection,
    onSelectionChange,
    sx,
    disabled,
    ...props
  }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const buttonWidth = 90; // Adjust this width as needed

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!disabled) {
        setAnchorEl(event.currentTarget);
      }
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleMenuItemClick = (value: string) => {
      if (
        value.toLowerCase() !==
        InternalShareButtonOptionValue.Remove.toLowerCase()
      ) {
        onSelectionChange(value);
      }
      handleClose();
    };

    const buttonClass = `${styles["internal-shared-button"]} ${styles[`internal-shared-button--${colorVariant}`]}`;
    const disableRipple = colorVariant === SmallButtonColor.Transparent;

    // Filter options to conditionally include "Remove" based on selectedOption
    const filteredOptions =
      selectedOption !== "Add now"
        ? DROPDOWN_OPTIONS
        : DROPDOWN_OPTIONS.filter(
            (option) =>
              option.value.toLowerCase() !==
              InternalShareButtonOptionValue.Remove.toLowerCase()
          );

    return (
      <div>
        <Button
          sx={{
            width: `${buttonWidth}px`,
            height: "25px",
            font: "normal normal normal 13px/17px Source Serif Pro",
            ...(disabled && { cursor: "not-allowed" }),
            ...sx,
          }}
          className={buttonClass}
          variant="contained"
          type={type}
          {...props}
          disableRipple={disableRipple}
          disableElevation
          aria-label={selectedOption}
          onClick={handleClick}
        >
          {selectedOption}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          className={styles["internal-shared-button__menu"]}
          MenuListProps={{
            style: {
              width: `${buttonWidth}px`,
              padding: "0px",
              borderRadius: "5px",
              border: "0.5px solid #E9EDF2",
              boxShadow: "5px 5px 10px #00000029",
            },
          }}
        >
          {filteredOptions.map((option) => (
            <MenuItem
              key={option.id}
              className={styles["internal-shared-button__menu-item"]}
              onClick={() => handleMenuItemClick(option.value)}
            >
              <Typography
                className={styles["internal-shared-button__menu-item-text"]}
                sx={{
                  color:
                    option.value.toLowerCase() ===
                    InternalShareButtonOptionValue.Remove.toLowerCase()
                      ? "red"
                      : "black",
                }}
              >
                {option.value}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
);

export default InternalSharedButton;
