import { createSlice } from "@reduxjs/toolkit";
import { AuthState } from "./authTypes";
import {
  /* Forgot */
  forgotPasswordFailure,
  forgotPasswordRequest,
  forgotPasswordSuccess,

  /* Auth with google */
  googleAuthFailure,
  googleAuthRequest,
  googleAuthSuccess,

  /* Login with Bearish */
  loginFailure,
  loginRequest,
  loginSuccess,

  /* Logout */
  logoutRequest,

  /* Auth with microsoft */
  microsoftAuthFailure,
  microsoftAuthRequest,
  microsoftAuthSuccess,

  /* Opt send */
  optSendFailure,
  optSendRequest,
  optSendSuccess,

  /* Opt Verify */
  optVerificationFailure,
  optVerificationRequest,
  optVerificationSuccess,

  /* state reset for opt */
  resetOptSend,
  resetOptVerify,

  /* Signup with bearish */
  signUpFailure,
  signUpRequest,
  signUpSuccess,

  /* Company availibity check */
  companyAvailibityRequest,
  companyAvailibitySuccess,
  companyAvailibityFailure,

  /* department list */
  getAllDepartmentsRequest,
  getAllDepartmentsSuccess,
  getAllDepartmentsFailure,

  /* BrandFetch Company list */
  fetchCompanyListRequest,
  fetchCompanyListFailure,
  fetchCompanyListSuccess,

  /* temp auth store rarely used */
  tempAuthStoreSuccess,
  tempAuthStoreRequest,
  getAllUsersRequest,
  getAllUsersSuccess,
  getAllUsersFailure,
} from "./AuthSliceActions";

/*
 * ==============================================
 * Define the initial state for the authentication slice
 * ==============================================
 */
const initialState: AuthState = {
  isAuthenticated: false, // Indicates if the user is authenticated
  user: null, // Stores user information when authenticated
  error: null, // Stores error messages if any
  isSignUp: false, // Add the isSignUp property
  externalEntities: null, // Initialize externalEntities as null
  eMessage: null, //  external entities
};

/*
 * ==============================================
 * Create a slice for authentication logic
 * ==============================================
 */
const authSlice = createSlice({
  name: "auth", // Name of the slice
  initialState, // Initial state of the slice
  reducers: {
    // Reducers to handle different actions

    loginRequest,
    loginSuccess,
    loginFailure,

    optSendRequest,
    optSendSuccess,
    optSendFailure,

    optVerificationRequest,
    optVerificationSuccess,
    optVerificationFailure,

    logoutRequest,
    signUpRequest,

    googleAuthRequest,
    microsoftAuthRequest,

    signUpSuccess,

    googleAuthSuccess,

    microsoftAuthSuccess,

    signUpFailure,

    googleAuthFailure,
    microsoftAuthFailure,

    forgotPasswordRequest,
    forgotPasswordSuccess,
    forgotPasswordFailure,

    resetOptSend,
    resetOptVerify,

    companyAvailibityRequest,
    companyAvailibitySuccess,
    companyAvailibityFailure,

    getAllDepartmentsRequest,
    getAllDepartmentsSuccess,
    getAllDepartmentsFailure,

    fetchCompanyListRequest,
    fetchCompanyListFailure,
    fetchCompanyListSuccess,

    tempAuthStoreSuccess,
    tempAuthStoreRequest,

    getAllUsersRequest,
    getAllUsersSuccess,
    getAllUsersFailure,
  },
});

/*
 * ======================================================
 * Export the actions to be used in dispatch calls
 * ======================================================
 */
export const {
  /* Login */
  loginRequest: loginRequestAction,
  loginSuccess: loginSuccessAction,
  loginFailure: loginFailureAction,

  /* Signup */
  signUpRequest: signUpRequestAction,
  signUpSuccess: signUpSuccessAction,
  signUpFailure: signUpFailureAction,

  /* google signup/login */
  googleAuthRequest: googleAuthRequestAction,
  googleAuthSuccess: googleAuthSuccessAction,
  googleAuthFailure: googleAuthFailureAction,

  /* microsoft signup/login */
  microsoftAuthRequest: microsoftAuthRequestAction,
  microsoftAuthSuccess: microsoftAuthSuccessAction,
  microsoftAuthFailure: microsoftAuthFailureAction,

  /* Opt send*/
  optSendRequest: optSendRequestAction,
  optSendSuccess: optSendSuccessAction,
  optSendFailure: optSendFailureAction,

  /* Opt verify */
  optVerificationRequest: optVerificationRequestAction,
  optVerificationSuccess: optVerificationSuccessAction,
  optVerificationFailure: optVerificationFailureAction,

  /* Opt reset state in frontend */
  resetOptSend: resetOptSendAction,

  /* Otp reset state for verify */
  resetOptVerify: resetOptVerifyAction,

  /* Logout */
  logoutRequest: logoutRequestAction,

  /* Forgot */
  forgotPasswordRequest: forgotPasswordRequestAction,
  forgotPasswordSuccess: forgotPasswordSuccessAction,
  forgotPasswordFailure: forgotPasswordFailureAction,

  /* Company Avaibility check */
  companyAvailibityRequest: companyAvailibityRequestAction,
  companyAvailibitySuccess: companyAvailibitySuccessAction,
  companyAvailibityFailure: companyAvailibityFailureAction,

  /* Get all department */
  getAllDepartmentsRequest: getAllDepartmentsRequestAction,
  getAllDepartmentsSuccess: getAllDepartmentsSuccessAction,
  getAllDepartmentsFailure: getAllDepartmentsFailureAction,

  /* Fetch company list */
  fetchCompanyListRequest: fetchCompanyListRequestAction,
  fetchCompanyListSuccess: fetchCompanyListSuccessAction,
  fetchCompanyListFailure: fetchCompanyListFailureAction,

  /* Temp state that rarely used */
  tempAuthStoreRequest: tempAuthStoreRequestAction,
  tempAuthStoreSuccess: tempAuthStoreSuccessAction,

      /* Get all users */
      getAllUsersRequest: getAllUsersRequestAction,
      getAllUsersSuccess: getAllUsersSuccessAction,
      getAllUsersFailure: getAllUsersFailureAction,

} = authSlice.actions;

/*
 * ======================================================
 * Export the reducer to be used in the store
 * ======================================================
 */
export default authSlice.reducer;
