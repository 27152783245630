import { useState } from "react";

export const useShareButton = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [tooltipTitle, setTooltipTitle] = useState<string>("Copy Link");
  const [selectedTab, setSelectedTab] = useState<string>("share");
  const [selectedOption, setSelectedOption] = useState<string>("Add now");

  // Handle click on the main Share button to open the Popover
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle closing of the Popover
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handle copy link click, show "Link Copied" for 2 seconds, then revert
  const handleCopyLinkClick = () => {
    setTooltipTitle("Link Copied");
    console.log("link");
    setTimeout(() => {
      setTooltipTitle("Copy Link");
    }, 2000);
  };



  // Reset selection handler
  const resetSelection = () => {
    setSelectedOption("Add now");
  };

  const open = Boolean(anchorEl);
  const id = open ? "create-button-popover" : undefined;

  return {
    anchorEl,
    tooltipTitle,
    selectedTab,
    selectedOption,
    open,
    id,
    handleClick,
    handleClose,
    handleCopyLinkClick,
    resetSelection,
    setSelectedTab,
  };
};
