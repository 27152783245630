import { Menu, MenuProps, styled } from "@mui/material";

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(() => ({
  "& .MuiPaper-root": {
    borderRadius: 5,
    marginTop: "10px",
    minWidth: 250,
    boxShadow: "0px 3px 6px #00000029",
    "& .MuiMenu-list": {
      padding: "0px",
    },
  },
}));

export const StyledSubMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(() => ({
  "& .MuiPaper-root": {
    borderRadius: 5,
    minWidth: 150,
    boxShadow: "0px 3px 6px #00000029",
  },
}));
