export interface DropdownWithColorOptionTypes {
  id: number;
  value: string;
  defaultColorHex: string;
  customColorHex: string;
}

export const DUMMY_TODO_OPTIONS: DropdownWithColorOptionTypes[] = [
  {
    id: 1,
    value: "To Do",
    defaultColorHex: "#808080",
    customColorHex: "#808080",
  },
  {
    id: 2,
    value: "In Progress",
    defaultColorHex: "#007BFF",
    customColorHex: "#007BFF",
  },
  {
    id: 3,
    value: "In Review",
    defaultColorHex: "#FFA500",
    customColorHex: "#FFA500",
  },
  {
    id: 4,
    value: "Done",
    defaultColorHex: "#28A745",
    customColorHex: "#28A745",
  },
  {
    id: 5,
    value: "Delayed",
    defaultColorHex: "#DC3545",
    customColorHex: "#DC3545",
  },
];

export const DUMMY_DEALSTAGE_OPTIONS: DropdownWithColorOptionTypes[] = [
  {
    id: 1,
    value: "Lead",
    defaultColorHex: "#808080",
    customColorHex: "#808080",
  },
  {
    id: 2,
    value: "Intro",
    defaultColorHex: "#007BFF",
    customColorHex: "#007BFF",
  },
  {
    id: 3,
    value: "Pitch",
    defaultColorHex: "#FFA500",
    customColorHex: "#FFA500",
  },
  {
    id: 4,
    value: "Due diligence",
    defaultColorHex: "#28A745",
    customColorHex: "#28A745",
  },
  {
    id: 5,
    value: "Term sheet",
    defaultColorHex: "#DC3545",
    customColorHex: "#DC3545",
  },
];


export const DUMMY_PRIORITY_OPTIONS: DropdownWithColorOptionTypes[] = [
  {
    id: 1,
    value: "Low",
    defaultColorHex: "#28A745", // Green
    customColorHex: "#28A745", // Green
  },
  {
    id: 2,
    value: "Medium",
    defaultColorHex: "#FFC107", // Yellow
    customColorHex: "#FFC107", // Yellow
  },
  {
    id: 3,
    value: "High",
    defaultColorHex: "#DC3545", // Red
    customColorHex: "#DC3545", // Red
  },
  {
    id: 4,
    value: "Critical",
    defaultColorHex: "#FF5733", // Dark Orange
    customColorHex: "#FF5733", // Dark Orange
  },
  {
    id: 5,
    value: "Urgent",
    defaultColorHex: "#C70039", // Dark Red
    customColorHex: "#C70039", // Dark Red
  },
];
