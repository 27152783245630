import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { ChecklistIcon, PlusIcon } from "../../../../../../../images";
import { GreenArrowButton, SmallChecklist } from "../../../../../../common";

const CheckList: React.FC = () => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [checklistText, setChecklistText] = React.useState("");

  const toggleContainer = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <Stack sx={{ bgcolor: "white" }}>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack sx={{ flexDirection: "row", gap: "10px", alignItems: "center" }}>
          <ChecklistIcon w={24} />
          <Typography
            sx={{ font: "normal normal normal 18px/25px Source Serif Pro" }}
          >
            Checklist
          </Typography>
          {/* total subtask */}
          {/* use [responce-data.length] here for dynamic value */}
          <Typography
            sx={{ font: "normal normal bold 14px/20px Source Serif Pro" }}
          >
            2
          </Typography>
          <GreenArrowButton
            sx={{
              transform: isExpanded ? "rotate(90deg)" : "rotate(270deg)",
              transition: "transform 0.2s ease-in-out",
            }}
            onClick={toggleContainer}
          />
        </Stack>
        <Tooltip title="Add a new Checklist" placement="top" arrow>
          <IconButton sx={{ padding: 1 }}>
            <PlusIcon w={14} />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack
        sx={{
          maxHeight: isExpanded ? "270px" : "0px",
          overflow: "hidden",
          transition: "max-height 0.3s ease",
          overflowY: "scroll",
          scrollbarWidth: "none",
          marginTop: "15px",
          gap: "20px",
        }}
      >
        <SmallChecklist
          value={checklistText}
          onSave={(newText) => setChecklistText(newText)}
        />
        <SmallChecklist
          value={checklistText}
          onSave={(newText) => setChecklistText(newText)}
        />
        <SmallChecklist
          value={checklistText}
          onSave={(newText) => setChecklistText(newText)}
        />
      </Stack>
    </Stack>
  );
};

export default CheckList;
