import { Box, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import g from "./General.module.css";
import {
  AvatarLarge,
  BgColorCode,
  ButtonSmall,
  DropdownLarge,
  DropdownLargeSingle,
  DropdownWIconLarge,
  IconButtonLong,
  MediumInputIconBox,
} from "../../../../common";
import {
  LocationPng,
  Phone50Icon,
  Phone64Icon,
  TimeZonePng,
} from "../../../../../images";
import {
  ButtonType,
  SmallButtonColor,
} from "../../../../common/Buttons/AllButtonProps";
import {
  getCurrentDateFormats,
  getCurrentTimeFormats,
} from "../../../../../Utils";
import { getAllCountries, getAllTimezonesFormatted } from "../common";
import { getDepartmentList } from "../../../../../Utils/authService";
import { getAllDepartment } from "../../../../../redux";
import { useDispatch } from "react-redux";
import {
  Department,
  UserProfileData,
  initialUserProfileData,
} from "./generalTypes";

type GeneralProps = {};

const WorkingLocationOptions = [
  "In office",
  "Remote",
  "Out sick",
  "On break",
  "Out of office",
  "Working outside",
  "Personal time",
];
const LanguageOptions = [
  "English",
  "日本語",
  "Español",
  "Français",
  "عربي",
  "Italiano",
  "Português",
];
// const OperationOptions = [
//   "Human Resources",
//   "Sales",
//   "Marketing",
//   "Customer Service",
//   "Opearations",
//   "R&D",
//   "Finance",
//   "Other",
// ];

const timeFormat = getCurrentTimeFormats();
const dateFormat = getCurrentDateFormats();

const General: React.FC<GeneralProps> = () => {
  const dispatch = useDispatch();
  const [userProfileData, setUserProfileData] = useState<UserProfileData>(
    initialUserProfileData
  );

  console.log(userProfileData, "userProfileData");

  const [gAllDep, setGAllDep] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Assuming getDepartmentList() returns a promise that resolves to an array
        const departments = await getDepartmentList();
        if (departments.length === 0) {
          await getALlDepartments();
        }
        setGAllDep(departments);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs only once after the initial render

  const getALlDepartments = async () => {
    try {
      // Assuming getAllDepartment() is an async action creator that dispatches an action
      await dispatch(getAllDepartment());
      // After dispatching, you might want to update gAllDep state again if necessary
      const updatedDepartments = await getDepartmentList();
      setGAllDep(updatedDepartments);
    } catch (error) {
      console.error("Error getting all departments:", error);
    }
  };
  const [file, setFile] = useState<string | undefined>(undefined);
  const [locations, setLocations] = useState<string[]>([]);
  const [timezones, setTimezones] = useState<string[]>([]);

  useEffect(() => {
    const fetchedLocations = getAllCountries();
    const fetchedTimezones = getAllTimezonesFormatted();
    setLocations(fetchedLocations?.map((location) => location?.name));
    setTimezones(
      fetchedTimezones?.map((timezone) => timezone?.name || timezone?.id)
    );
  }, []);

  // Handler to update form data
  const handleInputChange = (name: string, value: string) => {
    setUserProfileData({
      ...userProfileData,
      [name]: value,
    });
  };
  // Example handler for file input (avatarPic)
  const handleFileChange = (file: File) => {
    if (!file) {
      return;
    } /* Peserve the data below */
    const fileUrl = URL.createObjectURL(file);
    setFile(fileUrl); // Update file state with the created object URL
    setUserProfileData({
      ...userProfileData,
      avatarPic: file || null,
    });
  };

  // Example handler for department select
  const handleDepartmentChange = (departments: Department[]) => {
    setUserProfileData({
      ...userProfileData,
      department: departments,
    });
  };

  // Define array of field configurations
  const fieldsConfig_One = [
    {
      label: "First Name",
      name: "firstName",
      value: userProfileData.firstName,
    },
    { label: "Last Name", name: "lastName", value: userProfileData.lastName },
  ];

  const fieldsConfig_Two = [
    {
      label: "Email Address",
      name: "emailAddress",
      value: userProfileData.emailAddress,
    },
    {
      label: "Job Title",
      name: "jobTitle",
      value: userProfileData.jobTitle,
    },
  ];

  const fieldsConfig_Three = [
    {
      label: "Working Location",
      options: WorkingLocationOptions,
      componentHeader: "Working location",
      name: "workingLocation",
    },
    {
      label: "Language",
      options: LanguageOptions,
      componentHeader: "Language",
      name: "language",
    },
  ];

  const fieldsConfig_Four = [
    {
      label: "Phone number",
      value: userProfileData.phoneNumber,
      name: "phoneNumber",
      icon: <Phone50Icon />,
    },
    {
      label: "Mobile number",
      value: userProfileData.mobileNumber,
      name: "mobileNumber",
      icon: <Phone64Icon />,
    },
  ];

  const fieldConfig_Five = [
    {
      label: "Location",
      options: locations,
      iconSrc: LocationPng,
      name: "location",
    },
    {
      label: "Timezone",
      options: timezones,
      iconSrc: TimeZonePng,
      name: "timeZone",
    },
  ];

  const fieldConfig_Six = [
    {
      label: "Time format",
      options: timeFormat,
      componentHeader: "Time format",
      name: "timeFormat",
    },
    {
      label: "Date format",
      options: dateFormat,
      componentHeader: "Date format",
      name: "dateFormat",
    },
  ];

  const fieldConfig_Seven = [
    {
      label: "Current password",
      name: "currentPassword",
      value: userProfileData.currentPassword,
    },
    {
      label: "New password",
      name: "newPassword",
      value: userProfileData.newPassword,
    },
    {
      label: "Confirm new password",
      name: "confirmNewPassword",
      value: userProfileData.confirmNewPassword,
    },
  ];

  return (
    <Box sx={{ padding: "20px 25px 25px 25px" }}>
      <div>
        <Typography
          className={g["gs-container-header"]}
          children={"General Settings"}
        />
      </div>
      <Stack>
        <Stack
          padding={"0 15px 0 15px"}
          direction={"row"}
          mt={"20px"}
          gap={"10px"}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
        >
          {/* Section 1 -- */}
          <Stack mt={"15px"}>
            {/* Avatar Container */}
            <AvatarLarge imgSrc={file} size={80} text="AA" bgColorCode={BgColorCode.Purple} />
            <div>
              <IconButtonLong onFileSelect={handleFileChange} />
            </div>
          </Stack>

          {/* Section 2 -- */}
          <Stack gap={"15px"} padding={"0 30px 0 15px"}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={"10px"}
            >
              {fieldsConfig_One.map((field) => {
                return (
                  <MediumInputIconBox
                    label={field.label}
                    customePass={true}
                    value={field.value || null}
                    onChange={(e) => {
                      handleInputChange(
                        field.name,
                        typeof e === "string" ? e : e.target.value
                      );
                    }}
                    name={field.name}
                  />
                );
              })}
            </Stack>

            {fieldsConfig_Two.map((field) => {
              return (
                <MediumInputIconBox
                  label={field.label}
                  customePass={true}
                  value={field.value}
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    handleInputChange(
                      field.name,
                      typeof e === "string" ? e : e.target.value
                    );
                  }}
                  name={field.name}
                />
              );
            })}
          </Stack>

          {/* Section 3 -- */}
          <Stack gap={"15px"} padding={"0 0 0 15px"}>
            {fieldsConfig_Three?.map((field) => {
              return (
                <DropdownLargeSingle
                  label={field.label}
                  options={field.options}
                  componentHeader={field.componentHeader}
                  borderColor={"lightBlue"}
                  onChange={(e) => {
                    handleInputChange(field.name, typeof e && e);
                  }}
                />
              );
            })}

            <DropdownLarge
              label="Department"
              options={gAllDep || []}
              componentHeader={"Operations"}
              borderColor={"lightBlue"}
              onChange={handleDepartmentChange}
              multiple={true} // Ensure multiple selection is enabled
              initialSelectedOptions={userProfileData.department}
            />
          </Stack>
        </Stack>
        <Stack mt={"15px"} mb={"15px"}>
          <Divider />
        </Stack>

        <Stack
          direction={"row"}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
          padding={"0 15px 0 15px"}
        >
          <Stack gap={"15px"}>
            {fieldsConfig_Four.map((field) => {
              return (
                <MediumInputIconBox
                  label={field.label}
                  value={field.value}
                  onChange={(e) => {
                    handleInputChange(
                      field.name,
                      typeof e === "string" ? e : e.target.value
                    );
                  }}
                  icon={field.icon}
                  name={field.name}
                  customePass={true}
                />
              );
            })}
          </Stack>
          <Stack gap={"15px"}>
            {fieldConfig_Five.map((field) => {
              return (
                <DropdownWIconLarge
                  label={field.label}
                  options={field.options}
                  iconSrc={field.iconSrc}
                  onChange={(e) => {
                    handleInputChange(field.name, typeof e && e);
                  }}
                />
              );
            })}
          </Stack>
          <Stack gap={"15px"}>
            {fieldConfig_Six.map((field) => {
              return (
                <DropdownLargeSingle
                  label={field.label}
                  options={field.options}
                  componentHeader={field.componentHeader}
                  borderColor={"lightBlue"}
                  onChange={(e) => {
                    handleInputChange(field.name, typeof e && e);
                  }}
                />
              );
            })}
          </Stack>
        </Stack>

        <Stack mt={"15px"} mb={"15px"}>
          <Divider />
        </Stack>

        <Stack
          gap={"15px"}
          direction={"row"}
          padding={"0 15px 0 15px"}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          {fieldConfig_Seven.map((field) => {
            return (
              <MediumInputIconBox
                label={field.label}
                customePass={true}
                value={field.value || null}
                onChange={(e) => {
                  handleInputChange(
                    field.name,
                    typeof e === "string" ? e : e.target.value
                  );
                }}
                name={field.name}
              />
            );
          })}

          <ButtonSmall
            colorVarient={SmallButtonColor.Orange}
            label={"Save"}
            types={ButtonType.Button}
          />
        </Stack>

        <Stack mt={"15px"} padding={"0 15px 0 15px"}>
          <Typography className={g.fpr}>
            Forgot password? Reset via email
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default General;
