import { Box, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import {
  ColorPickerDot,
  SmallThreeDotMenu,
  // StyledTooltip,
} from "../../../../common";
import styles from "./LargeStackedAndImage.module.css";
import { formatDate } from "../../../../../Utils";

interface LargeStackedAndImageProps {
  projectName: string;
  dateCreated: string;
  imageUrl: string | File;
  handleNavigate?: (args: string) => void;
  _id: string;
  onClickAction?: (args: string, _id: string) => void;
  cardColor?: string;
  onThemeUpdate?: (args: string) => void;
}

const LargeStackedAndImage: React.FC<LargeStackedAndImageProps> = ({
  projectName,
  dateCreated,
  imageUrl,
  handleNavigate,
  _id,
  onClickAction,
  cardColor = "#461959",onThemeUpdate
}) => {
  const formattedDate = formatDate(dateCreated);
  return (
    <Stack className={styles.largeStackedAndImage}>
      <Stack
        onClick={() =>
          handleNavigate && handleNavigate(_id || "brokenIdentifier")
        }
        className={styles.largeStackedAndImage__left}
      >
        <Box
          component={"img"}
          className={styles.largeStackedAndImage__image}
          src={imageUrl as string}
        />
        <Typography className={styles.largeStackedAndImage__projectName}>
          {projectName}
        </Typography>
      </Stack>
      <Stack className={styles.largeStackedAndImage__right}>
        <Tooltip title="Edit color">
          <ColorPickerDot onColorChange={(color:string) => onThemeUpdate && onThemeUpdate(color)} initialColor={cardColor ? cardColor : "#461959"} />
        </Tooltip>
        <Typography
          onClick={() =>
            handleNavigate && handleNavigate(_id || "brokenIdentifier")
          }
          className={styles.largeStackedAndImage__createdOn}
        >
          Created on: {formattedDate}
        </Typography>
        <SmallThreeDotMenu
          onClickAction={(action) => {
            onClickAction && onClickAction(action, _id);
          }}
        />
      </Stack>
    </Stack>
  );
};

export default LargeStackedAndImage;
