import { IconButton, Stack } from "@mui/material";
import React from "react";
import {
  BoldIcon,
  ItalicIcon,
  StrikethroughIcon,
  TxIcon,
  UnderlineIcon,
} from "../../../images";

interface FontDecorationProps {
  toggleBold?: () => void;
  toggleItalic?: () => void;
  toggleUnderline?: () => void;
  toggleStrikethrough?: () => void;
  applySubscriptSuperscript?: (arg: string) => void;
}

const FontDecoration: React.FC<FontDecorationProps> = ({
  toggleBold,
  toggleItalic,
  toggleUnderline,
  toggleStrikethrough,
  applySubscriptSuperscript,
}) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        gap: "15px",
        alignItems: "center",
        flexShrink: 0,
        boxSizing: "border-box",
      }}
    >
      <IconButton
        sx={{
          p: 0,
          boxSizing: "border-box",
        }}
        onClick={() => toggleBold && toggleBold()}
      >
        <BoldIcon w={15} />
      </IconButton>

      <IconButton
        onClick={() => toggleItalic && toggleItalic()}
        sx={{ p: 0, borderRadius: 0 }}
      >
        <ItalicIcon w={15} />
      </IconButton>

      <IconButton
        onClick={() => toggleUnderline && toggleUnderline()}
        sx={{ p: 0, borderRadius: 0 }}
      >
        <UnderlineIcon w={15} />
      </IconButton>

      <IconButton
        onClick={() => toggleStrikethrough && toggleStrikethrough()}
        sx={{ p: 0, borderRadius: 0 }}
      >
        <StrikethroughIcon w={15} />
      </IconButton>

      <IconButton
        onClick={() =>
          applySubscriptSuperscript && applySubscriptSuperscript("superscript")
        }
        sx={{ p: 0, borderRadius: 0 }}
      >
        <TxIcon w={15} />
      </IconButton>
    </Stack>
  );
};

export default FontDecoration;
