import React, { useState } from "react";
import {
  Box,
  IconButton,
  Tooltip,
  TextField,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import g from "../centralComponentStyle.module.css";
import { CorrectIcon } from "../../../../../../../images";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../redux/store";
import { WorkspaceState } from "../../../../../../../redux";

interface TableComponentProps {
  columns: string[];
  rows: string[][];
  onUpdate: (newData: any) => void; // Callback to update the parent component or store
}

const TableComponent: React.FC<TableComponentProps> = ({
  columns,
  rows,
  onUpdate,
}) => {
  const workspaceState = useSelector(
    (state: RootState) => state.workspace as WorkspaceState
  );
  const { lockPage } = workspaceState || {};

  // Initialize with 2 rows and 2 columns if the props are empty
  const initializeTable = (cols: string[], rws: string[][]) => {
    const initialCols = cols.length ? cols : ["Column 1", "Column 2"];
    const initialRows = rws.length
      ? rws
      : [
          Array(initialCols.length).fill(""),
          Array(initialCols.length).fill(""),
        ];
    return { columns: initialCols, rows: initialRows };
  };

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [tableData, setTableData] = useState<{
    columns: string[];
    rows: string[][];
  }>(initializeTable(columns, rows));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAddColumn = (position: "left" | "right") => {
    const newColumn = "New Column";
    setTableData((prev) => {
      const updatedColumns =
        position === "left"
          ? [newColumn, ...prev.columns]
          : [...prev.columns, newColumn];
      const updatedRows = prev.rows.map((row) =>
        position === "left" ? [newColumn, ...row] : [...row, newColumn]
      );
      return { columns: updatedColumns, rows: updatedRows };
    });
    handleMenuClose();
  };

  const handleAddRow = (position: "top" | "bottom") => {
    const newRow = Array(tableData.columns.length).fill("");
    setTableData((prev) => {
      const updatedRows =
        position === "top" ? [newRow, ...prev.rows] : [...prev.rows, newRow];
      return { ...prev, rows: updatedRows };
    });
    handleMenuClose();
  };

  const handleCellChange = (
    rowIndex: number,
    colIndex: number,
    value: string
  ) => {
    const updatedRows = tableData.rows.map((row, rIdx) =>
      rIdx === rowIndex
        ? row.map((cell, cIdx) => (cIdx === colIndex ? value : cell))
        : row
    );
    setTableData((prev) => ({ ...prev, rows: updatedRows }));
  };

  const handleSave = () => {
    onUpdate(tableData);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setTableData(initializeTable(columns, rows)); // Revert to initial table data
    setIsEditing(false);
  };

  return (
    <Box className={g.tableComponent}>
      {isEditing ? (
        <Box className={g.editorContainer}>
          <table>
            <thead>
              <tr>
                {tableData.columns.map((col, index) => (
                  <th key={index}>
                    <TextField
                      value={col}
                      onChange={(e) => {
                        const newColumns = [...tableData.columns];
                        newColumns[index] = e.target.value;
                        setTableData((prev) => ({
                          ...prev,
                          columns: newColumns,
                        }));
                      }}
                      variant="outlined"
                      className={g.tableTextField}
                    />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, colIndex) => (
                    <td key={colIndex}>
                      <TextField
                        value={cell}
                        onChange={(e) =>
                          handleCellChange(rowIndex, colIndex, e.target.value)
                        }
                        variant="outlined"
                        className={g.tableTextField}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <Box className={g.buttonContainer}>
            <Tooltip title="Save">
              <IconButton
                className={g.iconButtonWC}
                color="primary"
                onClick={handleSave}
              >
                <CorrectIcon w={19} color="green" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel">
              <IconButton
                className={g.iconButtonWC}
                color="error"
                onClick={handleCancel}
              >
                <CancelIcon sx={{ fontSize: 19 }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ) : (
        <Box className={g.tableDisplay}>
          <table>
            <thead>
              <tr>
                {tableData.columns.map((col, index) => (
                  <th key={index}>{col}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, colIndex) => (
                    <td key={colIndex}>{cell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {!lockPage && (
            <Tooltip title="Edit">
              <IconButton
                color="primary"
                className={g.editButton}
                onClick={() => setIsEditing(true)}
              >
                <EditIcon sx={{ fontSize: 19 }} />
              </IconButton>
            </Tooltip>
          )}
          {!lockPage && (
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <IconButton color="primary" onClick={handleMenuClick}>
                <AddIcon />
              </IconButton>
              <Typography
                sx={{ fontFamily: "Source Serif Pro" }}
                children={"Add More..."}
              />
            </Stack>
          )}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              sx={{ fontFamily: "Source Serif Pro" }}
              onClick={() => handleAddColumn("left")}
            >
              Add Column Left
            </MenuItem>
            <MenuItem
              sx={{ fontFamily: "Source Serif Pro" }}
              onClick={() => handleAddColumn("right")}
            >
              Add Column Right
            </MenuItem>
            <MenuItem
              sx={{ fontFamily: "Source Serif Pro" }}
              onClick={() => handleAddRow("top")}
            >
              Add Row Top
            </MenuItem>
            <MenuItem
              sx={{ fontFamily: "Source Serif Pro" }}
              onClick={() => handleAddRow("bottom")}
            >
              Add Row Bottom
            </MenuItem>
          </Menu>
        </Box>
      )}
    </Box>
  );
};

export default TableComponent;
