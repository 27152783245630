import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getOneDoc__api, updateOneDoc__api } from "../../../../../../redux";
import { AddToWorkspace, CommentCard } from "../../../components";
import FroalaEditorComponent from "../../../../../common/FroalaEditor/FroalaEditor";
import { Stack, Typography } from "@mui/material";
import { ShareButton, ThreeDotMenuLarge, ViewPicker } from "../../../../../common";
import { menuItems } from "../../../InsideWorkspace/InsideWorkspaceType";

const DocumentOverView = () => {
  const dispatch = useDispatch();
  const workspaceState = useSelector((state) => state?.workspace);
  const { insideWorkspaceComponentData } = workspaceState || {};
  const { workspaceId, componentId } = useParams();
  const [content, setContent] = useState(""); // Local state for content
  const componentMounted = useRef(false); // Ref to track if mounted
  const [isCommentButtonClicked, setCommentButtonClicked] = useState(false);
  const handleGetOneDocument = async () => {
    if (!workspaceId || !componentId) return;

    const action = getOneDoc__api(workspaceId, componentId);
    await dispatch(action);
  };

  const idsMatch = () => {
    return (
      insideWorkspaceComponentData?._id === componentId &&
      insideWorkspaceComponentData?.workspaceId === workspaceId
    );
  };

  const debouncedUpdateDocument = useCallback(
    async (updatedContent) => {
      if (!insideWorkspaceComponentData || !idsMatch()) {
        return;
      }
      const addMonths = (months) => {
        const date = new Date();
        date.setMonth(date.getMonth() + months);
        return date.toISOString();
      };

      const documentPayload = {
        title: insideWorkspaceComponentData?.title,
        content: updatedContent,
        isPublic: true,
        password: "",
        expirationDate: addMonths(6),
      };

      const action = updateOneDoc__api(
        workspaceId,
        componentId,
        documentPayload
      );
      await dispatch(action);
    },
    [dispatch, insideWorkspaceComponentData, workspaceId, componentId]
  );

  const decodeHTML = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  useEffect(() => {
    if (idsMatch() && insideWorkspaceComponentData?.content) {
      const decodedContent = decodeHTML(insideWorkspaceComponentData.content);
      setContent(decodedContent || "");
    } else {
      handleGetOneDocument(); // Call API if data isn't available in Redux
    }

    componentMounted.current = true;
  }, [insideWorkspaceComponentData, componentId, workspaceId]);

  const handleContentChange = (updatedContent) => {
    setContent(updatedContent);
    if (componentMounted.current) {
      debouncedUpdateDocument(updatedContent); // Call debounced function
    }
  };

  return (
    <Stack>
      <Stack ml={2} mb={3} direction={'row'} alignItems={'center'} justifyContent={'space-between'}> 
        
        <Typography sx={{
          fontFamily:"Source Serif Pro", fontSize:"25px", fontWeight:"900", color:"black", lineHeight:"31.33px"
        }}>
          {insideWorkspaceComponentData && insideWorkspaceComponentData?.title}
        </Typography>

        <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'} gap={1}>
        <ViewPicker />
        <ShareButton />
        <AddToWorkspace />
        <ThreeDotMenuLarge options={menuItems}  />
        </Stack>

         </Stack>
      <div style={{ marginLeft: "10px" }}>
        <Stack direction={"row"} alignItems={"flex-start"} gap={1}>
          <FroalaEditorComponent
            initialContent={content}
            onContentChange={handleContentChange}
            isCommentButtonClicked={isCommentButtonClicked}
            setCommentButtonClicked={setCommentButtonClicked}
          />
          {isCommentButtonClicked && <CommentCard />}
        </Stack>
      </div>
    </Stack>
  );
};

export default DocumentOverView;
