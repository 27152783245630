import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FolderIcon } from "../../../../../../images";
import { ShareButton } from "../../../../Buttons";

interface CloudStorageComponentProps {
  folderId: string;
  fileId?: string;
}

const CloudStorageRoute: React.FC<CloudStorageComponentProps> = ({
  folderId,
  fileId,
}) => {
  const navigate = useNavigate();
  if (!folderId || !fileId) return null;

  const handleNavigate = () => {
    navigate(`/cloud-storage/`); // Navigate to all workspaces
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Stack
        mt={"5px"}
        gap={2}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"flex-start"}
      >
        <Stack
          width={"35px"}
          height={"35px"}
          bgcolor={"#FF9100"}
          alignContent={"center"}
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius={"5px"}
        >
          <Typography
            sx={{
              font: "normal normal bold 24px/27px Source Serif Pro",
              color: "white",
            }}
          >
            V1
          </Typography>
        </Stack>

        <Stack direction={"column"} gap={0}>
          <Stack>
            {" "}
            <Typography
              sx={{ font: "normal normal 15px/17px Source Serif Pro" }}
            >
              Document name
            </Typography>{" "}
          </Stack>

          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            gap={0.6}
          >
            <FolderIcon color="#00A1E0" w={15} />
            <Typography
              onClick={() => handleNavigate()}
              sx={{ font: "normal normal 11px/13px Source Serif Pro" }}
            >
              All files
            </Typography>{" "}
            <Stack mt={"-4px"} sx={{ opacity: 0.5 }}>
              |
            </Stack>
            <Typography
              onClick={() => handleNavigate()}
              sx={{ font: "normal normal 11px/13px Source Serif Pro" }}
            >
              Updated on November 3rd, 2024 by Jackie Johnson
            </Typography>{" "}
          </Stack>
        </Stack>
      </Stack>

      <Stack gap={2} alignItems={'center'} justifyContent={'flex-start'} direction={'row'}>
              <ShareButton />
        <Stack mt={"-4px"} sx={{ opacity: 0.5 }}>
          |
        </Stack>
      </Stack>
    </Box>
  );
};

export default CloudStorageRoute;
