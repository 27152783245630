import React from "react";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

// Centralized style configuration for Tooltip
const tooltipStyles = {
  backgroundColor: "#616161",
  color: "white",
  boxShadow: "0px 3px 6px #00000029",
  font: "normal normal 300 11px/15px Source Serif Pro",
  borderRadius: 2,
  padding: "3px 6px",
};

const arrowStyles = {
  color: "#616161",
};

// Custom Tooltip with centralized styling
const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    arrow
    placement="top"
    PopperProps={{
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, -5],
          },
        },
      ],
    }}
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: tooltipStyles,
  [`& .${tooltipClasses.arrow}`]: arrowStyles,
}));

// Props interface for StyledTooltip
interface StyledTooltipProps extends TooltipProps {
  // Add any additional props specific to StyledTooltip here
}

const StyledTooltip: React.FC<StyledTooltipProps> = ({
  children,
  ...props
}) => {
  return (
    <CustomTooltip {...props}>
      <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center' }}>
        {children}
      </Box>
    </CustomTooltip>
  );
};

export default StyledTooltip;
