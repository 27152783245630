import { Stack, styled, Typography, Popover, SxProps } from "@mui/material";
import React, { useState } from "react";
import { MultiSectionDigitalClock } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ClockIcon } from "../../../images";

const StyledMultiSectionDigitalClock = styled(MultiSectionDigitalClock)({
  "& .MuiMultiSectionDigitalClockSection-root": {
    "&::-webkit-scrollbar": {
      display: "none" /* Chrome, Safari, Opera */,
    },
    scrollbarWidth: "none" /* Firefox */,
  },
  "& .MuiMultiSectionDigitalClockClock-root": {
    fontFamily: "Source Serif Pro, serif",
  },
  "& .MuiMultiSectionDigitalClockSection-item": {
    fontFamily: "Source Serif Pro, serif",
  },
});

interface DatePickerMiniProps {
  sx?: SxProps;
  hideIcon?: boolean;
}

const DatePickerMini: React.FC<DatePickerMiniProps> = ({
  sx,
  hideIcon = false,
}) => {
  const [anchorElTime, setAnchorElTime] = useState<null | HTMLElement>(null);
  const [selectedTime, setSelectedTime] = useState<Dayjs | null>(dayjs());

  const handleClickTime = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElTime(event.currentTarget);
  };

  const handleCloseTime = () => {
    setAnchorElTime(null);
  };

  const handleTimeChange = (time: Dayjs | null) => {
    setSelectedTime(time);
    // handleCloseTime();
  };

  const openTime = Boolean(anchorElTime);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack
        onClick={handleClickTime}
        sx={{
          height: "25px",
          width: "80px",
          border: "0.5px solid #E9EDF2",
          borderRadius: "5px",
          flexDirection: "row",
          alignItems: "center",
          px: "5px",
          boxSizing: "border-box",
          justifyContent: "space-between",
          cursor: "pointer",
          flexShrink: 0,
          font: "normal normal 300 12px/16px Source Serif Pro",
          ...sx,
        }}
      >
        <Typography sx={{ font: "inherit" }}>
          {selectedTime ? selectedTime.format("hh:mm A") : "time here"}
        </Typography>
        {!hideIcon && <ClockIcon w={15} />}
      </Stack>
      <Popover
        open={openTime}
        anchorEl={anchorElTime}
        onClose={handleCloseTime}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <StyledMultiSectionDigitalClock
          timeSteps={{ minutes: 1 }}
          value={selectedTime}
          onChange={handleTimeChange}
        />
      </Popover>
    </LocalizationProvider>
  );
};

export default DatePickerMini;
